import { GROUPS } from 'constants/endpoints';
import { METHOD_PATCH } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_PATCH_updateStaticGroupType } from 'requests/PATCH_updateStaticGroup.types';

export const API_PATCH_updateStaticGroup = ({
  name,
  groupId,
  addMembers,
  deleteMembers,
}: API_PATCH_updateStaticGroupType): ApiRequestConfigType => {
  const group_janes_attributes = [...addMembers, ...deleteMembers];
  return {
    __typename: 'LegacyAPI',
    url: `${GROUPS}/${groupId}`,
    method: METHOD_PATCH,
    body: {
      group: {
        name,
        type: 'StaticGroup',
        group_janes_attributes,
      },
    },
  };
};
