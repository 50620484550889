import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { ShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { isArray } from 'types/common.types';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_POST_addShiftReview } from 'requests/POST_addShiftReview';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { LegacyAPIContextType } from 'types/common.types';
import { createLegacyDataContext } from 'utils/createDataContext';
import { useTranslation } from 'hooks/useTranslation';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

export type ReviewType = {
  rating: number;
  comment: string;
};

interface IRateShiftViewContext extends LegacyAPIContextType {
  isDisabled: boolean;
  onSubmit: <T = unknown>(callback?: T) => void;
  onChange: ChangeFunctionType;
  formValues: ReviewType;
}

export const RateShiftViewContext = createLegacyDataContext<IRateShiftViewContext>({
  isDisabled: false,

  formValues: {
    rating: 0,
    comment: '',
  },
} as IRateShiftViewContext);

export const RateShiftViewProvider: React.FC = ({ children }) => {
  const { apiRequest } = useAPIActions();

  const { shift_id } = useContext(ShiftModalUIContext);

  const [reviewValues, setReviewValues] = useState<ReviewType>({
    rating: 0,
    comment: '',
  });

  const { t } = useTranslation();

  const { rating, comment } = reviewValues;
  const requestOptions = API_POST_addShiftReview({
    id: shift_id,
    rating,
    comment,
  });

  const { isPending, ...rest } = useSelector((state) => apiDataSelector(state, requestOptions));

  const onChange: ChangeFunctionType = (event) => {
    if (!isArray(event))
      setReviewValues({ ...reviewValues, [event.target.name]: event.target.value });
  };

  const onSubmit: IRateShiftViewContext['onSubmit'] = async (callback) => {
    await apiRequest(requestOptions, {
      onSuccess: () => {
        toastService.success(t('success:REVIEW_SUBMITTED'));
        queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${shift_id}`);

        window.scheduleUtils.forceDataUpdate();
        callback && (callback as unknown as () => void)();
      },
      onError: (e) =>
        handleGenericErrorMessage(e, t('errors:SOMETHING_WENT_WRONG_SUBMITTING_REVIEW')),
    });
  };

  const isDisabled = isPending || reviewValues.rating <= 0 || !reviewValues.comment;

  const _value: IRateShiftViewContext = {
    onSubmit,
    isPending,
    isDisabled,
    formValues: reviewValues,
    onChange,
    ...rest,
  };

  return <RateShiftViewContext.Provider value={_value}>{children}</RateShiftViewContext.Provider>;
};

export const useRateShiftViewContext = (): IRateShiftViewContext =>
  useContext(RateShiftViewContext);
