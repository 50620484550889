import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isEditCompletedShiftsFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (permissions) => {
    if (permissions?.EDIT_COMPLETED_SHIFTS?.access)
      return (
        permissions.EDIT_COMPLETED_SHIFTS.access.includes('read') &&
        !!permissions.EDIT_COMPLETED_SHIFTS.communities &&
        !!permissions.EDIT_COMPLETED_SHIFTS.communities[communityId] &&
        !!permissions.EDIT_COMPLETED_SHIFTS.communities[communityId].includes('read')
      );
    else return false;
  });
