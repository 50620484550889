import { Flex } from 'components/Flex';
import { BKJButton, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { FC, MouseEventHandler, useState } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  GroupDetailsHeaderBorderStyle,
  GroupsDetailsWrapper,
  H3,
} from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.styled';
import { useGroupsModalFormContext } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.form.context';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { useGroupsDetailsModalUIContext } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.ui.context';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

export const EditGroupComponent: FC = () => {
  const {
    values: { name, groupType },
    onChange,
    onDelete,
    fieldErrors,
    isSubmitting,
  } = useGroupsModalFormContext();
  const { goToNextView } = useGroupsDetailsModalUIContext();
  const { t } = useTranslation();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={GroupDetailsHeaderBorderStyle}
    >
      <GroupsDetailsWrapper>
        <H3 paddingBottom="28px">{t('groups:EDIT_GROUP')}</H3>
        <BKJTextInput
          label={t('groups:GROUP_NAME')}
          type="text"
          placeholder={t('groups:GROUP_NAME_PLACEHOLDER')}
          value={name}
          name="name"
          required
          error={fieldErrors.name}
          onChange={onChange}
        />
      </GroupsDetailsWrapper>
      <Flex height="100%"></Flex>
      <BKJCenteredDialog
        isOpen={isConfirmDeleteOpen}
        variant="Danger"
        title={t('groups:DELETE_GROUP_TITLE')}
        text={t('groups:DELETE_GROUP_DESCRIPTION')}
        cancelLabel={t('groups:CANCEL')}
        actionLabel={t('groups:DELETE')}
        onAction={onDelete}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onClose={() => setIsConfirmDeleteOpen(false)}
      />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        <BKJButton
          variant="RedText"
          onClick={() => setIsConfirmDeleteOpen(true)}
          type="button"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          css={css`
            text-decoration: none;
          `}
        >
          {t('groups:DELETE')}
        </BKJButton>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={() =>
            goToNextView({ groupType }) as unknown as MouseEventHandler<HTMLButtonElement>
          }
          type="button"
          disabled={!name}
        >
          {t('groups:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const EditGroup: FC = () => {
  return <EditGroupComponent />;
};
