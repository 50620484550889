import { FC, Fragment, useCallback, useMemo } from 'react';
import { H3 } from 'components/Typography';
import { BKJButton, ErrorBoundary, Flex } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useEditViewContext } from 'components/ShiftModal/src/views/EditView/EditView.context';
import { AutoMargin } from 'components/AutoMargin';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';
import {
  ConfirmEditsContainer,
  ConfirmEditsHeader,
  ConfirmEditsRow,
  ReasonForEditingBody,
  ReasonForEditingHeader,
  ZeroHoursWorked,
} from 'components/ShiftModal/src/views/ConfirmEditsView/ConfirmEditsView.styled';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { breakTimeToWords } from 'utils/time';
import { reasonForEditsSelectOptions } from 'components/ShiftModal/src/views/ReasonForEdits/ReasonForEdits.constants';
import { useSelector } from 'react-redux';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const ConfirmEditsView: FC = () => {
  const { t } = useTranslation();
  const { timeZone } = getCurrentlySelectedLocation();
  const {
    isSubmitting,
    onSubmit,
    values: {
      start_date,
      start_time,
      end_time,
      unpaid_break_time,
      description,
      reasonForEdit,
      zeroHoursWorked,
      customFields,
      isNotifyFlowRequired,
      ...restValues
    },
  } = useEditViewContext();

  const { data } = useShiftModalDataContext();

  const communityCustomFields = data.fetchOrder.community.customFields || [];

  const isCustomFieldsEnabled =
    useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']) &&
    communityCustomFields.length > 0;

  const reasonForEditLabel = useMemo(
    () =>
      reasonForEditsSelectOptions().find((reasonOption) => reasonOption.value === reasonForEdit[0])
        ?.label,
    [reasonForEdit],
  );

  const handleSubmit = useCallback(
    (values) => {
      const submitValues = {
        ...values,
        ...restValues,
        start_date: zeroHoursWorked ? data.fetchOrder.startDate : start_date,
        start_time: zeroHoursWorked ? data.fetchOrder.startAt : start_time,
        end_time: zeroHoursWorked ? data.fetchOrder.endAt : end_time,
        unpaid_break_time: zeroHoursWorked
          ? data.fetchOrder.orderSchedule.breakTime
          : unpaid_break_time,
      };
      return onSubmit(submitValues);
    },
    [
      data.fetchOrder.endAt,
      data.fetchOrder.orderSchedule.breakTime,
      data.fetchOrder.startAt,
      data.fetchOrder.startDate,
      end_time,
      onSubmit,
      restValues,
      start_date,
      start_time,
      unpaid_break_time,
      zeroHoursWorked,
    ],
  );

  const startDateLabel = DateTime.fromISO(data.fetchOrder.startDate).toFormat('EEE, MMM dd, yyyy');
  const startTimeLabel = DateTime.fromISO(data.fetchOrder.startAt)
    .setZone(timeZone)
    .toFormat('h:mm a');
  const endTimeLabel = DateTime.fromISO(data.fetchOrder.endAt).setZone(timeZone).toFormat('h:mm a');
  const breakTimeLabel = breakTimeToWords(data.fetchOrder.orderSchedule.breakTime);

  return (
    <ErrorBoundary
      componentName="ConfirmEditsView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <Flex flexDirection="column" padding="32px" flex="1">
        <Flex padding="0 0 30px 0">
          <H3 color="TextDarkGrey">{t('shift_modal:CONFIRM_EDITS')}</H3>
        </Flex>
        <Flex padding="0 0 15px 0">
          <div>{t('shift_modal:PLEASE_CONFIRM_FOLLOWING_EDITS')}</div>
        </Flex>
        <ConfirmEditsContainer>
          <ConfirmEditsHeader>{t('shift_modal:ITEM')}</ConfirmEditsHeader>
          <ConfirmEditsHeader>{t('shift_modal:CURRENT')}</ConfirmEditsHeader>
          <ConfirmEditsHeader>{t('shift_modal:EDITED_TO')}</ConfirmEditsHeader>

          <ConfirmEditsRow>{t('shift_modal:DATE')}</ConfirmEditsRow>
          <ConfirmEditsRow>{startDateLabel}</ConfirmEditsRow>
          <ConfirmEditsRow>
            {zeroHoursWorked
              ? startDateLabel
              : DateTime.fromISO(start_date[0]).toFormat('EEE, MMM dd, yyyy')}
          </ConfirmEditsRow>

          <ConfirmEditsRow $isEven>{t('shift_modal:START_TIME')}</ConfirmEditsRow>
          <ConfirmEditsRow $isEven>{startTimeLabel}</ConfirmEditsRow>
          <ConfirmEditsRow $isEven>
            {zeroHoursWorked
              ? t('shift_modal:ABSENT')
              : DateTime.fromFormat(start_time[0], 'HH:mm:ss').toFormat('h:mm a')}
          </ConfirmEditsRow>

          <ConfirmEditsRow>{t('shift_modal:END_TIME')}</ConfirmEditsRow>
          <ConfirmEditsRow>{endTimeLabel}</ConfirmEditsRow>
          <ConfirmEditsRow>
            {zeroHoursWorked
              ? t('shift_modal:ABSENT')
              : DateTime.fromFormat(end_time[0], 'HH:mm:ss').toFormat('h:mm a')}
          </ConfirmEditsRow>

          <ConfirmEditsRow $isEven>{t('common:UNPAID_BREAK_TIME')}</ConfirmEditsRow>
          <ConfirmEditsRow $isEven>{breakTimeLabel}</ConfirmEditsRow>
          <ConfirmEditsRow $isEven>
            {zeroHoursWorked ? t('shift_modal:ABSENT') : breakTimeToWords(unpaid_break_time[0])}
          </ConfirmEditsRow>

          <ConfirmEditsRow>{t('shift_modal:NOTES')}</ConfirmEditsRow>
          <ConfirmEditsRow>{data.fetchOrder.description}</ConfirmEditsRow>
          <ConfirmEditsRow>{description}</ConfirmEditsRow>

          {isCustomFieldsEnabled &&
            communityCustomFields.map((customField, index) => (
              <Fragment key={index}>
                <ConfirmEditsRow>{customField.name}</ConfirmEditsRow>
                <ConfirmEditsRow>
                  {
                    data?.fetchOrder?.customFields?.find(
                      (orderCustomField) => orderCustomField.name === customField.name,
                    )?.value
                  }
                </ConfirmEditsRow>
                <ConfirmEditsRow>{restValues[customField.name]}</ConfirmEditsRow>
              </Fragment>
            ))}
        </ConfirmEditsContainer>
        {reasonForEditLabel && (
          <Flex padding="40px 0 4px 0">
            <ReasonForEditingHeader>
              {t('shift_modal:REASON_FOR_EDITING_1')}:
            </ReasonForEditingHeader>
          </Flex>
        )}
        {reasonForEditLabel && (
          <Flex>
            <ReasonForEditingBody>{reasonForEditLabel}</ReasonForEditingBody>
          </Flex>
        )}
        {zeroHoursWorked && (
          <Flex padding="30px 0 0 0" flexDirection="column" gap="8px">
            <ZeroHoursWorked>{t('shift_modal:ABSENT')}</ZeroHoursWorked>
            <ReasonForEditingBody>
              {t('shift_modal:ZERO_HOURS_WORKED_WARNING')}
            </ReasonForEditingBody>
          </Flex>
        )}
        {isNotifyFlowRequired && (
          <Flex padding="40px 0 0 0">
            {t('shift_modal:FOLLOWING_TEAM_MEMBERS_EMAIL_NOTIFICATION')}
          </Flex>
        )}
      </Flex>

      <BKJSideModalFooter justifyContent="space-between">
        <AutoMargin />
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          type="button"
          disabled={false}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          {t('shift_modal:PUBLISH_EDITS')}
        </BKJButton>
      </BKJSideModalFooter>
    </ErrorBoundary>
  );
};
