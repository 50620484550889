import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { fetchAgenciesByOrderIdConfig } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/constants';
import { useSSFBehaviors } from 'hooks';
import { SSFBehaviorsContextType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { FC, useContext, useMemo } from 'react';
import { sendToSelectedAgenciesFetchAgenciesByOrderId } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

interface IFetchAgenciesByOrderIdData {}

export const FetchAgenciesByOrderIdDataContext = createGQLDataContext<IFetchAgenciesByOrderIdData>(
  {},
);

export const FetchAgenciesByOrderIdDataProvider: FC = ({ children }) => {
  const {
    data: {
      fetchOrder: { relayId: orderId },
    },
  } = useShiftModalDataContext();

  const [values, rest] = useSSFBehaviors(
    fetchAgenciesByOrderIdConfig({
      orderId,
    }),
  );

  const contextValue = useMemo(() => ({ values, ...rest }), [rest, values]);

  return (
    <FetchAgenciesByOrderIdDataContext.Provider value={contextValue}>
      {children}
    </FetchAgenciesByOrderIdDataContext.Provider>
  );
};

export const useFetchAgenciesByOrderIdDataContext =
  (): SSFBehaviorsContextType<sendToSelectedAgenciesFetchAgenciesByOrderId> =>
    useContext(FetchAgenciesByOrderIdDataContext);
