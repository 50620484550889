import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';

export const onSort = (e: ChangeEventType) => {
  let updateEvent = { ...e };
  switch (updateEvent.target.value) {
    case 'default':
      updateEvent.target.value = JSON.stringify({
        direction: 'asc',
        name: updateEvent.target.name,
      });
      break;
    case 'asc':
      updateEvent.target.value = JSON.stringify({
        direction: 'desc',
        name: updateEvent.target.name,
      });
      break;
    case 'desc':
      updateEvent.target.value = JSON.stringify({
        direction: 'default',
        name: updateEvent.target.name,
      });
      break;
    default:
      updateEvent.target.value = JSON.stringify({
        direction: 'asc',
        name: updateEvent.target.name,
      });
  }
  updateEvent.target.name = 'sortField';
  return updateEvent;
};
