import { FC, Fragment } from 'react';
import { H3 } from 'components/Typography';
import {
  BKJListItem,
  BKJSelect,
  BKJTypography,
  ErrorBoundary,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { useEditViewContext } from 'components/ShiftModal/src/views/EditView/EditView.context';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { withMemo } from 'utils/withMemo';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { OrderStatusEnum } from 'types/graphql-types';

const hourDropdownOptions = [4, 12, 24, 48, 72].map((dur) => ({
  key: `${dur}`,
  value: `${dur}`,
  label: `${dur} hrs`,
}));

const RenderOptions: FC<IBKJSelectRenderOptionsProps> = withMemo(function _RenderOptions(props) {
  return (
    <Fragment>
      {hourDropdownOptions.map((option) => {
        const { type, ...restProps } = props;
        return <BKJListItem {...restProps} {...option} />;
      })}
    </Fragment>
  );
});

export const NotifyTeamMember: FC = () => {
  const { t } = useTranslation();
  const {
    data: {
      fetchOrder: { status, biddable },
    },
  } = useShiftModalDataContext();
  const { values, onChange } = useEditViewContext();
  const isInProgressOrCompletedShift =
    status.value === OrderStatusEnum.in_progress || status.value === OrderStatusEnum.completed;
  const isOnlyNotificationOrNoNotification = !!isInProgressOrCompletedShift || !!biddable;
  return (
    <ErrorBoundary
      componentName="NotifyTeamMember"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <Flex flexDirection="column" padding="32px" flex="1">
        <H3 color="TextDarkGrey">
          {isInProgressOrCompletedShift
            ? t('shift_modal:NOTIFY_TEAM_MEMBER_OF_THE_EDITS')
            : t('shift_modal:NOTIFY_TEAM_MEMBER_NOTE')}
        </H3>
        <br />
        {!isOnlyNotificationOrNoNotification && (
          <BKJRadioInput
            name="notification_method"
            value="needs_acknowledgement"
            onChange={onChange}
            checked={values.notification_method === 'needs_acknowledgement'}
            label={t('shift_modal:TEAM_MEMBER_ACKNOWLEDGE_LABEL')}
          />
        )}
        {values.notification_method === 'needs_acknowledgement' && (
          <Flex padding="0 0 0 36px" flexDirection="column">
            <BKJTypography variant="Caption" element="span">
              {t('shift_modal:TEAM_MEMBER_ACKNOWLEDGE_NOTE')}
            </BKJTypography>
          </Flex>
        )}
        {!isOnlyNotificationOrNoNotification && (
          <BKJRadioInput
            name="notification_method"
            value="reaccept_or_decline"
            onChange={onChange}
            checked={values.notification_method === 'reaccept_or_decline'}
            label={t('shift_modal:TEAM_MEMBER_RE_ACCEPT_LABEL')}
          />
        )}
        {values.notification_method === 'reaccept_or_decline' && (
          <Flex padding="0 0 0 36px" flexDirection="column">
            <BKJTypography variant="Caption" element="span">
              {t('shift_modal:TEAM_MEMBER_RE_ACCEPT_NOTE')}
            </BKJTypography>
            <Flex alignItems="center" margin="10px 0 0 0">
              <BKJSelect
                width="130px"
                type="Single"
                name="reaccept_or_decline_threshold"
                value={values.reaccept_or_decline_threshold}
                variant="GreyOutlined"
                onChange={onChange}
                options={hourDropdownOptions}
                renderOptions={RenderOptions}
              />
              &nbsp; &nbsp;
              <BKJTypography variant="Caption" element="span">
                {t('shift_modal:FROM_NOW')}
              </BKJTypography>
            </Flex>
          </Flex>
        )}
        <BKJRadioInput
          name="notification_method"
          value="only_notification"
          onChange={onChange}
          checked={values.notification_method === 'only_notification'}
          label={
            isInProgressOrCompletedShift
              ? t('shift_modal:YES_NOTIFY_TEAM_MEMBER')
              : t('shift_modal:NOTIFICATION_ONLY')
          }
        />
        <BKJRadioInput
          name="notification_method"
          value="no_notification"
          onChange={onChange}
          checked={values.notification_method === 'no_notification'}
          label={
            isInProgressOrCompletedShift
              ? t('shift_modal:NO_NOTIFY_TEAM_MEMBER')
              : t('shift_modal:DO_NOT_NOTIFY_TEAM_MEMBER')
          }
        />
      </Flex>
    </ErrorBoundary>
  );
};
