import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { isEditCompletedShiftsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isEditCompletedShiftsFeatureEnabledSelector';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isEditInProgressShiftsFeatureEnabledSelector = (communityId: number) =>
  createSelector(
    getUserPermissions,
    isEditCompletedShiftsFeatureEnabledSelector(communityId),
    (permissions, isEditCompletedShiftsFeatureEnabled) => {
      if (isEditCompletedShiftsFeatureEnabled) return true;
      if (permissions?.EDIT_IN_PROGRESS_SHIFTS?.access)
        return (
          permissions.EDIT_IN_PROGRESS_SHIFTS.access.includes('read') &&
          !!permissions.EDIT_IN_PROGRESS_SHIFTS.communities &&
          !!permissions.EDIT_IN_PROGRESS_SHIFTS.communities[communityId] &&
          !!permissions.EDIT_IN_PROGRESS_SHIFTS.communities[communityId].includes('read')
        );
      else return false;
    },
  );
