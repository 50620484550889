import { BKJButton, BKJIcon, BKJLoadingSpinner, Flex } from '@bookjane2/bookjane-design-library';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  CallOutHeader,
  CallOutWrapper,
  CallOutViewDetailsLink,
  CallOutShiftDistributionLink,
  CallOutTitleSection,
  CallOutBodySection,
  EstimatedTimeSection,
  ProgressStatusSection,
  BKJProgressBar,
  CallOutProgressBarSection,
  CallOutHeaderText,
  CallOutHeaderIcon,
  CallOutHeaderWrapper,
  ProgressBold,
  ProgressText,
  Description,
  CallOutBeingFinalized,
} from 'components/ShiftModal/src/views/DetailView/GeneralTab/CallOut.styled';
import { WarningIcon } from 'pages/SchedulePage/Modals/SchedulePublishModal/styled';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { OrderStatusEnum } from 'types/graphql-types';
import { ContinueCalloutDialog } from 'components/ContinueCalloutDialog';
import { queryRefetchService } from 'services/QueryRefetchService';
import { toastService } from 'services';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const calculateEstimatedTimeRemaining = (
  estimatedCompletedAt: string,
  timeZone: string | undefined,
) => {
  const now = DateTime.now().setZone(timeZone);
  const estimatedCalloutCompleteTimestamp =
    DateTime.fromISO(estimatedCompletedAt).setZone(timeZone);

  const totalMins = Math.floor(
    estimatedCalloutCompleteTimestamp.diff(now, 'minutes').as('minutes'),
  );
  const mins = Math.floor(totalMins % 60);

  const totalHours = Math.floor(estimatedCalloutCompleteTimestamp.diff(now, 'hours').as('hours'));
  const hours = Math.floor(totalHours % 24);

  const totalDays = Math.floor(estimatedCalloutCompleteTimestamp.diff(now, 'days').as('days'));
  const days = Math.floor(totalDays);

  return { mins, hours, days };
};

export function CallOutSection() {
  const { t } = useTranslation();
  const {
    data: { fetchOrder },
    refetch,
  } = useShiftModalDataContext();

  const { timeZone } = getCurrentlySelectedLocation();

  const isCompleted = useMemo(
    () =>
      fetchOrder.currentlyActiveCalloutList?.isCompleted &&
      fetchOrder.status.value === OrderStatusEnum.pending,
    [fetchOrder],
  );

  const [isContinueCallOutConfirmationOpen, setIsContinueCallOutConfirmationOpen] = useState(false);

  const isCalloutPaused = useMemo(
    () =>
      fetchOrder.currentlyActiveCalloutList?.waitingForManualApproval &&
      fetchOrder.status.value === OrderStatusEnum.pending &&
      !isCompleted,
    [
      fetchOrder.currentlyActiveCalloutList?.waitingForManualApproval,
      fetchOrder.status.value,
      isCompleted,
    ],
  );

  const goToAuditTrailView = useCallback(
    (calloutListId: string) =>
      shiftModalRoutingService.goToView('AuditTrailView', {
        calloutListId: calloutListId,
      }),
    [],
  );

  const goToSettingsShiftDistributionView = useCallback(() => {
    window.history.pushState(
      {},
      'BookJane',
      `/settings/locations/${fetchOrder.community.id}/shift-distribution`,
    );
    window.location.reload();
  }, [fetchOrder.community.id]);

  if (!fetchOrder.currentlyActiveCalloutList) {
    return <></>;
  }

  const totalProgress = fetchOrder.currentlyActiveCalloutList.totalJanes;

  const { mins, hours, days } = calculateEstimatedTimeRemaining(
    fetchOrder.currentlyActiveCalloutList.estimatedCompletedAt,
    timeZone,
  );

  const nextCalloutGroup =
    !!isCalloutPaused &&
    fetchOrder.currentlyActiveCalloutList &&
    fetchOrder.currentlyActiveCalloutList.manualApprovalIndexes?.length
      ? fetchOrder.currentlyActiveCalloutList.manualApprovalIndexes[0].groupName
      : '';

  return (
    <CallOutWrapper>
      <CallOutTitleSection>
        <Flex flexDirection="row" flex="1">
          <CallOutHeader>
            <CallOutHeaderWrapper>
              <CallOutHeaderText>
                {t('shift_modal:CALL_OUT')}{' '}
                {isCompleted
                  ? t('shift_modal:COMPLETE')
                  : isCalloutPaused
                  ? t('shift_modal:PAUSED')
                  : t('shift_modal:IN_PROGRESS')}
                &nbsp;
              </CallOutHeaderText>
              {isCalloutPaused && <BKJIcon iconName="Pause" />}
              <CallOutHeaderIcon>{isCompleted && <WarningIcon />}</CallOutHeaderIcon>
            </CallOutHeaderWrapper>
          </CallOutHeader>
          <CallOutViewDetailsLink
            onClick={() => goToAuditTrailView(fetchOrder.currentlyActiveCalloutList!.id)}
          >
            {t('shift_modal:VIEW_DETAILS')}
          </CallOutViewDetailsLink>
        </Flex>
      </CallOutTitleSection>
      <CallOutProgressBarSection>
        <BKJProgressBar
          value={fetchOrder.currentlyActiveCalloutList.totalNotifiedAndSkipped}
          max={totalProgress}
          height="8px;"
        ></BKJProgressBar>
      </CallOutProgressBarSection>
      <CallOutBodySection>
        <Flex flexDirection="row" flex="1">
          <ProgressStatusSection>
            <ProgressBold>{t('shift_modal:PROGRESS')}:&nbsp;</ProgressBold>
            <ProgressText>
              {fetchOrder.currentlyActiveCalloutList.totalNotifiedAndSkipped}/{totalProgress}
            </ProgressText>
          </ProgressStatusSection>
          <EstimatedTimeSection>
            <ProgressBold>{t('shift_modal:EST_TIME_REMAINING')}:&nbsp;</ProgressBold>
            <ProgressText>
              {days > 0 && <span>{days}d&nbsp;</span>}
              {hours > 0 && <span>{hours}hr&nbsp;</span>}
              {mins > 0 && <span>{mins}m&nbsp;</span>}
              {days <= 0 && hours <= 0 && mins <= 0 && <span>-</span>}
            </ProgressText>
          </EstimatedTimeSection>
        </Flex>
        <Flex>
          <Description>
            {t('shift_modal:EST_TIME_REMAINING_BASED_ON')}{' '}
            <CallOutShiftDistributionLink onClick={goToSettingsShiftDistributionView}>
              {t('shift_modal:SHIFT_DISTRIBUTION_RULES')}
            </CallOutShiftDistributionLink>{' '}
            {t('shift_modal:FOR_THIS_LOCATION')} {t('shift_modal:REFRESH_TO_VIEW_UPDATES')}
          </Description>
        </Flex>
        <Flex justifyContent="space-between">
          {nextCalloutGroup && (
            <Flex padding="20px 0 0 0" flexDirection="row">
              {t('shift_modal:NEXT_GROUP')}:&nbsp;{nextCalloutGroup}
            </Flex>
          )}
          {isCalloutPaused && (
            <Flex flexDirection="row-reverse" padding="10px 0 0 0">
              <BKJButton
                variant="PurpleSolid"
                width="192px"
                onClick={(e) => setIsContinueCallOutConfirmationOpen(true)}
              >
                {t('shift_modal:CONTINUE_CALL_OUT')}
              </BKJButton>
            </Flex>
          )}
        </Flex>
        {!!isCalloutPaused && (
          <ContinueCalloutDialog
            isContinueCallOutConfirmationOpen={isContinueCallOutConfirmationOpen}
            setIsContinueCallOutConfirmationOpen={setIsContinueCallOutConfirmationOpen}
            orderId={fetchOrder.id}
            onSuccess={() => {
              toastService.success(t('shift_modal:CALL_OUT_SUCCESSFUL_RESUMED'));
              queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${fetchOrder.id}`);
              if (refetch) refetch();
            }}
          />
        )}
      </CallOutBodySection>
    </CallOutWrapper>
  );
}

export const CalloutNotFinalizedView = () => {
  const { t } = useTranslation();
  return (
    <CallOutWrapper>
      <Flex flexDirection="column" padding="0 0 25px 0" justifyContent="center" alignItems="center">
        <CallOutBeingFinalized>
          {t('shift_modal:CALLOUT_CREATION_IN_PROGRESS')}
        </CallOutBeingFinalized>
        <BKJLoadingSpinner variant="Small" />
      </Flex>
    </CallOutWrapper>
  );
};
