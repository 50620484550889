import { IPortalProps } from 'components/Portal/Portal.types';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

export class Portal extends React.Component<IPortalProps> {
  el: HTMLDivElement;
  constructor(props: IPortalProps) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    const { targetElement } = this.props;
    if (!targetElement) throw new Error('Portals targetElement was undefined.');
    targetElement.appendChild(this.el);
  }

  componentWillUnmount() {
    const { targetElement } = this.props;
    if (!targetElement) throw new Error('Portals targetElement was undefined.');
    targetElement.removeChild(this.el);
  }

  render() {
    const { children } = this.props;

    if (!this.el) return <Fragment />;
    return ReactDOM.createPortal(children, this.el);
  }
}
