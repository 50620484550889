// kulers
export const PURPLE = '#5E50A2';
export const LIGHTPURPLE = '#8080BA';
export const MAUVE = '#45424E';
export const SUBTLE = '#8b96a3';
export const ORANGE = '#F5A623';
export const ARMY_GREEN = '#417505';
export const SUCCESS_GREEN = '#00c18c';
export const TEAL = '#289E81';
export const GREY = '#707985';
export const LIGHTGREY = '#dadee3';
export const WARNING = '#961e36';
export const WHITE = '#ffffff';

export const calculateColor = (color) => {
  switch (color) {
    case 'primary':
      return MAUVE;
    case 'secondary':
      return LIGHTPURPLE;
    case 'subtle':
      return SUBTLE;
    case 'white':
      return WHITE;
    case 'success':
      return SUCCESS_GREEN;
    case 'warning':
      return WARNING;
    default:
      return color;
  }
};
