import { useFormBehaviors } from 'hooks';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useUserProfilePageDataContext } from 'pages/SettingsPage/UserProfilePage/UserProfilePage.context';

import { useSelector } from 'react-redux';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import {
  API_PUT_agencyUserProfile,
  API_PUT_b2bUserProfile,
  UserProfilePageForm,
} from 'pages/SettingsPage/UserProfilePage/UserProfilePage.constants';
import { useTranslation } from 'hooks/useTranslation';
import { userProfilePage_me_AgencyUser, userProfilePage_me_B2bCustomer } from 'types/graphql-types';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

export const UserProfilePageFormContext = createContext(
  {} as UseFormBehaviors<UserProfilePageForm>,
);

export const UserProfilePageFormProvider: FC = ({ children }) => {
  const { data, fetch } = useUserProfilePageDataContext();
  const { apiRequest } = useAPIActions();
  const isUserAgency = useSelector(isUserAgencySelector);

  const { t } = useTranslation();

  const meData = useMemo(
    () =>
      !!data && !!data?.me
        ? { ...(data.me as userProfilePage_me_B2bCustomer | userProfilePage_me_AgencyUser) }
        : ({} as userProfilePage_me_B2bCustomer | userProfilePage_me_AgencyUser),
    [data],
  );

  const apiRequestConfig = useMemo(() => {
    return isUserAgency ? API_PUT_agencyUserProfile(meData.id) : API_PUT_b2bUserProfile(meData.id);
  }, [isUserAgency, meData]);

  const handleSubmit = useCallback(
    async (formValues) => {
      await apiRequest(apiRequestConfig(formValues), {
        onSuccess: () => {
          toastService.success(t('settings:UPDATED', { value: formValues.email }));
          fetch();
        },
        onError: handleGenericErrorMessage,
      });
    },
    [apiRequest, apiRequestConfig, fetch, t],
  );

  const form = useFormBehaviors<UserProfilePageForm>({
    initialState: {
      first_name: meData.firstName,
      last_name: meData.lastName,
      email: meData.email,
      phone: meData.phone ?? undefined,
      locale: meData.locale,
    },
    onSubmit: handleSubmit,
  });

  const contextValue = useMemo(() => form, [form]);

  return (
    <UserProfilePageFormContext.Provider value={contextValue}>
      {children}
    </UserProfilePageFormContext.Provider>
  );
};

export const useUserProfilePageFormContext = () => useContext(UserProfilePageFormContext);
