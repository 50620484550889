import BKJToastMessage from '@bookjane2/bookjane-design-library/lib/components/BKJToastMessage';
import { BKJToastMessageType } from '@bookjane2/bookjane-design-library/lib/components/BKJToastMessage/BKJToastMessage.types';
import { FC } from 'react';
import {
  toast,
  ToastClassName,
  ToastContentProps,
  ToastOptions,
  ToastPosition,
} from 'react-toastify';

const TOAST_DURATION = 3000;
const TOAST_POSITION: ToastPosition = toast.POSITION.BOTTOM_CENTER;
const TOAST_CLASSNAME: ToastClassName = 'BKJToast';

const toastOpts: ToastOptions = {
  position: TOAST_POSITION,
  className: TOAST_CLASSNAME,
  autoClose: TOAST_DURATION,
};

const BKJToastMessageAliasMap: Record<
  ToastContentProps['toastProps']['type'],
  BKJToastMessageType
> = {
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  info: 'Error',
  default: 'Error',
};

export const toastService = {
  success(msg: string | FC, opts = toastOpts) {
    return toast.success(
      (props: ToastContentProps) => (
        <BKJToastMessage variant={BKJToastMessageAliasMap[props.toastProps?.type]} message={msg} />
      ),
      {
        ...toastOpts,
        ...opts,
      },
    );
  },
  error(msg: string, opts = toastOpts) {
    return toast.error(
      (props: ToastContentProps) => (
        <BKJToastMessage variant={BKJToastMessageAliasMap[props.toastProps?.type]} message={msg} />
      ),
      {
        ...toastOpts,
        ...opts,
      },
    );
  },
  warn(msg: string, opts = toastOpts) {
    return toast.warn(
      (props: ToastContentProps) => (
        <BKJToastMessage variant={BKJToastMessageAliasMap[props.toastProps?.type]} message={msg} />
      ),
      {
        ...toastOpts,
        ...opts,
      },
    );
  },
};
