import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ApiRequestConfigType } from 'requests/index.types';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { saveResponseAsCSV } from 'utils/saveResponseAsCSV';

interface IUseExportAsCSV {
  exportAsCSV: () => void;
  isPending: boolean;
}

export const useExportAsCSV = (
  requestOptions: ApiRequestConfigType,
  fileName: string,
): IUseExportAsCSV => {
  const { apiRequest } = useAPIActions();
  const exportAsCSV = useCallback(
    () =>
      apiRequest(requestOptions, {
        onSuccess: (res) => saveResponseAsCSV(res, fileName),
      }),
    [apiRequest, fileName, requestOptions],
  );

  const { isPending } = useSelector((state) => apiDataSelector(state, requestOptions));

  return {
    exportAsCSV,
    isPending,
  };
};
