import { useCalendarDayCellPropsContext } from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCell.props.context';
import { CalendarMoreOptions } from 'components/ShiftCalendar/CalendarMoreOptions';
import {
  CalendarDayStatusCard,
  CalendarDayStatusesCardCount,
  CalendarDayStatusesCardStatus,
} from 'components/ShiftCalendar/ShiftCalendar.styled';
import { useShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import { UseShiftsPageContextReturnType } from 'pages/ShiftsPage/ShiftsPage.types';
import { FC, Fragment, useMemo } from 'react';
import { parseTitle } from 'utils/parseTitle';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';
import {
  OrderStatusEnum,
  shiftsCalendarPage,
  shiftsCalendarPage_groupCountOrders_counted_subGroups,
  shiftsCalendarPage_groupCountOrders_counted,
  shiftsCalendarPage_groupCountOrders_group,
} from 'types/graphql-types';

const OrderStatusComponentMapper: FC<{
  orderStatus: keyof typeof OrderStatusEnum | 'open';
  numOrders: number;
}> = ({ orderStatus, numOrders }) => {
  const { i18n, t } = useTranslation();
  return (
    <CalendarDayStatusCard orderStatus={orderStatus}>
      <CalendarDayStatusesCardCount>{numOrders}</CalendarDayStatusesCardCount>
      &nbsp; &nbsp;
      <CalendarDayStatusesCardStatus>
        {getTranslatedResponse(parseTitle(orderStatus, '_'), 'status', i18n, t)}
      </CalendarDayStatusesCardStatus>
    </CalendarDayStatusCard>
  );
};

export const CalendarCellStatuses: FC = () => {
  const { data } = useShiftsPageContext() as {
    data: shiftsCalendarPage;
  } & UseShiftsPageContextReturnType;

  const { date } = useCalendarDayCellPropsContext();
  const __data__ = useMemo(() => {
    let filtered: {
      [key: string]: {
        status: shiftsCalendarPage_groupCountOrders_counted_subGroups['fieldValue'];
        date: shiftsCalendarPage_groupCountOrders_group['fieldValue'];
        count: shiftsCalendarPage_groupCountOrders_counted['value'];
      };
    } = {};

    data.groupCountOrders.forEach(({ group, counted }) => {
      counted.forEach(({ subGroups, value }) => {
        subGroups.forEach(({ fieldValue }) => {
          const startDate = group.fieldValue;
          if (startDate === date) {
            filtered[fieldValue] = {
              status: fieldValue,
              date: startDate,
              count: value,
            };
          }
        });
      });
    });
    return Object.values(filtered);
  }, [data.groupCountOrders, date]);

  const truncatedStatuses = __data__?.slice(3);
  return (
    <Fragment>
      {__data__.map(({ status, count }, i) => {
        const orderStatus = status.replace(' ', '_').toLowerCase();
        if (i >= 3) return <Fragment key={`${date}-${orderStatus}`} />;
        return (
          <OrderStatusComponentMapper
            key={`${date}-${orderStatus}`}
            orderStatus={orderStatus}
            numOrders={count}
          />
        );
      })}

      {truncatedStatuses.length > 0 && (
        <CalendarMoreOptions
          truncatedStatuses={truncatedStatuses.map(({ status }, i) => {
            const orderStatus = status.replace(' ', '_').toLowerCase();
            return orderStatus;
          })}
        />
      )}
    </Fragment>
  );
};
