import { FC, useMemo } from 'react';
import { QUERY_searchTeamMembersForGroup } from 'queries';
import { useSSFBehaviors } from 'hooks';
import { createGQLDataContext } from 'utils/createDataContext';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { pageContextInitialState } from 'types/common.types';
import { SessionService } from 'services';
import { InMemoryCache } from '@apollo/client';
import { createApolloClient } from 'providers/ApolloProvider';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';

export const SearchTeamMembersGroupsContext = createGQLDataContext({ ...pageContextInitialState });

const searchTeamMembersGroupsApolloClient = createApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          fetchJanes: {
            keyArgs: false,
            merge(existing = { edges: [] }, incoming = { edges: [] }) {
              const nextEdges = [...existing.edges];
              if (incoming.edges?.length > 0)
                incoming.edges.forEach((record: { node: { __ref?: string } }) => {
                  if (record && record.node?.__ref)
                    if (!nextEdges.some(({ node: __ref }) => record.node.__ref === __ref))
                      nextEdges.push(record);
                });
              return { ...existing, ...incoming, edges: nextEdges };
            },
          },
        },
      },
    },
  }),
});
const config = ({
  location,
  groupId,
}: {
  location: ILocationFilterProps['value'];
  groupId: number;
}): ISSFBehaviorsOptions => ({
  debounceTimeout: 500,
  key: 'SEARCH_TEAM_MEMBERS_FOR_GROUP',
  query: QUERY_searchTeamMembersForGroup,
  client: searchTeamMembersGroupsApolloClient,
  payloadTransformer: (variables) => {
    if (SessionService.assertUserType('B2B')) {
      // Only primary B2B users should filter by primary community
      // Essential users will see team members across all locations
      if (SessionService.assertUserRole('Premium'))
        variables.byPrimaryCommunity = Number(location.value as string);
    }

    if (SessionService.assertUserType('Agency'))
      variables.byAgencyCommunity = Number(location.value as string);

    return variables;
  },
  paginationType: 'InfiniteScroll',
  schema: {
    byFullName: {
      initialValue: '',
      mapper: String,
    },
    byPrimaryCommunity: {
      initialValue: undefined,
    },
    byAgencyCommunity: {
      initialValue: undefined,
    },
    groupsFilter: {
      initialValue: { withId: [groupId] },
    },
    sort: {
      initialValue: [
        {
          field: 'firstName',
          direction: 'asc',
        },
      ],
    },
  },
});

export const SearchTeamMembersGroupsProvider: FC<{
  location: ILocationFilterProps['value'];
  groupId: number;
}> = ({ location, groupId, children }) => {
  const [values, useQueryOptions] = useSSFBehaviors(config({ location, groupId }));
  const value = useMemo(() => ({ values, ...useQueryOptions }), [useQueryOptions, values]);
  return (
    <SearchTeamMembersGroupsContext.Provider value={value}>
      {children}
    </SearchTeamMembersGroupsContext.Provider>
  );
};
