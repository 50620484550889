import { UPDATE_SHIFT_SWAP } from 'constants/endpoints';
import { ShiftSwapLocationSettingsPageFormFields } from 'pages/LocationSettingsPage/ShiftSwapLocationSettingsPageForm.types';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_POST_updateCustomerShiftSwap = (
  idArr: string[],
  params: ShiftSwapLocationSettingsPageFormFields,
): ApiRequestConfigType[] => {
  const payload = {
    community: {
      enabled: params.shift_swap_enabled,
      min_hours_before_shift_swap: Number.parseInt(params.min_hours_before_shift_swap),
    },
  };

  return idArr.map((id) => {
    return {
      method: METHOD_POST,
      url: UPDATE_SHIFT_SWAP(id),
      __typename: 'LegacyAPI',
      body: payload,
    };
  });
};
