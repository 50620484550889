import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const NoResultsWrapper = styled.div`
  font-size: 15px;
`;
export const MemberComponentWrapper = styled.div`
  padding-top: 18px;
  height: 100%;
  width: 100%;
`;

export const MemberTable = styled(Flex)`
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey3};
  width: 100%;
`;

export const MemberColumn = styled(Flex)`
  gap: 8px;
  width: 100%;
`;

export const MemberRow = styled(Flex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 16px 12px 42px;
  border-top: 1px solid ${({ theme }) => theme.colors.Grey3};
`;
