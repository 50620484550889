import styled from 'styled-components';

export const DetailsWrapper = styled.div`
  padding: 32px;
`;

export const Subtitle = styled.span`
  ${({ theme }) => theme.typography.H4};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 32px;
`;

export const LabelText = styled.span`
  ${({ theme }) => theme.typography.CaptionBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin: 0;
`;

export const ValueText = styled.span<{ marginLeft?: number }>`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};
`;

export const Divider = styled.hr`
  color: ${({ theme }) => theme.colors.Grey5};
  width: 100%;
  height: 0.5px;
  margin: 32px 0px;
`;

export const NoTimesheetsAvailable = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  padding: 50px 0;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const NoShowLabel = styled.span`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.ErrorDefault};
  ${({ theme }) => theme.common.Ellipsis};
`;
