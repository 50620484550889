import { FC, useEffect, useRef } from 'react';
import { IGoogleMapsViewProps } from 'components/BKJInlineGoogleMapView/BKJInlineGoogleMapsView.types';
import { GoogleMapsElement } from 'components/BKJInlineGoogleMapView/BKJInlineGoogleMapsView.styled';

export const BKJInlineGoogleMapsComponent: FC<IGoogleMapsViewProps> = ({
  addressInfo,
  zoom,
  width,
  height,
}: IGoogleMapsViewProps): JSX.Element => {
  const internalMapRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (addressInfo.longitude && addressInfo.latitude) {
      const center = { lng: addressInfo.longitude, lat: addressInfo.latitude };
      const map = new window.google.maps.Map(internalMapRef.current, {
        center,
        zoom,
      });

      // Base64 conversion of BookJane location marker in case we want to make this consistent across the maps in J360 yet deviate from Figma
      // 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMWVtIiBoZWlnaHQ9IjE5cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmb250LXNpemU9IjI0Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTUgOS40MzVDNSA0LjgwOSA4LjggMiAxMi40MzUgMnM3LjQzNSAyLjgwOSA3LjQzNSA3LjQzNWMwIDQuMzc4LTYuNjEgMTEuMDctNi44NTcgMTEuMzE3YS43NS43NSAwIDAxLS41NzguMjQ4Ljc1Ljc1IDAgMDEtLjU3OS0uMjQ4QzExLjYxIDIwLjUwNCA1IDEzLjgxMyA1IDkuNDM1em00Ljk1OCAwYzAgMS40MDQgMS4wNzQgMi40NzggMi40NzggMi40NzggMS40MDQgMCAyLjQ3OC0xLjA3NCAyLjQ3OC0yLjQ3OCAwLTEuNDA1LTEuMDc0LTIuNDc5LTIuNDc4LTIuNDc5LTEuNDA0IDAtMi40NzggMS4wNzQtMi40NzggMi40Nzl6IiBmaWxsPSIjMUMwRjUxIj48L3BhdGg+PG1hc2sgaWQ9IkxvY2F0aW9uX3N2Z19fYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iNSIgeT0iMiIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE5Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTUgOS40MzVDNSA0LjgwOSA4LjggMiAxMi40MzUgMnM3LjQzNSAyLjgwOSA3LjQzNSA3LjQzNWMwIDQuMzc4LTYuNjEgMTEuMDctNi44NTcgMTEuMzE3YS43NS43NSAwIDAxLS41NzguMjQ4Ljc1Ljc1IDAgMDEtLjU3OS0uMjQ4QzExLjYxIDIwLjUwNCA1IDEzLjgxMyA1IDkuNDM1em00Ljk1OCAwYzAgMS40MDQgMS4wNzQgMi40NzggMi40NzggMi40NzggMS40MDQgMCAyLjQ3OC0xLjA3NCAyLjQ3OC0yLjQ3OCAwLTEuNDA1LTEuMDc0LTIuNDc5LTIuNDc4LTIuNDc5LTEuNDA0IDAtMi40NzggMS4wNzQtMi40NzggMi40Nzl6IiBmaWxsPSIjRkZGRkZGIj48L3BhdGg+PC9tYXNrPjxnIG1hc2s9InVybCgjTG9jYXRpb25fc3ZnX19hKSI+PHBhdGggZmlsbD0iIzFDMEY1MSIgZD0iTTMuMTAyIDJoMTl2MTloLTE5eiI+PC9wYXRoPjwvZz48L3N2Zz4='

      new google.maps.Marker({
        position: center,
        map,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
          labelOrigin: new google.maps.Point(0, -10),
          size: new google.maps.Size(32, 32),
        },
      });
    }
  });

  return <GoogleMapsElement width={width} height={height} ref={internalMapRef} />;
};
