import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const ShiftsName = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.Grey7};
`;

export const BKJFieldWrapper = styled(Flex)`
  width: 100%;
  gap: 90px;
  margin-bottom: 25px;
`;

export const FieldErrorWrapper = styled(Flex)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ErrorDefault};
  margin-bottom: 25px;
`;
