import styled from 'styled-components';
import {
  StyledCalendarButtonAttributes,
  StyledCalendarWrapperProps,
} from '../../BKJCalendar.types';

export const CalendarNavigationWrapper = styled.div<StyledCalendarWrapperProps>`
  display: flex;
  height: 52px;
  width: 100%;
`;

export const CalendarNavigationButton = styled.button<StyledCalendarButtonAttributes>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
  max-width: 40px;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.White};
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
`;

export const CalendarGoToViewButton = styled.button<StyledCalendarButtonAttributes>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 256px;
  max-width: 256px;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.H4};
  background-color: ${({ theme }) => theme.colors.White};
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.White};
    cursor: not-allowed;
  }
`;
