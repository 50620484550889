import { FC, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import { fetchCommunityConfig } from 'pages/ShiftDistributionPage/ShiftDistributionPage.constants';
import {
  fetchCommunity,
  fetchCommunityVariables,
  fetchSharingDetails,
  fetchSharingDetails_fetchCommunities_nodes,
} from 'types/graphql-types';
import { GQLAPIContextType } from 'types/common.types';
import { fetchSharingDetailsConfig } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.constants';

export const ShiftDistributionDataContext =
  createGQLDataContext<GQLAPIContextType<fetchCommunity>>();

export const ShiftDistributionDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const location_id = pathname.split('/')[3];
  const { data, fetch, ...restQuery } = useGQLQuery<
    fetchCommunity,
    fetchCommunity,
    fetchCommunityVariables
  >(fetchCommunityConfig(Number.parseInt(location_id)));

  const sharingDetailsData = useGQLQuery<
    fetchSharingDetails,
    fetchSharingDetails_fetchCommunities_nodes[],
    {}
  >(fetchSharingDetailsConfig());

  useEffect(() => {
    fetch();
    sharingDetailsData.fetch();
  }, []); // eslint-disable-line

  const contextValues = useMemo(() => {
    return {
      sharingCommunities: [...sharingDetailsData.data],
      data,
      fetch,
      ...restQuery,
    };
  }, [data, fetch, restQuery, sharingDetailsData.data]);

  return (
    <ShiftDistributionDataContext.Provider value={contextValues}>
      {children}
    </ShiftDistributionDataContext.Provider>
  );
};

export const useShiftDistributionDataContext = (): GQLAPIContextType<fetchCommunity> =>
  useContext(ShiftDistributionDataContext);
