import styled, { css } from 'styled-components';
import { Flex } from 'components/Flex/Flex';
import { BKJButton } from '@bookjane2/bookjane-design-library';

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const MainTitleContainer = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    flex-direction: column;
  }
`;

export const AvailableCountText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 21px;
`;

export const EstimatedTotalPayoutFooterText = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    !disabled &&
    css`
      color: ${theme.colors.TextDisabled};
    `}
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-bottom: 10px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 0px 32px;
  gap: 24px;

  top: 0;
  position: sticky;
  background: white;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px 24px;
    gap: 12px;
  }
`;

export const AgencySelectListWrapper = styled.div`
  width: 100%;

  display: flex;
  flex: 1;

  padding: 32px 24px 32px 32px;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 4px 16px;
  }
`;

export const NextButton = styled(BKJButton)`
  width: 180px;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    font-size: 16px;
  }
`;
