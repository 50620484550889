import { J360UserType } from 'guards/UserGuard.types';
import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/selectors/userSelectors/userSelector';
import { isUserObjectB2bType } from 'types/common.types';

export const userTypeSelector = createCachedSelector(userSelector, (user): J360UserType | null => {
  if (!user) return null;
  const { type } = user;
  if (type === 'AgencyManager' || type === 'AgencyOwner') return 'Agency';
  if (isUserObjectB2bType(user)) {
    const { user_role } = user;
    if (user_role === 'essential' || user_role === 'premium') return 'B2B';
  }
  throw new Error(
    'userTypeSelector: Could not determine user type. The User object was probably not set in the store.',
  );
})((store) => {
  if (!store.auth.user) return 'NO_SESSION';

  return String(store.auth.user.id);
});

export const useUserTypeSelector = () => useSelector(userTypeSelector);
