import { InMemoryCache, Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import {
  PositionByDepartmentResponseDataType,
  PositionByDepartmentSelectOptionType,
} from 'components/PositionByDepartmentSelectInput/PositionsByDepartmentSelectInput.types';
import { useSSFBehaviors } from 'hooks';
import { authLink, createApolloClient, httpLink } from 'providers/ApolloProvider';
import { QUERY_positionByDepartmentSelectInput } from 'queries';
import { createContext, FC, useCallback, useMemo } from 'react';
import { useUserTypeSelector } from 'store/selectors/userSelectors/userTypeSelector';
import { GQLAPIContextType, pageContextInitialState } from 'types/common.types';
import {
  positionByDepartmentSelectInput_groupPositions,
  positionByDepartmentSelectInput_groupPositions_edges_node,
} from 'types/graphql-types';

export const PositionByDepartmentSelectInputContext = createContext(
  pageContextInitialState,
) as GQLAPIContextType;

const client = createApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          groupPositions: {
            keyArgs: false,
            merge(
              existing = { edges: [] },
              incoming = { edges: [] },
              { readField }: { readField: ReadFieldFunction },
            ) {
              const nextEdges: { node: Reference }[] = [...existing.edges];
              if (incoming.edges?.length > 0)
                incoming.edges.forEach(({ node }: { node: Reference }) => {
                  const groupId = readField('group', node);
                  if (
                    groupId &&
                    !nextEdges.some(({ node: _node }) => {
                      const _groupId = readField('group', _node);
                      return _groupId === groupId;
                    })
                  )
                    nextEdges.push({ node });
                });

              return { ...existing, ...incoming, edges: nextEdges };
            },
          },
        },
      },
    },
  }),
});

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';
const snakeToTitleCase = (s: string) => s.split('_').map(capitalize).join(' ');

export const PositionByDepartmentSelectInputProvider: FC<{
  location: ILocationFilterProps['value'];
}> = ({ location, children }) => {
  const userType = useUserTypeSelector();
  client.setLink(authLink.concat(httpLink()));
  const [values, useQueryOptions] = useSSFBehaviors({
    key: 'POSITION_BY_FACILITY_FILTER',
    query: QUERY_positionByDepartmentSelectInput,
    pageSize: 30,
    paginationType: 'InfiniteScroll',
    client,
    payloadTransformer: (variables) => {
      const payload = {
        ...variables,
        filter: {
          byName: variables.byName,
          byAgencyCommunity: undefined,
        },
      };
      if (userType === 'Agency') payload.filter.byAgencyCommunity = Number(location.value);
      return payload;
    },
    responseTransformer: (data): PositionByDepartmentResponseDataType => {
      if (!data) return { options: {} };
      if (!data.groupPositions) return { options: {} };

      const { edges, pageInfo } =
        data?.groupPositions as positionByDepartmentSelectInput_groupPositions;
      const filteredEdges = edges?.filter((edge) => !!edge?.node) as {
        node: positionByDepartmentSelectInput_groupPositions_edges_node;
      }[];

      let options: Record<string, PositionByDepartmentSelectOptionType[]> = {};
      filteredEdges.forEach(({ node: { positions, group } }) => {
        if (positions) {
          options[snakeToTitleCase(group)] = positions.map((position) => {
            return {
              value: position.id,
              label: position.name,
              key: position.id.toString(),
            };
          });
        }
      });
      return { options, pageInfo };
    },
    schema: {
      byFullName: {
        initialValue: '',
        mapper: String,
      },
    },
  });

  const onClose = useCallback(() => {
    useQueryOptions.onChange({
      target: { name: 'byName', value: '' },
    });
  }, [useQueryOptions]);

  const value = useMemo(() => {
    return { onClose, values, ...useQueryOptions };
  }, [onClose, values, useQueryOptions]);

  return (
    <PositionByDepartmentSelectInputContext.Provider value={value}>
      {children}
    </PositionByDepartmentSelectInputContext.Provider>
  );
};
