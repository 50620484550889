import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';

export const GroupTypeSelectionWrapper = styled(Flex)<{ $isGrey?: boolean }>`
  flex-direction: column;
  padding-bottom: 20px;

  ${({ $isGrey }) =>
    $isGrey &&
    css`
      opacity: 50%;
    `}
`;

export const GroupTypeSubHeader = styled(Flex)`
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 16px;
`;

export const GroupTypeSelectionRow = styled(Flex)`
  flex-direction: row;
`;

export const GroupTypeDescription = styled.div`
  padding-left: 35px;
  font-size: 14px;
  line-height: 21px;
`;
