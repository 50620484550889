import { fetchCustomFieldByIdQueryConfig } from 'components/CustomFieldModal/Contexts/DataContexts.constants';
import { ICustomField } from 'components/CustomFieldModal/CustomFieldModal.types';
import { useGQLQuery } from 'hooks';
import { FC, useContext, useEffect, useMemo } from 'react';
import { GQLAPIContextType } from 'types/common.types';
import { editCustomFieldFetchCustomFieldById } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchCustomFieldByIdDataContext = createGQLDataContext<ICustomField>(
  {} as ICustomField,
);

export const FetchCustomFieldByIdDataProvider: FC<{ customFieldId: number }> = ({
  children,
  customFieldId,
}) => {
  const queryConfig = useMemo(
    () => fetchCustomFieldByIdQueryConfig(customFieldId),
    [customFieldId],
  );

  const rest =
    useGQLQuery<editCustomFieldFetchCustomFieldById, ICustomField, { fetchCustomFieldId: number }>(
      queryConfig,
    );

  useEffect(() => {
    if (typeof customFieldId === 'number') rest.fetch();
  }, [customFieldId]); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue = useMemo(() => rest, [rest]);

  return (
    <FetchCustomFieldByIdDataContext.Provider value={contextValue}>
      {children}
    </FetchCustomFieldByIdDataContext.Provider>
  );
};

export const useFetchCustomFieldByIdDataContext = (): GQLAPIContextType<ICustomField> =>
  useContext(FetchCustomFieldByIdDataContext);
