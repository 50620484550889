import {
  MainNavigationContent,
  MainNavigationWrapper,
  OffsetWrapper,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { WebNavFooter } from 'components/BKJMainNavigation/src/Web/WebNavFooter';
import { WebNavHeader } from 'components/BKJMainNavigation/src/Web/WebNavHeader';
import { WebNavMenuItems } from 'components/BKJMainNavigation/src/Web/WebNavMenuItems';
import { AuthGuard } from 'guards/AuthGuard';
import { useClickOutside } from 'hooks';
import { usePathname } from 'hooks/usePathname';
import { FC, Fragment, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsUserB2bPendingSelector } from 'store/selectors/userSelectors/isUserB2bPendingSelector';
import { userIdSelector } from 'store/selectors/userSelectors/userIdSelector';

export const BKJMainNavigation: FC = () => {
  const pathname = usePathname();
  const isPendingB2B = useIsUserB2bPendingSelector();

  const ref = useRef<HTMLInputElement>(null);
  const disabled = useRef(false);
  const userId = useSelector(userIdSelector);

  const [isOpen, setOpen] = useState(false);

  const onMenuClosed = useCallback(() => setOpen(false), []);
  const onMenuToggled = useCallback(() => setOpen(!isOpen), [isOpen]);

  useClickOutside([ref && ref.current], onMenuClosed);

  if (pathname === '/pending') return <Fragment />;
  if (isPendingB2B) return <Fragment />;
  return (
    <AuthGuard>
      <OffsetWrapper />
      <MainNavigationWrapper $isOpen={isOpen} id="main-menu-wrapper" ref={ref} key={userId}>
        <MainNavigationContent>
          <WebNavHeader onMouseDown={onMenuToggled} isOpen={isOpen} menuIcon="HamburgerTablet" />
          <WebNavMenuItems disabled={disabled} isOpen={isOpen} onClose={onMenuClosed} />
        </MainNavigationContent>
        <WebNavFooter disabled={disabled} isOpen={isOpen} onClose={onMenuClosed} />
      </MainNavigationWrapper>
    </AuthGuard>
  );
};
