/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ADP_USER_INFO,
  ADP_CREDENTIALS,
  ADP_SUBSCRIPTION,
  ADP_EMPLOYER_INFO,
} from 'constants/endpoints';
import { ADP_REDIRECT_URI } from 'config';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';
import { toastService } from 'services/toastService';
import { CredentialsInfo } from 'store/reducers/adpSlice/index.constants';

const fetchUserInfo = async (code?: string, country?: string) => {
  const isCanada = country === 'CA';
  const URL =
    code && country
      ? `${ADP_USER_INFO}/${code}?redirect_uri=${ADP_REDIRECT_URI}/auth&is_canada=${isCanada}`
      : ADP_USER_INFO;
  try {
    const response = await fetch(URL, {
      method: 'GET',
      headers: createBookJaneRequestHeaders({}),
    });

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

const fetchCredentials = async () => {
  try {
    const URL = ADP_CREDENTIALS;
    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    return await get.json();
  } catch (error: any) {
    toastService.error(error);
    throw error;
  }
};

const fetchEmployerInfo = async (credentials: CredentialsInfo) => {
  try {
    const is_canada = credentials.country === 'CA';
    const get = await fetch(`${ADP_EMPLOYER_INFO}?is_canada=${is_canada}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await get.json();
    return { result, get };
  } catch (error: any) {
    toastService.error(error);
    throw error;
  }
};

const fetchSubscriptionStatus = async () => {
  try {
    const URL = ADP_SUBSCRIPTION;
    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await get.json();
    return { result, get };
  } catch (error: any) {
    toastService.error(error);
    throw error;
  }
};

export { fetchCredentials, fetchUserInfo, fetchSubscriptionStatus, fetchEmployerInfo };
