import { BKJButton, BKJPhoneNumberInput, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { BKJFormWrapper } from 'components/BKJFormWrapper';
import { Flex } from 'components/Flex';
import { SetupBanner } from 'components/SetupBanner/SetupBanner';
import {
  UserProfilePageDataProvider,
  useUserProfilePageDataContext,
} from 'pages/SettingsPage/UserProfilePage/UserProfilePage.context';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import {
  UserProfilePageFormProvider,
  useUserProfilePageFormContext,
} from 'pages/SettingsPage/UserProfilePage/UserProfilePage.form.context';
import {
  UserProfileHeader,
  UserProfilePageWrapper,
  UserProfileContentWrapper,
  InputLabelText,
} from 'pages/SettingsPage/UserProfilePage/UserProfilePage.styled';
import { MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  userProfilePage_me_SecondaryUser,
  userProfilePage_me_B2bCustomer,
} from 'types/graphql-types';
import { css } from 'styled-components';

const UserProfileView = () => {
  const { values, onChange, onSubmit, fieldErrors } = useUserProfilePageFormContext();
  const { data } = useUserProfilePageDataContext();

  const isSelectedLanguageEnglish = values.locale === 'en';
  const isSelectedLanguageFrench = values.locale === 'fr';

  const { t } = useTranslation();

  const showLanguageSelector = process.env.REACT_APP_WHITE_LABEL === 'petal';

  const adpIdentifier = useMemo(
    () =>
      (data?.me as userProfilePage_me_B2bCustomer | userProfilePage_me_SecondaryUser)
        ?.adpCustomIdentifier || '',
    [data?.me],
  );

  return (
    <UserProfilePageWrapper flexDirection="column" width="100%" padding="32px 40px">
      <UserProfileContentWrapper>
        <Flex flexDirection="column" flexGrow={1}>
          <UserProfileHeader data-testid="hdr-userprofile">
            {t('settings:USER_PROFILE')}
          </UserProfileHeader>
          <div>{t('settings:GENERAL_INFORMATION')}</div>
          {!!adpIdentifier && (
            <Flex flexGrow={1} padding="20px 0 10px 0" flexDirection="column">
              <InputLabelText>ADP Identifier ID</InputLabelText>
              <div>{adpIdentifier || 'Not Available'}</div>
            </Flex>
          )}

          <Flex padding="18px 0 0 0" width="100%">
            <BKJTextInput
              name="first_name"
              label={t('settings:FIRST_NAME')}
              onChange={onChange}
              value={values.first_name}
              error={fieldErrors.first_name}
            />
            <BKJTextInput
              name="last_name"
              label={t('settings:LAST_NAME')}
              value={values.last_name}
              error={fieldErrors.last_name}
              onChange={onChange}
              css={css`
                padding-left: 10px;
              `}
            />
          </Flex>

          <Flex padding="18px 0 0 0" width="50%">
            <BKJPhoneNumberInput
              name="phone"
              value={values.phone}
              onChange={onChange}
              label={t('settings:WORK_PHONE')}
              error={fieldErrors.phone}
              maxLength={10}
            />
          </Flex>

          <Flex padding={showLanguageSelector ? '18px 0 0 0' : '18px 0 18px 0'} width="50%">
            <BKJTextInput
              name="email"
              label={t('settings:WORK_EMAIL')}
              onChange={onChange}
              value={values.email}
              error={fieldErrors.email}
            />
          </Flex>

          {showLanguageSelector && (
            <Flex flexDirection="column" padding="18px 0 18px 0" width="50%">
              <InputLabelText>{t('auth:SELECT_LANGUAGE_PREFERENCE')}</InputLabelText>
              <BKJRadioInput
                name="locale"
                value="en"
                onChange={onChange}
                checked={isSelectedLanguageEnglish}
                label={t('ENGLISH')}
              />
              <BKJRadioInput
                name="locale"
                value="fr"
                onChange={onChange}
                checked={isSelectedLanguageFrench}
                label={t('FRENCH')}
              />
            </Flex>
          )}

          <BKJButton
            variant="GreenSolid"
            width="192px"
            disabled={false}
            onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            type="submit"
          >
            {t('auth:SAVE')}
          </BKJButton>
        </Flex>
      </UserProfileContentWrapper>
    </UserProfilePageWrapper>
  );
};

const UserProfileFormView = () => {
  const { data } = useUserProfilePageDataContext();
  if (!data) return null;
  return (
    <BKJFormWrapper>
      <UserProfilePageFormProvider>
        <SetupBanner />
        <UserProfileView />
      </UserProfilePageFormProvider>
    </BKJFormWrapper>
  );
};

export const UserProfilePage = () => {
  return (
    <UserProfilePageDataProvider>
      <UserProfileFormView />
    </UserProfilePageDataProvider>
  );
};
