export const MODAL_ROOT_ID = 'modal-root';
export const MODAL_BACKDROP_ID = 'modal-background';
export const CENTRE_MODAL_BACKDROP_ID = 'center-modal-background';
export const DROPDOWN_ROOT_ID = 'dropdown-root';
export const MAIN_MENU_WRAPPER_ID = 'main-menu-wrapper';
export const COMMUNICATION_PAGE_CONTAINER_ID = 'communication-page-container-id';
export const SCHEDULE_TAB_ROW_WRAPPER_ID = 'schedule-tab-row-wrapper';
export const SCHEDULE_TAB_RELATIVE_ANCHOR_ID = 'schedule-tab-relative-wrapper';
export const SCHEDULING_FILTER_SLIDE_DOWN_PANEL_WRAPPER_ID =
  'scheduling_filter_slide_down_panel_wrapper';
export const SCHEDULING_ALL_SHIFT_STATUSES_DROPDOWN_TOGGLER_ID =
  'SCHEDULING_ALL_SHIFT_STATUSES_DROPDOWN_TOGGLER_ID';
export const SCHEDULING_ALL_EMPLOYMENT_STATUS_DROPDOWN_TOGGLER_ID =
  'SCHEDULING_ALL_EMPLOYMENT_STATUS_DROPDOWN_TOGGLER_ID';
export const SCHEDULING_JANE_STAFF_TYPE_DROPDOWN_TOGGLER_ID =
  'SCHEDULING_JANE_STAFF_TYPE_DROPDOWN_TOGGLER_ID';
export const SCHEDULING_ALL_GROUPS_DROPDOWN_TOGGLER_ID =
  'SCHEDULING_ALL_GROUPS_DROPDOWN_TOGGLER_ID';
export const SCHEDULING_ALL_TIME_FRAME_STATUS_DROPDOWN_TOGGLER_ID =
  'SCHEDULING_ALL_TIME_FRAME_STATUS_DROPDOWN_TOGGLER_ID';
export const POSITION_TAB_INPUT = 'position_tab_input';
export const SCHEDULE_POPOVER = 'schedule_popover';
export const TOOL_TIP = 'tooltip';
export const DEBUG_WRAPPER = 'misc-modals';
export const PDF_VIEWER = 'pdf-view';
export const SCHEDULING_BUDGETING_PANEL_ID = 'scheduling_budgeting_panel';
