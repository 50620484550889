export function maskStringToPhoneString(string: string | null) {
  if (string) {
    switch (string.length) {
      case 11:
        return `(${string.substring(0, 3)}) ${string.substring(3, 6)}-${string.substring(
          6,
          10,
        )}-${string.substring(10, 11)}`;
      case 12:
        return `(${string.substring(0, 3)}) ${string.substring(3, 6)}-${string.substring(
          6,
          10,
        )}-${string.substring(10, 12)}`;
      default:
        return `(${string.substring(0, 3)}) ${string.substring(3, 6)}-${string.substring(6, 10)}`;
    }
  }

  return string;
}
