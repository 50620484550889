import styled, { css } from 'styled-components';
import { Flex } from 'components/Flex';
import { elementDimensions } from 'constants/elementDimensions';
import { FC } from 'react';
import { Grid } from 'components/Grid';
import { BKJButton, BKJHorizontalRule } from '@bookjane2/bookjane-design-library';
import { MarketPlaceLogoProps } from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.constants';
import { CompanyProfileIconImage } from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.styled';

export const SettingsPageViewTemplate = styled(Flex)<{ x: number; isPendingApproval: boolean }>`
  ${({ x, isPendingApproval }) => {
    return css`
      position: fixed;
      top: ${elementDimensions.DesktopPageHeaderHeight +
      (isPendingApproval ? elementDimensions.SetupBanner : 0)}px;
      right: 0;
      overflow-y: overlay;
      background-color: #f6f7fb;
      height: calc(
        100% -
          ${elementDimensions.DesktopPageHeaderHeight +
          (isPendingApproval ? elementDimensions.SetupBanner : 0)}px
      );
      @media (max-width: 768px) {
        top: 54px;
        left: ${x}px;
        right: unset;

        width: calc(100% - ${x}px);
      }

      ${x ? `width: calc(100% - ${elementDimensions.NavigationWidth}px - ${x}px);` : `width: auto;`}
    `;
  }}
`;

export const SettingsPageWrapper: FC = ({ children }) => {
  return (
    <Flex flexDirection="column" width="100%" padding="48px 126px">
      {children}
    </Flex>
  );
};

export const LogoWrapper: FC<MarketPlaceLogoProps> = ({ alt, src, size }) => (
  <CompanyProfileIconImage alt={alt} src={src} size={size} />
);

export const SettingsPageFlex = styled(Flex)`
  width: 100%;
`;

export const H4 = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 20px;
  line-height: 28px;
`;

export const H5 = styled.h4`
  ${({ theme }) => theme.fonts.PoppinsBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
`;

export const P = styled.span<{ bold?: boolean; error?: boolean }>`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.ErrorDefault};
    `};
  ${({ bold = false, theme }) => (bold ? theme.fonts.PoppinsTextBold : theme.fonts.PoppinsText)};
`;

export const BKJFormLabel = styled.label`
  display: block;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
`;

export const HR = styled(BKJHorizontalRule)`
  margin: 30px 0;
`;

export const SettingsContent = styled(Grid)`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    grid-column: 2/12;
  } ;
`;

export const Heading = styled.h3`
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.TextHighlight1};
  ${({ theme }) => theme.fonts.PoppinsBold};
  margin-bottom: 32px;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsText};
  max-width: 672px;
  margin-bottom: 24px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsTextLight};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const SettingsBody = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.White};
  width: 100%;
  overflow: auto;
  height: calc(
    100vh - ${elementDimensions.DesktopPageHeaderHeight + elementDimensions.TabNavigationHeight}px
  );
  padding: 40px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    height: calc(
      100vh - ${elementDimensions.MobilePageHeaderHeight + elementDimensions.TabNavigationHeight}px
    );
  }
`;

export const SettingsBodyWithoutTabNavigation = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.White};
  width: 100%;
  overflow: auto;
  padding: 40px 0;

  height: calc(100vh - ${`${elementDimensions.DesktopPageHeaderHeight}px`});

  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    height: calc(100vh - ${`${elementDimensions.MobilePageHeaderHeight}px`});
  }
`;

export const FormWrapper = styled(Grid)`
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    grid-column: 2/12;
  }
`;

export const AddNewButton = styled(BKJButton)`
  width: fit-content;
  padding: 44px 16px;
`;
