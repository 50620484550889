import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import i18next from 'i18next';
import {
  ISO8601Type,
  ISO8601TupleType,
  isISO8601Type,
  isISO8601Tuple,
  ShiftDateTimeRangeType,
  ShiftDateTimeType,
} from 'types/common.types';
import { DateTime } from 'luxon';

export function parseVerboseDate(
  arg: ISO8601TupleType,
  timeZone?: Nullable<string>,
): ShiftDateTimeRangeType;
export function parseVerboseDate(arg: ISO8601Type, timeZone?: Nullable<string>): ShiftDateTimeType;
export function parseVerboseDate(
  arg: ISO8601TupleType | ISO8601Type,
  timeZone?: Nullable<string>,
): ShiftDateTimeRangeType | ShiftDateTimeType | string {
  const dateTimeZone = timeZone || undefined;
  const date = isISO8601Tuple(arg) ? arg[0] : arg;

  const { dayOfWeek, month } = getLocalizedDateFormat(date, dateTimeZone);

  if (isISO8601Tuple(arg)) {
    return `${dayOfWeek}, ${month}, ${DateTime.fromISO(date)
      .setZone(dateTimeZone)
      .toFormat('d')} ${i18next.t('common:AT')} ${DateTime.fromISO(date)
      .setZone(dateTimeZone)
      .toFormat('hh:mm a')
      .toLowerCase()} - ${DateTime.fromISO(arg[1])
      .setZone(dateTimeZone)
      .toFormat('hh:mm a')
      .toLowerCase()}` as ShiftDateTimeRangeType;
  } else if (isISO8601Type(arg)) {
    return `${month}, ${DateTime.fromISO(arg)
      .setZone(dateTimeZone)
      .toFormat('d, yyyy')} at ${DateTime.fromISO(arg)
      .setZone(dateTimeZone)
      .toFormat('hh:mm a')
      .toLowerCase()}` as ShiftDateTimeType;
  } else {
    return '';
  }
}
