import styled from 'styled-components';
import { BKJButton } from '@bookjane2/bookjane-design-library';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledSelectDatesButton = styled(BKJButton)<{ ref: any }>`
  width: 220px;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-bottom: 28px;
    margin-top: 12px;
  }
`;
