import { IShiftFormCustomField } from 'components/ShiftModal/src/components/ShiftCustomFields/ShiftCustomFields.types';
import { SCHEDULING_PUBLISHED_SHIFT_DETAILS } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { DateTimeType } from 'requests/POST_createOrders.types';
import { ApiRequestConfigType } from 'requests/index.types';
import { OrderStatusEnum } from 'types/graphql-types';

export type UpdateOrderDetailsParams = {
  description?: string;
  shift_id: number;
  notification_method?: string;
  reaccept_or_decline_threshold?: number;
  start_date_time?: DateTimeType;
  end_date_time?: DateTimeType;
  unpaid_break_time?: string;
  isOnlyNotesUpdated?: boolean;
  custom_fields?: IShiftFormCustomField[];
  allow_overtime?: boolean;
  zero_hours_worked?: boolean;
  reason_category?: string;
  reason_message?: string;
  status: OrderStatusEnum;
  acceptedByAgency?: boolean;
};

type UpdateOrderRequestBodyType = {
  order: {
    description?: string;
    notification_method?: string;
    reaccept_or_decline_threshold?: number;
    start_date_time?: DateTimeType;
    end_date_time?: DateTimeType;
    unpaid_break_time?: string;
    custom_fields?: IShiftFormCustomField[];
    allow_overtime?: boolean;
    no_show?: boolean;
    reason?: { reason_category: string; reason_message?: string };
  };
};

export const API_PUT_updateOrderDetails = (
  params: UpdateOrderDetailsParams,
): ApiRequestConfigType => {
  const {
    description = '',
    shift_id,
    notification_method,
    reaccept_or_decline_threshold = 1,
    start_date_time,
    end_date_time,
    unpaid_break_time,
    isOnlyNotesUpdated = false,
    custom_fields,
    allow_overtime = false,
    zero_hours_worked = false,
    reason_category = '',
    reason_message = '',
    status = '',
  } = params;

  const isShowEditReason =
    status === OrderStatusEnum.in_progress || status === OrderStatusEnum.completed;

  const body: UpdateOrderRequestBodyType = {
    order: {
      description,
      notification_method,
      reaccept_or_decline_threshold,
      ...(!zero_hours_worked && { start_date_time }),
      ...(!zero_hours_worked && { end_date_time }),
      unpaid_break_time,
      custom_fields,
      allow_overtime,
      no_show: zero_hours_worked,
      ...(isShowEditReason && {
        reason: {
          reason_category,
          ...(reason_message && { reason_message }),
        },
      }),
    },
  };

  if (isOnlyNotesUpdated) {
    delete body.order.reaccept_or_decline_threshold;
    delete body.order.notification_method;
    delete body.order.start_date_time;
    delete body.order.end_date_time;
    delete body.order.unpaid_break_time;
  } else if (!notification_method) {
    delete body.order.reaccept_or_decline_threshold;
  } else if (notification_method !== 'reaccept_or_decline')
    delete body.order.reaccept_or_decline_threshold;

  return {
    __typename: 'LegacyAPI',
    url: SCHEDULING_PUBLISHED_SHIFT_DETAILS(shift_id.toString()),
    method: METHOD_PUT,
    body,
  };
};
