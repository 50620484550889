import { ActivityEventEnum } from './../types/graphql-types';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import {
  OrderStatusEnum,
  shiftModalOrderDetails,
  shiftModalOrderDetails_fetchOrder_location,
} from 'types/graphql-types';
import { formatFloatToText, formatHourToFloat } from 'utils';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { DateTime } from 'luxon';

export function getShiftModalShiftJaneName(data: shiftModalOrderDetails) {
  if (!data?.fetchOrder?.mainJane) return null;
  return `${data?.fetchOrder?.mainJane?.firstName} ${data?.fetchOrder?.mainJane?.lastName}`;
}

export function getShiftModalShiftDate(data: shiftModalOrderDetails) {
  const { timeZone } = getCurrentlySelectedLocation();
  const { dayOfWeek, month } = getLocalizedDateFormat(data?.fetchOrder?.startAt, timeZone);

  return `${dayOfWeek}, ${month}, ${DateTime.fromISO(data?.fetchOrder?.startAt)
    .setZone(timeZone)
    .toFormat('dd, yyyy')}`;
}

export function getShiftModalShiftTimeRange(data: shiftModalOrderDetails) {
  const timeFormat = 'hh:mm a';
  const { timeZone } = getCurrentlySelectedLocation();
  return `${DateTime.fromISO(data?.fetchOrder?.startAt)
    .setZone(timeZone)
    .toFormat(timeFormat)} - ${DateTime.fromISO(data?.fetchOrder?.endAt)
    .setZone(timeZone)
    .toFormat(timeFormat)}`;
}

export function getShiftModalShiftTotalDuration(data: shiftModalOrderDetails) {
  const totalDuration = data?.fetchOrder?.totalDuration ?? 0;
  const breakTime = data?.fetchOrder?.orderSchedule?.breakTime ?? 0;
  return `${formatFloatToText(totalDuration + formatHourToFloat(breakTime))}`;
}

export function getShiftModalScheduleId(data: shiftModalOrderDetails) {
  if (!data?.fetchOrder?.orderSchedule?.id) throw new Error('Schedule id cannot be null');
  return data?.fetchOrder?.orderSchedule?.id;
}

export function getCancellationReason(data: shiftModalOrderDetails) {
  if (data.fetchOrder?.status.value !== OrderStatusEnum.cancelled) return '';
  return data.fetchOrder.activities.filter(
    (activity) => activity.event.name === ActivityEventEnum.cancelled,
  )[0].message;
}

export const formatLocation = (location: shiftModalOrderDetails_fetchOrder_location) => {
  const { streetNumber, street, city, state, zipCode } = location;
  return streetNumber + ' ' + street + ' ' + city + ' ' + state + ' ' + zipCode;
};
