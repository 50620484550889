import {
  IMappedPositionCode,
  IMappedSchedulingSummary,
  ISchedulingDetailsForPosition,
  ISchedulingSummaryResponse,
} from 'mappers/scheduling/schedule.rest.types';

function GET_schedulingSummaryMapper(apiResponseData = {}) {
  const data = { ...apiResponseData } as IMappedSchedulingSummary;
  const {
    scheduling_summary: { summary_by_position_code },
  } = apiResponseData as ISchedulingSummaryResponse;

  data.scheduling_summary.summary_by_position = [
    ...summary_by_position_code,
  ] as ISchedulingDetailsForPosition[];

  data.scheduling_summary.summary_by_position_code = summary_by_position_code.reduce(
    (obj, code) => {
      return {
        ...obj,
        [code.position_code]: code,
      };
    },
    {},
  ) as IMappedPositionCode[];

  return data;
}

export default GET_schedulingSummaryMapper;
