import { DateTime, Interval } from 'luxon';
import { IDayCellConfig, IGetDayCellsArgs } from './MonthView.types';
import {
  getOneMonthRangeFromDate,
  getFortyTwoDaysArrayFromDate,
  getFirstDayOfMonthFromDate,
  getOneWeekRangeFromDate,
  getSingleDateRangeFromDate,
} from '../../../../../BKJDatePicker.utils';
import {
  isArray,
  isISO8601Tuple,
  isISO8601Type,
  ISO8601TupleType,
  ISO8601Type,
} from 'types/common.types';

const getMonthRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  hoveredDate,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isISO8601Tuple(value)) {
    throw new Error('value of Month Range Calendar must be of type ISO8601TupleType');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const _value = getOneMonthRangeFromDate(day);
    const handleDayClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };
    return {
      label: DateTime.fromISO(_value[0]).get('day'),
      onClick: handleDayClick,
      isSelected:
        DateTime.fromISO(value[0]).hasSame(DateTime.fromISO(day), 'day') ||
        DateTime.fromISO(value[1]).hasSame(DateTime.fromISO(day), 'day'),
      isHoveredOnCalendar: !!hoveredDate,
      isHovered: hoveredDate === _value[0],
      isDateInHoverRange:
        hoveredDate.length > 0 &&
        Interval.fromDateTimes(
          DateTime.fromISO(_value[0]),
          DateTime.fromISO(getOneMonthRangeFromDate(hoveredDate)[1]),
        ).contains(DateTime.fromISO(hoveredDate)),
      isBetweenSelectedRange: Interval.fromDateTimes(
        DateTime.fromISO(value[0]),
        DateTime.fromISO(value[1]),
      ).contains(DateTime.fromISO(day)),
      isDateToday: DateTime.now().hasSame(DateTime.fromISO(day), 'day'),
      isDateInViewMonth: DateTime.fromISO(day).hasSame(
        DateTime.fromISO(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])),
        'month',
      ),
      id: day,
    };
  });
};

const getWeekRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  hoveredDate,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isISO8601Tuple(value)) {
    throw new Error('value of Week Range Calendar must be of type ISO8601TupleType');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const _value = getOneWeekRangeFromDate(day);
    const handleDayClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };
    return {
      label: DateTime.fromISO(_value[0]).day,
      onClick: handleDayClick,
      isSelected:
        DateTime.fromISO(value[0]).hasSame(DateTime.fromISO(day), 'day') ||
        DateTime.fromISO(value[1]).hasSame(DateTime.fromISO(day), 'day'),
      isHoveredOnCalendar: !!hoveredDate,
      isHovered: hoveredDate === _value[0],
      isDateInHoverRange:
        hoveredDate.length > 0 &&
        Interval.fromDateTimes(
          DateTime.fromISO(hoveredDate),
          DateTime.fromISO(getOneWeekRangeFromDate(hoveredDate)[1]),
        ).contains(DateTime.fromISO(_value[0])),
      isBetweenSelectedRange: Interval.fromDateTimes(
        DateTime.fromISO(value[0]),
        DateTime.fromISO(value[1]),
      ).contains(DateTime.fromISO(day)),
      isDateToday: DateTime.now().hasSame(DateTime.fromISO(day), 'day'),
      isDateInViewMonth: DateTime.fromISO(day).hasSame(
        DateTime.fromISO(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])),
        'month',
      ),
      id: day,
    };
  });
};

const getSingleDateRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isISO8601Tuple(value)) {
    throw new Error('value of Single Date Range Calendar must be of type ISO8601TupleType');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const _value = getSingleDateRangeFromDate(day);
    const handleDayClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };
    return {
      label: DateTime.fromISO(_value[0]).day,
      onClick: handleDayClick,
      isSelected: DateTime.fromISO(value[0]).hasSame(DateTime.fromISO(day), 'day'),
      isDateToday: DateTime.now().hasSame(DateTime.fromISO(day), 'day'),
      isDateInViewMonth: DateTime.fromISO(day).hasSame(
        DateTime.fromISO(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])),
        'month',
      ),
      id: day,
    };
  });
};

const getSingleDateDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
  disabledTo,
  disabledFrom,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (value && !isISO8601Type(value)) {
    throw new Error('value of Single Date Calendar must be of type ISO8601Type');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    let isDisabled = false;
    if (disabledTo) {
      isDisabled = DateTime.fromISO(day) < DateTime.fromISO(disabledTo);
    }
    if (disabledFrom) {
      isDisabled = isDisabled || DateTime.fromISO(day) > DateTime.fromISO(disabledFrom);
    }
    const handleDayClick = (): void => {
      if (isDisabled) return;
      const e = {
        target: {
          name,
          value: day,
        },
      };
      return onChange(e);
    };
    return {
      label: DateTime.fromISO(day).day,
      name: name,
      value: value,
      onClick: handleDayClick,
      isSelected: DateTime.fromISO(value).hasSame(DateTime.fromISO(day), 'day'),
      isDateToday: DateTime.now().hasSame(DateTime.fromISO(day), 'day'),
      isDisabled: isDisabled,
      isDateInViewMonth:
        DateTime.fromISO(day).month ===
        DateTime.fromISO(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month,
      id: day,
    };
  });
};

const getMultipleDatesDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isArray(value)) {
    throw new Error('value of Multiple Dates Calendar must be an array of ISO8601Type dates');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const isDisabled = DateTime.now().startOf('day') > DateTime.fromISO(day);
    const handleDayClick = (): void => {
      if (isDisabled) return;
      let valueArray = Array.from(value);
      const valueIndex = valueArray.indexOf(day);
      if (valueIndex > -1) {
        valueArray.splice(valueIndex, 1);
      } else {
        valueArray.push(day);
      }
      const e = {
        target: {
          name,
          value: valueArray,
        },
      };
      return onChange(e);
    };
    return {
      label: DateTime.fromISO(day).day,
      onClick: handleDayClick,
      isSelected: value.some((dateValue: ISO8601Type) =>
        DateTime.fromISO(dateValue).hasSame(DateTime.fromISO(day), 'day'),
      ),
      isDateToday: DateTime.now().hasSame(DateTime.fromISO(day), 'day'),
      isDisabled: isDisabled,
      isDateInViewMonth: DateTime.fromISO(day).hasSame(
        DateTime.fromISO(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])),
        'month',
      ),
      id: day,
    };
  });
};

const getCustomRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isArray(value)) {
    throw new Error('value of Multiple Dates Calendar must be an array of ISO8601Type dates');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const handleDayClick = (): void => {
      let valueArray = Array.from(value);
      valueArray.push(day);
      const e = {
        target: {
          name,
          value: [day, day],
        },
      };
      return onChange(e);
    };
    return {
      label: DateTime.fromISO(day).day,
      onClick: handleDayClick,
      isSelected: value.some((dateValue: ISO8601Type) =>
        DateTime.fromISO(dateValue).hasSame(DateTime.fromISO(day), 'day'),
      ),
      isDateToday: DateTime.now().hasSame(DateTime.fromISO(day), 'day'),
      isDisabled: false,
      isDateInViewMonth:
        DateTime.fromISO(day).month ===
        DateTime.fromISO(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month,
      id: day,
    };
  });
};

export const getDayCellsConfigArray = (args: IGetDayCellsArgs): Array<IDayCellConfig> => {
  const { type } = args;
  switch (type) {
    case 'MonthRange': {
      return getMonthRangeDayCells(args);
    }
    case 'WeekRange': {
      return getWeekRangeDayCells(args);
    }
    case 'SingleDateRange': {
      return getSingleDateRangeDayCells(args);
    }
    case 'SingleDate': {
      return getSingleDateDayCells(args);
    }
    case 'MultipleDates': {
      return getMultipleDatesDayCells(args);
    }
    case 'CustomDateRange': {
      return getCustomRangeDayCells(args);
    }
    default:
      return [];
  }
};

export const getYearInView = (dateRangeInMonthView: ISO8601TupleType): number =>
  DateTime.fromISO(dateRangeInMonthView[0]).year;
