import { BKJForm } from 'components/FormWrapper';
import { H3, P } from 'components/Typography';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Flex } from 'components/Flex';
import {
  BKJFieldWrapper,
  ShiftsName,
} from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPage.styled';
import { formatTimeWithAP } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.types';
import { useTimeFrameLocationSettingsPageDataContext } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.data.context';

export const TimeFrameLocationSettingsPageRead: FC = () => {
  const { data } = useTimeFrameLocationSettingsPageDataContext();
  const { t } = useTranslation();
  const formattedEveningTime = formatTimeWithAP(`${data?.eveningStartsAt}`);
  const formattedNightTime = formatTimeWithAP(`${data?.nightStartsAt}`);
  const formattedMorningTime = formatTimeWithAP(`${data?.morningStartsAt}`);
  return (
    <Fragment>
      <BKJForm width="672px" maxWidth="672px" gap="22px">
        <H3>{t('location_settings:TIME_FRAME')}</H3>
        <P>{t('location_settings:TIME_FRAME_DETAILS')}</P>
        <BKJFieldWrapper>
          <Flex flexDirection="column" gap="60px" margin="38px 0 0">
            <ShiftsName>{t('location_settings:DAY_SHIFTS')}</ShiftsName>
            <ShiftsName>{t('location_settings:AFTERNOON_SHIFTS')}</ShiftsName>
            <ShiftsName>{t('location_settings:OVERNIGHT_SHIFTS')}</ShiftsName>
          </Flex>

          <Flex flexDirection="column" gap="40px" margin="20px 0 0">
            <Flex gap="16px">
              <Flex flexDirection="column" gap="10px">
                <P>{t('location_settings:START_OF_RANGE')}</P>
                <Flex flexDirection="column" width="126px">
                  {formattedMorningTime}
                </Flex>
              </Flex>
              <Flex gap="50px">
                <Flex flexDirection="column" justifyContent="flex-end">
                  -
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between">
                  <P>{t('location_settings:END_OF_RANGE')}</P>
                  <span>{formattedEveningTime}</span>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap="16px">
              <Flex flexDirection="column" gap="10px">
                <P>{t('location_settings:START_OF_RANGE')}</P>
                <Flex flexDirection="column" width="126px">
                  {formattedEveningTime}
                </Flex>
              </Flex>
              <Flex gap="50px">
                <Flex flexDirection="column" justifyContent="flex-end">
                  -
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between">
                  <P>{t('location_settings:END_OF_RANGE')}</P>
                  <span>{formattedNightTime}</span>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap="16px">
              <Flex flexDirection="column" gap="10px">
                <P>{t('location_settings:START_OF_RANGE')}</P>
                <Flex flexDirection="column" width="126px">
                  {formattedNightTime}
                </Flex>
              </Flex>
              <Flex gap="50px">
                <Flex flexDirection="column" justifyContent="flex-end">
                  -
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between">
                  <P>{t('location_settings:END_OF_RANGE')}</P>
                  <span>{formattedMorningTime}</span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </BKJFieldWrapper>
      </BKJForm>
    </Fragment>
  );
};
