import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { isUserObjectAgencyType, isUserObjectB2bType } from 'types/common.types';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

const isUserAgencySelector = createSelector([getUser], (user) => {
  if (!user) return false;
  if (isUserObjectB2bType(user))
    return user.user_type !== 'B2bCustomer' && user.type !== 'SecondaryUser';
  if (isUserObjectAgencyType(user)) return ['AgencyOwner', 'AgencyManager'].includes(user.type);
  return false;
});

export default isUserAgencySelector;
