import { UseDimensionsRefType } from 'hooks/useDimensionsRef/useDimensionRef.types';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useDimensionsRef<T = Element>(): UseDimensionsRefType<T> {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const ref: MutableRefObject<T> = useRef(null as unknown as T);
  useEffect(() => {
    const currentRef = ref.current as unknown as Element;
    function handleObservableResizeEvent(nodeList: ResizeObserverEntry[]) {
      const _height =
        currentRef.getBoundingClientRect()?.height || nodeList[0].contentRect.height || height;
      const _width =
        currentRef.getBoundingClientRect()?.width || nodeList[0].contentRect.width || width;
      if (_height !== height) setHeight(_height);
      if (_width !== width) setWidth(_width);
    }
    const resizeObserver = new ResizeObserver(handleObservableResizeEvent);
    if (currentRef) resizeObserver.observe(currentRef);
    return () => {
      if (currentRef) resizeObserver.unobserve(currentRef);
    };
  }, []); // eslint-disable-line
  return [ref, { height, width }];
}
