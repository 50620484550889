import { AuthPageContentWrapper } from 'components/AuthPageContentWrapper';
import { OffsetElementType } from 'components/AuthPageContentWrapper/AuthPageContextWrapper.types';
import { TabNavigation } from 'components/TabNavigation';
import { ITabItem } from 'components/TabNavigation/TabNavigation.types';
import { FeatureGuard } from 'guards/FeatureGuard';
import { useFeatureSelector } from 'guards/useFeatureSelector';
import { UserGuard } from 'guards/UserGuard';
import { CUSTOMER_PERMISSIONS } from 'guards/UserGuard.types';
import { usePathname } from 'hooks/usePathname';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { TimeFrameLocationSettingsPage } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPage';
import { GroupsPage } from 'pages/GroupsPage';
import { AvailabilityLocationSettingsPage } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPage';
import { ShiftSwapLocationSettingsPage } from 'pages/LocationSettingsPage/ShiftSwapLocationSettingsPage';
import { GeneralLocationSettingsPage } from 'pages/LocationSettingsPage/GeneralLocationSettingsPage';
import { SettingsPageWrapper } from 'pages/SettingsPage/SettingsPage.styled';
import { ShiftDistributionPage } from 'pages/ShiftDistributionPage';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import {
  isSchedulingFeatureEnabledSelector,
  isGroupsFeatureEnabledSelector,
} from 'store/selectors/featureFlagSelectors';
export interface SettingsLocationPageMatchParams {
  locationId: string;
}

const offsetElements: OffsetElementType[] = ['DesktopPageHeaderHeight', 'TabNavigationHeight'];

export const LocationSettingsPage: FC = () => {
  const { locationId } = useParams<SettingsLocationPageMatchParams>();
  const { path } = useRouteMatch();
  const activeTab = usePathname();

  const isAvailabilityFeatureEnabled = useFeatureSelector('FEATURE_TYPE_AVAILABILITY');
  const isShiftDistributionFeatureEnabled = useFeatureSelector('FEATURE_TYPE_VACCINATION');
  const isShiftSwapFeatureEnabled = useFeatureSelector('FEATURE_TYPE_SHIFT_SWAP');
  const isGroupsFeatureEnabled = useSelector(
    isGroupsFeatureEnabledSelector(Number.parseInt(locationId)),
  );
  const isSchedulingFeatureEnabled = useSelector(isSchedulingFeatureEnabledSelector);

  const isGroupsTabSelected = activeTab.startsWith(`/settings/locations/${locationId}/groups`);
  return (
    <Fragment>
      <TabNavigation
        activeTab={activeTab}
        tabs={useUserTypeSwitch({
          Agency: [
            {
              label: 'GENERAL',
              path: `/settings/locations/${locationId}/general`,
            },
          ],
          B2B: getB2BLocationSettingsTabs({
            locationId,
            isShiftDistributionFeatureEnabled,
            isAvailabilityFeatureEnabled,
            isGroupsFeatureEnabled,
            isShiftSwapFeatureEnabled,
            isSchedulingFeatureEnabled,
          }),
        })}
      />
      <AuthPageContentWrapper offsetElements={offsetElements}>
        {isGroupsFeatureEnabled && isGroupsTabSelected ? (
          <Switch>
            <Route path={`${path}/groups`} component={GroupsPage} />
          </Switch>
        ) : (
          <SettingsPageWrapper>
            <Switch>
              <Route path={`${path}/general`} component={GeneralLocationSettingsPage} />
              {/* // TODO: When Company Profile Is Ready Set Up This Page
            <Route
              path={`${path}/profile`}
              component={() => {
                return <Fragment>profile</Fragment>;
              }}
            /> */}

              <UserGuard userType={CUSTOMER_PERMISSIONS}>
                <Fragment>
                  <FeatureGuard type="FEATURE_TYPE_AVAILABILITY">
                    <Route
                      path={`${path}/availability`}
                      component={AvailabilityLocationSettingsPage}
                    />
                  </FeatureGuard>
                  <FeatureGuard type="FEATURE_TYPE_VACCINATION">
                    <Route path={`${path}/shift-distribution`} component={ShiftDistributionPage} />
                  </FeatureGuard>
                  <FeatureGuard type="FEATURE_TYPE_SHIFT_SWAP">
                    <Route path={`${path}/shift-swap`} component={ShiftSwapLocationSettingsPage} />
                  </FeatureGuard>
                  <Route path={`${path}/time-frames`} component={TimeFrameLocationSettingsPage} />
                </Fragment>
              </UserGuard>
            </Switch>
          </SettingsPageWrapper>
        )}
      </AuthPageContentWrapper>
    </Fragment>
  );
};

function getB2BLocationSettingsTabs({
  locationId,
  isShiftDistributionFeatureEnabled,
  isAvailabilityFeatureEnabled,
  isGroupsFeatureEnabled,
  isShiftSwapFeatureEnabled,
  isSchedulingFeatureEnabled,
}: {
  locationId: string;
  isShiftDistributionFeatureEnabled: boolean;
  isAvailabilityFeatureEnabled: boolean;
  isGroupsFeatureEnabled: boolean;
  isShiftSwapFeatureEnabled: boolean;
  isSchedulingFeatureEnabled: boolean;
}): ITabItem[] {
  const tabs: ITabItem[] = [
    {
      label: 'GENERAL',
      path: `/settings/locations/${locationId}/general`,
    },
    // TODO: When Company Profile Is Ready Set Up This Page
    // {
    //   label: 'Facility Profile',
    //   path: `/settings/locations/${locationId}/profile`,
    // },
  ];
  if (locationId !== 'new') {
    if (isShiftDistributionFeatureEnabled)
      tabs.push({
        label: 'SHIFT_DISTRIBUTION',
        path: `/settings/locations/${locationId}/shift-distribution`,
      });
    if (isAvailabilityFeatureEnabled)
      tabs.push({
        label: 'AVAILABILITY',
        path: `/settings/locations/${locationId}/availability`,
        activePaths: [
          `/settings/locations/${locationId}/availability`,
          `/settings/locations/${locationId}/availability/edit`,
        ],
      });
    if (isGroupsFeatureEnabled)
      tabs.push({
        label: 'GROUPS',
        path: `/settings/locations/${locationId}/groups`,
        activePaths: [`/settings/locations/${locationId}/groups`],
      });
    if (isShiftSwapFeatureEnabled)
      tabs.push({
        label: 'SHIFT_SWAP',
        path: `/settings/locations/${locationId}/shift-swap`,
        activePaths: [
          `/settings/locations/${locationId}/shift-swap`,
          `/settings/locations/${locationId}/shift-swap/edit`,
        ],
      });
    if (isSchedulingFeatureEnabled)
      tabs.push({
        label: 'TIME_FRAMES',
        path: `/settings/locations/${locationId}/time-frames`,
        activePaths: [`/settings/locations/${locationId}/time-frames`],
      });
  }
  return tabs;
}
