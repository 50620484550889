/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs';
import { ADP_LOCATION, ADP_JANES, ADP_EMPLOYEES } from 'constants/endpoints';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';
// import { toastService } from 'services/toastService';
import {
  AdpEmployer,
  Employee,
  FetchWorkersArgs,
  FetchWorkersResponse,
  PostADPEmployeeData,
} from 'store/reducers/adpSlice/index.constants';
interface LocationRequestBody {
  location: {
    location_id: number[];
    adp_employer_id?: number;
    upload_frequency: string;
  };
}

const fetchLocations = async () => {
  try {
    const URL = ADP_LOCATION;
    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await get.json();
    return { result, get };
  } catch (error) {
    // toastService.error(error);
    throw error;
  }
};

const fetchJanes = async (
  selectedLocations: number[],
  direction?: string,
  sortBy?: string,
): Promise<any> => {
  try {
    const queryFilters: any = {
      filter: {
        with_community: selectedLocations.length > 0 ? selectedLocations : ['N'],
      },
    };
    if (sortBy) queryFilters.sort = sortBy;
    if (direction !== undefined) queryFilters.direction = direction;

    const QUERY = `${qs.stringify(queryFilters, {
      arrayFormat: 'brackets',
      // parseArrays: false,
      encodeValuesOnly: true,
    })}`;

    const URL = `${ADP_JANES}?${QUERY}`;

    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });

    const result = await get.json();
    return result as Employee[];
  } catch (error) {
    // toastService.error(error);
    throw error;
  }
};

const fetchADPWorkers = async ({
  page,
  per_page,
  matched,
  userInfo,
  ADPSearch,
  direction,
  sortBy,
}: FetchWorkersArgs): Promise<FetchWorkersResponse> => {
  try {
    const queryFilters: any = {};
    if (sortBy) queryFilters.sort = sortBy || 'name';
    if (direction !== undefined) queryFilters.direction = direction;
    if (matched !== null) queryFilters.matched = matched;
    if (ADPSearch) queryFilters.search = ADPSearch;
    queryFilters.page = page;
    queryFilters.per_page = per_page;

    const QUERY = `${qs.stringify(queryFilters, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    })}`;

    const URL =
      matched === true
        ? `${ADP_EMPLOYEES}/${userInfo.id}?${QUERY}`
        : `${ADP_EMPLOYEES}?adp_employer_id=${userInfo.id}&${QUERY}`;

    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });

    const result = await get.json();
    return result as FetchWorkersResponse;
  } catch (error) {
    // toastService.error(error);
    throw error;
  }
};

const fetchADPWorkersCount = async (userInfo: AdpEmployer): Promise<FetchWorkersResponse> => {
  try {
    const URL = `${ADP_EMPLOYEES}?adp_employer_id=${userInfo.id}&per_page=1`;
    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await get.json();
    return result as FetchWorkersResponse;
  } catch (error) {
    // toastService.error(error);
    throw error;
  }
};
const isUserInfoEmpty = (userInfo: AdpEmployer): boolean => {
  return !Object.values(userInfo).some((value) => value !== '' && value !== 0 && value !== false);
};

const postLocations = async (selectedMembers: number[], userInfo?: AdpEmployer): Promise<any> => {
  const requestBody: LocationRequestBody = {
    location: {
      location_id: selectedMembers,
      upload_frequency: '1xWeek',
      ...(userInfo && !isUserInfoEmpty(userInfo) && { adp_employer_id: userInfo.id }),
    },
  };

  try {
    const response = await fetch(`${ADP_LOCATION}`, {
      method: 'POST',
      headers: createBookJaneRequestHeaders({}),
      body: JSON.stringify(requestBody),
    });

    return await response.json();
  } catch (error) {
    console.error('Error posting locations:', error);
    throw error;
  }
};

const postSyncEmployees = async (
  data: PostADPEmployeeData[],
  userInfo: AdpEmployer,
): Promise<any> => {
  try {
    const URL = `${ADP_EMPLOYEES}?adp_employer_id=${userInfo.id}`;
    const post = await fetch(`${URL}`, {
      method: 'POST',
      headers: createBookJaneRequestHeaders({}),
      body: JSON.stringify({
        adp_employees: data,
      }),
    });
    if (post.status !== 200) {
      const text = await post.json();
      throw new Error(text.message);
    }
    return await post.json();
  } catch (error) {
    // toastService.error(error);
    throw error;
  }
};

export {
  fetchLocations,
  fetchJanes,
  fetchADPWorkers,
  fetchADPWorkersCount,
  postLocations,
  postSyncEmployees,
};
