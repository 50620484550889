import { ICustomFieldTypeSelectOption } from 'components/CustomFieldTypeSelectInput/CustomFieldTypeSelectInput.types';
import i18next from 'i18next';

export const CustomFieldTypeSelectOptions = (): ICustomFieldTypeSelectOption[] => [
  {
    key: 'Single-Line Text',
    value: 'Single-Line Text',
    label: i18next.t('settings:SINGLE-LINE TEXT'),
  },
  {
    key: 'Paragraph Text',
    value: 'Paragraph Text',
    label: i18next.t('settings:PARAGRAPH TEXT'),
  },
  {
    key: 'Single-Select Dropdown',
    value: 'Single-Select Dropdown',
    label: i18next.t('settings:SINGLE-SELECT DROPDOWN'),
  },
];
