import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import {
  MemberComponentWrapper,
  MemberColumn,
  MemberRow,
  MemberTable,
  NoResultsWrapper,
} from 'pages/SettingsPage/modals/GroupsModal/views/static/MemberTable.component.styled';
import { IconButton } from 'components/IconButton';
import { FC, useMemo } from 'react';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useGroupsModalFormContext } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.form.context';
import { fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane } from 'types/graphql-types';
import { useTranslation } from 'hooks/useTranslation';

export interface IMemberTableComponentProps {
  readOnly?: boolean;
  memberFilter?: string;
}

function applyStringTransformations(str: string) {
  return str.toLocaleLowerCase().replace(/\s+/g, '');
}

function filterMembers(
  members: fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane[],
  memberFilter?: string,
): fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane[] {
  return members.filter((member) => {
    if (memberFilter) {
      return applyStringTransformations(member.firstName + member.lastName).includes(
        applyStringTransformations(memberFilter),
      );
    }
    return true;
  });
}

export const MemberTableComponent: FC<IMemberTableComponentProps> = ({
  readOnly,
  memberFilter,
}: IMemberTableComponentProps) => {
  const { formMembers: members, deleteMember } = useGroupsModalFormContext();
  const filteredMembers = useMemo(
    () => filterMembers(members, memberFilter),
    [memberFilter, members],
  );
  const { t } = useTranslation();
  return (
    <MemberComponentWrapper>
      {filteredMembers.length === 0 && readOnly && (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <NoResultsWrapper>{t('groups:NO_RESULTS')}</NoResultsWrapper>
        </Flex>
      )}
      {filteredMembers.length > 0 && (
        <MemberTable>
          {filteredMembers.map((member, i) => {
            return (
              <MemberRow key={i}>
                <MemberColumn>
                  <BKJAvatar
                    width="30px"
                    height="30px"
                    fallbackAvatarColor="Grey3"
                    src={member?.avatarUrls?.small}
                    showPendingSignUp={member?.pendingSignup}
                  />
                  <Flex
                    alignItems="center"
                    width="80%"
                  >{`${member.firstName} ${member.lastName}`}</Flex>
                  <Flex alignItems="flex-end">
                    {!readOnly && (
                      <IconButton
                        iconName="CloseBold"
                        onClick={(e: ChangeEventType) => {
                          e.preventDefault();
                          deleteMember(member.id);
                        }}
                      />
                    )}
                  </Flex>
                </MemberColumn>
              </MemberRow>
            );
          })}
        </MemberTable>
      )}
    </MemberComponentWrapper>
  );
};
