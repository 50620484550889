import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import {
  TeamMemberHeader,
  TeamMemberAvatarWrapper,
  TableCellValue,
  ShiftStartDate,
  ShiftSummaryDetailContainer,
} from '../ShiftSummaryView.styled';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import {
  prettyShiftDate,
  prettyStartTimeAndEndTime,
  prettyUnpaidBreakTime,
} from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { transformObjectValuesArrayToSingle } from 'utils';
import {
  calculateShiftDurationInMinutes,
  formatInterval,
  getShiftStartTimeAndEndTime,
} from 'utils/time';
import { useTranslation } from 'hooks/useTranslation';
import { UserGuard } from 'guards/UserGuard';
import { Flex } from 'components/Flex';
import { MobileShiftSummaryJaneRow } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryJaneRow';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useCreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { getBiddingPeriod } from 'pages/CreateShiftsPage/CreateShiftsPage.constants';
import { OpenShiftPolicyEnum } from 'types/graphql-types';

export const MobileShiftSummaryDetailRow: FC<{ shift: ICreateShift }> = ({ shift }) => {
  const { t, i18n } = useTranslation();
  const {
    startDate,
    startAt,
    endAt,
    unpaidBreakTime,
    request_janes,
    quantity,
    position_id,
    openShiftPolicy,
  } = transformObjectValuesArrayToSingle(shift.values);

  const quantityInt = parseInt(quantity);

  const request_janes_split: string[] =
    request_janes?.split(',').filter((id: string) => (id as string) !== '') || [];

  const { biddingPeriods } = useCreateShiftsPageContext();
  const [start_time] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
    startDate,
  });
  const calculatedBiddingPeriod = getBiddingPeriod(start_time, biddingPeriods);

  const isShiftBiddingFeatureEnabled = useSelector(isShiftBiddingFeatureEnabledSelector);
  const isShiftBiddingRequired =
    openShiftPolicy === OpenShiftPolicyEnum.bidding_required && isShiftBiddingFeatureEnabled;

  const ShiftDetailCells = () => (
    <Flex flexDirection="column">
      <TableCellValue>{prettyStartTimeAndEndTime([startAt, endAt])}</TableCellValue>
      <TableCellValue>
        {t('create_shifts:BREAK_TIME')} : {prettyUnpaidBreakTime(unpaidBreakTime)}
      </TableCellValue>
      <TableCellValue>
        {t('create_shifts:PAID_DURATION')} :{' '}
        {formatInterval(
          calculateShiftDurationInMinutes({
            startDate,
            startAt,
            endAt,
            unpaidBreakTime,
            isPaidDuration: true,
          }),
        )}
      </TableCellValue>
    </Flex>
  );

  const RequestedJanesShiftRows = request_janes_split.map((janeId, index) => (
    <ShiftSummaryDetailContainer key={janeId} flexDirection="column" padding="12px 20px">
      {index === 0 && <ShiftStartDate>{prettyShiftDate(startDate, i18n.language)}</ShiftStartDate>}
      <ShiftDetailCells />
      <MobileShiftSummaryJaneRow positionId={position_id} janeId={janeId} />
    </ShiftSummaryDetailContainer>
  ));

  const OpenShiftRows = [...Array(quantityInt - request_janes_split.length)].map((_, index) => (
    <ShiftSummaryDetailContainer key={index} flexDirection="column" padding="12px 20px">
      {index === 0 && !request_janes_split.length && (
        <ShiftStartDate>{prettyShiftDate(startDate, i18n.language)}</ShiftStartDate>
      )}
      <ShiftDetailCells />
      {isShiftBiddingRequired && (
        <TableCellValue>{`${t(
          'create_shifts:BIDDING_PERIOD',
        )} : ${calculatedBiddingPeriod}`}</TableCellValue>
      )}
      <TableCellValue>
        <Flex alignItems="center" gap="13px">
          <TeamMemberAvatarWrapper>
            <BKJAvatar width="30px" height="30px" fallbackAvatarColor="SecondaryPendingDefault" />
          </TeamMemberAvatarWrapper>
          <TeamMemberHeader>{t('schedule:OPEN_SHIFT')}</TeamMemberHeader>
        </Flex>
      </TableCellValue>
      <UserGuard userType="USER_TYPE_ESSENTIAL">
        <TableCellValue>-</TableCellValue>
      </UserGuard>
    </ShiftSummaryDetailContainer>
  ));

  return (
    <Fragment>
      {RequestedJanesShiftRows}
      {OpenShiftRows}
    </Fragment>
  );
};
