import { BKJDropdownMenu, MobileBKJDropdownMenu } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

export const DesktopShiftActionDropdownMenu = styled(BKJDropdownMenu)`
  ${({ variant }) =>
    variant === 'PurpleText' &&
    css`
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
    `}
`;

export const MobileShiftActionDropdownMenu = styled(MobileBKJDropdownMenu)`
  ${({ variant }) =>
    variant === 'PurpleText' &&
    css`
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
    `}
`;
