import { BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useAssignToAgencyTeamMemberFormContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import {
  SendToAgenciesText,
  SendToAgenciesViewTitle,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment } from 'react';

export const TeamMemberUnavailableView: FC = () => {
  const { t } = useTranslation();
  const { onReset } = useAssignToAgencyTeamMemberFormContext();

  return (
    <Fragment>
      <Flex flexDirection="column" padding="40px 32px 0px 32px" height="100%">
        <SendToAgenciesViewTitle>{t('TEAM_MEMBER_UNAVAILABLE_VIEW_TITLE')}</SendToAgenciesViewTitle>
        <Flex flexDirection="column" gap="18px">
          <SendToAgenciesText>{t('TEAM_MEMBER_UNAVAILABLE_VIEW_DESCRIPTION_1')}</SendToAgenciesText>
          <SendToAgenciesText>{t('TEAM_MEMBER_UNAVAILABLE_VIEW_DESCRIPTION_2')}</SendToAgenciesText>
        </Flex>
      </Flex>

      <BKJSideModalFooter justifyContent="end">
        <BKJButton
          width="192px"
          variant="PurpleSolid"
          onClick={() => {
            onReset();
            shiftModalRoutingService.goToView('AssignToAgencyTeamMemberView');
          }}
        >
          {t('GO_BACK')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
