import { createSelector } from '@reduxjs/toolkit';

import isUserAgencySecondarySelector from 'store/selectors/userSelectors/isUserAgencySecondarySelector';
import isUserEssentialSecondarySelector from 'store/selectors/userSelectors/isUserEssentialSecondarySelector';
import isUserPremiumSecondarySelector from 'store/selectors/userSelectors/isUserPremiumSecondarySelector';

const isUserSecondarySelector = createSelector(
  isUserEssentialSecondarySelector,
  isUserPremiumSecondarySelector,
  isUserAgencySecondarySelector,
  (isUserEssentialSecondary, isUserPremiumSecondary, isUserAgencySecondary) => {
    return isUserEssentialSecondary || isUserPremiumSecondary || isUserAgencySecondary;
  },
);

export default isUserSecondarySelector;
