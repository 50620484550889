import { BKJDialogChildren, IBKJThemeColor } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';
import { zIndexLayers } from 'constants/zIndexLayers';

export const BKJCenteredDialogBackdrop = styled.div<{ bgColor?: keyof IBKJThemeColor }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${({ theme }) => theme.colors[bgColor]};
    `};
  z-index: ${zIndexLayers.centeredDialogBackdrop};
`;

export const StyledBKJDialog = styled(BKJDialogChildren)`
  z-index: ${zIndexLayers.centeredDialog};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
