import {
  IUseSingleDateRangePickerBehaviorsArgs,
  IUseSingleDateRangePickerReturn,
} from './SingleDateRangePicker.types';
import {
  getPrettySingleDateFromRange,
  getSingleDateRangeFromPreviousDay,
  getSingleDateRangeFromNextDay,
} from '../../BKJDatePicker.utils';

export function useSingleDateRangePickerBehaviors({
  value,
}: IUseSingleDateRangePickerBehaviorsArgs): IUseSingleDateRangePickerReturn {
  const startDate = value[0];
  const previousValue = getSingleDateRangeFromPreviousDay({ date: startDate });
  const nextValue = getSingleDateRangeFromNextDay({ date: startDate });
  const displayDate = getPrettySingleDateFromRange(value);
  return {
    nextValue,
    previousValue,
    displayDate,
  };
}
