export const formatFloatToText = (float: number | null): string => {
  if (float === null || float === undefined) return '';

  const hours = Math.floor(float);
  const minutes = Math.round((float - hours) * 60);

  const hoursText = `${hours} hrs`;
  const minutesText = minutes > 0 ? `${minutes} mins` : '';

  return minutesText ? `${hoursText} ${minutesText}` : hoursText;
};
