import { IBKJLoadingSpinnerProps } from '@bookjane2/bookjane-design-library/lib/components/BKJLoadingSpinner/BKJLoadingSpinner.types';
import { GraphQLAPISwitch } from 'components/QuerySwitch/GraphQLAPISwitch';
import { LegacyAPISwitch } from 'components/QuerySwitch/LegacyAPISwitch';
import { ComponentType, FC, FunctionComponent, ReactElement } from 'react';
import { ApiRequestConfigType } from 'requests/index.types';
import {
  GQLAPIContextType,
  isGQLContextType,
  isLegacyAPIType,
  LegacyAPIContextType,
} from 'types/common.types';
import { withMemo } from 'utils/withMemo';
export interface IQuerySwitchComponentProps {
  component: ReactElement<unknown> | ComponentType<unknown> | unknown;
  loadingComponent?: ReactElement<unknown> | ComponentType<unknown> | unknown;
  loadingIconSize?: 'Default' | 'Small' | 'XSmall';
  context: ApiRequestConfigType | GQLAPIContextType;
  height?: string;
  isTransitionDisabled?: boolean;
  overrides?: Array<'NO_ERROR'>;
}

const QuerySwitchComponent: FC<IQuerySwitchComponentProps> = ({
  component,
  loadingComponent,
  loadingIconSize = 'Default',
  context,
  height = '100%',
  overrides = [],
  isTransitionDisabled = false,
  ...rest
}) => {
  if (isLegacyAPIType(context)) {
    return (
      <LegacyAPISwitch
        context={context}
        component={component as ComponentType<LegacyAPIContextType>}
        loadingComponent={loadingComponent as FunctionComponent<{}>}
        loadingIconSize={loadingIconSize as IBKJLoadingSpinnerProps['variant']}
        overrides={overrides}
      />
    );
  }

  if (isGQLContextType(context)) {
    return (
      <GraphQLAPISwitch
        height={height}
        context={context}
        component={component as ComponentType<unknown>}
        loadingComponent={loadingComponent as FunctionComponent<{}>}
        loadingIconSize={loadingIconSize as IBKJLoadingSpinnerProps['variant']}
        isTransitionDisabled={isTransitionDisabled}
        {...rest}
      />
    );
  }
  throw new Error(
    'QuerySwitch Invariant Violation: Context type is invalid: expected an object containing a "__typename" descriminator key equal to either "GraphQLAPI" or "LegacyAPI". Example: { __typename: "GraphQLAPI" | "LegacyAPI" }',
  );
};

export const QuerySwitch = withMemo(QuerySwitchComponent);
