import { InviteAdminModalViewType } from 'components/InviteAdminModal/InviteAdminModal.constants';
import { NewAdmin } from 'components/InviteAdminModal/views/NewAdmin';
import { NewAdminLocations } from 'components/InviteAdminModal/views/NewAdminLocations';
import { useURLParameters } from 'hooks';
import { createContext, FC, useCallback, useContext, useEffect } from 'react';
import { ModalRoutingService } from 'services';

const InviteAdminModalComponentMap = {
  New: NewAdmin,
  Locations: NewAdminLocations,
};

export const inviteAdminModalRoutingService = new ModalRoutingService(
  'inviteAdmin',
  'New',
  InviteAdminModalComponentMap,
  {},
  ['New'],
);

export interface IInviteAdminModalUI {
  isOpen: boolean;
  onClose: () => void;
  goToNextView: () => void;
  inviteAdminActiveModalView: InviteAdminModalViewType;
}

export const InviteAdminModalUIContext = createContext<IInviteAdminModalUI>(
  {} as IInviteAdminModalUI,
);

export const InviteAdminModalUIProvider: FC = ({ children }) => {
  const [{ inviteAdminActiveModalView }, { removeParam }] =
    useURLParameters<{ activeModalView: InviteAdminModalViewType }>();

  const handleClose = useCallback(() => {
    inviteAdminModalRoutingService.close();
  }, []);

  const goToNextView = useCallback(() => {
    switch (inviteAdminActiveModalView) {
      case 'New':
        inviteAdminModalRoutingService.goToView('Locations');
        break;
      case 'Locations':
        inviteAdminModalRoutingService.goToView('New');
        break;
    }
  }, [inviteAdminActiveModalView]);

  useEffect(() => {
    if (!Object.keys(InviteAdminModalComponentMap).includes(inviteAdminActiveModalView)) {
      removeParam(['inviteAdminActiveModalView']);
    }
  }, [inviteAdminActiveModalView, removeParam]);

  return (
    <InviteAdminModalUIContext.Provider
      value={{
        isOpen: !!inviteAdminActiveModalView,
        onClose: handleClose,
        goToNextView,
        inviteAdminActiveModalView: inviteAdminActiveModalView as InviteAdminModalViewType,
      }}
    >
      {children}
    </InviteAdminModalUIContext.Provider>
  );
};

export const useInviteAdminModalUIContext = () =>
  useContext<IInviteAdminModalUI>(InviteAdminModalUIContext);
