import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { UserGuard } from 'guards/UserGuard';
import { useTranslation } from 'hooks/useTranslation';
import {
  ShiftSummaryJaneRowDataProvider,
  useShiftSummaryJaneRowDataContext,
} from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryJaneRow/ShiftSummaryJaneRowData.context';
import {
  TableCell,
  TeamMemberAvatarWrapper,
  TeamMemberHeader,
  TeamMemberRequested,
} from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.styled';

import { FC, Fragment } from 'react';

const MobileShiftSummaryJaneRowComponent: FC = () => {
  const { t } = useTranslation();
  const { data } = useShiftSummaryJaneRowDataContext();
  return !!data ? (
    <Fragment>
      <Flex alignItems="center" gap="13px">
        <TeamMemberAvatarWrapper>
          <BKJAvatar
            width="30px"
            height="30px"
            src={data.fetchJane?.avatarUrls.origin}
            fallbackAvatarColor="SecondaryPendingDefault"
            showPendingSignUp={data.fetchJane?.pendingSignup}
          />
        </TeamMemberAvatarWrapper>
        <Flex flexDirection="column">
          <TeamMemberHeader>{`${data.fetchJane?.firstName} ${data.fetchJane?.lastName}`}</TeamMemberHeader>
          <TeamMemberRequested>{t('common:REQUESTED')}</TeamMemberRequested>
        </Flex>
      </Flex>
      <UserGuard userType="USER_TYPE_ESSENTIAL">
        <TableCell>{data.fetchJane.positions[0]?.agencyDetails?.openRate}</TableCell>
      </UserGuard>
    </Fragment>
  ) : null;
};

export const MobileShiftSummaryJaneRow: FC<{
  positionId: number;
  janeId: string;
}> = ({ positionId, janeId }) => (
  <ShiftSummaryJaneRowDataProvider positionId={positionId} janeId={janeId}>
    <MobileShiftSummaryJaneRowComponent />
  </ShiftSummaryJaneRowDataProvider>
);
