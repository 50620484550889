import { OrderStatusEnum } from 'types/graphql-types';
import { BiddableShiftStatus, ShiftBiddingStatusParams } from 'components/Tags/Tags.types';
import { DateTime } from 'luxon';

export const getShiftBiddingStatus = ({
  status,
  biddable,
  biddingTimeEndsAt,
  bidders,
}: ShiftBiddingStatusParams): Nullable<BiddableShiftStatus> => {
  if (status === OrderStatusEnum.accepted && !!biddable && bidders?.length! > 0) {
    return 'AWARDED';
  }
  if (status === OrderStatusEnum.pending && !!biddable) {
    if (!!biddingTimeEndsAt && DateTime.fromISO(biddingTimeEndsAt) > DateTime.now()) {
      return 'BIDDING';
    }
    if (
      (!biddingTimeEndsAt || DateTime.fromISO(biddingTimeEndsAt) < DateTime.now()) &&
      bidders?.length > 0
    ) {
      return 'AWARDING';
    }
    if (
      (!biddingTimeEndsAt || DateTime.fromISO(biddingTimeEndsAt) < DateTime.now()) &&
      bidders?.length === 0
    ) {
      return 'NO_BIDS';
    }
  }
  return null;
};
