import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloClient, createApolloCache } from 'providers/ApolloProvider';
import {
  QUERY_assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  QUERY_assignToAgencyTeamMemberFetchAgencyJaneById,
  QUERY_assignToAgencyTeamMemberFetchAgencyJanes,
  QUERY_assignToAgencyTeamMemberFetchAgencyJanesCount,
} from 'queries';
import {
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderIdVariables,
  assignToAgencyTeamMemberFetchAgencyJaneById,
  assignToAgencyTeamMemberFetchAgencyJaneByIdVariables,
  assignToAgencyTeamMemberFetchAgencyJanesCount,
  assignToAgencyTeamMemberFetchAgencyJanesCountVariables,
} from 'types/graphql-types';

const agencyJaneOrderValidationClient = createApolloClient({ cache: createApolloCache() });
export const agencyJaneOrderValidationQueryConfig = ({
  janeId,
  orderId,
  orderRelayId,
  onSuccess,
}: {
  janeId: string;
  orderId: number;
  orderRelayId: string;
  onSuccess: (data: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId) => void;
}): UseGQLQueryConfig<
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderIdVariables
> => ({
  key: 'FETCH_AGENCY_TEAM_MEMBER_AVAILABILITY_BY_ORDER_ID',
  query: QUERY_assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  client: agencyJaneOrderValidationClient,
  onSuccess,
  variables: {
    janeId,
    orderId,
    orderRelayId,
  },
});

const fetchAgencyJaneClient = createApolloClient({ cache: createApolloCache() });
export const fetchAgencyJaneQueryConfig = ({
  janeId,
  onSuccess,
}: {
  janeId: string;
  onSuccess: (data: assignToAgencyTeamMemberFetchAgencyJaneById) => void;
}): UseGQLQueryConfig<
  assignToAgencyTeamMemberFetchAgencyJaneById,
  assignToAgencyTeamMemberFetchAgencyJaneById,
  assignToAgencyTeamMemberFetchAgencyJaneByIdVariables
> => ({
  query: QUERY_assignToAgencyTeamMemberFetchAgencyJaneById,
  key: 'ASSIGN_TO_AGENCY_TEAM_MEMBER_FETCH_AGENCY_JANE_BY_ID',
  client: fetchAgencyJaneClient,
  onSuccess,
  variables: {
    janeId,
  },
});

const fetchAgencyTeamMembersClient = createApolloClient({ cache: createApolloCache() });
export const fetchAgencyTeamMembersQueryConfig = ({
  orderId,
  positionId,
  agencyId,
  agencyCommunityId,
}: {
  orderId: string;
  positionId: number;
  agencyId: number;
  agencyCommunityId: number[];
}): ISSFBehaviorsOptions => ({
  query: QUERY_assignToAgencyTeamMemberFetchAgencyJanes,
  key: 'ASSIGN_TO_AGENCY_TEAM_MEMBER_FETCH_AGENCY_JANES',
  paginationType: 'InfiniteScroll',
  pageSize: 12,
  client: fetchAgencyTeamMembersClient,
  schema: {
    orderId: {
      initialValue: orderId,
    },
    byAgency: {
      initialValue: agencyId,
    },
    byFullName: {
      initialValue: '',
    },
    byPositionId: {
      initialValue: positionId,
    },
    byAgencyCommunityId: {
      initialValue: agencyCommunityId,
    },
  },
});

export const fetchAgencyTeamMembersCountQueryConfig = ({
  orderId,
  agencyCommunityId,
}: {
  orderId: string;
  agencyCommunityId: number[];
}): UseGQLQueryConfig<
  assignToAgencyTeamMemberFetchAgencyJanesCount,
  { totalCount: number },
  assignToAgencyTeamMemberFetchAgencyJanesCountVariables
> => ({
  query: QUERY_assignToAgencyTeamMemberFetchAgencyJanesCount,
  key: 'ASSIGN_TO_AGENCY_TEAM_MEMBER_FETCH_AGENCY_JANES_COUNT',
  variables: {
    orderId: orderId,
    byAgencyCommunityId: agencyCommunityId,
  },
  responseTransformer: (response) => ({
    totalCount: response?.requestJanes?.totalCount,
  }),
});
