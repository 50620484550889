import {
  GroupRule,
  toShiftDistributionGroupRules,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';
import {
  SHIFT_DISTRIBUTION_GROUP_RULES_BATCH,
  SHIFT_DISTRIBUTION_RULES,
} from 'constants/endpoints';
import { METHOD_PATCH, METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export type GroupRulesFormSchema = {
  groupRules: GroupRule[];
};

export type GroupRulesFormActions = {
  addGroupRule: () => void;
  onGroupSelect: (groupOption: GroupRule, rankId: number) => void;
  onGroupDelete: (index: number) => void;
  onWaitTimeSelect: (waitTimeMinutesOptionValue: string[], rankId: number) => void;
};

export type AddPositionRuleFormSchema = {
  position_id: string[];
  skip_wait_time_threshold_minutes: string[];
};

type ShiftDistributionRulesParamsType = GroupRulesFormSchema &
  AddPositionRuleFormSchema & { community_id: string };

export type ShiftDistributionRulesPayloadType = {
  position_id?: number;
  skip_wait_time_threshold_minutes: number;
};

export type ShiftDistributionRulesGroupRule = {
  group_id: number;
  wait_time_minutes?: number | null;
};

export const shiftDistributionGroupRulesPayloadTransformer = ({
  groupRules,
  position_id,
  skip_wait_time_threshold_minutes,
  community_id,
}: ShiftDistributionRulesParamsType) => ({
  community_id: Number.parseInt(community_id),
  position_id: Number.parseInt(position_id[0]),
  skip_wait_time_threshold_minutes: Number.parseInt(skip_wait_time_threshold_minutes[0]),
  group_rules: groupRules.map(toShiftDistributionGroupRules),
});

export const API_PATCH_shiftDistirbutionRules = ({
  shiftDistributionId,
  payload,
}: {
  shiftDistributionId: number;
  payload: ShiftDistributionRulesPayloadType;
}): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: `${SHIFT_DISTRIBUTION_RULES}/${shiftDistributionId}`,
    method: METHOD_PATCH,
    body: payload,
  };
};

export const toShiftDistribituionRulesPatchPayload = (
  shiftDistributionId: number,
  skip_wait_time_threshold_minutes: string[],
  position_id?: number | undefined,
) => ({
  shiftDistributionId: shiftDistributionId,
  payload: {
    skip_wait_time_threshold_minutes: Number.parseInt(skip_wait_time_threshold_minutes[0]),
    ...(!!position_id && { position_id: position_id }),
  },
});

export const createUpdateGroupRulesRequest = ({
  shiftDistributionId,
  skipWaitTimeThresholdMinutes,
  positionId,
}: {
  shiftDistributionId: number;
  skipWaitTimeThresholdMinutes: string[];
  positionId: number | undefined;
}) =>
  API_PATCH_shiftDistirbutionRules(
    toShiftDistribituionRulesPatchPayload(
      shiftDistributionId,
      skipWaitTimeThresholdMinutes,
      positionId,
    ),
  );

export const createAddGroupRulesRequest = ({
  communityId,
  skipWaitTimeThresholdMinutes,
  positionId,
}: {
  communityId: number;
  skipWaitTimeThresholdMinutes: number;
  positionId?: number | undefined;
}) =>
  API_POST_shiftDistirbutionRules({
    ...{
      skip_wait_time_threshold_minutes: skipWaitTimeThresholdMinutes,
      community_id: communityId,
    },
    ...(positionId && { position_id: positionId }),
  });

export const API_POST_shiftDistirbutionRules = (
  params: ShiftDistributionRulesPayloadType & { community_id: number },
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: SHIFT_DISTRIBUTION_RULES,
    method: METHOD_POST,
    body: params,
  };
};

type ShiftDistributionRulesGroupRuleBatchPayloadType = {
  group_rules: ShiftDistributionRulesGroupRule[];
  shiftDistributionRuleId: number;
};
export const API_POST_shiftDistirbutionRulesGroupRuleBatch = (
  params: ShiftDistributionRulesGroupRuleBatchPayloadType,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: SHIFT_DISTRIBUTION_GROUP_RULES_BATCH(params.shiftDistributionRuleId),
    method: METHOD_POST,
    body: { group_rules: params.group_rules },
  };
};
