import { useSSFBehaviors } from 'hooks';
import { FC, useContext, useMemo } from 'react';
import { GQLAPIContextType } from 'types/common.types';
import { createCustomFieldFetchCommunities } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { useSelector } from 'react-redux';
import { customFieldFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/customFieldFeatureEnabledSelector';
import { fetchCommunitiesQueryConfig } from 'components/CustomFieldModal/Contexts/DataContexts.constants';

export const FetchCommunitiesDataContext = createGQLDataContext({});

export const FetchCommunitiesDataProvider: FC = ({ children }) => {
  const communities = useSelector(customFieldFeatureEnabledSelector);
  const queryConfig = useMemo(() => fetchCommunitiesQueryConfig(communities), [communities]);
  const [values, useQueryOptions] = useSSFBehaviors(queryConfig);

  const contextValue = useMemo(() => ({ values, ...useQueryOptions }), [useQueryOptions, values]);

  return (
    <FetchCommunitiesDataContext.Provider value={contextValue}>
      {children}
    </FetchCommunitiesDataContext.Provider>
  );
};

export const useFetchCommunitiesDataContext =
  (): GQLAPIContextType<createCustomFieldFetchCommunities> =>
    useContext(FetchCommunitiesDataContext);
