import { ApolloQueryResult, QueryOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import {
  Action,
  IQueryTransformFn,
  IState,
  UseQueryFormReducer,
  UseQueryFormStateResult,
} from 'hooks/useQueryForm/useQueryForm.types';
import { defaultApolloClient } from 'providers/ApolloProvider';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { isArray } from 'types/common.types';
import { findValueByKeyDeep } from 'utils/findValueByKeyDeep';

interface InitialStateArgs<IVariables, ITransformedData> {
  initialVariables: IState<IVariables, ITransformedData>['variables'];
  pageSize: number;
}

function getInitialUseQueryFormState<IVariables, ITransformedData>({
  initialVariables,
  pageSize,
}: InitialStateArgs<IVariables, ITransformedData>) {
  const defaultValue = {
    isLoading: true,
    pageInfo: {
      hasNextPage: false,
      endCursor: null,
    },
    data: undefined,
    variables: {
      ...initialVariables,
      first: pageSize,
      after: null,
    },
  };

  return defaultValue;
}

export function useQueryFormState<
  TData,
  IVariables,
  ITransformedData,
  ITransformedPayload,
>(options: {
  key: string;
  initialVariables: IState<IVariables, ITransformedData>['variables'];
  query: DocumentNode;
  pageSize?: number;
  responseTransformer: IQueryTransformFn<TData, ITransformedData>;
  payloadTransformer: IQueryTransformFn<IVariables, ITransformedPayload>;
}) {
  const {
    initialVariables: _initialVariables,
    query,
    payloadTransformer,
    responseTransformer,
    pageSize = 10,
  } = options;
  const debounceId = useRef<Nullable<number>>(null);
  const [initialVariables] = useState(_initialVariables);

  const initialState = getInitialUseQueryFormState<IVariables, ITransformedData>({
    initialVariables,
    pageSize,
  });

  const [state, dispatch] = useReducer<
    UseQueryFormReducer<IState<IVariables, ITransformedData>, Action<ITransformedData, TData>>
  >((prevState, action) => {
    const { type } = action;
    switch (type) {
      case 'SET_LOADING':
        return { ...prevState, isLoading: action.isLoading! };
      case 'ON_CHANGE': {
        const event = action.event!;

        return {
          ...prevState,
          isLoading: true,
          data: undefined,
          variables: {
            ...prevState.variables,
            [event.target.name]: event.target.value,
          },
        };
      }

      case 'ON_QUERY_COMPLETED': {
        const __data__ = action!.result!.data as unknown as never;
        const __transformedData__ = action!.result!.transformedData as unknown as never;
        const endCursor = findValueByKeyDeep<string>('endCursor', __data__) || null;
        const hasNextPage = !!findValueByKeyDeep<boolean>('hasNextPage', __data__);

        let merged: never[] = [];

        if (prevState.data && isArray(prevState.data))
          merged = merged.concat(prevState.data as unknown as ConcatArray<never>);
        (__transformedData__ as unknown as []).forEach((node: { id: string }) => {
          if (!merged.some((mergedNode: { id: string }) => node.id === mergedNode.id)) {
            merged.push(node as unknown as never);
          }
        });

        const next = {
          ...prevState,
          variables: {
            ...prevState.variables,
            after: endCursor,
          },
          pageInfo: {
            endCursor,
            hasNextPage,
          },
          data: merged as unknown as ITransformedData,
          isLoading: false,
        };
        return next;
      }
      default:
        throw new Error();
    }
  }, initialState);

  const setLoadingValue = useCallback((bool) => {
    dispatch({ type: 'SET_LOADING', isLoading: bool });
  }, []);

  const handleFetch = useCallback(
    async (opts: QueryOptions<ITransformedPayload, TData>) => {
      setLoadingValue(true);
      const response = await defaultApolloClient.query<TData, ITransformedPayload>({
        ...opts,
        notifyOnNetworkStatusChange: false,
      });
      return response;
    },
    [setLoadingValue],
  );

  const handleQueryCompleted = useCallback(
    (result: ApolloQueryResult<TData>, reset = false) => {
      dispatch({
        type: 'ON_QUERY_COMPLETED',
        result: {
          ...result,
          transformedData: responseTransformer(result.data),
          data: result.data,
        },
        reset,
      });
    },
    [responseTransformer],
  );

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      setLoadingValue(true);
      dispatch({ type: 'ON_CHANGE', event });
      if (debounceId.current) window.clearTimeout(debounceId.current);

      debounceId.current = window.setTimeout(() => {
        let nextVariables: IVariables = {
          ...state.variables,
          [event.target.name]: event.target.value,
        };
        return handleFetch({
          variables: { ...payloadTransformer(nextVariables), after: undefined },
          query,
        }).then((result) => {
          if (result) return handleQueryCompleted(result, true);
          else void 0;
        });
      }, 300);
    },
    [
      handleFetch,
      handleQueryCompleted,
      payloadTransformer,
      query,
      setLoadingValue,
      state.variables,
    ],
  );

  const handleMount = useCallback(async () => {
    let vars: IVariables = { ...state.variables };
    let result = await handleFetch({
      variables: payloadTransformer(vars),
      query,
    });
    if (result) handleQueryCompleted(result);
  }, [handleFetch, handleQueryCompleted, payloadTransformer, query, state.variables]);

  const handleNextPage = useCallback(async () => {
    let vars: IVariables = { ...state.variables };
    let result = await handleFetch({
      variables: payloadTransformer(vars),
      query,
    });
    if (result) handleQueryCompleted(result);
  }, [handleFetch, handleQueryCompleted, payloadTransformer, query, state.variables]);

  useEffect(() => {
    handleMount();

    return () => {};
  }, []); // eslint-disable-line

  const returnValue = useMemo(() => {
    const actions = {
      onChange: handleChange,
      onMount: handleMount,
      onNextPage: handleNextPage,
    };
    return [
      {
        ...state,
        data: state.data,
      },
      actions,
    ] as UseQueryFormStateResult<IState<IVariables, ITransformedData>, typeof actions>;
  }, [handleChange, handleMount, handleNextPage, state]);

  return returnValue;
}
