import { formatJaneNameByStatus } from 'utils/formatJaneNameByStatus';
import { JaneNameWrapper } from './JaneName.styled';
import { IJaneNameProps } from './JaneName.types';

export const JaneName = (props: IJaneNameProps): JSX.Element => {
  const { status, mainJane, ...rest } = props;

  return (
    <JaneNameWrapper {...rest}>{formatJaneNameByStatus({ status, mainJane })}</JaneNameWrapper>
  );
};
