import { BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { useTranslation } from 'hooks/useTranslation';
import {
  SendToAgenciesBoldText,
  InfoSection,
  SendToAgenciesText,
} from '../../SendToAgenciesView.styled';
import { FormatCurrency } from 'components/FormatCurrency';
import { Fragment } from 'react';

function parsePayRange(payRange: string): number[] {
  return payRange.split(' - ').map((pay) => Number(pay.replace('$', '')));
}

export function ShiftPay({
  estimatedHourlyPay,
  estimatedTotalPay,
}: {
  estimatedHourlyPay: string;
  estimatedTotalPay: string;
}) {
  const { t } = useTranslation();
  const [minTotalPay, maxTotalPay] = parsePayRange(estimatedTotalPay);
  const [minHourlyPay, maxHourlyPay] = parsePayRange(estimatedHourlyPay);
  return (
    <Flex flexDirection="column" gap="8px">
      <Flex alignItems="center" gap="8px">
        <SendToAgenciesBoldText>{t('shift_modal:ESTIMATED_SHIFT_PAY')}</SendToAgenciesBoldText>
        <BKJIconTooltip iconName="Info" placement="bottom">
          <Flex width="258px">{t('SEND_TO_AGENCIES_SUMMARY_TOOLTIP')}</Flex>
        </BKJIconTooltip>
      </Flex>

      <InfoSection flexDirection="column" childMarginBottom="8px">
        <Flex flexDirection="column" gap="8px">
          <SendToAgenciesBoldText>
            {t('shift_modal:HOURLY')}:&nbsp;
            <SendToAgenciesText>
              <FormatCurrency amount={`${minHourlyPay}`} />{' '}
              {!!maxHourlyPay && (
                <Fragment>
                  -
                  <FormatCurrency amount={`${maxHourlyPay}`} />
                </Fragment>
              )}
            </SendToAgenciesText>
          </SendToAgenciesBoldText>
          <SendToAgenciesBoldText>
            {t('shift_modal:TOTAL')}:&nbsp;
            <SendToAgenciesText>
              <FormatCurrency amount={`${minTotalPay}`} />{' '}
              {!!maxTotalPay && (
                <Fragment>
                  -
                  <FormatCurrency amount={`${maxTotalPay}`} />
                </Fragment>
              )}
            </SendToAgenciesText>
          </SendToAgenciesBoldText>
        </Flex>
      </InfoSection>
    </Flex>
  );
}
