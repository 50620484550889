import {
  CalendarDayCellWrapper,
  CalendarDayNumber,
} from 'components/ShiftCalendar/ShiftCalendar.styled';
import { FC } from 'react';
import { useShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import { formatDate } from 'format/formatDate';
import { CalendarDayCellStatusAggregateContent } from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCellStatusAggregateContent';
import {
  CalendarDayCellPropsProvider,
  useCalendarDayCellPropsContext,
} from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCell.props.context';
import { CalendarDayCellInnerWrapper } from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCell.styled';
import { withMemo } from 'utils/withMemo';
import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

const CalendarDayCellComponent: FC = withMemo(() => {
  const { isOutsideOfMonth } = useShiftsPageContext();
  const { date, onDaySelection } = useCalendarDayCellPropsContext();

  return (
    <CalendarDayCellWrapper
      isOutsideOfMonth={isOutsideOfMonth(date)}
      id={formatDate(date)}
      onClick={onDaySelection}
    >
      <CalendarDayNumber>{formatDate(date, 'dd')}</CalendarDayNumber>
      <CalendarDayCellInnerWrapper>
        <CalendarDayCellStatusAggregateContent />
      </CalendarDayCellInnerWrapper>
    </CalendarDayCellWrapper>
  );
});

export const CalendarDayCell: FC<{ date: string }> = withMemo(({ date }) => {
  const key = `CalendarDayCell-${date}`;
  return (
    <ErrorBoundary
      componentName="CalendarDayCell"
      size="Small"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <CalendarDayCellPropsProvider date={date} key={key}>
        <CalendarDayCellComponent />
      </CalendarDayCellPropsProvider>
    </ErrorBoundary>
  );
});
