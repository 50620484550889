import { DOWNLOAD_INVITE_SPREADSHEET } from 'constants/endpoints';
import { createContext, useState } from 'react';
import { API_POST_inviteBySpreadsheet } from 'requests/POST_inviteBySpreadsheet';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';
import { FileSaver } from 'utils/downloadFile';
import { ICSVViewContext } from './CSVView.types';

const initialState: ICSVViewContext = {
  flowView: 'invite',
  setFlowView: () => {},
  formValues: {},
  errors: [],
  setErrors: () => {},
  numInvites: 0,
  setNumInvites: () => {},
  onChange: () => {},
  onDownload: () => {},
  onSubmit: () => {},
};

export const CSVViewContext = createContext<ICSVViewContext>(initialState);

export const CSVViewContextProvider: React.FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const [flowView, setFlowView] = useState<string>('invite');
  const [formValues, setFormValues] = useState({});
  const [numInvites, setNumInvites] = useState<number>(0);
  const [errors, setErrors] = useState([]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (name: string, value: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const onDownload = async () => {
    await fetch(DOWNLOAD_INVITE_SPREADSHEET, {
      method: 'GET',
      headers: createBookJaneRequestHeaders({}),
    }).then(async (response) => {
      if (response.status > 200) {
        throw response.json();
      } else {
        const CSV = await response.blob();
        FileSaver.saveAs(CSV, 'TeamMemberInviteSpreadsheetTemplate.xlsx');
      }
    });
  };

  const onSubmit = async () => {
    const formData = new FormData();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { csv } = formValues as any;
    formData.append('csv_file', csv);
    apiRequest(API_POST_inviteBySpreadsheet({ formData }), {
      onSuccess: () => {
        console.log('Successfully invited team members');
        setFlowView('success');
      },
      onError: () => {
        alert('Error inviting team members');
        setFlowView('fail');
      },
    });
  };

  return (
    <CSVViewContext.Provider
      value={{
        flowView,
        setFlowView,
        formValues,
        errors,
        setErrors,
        numInvites,
        setNumInvites,
        onChange,
        onSubmit,
        onDownload,
      }}
    >
      {children}
    </CSVViewContext.Provider>
  );
};
