import { BKJButton } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';
export const InnerHeaderWrapper = styled.div`
  padding: 32px 32px 30px 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px;
  }
`;

export const MainTitle = styled.h1`
  ${({ theme }) => theme.typography.H3};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    ${({ theme }) => theme.typography.H4};
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
  }
`;

export const TotalAvailableJanesText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 21px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

export const RequestedJanesText = styled.span`
  font-size: 16px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 24px;
`;

export const RequestedJanesBoldText = styled(RequestedJanesText)`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
`;

export const TeamMemberCount = styled.div`
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const ModalFooterButton = styled(BKJButton)`
  width: 192px;
  max-height: 44px;
  min-height: 44px;
  margin-left: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
  } ;
`;

export const Caption = styled.div`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const ModalSection = styled.div`
  margin-top: 32px;
`;
