import { usePersistentRef } from 'hooks/usePersistentRef';
import { useRef, useEffect, UIEventHandler, useCallback } from 'react';

export const useCachedScrollRef = (scrollRefKey: string) => {
  const [scrollTop, mutateScrollTop] = usePersistentRef(0, scrollRefKey, 'sessionStorage');
  const scrollElementRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const element = scrollElementRef.current;
    if (element) element.scrollTop = scrollTop;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll: UIEventHandler<HTMLUListElement> = useCallback(
    (event) => {
      if (event.currentTarget.scrollTop) mutateScrollTop(event.currentTarget.scrollTop);
    },
    [mutateScrollTop],
  );

  return {
    scrollElementRef,
    handleScroll,
  };
};
