import i18next from 'i18next';
import { fetchTieringDetails_fetchCommunity_biddingTimes } from 'types/graphql-types';

export const getBiddingPeriodTime = (
  biddingTime: fetchTieringDetails_fetchCommunity_biddingTimes,
): string => {
  const mins = biddingTime['priorityTime'] % 60;
  const hours = Math.floor(biddingTime['priorityTime'] / 60);

  const minsStr = mins ? `${mins} mins` : '';
  const hoursStr = hours ? `${hours} ${i18next.t('location_settings:HOURS')}` : '';

  return `${hoursStr} ${minsStr}`;
};

export const getBiddingRuleTime = (
  biddingTime: fetchTieringDetails_fetchCommunity_biddingTimes,
): string => {
  const leadTime = biddingTime['leadTime'];
  if (leadTime <= 48) {
    return `${leadTime} ${i18next.t('location_settings:HOURS')}`;
  }
  const days = Math.floor(leadTime / 24);

  return `${days} ${i18next.t('location_settings:DAY')}s`;
};
