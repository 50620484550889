import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { QUERY_agencyMemberPage } from 'queries';
import { castStringToInteger } from 'utils';

export const agencyMemberPageConfig = ({
  location,
}: {
  location: ILocationFilterProps['value'];
}): ISSFBehaviorsOptions => {
  return {
    key: 'AGENCY_MEMBER_PAGE',
    query: QUERY_agencyMemberPage,
    schema: {
      external: {
        initialValue: true,
        mapper: (value: boolean) => value,
      },
      first: {
        initialValue: 10,
        mapper: castStringToInteger,
      },
      location: {
        initialValue: location,
        isGlobalCached: true,
        isPreservedOnReset: true,
        resetFieldsOnChange: [
          {
            field: 'position_id',
            userTypes: ['Agency'],
          },
        ],
      },
    },
  };
};
