import styled, { css } from 'styled-components';

export const AgencyGridNoAgenciesTextWrapper = styled.span`
  max-width: 345px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;

export const AgencyGridWrapper = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  gap: 16px;
`;

export const AgencyGridCardWrapper = styled.label`
  width: calc(50% - 10px);
  height: 300px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.Grey1};

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.5);
  }
  &:focus {
    box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.5);
  }
`;

export const AgencyGridAvatarWrapper = styled.img`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 237px;
  height: 102px;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    height: 43%;
    min-height: 96px;
  }
`;

export const AgencyGridCardContentWrapper = styled.div`
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    height: 80%;
  }
`;

export const AgencyGridCardAvatar = styled.div<{ background: string }>`
  ${({ background }) =>
    background &&
    css`
      background-image: url('${background}');
      background-size: cover;
    `}
`;

export const AgencyName = styled.div`
  display: block;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
  white-space: nowrap;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 148px;
  }
`;

export const AgencyLicenceNumber = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 148px;
  }
`;

export const AgencyLabel = styled.div`
  display: block;
  font-size: 14px;
  line-height: 21px;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const AgencyCount = styled(AgencyName)`
  display: block;
  font-size: 14px;
  line-height: 21px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};

  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  ${({ theme }) => theme.common.Ellipsis};
`;
