import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { QuerySwitch } from 'components/QuerySwitch';
import { AvailableMemberTabWrapper } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailabilityMemberTab.styled';
import { AvailableMemberTabTable } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemberTabTable';

import {
  AvailableMembersDataContext,
  AvailableMembersDataProvider,
} from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemeberTab.context';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const AvailableMembersTab: FC = () => {
  return (
    <ErrorBoundary
      componentName="AvailableMembersTab"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <AvailableMemberTabWrapper>
        <AvailableMembersDataProvider>
          <QuerySwitch component={AvailableMemberTabTable} context={AvailableMembersDataContext} />
        </AvailableMembersDataProvider>
      </AvailableMemberTabWrapper>
    </ErrorBoundary>
  );
};
