import { useFetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import { CreateTimeOffViewRenderer } from 'components/TimeOffModal/views/CreateTimeOffView';
import { DeleteTimeOffView } from 'components/TimeOffModal/views/DeleteTimeOffView';
import { DetailTimeOffView } from 'components/TimeOffModal/views/DetailTimeOffView';
import { EditTimeOffView } from 'components/TimeOffModal/views/EditTimeOffView';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { QUERY_fetchTimeOffCustomFields } from 'queries';
import { Fragment } from 'react';
import { ModalRoutingService } from 'services';
import {
  fetchTimeOffCustomFields,
  fetchTimeOffCustomFields_fetchCustomFields_nodes,
} from 'types/graphql-types';

export const startOfDay: [i0: string] = ['00:00:00'];
export const endOfDay: [i0: string, i1: string] = ['23:59:59', '23:59:00'];

export const defaultStartTime = '07:00:00';
export const defaultEndTime = '15:00:00';

export const titleOptions = [
  {
    key: 'time_off',
    value: 'time_off',
    label: 'schedule:TIME_OFF',
  },
  {
    key: 'vacation',
    value: 'vacation',
    label: 'schedule:VACATION',
  },
  {
    key: 'personal',
    value: 'personal',
    label: 'schedule:PERSONAL',
  },
  {
    key: 'sick',
    value: 'sick',
    label: 'schedule:SICK',
  },
  {
    key: 'stat',
    value: 'stat',
    label: 'schedule:STAT',
  },
  {
    key: 'float',
    value: 'float',
    label: 'schedule:FLOAT',
  },
];

export const calendarDropdownProps = {
  disabledTo: DateTime.now().minus({ day: 1 }).toString(),
  disabledFrom: DateTime.now().plus({ month: 6 }).toString(),
};

export interface ICustomFields {
  [fieldName: string]: string | string[] | undefined;
}

export const customFieldsPayloadConverter = (
  customFields: ICustomFields,
  timeOffCustomFields: fetchTimeOffCustomFields_fetchCustomFields_nodes[],
): { name: string; value: string | string[]; custom_field_id: number }[] =>
  Object.keys(customFields)
    .map((key) => {
      const value =
        Array.isArray(customFields[key]) && !!customFields[key]
          ? `${(customFields[key] as string[]).pop()}`
          : `${customFields[key]}`;
      return {
        name: key,
        value: value,
        custom_field_id: timeOffCustomFields.find((cf) => cf.name === key)!.id,
      };
    })
    .filter((customField) => !!customField.value);

export function fetchTimeOffCustomFieldsQueryConfig({
  byFacilityId,
}: {
  byFacilityId?: number;
}): UseGQLQueryConfig<
  fetchTimeOffCustomFields,
  fetchTimeOffCustomFields_fetchCustomFields_nodes[]
> {
  return {
    key: 'COMMUNITY_CUSTOM_FIELDS_QUERY_CONFIG',
    query: QUERY_fetchTimeOffCustomFields,
    variables: {
      filter: {
        withCommunity: [byFacilityId],
      },
    },
    responseTransformer: (data) => {
      return data?.fetchCustomFields?.nodes || [];
    },
  };
}

export function DetailTimeOffViewHeaderComponent() {
  const { isLoading, data } = useFetchTimeOffDataContext();
  return isLoading ? (
    <Fragment />
  ) : (
    <Fragment>{`${i18next.t('schedule:TIME_OFF')} #${data.fetchTimeOff?.displayId}`}</Fragment>
  );
}

export function DeleteTimeOffViewHeaderComponent() {
  const { isLoading, data } = useFetchTimeOffDataContext();
  return isLoading ? (
    <Fragment />
  ) : (
    <Fragment>{`${i18next.t('schedule:DELETE_TIME_OFF')} #${
      data.fetchTimeOff?.displayId
    }`}</Fragment>
  );
}

export const TimeOffModalHeaderMap = {
  CreateTimeOffView: () => <>{i18next.t('schedule:NEW_TIME_OFF')}</>,
  EditTimeOffView: DetailTimeOffViewHeaderComponent,
  DetailTimeOffView: DetailTimeOffViewHeaderComponent,
  DeleteTimeOffView: DeleteTimeOffViewHeaderComponent,
};

export const TimeOffModalComponentMap = {
  EditTimeOffView: EditTimeOffView,
  CreateTimeOffView: CreateTimeOffViewRenderer,
  DetailTimeOffView,
  DeleteTimeOffView,
};

export const timeOffModalRoutingService = new ModalRoutingService(
  'timeOffModal',
  'EditTimeOffView',
  TimeOffModalComponentMap,
  {
    timeOffModalTimeOffId: 'number',
  },
  [],
);
