import { createSelector } from '@reduxjs/toolkit';
import { isAgencyLocationFilteringEnabledSelector } from 'store/selectors/featureFlagSelectors';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';

export const isUserAgencyAndAgencyLocationFilterForcedDisabledSelector = createSelector(
  [isUserAgencySelector, isAgencyLocationFilteringEnabledSelector],
  (isUserAgency, isAgencyLocationFilteringEnabled) => {
    if (isUserAgency && !isAgencyLocationFilteringEnabled) return true;
    return false;
  },
);
