import { FC, useContext, useEffect, useMemo } from 'react';
import { QUERY_userProfilePage } from 'queries';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import { GQLAPIContextType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { userProfilePage } from 'types/graphql-types';

const UserProfilePageDataContext = createGQLDataContext<GQLAPIContextType<userProfilePage>>();

export const UserProfilePageDataProvider: FC = ({ children }) => {
  const { data, fetch, ...restQuery } = useGQLQuery({
    key: 'USER_PROFILE_PAGE',
    query: QUERY_userProfilePage,
    variables: {},
  });

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  const contextValue = useMemo(() => ({ data, fetch, ...restQuery }), [data, fetch, restQuery]);

  return (
    <UserProfilePageDataContext.Provider value={contextValue}>
      {children}
    </UserProfilePageDataContext.Provider>
  );
};

export const useUserProfilePageDataContext = (): GQLAPIContextType<userProfilePage> =>
  useContext(UserProfilePageDataContext);
