import { BKJTableHead } from 'components/BKJTable/BKJTable.styled';
import { BKJTableSubHeader } from 'components/BKJTable/BKJTableSubHeader';
import { DedicatedTeamTableHeadRow } from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamTable/DedicatedTeamTableHeader.styled';

export function DedicatedTeamTableHeader() {
  return (
    <DedicatedTeamTableHeadRow>
      <BKJTableHead>
        <BKJTableSubHeader name="dedicated-team" title="Dedicated Team" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="facilities" title="Facilities" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="position" title="Position" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="team-members" title="Team Members" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="pay-range" title="Pay Range" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="action" title="Action" direction="default" />
      </BKJTableHead>
    </DedicatedTeamTableHeadRow>
  );
}
