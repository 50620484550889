import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isGroupsFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (permissions) => {
    if (permissions?.GROUPS?.access)
      return (
        permissions.GROUPS.access.includes('read') &&
        !!permissions.GROUPS.communities &&
        !!permissions.GROUPS.communities[communityId] &&
        !!permissions.GROUPS.communities[communityId].includes('read')
      );
    else return false;
  });
