import createCachedSelector from 're-reselect';
import scheduleFacilityViewStateSelector from 'store/selectors/scheduleSelectors/scheduleFacilityViewStateSelector';

const activePositionTabSelector = createCachedSelector(
  scheduleFacilityViewStateSelector,
  (facilityViewState) => facilityViewState.activeTab,
)((state) => {
  const facilityViewState = scheduleFacilityViewStateSelector(state);

  const activeTab = facilityViewState.activeTab;

  return activeTab;
});

export default activePositionTabSelector;
