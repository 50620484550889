import { BKJButton } from '@bookjane2/bookjane-design-library';
import { ShiftJane } from 'components/ShiftModal/src/components/ShiftJane';
import styled from 'styled-components';

export const ShiftJaneSection = styled(ShiftJane)`
  margin-bottom: 12px;
`;

export const DeleteButton = styled(BKJButton)`
  padding: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    text-decoration: none;
  }
`;

export const UnableToEditShiftMessage = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    text-align: center;
  }
`;
