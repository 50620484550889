import { QuerySwitch } from 'components/QuerySwitch';
import {
  ShiftModalDataContext,
  useShiftModalDataContext,
} from 'components/ShiftModal/ShiftModalProvider.data.context';
import { FetchAgencyTeamMembersDataProvider } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMembers.data.context';
import { SendToAgencyTeamMemberViewPropsProvider } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/SelectAgencyTeamMemberView/SendToAgencyTeamMemberView.props.context';
import { ShiftUnavailableView } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/SelectAgencyTeamMemberView/ShiftUnavailableView.component';
import { FC } from 'react';
import { SelectAgencyTeamMemberViewComponent } from './SelectAgencyTeamMemberView.component';

const Component: FC = () => {
  const { data } = useShiftModalDataContext();

  return data.fetchOrder.canAssign ? (
    <FetchAgencyTeamMembersDataProvider>
      <SendToAgencyTeamMemberViewPropsProvider>
        <SelectAgencyTeamMemberViewComponent />
      </SendToAgencyTeamMemberViewPropsProvider>
    </FetchAgencyTeamMembersDataProvider>
  ) : (
    <ShiftUnavailableView />
  );
};

export const SelectAgencyTeamMemberView: FC = () => {
  return <QuerySwitch component={Component} context={ShiftModalDataContext} />;
};
