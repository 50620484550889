import { Flex } from 'components/Flex';

import { OrderStatusEnum, TimeSheetStatusEnum } from 'types/graphql-types';
import { UnableToEditShiftMessage } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.styled';
import { useTranslation } from 'react-i18next';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEditCompletedShiftsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';

export const UnableToEditMessage = () => {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { isMobile } = useResponsive();
  const { value: locationId } = getCurrentlySelectedLocation();

  const isEditCompletedShiftsFeatureEnabled = useSelector(
    isEditCompletedShiftsFeatureEnabledSelector(Number.parseInt(locationId)),
  );

  const isCompletedShiftNotEditable =
    data.fetchOrder?.status?.value === OrderStatusEnum.completed &&
    !data.fetchOrder?.isEditable &&
    isEditCompletedShiftsFeatureEnabled;

  const message = useMemo(() => {
    if (!isCompletedShiftNotEditable) return null;

    if (data.fetchOrder?.orderSchedule.timeSheet?.status.value === TimeSheetStatusEnum.approved) {
      return t('shift_modal:UNABLE_TO_EDIT_SHIFT_AFTER_TIME_SHEET_APPROVAL');
    }

    if (data.fetchOrder?.orderSchedule.timeSheet?.status.value === TimeSheetStatusEnum.no_show) {
      return t('shift_modal:UNABLE_TO_EDIT_SHIFT_AFTER_TIME_SHEET_NO_SHOW');
    }

    return (
      <Fragment>
        <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
          {t('shift_modal:UNABLE_TO_EDIT_SHIFT_POST_COMPLETION', {
            hours:
              data.fetchOrder.community.b2bUserShiftEditThreshold &&
              Math.floor(data.fetchOrder.community.b2bUserShiftEditThreshold),
          })}
        </UserGuard>
        <UserGuard userType={[UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY]}>
          {t('shift_modal:UNABLE_TO_EDIT_SHIFT_POST_COMPLETION', {
            hours:
              data.fetchOrder.community.secondaryUserShiftEditThreshold &&
              Math.floor(data.fetchOrder.community.secondaryUserShiftEditThreshold),
          })}
        </UserGuard>
      </Fragment>
    );
  }, [data, t, isCompletedShiftNotEditable]);

  if (!message) return null;

  return (
    <Flex margin={isMobile ? '10px 0 0 0' : undefined}>
      <UnableToEditShiftMessage>{message}</UnableToEditShiftMessage>
    </Flex>
  );
};
