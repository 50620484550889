import {
  defaultEndTime,
  defaultStartTime,
  endOfDay,
  ICustomFields,
  startOfDay,
  timeOffModalRoutingService,
  titleOptions,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  VALIDATION_durationMin1Hr,
  VALIDATION_endDate,
  VALIDATION_without_msg,
} from 'components/TimeOffModal/TimeOffModalView.validation.constants';
import { updateTimeOffPayloadTransformer } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.constants';
import { useEditTimeOffViewDataContext } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.data.context';
import { IEditTimeOffViewForm } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.types';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { API_PUT_updateTimeOff } from 'requests/PUT_updateTimeOff';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { timeOffModalFetchTimeOffById_fetchTimeOff_jane } from 'types/graphql-types';
import { combineMsgs } from 'validations/index.utils';
import { useTranslation } from 'hooks/useTranslation';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import selectedScheduleFacilityDataSelector from 'store/selectors/scheduleSelectors/selectedScheduleFacilityDataSelector';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

export type EditTimeOffViewFormContextType = UseFormBehaviors<IEditTimeOffViewForm> & {
  jane: timeOffModalFetchTimeOffById_fetchTimeOff_jane;
};

export const EditTimeOffViewFormContext = createContext<EditTimeOffViewFormContextType>(
  {} as EditTimeOffViewFormContextType,
);

export const EditTimeOffViewFormProvider: FC = ({ children }) => {
  const {
    data: {
      fetchTimeOff: {
        displayId,
        customFields,
        endTime: rawEndTime,
        jane,
        notes,
        startTime: rawStartTime,
        title,
      },
    },
    timeOffCustomFields,
  } = useEditTimeOffViewDataContext();

  const selectedScheduleFacilityData = useSelector(selectedScheduleFacilityDataSelector);
  const time_zone = selectedScheduleFacilityData?.location?.time_zone ?? undefined;

  const browserTimeZone = DateTime.fromISO(rawStartTime).zoneName;

  const startTime = DateTime.fromISO(rawStartTime).setZone(time_zone);
  const endTime = DateTime.fromISO(rawEndTime).setZone(time_zone);

  // Filter out invalid time offs that have been deleted on the org level but still exist in the time off entity
  const validCustomFields = customFields.filter((cf) =>
    timeOffCustomFields.map((field) => field.id).includes(cf.customFieldId),
  );

  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data) => {
      const {
        allDay,
        startDate,
        jane,
        endDate,
        startTime,
        endTime,
        title,
        notes,
        customFields,
        ...rest
      } = data;
      const payloadTransformer = updateTimeOffPayloadTransformer({
        allDay,
        startDate,
        endDate,
        startTime,
        endTime,
        title,
        notes,
        customFields: { ...rest },
        timeOffCustomFields,
      });
      const apiRequestConfig = API_PUT_updateTimeOff(payloadTransformer, displayId);
      await apiRequest(apiRequestConfig, {
        onSuccess: (res) => {
          toastService.success(t('success:TIME_OFF_EDITED'));
          window.scheduleUtils.forceDataUpdate();
          timeOffModalRoutingService.close();
        },
        onError: handleGenericErrorMessage,
      });
    },
    [timeOffCustomFields, displayId, apiRequest, t],
  );

  const customEntries = validCustomFields.reduce(
    (acc: ICustomFields, cf: { name: string; value: string }) => {
      acc[cf.name] = cf.value;
      return acc;
    },
    {},
  );

  const startTimeHoursMinSec = startTime.toFormat('HH:mm:ss');
  const endTimeHoursMinSec = endTime.toFormat('HH:mm:ss');

  let modifiedStartTime = startTimeHoursMinSec;
  let modifiedEndTime = endTimeHoursMinSec;
  const allDay =
    startTimeHoursMinSec === startOfDay[0] && endOfDay.some((t) => t === endTimeHoursMinSec);
  if (allDay) {
    modifiedStartTime = defaultStartTime;
    modifiedEndTime = defaultEndTime;
  }

  const endTimeWithBrowserTimeZone = endTime
    .setZone(`${browserTimeZone}`)
    .set({
      day: endTime.get('day'),
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
      second: endTime.get('second'),
    })
    .toString();

  let modifiedEndDate = endTimeWithBrowserTimeZone;
  const isEndTimeInvalidDropdownOption = endOfDay.some((t) => t === endTimeHoursMinSec);
  if (!allDay && isEndTimeInvalidDropdownOption)
    modifiedEndDate = DateTime.fromISO(modifiedEndDate).plus({ minute: 1 }).toString();

  const startTimeWithBrowserTimeZone = startTime
    .setZone(`${browserTimeZone}`)
    .set({
      day: startTime.get('day'),
      hour: startTime.get('hour'),
      minute: startTime.get('minute'),
      second: startTime.get('second'),
    })
    .toString();

  const initialState = {
    notes,
    title: [titleOptions.find((t) => t.key === title.name)?.value || ''],
    allDay,
    startDate: [startTimeWithBrowserTimeZone],
    startTime: [modifiedStartTime],
    endDate: [modifiedEndDate],
    endTime: allDay
      ? [modifiedEndTime]
      : isEndTimeInvalidDropdownOption
      ? startOfDay
      : [endTimeHoursMinSec],
    customFields: validCustomFields,
    ...customEntries,
  };

  const useFormBehaviorsOptions: UseFormBehaviors_Options<IEditTimeOffViewForm> = {
    initialState: initialState,
    onSubmit: handleSubmit,
    isDirtyCheckEnabled: true,
    type: 'EDIT',
    validations: {
      startDate: VALIDATION_without_msg,
      startTime: VALIDATION_without_msg,
      endDate: VALIDATION_endDate,
      endTime: combineMsgs(VALIDATION_durationMin1Hr, VALIDATION_without_msg),
    } as unknown as UseFormBehaviors_Options<IEditTimeOffViewForm>['validations'],
  };

  const form = useFormBehaviors<IEditTimeOffViewForm>(useFormBehaviorsOptions);
  const value = useMemo(
    () => ({
      ...form,
      jane,
    }),
    [form, jane],
  );

  return (
    <EditTimeOffViewFormContext.Provider value={value}>
      {children}
    </EditTimeOffViewFormContext.Provider>
  );
};

export const useEditTimeOffViewFormContext = (): EditTimeOffViewFormContextType => {
  return useContext(EditTimeOffViewFormContext);
};
