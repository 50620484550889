import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { selectedScheduleFacilityIdSelector } from 'store/selectors/scheduleSelectors/selectedScheduleFacilityIdSelector';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isCapacityPlanningFeatureEnabledSelector = createSelector(
  selectedScheduleFacilityIdSelector,
  getUserPermissions,
  (communityId, userPermissions) => {
    if (userPermissions?.CAPACITY_PLANNING?.access)
      return (
        userPermissions.CAPACITY_PLANNING.access.includes('read') &&
        !!userPermissions.CAPACITY_PLANNING.communities &&
        !!userPermissions.CAPACITY_PLANNING.communities[communityId] &&
        !!userPermissions.CAPACITY_PLANNING.communities[communityId].includes('read')
      );
    else return false;
  },
);
