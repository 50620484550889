import styled, { css } from 'styled-components';
import { BKJTableSubHeaderWrapper } from 'components/BKJTable/BKJTableSubHeader/BKJTableSubHeader.styled';
import { zIndexLayers } from 'constants/zIndexLayers';

export const BKJTableWrapper = styled.div`
  overflow: overlay;
  height: 100%;
  max-width: 100%;
`;

export const BKJTableHeader = styled.div`
  position: sticky;
  background-color: ${({ theme }) => theme.colors.White};
  top: 0;
  z-index: ${zIndexLayers.pageTableHeader};
`;

export const BKJTableRow = styled.div`
  display: grid;
  border-collapse: collapse;
  /* grid-template-columns is required */
`;

export const BKJTableHead = styled.div`
  background-color: white;
  ${({ theme }) => theme.common.Ellipsis};
  text-align: left;
  svg {
    cursor: pointer;
  }
  width: 100%;
  min-width: max-content;

  &:nth-child(1) {
    ${BKJTableSubHeaderWrapper} {
      padding-left: 40px;
    }
  }
`;

export const BKJStickyTableHead = styled(BKJTableHead)`
  position: sticky;
  top: 0;
  z-index: ${zIndexLayers.pageTableHeader};
`;

export const BKJTableBody = styled.div<{ gridTemplateColumns?: string }>`
  position: relative;
  display: grid;
  border-collapse: collapse;
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      grid-template-columns: ${gridTemplateColumns};
    `};
`;

export const BKJTableFooter = styled.div`
  width: 100%;
  height: 64px;
  max-height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  button {
    cursor: pointer;
  }
  background-color: ${({ theme }) => theme.colors.White};
  ${({ theme }) => theme.shadows.Default};
  z-index: ${zIndexLayers.pageHeader};
`;
