import styled from 'styled-components';

export const InfiniteScrollLoadingWrapper = styled.div<{ ref: unknown }>`
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  background: none;
  outline: none;
  border: none;
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
