import { BKJButton, BKJTextInput, BKJToggleInput, Flex } from '@bookjane2/bookjane-design-library';
import { BKJForm, BKJFieldErrorMessage } from 'components/FormWrapper';
import { H3, P } from 'components/Typography';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { useTranslation } from 'hooks/useTranslation';
import { ShiftSwapLocationSettingsPageDataProvider } from 'pages/LocationSettingsPage/ShiftSwapLocationSettingsPage.data.context';
import {
  ShiftSwapLocationSettingsPageFormProvider,
  useShiftSwapLocationSettingsPageFormContext,
} from 'pages/LocationSettingsPage/ShiftSwapLocationSettingsPageForm.form.context';
import {
  ReadViewTableCell,
  ReadViewTableWrapper,
} from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.styled';
import { useLocationRecordDetailDataContext } from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { BKJFormLabel } from 'pages/SettingsPage/SettingsPage.styled';
import { FC, Fragment } from 'react';
import { DEFAULT_MIN_HOURS, DEFAULT_MAX_HOURS } from './ShiftSwap.constants';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

const InnerShiftSwapForm: FC = () => {
  const { values, onChange, apiErrors } = useShiftSwapLocationSettingsPageFormContext();
  const { t } = useTranslation();
  return (
    <Fragment>
      <Flex flexDirection="column">
        <BKJFormLabel>{t('location_settings:SET_SHIFT_SWAP_TIME_LIMIT')}</BKJFormLabel>
        <Flex alignItems="center">
          <Flex maxWidth="70px">
            <BKJTextInput
              type="number"
              name="min_hours_before_shift_swap"
              onChange={onChange}
              value={values.min_hours_before_shift_swap}
              error={!!apiErrors?.error_messages?.error_message}
              min={DEFAULT_MIN_HOURS}
              max={DEFAULT_MAX_HOURS}
              step={1}
            />
          </Flex>
          &nbsp;&nbsp;&nbsp;
          <P error={!!apiErrors?.error_messages?.error_message}>
            {t('location_settings:HOURS_BEFORE_SHIFT_START_TIME')}
          </P>
        </Flex>
        {apiErrors?.error_messages?.error_message && (
          <BKJFieldErrorMessage>{apiErrors?.error_messages?.error_message}</BKJFieldErrorMessage>
        )}
      </Flex>
    </Fragment>
  );
};

const EditView: FC = () => {
  const { data } = useLocationRecordDetailDataContext();
  const { onSubmit, values, onChange } = useShiftSwapLocationSettingsPageFormContext();

  const { t } = useTranslation();

  return (
    <Fragment>
      <BKJForm onSubmit={onSubmit} width="100%" gap="40px">
        <H3>{data.name}</H3>
        <P>{t('location_settings:SHIFT_SWAP_TURN_ON_NOTE')}</P>
        <Flex alignItems="center">
          <BKJFormLabel>
            {t('location_settings:ALLOW_SHIFT_SWAP', {
              value: values.shift_swap_enabled
                ? t('location_settings:OFF')
                : t('location_settings:ACTIVE'),
            })}
          </BKJFormLabel>
          &nbsp;&nbsp;&nbsp;
          <BKJToggleInput
            variant="Switch"
            onChange={onChange}
            name="shift_swap_enabled"
            checked={values.shift_swap_enabled}
          />
        </Flex>

        {values.shift_swap_enabled && <InnerShiftSwapForm />}
        <BKJButton variant="PurpleSolid" width="192px" minWidth="192px" type="submit">
          {t('auth:SAVE')}
        </BKJButton>
      </BKJForm>
    </Fragment>
  );
};

const ReadView: FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { values } = useShiftSwapLocationSettingsPageFormContext();
  const { data } = useLocationRecordDetailDataContext();
  const { t } = useTranslation();
  return (
    <Fragment>
      <Flex flexDirection="column" gap="22px">
        <Flex justifyContent="space-between">
          <H3>{data.name}</H3>
          <UserGuard
            userType={[UserPermissionEnum.USER_TYPE_AGENCY, UserPermissionEnum.USER_TYPE_B2B]}
          >
            <BKJButton
              variant="PurpleOutlined"
              width="192px"
              minWidth="192px"
              onClick={() => {
                history.push(`${match.url}/edit`);
              }}
            >
              {t('location_settings:EDIT')}
            </BKJButton>
          </UserGuard>
        </Flex>
        <P>{t('location_settings:SHIFT_SWAP_TURN_ON_NOTE')}</P>
        <P bold>
          {t('location_settings:SHIFT_SWAP')}:&nbsp;
          <P>
            {values.shift_swap_enabled ? t('location_settings:ACTIVE') : t('location_settings:OFF')}
          </P>
        </P>
        {values.shift_swap_enabled && (
          <Fragment>
            <ReadViewTableWrapper>
              <ReadViewTableCell bgColor="Grey1" topBorder>
                {t('location_settings:SHIFT_SWAPS_POST_NOTE')}
              </ReadViewTableCell>
              <ReadViewTableCell bgColor="Grey1" topBorder>
                {values.min_hours_before_shift_swap}&nbsp;
                {t('location_settings:HOURS_BEFORE_SHIFT_START_TIME')}
              </ReadViewTableCell>
            </ReadViewTableWrapper>
          </Fragment>
        )}
      </Flex>
    </Fragment>
  );
};

const Component: FC = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path} component={ReadView} />
      <Route path={`${match.path}/edit`} component={EditView} />
    </Switch>
  );
};

export const ShiftSwapLocationSettingsPageForm = () => {
  return (
    <ShiftSwapLocationSettingsPageDataProvider>
      <ShiftSwapLocationSettingsPageFormProvider>
        <Component />
      </ShiftSwapLocationSettingsPageFormProvider>
    </ShiftSwapLocationSettingsPageDataProvider>
  );
};
