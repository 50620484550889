import { RequestTeamMemberFilterBarWrapper } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberFilterBar.styled';
import { FC } from 'react';

export const RequestTeamMemberFilterBar: FC<{ isOpen: boolean }> = ({ isOpen, children }) => {
  return (
    <RequestTeamMemberFilterBarWrapper isOpen={isOpen}>
      {children}
    </RequestTeamMemberFilterBarWrapper>
  );
};
