import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { AutoRebidDialogProps } from 'components/AutoRebidDialog/AutoRebidDialog.types';
import { AUTO_EXTEND_BID } from 'constants/endpoints';
import { METHOD_PATCH } from 'requests';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { ApiRequestConfigType } from 'requests/index.types';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

interface AutoRebidDialogForm {
  handleAutoRebid: () => void;
}

const API_PATCH_extendAutoBid = (shift_id: number): ApiRequestConfigType => ({
  __typename: 'LegacyAPI',
  url: AUTO_EXTEND_BID(`${shift_id}`),
  method: METHOD_PATCH,
});

export const AutoRebidDialogFormContext = createContext({} as AutoRebidDialogForm);

export const AutoRebidDialogFormProvider: FC<AutoRebidDialogProps> = ({
  children,
  orderId,
  onSuccess,
}) => {
  const { apiRequest } = useAPIActions();

  const handleAutoRebid = useCallback(
    async () =>
      await apiRequest(API_PATCH_extendAutoBid(orderId), {
        onSuccess,
        onError: handleGenericErrorMessage,
      }),
    [apiRequest, onSuccess, orderId],
  );

  const contextValue = useMemo(
    () => ({
      handleAutoRebid,
    }),
    [handleAutoRebid],
  );

  return (
    <AutoRebidDialogFormContext.Provider value={contextValue}>
      {children}
    </AutoRebidDialogFormContext.Provider>
  );
};

export const useAutoRebidDialogFormContext = () => useContext(AutoRebidDialogFormContext);
