import {
  PageContentWrapperContextProvider,
  usePageContentWrapperContext,
} from 'components/AuthPageContentWrapper/AuthPageContextWrapper.context';
import { IAuthPageContentWrapper } from 'components/AuthPageContentWrapper/AuthPageContextWrapper.types';
import { SSFBarsContext } from 'components/SSFBars/SSFBars.context';
import { elementDimensions, ElementDimensionsType } from 'constants/elementDimensions';
import { FC, useContext } from 'react';
import { StyledAuthPageContentWrapper } from './AuthPageContentWrapper.styled';

export const calcOffsetHeight = (offsetElements: ElementDimensionsType[]) => {
  return offsetElements.map((key) => elementDimensions[key]).reduce((acc, itr) => acc + itr, 0);
};

export const Component: FC<Omit<IAuthPageContentWrapper, 'offsetElements'>> = (props) => {
  const { children, className } = props;

  const { heights, hasSSFBars } = usePageContentWrapperContext();
  const { isOpen } = useContext(SSFBarsContext);

  return (
    <StyledAuthPageContentWrapper
      isSSFBottomBarOpen={isOpen}
      headerOffset={heights}
      hasSSFBars={hasSSFBars}
      className={className}
    >
      {children}
    </StyledAuthPageContentWrapper>
  );
};

export const AuthPageContentWrapper: FC<IAuthPageContentWrapper> = ({
  offsetElements,
  ...props
}) => {
  return (
    <PageContentWrapperContextProvider offsetElements={offsetElements}>
      <Component {...props} />
    </PageContentWrapperContextProvider>
  );
};
