import { UPDATE_SHIFT_SWAP } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_GET_disableCustomerShiftSwap = (id: string): ApiRequestConfigType => {
  const payload = {
    community: {
      enabled: false,
    },
  };

  return {
    method: METHOD_POST,
    url: UPDATE_SHIFT_SWAP(id),
    __typename: 'LegacyAPI',
    body: payload,
  };
};
