import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import {
  InviteStepLine,
  InviteSubtitle,
  InviteText,
  InviteTitle,
  InviteViewWrapper,
} from 'components/InviteTeamMembersModal/InviteTeamMembers.styled';
import { InviteTeamMemberModalTemplate } from 'components/InviteTeamMembersModal/InviteTeamMembersModalTemplate';
import { FC } from 'react';
import { ISuccessViewProps } from './SuccessView.types';

const RemainingSteps: FC<ISuccessViewProps & { numInvites: number }> = ({ view, numInvites }) => {
  switch (view) {
    case 'CSVView':
      return (
        <InviteViewWrapper flexDirection="column" childMarginBottom="32px">
          <InviteTitle>Invite Successful</InviteTitle>
          <InviteText>
            All {numInvites} team members will be notified to download the BookJane app and will be
            automatically approved after they sign up.
          </InviteText>
          <Flex flexDirection="column" childMarginBottom="10px">
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" />
              <InviteSubtitle>Invited</InviteSubtitle>
            </Flex>
            <InviteStepLine />
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" />
              <InviteSubtitle>Profile Complete</InviteSubtitle>
            </Flex>
            <InviteStepLine />
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" color="Grey6" />
              <InviteSubtitle>Mobile Sign Up</InviteSubtitle>
            </Flex>
          </Flex>
        </InviteViewWrapper>
      );
    default:
      return (
        <InviteViewWrapper flexDirection="column" childMarginBottom="32px">
          <InviteTitle>Invite Successful</InviteTitle>
          <InviteText>
            All {numInvites} team members will be notified to download the BookJane app and will be
            automatically approved after they sign up.
          </InviteText>
          <Flex flexDirection="column" childMarginBottom="10px">
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" />
              <InviteSubtitle>Invited</InviteSubtitle>
            </Flex>
            <InviteStepLine />
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" color="Grey6" />
              <InviteSubtitle>Mobile Sign Up</InviteSubtitle>
            </Flex>
            <InviteStepLine />
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" color="Grey6" />
              <InviteSubtitle>Profile Complete</InviteSubtitle>
            </Flex>
            <InviteStepLine />
            <Flex childMarginRight="6px">
              <BKJIcon iconName="StepCheck" color="Grey6" />
              <InviteSubtitle>Approved</InviteSubtitle>
            </Flex>
          </Flex>
        </InviteViewWrapper>
      );
  }
};

export const SuccessView: FC<ISuccessViewProps> = (props) => {
  const { view, numInvites = 0, onClose } = props;
  return (
    <InviteTeamMemberModalTemplate>
      <RemainingSteps view={view} numInvites={numInvites} />
      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton width="180px" variant="GreenSolid" onClick={onClose}>
          Done
        </BKJButton>
      </BKJSideModalFooter>
    </InviteTeamMemberModalTemplate>
  );
};
