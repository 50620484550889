import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isAccushieldSyncingFeatureEnabledSelector = createSelector(
  getUserPermissions,
  (userPermissions) => {
    if (!userPermissions) return true; // default to true if permissions are not loaded yet
    if (!userPermissions?.ACCUSHIELD_SYNCING) return false;
    return userPermissions?.ACCUSHIELD_SYNCING?.access.includes('read');
  },
);
