import { BKJForm } from 'components/FormWrapper';
import { H3 } from 'components/Typography';
import {
  GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_TITLE,
  GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_DESCRIPTION,
} from 'pages/LocationSettingsPage/GeneralLocationSettingsPage.constants';
import { useLocationRecordDetailDataContext } from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { H4, H5, P } from 'pages/SettingsPage/SettingsPage.styled';
import { FC } from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const GeneralLocationSettingsRead: FC = () => {
  const { data } = useLocationRecordDetailDataContext();
  const { t } = useTranslation();

  return (
    <BKJForm width="672px" maxWidth="672px" gap="22px">
      <H3>{data?.name}</H3>

      <H4>Address</H4>

      <H5>Location Address</H5>
      <P>{data?.full_address || '-'}</P>

      <H5>Suite/Unit No.</H5>
      <P>{data?.flat || '-'}</P>

      <H4>{t(GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_TITLE)}</H4>
      <P>{t(GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_DESCRIPTION)}</P>

      <H5>Name</H5>
      <P>{data?.first_name && data?.last_name ? `${data.first_name} ${data.last_name}` : '-'}</P>

      <H5>Email</H5>
      <P>{data?.email || '-'}</P>

      <H5>Phone</H5>
      <P>{data?.phone || '-'}</P>
    </BKJForm>
  );
};
