import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import { css } from 'styled-components';
import {
  Jane,
  JaneName,
  JaneFacility,
  ShiftJaneWrapper,
} from 'components/ShiftModal/src/components/ShiftJane/ShiftJane.styled';

import { timeOffFetchJane_fetchJane } from 'types/graphql-types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export function TimeOffJane({
  avatarUrls,
  firstName,
  lastName,
  communityName,
  pendingSignup,
}: timeOffFetchJane_fetchJane) {
  return (
    <ErrorBoundary componentName="TimeOffJane" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <ShiftJaneWrapper>
        <Jane justifyContent="space-between" alignItems="center" width="100%">
          <Flex alignItems="center">
            <JaneAvatar
              url={avatarUrls.small}
              status={'in_progress'}
              css={css`
                margin-right: 13px;
              `}
              size={30}
              pendingSignup={pendingSignup}
            />
            <Flex flexDirection="column">
              <JaneName>{`${firstName} ${lastName}`}</JaneName>
              <JaneFacility>{communityName}</JaneFacility>
            </Flex>
          </Flex>
        </Jane>
      </ShiftJaneWrapper>
    </ErrorBoundary>
  );
}
