import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { BKJTableWrapper } from 'components/BKJTable/BKJTable.styled';
import {
  BoldText,
  Text,
} from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.styled';
import { Flex } from 'components/Flex';
import AuditTrailHeader from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailHeader';
import { AuditTrailTableView } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailTableView';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { css } from 'styled-components';
import {
  AuditTrailDataProvider,
  useAuditTrailDataContext,
} from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.data.context';
import { GET_callOutListCSV } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.constants';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { saveResponseAsCSV } from 'utils/saveResponseAsCSV';
import { shiftModalOrderDetailsCalloutList_node_CalloutList } from 'types/graphql-types';
import { CSVFooterDisclaimer } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailTableView.styled';
import { HARDCODED_SHOW_AUDIT_TRAIL_CSV_EXPORT_DISCLAIMER } from 'constants/hardcoded';
import { DateTime } from 'luxon';

export function DetailViewFooter() {
  const { t } = useTranslation();
  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const {
    data,
    estimatedTimeRemaining: { mins, hours, days },
  } = useAuditTrailDataContext();

  const callout_list = `${data?.node?.id}`;

  const exportShiftId = (data?.node as shiftModalOrderDetailsCalloutList_node_CalloutList)?.order
    ?.orderSchedule?.id;

  const requestOptions = GET_callOutListCSV({
    callout_list,
    data: 'csv',
  });

  const { apiRequest } = useAPIActions();

  const exportCallOutListCSV = useCallback(() => {
    apiRequest(requestOptions, {
      onSuccess: (response) => {
        saveResponseAsCSV(
          response,
          `${t('shift_modal:AUDIT_TRAIL_SHIFT_ID')} #${exportShiftId} - ${t(
            'shift_modal:EXPORTED',
          )} ${DateTime.now().toFormat('MMM d yyyy')}.csv`,
        );
      },
    });
  }, [apiRequest, exportShiftId, requestOptions, t]);

  const {
    totalNotifiedAndSkipped = 0,
    totalJanes = 0,
    order: { biddable = false } = {},
  } = !!data && !!data?.node
    ? (data.node as shiftModalOrderDetailsCalloutList_node_CalloutList)
    : {};

  const BiddableFooterView = useCallback(() => {
    return (
      <Fragment>
        <BoldText>{t('shift_modal:BIDDING_SHIFT_SENT')} </BoldText>
        <Text>
          {t('shift_modal:SENT_OUT_TO_TEAM_MEMBERS', {
            count: totalNotifiedAndSkipped,
            totalCount: totalJanes,
          })}
        </Text>
      </Fragment>
    );
  }, [t, totalJanes, totalNotifiedAndSkipped]);

  const CalloutFooter = useCallback(() => {
    return (
      <Fragment>
        <Text>
          <BoldText>{t('shift_modal:CALL_OUT_PROGRESS')}: </BoldText>
          {totalNotifiedAndSkipped}/{totalJanes}
        </Text>
        <Text>
          <BoldText>{t('shift_modal:EST_TIME_REMAINING')}: </BoldText>
          {days > 0 && <span>{days}d&nbsp;</span>}
          {hours > 0 && <span>{hours}hr&nbsp;</span>}
          {mins > 0 && <span>{mins}m&nbsp;</span>}
          {days <= 0 && hours <= 0 && mins <= 0 && <span>-</span>}
        </Text>
      </Fragment>
    );
  }, [days, hours, mins, t, totalJanes, totalNotifiedAndSkipped]);

  if (isReadOnly || !data) return <Fragment />;

  return (
    <BKJSideModalFooter>
      <Flex justifyContent="space-between" width="100%">
        <Flex flexDirection="column" width="100%">
          {biddable ? <BiddableFooterView /> : <CalloutFooter />}
        </Flex>
        <Flex justifyContent="flex-end">
          <BKJButton
            width="180px"
            variant="PurpleOutlined"
            css={css`
              margin-right: 8px;
            `}
            onClick={exportCallOutListCSV}
          >
            {t('shift_modal:EXPORT_CSV')}
          </BKJButton>
        </Flex>
      </Flex>
    </BKJSideModalFooter>
  );
}

export const CSVFooter = () => (
  <BKJSideModalFooter>
    <Flex padding="32px 32px 32px 0">
      <CSVFooterDisclaimer>
        Note: For details on how the values in the CSV export are ranked, please refer to the help
        desk.
      </CSVFooterDisclaimer>
    </Flex>
  </BKJSideModalFooter>
);

export const AuditTrailTabView = () => {
  return (
    <AuditTrailDataProvider>
      <BKJTableWrapper>
        <Flex flexDirection="column">
          <AuditTrailHeader />
          <AuditTrailTableView />
        </Flex>
      </BKJTableWrapper>
      {HARDCODED_SHOW_AUDIT_TRAIL_CSV_EXPORT_DISCLAIMER && <CSVFooter />}
      <DetailViewFooter />
    </AuditTrailDataProvider>
  );
};
