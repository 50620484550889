import {
  AccordionItem,
  AccordionText,
} from 'components/SettingsNavigation/SettingsNavigation.styled';
import { FC, useEffect, useRef } from 'react';

interface SettingsNavMenuItemProps {
  active?: boolean;
  label: string;
  scrollElement: HTMLUListElement | null;
}

export const SettingsNavMenuItem: FC<SettingsNavMenuItemProps> = ({
  active,
  label,
  scrollElement,
  ...rest
}) => {
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (ref.current && active && scrollElement) {
      scrollElement.scrollTo(0, ref.current.offsetTop - 95);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AccordionItem active={active} {...rest} ref={ref}>
      <AccordionText data-cy="leftpane-facilityName">{label}</AccordionText>
    </AccordionItem>
  );
};
