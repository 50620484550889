import { Fragment } from 'react';
import { Caption } from 'pages/CreateShiftsPage/BulkCreateShiftView/BulkCreateShiftView.styled';
import { useCreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { useTranslation } from 'hooks/useTranslation';
import { Facility } from './ShiftSummaryView.styled';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import { getShiftsCount } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import {
  useLocationNameLabelContext,
  LocationNameLabelProvider,
} from 'pages/CreateShiftsPage/LocationNameLabel.data.context';
import { formatPlural } from 'utils/formatPlural';

const ShiftCountAndLocationLabelComponent = () => {
  const { t } = useTranslation();
  const { groupedFormsBehaviors } = useCreateShiftsPageContext();
  const createShifts = groupedFormsBehaviors.groupedForms as unknown as ICreateShift[];
  const shiftsCount = getShiftsCount(createShifts);

  const { locationName } = useLocationNameLabelContext();

  return (
    <Caption>
      {shiftsCount} {formatPlural(t('schedule:SHIFT').toLocaleLowerCase(), shiftsCount)}
      {locationName && (
        <Fragment>
          {' '}
          {t('create_shifts:FOR')} <Facility>{locationName}</Facility>
        </Fragment>
      )}
    </Caption>
  );
};

export const ShiftCountAndLocationLabel = ({ locationId }: { locationId: number }) => (
  <LocationNameLabelProvider locationId={locationId}>
    <ShiftCountAndLocationLabelComponent />
  </LocationNameLabelProvider>
);
