import { BKJIcon } from '@bookjane2/bookjane-design-library';
import React from 'react';
import { TableSortWrapper } from './BKJTableSubHeader.styled';
import { BKJTableDirectionIconProps } from './BKJTableSubHeader.types';

export const BKJTableDirectionIcon: React.FC<BKJTableDirectionIconProps> = ({
  direction,
  name,
  onClick,
}): JSX.Element => {
  return (
    <TableSortWrapper role="button" name={name} value={direction} onClick={onClick}>
      {direction === 'asc' && <BKJIcon iconName="CombinedChevronUp" />}
      {direction === 'desc' && <BKJIcon iconName="CombinedChevronDown" />}
      {direction === 'default' && <BKJIcon iconName="CombinedChevronDefault" />}
    </TableSortWrapper>
  );
};
