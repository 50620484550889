import { AutoMargin } from 'components/AutoMargin';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { HourSelectInput } from 'components/HourSelectInput/HourSelectInput';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  SendToAgenciesText,
  SendToAgenciesViewTitle,
  SendToAgenciesContainer,
  ConfirmationButtons,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { hourDropdownOptions } from 'utils/hourDropdownOptions';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSendToAgenciesPropsContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.props.context';
import { useSelector } from 'react-redux';
import { isSendToSelectedAgenciesEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { DateTime } from 'luxon';

export const PreRequestOptionsView: FC = () => {
  const { t } = useTranslation();
  const {
    data: {
      fetchOrder: { startAt },
    },
  } = useShiftModalDataContext();
  const { values, onChange } = useSendToAgenciesFormContext();
  const { goToNextView } = useSendToAgenciesPropsContext();

  const { timeZone } = getCurrentlySelectedLocation();

  const canSendToSelectedAgencyCommunities = useSelector(isSendToSelectedAgenciesEnabledSelector);

  const isAllMembersSelected = values.send_to_agencies_target === 'all';
  const isRequestMemberSelected = values.send_to_agencies_target === 'request_member';
  const isRequestAgenciesSelected = values.send_to_agencies_target === 'request_agencies';

  const hourOpts = useMemo(() => {
    const beforeShiftStartsLabel = t('shift_modal:BEFORE_SHIFT_STARTS');
    const translatedHourDropdownOptions = hourDropdownOptions(beforeShiftStartsLabel);

    return [
      {
        id: 'now',
        value: 'now',
        label: t('shift_modal:NOW'),
      },
      ...translatedHourDropdownOptions.filter((option) => {
        const startFromNow = DateTime.fromISO(startAt)
          .setZone(timeZone)
          .diff(DateTime.now().setZone(timeZone), 'hours').hours;
        return option.value <= startFromNow;
      }),
    ];
  }, [startAt, t, timeZone]);

  return (
    <Fragment>
      <SendToAgenciesContainer flexDirection="column" flex="1" padding="40px 32px">
        <SendToAgenciesViewTitle>{t('shift_modal:I_WANT_TO')}:</SendToAgenciesViewTitle>

        <BKJRadioInput
          name="send_to_agencies_target"
          value="all"
          onChange={onChange}
          checked={isAllMembersSelected}
          label={t('shift_modal:SEND_TO_ALL_AGENCIES')}
        />

        {canSendToSelectedAgencyCommunities && (
          <BKJRadioInput
            name="send_to_agencies_target"
            value="request_agencies"
            onChange={onChange}
            checked={isRequestAgenciesSelected}
            label={t('shift_modal:CHOOSE_AGENCIES_TO_SEND_TO')}
          />
        )}

        <BKJRadioInput
          name="send_to_agencies_target"
          value="request_member"
          onChange={onChange}
          checked={isRequestMemberSelected}
          label={t('shift_modal:REQUEST_AGENCY_MEMBER')}
        />

        {(isAllMembersSelected || isRequestMemberSelected || isRequestAgenciesSelected) && (
          <SectionDivider variant="Thin" />
        )}

        {(isAllMembersSelected || isRequestAgenciesSelected) && (
          <Flex flexDirection="column" gap="16px">
            <HourSelectInput
              label={`${t('shift_modal:FOR_WHEN')} :`}
              options={hourOpts}
              name="specific_time_selection"
              type="Single"
              onChange={onChange}
              value={values.specific_time_selection}
            />
          </Flex>
        )}
        {isRequestMemberSelected && (
          <SendToAgenciesText>{t('SEND_TO_AGENCIES_REQUEST_MEMBER_SELECTED')}</SendToAgenciesText>
        )}
      </SendToAgenciesContainer>
      <BKJSideModalFooter>
        <AutoMargin />
        <ConfirmationButtons
          variant="PurpleSolid"
          disabled={
            !values.send_to_agencies_target ||
            (values.send_to_agencies_target === 'all' && !values.notification_strategy) ||
            (values.notification_strategy === 'specific_time' && !values.specific_time_selection)
          }
          onClick={goToNextView}
        >
          {t('shift_modal:NEXT')}
        </ConfirmationButtons>
      </BKJSideModalFooter>
    </Fragment>
  );
};
