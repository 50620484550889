import { BKJButtonIconTextInput, Flex } from '@bookjane2/bookjane-design-library';
import { FetchAgencyContextType } from 'components/AgencyGridSelect/AgencyGridSelect.types';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { QuerySwitch } from 'components/QuerySwitch';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { RequestTeamMemberFilterBar } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberFilterBar';
import { PriceFilter } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberView.styled';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSendToAgenciesPropsContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.props.context';
import {
  FetchAgenciesByOrderIdDataContext,
  useFetchAgenciesByOrderIdDataContext,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/FetchAgenciesByOrderId.data.context';
import { useSelectAgenciesViewPropsContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/SelectAgenciesView.props.context';
import { useTranslation } from 'hooks/useTranslation';
import {
  AgencySelectListWrapper,
  AvailableCountText,
  EstimatedTotalPayoutFooterText,
  HeaderWrapper,
  MainTitleContainer,
  Title,
  NextButton,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/SendToSelectedAgenciesView.styled';
import { UseFormBehaviorsEventType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { Fragment, useCallback } from 'react';
import { AgencyGridSelect } from 'components/AgencyGridSelect';
import { useFetchSelectedAgenciesRateDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/FetchSelectedAgenciesRate.data.context';
import { FormatCurrency } from 'components/FormatCurrency';
export const SelectAgenciesViewComponent = () => {
  const { t } = useTranslation();
  const { goToNextView } = useSendToAgenciesPropsContext();
  const { values: formValues, onChange: _onChangeForm } = useSendToAgenciesFormContext();
  const { isOpen, handleFilterClick, fetchAgenciesNoAgenciesErrorMessage } =
    useSelectAgenciesViewPropsContext();

  const { data: selectedAgencyRate } = useFetchSelectedAgenciesRateDataContext();

  const {
    onChange: onChangeSearchFilter,
    values: fetchAgenciesFilterValues,
    data,
  } = useFetchAgenciesByOrderIdDataContext();

  const { data: fetchOrderData } = useShiftModalDataContext();
  const {
    fetchOrder: {
      position: { id: positionId, openPriceRange },
    },
  } = fetchOrderData;

  const onChangeForm = useCallback(
    (e: UseFormBehaviorsEventType) => {
      if (formValues.selectedAgencies.includes(e.target.value as number)) {
        _onChangeForm({
          target: {
            name: 'selectedAgencies',
            value: formValues.selectedAgencies.filter(
              (agencyId) => agencyId !== (e.target.value as number),
            ),
          },
        });
      } else {
        _onChangeForm({
          target: {
            name: 'selectedAgencies',
            value: [...formValues.selectedAgencies, e.target.value as number],
          },
        });
      }
    },
    [_onChangeForm, formValues.selectedAgencies],
  );

  const AgencyGridSelectComponent = useCallback(
    () => (
      <AgencyGridSelect
        name="selectedAgencies"
        selected={formValues.selectedAgencies}
        type="Multiple"
        errorMessage={fetchAgenciesNoAgenciesErrorMessage}
        onChangeForm={onChangeForm}
        positionId={positionId}
        fetchAgenciesContext={
          FetchAgenciesByOrderIdDataContext as unknown as FetchAgencyContextType
        }
      />
    ),
    [fetchAgenciesNoAgenciesErrorMessage, formValues.selectedAgencies, onChangeForm, positionId],
  );

  return (
    <Fragment>
      <HeaderWrapper>
        <MainTitleContainer justifyContent="space-between" alignItems="baseline">
          <Title>{t('shift_modal:CHOOSE_AGENCIES')}</Title>
          {data?.fetchAgencyCommunities.totalCount && (
            <AvailableCountText>{`${data?.fetchAgencyCommunities.totalCount} ${t(
              'shift_modal:AGENCIES_AVAILABLE',
            )}`}</AvailableCountText>
          )}
        </MainTitleContainer>
        <Flex flexDirection="column">
          <BKJButtonIconTextInput
            placeholder={t('shift_modal:SEARCH_AGENCY')}
            buttonLabel={t('shift_modal:FILTERS')}
            name="byName"
            iconName="Search"
            buttonIcon="Filters"
            value={fetchAgenciesFilterValues.byName}
            onChange={onChangeSearchFilter}
            onButtonClick={handleFilterClick}
            autoComplete="off"
            label=""
            required={false}
            error={''}
            disabled={false}
          />
          <RequestTeamMemberFilterBar isOpen={isOpen}>
            {openPriceRange?.min && openPriceRange?.max && (
              <PriceFilter
                priceRange={[openPriceRange.min, openPriceRange.max]}
                name="byRateLessThanOrEqualTo"
                value={[fetchAgenciesFilterValues.byRateLessThanOrEqualTo]}
                onChange={onChangeSearchFilter}
              />
            )}
          </RequestTeamMemberFilterBar>
        </Flex>
      </HeaderWrapper>

      <AgencySelectListWrapper>
        <QuerySwitch
          context={FetchAgenciesByOrderIdDataContext}
          component={AgencyGridSelectComponent}
        />
      </AgencySelectListWrapper>

      <BKJSideModalFooter justifyContent="space-between">
        <EstimatedTotalPayoutFooterText disabled={!!formValues.selectedAgencies.length}>
          Est. {t('common:TOTAL_PAY')}:
          {!!selectedAgencyRate?.cost ? (
            selectedAgencyRate.cost.min === selectedAgencyRate.cost.max ? (
              <FormatCurrency amount={`${selectedAgencyRate.cost.min}`} />
            ) : (
              <Fragment>
                <FormatCurrency amount={`${selectedAgencyRate.cost.min}`} /> -
                <FormatCurrency amount={`${selectedAgencyRate.cost.max}`} />
              </Fragment>
            )
          ) : (
            <FormatCurrency amount="0.0" />
          )}
        </EstimatedTotalPayoutFooterText>
        <NextButton
          width="180px"
          variant="PurpleSolid"
          onClick={goToNextView}
          disabled={formValues.selectedAgencies.length === 0}
        >
          {t('shift_modal:NEXT')}
        </NextButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
