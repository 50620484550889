import {
  CalenderTotalSummaryFooterPropsProvider,
  useCalenderTotalSummaryFooterPropsContext,
} from 'components/ShiftCalendar/CalenderTotalSummaryFooter/CalenderTotalSummaryFooter.props.context';
import { TotalSummaryFooter } from 'components/TotalSummaryFooter';
import { FC } from 'react';

const CalenderTotalSummaryFooterComponent: FC = () => {
  const { monthYear, entries } = useCalenderTotalSummaryFooterPropsContext();

  return <TotalSummaryFooter title={monthYear} entries={entries} />;
};

export const CalenderTotalSummaryFooter: FC = () => (
  <CalenderTotalSummaryFooterPropsProvider>
    <CalenderTotalSummaryFooterComponent />
  </CalenderTotalSummaryFooterPropsProvider>
);
