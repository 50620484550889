import { IBKJComboBoxProps } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBox/BKJComboBox.types';
import { IBKJSelectProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { GroupsOptionType } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.data.context';
import { AddPositionRule } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/AddPositionRule';
import { EditRule } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/EditRule';
import { GroupsRules } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/GroupsRules';
import { ViewDetails } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/ViewDetails';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import i18next from 'i18next';
import { QUERY_settingsGroups } from 'queries';
import { toastService } from 'services';
import { shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules } from 'types/graphql-types';
import { getDaysHoursAndMinutesFromMin } from 'utils/time';

export const ShiftDistributionRulesGroupsComponentMap = {
  GroupsRules,
  AddPositionRule,
  ViewDetails,
  EditRule,
};
export type ShiftDistributionRulesGroupsModalViewType =
  keyof typeof ShiftDistributionRulesGroupsComponentMap;

const MANUAL_APPROVAL_WAIT_TIME_DROPDOWN_VALUE = -1;
const MANUAL_APPROVAL_WAIT_TIME_API_VALUE = null;

export const getWaitTimeLabel = (waitTime: number | null): string =>
  null === waitTime
    ? i18next.t('location_settings:MANUAL_APPROVAL_LABEL')
    : getDaysHoursAndMinutesFromMin(waitTime);

export const waitTimesDropdownOptions = (): IBKJComboBoxProps['options'] =>
  [
    {
      key: MANUAL_APPROVAL_WAIT_TIME_DROPDOWN_VALUE,
      value: `${MANUAL_APPROVAL_WAIT_TIME_DROPDOWN_VALUE}`,
      label: i18next.t('location_settings:MANUAL_APPROVAL_LABEL'),
    },
    {
      key: 0,
      value: '0',
      label: '0 mins',
    },
    {
      key: 0.25,
      value: '15',
      label: '15 mins',
    },
    {
      key: 0.5,
      value: '30',
      label: '30 mins',
    },
    {
      key: 0.75,
      value: '45',
      label: '45 mins',
    },
    {
      key: 1,
      value: '60',
      label: '1 hr',
    },
    {
      key: 1.25,
      value: '75',
      label: '1 hr  15 mins',
    },
    {
      key: 1.5,
      value: '90',
      label: '1 hr  30 mins',
    },
    {
      key: 1.75,
      value: '105',
      label: '1 hr  45 mins',
    },
    {
      key: 2,
      value: '120',
      label: '2 hrs',
    },
    {
      key: 2.25,
      value: '135',
      label: '2 hrs 15 mins',
    },
    {
      key: 2.5,
      value: '150',
      label: '2 hrs 30 mins',
    },
    {
      key: 2.75,
      value: '165',
      label: '2 hrs 45 mins',
    },
    {
      key: 3,
      value: '180',
      label: '3 hrs',
    },
    {
      key: 3.25,
      value: '195',
      label: '3 hrs 15 mins',
    },
    {
      key: 3.5,
      value: '210',
      label: '3 hrs 30 mins',
    },
    {
      key: 3.75,
      value: '225',
      label: '3 hrs 45 mins',
    },
    {
      key: 4,
      value: '240',
      label: '4 hrs',
    },
    {
      key: 4.25,
      value: '255',
      label: '4 hrs 15 mins',
    },
    {
      key: 4.5,
      value: '270',
      label: '4 hrs 30 mins',
    },
    {
      key: 4.75,
      value: '285',
      label: '4 hrs 45 mins',
    },
    {
      key: 5,
      value: '300',
      label: '5 hrs',
    },
    {
      key: 5.25,
      value: '315',
      label: '5 hrs 15 mins',
    },
    {
      key: 5.5,
      value: '330',
      label: '5 hrs 30 mins',
    },
    {
      key: 5.75,
      value: '345',
      label: '5 hrs 45 mins',
    },
    {
      key: 6,
      value: '360',
      label: '6 hrs',
    },
    {
      key: 6.25,
      value: '375',
      label: '6 hrs 15 mins',
    },
    {
      key: 6.5,
      value: '390',
      label: '6 hrs 30 mins',
    },
    {
      key: 6.75,
      value: '405',
      label: '6 hrs 45 mins',
    },
    {
      key: 7,
      value: '420',
      label: '7 hrs',
    },
    {
      key: 7.25,
      value: '435',
      label: '7 hrs 15 mins',
    },
    {
      key: 7.5,
      value: '450',
      label: '7 hrs 30 mins',
    },
    {
      key: 7.75,
      value: '465',
      label: '7 hrs 45 mins',
    },
    {
      key: 8,
      value: '480',
      label: '8 hrs',
    },
    {
      key: 8.25,
      value: '495',
      label: '8 hrs 15 mins',
    },
    {
      key: 8.5,
      value: '510',
      label: '8 hrs 30 mins',
    },
    {
      key: 8.75,
      value: '525',
      label: '8 hrs 45 mins',
    },
    {
      key: 9,
      value: '540',
      label: '9 hrs',
    },
    {
      key: 9.25,
      value: '555',
      label: '9 hrs 15 mins',
    },
    {
      key: 9.5,
      value: '570',
      label: '9 hrs 30 mins',
    },
    {
      key: 9.75,
      value: '585',
      label: '9 hrs 45 mins',
    },
    {
      key: 10,
      value: '600',
      label: '10 hrs',
    },
    {
      key: 10.25,
      value: '615',
      label: '10 hrs 15 mins',
    },
    {
      key: 10.5,
      value: '630',
      label: '10 hrs 30 mins',
    },
    {
      key: 10.75,
      value: '645',
      label: '10 hrs 45 mins',
    },
    {
      key: 11,
      value: '660',
      label: '11 hrs',
    },
    {
      key: 11.25,
      value: '675',
      label: '11 hrs 15 mins',
    },
    {
      key: 11.5,
      value: '690',
      label: '11 hrs 30 mins',
    },
    {
      key: 11.75,
      value: '705',
      label: '11 hrs 45 mins',
    },
    {
      key: 12,
      value: '720',
      label: '12 hrs',
    },
    // New interval

    {
      key: 18,
      value: '1080',
      label: '18 hrs',
    },
    {
      key: 24,
      value: '1440',
      label: '1 day',
    },
    {
      key: 36,
      value: '2160',
      label: '1 day 12 hrs',
    },
    {
      key: 48,
      value: '2880',
      label: '2 days',
    },
    {
      key: 60,
      value: '3600',
      label: '2 days 12 hrs',
    },
    {
      key: 72,
      value: '4320',
      label: '3 days',
    },
    {
      key: 96,
      value: '5760',
      label: '4 days',
    },
    {
      key: 120,
      value: '7200',
      label: '5 days',
    },
    {
      key: 144,
      value: '8640',
      label: '6 days',
    },
    {
      key: 168,
      value: '10080',
      label: '7 days',
    },
  ].map((item) => ({ ...item, key: String(item.key), id: String(item.value) }));

export const skipWaitTimeMinutesThresholdDropdownOptions: IBKJSelectProps['options'] = [
  {
    key: 0,
    value: '0',
    label: '0 mins',
  },
  {
    key: 30,
    value: '30',
    label: '30 mins',
  },
  {
    key: 60,
    value: '60',
    label: '1 hr',
  },
  {
    key: 120,
    value: '120',
    label: '2 hrs',
  },
  {
    key: 180,
    value: '180',
    label: '3 hrs',
  },
  {
    key: 240,
    value: '240',
    label: '4 hrs',
  },
  {
    key: 300,
    value: '300',
    label: '5 hrs',
  },
  {
    key: 360,
    value: '360',
    label: '6 hrs',
  },
  {
    key: 720,
    value: '720',
    label: '12 hrs',
  },
  {
    key: 1080,
    value: '1080',
    label: '18 hrs',
  },
  {
    key: 1440,
    value: '1440',
    label: '24 hrs',
  },
  {
    key: 1800,
    value: '1800',
    label: '30 hrs',
  },
  {
    key: 2160,
    value: '2160',
    label: '36 hrs',
  },
  {
    key: 2520,
    value: '2520',
    label: '42 hrs',
  },
  {
    key: 2880,
    value: '2880',
    label: '48 hrs',
  },
  {
    key: 3240,
    value: '3240',
    label: '54 hrs',
  },
  {
    key: 3600,
    value: '3600',
    label: '60 hrs',
  },
  {
    key: 3960,
    value: '3960',
    label: '66 hrs',
  },
  {
    key: 4320,
    value: '4320',
    label: '72 hrs',
  },
].map((item) => ({ ...item, key: String(item.key), id: String(item.value) }));

const minuteThresholds = skipWaitTimeMinutesThresholdDropdownOptions
  .filter((threshold) => Number(threshold.value) < 60)
  .map((threshold) => Number(threshold.value));

export const fetchGroupsConfig = (locationId: number): ISSFBehaviorsOptions => {
  return {
    key: 'FETCH_GROUPS_FOR_GROUP_RULES_QUERY',
    query: QUERY_settingsGroups,
    paginationType: 'InfiniteScroll',
    nextFetchPolicy: 'network-only',
    debounceTimeout: 500,
    pageSize: 20,
    schema: {
      locationId: {
        initialValue: locationId,
      },
    },
  };
};

export const createInitialGroupRule = (): GroupRule => ({
  wait_time_minutes: '0',
  groupOption: {
    id: '',
    name: '',
    label: '',
    key: 0,
    value: 0,
    type: 'Standard',
  },
});

export const createGroupRule = (): GroupRule => ({
  wait_time_minutes: '',
  groupOption: {
    id: '',
    name: '',
    label: '',
    key: 0,
    value: 0,
    type: 'Standard',
  },
});

export type GroupRule = {
  id?: number;
  wait_time_minutes: string;
  groupOption: GroupsOptionType;
};

export function toGroupRules(
  groupRules?: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules[],
): GroupRule[] | undefined {
  if (!groupRules || groupRules.length <= 0) {
    return undefined;
  }
  return groupRules.map<GroupRule>(({ id, rank, group, waitTimeMinutes }) => ({
    id,
    rank,
    groupOption: {
      id: `${group.id}`,
      key: group.id,
      value: group.id,
      name: group.name,
      label: group.name,
      type: 'Standard',
    },
    wait_time_minutes:
      waitTimeMinutes === MANUAL_APPROVAL_WAIT_TIME_API_VALUE ? `${-1}` : `${waitTimeMinutes}`,
  }));
}

export function toShiftDistributionGroupRules({
  wait_time_minutes,
  groupOption,
}: {
  wait_time_minutes: string;
  groupOption: GroupsOptionType;
}) {
  const waitTimeMins = Number.parseInt(wait_time_minutes);
  return {
    group_id: groupOption.value,
    wait_time_minutes:
      waitTimeMins === MANUAL_APPROVAL_WAIT_TIME_DROPDOWN_VALUE ? null : waitTimeMins,
  };
}

export function updateWaitTimeMinutes({
  waitTimeMinutesOptionValue,
  indexId,
}: {
  waitTimeMinutesOptionValue: string[];
  indexId: number;
}) {
  return function (rule: GroupRule, i: number) {
    return i === indexId ? { ...rule, wait_time_minutes: waitTimeMinutesOptionValue[0] } : rule;
  };
}

export function updateGroupId({
  groupOption,
  indexId,
}: {
  groupOption: GroupsOptionType;
  indexId: number;
}) {
  return function (rule: GroupRule, i: number) {
    return i === indexId ? { ...rule, groupOption } : rule;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function handleApiError(errors: any) {
  if (
    !!errors?.data?.skip_wait_time_threshold_minutes &&
    errors?.data?.skip_wait_time_threshold_minutes.length > 0
  )
    toastService.error(errors.data.skip_wait_time_threshold_minutes[0]);
  else if (!!errors?.data?.community_id && errors.data.community_id.length > 0)
    toastService.error(errors.data.community_id[0]);
  else toastService.error(i18next.t('FAILURE_TOAST_MESSAGE'));
  return void 0;
}

export const getWaitTimeThresholdLabel = (waitTimeThreshold: number) => {
  return minuteThresholds.some((threshold) => threshold === waitTimeThreshold)
    ? `${waitTimeThreshold} mins`
    : `${Math.floor(waitTimeThreshold) / 60} ${
        Math.floor(waitTimeThreshold) / 60 === 1
          ? i18next.t('location_settings:HOURS').slice(0, -1)
          : i18next.t('location_settings:HOURS')
      }`;
};
