import { FC } from 'react';

export const PetalLogo: FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Short company logo">
        <g id="Layer_1-2">
          <g id="Layer_2-2">
            <g id="Layer_1-2_2">
              <path id="Vector" d="M15.77 44L15.76 32.2H4V25.63H22.37V44H15.77Z" fill="white" />
              <path
                id="Vector_2"
                d="M32.23 4L32.24 15.8H44L43.99 22.37H25.63V4H32.23Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
