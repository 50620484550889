import { useFetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import { timeOffModalRoutingService } from 'components/TimeOffModal/TimeOffModal.constants';
import { DetailTimeOffViewTabType } from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.types';
import { createContext, FC, useContext, useMemo, useState } from 'react';

interface IDetailTimeOffViewProps {
  isEditable: boolean;
  goToEditTimeOffView: () => void;
  goToDeleteTimeOffView: () => void;
  activeTab: DetailTimeOffViewTabType;
  goToGeneralTab: () => void;
  goToActivityTab: () => void;
}

export const DetailTimeOffViewPropsContext = createContext<IDetailTimeOffViewProps>(
  {} as IDetailTimeOffViewProps,
);

export const DetailTimeOffViewPropsProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState<DetailTimeOffViewTabType>('General');
  const goToGeneralTab = () => setActiveTab('General');
  const goToActivityTab = () => setActiveTab('Activity');

  const {
    data: {
      fetchTimeOff: { isEditable },
    },
  } = useFetchTimeOffDataContext();

  const goToEditTimeOffView = () => timeOffModalRoutingService.goToView('EditTimeOffView');
  const goToDeleteTimeOffView = () => timeOffModalRoutingService.goToView('DeleteTimeOffView');

  const contextValue = useMemo(
    () => ({
      isEditable,
      goToEditTimeOffView,
      goToDeleteTimeOffView,
      activeTab,
      goToGeneralTab,
      goToActivityTab,
    }),
    [activeTab, isEditable],
  );
  return (
    <DetailTimeOffViewPropsContext.Provider value={contextValue}>
      {children}
    </DetailTimeOffViewPropsContext.Provider>
  );
};

export const useDetailTimeOffViewPropsContext = () => useContext(DetailTimeOffViewPropsContext);
