// @ts-nocheck

import { createCachedSelector } from 're-reselect';
import { createSelector } from '@reduxjs/toolkit';
import { generateRequestId } from '../utils';
import { APIRequestContextType } from 'types/common.types';

const defaultValues: APIRequestContextType = {
  data: null,
  error: null,
  isError: false,
  isLoading: true,
  isPending: false,
  method: null,
  query: null,
  request_id: null,
  status: 'LOADING',
  statusText: null,
  statusCode: null,
  timestamp_utc: null,
  url: null,
};

const getAPI = (state, _) => state.api || defaultValues;
const getRequestOptions = (_, requestOptions) => requestOptions;
const getConfig = (_, config) => config;

export const apiDataSelector = createCachedSelector([getAPI, getConfig], (api, config) => {
  if (typeof config === 'string') {
    if (!api[config]) return defaultValues;
    return api[config];
  }
  if (!api[generateRequestId(config)]) return defaultValues;
  return api[generateRequestId(config)];
})((_state_, config) => {
  if (typeof config === 'string') {
    if (!_state_.api[config]) return new Date().getTime();
    return _state_.api[config].request_id;
  }
  if (!_state_.api[generateRequestId(config)]) return new Date().getTime();
  return _state_.api[generateRequestId(config)].request_id;
});

export const isRequestSeriesLoadingSelector = createSelector(
  [getAPI, getRequestOptions],
  (api, requestOptions) => {
    return requestOptions.some((options) => {
      return !!(!api[generateRequestId(options)] || api[generateRequestId(options)].isLoading);
    });
  },
);
