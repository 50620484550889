import { Flex } from 'components/Flex';
import {
  H3,
  TieringDetailsTableContentCell,
  TieringDetailsTableHeaderCell,
  TieringDetailsWrapper,
  TieringRulesTableHeaderDivider,
  TieringDetailsTableRow,
  TieringDetailsSubHeader,
  TieringDetailsBody,
  TieringTypeCategoryValue,
  TieringTypeCategory,
  TieringTypeTablePadding,
  TieringOverlayBold,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.styled';
import { BKJButton, BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { FC } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useTieringDetailsModalUIContext } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.ui.context';
import {
  TieringDetailsDataProvider,
  useTieringDetailsDataContext,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/views/TieringDetails.data.context';
import {
  ShiftDistributionLocationDataProvider,
  useShiftDistributionLocationDataContext,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/views/ShiftDistributionLocation.data.context';
import { useTranslation } from 'hooks/useTranslation';

export const TieringDetailsViewComponent: FC = () => {
  const { onClose } = useTieringDetailsModalUIContext();
  const {
    data: { shiftUrgency, waitTime, tieringType },
  } = useTieringDetailsDataContext();

  const { locationName } = useShiftDistributionLocationDataContext();
  const { t, i18n } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
    >
      <TieringDetailsWrapper>
        <H3>{locationName}</H3>
        <TieringDetailsSubHeader>
          {t('location_settings:TIERING_DETAILS_HEADER')}
        </TieringDetailsSubHeader>
        <TieringDetailsBody>
          {t('location_settings:TIERING_DETAILS_DESCRIPTION')}
        </TieringDetailsBody>
        <Flex>
          <TieringTypeCategory>{`${t('location_settings:TIERING_TYPE')}: `}</TieringTypeCategory>
          <TieringTypeCategoryValue>
            {i18n.exists(`location_settings:${tieringType.toUpperCase()}`)
              ? t(`location_settings:${tieringType.toUpperCase()}`)
              : tieringType}
            &nbsp;
          </TieringTypeCategoryValue>
          <BKJIconTooltip iconName="Info" placement="bottom">
            <Flex flexDirection="column" width="258px">
              <div>
                <TieringOverlayBold>
                  {`${t('location_settings:STANDARD_TIERING')}: `}
                </TieringOverlayBold>
                {t('location_settings:CALL_OUT_INFO')}
              </div>
              <br />
              <div>
                <TieringOverlayBold>
                  {`${t('location_settings:ROTATIONAL_TIERING')}: `}
                </TieringOverlayBold>
                {t('location_settings:ROTATIONAL_TIERING_INFO')}
              </div>
            </Flex>
          </BKJIconTooltip>
        </Flex>
        <TieringTypeTablePadding />
        <Flex flexDirection="column" height="200px">
          <TieringDetailsTableRow>
            <TieringDetailsTableHeaderCell data-cy="read-position-name">
              {t('location_settings:SHIFT_URGENCY')}&nbsp;
              <BKJIconTooltip iconName="Info" placement="bottom">
                <Flex flexDirection="column" width="258px">
                  <div>{t('location_settings:SHIFT_URGENCY_DESCRIPTION')}</div>
                </Flex>
              </BKJIconTooltip>
            </TieringDetailsTableHeaderCell>
            <TieringDetailsTableHeaderCell>
              {t('location_settings:WAIT_TIME_TEAM_MEMBERS')}
            </TieringDetailsTableHeaderCell>
          </TieringDetailsTableRow>
          <TieringRulesTableHeaderDivider />
          <TieringDetailsTableRow>
            <TieringDetailsTableContentCell data-cy="read-position-name" isGrey={true}>
              <Flex flexDirection="column">
                <div>{t('location_settings:CRITICAL')}</div>
                <div>{`< ${shiftUrgency.critical} ${t(
                  'location_settings:HOURS_BEFORE_SHIFT',
                )}`}</div>
              </Flex>
            </TieringDetailsTableContentCell>
            <TieringDetailsTableContentCell isGrey={true}>
              {`${waitTime.critical} ${waitTime.critical > 1 ? 'mins' : 'min'}`}
            </TieringDetailsTableContentCell>
          </TieringDetailsTableRow>
          <TieringDetailsTableRow>
            <TieringDetailsTableContentCell data-cy="read-position-name" isGrey={false}>
              <Flex flexDirection="column">
                <div>{t('location_settings:HIGH')}</div>
                <div>{`${shiftUrgency.critical} ${t('location_settings:TO')} < ${
                  shiftUrgency.high
                } ${t('location_settings:HOURS_BEFORE_SHIFT')}`}</div>
              </Flex>
            </TieringDetailsTableContentCell>
            <TieringDetailsTableContentCell isGrey={false}>
              {`${waitTime.high} ${waitTime.high > 1 ? 'mins' : 'min'}`}
            </TieringDetailsTableContentCell>
          </TieringDetailsTableRow>
          <TieringDetailsTableRow>
            <TieringDetailsTableContentCell data-cy="read-position-name" isGrey={true}>
              <Flex flexDirection="column">
                <div>{t('location_settings:MEDIUM')}</div>
                <div>{`${shiftUrgency.high} ${t('location_settings:TO')} < ${
                  shiftUrgency.medium
                } ${t('location_settings:HOURS_BEFORE_SHIFT')}`}</div>
              </Flex>
            </TieringDetailsTableContentCell>
            <TieringDetailsTableContentCell isGrey={true}>
              {`${waitTime.medium} ${waitTime.medium > 1 ? 'mins' : 'min'}`}
            </TieringDetailsTableContentCell>
          </TieringDetailsTableRow>
          <TieringDetailsTableRow>
            <TieringDetailsTableContentCell data-cy="read-position-name" isGrey={false}>
              <Flex flexDirection="column">
                <div>{t('location_settings:LOW')}</div>
                <div>{`${shiftUrgency.medium}+ ${t('location_settings:HOURS')} ${t(
                  'location_settings:HOURS_BEFORE_SHIFT',
                )}`}</div>
              </Flex>
            </TieringDetailsTableContentCell>
            <TieringDetailsTableContentCell isGrey={false}>
              {`${waitTime.low} ${waitTime.low > 1 ? 'mins' : 'min'}`}
            </TieringDetailsTableContentCell>
          </TieringDetailsTableRow>
        </Flex>
      </TieringDetailsWrapper>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton variant="PurpleSolid" width="180px" onClick={onClose}>
          {t('common:CLOSE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const TieringDetails: FC = () => {
  return (
    <ShiftDistributionLocationDataProvider>
      <TieringDetailsDataProvider>
        <TieringDetailsViewComponent />
      </TieringDetailsDataProvider>
    </ShiftDistributionLocationDataProvider>
  );
};
