import { BKJPagination } from '@bookjane2/bookjane-design-library';
import { QuerySwitch } from 'components/QuerySwitch';
import { ShiftsPageEmptyView } from 'pages/ShiftsPage/ShiftsEmptyView/ShiftsEmptyView';
import { ShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import { FC, Fragment, useContext } from 'react';
import { BKJTableFooter } from 'components/BKJTable/BKJTable.styled';
import { UserGuard } from 'guards/UserGuard';
import { ShiftsAgencyTable } from 'pages/ShiftsListPage/ShiftsTable/ShiftsAgencyTable';
import { ShiftsB2BTable } from 'pages/ShiftsListPage/ShiftsTable/ShiftsB2BTable';
import { UserPermissionEnum } from 'guards/UserGuard.types';

const ShiftsListView: FC = () => {
  const { prevPage, nextPage, firstPage, lastPage, currentPage, data } =
    useContext(ShiftsPageContext);

  return (
    <Fragment>
      <UserGuard
        userType={[
          UserPermissionEnum.USER_TYPE_B2B,
          UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
          UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
        ]}
      >
        <ShiftsB2BTable />
      </UserGuard>
      <UserGuard userType={UserPermissionEnum.USER_TYPE_AGENCY}>
        <ShiftsAgencyTable />
      </UserGuard>
      <BKJTableFooter>
        <BKJPagination
          currentPage={currentPage}
          pageCount={data.fetchOrders.pageCount}
          onFirstPage={firstPage}
          onPrevPage={prevPage}
          onNextPage={nextPage}
          onLastPage={lastPage}
        />
      </BKJTableFooter>
    </Fragment>
  );
};

const ShiftsList: FC = (): JSX.Element => {
  const { data } = useContext(ShiftsPageContext);

  if (!data) return <ShiftsPageEmptyView />;
  if (data?.fetchOrders?.totalCount === 0) return <ShiftsPageEmptyView />;

  return <ShiftsListView />;
};

export const ShiftsListPage: FC = () => {
  return <QuerySwitch context={ShiftsPageContext} component={ShiftsList} />;
};
