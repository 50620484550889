import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { AvailabilityEmptyState } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvaibilityEmptyState';
import { AvailabilityAvatar } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailabilityAvatar';
import { AvailableMembersDataContext } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemeberTab.context';
import {
  JaneFacility,
  JaneName,
  JaneWrapper,
  TableAvailabilityRow,
  TableAvailabilityRowWrapper,
  TableColumn,
  TeamMemberWrapper,
  TimeColumn,
} from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/index.styled';
import { DateTime } from 'luxon';
import { isCurrentLanguageFrench } from 'providers/i18NextProvider.constants';
import { FC, Fragment, useContext } from 'react';
import { availableTeamMembers_fetchOrder_orderAvailabilities_nodes } from 'types/graphql-types';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';

const AvailabilityRow: FC<availableTeamMembers_fetchOrder_orderAvailabilities_nodes> = (
  props,
): JSX.Element => {
  const { createdAt, jane } = props;
  const { timeZone } = getCurrentlySelectedLocation();
  const { month } = getLocalizedDateFormat(createdAt, timeZone);

  const isToday = DateTime.fromISO(createdAt)
    .setZone(timeZone)
    .hasSame(DateTime.now().setZone(timeZone), 'day');

  const hoursMinutes = isCurrentLanguageFrench() ? 'HH:mm' : 'h:mm a';
  const at = isCurrentLanguageFrench() ? 'à' : 'at';

  const translatedDate = isToday
    ? DateTime.fromISO(createdAt).setZone(timeZone).toFormat(hoursMinutes)
    : `${month} ${DateTime.fromISO(createdAt)
        .setZone(timeZone)
        .toFormat(`dd, yyyy '${at}' ${hoursMinutes}`)}`;

  return (
    <TableAvailabilityRowWrapper>
      <TableAvailabilityRow>
        <TableColumn>
          <TimeColumn>{translatedDate}</TimeColumn>
        </TableColumn>
        <TableColumn>
          <TeamMemberWrapper>
            <div>
              <AvailabilityAvatar {...jane} />
            </div>
            <JaneWrapper>
              <JaneName>{jane?.shorthandName}</JaneName>
              <JaneFacility>{jane?.communityName}</JaneFacility>
            </JaneWrapper>
          </TeamMemberWrapper>
        </TableColumn>
      </TableAvailabilityRow>
    </TableAvailabilityRowWrapper>
  );
};

export const AvailableMemberTabTableBody = () => {
  const { data, loadMore } = useContext(AvailableMembersDataContext);

  if (!data) return null;
  if (!data?.fetchOrder) return null;
  if (!data?.fetchOrder?.orderAvailabilities) return null;
  if (!data?.fetchOrder?.orderAvailabilities?.nodes) return null;
  if (data?.fetchOrder?.orderAvailabilities?.nodes?.length === 0) return <AvailabilityEmptyState />;
  return (
    <Fragment>
      {data.fetchOrder.orderAvailabilities.nodes.map(
        (node: availableTeamMembers_fetchOrder_orderAvailabilities_nodes) => (
          <AvailabilityRow {...node} key={node.id} />
        ),
      )}
      {!!data.fetchOrder.orderAvailabilities.pageInfo.hasNextPage ? (
        <InfiniteScrollLoading onViewportEnter={loadMore} />
      ) : null}
    </Fragment>
  );
};
