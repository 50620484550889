import styled, { css } from 'styled-components';
import { StyledCalendarWrapperProps } from '../../BKJCalendar.types';
import { StyledDayCellAttributes } from './MonthView.types';
import { useTranslation } from 'hooks/useTranslation';

export const MonthViewWrapper = styled.div<StyledCalendarWrapperProps>`
  width: fit-content;
  height: fit-content;
`;

export const DayCellsWrapper = styled.div<StyledCalendarWrapperProps>`
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-wrap: wrap;
`;

export const DayCell = styled.div<StyledDayCellAttributes>`
  position: relative;
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.White};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.Body};
  &:hover {
    color: ${({ theme }) => theme.colors.PrimaryHover};
    background-color: ${({ theme }) => theme.colors.Grey3};
  }
  ${({ isDateInViewMonth }) =>
    !isDateInViewMonth &&
    css`
      color: ${({ theme }) => theme.colors.TextDisabled};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.TextDisabled};
      cursor: not-allowed;
    `};

  ${({ isBetweenSelectedRange, isHoveredOnCalendar }) =>
    isBetweenSelectedRange &&
    !isHoveredOnCalendar &&
    css`
      color: ${({ theme }) => theme.colors.TextDarkGrey};
      background-color: ${({ theme }) => theme.colors.Grey2};
    `};

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.White};
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
    `};

  ${({ isDateInHoverRange }) =>
    isDateInHoverRange &&
    css`
      color: ${({ theme }) => theme.colors.PrimaryHover};
      background-color: ${({ theme }) => theme.colors.Grey2};
    `};
`;

export const TodayIndicator = styled.div`
  height: 4px;
  width: 30px;
  position: absolute;
  bottom: 6px;
  background-color: ${({ theme }) => theme.colors.AccentGreen};
`;

const DaysOfTheWeekWrapper = styled.div`
  margin-top: 3px;
  pointer-events: none;
  width: 100%;
  height: 42px;
  display: flex;
`;

const DayOfTheWeekWrapper = styled.div`
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.CaptionBold};
`;

export const DaysOfTheWeek = () => {
  const { t } = useTranslation();
  return (
    <DaysOfTheWeekWrapper>
      {[
        t('translations:SU'),
        t('translations:MO'),
        t('translations:TU'),
        t('translations:WE'),
        t('translations:TH'),
        t('translations:FR'),
        t('translations:SA'),
      ].map((dayOfTheWeek) => (
        <DayOfTheWeekWrapper key={dayOfTheWeek}>{dayOfTheWeek}</DayOfTheWeekWrapper>
      ))}
    </DaysOfTheWeekWrapper>
  );
};
