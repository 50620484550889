import { DateTime } from 'luxon';

export const teamMembersSortByDefaultKey = 'first_name';

export const initialSearchedMemberState = {
  selectedJaneIds: [],
};

export const initialContextMenuState = {
  date: null,
  isMenuOpen: false,
  isPageX: null,
  isPageY: null,
  jane_id: null,
  shift_id: 'null',
  type: null,
  togglerId: null,
};

export const initialCopyParamsState = {
  type: null,
  id: null,
  start_time: null,
  end_time: null,
  position_code: null,
  status: null,
  facility_id: null,
  facility_type: null,
  allow_overtime: null,
  unpaid_break_time: null,
  description: null,
  jane_id: null,
};

export const initialCreateShiftState = {
  isCreateShiftFormOpen: false,
  id: null,
};

export const initialPDFState = {
  isPDFModalVisible: false,
  exportOption: 'pdf',
};

export const initialStateAuditPDFState = {
  isPDFModalVisible: false,
  exportOption: 'pdf',
};

export const initialSideModalState = {
  isSideModalOpen: false,
  id: null,
  form: {
    date: '',
    start_time: '',
    end_time: '',
    unpaid_break: '',
    allow_overtime: '',
  },
  addNotes: false,
};

export const initialScheduleState = {
  copyParams: initialCopyParamsState,
  contextMenu: initialContextMenuState,
  selectedFacilityId: null,
  createShiftPopup: initialCreateShiftState,
  shiftSideModal: initialSideModalState,
  shiftPDFModal: initialPDFState,
  stateAuditPDFModal: initialStateAuditPDFState,
  isHighOpenShiftCountTipOpen: false,
  deletingTemplate: null,
  clearingTemplate: null,
};

export const initialJaneSortState = {
  sortBy: teamMembersSortByDefaultKey,
};

export const initialFacilityViewState = {
  publishPositionCodes: null,
  activeTab: null,
  isPublishModalOpen: false,
  isClearDraftsModalOpen: false,
  isLegendModalOpen: false,
  isCreateNewTabOpen: true,
  isShiftNoteVisible: false,
  isScheduleSettingsModalEditModeEnabled: false,
  isScheduleSettingsModalOpen: false,
  isFiltersSlideDownPanelOpen: false,
  isScheduleCalendarOpen: false,
  isAllShiftsStatusesFilterDropdownOpen: false,
  isTimeFrameStatusFilterDropdownOpen: false,
  isGroupsFilterDropdownOpen: false,
  isJaneStaffTypeFilterDropdownOpen: false,
  isEmploymentStatusFilterDropdownOpen: false,
  selectedShiftStatusFilters: [],
  selectedGroupsFilter: [],
  selectedGroupsFilterNames: [],
  selectedEmploymentStatusFilter: [],
  selectedJaneStaffTypeFilter: [],
  selectedTimeFrameStatusFilter: [],
  showGroupsFilter: false,
  isShowAgencyShiftsOnlyFilterSelected: false,
  isShowConflictsOnlyFilterSelected: false,
  positionTabIds: [],
  prevActiveTab: null,
  selectedScheduleStartDate: DateTime.now().startOf('week').minus({ days: 1 }).toString(),
  selectedWeekRange: 1,
  searchedMembers: initialSearchedMemberState,
  isFirstTime: true,
  isBudgetingPanelExpanded: false,
};
