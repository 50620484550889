import { useURLParameters } from 'hooks';
import { createContext, FC, useCallback, useContext } from 'react';

interface ICalendarDayCellProps {
  date: string;
  onDaySelection: () => void;
}

export const CalendarDayCellPropsContext = createContext<ICalendarDayCellProps>({
  date: '',
  onDaySelection: () => void 0,
});

export const CalendarDayCellPropsProvider: FC<{ date: string }> = ({ children, date }) => {
  const [, { setParam }] = useURLParameters();

  const handleDaySelection = useCallback(() => {
    void setParam({ daySelectionModalDate: date });
  }, [date, setParam]);

  return (
    <CalendarDayCellPropsContext.Provider value={{ date, onDaySelection: handleDaySelection }}>
      {children}
    </CalendarDayCellPropsContext.Provider>
  );
};

export const useCalendarDayCellPropsContext = () => useContext(CalendarDayCellPropsContext);
