import { BKJCheckboxListItem, BKJListItem } from '@bookjane2/bookjane-design-library';
import {
  GroupOptionProps,
  PositionOptionProps,
} from 'components/PositionByDepartmentSelectInput/PositionsByDepartmentSelectInput.types';
import styled from 'styled-components';

export const NoResultsWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.Grey4};
  font-size: 16px;
  height: 60px;
  width: 100%;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

export const GroupLabel = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  list-style: none;
  height: 38px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  cursor: pointer;
  transition: background-color 100ms linear;

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.Grey1};
  }
`;

export const GroupOption = ({ variant, type, ...rest }: GroupOptionProps) => {
  if (type === 'Single') {
    return <GroupLabel>{rest.label}</GroupLabel>;
  }
  switch (variant) {
    case 'List':
      return <BKJListItem {...rest} />;
    case 'Checkbox':
      return <BKJCheckboxListItem {...rest} />;
    default:
      return <BKJCheckboxListItem {...rest} />;
  }
};

export const NestedBKJListItem = styled(BKJListItem)`
  padding-left: 42px;
  ${({ theme }) => theme.fonts.PoppinsText};
`;

export const NestedBKJCheckboxListItem = styled(BKJCheckboxListItem)`
  padding-left: 42px;
`;

export const PositionOption = ({ variant, ...rest }: PositionOptionProps) => {
  switch (variant) {
    case 'List':
      return <NestedBKJListItem {...rest} />;
    case 'Checkbox':
      return <NestedBKJCheckboxListItem {...rest} />;
    default:
      return <NestedBKJCheckboxListItem {...rest} />;
  }
};
