import { BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import { AccushieldLogo } from 'components/WhiteLabelAssetComponents/Accushield/AccushieldLogo';
import { FC } from 'react';

export const AccushieldDashboardButton: FC = () => {
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://dashboard.accushield.com/auth/login">
      <BKJButton variant="PurpleSolid">
        <Flex width="100px" margin="0 -70px 0 -10px">
          <AccushieldLogo width="25px" height="25px" />
        </Flex>
        Accushield Dashboard
      </BKJButton>
    </a>
  );
};
