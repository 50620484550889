import { FC, useContext, useEffect, useMemo } from 'react';
import { QUERY_companyProfilePage } from 'queries';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';

const CompanyProfilePageDataContext = createGQLDataContext({});

export const CompanyProfilePageDataProvider: FC = ({ children }) => {
  const { data, fetch, ...restQuery } = useGQLQuery({
    key: 'COMPANY_PROFILE_PAGE',
    query: QUERY_companyProfilePage,
    variables: {},
  });

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line
  const contextValue = useMemo(() => ({ data, fetch, ...restQuery }), [data, fetch, restQuery]);

  return (
    <CompanyProfilePageDataContext.Provider value={contextValue}>
      {children}
    </CompanyProfilePageDataContext.Provider>
  );
};

export const useCompanyProfilePageDataContext = () => useContext(CompanyProfilePageDataContext);
