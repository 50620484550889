import { BKJButton, BKJTextArea } from '@bookjane2/bookjane-design-library';
import { FC, Fragment } from 'react';
import {
  ReviewBoxWrapper,
  ReviewBoxHeader,
  ReviewBoxTitle,
  ReviewBoxBody,
  BackgroundWrapper,
} from './ReviewBox.styled';
import { StarReview } from 'components/StarReview/StarReview';
import { useTranslation } from 'hooks/useTranslation';
import { Background } from './Background';
import { IReviewBoxProps } from './ReviewBox.types';
import { Flex } from 'components/Flex';
import { useRateShiftViewContext } from 'components/ShiftModal/src/views/RateShiftView/RateShiftView.context';
import { FormErrorBox } from 'components/FormErrorBox/FormErrorBox.styled';
import { css } from 'styled-components';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSelector } from 'react-redux';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const ReviewBox: FC<IReviewBoxProps> = ({ janeName }) => {
  const { t } = useTranslation();
  const { isPending, isDisabled, formValues, onSubmit, error, onChange } =
    useRateShiftViewContext();
  const { refetch, data } = useShiftModalDataContext();

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );
  if (
    isReadOnly ||
    Number(data?.fetchOrder?.community?.id) !== Number(getCurrentlySelectedLocation()?.value)
  ) {
    return <Fragment></Fragment>;
  }

  return (
    <ReviewBoxWrapper flexDirection="column">
      <ReviewBoxHeader>
        <BackgroundWrapper>
          <Background />
        </BackgroundWrapper>
        <ReviewBoxTitle>
          {t('shift_modal:HOW_WAS_SERVICE', { serviceBy: janeName.split(' ')[0] })}
        </ReviewBoxTitle>
      </ReviewBoxHeader>
      <ReviewBoxBody flexDirection="column" childMarginBottom="18px">
        <StarReview
          rating={formValues.rating}
          onChange={(newRating) => onChange({ target: { name: 'rating', value: newRating } })}
        />
        <BKJTextArea
          label={t('shift_modal:ADD_A_REVIEW')}
          placeholder={t('shift_modal:ADD_A_REVIEW_PLACEHOLDER')}
          name="comment"
          value={formValues.comment}
          onChange={onChange}
        />
        {error && (
          <FormErrorBox
            css={css`
              transform: translateY(-28px);
            `}
          >
            {t('shift_modal:SOMETHING_WENT_WRONG')}
          </FormErrorBox>
        )}
        <Flex justifyContent="flex-end" alignItems="center">
          <BKJButton
            width="130px"
            variant="GreenSolid"
            disabled={isDisabled}
            isLoading={isPending}
            onClick={() => onSubmit(refetch)}
            css={css`
              transform: translateY(-35px);
            `}
          >
            {t('shift_modal:SUBMIT')}
          </BKJButton>
        </Flex>
      </ReviewBoxBody>
    </ReviewBoxWrapper>
  );
};
