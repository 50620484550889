import { QUERY_fetchOrderBidders } from 'queries';
export const requestAwardTeamConfig = ({ orderId }: { orderId: number }) => ({
  key: 'FETCH_ORDER_BIDDERS',
  query: QUERY_fetchOrderBidders,
  schema: {
    orderId: {
      initialValue: orderId,
    },
    byFullName: {
      initialValue: '',
    },
    byEmploymentStatus: {
      initialValue: [],
    },
  },
});
