import { Flex, BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { Label } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import {
  AuditTrailJaneAvatarWrapper,
  AuditTrailTableCell,
  AuditTrailTableRow,
  AuditTrailTableText,
  AuditTrailTableTextSmall,
  AuditTrailTableTextLocation,
  AuditTrailAvatar,
} from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailTableView.styled';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import { useAuditTrailDataContext } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.data.context';
import {
  CalloutStatusEnum,
  shiftModalOrderDetailsCalloutList_node_CalloutList,
  shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes,
} from 'types/graphql-types';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { TeamMemberAccessStatusColorEnum } from 'pages/TeamMemberPage/components/TeamMemberAccessStatus/TeamMemberAccessStatus.types';
import { css } from 'styled-components';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

const mergeCalloutListNodes = (
  existingNodes: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes[],
  incomingNodes: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes[],
) => {
  const nextNodes = [...existingNodes];
  if (incomingNodes?.length > 0)
    incomingNodes.forEach((record) => {
      if (record && record.jane.id)
        if (!nextNodes.some(({ jane: { id } }) => record.jane.id === id)) nextNodes.push(record);
    });
  return nextNodes;
};

export const AuditTrailTableViewComponent: FC = () => {
  const { t, i18n } = useTranslation();
  const { data, loadMore } = useAuditTrailDataContext();

  const [calloutListData, setCalloutListData] = useState<
    shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes[]
  >([]);

  const nodeData = useMemo(
    () =>
      (data && data?.node ? data?.node : {}) as shiftModalOrderDetailsCalloutList_node_CalloutList,
    [data],
  );

  const manualApprovalRequiredIndexes = useMemo(
    () =>
      nodeData?.manualApprovalIndexes
        ? nodeData?.manualApprovalIndexes?.map(({ index }) => index).sort()
        : [],
    [nodeData?.manualApprovalIndexes],
  );

  // TODO: Figure out how to merge GQL fragments in ApolloProvider cache so that we don't have to do this in the components
  useEffect(() => {
    if (nodeData && nodeData?.calloutListJanes?.nodes && nodeData?.calloutListJanes?.nodes.length) {
      const lastElementFromApi = nodeData?.calloutListJanes?.nodes.slice(-1)[0];
      if (
        calloutListData.length === 0 ||
        lastElementFromApi?.jane?.id !== calloutListData?.slice(-1)[0]?.jane?.id
      ) {
        setCalloutListData(mergeCalloutListNodes(calloutListData, nodeData.calloutListJanes.nodes));
      }
    }
  }, [calloutListData, nodeData]);

  const { timeZone } = getCurrentlySelectedLocation();

  return (
    <Fragment>
      {calloutListData.map((calloutJane, index) => {
        const isSkipped =
          calloutJane?.calloutStatus?.value !== CalloutStatusEnum.in_line &&
          calloutJane?.calloutStatus?.value !== CalloutStatusEnum.notified &&
          calloutJane?.calloutStatus?.value !== CalloutStatusEnum.overtime_allowed_in_line &&
          calloutJane?.calloutStatus?.value !== CalloutStatusEnum.overtime_allowed_notified &&
          calloutJane?.calloutStatus?.value !== CalloutStatusEnum.not_notified;

        const hasCallOutOrNotified =
          !!calloutJane?.notifiedAt || !!calloutJane?.estimatedCalloutTime;
        const { month } = getLocalizedDateFormat(
          !!calloutJane?.notifiedAt ? calloutJane?.notifiedAt : calloutJane?.estimatedCalloutTime,
        );

        return (
          <Fragment>
            {manualApprovalRequiredIndexes.includes(index) && (
              <AuditTrailTableRow key={index}>
                <Flex flexDirection="column" padding="12px 0 12px 35px">
                  {calloutJane?.manualApproval?.approvedAt
                    ? t('shift_modal:CALL_OUT_CONTINUED')
                    : t('shift_modal:MANUAL_ACTION_REQUIRED')}
                </Flex>
                <Flex flexDirection="column-reverse" padding="5px 0 5px 65px">
                  <BKJIconTooltip iconName="Info" placement="bottom">
                    <Flex width="300px">{t('shift_modal:THE_CALL_OUT_TIME_INFO')}</Flex>
                  </BKJIconTooltip>
                </Flex>
              </AuditTrailTableRow>
            )}
            <AuditTrailTableRow key={index} isGrey={isSkipped}>
              <AuditTrailTableCell cellWidth="150px" padding="12px 0 12px 35px">
                <Label>{t('shift_modal:CALL_OUT_TIME')}</Label>
                <AuditTrailTableTextSmall>
                  {!isSkipped &&
                    hasCallOutOrNotified &&
                    ` ${month} ${DateTime.fromISO(
                      !!calloutJane?.notifiedAt
                        ? calloutJane?.notifiedAt
                        : calloutJane?.estimatedCalloutTime,
                    )
                      .setZone(timeZone)
                      .toFormat(`d, yyyy '${t('shift_modal:AT')}' hh:mm a`)}`}
                </AuditTrailTableTextSmall>
              </AuditTrailTableCell>
              <AuditTrailTableCell cellWidth="250px" padding="16px 0 18px 18px">
                <Flex flexDirection="row" alignItems="center">
                  <Label>{t('shift_modal:TEAM_MEMBER')}</Label>
                  <AuditTrailJaneAvatarWrapper isGrey={isSkipped}>
                    <AuditTrailAvatar
                      src={calloutJane?.jane?.avatarUrls?.origin as unknown as string}
                      width="30px"
                      height="30px"
                      size={48}
                      fallbackAvatarColor={TeamMemberAccessStatusColorEnum['default']}
                      css={css`
                        margin-right: 13px;
                      `}
                      showPendingSignUp={calloutJane?.jane?.pendingSignup}
                    />
                  </AuditTrailJaneAvatarWrapper>
                  <Flex flexDirection="column">
                    <AuditTrailTableText
                      isGrey={isSkipped}
                      css={css`
                        width: 140px;
                      `}
                    >{`${calloutJane?.jane?.firstName} ${calloutJane?.jane?.lastName}`}</AuditTrailTableText>
                    <AuditTrailTableTextLocation isGrey={true}>
                      {`${calloutJane?.jane?.primaryCommunity?.name}`}
                    </AuditTrailTableTextLocation>
                  </Flex>
                </Flex>
              </AuditTrailTableCell>
              <AuditTrailTableCell padding="20px 12px 18px 0px" cellWidth="150px">
                <Label>{t('shift_modal:CALL_OUT_STATUS')}</Label>
                <AuditTrailTableTextSmall isGrey={isSkipped}>
                  {getTranslatedResponse(calloutJane?.calloutStatus?.value, 'status', i18n, t)}
                </AuditTrailTableTextSmall>
              </AuditTrailTableCell>
            </AuditTrailTableRow>
          </Fragment>
        );
      })}
      {!!nodeData?.calloutListJanes?.pageInfo?.hasNextPage && (
        <InfiniteScrollLoading onViewportEnter={loadMore} />
      )}
    </Fragment>
  );
};

export const AuditTrailTableView: FC = () => {
  return <AuditTrailTableViewComponent />;
};
