import { memo, FC } from 'react';
import { IExternalMenuLinkProps } from 'components/BKJMainNavigation/BKJMainNavigation.types';
import {
  ExternalMenuLink,
  MenuItem,
  MenuItemLabel,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { NavMenuIcon } from 'components/BKJMainNavigation/src/NavMenuIcon';
import { deepEquals } from 'utils/deepEquals';
import { isCurrentLanguageFrench } from 'providers/i18NextProvider.constants';

const Component: FC<IExternalMenuLinkProps> = (props): JSX.Element => {
  return (
    <ExternalMenuLink
      href={props.href}
      $label={props.label}
      $isOpen={!!props.isOpen}
      $disabled={props.disabled}
      onClick={props.onClick}
    >
      <MenuItem>
        <NavMenuIcon iconName={props.icon} color="White" />
        <MenuItemLabel
          $isOpen={props.isOpen}
          $disabled={props.disabled}
          $showPreLine={isCurrentLanguageFrench()}
        >
          {props.label}
        </MenuItemLabel>
      </MenuItem>
    </ExternalMenuLink>
  );
};

export const ExternalNavMenuItem = memo(Component, deepEquals);
