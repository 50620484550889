import { BKJLoadingSpinner } from '@bookjane2/bookjane-design-library';
import { InfiniteScrollLoadingWrapper } from 'components/InfiniteScrollLoading/InfiniteScrollLoading.styled';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { FC, HTMLAttributes, RefObject } from 'react';

export const InfiniteScrollLoading: FC<
  { onViewportEnter: Function } & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { onViewportEnter, ...htmlAttributes } = props;
  const ref = useIntersectionObserver(onViewportEnter as IntersectionObserverCallback);
  return (
    <InfiniteScrollLoadingWrapper ref={ref as RefObject<HTMLDivElement>} {...htmlAttributes}>
      <BKJLoadingSpinner variant="Default" />
    </InfiniteScrollLoadingWrapper>
  );
};
