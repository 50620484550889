import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const TableRow = styled(Flex)<{ isGrey: boolean }>`
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
`;

export const TableCell = styled(Flex)`
  padding: 10px;
`;

export const TableHeaderCell = styled(Flex)`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  padding: 10px;
`;

export const LocationsWrapper = styled(Flex)`
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: solid 1px #8b96a350;
`;
