import { FC, forwardRef, useContext } from 'react';
import { ICalendarPopUpProps } from './CalendarPopUp.types';
import { CalendarPopUpWrapper } from './CalendarPopUp.styled';
import { BKJCalendar } from '../BKJCalendar';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CalendarPopUp: FC<ICalendarPopUpProps & { ref?: any }> = forwardRef(
  ({ style, context, setIsCalendarOpen, ...rest }, ref): JSX.Element => {
    const { variant } = useContext(context);
    return (
      <CalendarPopUpWrapper ref={ref} style={style} variant={variant} {...rest}>
        <BKJCalendar context={context} setIsCalendarOpen={setIsCalendarOpen} />
      </CalendarPopUpWrapper>
    );
  },
);
