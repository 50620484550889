import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isSendToSelectedAgenciesEnabledSelector = createSelector(
  getUserPermissions,
  (permissions) => {
    if (permissions?.SEND_TO_SELECTED_AGENCY?.access)
      return permissions.SEND_TO_SELECTED_AGENCY.access.includes('read');
    else return false;
  },
);
