import styled from 'styled-components';

export const DeleteTimeOffViewWrapper = styled.div`
  height: fit-content;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
`;

export const MainTitle = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const DeleteTimeOffDescriptionWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;
