import { StyledLink } from 'components/AppLink/AppLink.styled';
import { IAppLinkProps } from 'components/AppLink/AppLink.types';
import { memo, FC } from 'react';
import { deepEquals } from 'utils/deepEquals';

const AppLinkComponent: FC<IAppLinkProps> = ({ children, to, ...props }) => (
  <StyledLink to={to} {...props}>
    {children}
  </StyledLink>
);

export const AppLink = memo(AppLinkComponent, deepEquals);
