type ZIndexType = number;

interface IZIndexLayers {
  [name: string]: ZIndexType;
}

const base: number = 0;
const above: number = 1;
const below: number = -1;
const oneLayerAbove: number = 100;
const fiftyLayersAbove: number = 50 * oneLayerAbove;

const pageTableBody: ZIndexType = above + base;
const pageTableHeader: ZIndexType = above + pageTableBody;
const ssfBottomBar: ZIndexType = above + pageTableHeader;
const ssfBottomBarFilters: ZIndexType = above + ssfBottomBar;
const ssfTopBar: ZIndexType = above + ssfBottomBarFilters;
const ssfDropDown: ZIndexType = above + ssfBottomBarFilters;
const pageHeader: ZIndexType = above + ssfTopBar;
const mainNavigation: ZIndexType = oneLayerAbove + pageHeader;
const settingsNavigation: ZIndexType = below + pageHeader;
const sideModalBackdrop: ZIndexType = oneLayerAbove + base;
const sideModalDrawer: ZIndexType = above + sideModalBackdrop;
const aboveSideModalDrawer: ZIndexType = above + sideModalDrawer;
const translationSwitch: ZIndexType = oneLayerAbove + base;
const centeredDialogBackdrop: ZIndexType = oneLayerAbove + aboveSideModalDrawer;
const centeredDialog: ZIndexType = oneLayerAbove + centeredDialogBackdrop;
const ssfCalendarPopUp: ZIndexType = fiftyLayersAbove;

export const zIndexLayers: IZIndexLayers = {
  pageTableBody,
  pageTableHeader,
  ssfBottomBar,
  ssfBottomBarFilters,
  ssfTopBar,
  ssfDropDown,
  pageHeader,
  mainNavigation,
  sideModalBackdrop,
  sideModalDrawer,
  aboveSideModalDrawer,
  settingsNavigation,
  translationSwitch,
  centeredDialogBackdrop,
  centeredDialog,
  ssfCalendarPopUp,
};
