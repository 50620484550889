import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
`;
