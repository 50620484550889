import { JaneAvatar } from 'components/BKJJaneAvatar';
import { BKJToggleInput, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { Fragment } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useFetchJaneByIdDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/DirectRequests/FetchJaneByJaneId.data.context';
import { InternalPay } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/ShiftPay/InternalPay';
import { OpenPay } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/ShiftPay/OpenPay';
import {
  SendToAgenciesBoldText,
  InfoSection,
  SendToAgenciesText,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

export function DirectRequestsComponent() {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();
  const {
    values: { post_action, isDedicated },
    onChange,
  } = useSendToAgenciesFormContext();

  const { data: fetchJaneData } = useFetchJaneByIdDataContext();

  return (
    <ErrorBoundary componentName="DirectRequests" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <Flex flexDirection="column" gap="32px">
        <Flex flexDirection="column" gap="8px">
          <SendToAgenciesBoldText>{t('shift_modal:DIRECT_REQUEST')} (1)</SendToAgenciesBoldText>
          <InfoSection gap="20px" alignItems="center">
            <JaneAvatar
              size={80}
              status="pending"
              url={fetchJaneData.fetchJane.avatarUrls.small}
              pendingSignup={fetchJaneData.fetchJane.pendingSignup}
            />
            <Flex flexDirection="column">
              <SendToAgenciesBoldText>
                {`${fetchJaneData.fetchJane.firstName} ${fetchJaneData.fetchJane.lastName}`}
              </SendToAgenciesBoldText>
              <SendToAgenciesText>
                {isDedicated
                  ? `${t('shift_modal:REQUEST_HAVE_FOUR_HOUR_ACCEPTANCE_WINDOW_NOTE')}
              ${
                post_action === 'internal_only'
                  ? t('shift_modal:INTERNAL_STAFF')
                  : t('shift_modal:ALL_DEDICATED_TEAM_MEMBERS')
              }`
                  : `${t('shift_modal:REQUEST_HAVE_FOUR_HOUR_ACCEPTANCE_WINDOW_NOTE')} ${
                      post_action === 'internal_only'
                        ? t('shift_modal:INTERNAL_STAFF')
                        : t('shift_modal:ALL_AVAILABLE_AGENCY_MEMBERS')
                    }`}
              </SendToAgenciesText>
            </Flex>
          </InfoSection>
        </Flex>
        {post_action === 'internal_only' ? (
          <InternalPay />
        ) : (
          <Fragment>
            {data.fetchOrder?.dedicatedPool ? (
              <InfoSection gap="8px" alignItems="center">
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <SendToAgenciesBoldText>
                    {t('shift_modal:SEND_OUT')}:&nbsp;
                    <SendToAgenciesText>
                      {t('shift_modal:ONLY_DEDICATED_TEAM_MEMBERS')}
                    </SendToAgenciesText>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </SendToAgenciesBoldText>
                  <BKJToggleInput
                    name="isDedicated"
                    variant="Switch"
                    checked={isDedicated}
                    onChange={onChange}
                  />
                </Flex>
              </InfoSection>
            ) : null}
            <OpenPay />
          </Fragment>
        )}
      </Flex>
    </ErrorBoundary>
  );
}
