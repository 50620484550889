import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { IDirectionType } from 'components/BKJTable/BKJTableSubHeader/BKJTableSubHeader.types';
import styled from 'styled-components';

export const BKJTableSubHeaderWrapper = styled.div`
  background-color: inherit;
  display: flex;
  width: 100%;
  min-width: max-content;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
  height: 65px;
`;

export const TextWrapper = styled.div`
  display: flex;
`;

export const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const TableSortWrapper = styled.button<{
  role: string;
  name: string;
  value: IDirectionType;
  onClick: ChangeFunctionType;
}>`
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  svg {
    pointer-events: none;
  }
`;

export const TooltipWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.Grey5};
  position: absolute;
  display: flex;
  margin-top: 8px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  top: 100%;
  width: 250px;

  ::before {
    content: '';
    position: absolute;
    border-bottom: 8px solid ${({ theme }) => theme.colors.Grey5};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -8px;
    left: calc(50% - 8px);
  }
`;

export const TooltipText = styled.span`
  ${({ theme }) => theme.typography.Caption};
  color: white;
`;

export const HeaderText = styled.span`
  ${({ theme }) => theme.typography.Bold};
  margin: 0;
  margin-right: 11px;
`;
