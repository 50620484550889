import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect, useMemo } from 'react';

interface ISendToAgenciesSummaryViewProps {}

export const SendToAgenciesSummaryViewPropsContext = createContext<ISendToAgenciesSummaryViewProps>(
  {} as ISendToAgenciesSummaryViewProps,
);

export const SendToAgenciesSummaryViewPropsProvider: FC = ({ children }) => {
  const [, { removeParam }] = useURLParameters();

  useEffect(() => {
    if (!shiftModalRoutingService.isBackButtonVisible())
      removeParam(['shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [removeParam]);

  const contextValue = useMemo(() => ({}), []);

  return (
    <SendToAgenciesSummaryViewPropsContext.Provider value={contextValue}>
      {children}
    </SendToAgenciesSummaryViewPropsContext.Provider>
  );
};

export const useSendToAgenciesSummaryViewPropsContext = () =>
  useContext(SendToAgenciesSummaryViewPropsContext);
