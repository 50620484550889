import { StyledBKJAvatar } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/index.styled';
import React from 'react';
import { availableTeamMembers_fetchOrder_orderAvailabilities_nodes_jane } from 'types/graphql-types';
export function AvailabilityAvatar(
  props: availableTeamMembers_fetchOrder_orderAvailabilities_nodes_jane,
) {
  const { avatarUrls = { origin: '' }, pendingSignup } = props;
  return (
    <StyledBKJAvatar
      src={avatarUrls?.origin as unknown as string}
      width="30px"
      height="30px"
      fallbackAvatarColor="Grey4"
      showPendingSignUp={pendingSignup}
    />
  );
}
