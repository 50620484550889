import {
  shiftCardFetchOrder_fetchOrder_mainJane,
  shiftModalOrderDetails_fetchOrder_mainJane,
  shiftsListPage_fetchOrders_edges_node_mainJane,
  shiftPageDaySelection_fetchOrders_edges_node_mainJane,
  shiftCardFetchOrder_fetchOrder_status,
  OrderStatusEnum,
} from 'types/graphql-types';
import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';

type IGetIsJaneCommunityMismatch = {
  acceptedBySharedTeamMember: Nullable<boolean>;
  status: shiftCardFetchOrder_fetchOrder_status;
  currentlySelectedLocation: LocationOptionType;
  isMultiLocationCallOutFeatureEnabled: boolean;
  isMultiLocationSchedulingFeatureEnabled: boolean;
  mainJane:
    | shiftPageDaySelection_fetchOrders_edges_node_mainJane
    | shiftsListPage_fetchOrders_edges_node_mainJane
    | shiftModalOrderDetails_fetchOrder_mainJane
    | shiftCardFetchOrder_fetchOrder_mainJane
    | null;
};
export const getIsJaneCommunityMismatch = ({
  acceptedBySharedTeamMember,
  status,
  mainJane = null,
  currentlySelectedLocation,
  isMultiLocationCallOutFeatureEnabled = false,
  isMultiLocationSchedulingFeatureEnabled = false,
}: IGetIsJaneCommunityMismatch): boolean => {
  if (isMultiLocationCallOutFeatureEnabled)
    return (
      !!mainJane &&
      !mainJane?.agencyCommunity &&
      mainJane?.primaryCommunity?.id !== currentlySelectedLocation?.value?.toString()
    );
  else
    return (
      isMultiLocationSchedulingFeatureEnabled &&
      !!mainJane &&
      !mainJane?.agencyCommunity &&
      (!acceptedBySharedTeamMember || status.value === OrderStatusEnum.assigned) &&
      mainJane?.primaryCommunity?.id !== currentlySelectedLocation?.value?.toString()
    );
};
