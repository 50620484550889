import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { timesheetsTabQueryConfig } from 'components/ShiftModal/src/views/DetailView/TimesheetsTab/TimesheetsTab.constants';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext, useEffect } from 'react';
import { shiftModalTimeSheetDetails } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const TimesheetsTabDataContext = createGQLDataContext({});

export const TimesheetsTabDataProvider: FC = ({ children }) => {
  const { data } = useShiftModalDataContext();

  const result = useGQLQuery(
    timesheetsTabQueryConfig({
      key: 'ShiftModalTimeSheetQuery',
      initialValues: {
        orderScheduleId: data.fetchOrder!.orderSchedule.id,
      },
    }),
  );

  useEffect(() => {
    result.fetch();
  }, []); // eslint-disable-line

  return (
    <TimesheetsTabDataContext.Provider value={result}>{children}</TimesheetsTabDataContext.Provider>
  );
};

export const useTimeSheetsTabDataContext = (): UseGQLQuery<shiftModalTimeSheetDetails> =>
  useContext(TimesheetsTabDataContext);
