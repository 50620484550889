import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_settingsShiftDistributionPage } from 'queries';
import { API_PATCH_communityVaccinationStatusV2 } from 'requests/PATCH_communityVaccinationStatus';
import { fetchCommunity, fetchCommunityVariables } from 'types/graphql-types';

export const fetchCommunityConfig: (
  communityId: number,
) => UseGQLQueryConfig<fetchCommunity, fetchCommunity, fetchCommunityVariables> = (communityId) => {
  return {
    key: 'SHIFT_DISTRIBUTION_FETCH_COMMUNITY_QUERY',
    query: QUERY_settingsShiftDistributionPage,
    variables: {
      id: communityId,
    },
  };
};

export const vaccinationStatusRequestOptions = (
  vaccinationRequired: 'true' | 'false',
  communityId: number,
) =>
  API_PATCH_communityVaccinationStatusV2({
    communityId,
    vaccinationRequired: vaccinationRequired === 'true',
  });
