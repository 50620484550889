import { Flex } from '@bookjane2/bookjane-design-library';
import {
  BKJAvatarStyled,
  CancellationReason,
  ColorDot,
  EditedFields,
  InlineBKJButton,
  MobileTableCell,
  MobileTableRowViewDetailsCell,
  AuditTrailActivityTabButton,
  MobileAuditTrailContainer,
  MobileActivityDate,
  AwardedShift,
} from 'components/ActivityTab/ActivityTab.styled';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';
import { useActivityTabRowPropsContext } from 'components/ActivityTab/ActivityTabRow.props.context';
import { MobileAuditTrailActivityCell } from 'components/AuditTrailActivityTab/AuditTrailActivityTab.styled';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { FC, Fragment, useCallback } from 'react';
import { MountTransition } from 'routes';
import {
  shiftModalOrderDetails_fetchOrder_activities_user_Jane,
  shiftModalOrderDetails_fetchOrder_activities_user_User,
} from 'types/graphql-types';

enum ActivityTypeEnum {
  CALLOUT,
  REGULAR,
  AUTOMATED,
  BIDDING,
}

type ExtractActivityTypeParams = {
  eventName: string;
  userFirstName: string | null;
  userLastName: string | null;
};

const extractActivityType = ({
  eventName,
  userFirstName,
  userLastName,
}: ExtractActivityTypeParams): ActivityTypeEnum => {
  if (
    eventName &&
    (eventName === 'callout_list_created' || eventName === 'new_callout_list_created')
  ) {
    return ActivityTypeEnum.CALLOUT;
  }
  if (eventName && eventName === 'placed_bid') {
    return ActivityTypeEnum.BIDDING;
  }
  if (userFirstName && userLastName) {
    return ActivityTypeEnum.REGULAR;
  }
  return ActivityTypeEnum.AUTOMATED;
};

export const MobileAuditTrailActivityTabRow: FC = () => {
  const { t, i18n } = useTranslation();
  const {
    activityDate,
    calloutListId,
    dotColor,
    eventName,
    eventType,
    hasEdits,
    hasCancellationReason,
    sentToNrOfAgencies,
    showHideDetails,
    cancellationReason,
    hasReasonCategory,
    reasonCategory,
    userDetails,
    editDetailList,
    detailsShown,
    awardedTo,
  } = useActivityTabRowPropsContext();
  const goToAuditTrailView = useCallback(
    () =>
      shiftModalRoutingService.goToView('AuditTrailView', {
        calloutListId,
      }),
    [calloutListId],
  );

  const activityType = extractActivityType({
    eventName,
    userFirstName: userDetails?.firstName,
    userLastName: userDetails?.lastName,
  });

  const GetActivityViewComponent = () => {
    const showPendingSignUp = !!(
      userDetails as shiftModalOrderDetails_fetchOrder_activities_user_Jane
    )?.pendingSignup;

    const title = (userDetails as shiftModalOrderDetails_fetchOrder_activities_user_User)?.title;

    const communityName = (
      userDetails as shiftModalOrderDetails_fetchOrder_activities_user_Jane &
        shiftModalOrderDetails_fetchOrder_activities_user_User
    )?.communityName;

    switch (activityType) {
      case ActivityTypeEnum.CALLOUT:
        return (
          <AuditTrailActivityTabButton onClick={goToAuditTrailView} variant="PurpleOutlined">
            {t('schedule:VIEW')}
          </AuditTrailActivityTabButton>
        );
      case ActivityTypeEnum.REGULAR:
        return (
          <Flex alignItems="center" gap="8px">
            <Fragment>
              <BKJAvatarStyled
                width="30px"
                height="30px"
                fallbackAvatarColor="TextDarkGrey"
                src={userDetails?.avatarUrls?.origin}
                showPendingSignUp={showPendingSignUp}
              />
              <Flex flexDirection="column">
                <div>{`${userDetails.firstName} ${userDetails.lastName}`}</div>
                <div>{title}</div>
              </Flex>
            </Fragment>
          </Flex>
        );
      case ActivityTypeEnum.BIDDING:
        return (
          <Flex alignItems="center" gap="8px">
            <Fragment>
              <BKJAvatarStyled
                width="30px"
                height="30px"
                fallbackAvatarColor="TextDarkGrey"
                src={userDetails?.avatarUrls?.origin}
                showPendingSignUp={showPendingSignUp}
              />
              <Flex flexDirection="column">
                <div>{`${userDetails?.firstName} ${userDetails?.lastName}`}</div>
                <div>{communityName}</div>
              </Flex>
            </Fragment>
          </Flex>
        );
      case ActivityTypeEnum.AUTOMATED:
        return t('status:AUTOMATED');
    }
  };
  return (
    <MobileAuditTrailContainer>
      <MobileActivityDate>{activityDate}</MobileActivityDate>
      <MobileAuditTrailActivityCell>
        <Flex alignItems="flex-start" gap="8px" width="100%">
          <ColorDot color={dotColor}>•</ColorDot>
          <Flex flexDirection="column" justifyContent="center" width="100%">
            <Flex justifyContent="space-between">
              <MobileTableCell>
                {getTranslatedResponse(eventType, 'status', i18n, t)}{' '}
                {eventName === 'awarded_shift' && <AwardedShift>{awardedTo}</AwardedShift>}
              </MobileTableCell>
              {hasEdits && (
                <EditedFields>{`${editDetailList.length} ${t('dashboard:CHANGES')}`}</EditedFields>
              )}
            </Flex>
            {sentToNrOfAgencies > 0 && (
              <EditedFields>
                {sentToNrOfAgencies} {t('common:AGENCY_MEMBERS')}
              </EditedFields>
            )}
            {hasEdits && (
              <InlineBKJButton variant="PurpleText" onClick={showHideDetails}>
                {detailsShown ? t('dashboard:HIDE_DETAILS') : t('common:VIEW_DETAILS')}
              </InlineBKJButton>
            )}
          </Flex>
        </Flex>
      </MobileAuditTrailActivityCell>
      <MobileTableCell>{GetActivityViewComponent()}</MobileTableCell>
      {hasCancellationReason ? (
        <MobileTableRowViewDetailsCell>
          <CancellationReason>
            {t('dashboard:REASON')}: {reasonCategory ? reasonCategory : cancellationReason}
          </CancellationReason>
        </MobileTableRowViewDetailsCell>
      ) : null}
      {hasEdits && detailsShown ? (
        <MobileTableRowViewDetailsCell>
          <MountTransition>
            <div>{!hasCancellationReason && !!cancellationReason && cancellationReason}</div>
            <div>{hasReasonCategory && reasonCategory}</div>
            {editDetailList?.map((edit) => (
              <div style={{ wordBreak: 'break-word' }}>{edit}</div>
            ))}
          </MountTransition>
        </MobileTableRowViewDetailsCell>
      ) : null}
    </MobileAuditTrailContainer>
  );
};
