import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function usePathname() {
  const { pathname: _pathname } = useLocation();
  const [pathname, setPathname] = useState(_pathname);

  useEffect(() => {
    if (pathname !== _pathname) setPathname(_pathname);
  }, [_pathname, pathname]);

  const memoized = useMemo(() => pathname, [pathname]);

  return memoized;
}
