import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { isRecurring } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import {
  AllowOvertimeToggleInputLabel,
  AllowOvertimeToggleInputWrapper,
  StyledBKJToggleInput,
  ShiftCardButtonWithNoHeight,
} from './AllowOvertimeToggleInput.styled';
import { useTranslation } from 'hooks/useTranslation';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { OpenShiftPolicyEnum } from 'types/graphql-types';

export const AllowOvertimeToggleInput: FC = () => {
  const { createShift, handleChange } = useContext(CreateShiftCardContext);
  const { values } = createShift;
  const { allow_overtime } = values;
  const { t } = useTranslation();
  const isShiftBiddingFeatureEnabled = useSelector(isShiftBiddingFeatureEnabledSelector);
  const isShiftBiddingRequired =
    values?.openShiftPolicy === OpenShiftPolicyEnum.bidding_required &&
    isShiftBiddingFeatureEnabled;

  return (
    <AllowOvertimeToggleInputWrapper>
      <ShiftCardButtonWithNoHeight variant="PurpleNoDecorations" disabled={isRecurring(values)}>
        <AllowOvertimeToggleInputLabel>
          {t('create_shifts:ALLOW_OVERTIME')}?
        </AllowOvertimeToggleInputLabel>
      </ShiftCardButtonWithNoHeight>
      <StyledBKJToggleInput
        variant="Switch"
        name="allow_overtime"
        checked={isRecurring(values) ? false : !!allow_overtime || isShiftBiddingRequired}
        onChange={handleChange}
        disabled={isRecurring(values) || isShiftBiddingRequired}
      />
    </AllowOvertimeToggleInputWrapper>
  );
};
