import { ShiftModalContextType } from 'components/ShiftModal/ShiftModal.ui.context';
import {
  SendToAgenciesFormSchema,
  SendToAgenciesPostActionType,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.types';
import { ORDER_SEND_TO_AGENCIES } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from './index.types';

type POST_SendToAgenciesPayloadType = {
  hours_before_start?: string;
  jane_id?: string;
  post_action?: SendToAgenciesPostActionType;
  dedicated?: boolean;
  agency_community_ids?: number[];
} & URLSearchParams;

function payloadTransformer(values: SendToAgenciesFormSchema): POST_SendToAgenciesPayloadType {
  const sendToAgenciesPayload: Record<string, unknown> = {};

  if (values.specific_time_selection[0] !== 'now')
    sendToAgenciesPayload.hours_before_start = values.specific_time_selection[0];

  if (values.post_action) {
    if (!!values.jane_id) sendToAgenciesPayload.jane_id = values.jane_id;
    if (values.post_action === 'send_to_agencies' && values.isDedicated)
      sendToAgenciesPayload.post_action = 'send_to_agencies_dedicated';
    else sendToAgenciesPayload.post_action = values.post_action;
  } else {
    sendToAgenciesPayload.dedicated = values.isDedicated;
  }

  if (values.selectedAgencies.length > 0)
    sendToAgenciesPayload.agency_community_ids = values.selectedAgencies;

  return sendToAgenciesPayload as unknown as POST_SendToAgenciesPayloadType;
}

export const API_POST_sendOrderToAgencies = (
  orderId: ShiftModalContextType['shift_id'],
  sendToAgenciesParameters: SendToAgenciesFormSchema,
): ApiRequestConfigType => {
  const payload = payloadTransformer(sendToAgenciesParameters);
  return {
    __typename: 'LegacyAPI',
    url: ORDER_SEND_TO_AGENCIES(orderId),
    body: payload,
    method: METHOD_POST,
  };
};
