import { CUSTOMER_SECONDARY_USERS } from 'constants/endpoints';
import { AdministratorSettingsLocationFormFields } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_PUT_updateCustomerSecondaryDetailsLocation = (
  id: string,
  payload: AdministratorSettingsLocationFormFields,
): ApiRequestConfigType => {
  const communities = payload.communities.filter((item) => item.isIncluded);
  return {
    __typename: 'LegacyAPI',
    method: METHOD_PUT,
    url: `${CUSTOMER_SECONDARY_USERS}/${id}`,
    body: {
      secondary_user: {
        communities: communities,
      },
    },
  };
};
