import { shiftStatusMap } from 'constants/shiftStatusMap';
import { StyledBKJAvatar } from 'components/BKJJaneAvatar/BKJJaneAvatar.styled';
import { IJaneAvatarProps } from './BKJJaneAvatar.types';

export const JaneAvatar = (props: IJaneAvatarProps): JSX.Element => {
  const { status, url, size = 24, pendingSignup, ...rest } = props;

  return (
    <StyledBKJAvatar
      src={url}
      fallbackAvatarColor={shiftStatusMap[status]}
      height={`${size}px`}
      width={`${size}px`}
      size={size}
      fontSize={size}
      status={status}
      showPendingSignUp={pendingSignup}
      {...rest}
    />
  );
};
