import { QuerySwitch } from 'components/QuerySwitch';
import { FetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import { DeleteTimeOffViewPropsProvider } from 'components/TimeOffModal/views/DeleteTimeOffView/DeleteTimeOffView.props.context';
import { FC } from 'react';
import { DeleteTimeOffViewComponent } from './DeleteTimeOffView.component';

export const DeleteTimeOffView: FC = () => {
  return (
    <QuerySwitch
      context={FetchTimeOffDataContext}
      component={() => (
        <DeleteTimeOffViewPropsProvider>
          <DeleteTimeOffViewComponent />
        </DeleteTimeOffViewPropsProvider>
      )}
    />
  );
};
