import { J360Logo } from 'components/BKJMainNavigation/src/J360Logo';
import { AccushieldMainLogo } from 'components/WhiteLabelAssetComponents/Accushield/AccushieldMainLogo';
import { PetalMainLogo } from 'components/WhiteLabelAssetComponents/Petal/PetalMainLogo';
import { XtmMainLogo } from 'components/WhiteLabelAssetComponents/Xtm/XtmMainLogo';
import { FC, Fragment, useCallback } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

type WhiteLabelMainLogoProps = { width?: string };

export const WhiteLabelMainLogo: FC<WhiteLabelMainLogoProps> = (props) => {
  const { WhiteLabelProvidersEnum, whiteLabelProvider } = WhiteLabelAccessor;

  const RenderMainLogoComponent = useCallback(() => {
    switch (whiteLabelProvider) {
      case WhiteLabelProvidersEnum.ACCUSHIELD:
        return <AccushieldMainLogo {...props} />;
      case WhiteLabelProvidersEnum.PETAL:
        return <PetalMainLogo {...props} />;
      case WhiteLabelProvidersEnum.XTM:
        return <XtmMainLogo {...props} />;
      case WhiteLabelProvidersEnum.WHITE_LABEL_DEMO:
        return <Fragment />;
      default:
        return <J360Logo />;
    }
  }, [
    whiteLabelProvider,
    WhiteLabelProvidersEnum.ACCUSHIELD,
    WhiteLabelProvidersEnum.PETAL,
    WhiteLabelProvidersEnum.XTM,
    WhiteLabelProvidersEnum.WHITE_LABEL_DEMO,
    props,
  ]);

  return <RenderMainLogoComponent />;
};
