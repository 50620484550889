import { getCurrentMonthCalendarRange } from 'components/BKJDatePicker/src/BKJDatePicker.utils';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { DateTime } from 'luxon';
import {
  ShiftsCalendarPageInputValues,
  ShiftsCalendarPagePayloadTransformer,
} from 'pages/ShiftsCalendarPage/ShiftsCalendarPage.types';
import { QUERY_shiftsCalendarPage } from 'queries';
import { SessionService } from 'services';

const payloadTransformer: ShiftsCalendarPagePayloadTransformer = (variables) => {
  const startFrom = DateTime.fromISO(variables.filterDate[0]).toFormat('yyyy-MM-dd');
  const startTo = DateTime.fromISO(variables.filterDate[1]).toFormat('yyyy-MM-dd');
  const payload = {
    currentPage: variables.currentPage,
    first: variables.first,
    last: variables.last,
    after: variables.after,
    before: variables.before,
    filter: {
      forJanes: {
        byFullName: variables.byFullName,
      },
      forOrders: {
        withCommunity: undefined as number | undefined,
        withStatus: variables.shiftStatus,
        withPositions: variables.position_id,
        withJaneType: [...variables.internalJaneType],
        withStaff: {
          types: [...variables.externalJaneType],
          facility: {
            id: Number(variables.location.id),
            type: SessionService.assertUserType('B2B') ? 'Community' : 'AgencyCommunity',
          },
        },
        withTimeFrame: variables.timeFrameType,
        startBetweenTimeRange: {
          from: `${DateTime.fromFormat(startFrom, 'yyyy-MM-dd', {
            zone: variables.timeZone,
          }).toISO()}`,
          to: `${DateTime.fromFormat(startTo, 'yyyy-MM-dd', { zone: variables.timeZone })
            .set({ hour: 23, minute: 59 })
            .toISO()}`,
        },
        withActionsRequired: variables.actionsRequiredType,
      },
      forAgencyCommunities: {
        withId: undefined as number | undefined,
      },
    },
    groupBy: [{ field: 'startDate' }, { field: 'status' }],
  };
  if (!variables.viewAllFacilities) {
    if (SessionService.assertUserType('B2B')) {
      payload.filter.forOrders['withCommunity'] = Number(variables.location.value);
    }

    if (
      SessionService.assertUserType('Agency') &&
      !variables.isUserAgencyAndAgencyLocationFilterForcedDisabled
    )
      payload.filter.forAgencyCommunities['withId'] = Number(variables.location.value);
  }

  return payload;
};

export const shiftsCalendarPageConfig = ({
  location,
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
}: {
  location: ILocationFilterProps['value'];
  isUserAgencyAndAgencyLocationFilterForcedDisabled: boolean;
}): ISSFBehaviorsOptions => {
  return {
    key: 'SHIFTS_CALENDAR_PAGE',
    query: QUERY_shiftsCalendarPage,
    pageSize: 35,
    payloadTransformer: (variables: ShiftsCalendarPageInputValues) =>
      payloadTransformer({
        ...variables,
        isUserAgencyAndAgencyLocationFilterForcedDisabled,
        timeZone: (location as { timeZone?: string })?.timeZone || undefined,
      }),
    schema: {
      byFullName: {
        initialValue: '',
      },
      shiftStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      position_id: {
        initialValue: [],
        isSessionCached: true,
      },
      employmentStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      location: {
        initialValue: location,
        isGlobalCached: true,
        isPreservedOnReset: true,
        resetFieldsOnChange: [
          {
            field: 'position_id',
            userTypes: ['Agency'],
          },
        ],
      },
      filterDate: {
        initialValue: getCurrentMonthCalendarRange(),
        isSessionCached: true,
      },
      internalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      timeFrameType: {
        initialValue: [],
        isSessionCached: true,
      },
      externalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      actionsRequiredType: {
        initialValue: [],
        isSessionCached: true,
      },
      viewAllFacilities: {
        initialValue: false,
        isSessionCached: true,
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
    },
  };
};
