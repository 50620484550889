import { B2BDepartmentsNavigationComponent } from 'components/SettingsNavigation/B2BDepartmentsNavigation/B2BDepartmentNavigation.component';
import { FetchDepartmentsDataProvider } from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/contexts/FetchDepartments.data.context';
import { ISettingsNavigationProps } from 'components/SettingsNavigation/ExpandableMenuLink/ExpandableMenuLink.types';
import { MenuAccordion } from 'components/SettingsNavigation/SettingsNavigation.styled';
import { useCachedScrollRef } from 'hooks/useCachedScrollRef';
import { useNumSettingsMenuItems } from 'hooks/useNumSettingsMenuItems';
import { FC } from 'react';

export const B2BDepartmentsNavigation: FC<ISettingsNavigationProps> = ({ isOpen, isExpanded }) => {
  const numMenuItems = useNumSettingsMenuItems();
  const { scrollElementRef, handleScroll } = useCachedScrollRef(
    'B2BDepartmentsSettingsNavigationScrollTop',
  );
  return (
    <MenuAccordion
      $isOpen={isOpen}
      onScroll={handleScroll}
      $isExpanded={isExpanded}
      numMenuItems={numMenuItems}
      ref={scrollElementRef}
    >
      <FetchDepartmentsDataProvider>
        <B2BDepartmentsNavigationComponent ref={scrollElementRef} />
      </FetchDepartmentsDataProvider>
    </MenuAccordion>
  );
};
