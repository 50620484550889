import { BKJComboBoxAsync, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  LocationFilterDataContext,
  LocationFilterDataProvider,
  useLocationFilterDataContext,
} from 'components/LocationFilterInput/LocationFilterInput.context';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { usePathname } from 'hooks/usePathname';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { useTranslation } from 'hooks/useTranslation';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { LocationFilterWrapper } from 'components/LocationFilterInput/LocationFilterInput.styled';

function LocationFilterInputComponent(props: Omit<ILocationFilterProps, 'options'>) {
  const { value, onChange, name = 'location', disabled, dropDownWidth } = props;
  const { t } = useTranslation();
  const pathname = usePathname();
  const { data: options = [], values, onChange: onSearchChange } = useLocationFilterDataContext();
  const isLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );
  let __disabled__ = disabled;

  const isTheCurrentPageAnythingOtherThanTimesheets =
    pathname !== '/timesheets' && !pathname.includes('/communications');

  if (isLocationFilterForcedDisabled && isTheCurrentPageAnythingOtherThanTimesheets) {
    __disabled__ = true;
  }
  return (
    <LocationFilterWrapper width={dropDownWidth}>
      <BKJComboBoxAsync
        name={name}
        type="Single"
        value={value}
        icon="Location1"
        searchKey="byName"
        disabled={__disabled__}
        onChange={onChange}
        action="OpenToggle"
        options={options}
        searchValue={values.byName}
        onSearchChange={onSearchChange}
        context={LocationFilterDataContext}
        placeholder={t('schedule:SEARCH_LOCATION_PLACEHOLDER')}
      />
    </LocationFilterWrapper>
  );
}

export const LocationFilterInput = (props: Omit<ILocationFilterProps, 'options'>) => {
  return (
    <ErrorBoundary
      componentName="LocationFilterInput"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <LocationFilterDataProvider>
        <LocationFilterInputComponent {...props} />
      </LocationFilterDataProvider>
    </ErrorBoundary>
  );
};
