import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import {
  AdministratorSettingsInviteAdminFormFields as IASFFields,
  AdministratorSettingsGeneralFormApiError as IASFApiError,
  CommunitiesForCheckbox,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { createContext, FC, useContext, useMemo } from 'react';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { API_POST_inviteSecondaryUsers } from 'requests/API_POST_inviteSecondaryUsers';
import { VALIDATION_email, VALIDATION_required } from 'validations';
import { useInviteAdminModalDataContext } from 'components/InviteAdminModal/InviteAdminModal.data.context';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { API_POST_inviteAgencySecondaryUsers } from 'requests/API_POST_inviteAgencySecondaryUsers';
import { useAdministratorSettingsDataContext } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { useTranslation } from 'hooks/useTranslation';

const InviteAdminModalFormContext = createContext<UseFormBehaviors<IASFFields, IASFApiError>>(
  {} as UseFormBehaviors<IASFFields, IASFApiError>,
);

export const InviteAdminModalFormProvider: FC = ({ children }) => {
  const { communities } = useInviteAdminModalDataContext();
  const { refetchData } = useAdministratorSettingsDataContext();
  const { t } = useTranslation();
  const communitiesAllData = useMemo(
    () =>
      communities?.map((community) => {
        let shift_emails = !!community?.shift_emails ? community.shift_emails : false;
        let isIncluded = false;
        let name = community.name;
        var abc: CommunitiesForCheckbox = {
          id: community.id,
          shift_emails: shift_emails,
          isIncluded: isIncluded,
          name: name,
        };
        return abc;
      }),
    [communities],
  );
  const { apiRequest } = useAPIActions();
  const form = useFormBehaviors<IASFFields, IASFApiError>({
    initialState: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      communities: communitiesAllData,
    },
    isDirtyCheckEnabled: true,
    onSubmit: useUserTypeSwitch({
      Agency: (formValues: IASFFields) => {
        return apiRequest(API_POST_inviteAgencySecondaryUsers(formValues), {
          onError: (error) => {
            if (error && error?.data && error?.data?.message)
              toastService.error(`${error.data.message}`);
            else toastService.error(t('FAILURE_TOAST_MESSAGE'));
          },
          onSuccess: () => {
            toastService.success(t('success:SUCCESSFULLY_UPDATED'));
            refetchData();
          },
        });
      },
      B2B: (formValues: IASFFields) => {
        return apiRequest(API_POST_inviteSecondaryUsers(formValues), {
          onError: (error) => {
            if (error && error?.data && error?.data?.message)
              toastService.error(`${error.data.message}`);
            else toastService.error(t('FAILURE_TOAST_MESSAGE'));
          },
          onSuccess: () => {
            toastService.success(t('success:SUCCESSFULLY_UPDATED'));
            refetchData();
          },
        });
      },
    }),
    validations: {
      first_name: VALIDATION_required,
      last_name: VALIDATION_required,
      email: VALIDATION_email,
    },
  });

  const contextValues = useMemo(
    () => ({
      ...form,
      isDisabled:
        form.isDisabled || !form.values.first_name || !form.values.last_name || !form.values.email,
    }),
    [form],
  );
  return (
    <InviteAdminModalFormContext.Provider value={contextValues}>
      {children}
    </InviteAdminModalFormContext.Provider>
  );
};

export const useInviteAdminModalFormContext = () =>
  useContext<UseFormBehaviors<IASFFields, IASFApiError>>(InviteAdminModalFormContext);
