import { DateTime } from 'luxon';
import {
  IUnassignedShiftResponse,
  IMappedShiftResponse,
} from 'mappers/scheduling/schedule.rest.types';

const TYPE_DRAFT_UNASSIGNED = 'unassigned';

function GET_schedulingDraftShiftsUnassignedMapper(
  apiResponseData: IUnassignedShiftResponse = { day_schedules: [] },
) {
  const { day_schedules } = apiResponseData;
  const totals = {
    statuses: {
      open: 0,
      pending: 0,
      assigned: 0,
      in_progress: 0,
      accepted: 0,
      completed: 0,
      unfulfilled: 0,
      cancelled: 0,
      swapped: 0,
      absent: 0,
    },
    sent_to_agencies: 0,
  };
  const data: { [key: string]: IMappedShiftResponse } = {};

  day_schedules.forEach(({ date, shifts }) => {
    const shiftsMapByDay: IMappedShiftResponse = { shifts: {}, shifts_ids: [] };
    shifts.forEach((shift) => {
      shiftsMapByDay.shifts[shift.id] = {
        ...shift,
        _type: TYPE_DRAFT_UNASSIGNED,
      };
      if (shift.sent_to_agencies) totals.sent_to_agencies += 1;
      totals.statuses[shift.status] += 1;
      shiftsMapByDay.shifts_ids.push(shift.id);
    });

    data[DateTime.fromISO(date).startOf('day').toString()] = shiftsMapByDay;
  });
  return { ...data, totals };
}

export default GET_schedulingDraftShiftsUnassignedMapper;
