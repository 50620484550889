import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getAuth = (state: IGlobalReduxStore) => state.auth;

export const isAgencyLocationFilteringEnabledSelector = createSelector([getAuth], (auth) => {
  if (
    auth?.permissions &&
    auth?.permissions?.AGENCY_LOCATION_FILTERING &&
    auth?.permissions?.AGENCY_LOCATION_FILTERING?.access.includes('read')
  ) {
    return true;
  }

  return false;
});
