type ToggleZenDeskConditions = {
  windowObject?: Window | undefined;
  condition?: boolean;
};

export function toggleZenDesk(command: 'hide' | 'show', conditions: ToggleZenDeskConditions = {}) {
  const { windowObject = window, condition = true } = conditions;

  if (!!windowObject?.zE && condition) {
    windowObject.zE('messenger', command);
  }
}
