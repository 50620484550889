import { elementDimensions } from 'constants/elementDimensions';
import styled from 'styled-components';

export const ShiftsCalenderWrapper = styled.div`
  height: calc(100% - ${elementDimensions.ShiftStatusSummaryFooterHeight}px);
  max-height: calc(100% - ${elementDimensions.ShiftStatusSummaryFooterHeight}px);
  overflow: overlay;
  max-width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 16px;
`;
