import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isShiftDistributionFeatureEnabledSelector = createSelector(
  getUserPermissions,
  (userPermissions) => {
    if (!userPermissions?.VACCINATION_REQUIRED) return false;
    return userPermissions?.VACCINATION_REQUIRED?.access.includes('read');
  },
);
