import { requestAwardTeamConfig } from 'components/ShiftModal/src/views/AwardTeamMemberView/AwardTeamMemberView.constants';
import { useSSFBehaviors, useURLParameters } from 'hooks';
import { FC, MouseEventHandler, useContext } from 'react';
import { GQLAPIContextType } from 'types/common.types';
import { fetchOrderBidders } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
export const AwardTeamMemberContext = createGQLDataContext({});
export const AwardTeamMemberProvider: FC = ({ children }) => {
  const [{ shiftModalShiftId }] = useURLParameters<{ shiftModalShiftId?: string }>();

  const [values, { data, ...rest }] = useSSFBehaviors(
    requestAwardTeamConfig({ orderId: Number(shiftModalShiftId) }),
  );

  return (
    <AwardTeamMemberContext.Provider
      value={{
        values,
        data,
        ...rest,
      }}
    >
      {children}
    </AwardTeamMemberContext.Provider>
  );
};

export const useAwardTeamMemberContext = (): GQLAPIContextType<fetchOrderBidders> & {
  onReset: MouseEventHandler<HTMLDivElement>;
  data: fetchOrderBidders;
} => useContext(AwardTeamMemberContext);
