import { IReasonForEditsOption } from 'components/ShiftModal/src/views/ReasonForEdits/ReasonForEdits.types';
import i18next from 'i18next';

export const reasonForEditsSelectOptions = (): IReasonForEditsOption[] => [
  {
    key: 'ShiftTimeChangedByTeamMember',
    value: 'shift_time_changed_by_team_member',
    label: i18next.t('shift_modal:SHIFT_TIME_CHANGED_BY_TEAM_MEMBER'),
    id: 'shift_time_changed_by_team_member',
  },
  {
    key: 'ShiftTimeChangedByScheduler',
    value: 'shift_time_changed_by_scheduler',
    label: i18next.t('shift_modal:SHIFT_TIME_CHANGED_BY_SCHEDULER'),
    id: 'shift_time_changed_by_scheduler',
  },
  {
    key: 'NotesUpdated',
    value: 'notes_updated',
    label: i18next.t('shift_modal:NOTES_UPDATED'),
    id: 'notes_updated',
  },
  {
    key: 'CustomFieldsUpdated',
    value: 'custom_fields_updated',
    label: i18next.t('shift_modal:CUSTOM_FIELDS_UPDATED'),
    id: 'custom_fields_updated',
  },
  {
    key: 'Other',
    value: 'other',
    label: i18next.t('shift_modal:OTHER'),
    id: 'other',
  },
];
