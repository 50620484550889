import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { IGlobalReduxStore } from 'store';
import { isUserObjectB2bType } from 'types/common.types';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

export const userRoleSelector = createCachedSelector(getUser, (user) => {
  if (!user) return null;

  const { type } = user;
  if (isUserObjectB2bType(user)) {
    const { user_role } = user;
    if (user_role === 'essential') return 'Essential';
    if (user_role === 'premium') return 'Premium';
  } else if (type) {
    if (type === 'AgencyManager' || type === 'AgencyOwner') return 'Agency';
  }
})((store) => {
  if (!store.auth.user) return 'NO_SESSION';
  if (isUserObjectB2bType(store.auth.user))
    return `${store.auth.user.id}${store.auth.user.type}${store.auth.user.user_role}`;
  return 'AGENCY_USER';
});

export const useUserRoleSelector = () => useSelector(userRoleSelector);
