import { RecurringOrderAttributes } from 'types/graphql-types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import {
  QUERY_createShiftModalRequestTeamMember,
  QUERY_createShiftModalRequestTeamMemberRecurring,
} from 'queries';

export const requestTeamConfig = ({
  facilityId,
  isDedicated,
  positionId,
  recurring,
  byStartTime,
  byEndTime,
  allowOvertime,
}: {
  facilityId: number;
  isDedicated: boolean;
  positionId: number;
  recurring?: RecurringOrderAttributes | undefined;
  byStartTime: string;
  byEndTime: string;
  allowOvertime: boolean;
}): ISSFBehaviorsOptions => ({
  debounceTimeout: 500,
  key: 'CREATE_SHIFT_REQUEST_TEAM_MEMBER_VIEW_QUERY',
  query: recurring
    ? QUERY_createShiftModalRequestTeamMemberRecurring
    : QUERY_createShiftModalRequestTeamMember,
  paginationType: 'InfiniteScroll',
  pageSize: 18,
  variables: {},
  schema: {
    byFullName: {
      initialValue: '',
    },
    allowOvertime: {
      initialValue: allowOvertime,
    },
    isDedicated: {
      initialValue: isDedicated,
    },
    byPositionIdInt: {
      initialValue: positionId,
    },
    byPositionIdArray: {
      initialValue: [positionId],
    },
    byFacilityId: {
      initialValue: facilityId,
    },
    byEmploymentStatus: {
      initialValue: [],
    },
    byAgency: {
      initialValue: [],
    },
    recurring: {
      initialValue: recurring,
    },
    byStartTime: {
      initialValue: byStartTime,
    },
    byEndTime: {
      initialValue: byEndTime,
    },
  },
});
