import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import { ShiftStatusCodeType } from 'constants/shiftStatusMap';
import styled, { css } from 'styled-components';
import { OrderStatusEnum } from 'types/graphql-types';

interface IProps {
  status: ShiftStatusCodeType;
  size?: number;
  width?: string;
  height?: string;
  showPendingSignUp: boolean;
}

export const StyledBKJAvatar = styled(BKJAvatar)<IProps>`
  opacity: ${({ status }) => (status === OrderStatusEnum.requested ? '0.5' : '1')};
  ${({ size }) => {
    return css`
      min-width: calc(${size}px / 2);
      min-height: calc(${size}px / 2);
    `;
  }};
`;
