import { QuerySwitch } from 'components/QuerySwitch';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { FetchSelectedAgenciesRateDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests/FetchSelectedAgenciesRate.data.context';
import { SelectedAgenciesPayComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/ShiftPay/SelectedAgenciesPay/SelectedAgenciesPay.component';
import { FC } from 'react';
import { API_POST_selectedAgencyCommunitiesHourlyRate } from 'requests/POST_selectedAgencyCommunitiesHourlyRate';

export const SelectedAgenciesPay: FC = () => {
  const { values } = useSendToAgenciesFormContext();
  const {
    data: {
      fetchOrder: {
        totalDuration,
        position: { id },
      },
    },
  } = useShiftModalDataContext();

  const requestOptions = API_POST_selectedAgencyCommunitiesHourlyRate({
    agencyCommunityIds: values.selectedAgencies,
    positionId: id,
    shiftLength: totalDuration!,
  });

  return (
    <FetchSelectedAgenciesRateDataProvider>
      <QuerySwitch component={SelectedAgenciesPayComponent} context={requestOptions} />
    </FetchSelectedAgenciesRateDataProvider>
  );
};
