import styled, { css } from 'styled-components';

interface RequestTeamMemberFilterBarWrapperProps {
  isOpen: boolean;
}

export const RequestTeamMemberFilterBarWrapper = styled.div<RequestTeamMemberFilterBarWrapperProps>`
  transition: all 150ms ease-in-out;
  width: 100%;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.Grey1};
  min-height: 0px;
  max-height: 0px;
  height: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${({ isOpen }) => {
    return (
      isOpen &&
      css`
        min-height: 60px;
        max-height: 60px;
        height: 60px;
      `
    );
  }}
`;
