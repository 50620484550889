import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_timesheetCard } from 'queries';
import { timesheetCard, timesheetCardVariables } from 'types/graphql-types';
import { UserGuardType } from 'guards/UserGuard.types';

export const USER_TYPE_CAN_APPROVE_TIMESHEETS: UserGuardType[] = [
  'USER_TYPE_B2B',
  'USER_TYPE_ESSENTIAL_PRIMARY',
  'USER_TYPE_ESSENTIAL_SECONDARY',
  'USER_TYPE_PREMIUM_PRIMARY',
  'USER_TYPE_PREMIUM_SECONDARY',
];

export function fetchTimesheetQueryConfig(
  orderScheduleId: number,
): UseGQLQueryConfig<timesheetCard, timesheetCard, timesheetCardVariables> {
  return {
    key: 'FETCH_TIMESHEET_QUERY',
    query: QUERY_timesheetCard,
    variables: {
      orderScheduleId,
    },
  };
}
