import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import {
  TimeFrameLocationSettingsFormApiError as GLSFApiError,
  TimeFrameLocationSettingsFormFields as GLSFFields,
  calculateTimeDifference,
  calculateTotalHours,
} from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.types';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { useTranslation } from 'hooks/useTranslation';
import { useTimeFrameLocationSettingsPageDataContext } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.data.context';
import { API_PUT_updateCommunityDetailsTimeFrame } from 'requests/PUT_updateCommunityDetailsTimeFrame.ts';
import { useFormBehaviors } from 'hooks';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

export interface ITimeFrameLocationFormActions {
  buttonDisabled: boolean;
  setButtonDisabled: (buttonDisabled: boolean) => void;
}
const TimeFrameLocationSettingsFormFormContext = createContext(
  {} as UseFormBehaviors<GLSFFields, GLSFApiError> & ITimeFrameLocationFormActions,
);
export const TimeFrameLocationSettingsFormFormProvider: FC = ({ children }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data } = useTimeFrameLocationSettingsPageDataContext();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();
  const form = useFormBehaviors<GLSFFields, GLSFApiError>({
    initialState: data,
    onSubmit: async (formValues: GLSFFields) => {
      return apiRequest(
        API_PUT_updateCommunityDetailsTimeFrame(locationId, {
          timeFrameAttributes: {
            morning_starts_at: formValues.morningStartsAt,
            evening_starts_at: formValues.eveningStartsAt,
            night_starts_at: formValues.nightStartsAt,
          },
        }),
        {
          onError: handleGenericErrorMessage,
          onSuccess: async () => {
            toastService.success(t('settings:TIME_FRAMES_SAVED'));
          },
        },
      );
    },
    validations: {
      morningStartsAt: (value: string | undefined, data: GLSFFields) => {
        if (
          value === undefined ||
          data?.morningStartsAt === undefined ||
          data?.eveningStartsAt === undefined ||
          data?.nightStartsAt === undefined
        ) {
          return '';
        }

        if (
          !(
            calculateTimeDifference(String(data?.morningStartsAt), String(data?.eveningStartsAt)) &&
            calculateTotalHours(data) === 24
          )
        ) {
          return true;
        }

        return '';
      },
      eveningStartsAt: (value: string | undefined, data: GLSFFields) => {
        if (
          value === undefined ||
          data?.morningStartsAt === undefined ||
          data?.eveningStartsAt === undefined ||
          data?.nightStartsAt === undefined
        ) {
          return '';
        }

        if (
          !(
            calculateTimeDifference(String(data?.eveningStartsAt), String(data?.nightStartsAt)) &&
            calculateTotalHours(data) === 24
          )
        ) {
          return true;
        }

        return '';
      },
      nightStartsAt: (value: string | undefined, data: GLSFFields) => {
        if (
          value === undefined ||
          data?.morningStartsAt === undefined ||
          data?.eveningStartsAt === undefined ||
          data?.nightStartsAt === undefined
        ) {
          return '';
        }

        if (
          !(
            calculateTimeDifference(String(data?.nightStartsAt), String(data?.morningStartsAt)) &&
            calculateTotalHours(data) === 24
          )
        ) {
          return true;
        }

        return '';
      },
    },
  });
  const contextValues = useMemo(() => {
    return {
      ...form,
      buttonDisabled,
      setButtonDisabled,
    };
  }, [buttonDisabled, form]);
  return (
    <TimeFrameLocationSettingsFormFormContext.Provider value={contextValues}>
      {children}
    </TimeFrameLocationSettingsFormFormContext.Provider>
  );
};

export const useTimeFrameLocationSettingsFormFormContext = () =>
  useContext(TimeFrameLocationSettingsFormFormContext);
