import { CUSTOMER_COMMUNITIESV2 } from 'constants/endpoints';
import { GeneralLocationSettingsNewFormFields as GLSNFFields } from 'pages/LocationSettingsPage/GeneralLocationSettingsPageNewForm.types';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

interface IPOST_CreateNewFacilityPayload {}

export const API_POST_createNewFacility = (formValues: GLSNFFields): ApiRequestConfigType => {
  const payload: IPOST_CreateNewFacilityPayload = {
    community: {
      name: formValues.name,
      contact_name: `${formValues.first_name} ${formValues.last_name}`,
      contact_phone: formValues.phone,
      contact_email: formValues.email,
      location_attributes: {
        longitude: formValues.longitude,
        latitude: formValues.latitude,
        flat: formValues.flat,
        street: formValues.street,
        street_number: formValues.street_number,
        city: formValues.city,
        state: formValues.state,
        country: formValues.country,
        zip_code: formValues.zip_code,
        full_address: formValues.full_address,
      },
    },
  };

  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url: CUSTOMER_COMMUNITIESV2,
    body: payload,
  };
};
