import styled from 'styled-components';
import { BKJTableRow } from 'components/BKJTable/BKJTable.styled';
import {
  BKJTableData,
  BKJTableRowWrapper,
} from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { DEDICATED_TEAM_TABLE_COLUMNS_WIDTH } from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamTable/DedicatedTeamTable.constants';

export const DedicatedTeamTableRow = styled(BKJTableRow)`
  grid-template-columns: ${DEDICATED_TEAM_TABLE_COLUMNS_WIDTH};
`;

export const DedicatedTeamTableRowWrapper = styled(BKJTableRowWrapper)`
  grid-template-columns: ${DEDICATED_TEAM_TABLE_COLUMNS_WIDTH};
`;
export const DedicatedTeamTableData = styled(BKJTableData)``;
