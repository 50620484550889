import {
  AgencyMemberTableData,
  AgencyMemberTableRowWrapper,
} from 'pages/AgencyMemberTablePage/AgencyMemberTable/AgencyMemberTableRow.styled';
import { Label, TableFlex, Text } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { BKJButton } from '@bookjane2/bookjane-design-library';

import { TeamMemberAvatar } from 'pages/TeamMemberPage/components/TeamMemberAvatar';
import { agencyMemberPage_fetchJanes_edges_node } from 'types/graphql-types';
import { startCase } from 'utils/startCase';

export function AgencyMemberTableRow(props: agencyMemberPage_fetchJanes_edges_node) {
  const {
    id = '',
    firstName = '',
    lastName = '',
    agencyCommunity = { name: '' },
    primaryPosition = { details: { name: '' } },
    positions = '',
    employmentStatus = { name: '' },
  } = props;
  return (
    <AgencyMemberTableRowWrapper
      key={id}
      onClick={() => window.alert('Clicking this should open the Team Member Sidepanel')}
    >
      <AgencyMemberTableData>
        <Label>Name</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <TeamMemberAvatar {...props} />
          <Text>{`${firstName} ${lastName}`}</Text>
        </TableFlex>
      </AgencyMemberTableData>
      <AgencyMemberTableData>
        <Label>Agency</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <Text>{`${agencyCommunity?.name}`}</Text>
        </TableFlex>
      </AgencyMemberTableData>
      <AgencyMemberTableData>
        <Label>Position</Label>
        <TableFlex flexDirection="column">
          <Text>
            {primaryPosition?.details?.name}
            <br />
            {positions.length > 0 && `+ ${positions.length} more`}
          </Text>
        </TableFlex>
      </AgencyMemberTableData>
      <AgencyMemberTableData>
        <Label>App ID</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <Text>{`${id}`}</Text>
        </TableFlex>
      </AgencyMemberTableData>
      <AgencyMemberTableData>
        <Label>Status</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <Text>{`${startCase(employmentStatus?.name)}`}</Text>
        </TableFlex>
      </AgencyMemberTableData>
      <AgencyMemberTableData>
        <Label>Action</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <BKJButton
            variant="PurpleText"
            onClick={() => window.alert('Clicking on this should trigger add to dedicated flow')}
          >
            Add to Dedicated
          </BKJButton>
        </TableFlex>
      </AgencyMemberTableData>
    </AgencyMemberTableRowWrapper>
  );
}
