import { InviteTeamMembersModalProvider } from './InviteTeamMembers.context';
import Component from './InviteTeamMembersModal';

const InviteTeamMembersModal = () => (
  <InviteTeamMembersModalProvider>
    <Component />
  </InviteTeamMembersModalProvider>
);

export { InviteTeamMembersModal };
