import * as React from 'react';

export const Background: React.FC = (props) => {
  return (
    <svg width={496} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#background_svg__clip0)">
        <path d="M0 4a4 4 0 014-4h520a4 4 0 014 4v56H0V4z" fill="#8080BA" />
        <path d="M0 4a4 4 0 014-4h282a4 4 0 014 4v56H0V4z" fill="#1C0F51" />
        <path
          d="M84.962 128.28c65.28 19.469 184.922 19.166 249.083-57.377 66.947-79.875-17.036-163.06-78.157-140.893-61.121 22.167-39.839-67.041-108.937-48.214C77.02-99.15-1.344.876 3.418 45.559c4.763 44.684 53.331 74.308 81.544 82.721z"
          fill="#1C0F51"
        />
        <path
          d="M84.962 128.28c65.28 19.469 184.922 19.166 249.083-57.377 66.947-79.875-17.036-163.06-78.157-140.893-61.121 22.167-39.839-67.041-108.937-48.214C77.02-99.15-1.344.876 3.418 45.559c4.763 44.684 53.331 74.308 81.544 82.721z"
          fill="url(#background_svg__paint0_linear)"
          fillOpacity={0.5}
        />
        <g opacity={0.1}>
          <path
            d="M685.714 32.908C651.565-35.927 553.854-136.736 426.899-137c-132.476-.269-145.291 129.236-73.888 165.232 71.404 35.996-33 80.86 41.638 125.944 75.539 45.628 236.979 41.344 276.716 5.85 39.738-35.494 29.108-97.37 14.349-127.118z"
            fill="#fff"
          />
          <path
            d="M685.714 32.908C651.565-35.927 553.854-136.736 426.899-137c-132.476-.269-145.291 129.236-73.888 165.232 71.404 35.996-33 80.86 41.638 125.944 75.539 45.628 236.979 41.344 276.716 5.85 39.738-35.494 29.108-97.37 14.349-127.118z"
            fill="url(#background_svg__paint1_linear)"
          />
        </g>
        <path
          d="M351.736 52.349c30.47-20.805 92.557-40.283 139.107-11.14 48.572 30.413 45.515 85.088 16.368 123.563-36.329 18.992-41.468 3.575-80.562 5.157-39.566 1.601-97.555-37.382-102.839-61.323-5.285-23.941 14.758-47.267 27.926-56.257z"
          fill="#34BCB3"
          opacity={0.4}
        />
      </g>
      <defs>
        <linearGradient
          id="background_svg__paint0_linear"
          x1={364.36}
          y1={29.887}
          x2={82.761}
          y2={-23.071}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C0F51" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="background_svg__paint1_linear"
          x1={332}
          y1={6.5}
          x2={484}
          y2={25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={0.755} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <clipPath id="background_svg__clip0">
          <path fill="#fff" d="M0 0h496v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
