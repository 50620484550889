import { QuerySwitch } from 'components/QuerySwitch';
import { AvailabilityLocationSettingsPageForm } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm';
import {
  LocationRecordDetailDataContext,
  LocationRecordDetailDataProvider,
} from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { FC } from 'react';

export const AvailabilityLocationSettingsPage: FC = () => {
  return (
    <LocationRecordDetailDataProvider>
      <QuerySwitch
        context={LocationRecordDetailDataContext}
        component={AvailabilityLocationSettingsPageForm}
      />
    </LocationRecordDetailDataProvider>
  );
};
