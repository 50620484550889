import { BKJSideModal } from 'components/BKJSideModal';
import { useContext } from 'react';
import { GQLAPIContextType } from 'types/common.types';
import { InviteTeamMembersModalContext } from './InviteTeamMembers.context';
import { IInviteTeamMembersModalContext } from './InviteTeamMembersModal.types';

export const InviteTeamMemberModalTemplate: React.FC<{ context?: GQLAPIContextType }> = ({
  context,
  children,
}) => {
  const { isOpen, onClose } = useContext<IInviteTeamMembersModalContext>(
    InviteTeamMembersModalContext,
  );

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={onClose}
      headerComponent="Invite Team Members"
      context={context}
    >
      {children}
    </BKJSideModal>
  );
};
