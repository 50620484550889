import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { DEFAULT_BASE_URL, UK_BASE_URL } from 'config';
import { LoginFormSchema } from 'pages/LoginPage/LoginPage';
import { API_GET_userPermissions } from 'requests/GET_userPermissions';
import { SignUpParam } from 'requests/index.types';
import { API_POST_signIn } from 'requests/POST_signIn';
import { API_POST_signUp } from 'requests/POST_signUp';
import { SessionService, sessionService } from 'services/SessionService';
import {
  B2BLoginResponseUserObject,
  IUserLoginActionPayload,
  AgencyLoginResponseUserObject,
  IRedirectUrl,
} from 'store/reducers/auth/authReducer.types';
import { BKJAuthTokenType } from 'types/common.types';
import { createBookJaneRequest } from 'utils/createBookJaneRequest';
import { fetchMeData } from 'store/reducers/auth/authReducer.constants';

enum AuthActionEnum {
  AUTH_LOGIN = 'auth/login',
  AUTH_LOGOUT = 'auth/logout',
  AUTH_VERIFY_TOKEN = 'auth/verify-token',
  AUTH_PERMISSIONS = 'auth/permissions',
  AUTH_REGISTRATION = 'auth/register',
}

export const userLogin = createAsyncThunk<IUserLoginActionPayload, LoginFormSchema>(
  AuthActionEnum.AUTH_LOGIN,
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const { data } = await createBookJaneRequest<{
        jwt: string;
        redirect_url: IRedirectUrl;
        user: B2BLoginResponseUserObject | AgencyLoginResponseUserObject;
      }>(API_POST_signIn({ username, password }));
      const { jwt: token, user, redirect_url } = data;
      const baseUrl = synthesizeBaseUrl(redirect_url);

      SessionService.setToken(token);
      const userWithRoleData = { ...(await fetchMeData({ baseUrl })), ...user };

      sessionService.onUserLoggedIn({ token, user: userWithRoleData, baseUrl });
      const response: { data: { permissions: {} } } = await createBookJaneRequest(
        API_GET_userPermissions({ baseUrl }),
      );
      return {
        permissions: response.data.permissions,
        token: sessionService.onTokenVerified(),
        user: userWithRoleData,
        baseUrl,
      };
    } catch (error) {
      sessionService.onUserLoggedOut(false);
      if ((error as AxiosError)?.response?.data)
        return rejectWithValue((error as AxiosError)?.response?.data);
      return rejectWithValue(error);
    }
  },
);

export const verifyToken = createAsyncThunk(
  AuthActionEnum.AUTH_VERIFY_TOKEN,
  async (_, { rejectWithValue }) => {
    try {
      const baseUrl = SessionService.getBaseUrl();
      const response: { data: { permissions: {} } } = await createBookJaneRequest(
        API_GET_userPermissions({ baseUrl }),
      );
      return {
        permissions: response.data.permissions,
        token: sessionService.onTokenVerified(),
      };
    } catch (error) {
      sessionService.onUserLoggedOut();
      return rejectWithValue((error as AxiosError)?.response?.data);
    }
  },
);

export const userPermissions = createAsyncThunk(
  AuthActionEnum.AUTH_PERMISSIONS,
  async (_, { rejectWithValue }) => {
    try {
      const baseUrl = SessionService.getBaseUrl();
      const response: { data: { permissions: {} } } = await createBookJaneRequest(
        API_GET_userPermissions({ baseUrl }),
      );
      return {
        permissions: response.data.permissions,
        token: sessionService.onTokenVerified(),
      };
    } catch (error) {
      sessionService.onUserLoggedOut();
      return rejectWithValue((error as AxiosError)?.response?.data);
    }
  },
);

export const userRegistration = createAsyncThunk(
  AuthActionEnum.AUTH_REGISTRATION,
  async (args: SignUpParam, { rejectWithValue }) => {
    try {
      const {
        data: { jwt: token, user },
      }: {
        data: {
          jwt: BKJAuthTokenType;
          redirect_url: IRedirectUrl;
          user: B2BLoginResponseUserObject | AgencyLoginResponseUserObject;
        };
      } = await createBookJaneRequest(API_POST_signUp(args));
      const baseUrl = args.country_code === 'GB' ? UK_BASE_URL : DEFAULT_BASE_URL;
      sessionService.onUserLoggedIn({ token, user, baseUrl });
      const {
        data: { permissions },
      }: { data: { permissions: {} } } = await createBookJaneRequest(
        API_GET_userPermissions({ baseUrl }),
      );
      return { token, permissions, user };
    } catch (error: unknown) {
      sessionService.onUserLoggedOut(false);
      return rejectWithValue((error as AxiosError)?.response?.data);
    }
  },
);

export const userLogout = createAsyncThunk(AuthActionEnum.AUTH_LOGOUT, async () => {
  sessionService.onUserLoggedOut();
  return { permissions: null, token: null };
});

export const synthesizeBaseUrl = (redirect_url: IRedirectUrl) => `${redirect_url.full_route}`;
