import styled from 'styled-components';

export const JaneInfoName = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  line-height: 24px;
`;

export const JaneInfoAgencyName = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const ConfirmShiftAssigningDescription = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 14px;
  line-height: 21px;
`;

export const ConfirmShiftAssigningDescriptionBold = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
`;

export const ConfirmShiftAssigningCancellationFeeToolTip = styled.span`
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  text-decoration: underline;
`;
