import { BKJTextArea } from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import {
  InnerHeaderWrapper,
  MainTitle,
  ModalFooterButton,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/CreateShiftModal.styled';
import { ShiftTimeAndPosition } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/components/ShiftTimeAndPosition/ShiftTimeAndPosition';
import { TextAreaWrapper } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';
import { CreateShiftFormValuesType } from 'pages/CreateShiftsPage/CreateShiftsPage.types';

import { Fragment, useContext, useState } from 'react';
import { FieldNameType } from 'types/common.types';
import { AutoMargin } from 'components/AutoMargin';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { shiftModalEditViewFormItemVerticalMargin } from 'components/ShiftModal/src/views';
import {
  CustomFieldsFormLabel,
  MobileLabel,
  FormLabel,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { ShiftCustomFieldInput } from 'components/ShiftModal/src/components/ShiftCustomFieldInput/ShiftCustomFieldInput';
import { CustomFieldType } from 'requests/POST_createOrders.types';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';

export const DetailsView = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { saveAndCloseModal, createShift, communityCustomFields } =
    useContext(CreateShiftCardContext);
  const { values } = createShift;
  const [modalValues, setModalValues] = useState<{
    description: CreateShiftFormValuesType['description'];
    customFieldsFormValues: CreateShiftFormValuesType['customFieldsFormValues'];
  }>({
    description: values.description,
    customFieldsFormValues: { ...values.customFieldsFormValues },
  });
  const [dirtyFields, setDirtyFields] = useState<FieldNameType[]>([]);

  const isCustomFieldsEnabled =
    useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']) &&
    communityCustomFields.length > 0;

  const handleCustomFieldsChange: ChangeFunctionType = (e) => {
    const { target } = e;
    const {
      name,
      value,
    }: { name: keyof typeof modalValues.customFieldsFormValues; value: string } = target;
    if (name) {
      const { customFieldsFormValues, ..._modalValues } = modalValues;
      setDirtyFields([...Object.keys(_modalValues), ...Object.keys(customFieldsFormValues)]);
      customFieldsFormValues[name] = value;

      setModalValues({ customFieldsFormValues, ..._modalValues });
    }
  };

  const handleChange: ChangeFunctionType = (e) => {
    const { target } = e;
    const { name, value }: { name: keyof typeof modalValues; value: string } = target;
    if (name && Object.keys(modalValues).includes(name)) {
      const { customFieldsFormValues, ..._modalValues } = modalValues;
      setDirtyFields([...Object.keys(_modalValues), ...Object.keys(customFieldsFormValues)]);
      _modalValues[name as keyof typeof _modalValues] = value;

      setModalValues({ customFieldsFormValues, ..._modalValues });
    }
  };

  const isSaveDisabled = (): boolean => {
    const { customFieldsFormValues, ..._modalValues } = modalValues;
    const formValues = [...Object.keys(customFieldsFormValues), ...Object.keys(_modalValues)];
    return !formValues.some((name) => dirtyFields.includes(name));
  };

  const handleSaveAndClose = (): void => {
    const custom_fields: CustomFieldType[] = [];
    if (
      !!modalValues.customFieldsFormValues &&
      !!communityCustomFields &&
      communityCustomFields.length > 0
    ) {
      Object.entries(modalValues.customFieldsFormValues).forEach(([name, value]) => {
        const communityField = communityCustomFields.find((field) => field.name === name);
        const customFieldValue = Array.isArray(value) ? value[0] : value;
        if (customFieldValue && communityField) {
          custom_fields.push({
            custom_field_id: communityField.id,
            name,
            value: customFieldValue,
          });
        }
      });
    }
    const submitValues = {
      ...modalValues,
      custom_fields,
    };
    const events = Object.entries(submitValues).map(([name, value]) => {
      return {
        target: {
          name,
          value,
        },
      };
    });
    saveAndCloseModal(events);
  };

  return (
    <Flex width="100%" height="100%" flexDirection="column" justifyContent="space-between">
      <InnerHeaderWrapper>
        <MainTitle>{t('schedule:EDIT_DETAILS')}</MainTitle>
        <ShiftTimeAndPosition />
        <TextAreaWrapper>
          <BKJTextArea
            name="description"
            label={t('schedule:SHIFT_NOTES')}
            placeholder={t('schedule:TYPE_NOTES')}
            value={modalValues.description}
            onChange={handleChange}
            maxLength={2000}
          />
        </TextAreaWrapper>
        {isCustomFieldsEnabled && (
          <Fragment>
            <SectionDivider
              variant="Thin"
              margin={`${shiftModalEditViewFormItemVerticalMargin}px 0`}
            />
            <CustomFieldsFormLabel>{t('common:CUSTOM_FIELDS')}</CustomFieldsFormLabel>
            {communityCustomFields?.map((customField) => {
              return (
                <Flex
                  key={customField.id}
                  flexDirection="column"
                  margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}
                >
                  <FormLabel>
                    {customField.name}
                    {customField.mobile && (
                      <MobileLabel>&nbsp;({t('schedule:SHOWN_INTERNALLY_ON_MOBILE')})</MobileLabel>
                    )}
                  </FormLabel>
                  <ShiftCustomFieldInput
                    key={customField.id}
                    customField={customField}
                    value={
                      // FIXME: Consider setting up initialStateWithCustomFields in the form provider, see EditView.context
                      customField.fieldType === 'dropdown'
                        ? modalValues.customFieldsFormValues[customField.name] || []
                        : modalValues.customFieldsFormValues[customField.name]
                    }
                    onChange={handleCustomFieldsChange}
                  />
                </Flex>
              );
            })}
          </Fragment>
        )}
      </InnerHeaderWrapper>
      <BKJSideModalFooter>
        <AutoMargin />
        <ModalFooterButton
          onClick={handleSaveAndClose}
          variant={isMobile ? 'PurpleSolid' : 'GreenSolid'}
          disabled={isSaveDisabled()}
        >
          {t('auth:SAVE')}
        </ModalFooterButton>
      </BKJSideModalFooter>
    </Flex>
  );
};
