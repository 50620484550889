import styled from 'styled-components';

export const Rating = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
`;
