import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery, UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { nonPaginatedApolloClient } from 'providers/ApolloClients/NonPaginatedApolloClient';
import { QUERY_sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated } from 'queries';
import { FC, useContext, useEffect, useMemo } from 'react';
import { sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

interface IFetchAgenciesByAgencyIdsData {
  selectedAgencies: string[];
  availableJaneCount: number;
  hourlyRate: {
    min: number;
    max: number;
  };
}

const fetchSelectedAgenciesQueryConfig = ({
  orderId,
  selectedAgencyCommunities,
}: {
  orderId: string;
  selectedAgencyCommunities: number[];
}): UseGQLQueryConfig => ({
  key: 'SEND_TO_AGENCIES_SUMMARY_VIEW_FETCH_AGENCIES_QUERY',
  client: nonPaginatedApolloClient(),
  query: QUERY_sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated,
  responseTransformer: (
    res: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated,
  ): IFetchAgenciesByAgencyIdsData => {
    const filteredRates =
      res?.fetchAgencyCommunities.nodes
        .map((agencyCommunity) => agencyCommunity.agencyPositionDetails[0]?.openRate)
        .filter((rate) => !!rate)
        .map((rate) => Number.parseFloat(rate.replace('$', ''))) || [];

    return {
      selectedAgencies: res?.fetchAgencyCommunities.nodes.map(
        (agencyCommunity) => agencyCommunity.name,
      ),
      availableJaneCount: res?.fetchAgencyCommunities.nodes
        .map((agencyCommunity) => agencyCommunity.availableJanes.count)
        .reduce((accum, curr) => curr + accum, 0),
      hourlyRate: {
        min: Math.min(...filteredRates),
        max: Math.max(...filteredRates),
      },
    };
  },
  variables: {
    orderId,
    selectedAgencyCommunities,
  },
});

export const FetchAgenciesByAgencyIdsDataContext =
  createGQLDataContext<IFetchAgenciesByAgencyIdsData>({} as IFetchAgenciesByAgencyIdsData);

export const FetchAgenciesByAgencyIdsDataProvider: FC = ({ children }) => {
  const {
    data: {
      fetchOrder: { relayId: orderId },
    },
  } = useShiftModalDataContext();
  const {
    values: { selectedAgencies },
  } = useSendToAgenciesFormContext();

  const config = useMemo(
    () =>
      fetchSelectedAgenciesQueryConfig({
        orderId,
        selectedAgencyCommunities: selectedAgencies,
      }),
    [orderId, selectedAgencies],
  );

  const selectedAgenciesInfo = useGQLQuery(config);

  useEffect(() => {
    selectedAgenciesInfo.fetch();
  }, []); // eslint-disable-line

  const contextValue = useMemo(() => selectedAgenciesInfo, [selectedAgenciesInfo]);

  return (
    <FetchAgenciesByAgencyIdsDataContext.Provider value={contextValue}>
      {children}
    </FetchAgenciesByAgencyIdsDataContext.Provider>
  );
};

export const useFetchAgenciesByAgencyIdsDataContext =
  (): UseGQLQuery<IFetchAgenciesByAgencyIdsData> => useContext(FetchAgenciesByAgencyIdsDataContext);
