import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModal } from 'components/BKJSideModal';
import { FC, Fragment, useMemo, useState } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { BKJListItem } from '@bookjane2/bookjane-design-library';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import {
  shiftDistributionRulesGroupsModalRoutingService,
  useShiftDistributionRulesGroupsModalUIContext,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import { ShiftDistributionRulesGroupsComponentMap } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';
import { LocationByIdDataProvider } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/LocationById.data.context';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'hooks/useTranslation';

export const RenderOptions: FC<IBKJSelectRenderOptionsProps> = ({
  name,
  options,
  selected,
  onChange,
}) => {
  return (
    <Fragment>
      {options.map(({ key, label, value }) => {
        return (
          <BKJListItem
            name={name}
            selected={selected}
            key={key}
            label={label}
            value={value}
            onChange={onChange}
          />
        );
      })}
    </Fragment>
  );
};

export const ShiftDistributionRulesGroupsModalViewComponent: FC = () => {
  const { isOpen, shiftDistributionRulesGroupsActiveModalView } =
    useShiftDistributionRulesGroupsModalUIContext();

  const [isDiscardConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const { t } = useTranslation();

  const Component = useMemo(
    () => ShiftDistributionRulesGroupsComponentMap[shiftDistributionRulesGroupsActiveModalView],
    [shiftDistributionRulesGroupsActiveModalView],
  );

  const EditViews: string[] = ['EditRule', 'AddPositionRule'];

  const modalHeader = {
    GroupsRules: t('location_settings:SEND_SHIFTS_TO_GROUPS'),
    AddPositionRule: t('location_settings:EDIT_SEND_SHIFTS_TO_GROUPS'),
    ViewDetails: t('location_settings:SEND_SHIFTS_TO_GROUPS'),
    EditRule: t('location_settings:EDIT_SEND_SHIFTS_TO_GROUPS'),
  };

  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={() => {
        if (EditViews.includes(shiftDistributionRulesGroupsActiveModalView))
          setIsDeleteConfirmationOpen(true);
        else shiftDistributionRulesGroupsModalRoutingService.close();
      }}
      showBackButton={shiftDistributionRulesGroupsModalRoutingService.isBackButtonVisible()}
      onBack={shiftDistributionRulesGroupsModalRoutingService.goToPrevView}
      headerComponent={modalHeader[shiftDistributionRulesGroupsActiveModalView]}
    >
      <ErrorBoundary
        componentName="ShiftDistributionRulesGroupsModal"
        showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
      >
        <BKJCenteredDialog
          isOpen={isDiscardConfirmationOpen}
          variant="Danger"
          title={t('location_settings:DISCARD_CHANGES')}
          data-cy="discard-modal"
          text=""
          cancelLabel={t('auth:CANCEL')}
          actionLabel={t('location_settings:DISCARD')}
          onAction={() => {
            setIsDeleteConfirmationOpen(false);
            return shiftDistributionRulesGroupsModalRoutingService.close();
          }}
          onCancel={() => setIsDeleteConfirmationOpen(false)}
          onClose={() => setIsDeleteConfirmationOpen(false)}
        />
        {isOpen && !!locationId && (
          <LocationByIdDataProvider locationId={locationId}>
            <Component />
          </LocationByIdDataProvider>
        )}
      </ErrorBoundary>
    </BKJSideModal>
  );
};

export const ShiftDistributionRulesGroupsModalComponent: FC = () => {
  return <ShiftDistributionRulesGroupsModalViewComponent />;
};
