import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isRecurringFeatureEnabledSelector = createSelector(
  getUserPermissions,
  (permissions) => {
    if (permissions?.RECURRING?.access) return permissions.RECURRING.access.includes('read');
    else return false;
  },
);
