import { calendarDayStatusCardStyleMap } from 'components/ShiftCalendar/ShiftCalendar.constants';
import { IColorProps, IDayStatusesProps } from 'components/ShiftCalendar/ShiftCalendar.types';
import styled, { css } from 'styled-components';

export const CalendarRowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 10px;
`;

export const HeaderCalendarRowWrapper = styled(CalendarRowWrapper)`
  position: sticky;
  top: 0;
  background-color: white;
`;

export const ShiftsPageContentWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  height: calc(100vh - 196px);
  max-height: calc(100vh - 196px);
  min-height: calc(100vh - 196px);
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: overlay;
`;

export const CalendarHeaderDay = styled.div`
  padding: 8px;
  text-align: right;
  min-width: 140px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;

export const CalendarDayCellWrapper = styled.div<{ isOutsideOfMonth: boolean }>`
  opacity: ${({ isOutsideOfMonth }) => (isOutsideOfMonth ? '0.5' : '1')};
  height: 156px;
  padding: 8px;
  display: flex;
  min-width: 140px;
  flex-direction: column;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  border-top: 1px solid ${({ theme }) => theme.colors.Grey3};
  cursor: pointer;
  background-color: white;
  transition: background-color 100ms linear;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
`;
export const CalendarDayNumber = styled.div`
  display: flex;
  pointer-events: none;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.Grey7};
  margin-bottom: 6px;
`;

export const CalendarDayStatusCard = styled.div<IDayStatusesProps>`
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  width: 100%;
  margin-bottom: 4px;
  pointer-events: none;
  ${({ theme }) => theme.fonts.PoppinsText};
  padding: 0 12px;
  ${({ theme }) => theme.common.Ellipsis};
  ${({ orderStatus }) => calendarDayStatusCardStyleMap[orderStatus]}
`;

export const Circle = styled.div<IColorProps>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-left: 4px;
  ${({ color }) =>
    color &&
    css`
      background-color: ${({ theme }) => theme.colors[color]};
    `}
`;

export const CalendarDayStatusesCardCount = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
`;

export const CalendarDayStatusesCardStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const CalendarMoreOptionsWrapper = styled.div`
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  font-size: 12px;
  pointer-events: none;
`;
