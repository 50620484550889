import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJForm } from 'components/FormWrapper';
import { H3, P } from 'components/Typography';

import {
  TimeFrameLocationSettingsFormFormProvider,
  useTimeFrameLocationSettingsFormFormContext,
} from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.form.context';
import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useParams } from 'react-router-dom';
import { Flex } from 'components/Flex';
import {
  BKJFieldWrapper,
  FieldErrorWrapper,
  ShiftsName,
} from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPage.styled';
import { TimeFrameSelectInput } from 'components/TimeFrameSelectInput';
import { formatTimeWithAP } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.types';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';

const Component: FC = () => {
  const { t } = useTranslation();
  const {
    onSubmit,
    isSubmitting,
    fieldErrors,
    isDisabled,
    onChange,
    values,
    setButtonDisabled,
    buttonDisabled,
  } = useTimeFrameLocationSettingsFormFormContext();
  const formattedEveningTime = formatTimeWithAP(`${values?.eveningStartsAt}`);
  const formattedNightTime = formatTimeWithAP(`${values?.nightStartsAt}`);
  const formattedMorningTime = formatTimeWithAP(`${values?.morningStartsAt}`);

  const handleChange = useCallback(
    (e: ChangeEventType) => {
      setButtonDisabled(false);
      onChange(e);
    },
    [setButtonDisabled, onChange],
  );
  return (
    <Fragment>
      <BKJForm onSubmit={onSubmit} width="672px" maxWidth="672px" gap="22px">
        <H3>{t('location_settings:TIME_FRAME')}</H3>
        <P>{t('location_settings:TIME_FRAME_DETAILS')}</P>
        <BKJFieldWrapper>
          <Flex flexDirection="column" gap="60px" margin="60px 0 0">
            <ShiftsName>{t('location_settings:DAY_SHIFTS')}</ShiftsName>
            <ShiftsName>{t('location_settings:AFTERNOON_SHIFTS')}</ShiftsName>
            <ShiftsName>{t('location_settings:OVERNIGHT_SHIFTS')}</ShiftsName>
          </Flex>

          <Flex flexDirection="column" gap="15px" margin="20px 0 0">
            <Flex gap="16px">
              <Flex flexDirection="column" gap="10px">
                <P>{t('location_settings:START_OF_RANGE')}</P>
                <Flex flexDirection="column" width="135px">
                  <TimeFrameSelectInput
                    placeholder={'Time'}
                    onChange={(e) => handleChange(e)}
                    error={fieldErrors?.morningStartsAt}
                    name="morningStartsAt"
                    value={[`${values?.morningStartsAt}`]}
                    label={''}
                  />
                </Flex>
              </Flex>
              <Flex gap="15px" padding="0 0 11px">
                <Flex flexDirection="column" justifyContent="flex-end">
                  -
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between">
                  <P>{t('location_settings:END_OF_RANGE')}</P>
                  <span>{formattedEveningTime}</span>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap="16px">
              <Flex flexDirection="column" gap="10px">
                <P>{t('location_settings:START_OF_RANGE')}</P>
                <Flex flexDirection="column" width="135px">
                  <TimeFrameSelectInput
                    placeholder={'Time'}
                    onChange={(e) => handleChange(e)}
                    error={fieldErrors?.eveningStartsAt}
                    name="eveningStartsAt"
                    value={[`${values?.eveningStartsAt}`]}
                    label={''}
                  />
                </Flex>
              </Flex>
              <Flex gap="15px" padding="0 0 11px">
                <Flex flexDirection="column" justifyContent="flex-end">
                  -
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between">
                  <P>{t('location_settings:END_OF_RANGE')}</P>
                  <span>{formattedNightTime}</span>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap="16px">
              <Flex flexDirection="column" gap="10px">
                <P>{t('location_settings:START_OF_RANGE')}</P>
                <Flex flexDirection="column" width="135px">
                  <TimeFrameSelectInput
                    placeholder={'Time'}
                    onChange={(e) => handleChange(e)}
                    error={fieldErrors?.nightStartsAt}
                    name="nightStartsAt"
                    value={[`${values?.nightStartsAt}`]}
                    label={''}
                    allowedAutoPlacements={['top']}
                  />
                </Flex>
              </Flex>
              <Flex gap="15px" padding="0 0 11px">
                <Flex flexDirection="column" justifyContent="flex-end">
                  -
                </Flex>
                <Flex flexDirection="column" justifyContent="space-between">
                  <P>{t('location_settings:END_OF_RANGE')}</P>
                  <span>{formattedMorningTime}</span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </BKJFieldWrapper>
        {(fieldErrors?.morningStartsAt ||
          fieldErrors?.eveningStartsAt ||
          fieldErrors?.nightStartsAt) && (
          <FieldErrorWrapper>{t('location_settings:OVERLAPPING_RANGE')}</FieldErrorWrapper>
        )}
        <BKJButton
          type="submit"
          variant="PurpleSolid"
          width="192px"
          disabled={isDisabled || buttonDisabled}
          isLoading={isSubmitting}
          data-cy="btn-save"
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJForm>
    </Fragment>
  );
};

export const TimeFrameLocationSettingsForm: FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  return (
    <TimeFrameLocationSettingsFormFormProvider key={locationId}>
      <Component />
    </TimeFrameLocationSettingsFormFormProvider>
  );
};
