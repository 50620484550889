/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveId } from 'pages/ADPProfilePage/AdpSideModals/AdpSideModals.constants';
import { AdpScheduleResult, fetchLogDetails, syncADPEmployees } from 'services/ADP/api/profile';
import { fetchCredentials, fetchEmployerInfo, fetchSubscriptionStatus } from 'services/ADP/api/sso';
import {
  fetchADPWorkers,
  fetchADPWorkersCount,
  fetchJanes,
  fetchLocations,
  postSyncEmployees,
} from 'services/ADP/api/sync';
import {
  AdpEmployeeAccountDetail,
  AdpEmployer,
  ADPfetchLogDetailsArgs,
  ADPpostSyncEmployeesArgs,
  AdpScheduleLog,
  ADPsubscriptionStatusResponse,
  AdpTeam,
  CredentialsInfo,
  DisplayErrorType,
  Employee,
  FetchADPEmployerInfoArgs,
  FetchError,
  FetchWorkersArgs,
  FetchWorkersCountArgs,
  FetchWorkersResponse,
  GetADPEmployeesArgs,
  GetADPLocationsResponse,
  Location,
  PaginationInfo,
  PostADPEmployeeData,
  PostADPEmployeesTypes,
  PostEmployeeResponse,
  SelectedEmployee,
  TeamInfo,
} from 'store/reducers/adpSlice/index.constants';

interface AdpGlobalState {
  userInfo: AdpEmployer;
  selectedAccount: AdpScheduleLog;
  janes: Employee[];
  getADPMeta: PaginationInfo;
  loadingADP: boolean;
  isWorkersPresent: boolean;
  getADPTeam: AdpTeam[];
  ADPWorkersCount: number;
  updateFlag: boolean;
  uploadHistory: AdpScheduleLog[];
  scheduleLogs: AdpEmployeeAccountDetail[];
  isDashboardDisplay: boolean;
  logsMeta: PaginationInfo;
  UpdatedJane: ActiveId[];
  selectedEmployee: SelectedEmployee;
  getTeam: TeamInfo[];
  credentials: CredentialsInfo;
  isUserAuthenticate: boolean;
  isUnsubscribe: boolean;
  isConsent: boolean;
  isIntegrateADP: boolean;
  showMatchData: boolean;
  configuredADP: boolean;
  subscriptionStatus: boolean;
  getLocationSubmit: boolean;
  showLocations: boolean;
  showUpdate: boolean;
  afterAddNewLocation: boolean;
  consentRedirectURL: string;
  displayError: DisplayErrorType;
  updatedIndex: number;
  UrlParams: any;
  authCode: string;
  locations: Location[];
  selectedLocations: number[];
  adpLocations: number[];
  getMappedData: PostADPEmployeeData[];
}

const initialState: AdpGlobalState = {
  userInfo: {
    id: 0,
    organization_id: '',
    associate_id: '',
    sub: '',
    given_name: '',
    family_name: '',
    name: '',
    email: '',
    employer_id: '',
    employer_type: '',
    created_at: '',
    updated_at: '',
    subscribed: false,
  },
  credentials: {
    partner_id: '',
    country: '',
    subscription_url: '',
    consent_url: '',
  },
  getADPMeta: {
    current_page: 0,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  },
  loadingADP: false,
  getADPTeam: [],
  isWorkersPresent: false,
  ADPWorkersCount: 0,
  updateFlag: false,
  uploadHistory: [],
  isDashboardDisplay: true,
  selectedAccount: {
    id: 0,
    account_id: '',
    created_at: '',
    processing_status: '',
    error_message: null,
    request_status: '',
    updated_at: '',
    location_name: '',
    retried_at: null,
  },
  scheduleLogs: [],
  logsMeta: {
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_count: 0,
    total_pages: 1,
  },
  UpdatedJane: [],
  selectedEmployee: {
    jane: {
      id: '',
      name: '',
      email: '',
      hours_clocked: 0,
    },
    index: 0,
  },
  getTeam: [],
  isUserAuthenticate: false,
  isUnsubscribe: false,
  isConsent: false,
  isIntegrateADP: false,
  consentRedirectURL: '',
  displayError: {
    show: false,
    message: '',
  },
  updatedIndex: -1,
  showMatchData: true,
  configuredADP: true,
  afterAddNewLocation: true,
  subscriptionStatus: false,
  getLocationSubmit: false,
  showLocations: false,
  UrlParams: {},
  authCode: '',
  locations: [],
  selectedLocations: [],
  adpLocations: [],
  getMappedData: [],
  showUpdate: false,
  // For Profile
  janes: [],
};
export const fetchWorkers = createAsyncThunk<
  FetchWorkersResponse,
  FetchWorkersArgs,
  {
    rejectValue: FetchError;
  }
>('adp/fetchWorkers', async (arg, thunkAPI) => {
  const { page, per_page, matched, userInfo, ADPSearch, direction, sortBy } = arg;

  try {
    // const state = thunkAPI.getState();
    const response = await fetchADPWorkers({
      page,
      per_page,
      matched,
      userInfo,
      ADPSearch,
      direction,
      sortBy,
    });

    return response as FetchWorkersResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});

export const fetchWorkersCount = createAsyncThunk<
  FetchWorkersResponse,
  FetchWorkersCountArgs,
  {
    rejectValue: FetchError;
  }
>('adp/fetchWorkersCount', async (arg, thunkAPI) => {
  const { userInfo } = arg;
  try {
    // const state = thunkAPI.getState();
    const response = await fetchADPWorkersCount(userInfo);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});

export const postADPEmployees = createAsyncThunk<
  FetchWorkersResponse,
  PostADPEmployeesTypes,
  {
    rejectValue: FetchError;
  }
>('adp/postADPEmployees', async (arg, thunkAPI) => {
  const { data, userInfo } = arg;
  try {
    const response = await postSyncEmployees(data, userInfo);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const ADPfetchLogDetails = createAsyncThunk<
  FetchWorkersResponse,
  ADPfetchLogDetailsArgs,
  {
    rejectValue: FetchError;
  }
>('adp/ADPfetchLogDetails', async (arg, thunkAPI) => {
  const { userInfo, selectedAccount, page, sortBy, direction } = arg;
  try {
    // const state = thunkAPI.getState();
    const response = await fetchLogDetails(userInfo, selectedAccount, page, sortBy, direction);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});

export const ADPpostSyncEmployees = createAsyncThunk<
  PostEmployeeResponse,
  ADPpostSyncEmployeesArgs,
  {
    rejectValue: FetchError;
  }
>('adp/ADPpostSyncEmployees', async (arg, thunkAPI) => {
  const {
    data,
    userInfo,
    // flag
  } = arg;
  try {
    // const state = thunkAPI.getState();
    const response = await postSyncEmployees(data, userInfo);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});

const set_Matched_Array = (getTeam: TeamInfo[], selectedEmployee: SelectedEmployee) => {
  return getTeam.map((item) =>
    item.id === selectedEmployee.jane.id ? { ...item, status: 'update' } : item,
  );
};
export const getADPCredentials = createAsyncThunk<
  FetchWorkersResponse,
  undefined,
  {
    rejectValue: FetchError;
  }
>('adp/getADPCredentials', async (_, thunkAPI) => {
  try {
    const response = await fetchCredentials();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const fetchADPEmployerInfo = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  FetchADPEmployerInfoArgs,
  {
    rejectValue: FetchError;
  }
>('adp/fetchADPEmployerInfo', async (arg, thunkAPI) => {
  const { credentials } = arg;
  try {
    const response = await fetchEmployerInfo(credentials);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const ADPsubscriptionStatus = createAsyncThunk<
  ADPsubscriptionStatusResponse,
  undefined,
  {
    rejectValue: FetchError;
  }
>('adp/ADPsubscriptionStatus', async (_, thunkAPI) => {
  try {
    // const state = thunkAPI.getState();
    const response = await fetchSubscriptionStatus();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const getADPLocations = createAsyncThunk<
  GetADPLocationsResponse,
  undefined,
  {
    rejectValue: FetchError;
  }
>('adp/getADPLocations', async (_, thunkAPI) => {
  try {
    const response = await fetchLocations();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const getADPEmployees = createAsyncThunk<
  FetchWorkersResponse,
  GetADPEmployeesArgs,
  {
    rejectValue: FetchError;
  }
>('adp/getADPEmployees', async (arg, thunkAPI) => {
  const { selectedLocations, janesDirection, janesSortBy } = arg;
  try {
    const response = await fetchJanes(selectedLocations, janesDirection, janesSortBy);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const syncADPEmployee = createAsyncThunk<
  AdpScheduleResult,
  FetchWorkersCountArgs,
  {
    rejectValue: FetchError;
  }
>('adp/syncADPEmployee', async (arg, thunkAPI) => {
  const { userInfo } = arg;
  try {
    const response = await syncADPEmployees(userInfo);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: (error as Error).message });
  }
});
export const adpSlice = createSlice({
  name: 'adp',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<CredentialsInfo>) => {
      state.credentials = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<AdpEmployer>) => {
      state.userInfo = action.payload;
    },
    setADPMeta: (state, action: PayloadAction<PaginationInfo>) => {
      state.getADPMeta = action.payload;
    },
    setLoadingADP: (state, action: PayloadAction<boolean>) => {
      state.loadingADP = action.payload;
    },
    setADPTeam: (state, action: PayloadAction<AdpTeam[]>) => {
      state.getADPTeam = action.payload;
    },
    setADPWorkersCount: (state, action: PayloadAction<number>) => {
      state.ADPWorkersCount = action.payload;
    },
    setUpdateFlag: (state, action: PayloadAction<boolean>) => {
      state.updateFlag = action.payload;
    },
    setUploadHistory: (state, action: PayloadAction<AdpScheduleLog[]>) => {
      state.uploadHistory = action.payload;
    },
    setIsDashboardDisplay: (state, action: PayloadAction<boolean>) => {
      state.isDashboardDisplay = action.payload;
    },
    setSelectedAccount: (state, action: PayloadAction<AdpScheduleLog>) => {
      state.selectedAccount = action.payload;
    },
    setScheduleLogs: (state, action: PayloadAction<AdpEmployeeAccountDetail[]>) => {
      state.scheduleLogs = action.payload;
    },
    setLogsMeta: (state, action: PayloadAction<PaginationInfo>) => {
      state.logsMeta = action.payload;
    },
    setUpdatedJane: (state, action: PayloadAction<ActiveId[]>) => {
      state.UpdatedJane = action.payload;
    },
    setSelectedEmployee: (state, action: PayloadAction<SelectedEmployee>) => {
      state.selectedEmployee = action.payload;
    },
    setTeam: (state, action: PayloadAction<TeamInfo[]>) => {
      state.getTeam = action.payload;
    },
    setUserAuthentication: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthenticate = action.payload;
    },
    setUnsubscribe: (state, action: PayloadAction<boolean>) => {
      state.isUnsubscribe = action.payload;
    },
    setADPConsent: (state, action: PayloadAction<boolean>) => {
      state.isConsent = action.payload;
    },
    setConsentRedirectURL: (state, action: PayloadAction<string>) => {
      state.consentRedirectURL = action.payload;
    },
    setADPIntegration: (state, action: PayloadAction<boolean>) => {
      state.isIntegrateADP = action.payload;
    },
    setDisplayError: (state, action: PayloadAction<DisplayErrorType>) => {
      state.displayError = action.payload;
    },
    setUpdatedIndex: (state, action: PayloadAction<number>) => {
      state.updatedIndex = action.payload;
    },
    setShowMatchData: (state, action: PayloadAction<boolean>) => {
      state.showMatchData = action.payload;
    },
    setConfiguredADP: (state, action: PayloadAction<boolean>) => {
      state.configuredADP = action.payload;
    },
    setSubscriptionStatus: (state, action: PayloadAction<boolean>) => {
      state.subscriptionStatus = action.payload;
    },
    setUrlParams: (state, action: PayloadAction<any>) => {
      state.UrlParams = action.payload;
    },
    setAuthCode: (state, action: PayloadAction<string>) => {
      state.authCode = action.payload;
    },
    setLocationSubmit: (state, action: PayloadAction<boolean>) => {
      state.getLocationSubmit = action.payload;
    },
    setShowLocations: (state, action: PayloadAction<boolean>) => {
      state.showLocations = action.payload;
    },
    setLocations: (state, action: PayloadAction<Location[]>) => {
      state.locations = action.payload;
    },
    setSelectedLocations: (state, action: PayloadAction<number[]>) => {
      state.selectedLocations = action.payload;
    },
    setAfterAddNewLocation: (state, action: PayloadAction<boolean>) => {
      state.afterAddNewLocation = action.payload;
    },
    setMappedData: (state, action: PayloadAction<PostADPEmployeeData[]>) => {
      state.getMappedData = action.payload;
    },
    setShowUpdate: (state, action: PayloadAction<boolean>) => {
      state.showUpdate = action.payload;
    },
    setADPLocations: (state, action: PayloadAction<number[]>) => {
      state.adpLocations = action.payload;
    },
    // For Profile
    setJanes: (state, action: PayloadAction<Employee[]>) => {
      state.janes = action.payload;
    },
    setIsWorkersPresent: (state, action: PayloadAction<boolean>) => {
      state.isWorkersPresent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkers.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(fetchWorkers.fulfilled, (state, action) => {
        try {
          const { status, adp_employees, meta, message } = action.payload;
          if (!status && !message) {
            if (meta.prev_page) {
              state.getADPTeam = [...state.getADPTeam, ...adp_employees];
            } else {
              state.getADPTeam = adp_employees;
            }
            state.getADPMeta = meta;
            state.isWorkersPresent = true;
          } else if (message) {
            window.j360.toastService.error(message);
          }
        } catch (e) {
          console.error(e);
          throw e;
        }
        state.loadingADP = false;
      })
      .addCase(fetchWorkers.rejected, (state) => {
        state.loadingADP = false;
      })
      .addCase(fetchWorkersCount.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(fetchWorkersCount.fulfilled, (state, action) => {
        const { meta, message } = action.payload;
        if (meta) {
          state.ADPWorkersCount = meta.total_count;
        } else if (message) {
          window.j360.toastService.error(message);
        }
        state.loadingADP = false;
      })
      .addCase(fetchWorkersCount.rejected, (state) => {
        state.loadingADP = false;
      })
      .addCase(postADPEmployees.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(postADPEmployees.fulfilled, (state, action) => {
        const { message } = action.payload;
        if (window?.j360?.toastService) {
          window.j360.toastService.success(message);
        }
        state.updateFlag = false;
        state.loadingADP = false;
      })
      .addCase(
        postADPEmployees.rejected,
        (state, action: PayloadAction<FetchError | undefined>) => {
          state.loadingADP = false;
          state.updateFlag = false;
          const message = action.payload?.message;
          if (window?.j360?.toastService) {
            window.j360.toastService.error(message!);
          }
        },
      )
      .addCase(ADPfetchLogDetails.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(ADPfetchLogDetails.fulfilled, (state, action) => {
        const { adp_employee_timesheet_logs, meta, message } = action.payload;
        if (message) {
          window.j360.toastService.error(message);
        }
        state.scheduleLogs = adp_employee_timesheet_logs;
        state.logsMeta = meta;
        state.updateFlag = false;
        state.loadingADP = false;
      })
      .addCase(ADPfetchLogDetails.rejected, (state) => {
        state.loadingADP = false;
      })
      .addCase(ADPpostSyncEmployees.pending, () => {})
      .addCase(ADPpostSyncEmployees.fulfilled, (state, action) => {
        const { arg } = action.meta;
        state.updateFlag = false;
        if (arg.flag && state.selectedEmployee.jane) {
          state.getTeam = set_Matched_Array(state.getTeam, state.selectedEmployee);
        } else if (action.payload.message) {
          window.j360.toastService.error(action.payload.message);
        }
      })
      .addCase(getADPCredentials.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(getADPCredentials.fulfilled, (state, action) => {
        try {
          const { status, credential, message } = action.payload;
          if (status === 200) {
            // state.configuredADP = false;
            state.credentials = credential;
          } else {
            state.displayError = { show: true, message };
          }
          // state.loadingADP = false;
        } catch (e) {
          throw e;
        }
      })
      .addCase(getADPCredentials.rejected, (state) => {
        state.loadingADP = false;
      })
      .addCase(fetchADPEmployerInfo.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(fetchADPEmployerInfo.fulfilled, (state, action) => {
        try {
          const { result, get } = action.payload;
          if (result && result.adp_employer) {
            state.userInfo = result.adp_employer;
            state.isUserAuthenticate = true;
          } else if (result.redirect_url && result.redirect_url !== null) {
            state.isUnsubscribe = false;
            state.isConsent = true;
            state.consentRedirectURL = result.redirect_url;
          } else if (result.results || get.status === 500) {
            state.isIntegrateADP = false;
            state.isUnsubscribe = true;
          }
          state.loadingADP = false;
        } catch (e) {
          throw e;
        }
      })
      .addCase(fetchADPEmployerInfo.rejected, (state) => {
        state.loadingADP = false;
      })
      .addCase(ADPsubscriptionStatus.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(ADPsubscriptionStatus.fulfilled, (state, action) => {
        const { result } = action.payload;
        if (result.id) {
          state.subscriptionStatus = result.subscription_status;
        } else if (result.message) {
          window.j360.toastService.error(result.message);
        }
        state.loadingADP = false;
        state.configuredADP = false;
      })
      .addCase(ADPsubscriptionStatus.rejected, (state) => {
        state.loadingADP = false;
        state.configuredADP = false;
      })
      .addCase(getADPLocations.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(getADPLocations.fulfilled, (state, action) => {
        try {
          const { result, get } = action.payload;
          if (get.status === 200) {
            state.locations = result.locations;
            state.showLocations = true;
          } else if (get.status === 500) {
            state.isIntegrateADP = false;
            state.isUnsubscribe = true;
          }
          state.loadingADP = false;
        } catch (e) {
          throw e;
        }
      })
      .addCase(getADPLocations.rejected, (state) => {
        state.loadingADP = false;
        state.configuredADP = false;
      })
      .addCase(getADPEmployees.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(getADPEmployees.fulfilled, (state, action) => {
        try {
          const { janes, message } = action.payload;
          if (message) {
            window.j360.toastService.error(message);
          } else {
            state.getTeam = janes;
          }
          state.loadingADP = false;
        } catch (e) {
          throw e;
        }
      })
      .addCase(getADPEmployees.rejected, (state) => {
        state.loadingADP = false;
      })
      .addCase(syncADPEmployee.pending, (state) => {
        state.loadingADP = true;
      })
      .addCase(syncADPEmployee.fulfilled, (state, action) => {
        try {
          // if(status === 200){}
          state.loadingADP = false;
        } catch (e) {
          throw e;
        }
      })
      .addCase(syncADPEmployee.rejected, (state) => {
        state.loadingADP = false;
      });
  },
});

export const {
  setUserInfo,
  setJanes,
  setADPMeta,
  setLoadingADP,
  setADPTeam,
  setIsWorkersPresent,
  setADPWorkersCount,
  setUpdateFlag,
  setUploadHistory,
  setIsDashboardDisplay,
  setSelectedAccount,
  setSelectedEmployee,
  setUpdatedJane,
  setUpdatedIndex,
  setShowMatchData,
  setSubscriptionStatus,
  setConfiguredADP,
  setUnsubscribe,
  setUrlParams,
  setAuthCode,
  setADPIntegration,
  setShowLocations,
  setSelectedLocations,
  setTeam,
  setUserAuthentication,
  setLocationSubmit,
  setAfterAddNewLocation,
  setShowUpdate,
  setMappedData,
  setDisplayError,
  setADPLocations,
} = adpSlice.actions;

export const adpReducer = adpSlice.reducer;

export const employeeSelector = (state: { adp: AdpGlobalState }): Employee[] => state.adp.janes;
export const adpTeamSelector = (state: { adp: AdpGlobalState }): AdpTeam[] => state.adp.getADPTeam;
export const userInfoSelector = (state: { adp: AdpGlobalState }): AdpEmployer => state.adp.userInfo;
export const selectedEmployeeSelector = (state: { adp: AdpGlobalState }): SelectedEmployee =>
  state.adp.selectedEmployee;
export const credentialsInfoSelector = (state: { adp: AdpGlobalState }): CredentialsInfo =>
  state.adp.credentials;
export const workersCountSelector = (state: { adp: AdpGlobalState }): number =>
  state.adp.ADPWorkersCount;
export const adpMetaSelector = (state: { adp: AdpGlobalState }): PaginationInfo =>
  state.adp.getADPMeta;
export const uploadHistorySelector = (state: { adp: AdpGlobalState }): AdpScheduleLog[] =>
  state.adp.uploadHistory;
export const isDashboardDisplaySelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.isDashboardDisplay;
export const isIntegrateADPSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.isIntegrateADP;
export const isUnsubscribeSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.isUnsubscribe;
export const loadingADPSelector = (state: { adp: AdpGlobalState }): boolean => state.adp.loadingADP;
export const getLocationSubmitSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.getLocationSubmit;
export const isConsentSelector = (state: { adp: AdpGlobalState }): boolean => state.adp.isConsent;
export const isWorkersPresentSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.isWorkersPresent;
export const isUserAuthenticateSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.isUserAuthenticate;
export const showLocationsSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.showLocations;
export const afterAddNewLocationSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.afterAddNewLocation;
export const consentRedirectURLSelector = (state: { adp: AdpGlobalState }): string =>
  state.adp.consentRedirectURL;
export const authCodeSelector = (state: { adp: AdpGlobalState }): string => state.adp.authCode;
export const isSubscriptionStatusSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.subscriptionStatus;
export const selectedAccountSelector = (state: { adp: AdpGlobalState }): AdpScheduleLog =>
  state.adp.selectedAccount;
export const logsMetaSelector = (state: { adp: AdpGlobalState }): PaginationInfo =>
  state.adp.logsMeta;
export const updateFlagSelector = (state: { adp: AdpGlobalState }): boolean => state.adp.updateFlag;
export const showMatchDataSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.showMatchData;
export const showUpdateSelector = (state: { adp: AdpGlobalState }): boolean => state.adp.showUpdate;
export const configuredADPSelector = (state: { adp: AdpGlobalState }): boolean =>
  state.adp.configuredADP;
export const updatedJaneSelector = (state: { adp: AdpGlobalState }): ActiveId[] =>
  state.adp.UpdatedJane;
export const scheduleLogsSelector = (state: { adp: AdpGlobalState }): AdpEmployeeAccountDetail[] =>
  state.adp.scheduleLogs;
export const locationsSelector = (state: { adp: AdpGlobalState }): Location[] =>
  state.adp.locations;
export const selectedLocationsSelector = (state: { adp: AdpGlobalState }): number[] =>
  state.adp.selectedLocations;
export const adpLocationsSelector = (state: { adp: AdpGlobalState }): number[] =>
  state.adp.adpLocations;
export const getMappedDataSelector = (state: { adp: AdpGlobalState }): PostADPEmployeeData[] =>
  state.adp.getMappedData;
export const getTeamSelector = (state: { adp: AdpGlobalState }): TeamInfo[] => state.adp.getTeam;
export const urlParamsSelector = (state: { adp: AdpGlobalState }): any => state.adp.UrlParams;
export const displayErrorSelector = (state: { adp: AdpGlobalState }): DisplayErrorType =>
  state.adp.displayError;
