import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isEnhancedDynamicGroupsFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    if (userPermissions?.ENHANCED_DYNAMIC_GROUPS?.access)
      return (
        userPermissions.ENHANCED_DYNAMIC_GROUPS.access.includes('read') &&
        !!userPermissions.ENHANCED_DYNAMIC_GROUPS.communities &&
        !!userPermissions.ENHANCED_DYNAMIC_GROUPS.communities[communityId] &&
        !!userPermissions.ENHANCED_DYNAMIC_GROUPS.communities[communityId].includes('read')
      );
    else return false;
  });
