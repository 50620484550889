import { QuerySwitch } from 'components/QuerySwitch';
import { useFeatureSelector } from 'guards/useFeatureSelector';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { API_GET_customerAvailability } from 'requests/GET_customerAvailability';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { createLegacyDataContext } from 'utils/createDataContext';

export const AvailabilityLocationSettingsPageDataContext = createLegacyDataContext({});

interface IData {
  start_at: Date;
  timespan: number;
  deadline: number;
  non_mobile: boolean;
  critical?: boolean;
  high_priority?: boolean;
  vacation_loa?: boolean;
  shift_exchange?: boolean;
  waiting_hours?: number;
}

const getDefaultData = (isTieringEnabled: boolean): IData => {
  let defaultValues: IData = {
    start_at: new Date(),
    timespan: 1,
    deadline: 1,
    non_mobile: true,
  };

  if (isTieringEnabled)
    defaultValues = {
      ...defaultValues,
      critical: true,
      high_priority: true,
      vacation_loa: true,
      shift_exchange: true,
    };
  else
    defaultValues = {
      ...defaultValues,
      waiting_hours: 1,
    };

  return defaultValues;
};

interface IAvailabilityState {
  isAvailabilityEnabled: boolean;
  form: IData;
}

const getInitialState = (isTieringEnabled: boolean): IAvailabilityState => ({
  isAvailabilityEnabled: false,
  form: getDefaultData(isTieringEnabled),
});

function responseTransformer(
  isTieringEnabled: boolean = false,
  community_availability: {
    start_at: Date;
    timespan: number;
    deadline: number;
    non_mobile: boolean;
    extra_settings: Partial<{
      critical?: boolean;
      high_priority?: boolean;
      vacation_loa?: boolean;
      shift_exchange?: boolean;
      waiting_hours?: number;
    }>;
  },
): IAvailabilityState {
  const data = {} as IData;

  [
    'timespan' as 'timespan',
    'non_mobile' as 'non_mobile',
    'deadline' as 'deadline',
    'start_at' as 'start_at',
  ].forEach((key) => {
    switch (key) {
      case 'start_at':
        data[key] = new Date(community_availability[key]);
        break;
      default:
        data[key] = community_availability[key] as never;
        break;
    }
  });

  const extra_settings = {} as IData;

  if (isTieringEnabled)
    [
      'critical' as 'critical',
      'high_priority' as 'high_priority',
      'shift_exchange' as 'shift_exchange',
      'vacation_loa' as 'vacation_loa',
    ].forEach((key) => {
      if (typeof community_availability.extra_settings[key] === 'boolean') {
        extra_settings[key] = community_availability.extra_settings[key];
      } else {
        extra_settings[key] = true;
      }
    });
  else
    ['waiting_hours' as 'waiting_hours'].forEach((key) => {
      extra_settings[key] = community_availability.extra_settings[key] || 1;
    });

  const payload = {
    isAvailabilityEnabled: true,
    form: {
      ...data,
      ...extra_settings,
    },
  };
  return payload;
}

export const AvailabilityLocationSettingsPageDataProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const { locationId } = useParams<{ locationId: string }>();

  const isTieringEnabled = useFeatureSelector('FEATURE_TYPE_TIERING');
  const requestOptions = API_GET_customerAvailability(locationId);

  const [data, setData] = useState<IAvailabilityState>(getInitialState(isTieringEnabled));
  const request = useSelector((state) => apiDataSelector(state, requestOptions));
  useEffect(() => {
    apiRequest(requestOptions, {
      onSuccess: async (response) => {
        setData({
          ...responseTransformer(isTieringEnabled, response.data.community_availability),
          isAvailabilityEnabled: true,
        });
      },
      onError: async (err) => {
        setData({
          ...data,
          isAvailabilityEnabled: false,
        });
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AvailabilityLocationSettingsPageDataContext.Provider value={{ ...request, data }}>
      <QuerySwitch
        component={children}
        context={API_GET_customerAvailability(locationId)}
        overrides={['NO_ERROR']}
      />
    </AvailabilityLocationSettingsPageDataContext.Provider>
  );
};

export const useAvailabilityLocationSettingsPageDataContext = () =>
  useContext<{ data: IAvailabilityState }>(AvailabilityLocationSettingsPageDataContext);
