import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { INVITE_SECONDARY_USERS } from 'constants/endpoints';
import { AdministratorSettingsInviteAdminFormFields } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';

export const API_POST_inviteSecondaryUsers = (
  params: AdministratorSettingsInviteAdminFormFields,
): ApiRequestConfigType => {
  const communities = params.communities.filter((item) => item.isIncluded);
  const url = INVITE_SECONDARY_USERS;
  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url,
    body: {
      secondary_user: {
        communities: communities,
        email: params.email,
        first_name: params.first_name,
        last_name: params.last_name,
        phone: params.phone,
      },
    },
  };
};
