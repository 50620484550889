import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isShiftSwapFeatureEnabledSelector = createSelector(
  getUserPermissions,
  (userPermissions) => {
    if (!userPermissions?.SHIFT_SWAP) return false;
    return userPermissions?.SHIFT_SWAP?.access.includes('read');
  },
);
