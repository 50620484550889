import { BKJButton, BKJIcon, Flex } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

export const HeaderSection = styled.div`
  display: block;
  padding-inline: 20px;
`;

export const Heading = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  ${({ theme }) => theme.typography.H3};
  color: ${({ theme }) => theme.colors.AccentPurple};
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    ${({ theme }) => theme.typography.H4};
    display: block;
    margin-bottom: 20px;
    padding: 0 20px;
  }
`;

export const Caption = styled.span`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.Body}
  ${({ theme }) => theme.fonts.PoppinsTextMedium};

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-left: 0;
    font-size: 14px;
    display: block;
  }
`;

export const IconWithMarginRight = styled(BKJIcon)`
  margin-right: 10px;
`;

export const CreateShiftCardsWrapper = styled.div``;

export const ConfirmButtonWrapper = styled(Flex)`
  flex-direction: row-reverse;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    box-shadow: 0px -4px 4px 0px #0000001a;
    padding: 0 20px;
  }
`;

export const MobileAddAnOtherShift = styled(BKJButton)`
  margin: 20px;
`;

export const ConfirmBookingButton = styled(BKJButton)<{ isSubmitDisabled?: boolean }>`
  margin-top: 40px;
  width: 192px;
  margin-left: auto;

  ${({ isSubmitDisabled }) =>
    isSubmitDisabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.colors.Grey3};
      color: ${({ theme }) => theme.colors.Grey5};
      fill: ${({ theme }) => theme.colors.Grey5};
      &:hover {
        background-color: #d0d7df;
        cursor: not-allowed;
      }
      &:focus {
        background-color: #d0d7df;
        cursor: not-allowed;
        border: 0px;
      }
    `};
`;

export const MobileBookingButton = styled(BKJButton)<{ isSubmitDisabled?: boolean }>`
  width: 100%;
  margin: 12px 0;
  padding: 12px 8px;
  min-height: 48px;

  ${({ isSubmitDisabled }) =>
    isSubmitDisabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.colors.Grey3};
      color: ${({ theme }) => theme.colors.Grey5};
      fill: ${({ theme }) => theme.colors.Grey5};
      &:hover {
        background-color: #d0d7df;
        cursor: not-allowed;
      }
      &:focus {
        background-color: #d0d7df;
        cursor: not-allowed;
        border: 0px;
      }
    `};
`;
