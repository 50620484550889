import { SocketService } from 'services/SocketService';
import { toastService } from 'services/toastService';

export const pusherService = new SocketService('Pusher');

pusherService.addEventListener('ADP_SYNC_EMPLOYEES', (data) => {
  const { action, message } = data;
  switch (action) {
    case 'NOTIFICATION_SUCCESS':
      toastService.success(message);
      break;
    case 'NOTIFICATION_FAILED':
      toastService.error(message);
      break;
  }
});
