import { B2B_UPDATE_TIME_OFF } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_PUT_updateTimeOffParamsType } from 'requests/PUT_updateTimeOff.types';

export const API_PUT_updateTimeOff = (
  requestBody: API_PUT_updateTimeOffParamsType,
  timeOffId: number,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: B2B_UPDATE_TIME_OFF(timeOffId),
    method: METHOD_PUT,
    body: requestBody,
  };
};
