import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { fetchAgencyTeamMembersCountQueryConfig } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/context.constants';
import { useGQLQuery, useURLParameters } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import {
  assignToAgencyTeamMemberFetchAgencyJanesCount,
  assignToAgencyTeamMemberFetchAgencyJanesCountVariables,
} from 'types/graphql-types';
import { castStringToInteger } from 'utils';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchAgencyTeamMembersCountDataContext = createGQLDataContext<
  UseGQLQuery<{ totalCount: number }>
>({} as UseGQLQuery<{ totalCount: number }>);

export const FetchAgencyTeamMembersCountDataProvider: FC = ({ children }) => {
  const { data } = useShiftModalDataContext();
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );
  const [{ agencyCommunityId: _agencyCommunityId }] =
    useURLParameters<{ agencyCommunityId?: string }>();
  const agencyCommunityId = castStringToInteger(_agencyCommunityId!);

  const { fetch, ...rest } = useGQLQuery<
    assignToAgencyTeamMemberFetchAgencyJanesCount,
    { totalCount: number },
    assignToAgencyTeamMemberFetchAgencyJanesCountVariables
  >(
    fetchAgencyTeamMembersCountQueryConfig({
      orderId: data.fetchOrder.relayId,
      agencyCommunityId: !isUserAgencyAndAgencyLocationFilterForcedDisabled
        ? [agencyCommunityId!]
        : [],
    }),
  );

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FetchAgencyTeamMembersCountDataContext.Provider value={rest}>
      {children}
    </FetchAgencyTeamMembersCountDataContext.Provider>
  );
};

export const useFetchAgencyTeamMembersCountDataContext = (): UseGQLQuery<{ totalCount: number }> =>
  useContext(FetchAgencyTeamMembersCountDataContext);
