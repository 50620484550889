import { AuthPageContentWrapper } from 'components/AuthPageContentWrapper';
import { OffsetElementType } from 'components/AuthPageContentWrapper/AuthPageContextWrapper.types';
import { TabNavigation } from 'components/TabNavigation';
import { ITabItem } from 'components/TabNavigation/TabNavigation.types';
import { usePathname } from 'hooks/usePathname';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';

import { GeneralAdministratorSettingPage } from 'pages/SettingsPage/AdministratorSettingsPage/GeneralPageAdministratorSettings';
import { LocationAdministratorSettingPage } from 'pages/SettingsPage/AdministratorSettingsPage/LocationPageAdministratorSettings';
import { SettingsPageWrapper } from 'pages/SettingsPage/SettingsPage.styled';
import { FC, Fragment } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'hooks/useTranslation';

export interface SettingsAdministratorPageMatchParams {
  id: string;
}

const offsetElements: OffsetElementType[] = ['DesktopPageHeaderHeight', 'TabNavigationHeight'];

export const AdministratorSettingsPage: FC = (params) => {
  const { id } = useParams<SettingsAdministratorPageMatchParams>();
  const { path } = useRouteMatch();
  const activeTab = usePathname();
  const { t } = useTranslation();
  return (
    <Fragment>
      <TabNavigation
        activeTab={activeTab}
        tabs={useUserTypeSwitch({
          Agency: [
            {
              label: t('common:GENERAL'),
              path: `/settings/administrators/${id}/general`,
            },
            {
              label: t('settings:LOCATION'),
              path: `/settings/administrators/${id}/location`,
            },
          ],
          B2B: getB2BLocationSettingsTabs({
            id,
          }),
        })}
      />
      <AuthPageContentWrapper offsetElements={offsetElements}>
        <SettingsPageWrapper>
          <Switch>
            <Route path={`${path}/general`} component={GeneralAdministratorSettingPage} />
            <Fragment>
              <Route path={`${path}/location`} component={LocationAdministratorSettingPage} />
            </Fragment>
          </Switch>
        </SettingsPageWrapper>
      </AuthPageContentWrapper>
    </Fragment>
  );
};

function getB2BLocationSettingsTabs({ id }: { id: string }): ITabItem[] {
  const tabs: ITabItem[] = [
    {
      label: 'General',
      path: `/settings/administrators/${id}/general`,
    },
  ];

  tabs.push({
    label: 'Location',
    path: `/settings/administrators/${id}/location`,
  });

  return tabs;
}
