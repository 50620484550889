import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { shiftTypeDetailsMapper } from 'components/ActivityTab/ActivityTab.constants';
import { getActivityColorByStatus } from 'components/ActivityTab/getActivityColorByStatus';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import {
  shiftModalOrderDetails_fetchOrder_activities_event,
  shiftModalOrderDetails_fetchOrder_activities_metadata,
} from 'types/graphql-types';
import { useTranslation } from 'hooks/useTranslation';
import { isCurrentLanguageFrench } from 'providers/i18NextProvider.constants';
import { DateTime } from 'luxon';
import { UserType } from 'types/activities.types';

interface IActivityTabRowProps {
  activityDate: string;
  calloutListId: string;
  dotColor: BKJThemeColorType;
  eventType: string;
  eventName: string;
  hasEdits: boolean;
  hasCancellationReason: boolean;
  sentToNrOfAgencies: number;
  showHideDetails: ChangeFunctionType;
  userDetails: UserType;
  hasReasonCategory: boolean;
  reasonCategory: string | null;
  cancellationReason: string | null;
  editDetailList: string[];
  detailsShown: boolean;
  hasDetails: boolean;
  isRowGrey: boolean;
  awardedTo: string;
  timeZone: string | undefined;
}

export const ActivityTabRowPropsContext = createContext<IActivityTabRowProps>(
  {} as IActivityTabRowProps,
);

export const ActivityTabRowPropsProvider: FC<{
  createdAt: string;
  event: shiftModalOrderDetails_fetchOrder_activities_event;
  userDetails: UserType;
  index: number;
  metadata: shiftModalOrderDetails_fetchOrder_activities_metadata | null;
  timeZone?: string;
}> = ({ children, createdAt, event, userDetails, index, metadata, timeZone }) => {
  const { t, i18n } = useTranslation();
  const { month } = getLocalizedDateFormat(createdAt);
  const [showDetails, setShowDetails] = useState(false);
  const isFrenchFormat = isCurrentLanguageFrench();
  const format = isFrenchFormat ? `'à' HH:mm` : `'at' h:mm a`;
  const todayFormat = isFrenchFormat ? 'HH:mm' : 'h:mm a';
  const activityDate = DateTime.fromISO(createdAt)
    .setZone(timeZone)
    .hasSame(DateTime.now().setZone(timeZone), 'day')
    ? DateTime.fromISO(createdAt).setZone(timeZone).toFormat(todayFormat)
    : `${month} ${DateTime.fromISO(createdAt).setZone(timeZone).toFormat(`d, yyyy ${format}`)}`;

  const [calloutListId] = useState(metadata?.calloutListId || '');

  const dotColor = getActivityColorByStatus(event.name);

  let hasEdits = false;
  let hasCancellationReason = false;
  let hasReasonCategory = false;
  let sentToNrOfAgencies = -1;
  let detailsShown = false;
  let hasDetails = false;
  let eventValue = event.value;
  let eventName = event.name;
  let awardedTo = '';

  switch (event.name) {
    case 'cancelled': {
      hasCancellationReason = !!metadata?.reasonMessage || !!metadata?.reasonCategory;
      detailsShown = hasCancellationReason;
      hasDetails = hasCancellationReason;
      break;
    }
    case 'awarded_shift': {
      if (metadata?.reasonMessage) {
        awardedTo = metadata.reasonMessage;
      }
      break;
    }
    case 'updated': {
      hasEdits = !!metadata?.feedableEdits?.length;
      hasReasonCategory = !!metadata?.reasonCategory;
      detailsShown = showDetails;
      hasDetails = hasEdits;
      break;
    }
    case 'agency_assigned': {
      if (!!metadata?.acceptedFor?.name)
        eventValue = `${t('common:ACCEPTED_FOR')} ${metadata?.acceptedFor?.name}`;
      break;
    }
    case 'send_to_agencies':
    case 'sent_to_select_agencies':
    case 'scheduled_send_to_agencies': {
      sentToNrOfAgencies = metadata?.qualifiedAgencyJanesCount || 0;
      break;
    }
  }

  const contextValue = useMemo(
    () => ({
      activityDate,
      calloutListId,
      dotColor,
      eventType: eventValue,
      eventName,
      hasEdits,
      hasCancellationReason,
      sentToNrOfAgencies,
      showHideDetails: () => setShowDetails(!showDetails),
      cancellationReason: metadata?.reasonMessage || null,
      userDetails,
      hasReasonCategory,
      reasonCategory:
        metadata?.reasonCategory === 'other'
          ? ''
          : getTranslatedResponse(metadata?.reasonCategory, 'status', i18n, t) || null,
      editDetailList: (
        metadata?.feedableEdits?.map((edit) =>
          edit.fieldType ? shiftTypeDetailsMapper[edit.fieldType](edit) : '',
        ) || []
      ).filter((shiftEdit) => !!shiftEdit),
      detailsShown,
      hasDetails,
      awardedTo,
      isRowGrey: !!(index % 2),
      timeZone,
    }),
    [
      activityDate,
      calloutListId,
      dotColor,
      eventValue,
      eventName,
      hasEdits,
      hasReasonCategory,
      hasCancellationReason,
      sentToNrOfAgencies,
      metadata?.reasonCategory,
      metadata?.reasonMessage,
      metadata?.feedableEdits,
      userDetails,
      detailsShown,
      hasDetails,
      awardedTo,
      index,
      timeZone,
      showDetails,
      i18n,
      t,
    ],
  );

  return (
    <ActivityTabRowPropsContext.Provider value={contextValue}>
      {children}
    </ActivityTabRowPropsContext.Provider>
  );
};

export const useActivityTabRowPropsContext = (): IActivityTabRowProps =>
  useContext(ActivityTabRowPropsContext);
