import { TieringDetailsModalModalComponent } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.component';
import { ShiftDistributionRulesTieringModalDataProvider } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.data.context';
import { TieringDetailsViewUIProvider } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.ui.context';
import { FC } from 'react';

export const ShiftDistributionRulesTieringModal: FC = () => {
  return (
    <TieringDetailsViewUIProvider>
      <ShiftDistributionRulesTieringModalDataProvider>
        <TieringDetailsModalModalComponent />
      </ShiftDistributionRulesTieringModalDataProvider>
    </TieringDetailsViewUIProvider>
  );
};
