import styled, { css } from 'styled-components';
import { StyledCalendarWrapperProps } from '../../BKJCalendar.types';
import { StyledMonthCellAttributes } from './YearView.types';

export const YearViewWrapper = styled.div<StyledCalendarWrapperProps>`
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-wrap: wrap;
`;

export const MonthCell = styled.div<StyledMonthCellAttributes>`
  position: relative;
  display: flex;
  width: 112px;
  height: 86px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.White};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.Body};
  &:hover {
    color: ${({ theme }) => theme.colors.PrimaryHover};
    background-color: ${({ theme }) => theme.colors.Grey3};
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.White};
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
    `};
`;

export const ThisMonthIndicator = styled.div`
  height: 4.3px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.AccentGreen};
`;
