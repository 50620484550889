import { useFormBehaviors } from 'hooks';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { METHOD_PATCH } from 'requests';
import { useAPIActions } from 'store/reducers/api/apiSlice';

import { toastService } from 'services';
import i18next from 'i18next';
import { FieldNameType } from 'types/common.types';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import {
  ChangeFunctionType,
  GQLAPIContextType,
} from '@bookjane2/bookjane-design-library/lib/common.types';
import { fetchOrderBidders } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { ADD_SHIFT_AWARD } from 'constants/endpoints';
import {
  ShiftModalUIContext,
  shiftModalRoutingService,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { queryRefetchService } from 'services/QueryRefetchService';

export const AwardTeamMemberViewFormContext = createGQLDataContext({});
export const AwardTeamMemberViewFormProvider: FC = ({ children }) => {
  const { shift_id: orderId } = useContext(ShiftModalUIContext);

  const { createShift } = useContext(CreateShiftCardContext);

  const {
    values: { request_janes = '', quantity },
  } = createShift;
  const [modalValues, setModalValues] = useState({
    request_janes: request_janes,
    estimatedTotalPayout: 0,
  });
  const [dirtyFields, setDirtyFields] = useState<FieldNameType[]>([]);
  const selectedJanes = modalValues.request_janes!.split(',').filter((id) => id !== '');
  const { apiRequest } = useAPIActions();
  const handleChange = useCallback(
    (e: { target: { name: string; value: string } }) => {
      const {
        target: { value: request_janes },
      } = e;

      const _modalValues = { ...modalValues };
      setDirtyFields([...dirtyFields].concat(['request_janes']));

      if (quantity[0] === '1') {
        _modalValues['request_janes'] = request_janes;
      } else {
        let updatedSelectedJanes;
        if (selectedJanes.includes(request_janes)) {
          updatedSelectedJanes = selectedJanes.filter((id) => id !== request_janes);
        } else {
          updatedSelectedJanes = [...selectedJanes, request_janes];
        }

        _modalValues['request_janes'] = updatedSelectedJanes.join(',');
      }
      setModalValues(_modalValues);
    },
    [dirtyFields, modalValues, quantity, selectedJanes],
  );
  const handleSave = useCallback(async () => {
    return await apiRequest(
      {
        __typename: 'LegacyAPI',
        method: METHOD_PATCH,
        url: ADD_SHIFT_AWARD(Number(orderId)),
        body: {
          jane_id: selectedJanes.toString(),
        },
      },
      {
        onSuccess: async () => {
          toastService.success(i18next.t('common:SHIFT_SUCCESSFULLY_AWARDED'));
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${orderId}`);
          shiftModalRoutingService.close();
        },
        onError: async () => {
          shiftModalRoutingService.goToView('AwardUnavailableView');
        },
      },
    );
  }, [apiRequest, orderId, selectedJanes]);
  const form = useFormBehaviors({
    isDirtyCheckEnabled: true,
    initialState: {},
    onSubmit: handleSave,
    validations: {},
  });
  const value = useMemo(() => {
    return {
      ...form,
      handleChange,
      selectedJanes,
    };
  }, [form, handleChange, selectedJanes]);

  return (
    <AwardTeamMemberViewFormContext.Provider value={value}>
      {children}
    </AwardTeamMemberViewFormContext.Provider>
  );
};

export const useAwardTeamMemberViewFormContext = (): GQLAPIContextType<fetchOrderBidders> & {
  handleChange: ChangeFunctionType;
  selectedJanes: string[];
  onSubmit: () => {};
} => useContext(AwardTeamMemberViewFormContext);
