import { JaneImage } from 'pages/LoginPage/LoginPage.styled';
import { FC, useCallback } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { AnchorAppLink } from 'components/AnchorAppLink/AnchorAppLink';
import registrationSplashImage from 'assets/registration_splash.png';
import registrationSplashFrImage from 'assets/registration_splashFr.png';
import registrationGenericPartnerSplashImage from 'assets/registration_partner_generic_splash.png';
import { useTranslation } from 'hooks/useTranslation';

type WhiteLabelSplashLogoProps = { width?: string };

export const WhiteLabelSplashLogo: FC<WhiteLabelSplashLogoProps> = (props) => {
  const { WhiteLabelProvidersEnum, whiteLabelProvider } = WhiteLabelAccessor;

  const { i18n } = useTranslation();

  const RenderSplashLogoComponent = useCallback(() => {
    switch (whiteLabelProvider) {
      case WhiteLabelProvidersEnum.ACCUSHIELD:
        return (
          <AnchorAppLink target="_blank" href="https://www.accushield.com">
            <JaneImage src={registrationGenericPartnerSplashImage} />
          </AnchorAppLink>
        );
      case WhiteLabelProvidersEnum.PETAL:
        return (
          <AnchorAppLink target="_blank" href={undefined}>
            <JaneImage src={registrationSplashFrImage} />
          </AnchorAppLink>
        );
      case WhiteLabelProvidersEnum.WHITE_LABEL_DEMO:
        return (
          <AnchorAppLink target="_blank" href={undefined}>
            <JaneImage src={registrationGenericPartnerSplashImage} />
          </AnchorAppLink>
        );
      default:
        return (
          <AnchorAppLink target="_blank" href={'https://www.bookjane.com/referral-program'}>
            <JaneImage
              src={i18n.language === 'fr' ? registrationSplashFrImage : registrationSplashImage}
            />
          </AnchorAppLink>
        );
    }
  }, [
    whiteLabelProvider,
    WhiteLabelProvidersEnum.ACCUSHIELD,
    WhiteLabelProvidersEnum.PETAL,
    WhiteLabelProvidersEnum.WHITE_LABEL_DEMO,
    i18n.language,
  ]);

  return <RenderSplashLogoComponent />;
};
