import { useFormBehaviors } from 'hooks/useFormBehaviors';
import { KeyboardEventHandler, useCallback } from 'react';

export const useHandleSearch = () => {
  const {
    onChange,
    values: { search },
    onReset,
  } = useFormBehaviors<{ search: string }>({
    initialState: { search: '' },
  });

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    ({ key }) => {
      switch (key) {
        case 'Escape':
          if (search !== '') onReset();
          break;
      }
    },
    [onReset, search],
  );

  return { search, onChange, onReset, handleKeyDown };
};
