import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { IHamburgerMenu } from 'components/MobilePageHeader/MobilePageHeader.types';
import { zIndexLayers } from 'constants/zIndexLayers';
import styled from 'styled-components';

export const MobilePageHeaderWrapper = styled.header`
  ${({ theme }) => theme.typography.H4};
  ${({ theme }) => theme.shadows.Default};
  z-index: ${zIndexLayers.pageHeader};
  display: flex;
  align-items: center;
  padding: 0 18px;
  display: none;
  width: 100%;
  min-height: 60px;
  background-color: ${(props) => props.theme.colors.AccentPurple};
  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: flex;
  }
`;

export const HamburgerMenu: IHamburgerMenu = styled(BKJIcon)`
  cursor: pointer;
  min-width: 24px;
`;
