import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { fetchJaneByIdConfig } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberViewDataProvider.constants';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSSFBehaviors } from 'hooks';
import { SSFBehaviorsContextType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { FC, useContext, useMemo } from 'react';
import { sendToAgenciesSummaryView } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchTeamMemberByJaneIdDataContext = createGQLDataContext<
  SSFBehaviorsContextType<sendToAgenciesSummaryView>
>({} as SSFBehaviorsContextType<sendToAgenciesSummaryView>);

export const FetchTeamMemberByJaneIdDataProvider: FC = ({ children }) => {
  const {
    values: { jane_id: janeId },
  } = useSendToAgenciesFormContext();
  const {
    data: {
      fetchOrder: {
        position: { id: positionId },
      },
    },
  } = useShiftModalDataContext();

  const [values, rest] = useSSFBehaviors(fetchJaneByIdConfig({ janeId, positionId }));

  const contextValue = useMemo(() => ({ values, ...rest }), [rest, values]);

  return (
    <FetchTeamMemberByJaneIdDataContext.Provider value={contextValue}>
      {children}
    </FetchTeamMemberByJaneIdDataContext.Provider>
  );
};

export const useFetchTeamMemberByJaneIdDataContext =
  (): SSFBehaviorsContextType<sendToAgenciesSummaryView> =>
    useContext(FetchTeamMemberByJaneIdDataContext);
