import { TableRowCell } from 'components/ActivityTab/ActivityTab.styled';
import { Flex } from 'components/Flex';
import {
  LocationHeader,
  TableRowCellContent,
} from 'components/ScheduleSettingsModal/views/ScheduleRules.styled';
import styled from 'styled-components';

export const ModalSideLabel = styled.div`
  ${({ theme }) => theme.fonts.Poppins};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
`;

export const DropdownLegendLabel = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  padding-top: 10px;
`;

export const ModalDropdownInput = styled.div`
  width: 180px;
`;

export const ModalInputGroup = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const HeaderText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  text-align: left;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 32px;
`;

export const AddPositionDescription = styled.div`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 20px;
`;

export const SendToGroupsSection = styled(Flex)`
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
  padding-bottom: 10px;
  padding-left: 5px;
`;

export const OrderColumn = styled(Flex)`
  width: 144px;
  padding: 0 0 0 40px;
`;

export const SendToGroupColumn = styled(Flex)`
  width: 312px;
`;

export const DeleteRowColumn = styled(Flex)`
  width: 40px;
`;

export const CloseButtonImage = styled.img`
  margin-left: 20px;
  margin-bottom: 4px;
  height: 14px;
  cursor: pointer;
`;

export const PositionRuleTableRowCell = styled(TableRowCell)`
  padding: 6px 0 0 6px;
  min-height: 65px;
`;
export const PositionRuleTableRowCellContent = styled(TableRowCellContent)`
  padding-left: 0;
  align-items: center;
`;

export const SendToGroupsLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
`;

export const ShiftThresholdHeader = styled(LocationHeader)`
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 10px;
`;

export const SendToGroupsDropdownLabel = styled(SendToGroupsLabel)`
  width: 300px;
`;

export const SendToGroupsTableLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
`;

export const MaxGroupRulesLabel = styled.div`
  color: ${({ theme }) => theme.colors.Grey7};

  padding-left: 30px;
  padding-bottom: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;
