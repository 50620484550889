import {
  sendToAgenciesSummaryView,
  sendToAgenciesSummaryViewVariables,
  shiftModalOrderDetails_fetchOrder_position_openPriceRange,
} from 'types/graphql-types';
import { QUERY_sendToAgenciesSummaryView } from 'queries';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';

export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function parsePrice(price?: string) {
  if (!price) throw new Error('Data Error: Price cannot be undefined');
  return Number.parseFloat(price ? price.replace('$', '') : '0.0');
}

export function buildTotalEstimatedPayoutRange(
  priceRange: shiftModalOrderDetails_fetchOrder_position_openPriceRange,
  duration: number,
) {
  if (!priceRange || (!priceRange.max && !priceRange.min)) return '$00.00';
  const { min, max } = priceRange;
  return min === max
    ? priceFormatter.format(parsePrice(min) * duration)
    : `${priceFormatter.format(parsePrice(min) * duration)} - ${priceFormatter.format(
        parsePrice(max) * duration,
      )}`;
}

export const fetchJaneByIdQueryConfig = (
  janeId: string,
  positionId?: number,
): UseGQLQueryConfig<
  sendToAgenciesSummaryView,
  sendToAgenciesSummaryView,
  sendToAgenciesSummaryViewVariables
> => ({
  key: 'SEND_TO_AGENCIES_SUMMARY_VIEW_FETCH_JANE_QUERY',
  query: QUERY_sendToAgenciesSummaryView,
  variables: {
    jane_id: janeId,
    positionId,
  },
});
