import { AUTH_SIGNUP } from 'constants/endpoints';
import i18next from 'i18next';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType, SignUpParam } from 'requests/index.types';

export const API_POST_signUp = (params: SignUpParam): ApiRequestConfigType => {
  const {
    industry_id,
    industry_type,
    email,
    first_name,
    last_name,
    password,
    password_confirmation,
    phone,
    terms,
    latitude,
    longitude,
    formatted_address,
    zip_code,
    country,
    suite,
    state,
    privacy,
    company_name,
    street_number,
    street,
    country_code,
    city,
    locale,
  } = params;

  // TO DO: Make sure this is fixed when auto complete is fixed
  const communities_attributes = [
    {
      contact_email: email,
      contact_name: `${first_name} ${last_name}`,
      contact_phone: phone,
      name: company_name,
      location_attributes: {
        latitude,
        longitude,
        street: street,
        street_number,
        flat: suite,
        formatted_address,
        state,
        zip_code,
        country,
        city,
      },
    },
  ];
  const user = {
    type: industry_type,
    email,
    first_name,
    last_name,
    password,
    password_confirmation,
    locale,
    industry_id: industry_id[0],
    phone,
    terms,
    privacy,
    communities_attributes,
  };

  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url: AUTH_SIGNUP(country_code),
    headers: {
      Locale: i18next.language,
    },
    body: {
      session: { device_platform: 'web' },
      user: { ...user },
    },
  };
};
