import styled, { css } from 'styled-components';
import { assertBKJThemeType } from '@bookjane2/bookjane-design-library';
import { NavLink } from 'react-router-dom';
import { IMenuLinkStyles } from 'components/BKJMainNavigation/BKJMainNavigation.types';
import { zIndexLayers } from 'constants/zIndexLayers';
import { HTMLAttributes, RefObject } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { BKJToggleInput } from '@bookjane2/bookjane-design-library';

export const OffsetWrapper = styled.div`
  width: 80px;
  min-width: 80px;
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    display: none;
  }
`;

interface IBKJMainNavigationWrapperProps extends HTMLAttributes<HTMLDivElement> {
  $isOpen: boolean;
  ref: RefObject<HTMLDivElement>;
}

export const MobileNavigationWrapper = styled.div<IBKJMainNavigationWrapperProps>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  width: ${({ $isOpen }) => ($isOpen ? '100vw' : '0')};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndexLayers.mainNavigation};
  will-change: width;
  background-color: ${(props) => props.theme.colors.AccentPurple};
  color: ${(props) => props.theme.colors.White};
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  overflow-y: scroll;
`;

export const MobileNavigationTitle = styled.div`
  margin: auto;
  color: ${(props) => props.theme.colors.TextWhite};
`;

export const InputLabelText = styled.div`
  color: ${(props) => props.theme.colors.TextWhite};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;

export const StyledBKJToggleInput = styled(BKJToggleInput)<{ checked: boolean }>`
  min-height: 14px;
  min-width: 34px;
  height: 14px;
  width: 34px;
  margin-right: 12px;
  > div:first-child {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    ${({ checked }) =>
      checked &&
      css`
        left: calc(100% - 14px);
        background-color: ${({ theme }) => theme.colors.AccentGreen};
      `}
  }
  > div:nth-child(2) {
    min-height: 14px;
    min-width: 32px;
    height: 14px;
    width: 32px;
    ${({ checked }) =>
      checked &&
      css`
        background-color: ${({ theme }) => theme.colors.Grey6};
      `}
  }
`;

export const MainNavigationWrapper = styled.div<IBKJMainNavigationWrapperProps>`
  ${(props) => assertBKJThemeType('MainNavigationWrapper', props)};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndexLayers.mainNavigation};
  width: ${({ $isOpen }) => ($isOpen ? '240px' : '80px')};
  will-change: width;
  background-color: ${(props) => props.theme.colors.AccentPurple};
  color: ${(props) => props.theme.colors.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    display: none;
  }
`;

export const MainNavigationContent = styled.div``;
export const MainNavigationHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 29px;
  justify-content: space-between;
  min-height: 80px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.AccentPurple};
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    position: fixed;
    width: 100%;
    z-index: 100;
    min-height: 60px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

interface INavLogoWrapperProps {
  $isOpen: boolean;
}

export const NavLogoWrapper = styled.div<INavLogoWrapperProps>`
  ${(props) => assertBKJThemeType('NavLogoWrapper', props)};
  display: flex;
  width: ${({ $isOpen }) => ($isOpen ? '100%' : '0%')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  justify-content: flex-end;
  will-change: width, opacity;
  padding-right: 23px;
`;

export const MobileNavLogoWrapper = styled.div<INavLogoWrapperProps>`
  ${(props) => assertBKJThemeType('NavLogoWrapper', props)};
  display: flex;
  width: ${({ $isOpen }) => ($isOpen ? '100%' : '0%')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  justify-content: flex-end;
  will-change: width, opacity;
`;

export const WhiteLabelLogoContainer = styled.div`
  margin: auto;
`;

export const GeneralLocation = styled.img`
  width: 100%;
`;

const MenuLinkStyles = css<IMenuLinkStyles>`
  color: ${({ $disabled, theme }) => ($disabled ? theme.colors.TextDisabled : theme.colors.White)};
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-left: 29px;
  justify-content: flex-start;
  min-height: 48px;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.PrimaryDefault};
  }

  &:hover::after {
    ${({ theme }) => theme.typography.Caption};
    content: ${({ $label }) => `'${$label}'`};
    white-space: nowrap;
    word-break: keep-all;
    background-color: ${({ theme }) => theme.colors.Grey7};
    display: ${({ $isOpen }) => ($isOpen ? 'none' : 'flex')};
    position: absolute;
    z-index: ${zIndexLayers.mainNavigation};
    color: white;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;
    border-radius: 8px;
    left: 104px;
    cursor: default;
    pointer-events: none;
    cursor: pointer;
  }

  &:hover::before {
    content: '';
    position: absolute;
    z-index: ${zIndexLayers.mainNavigation};
    background-color: ${({ theme }) => theme.colors.Grey7};
    display: ${({ $isOpen }) => ($isOpen ? 'none' : 'flex')};
    height: 12px;
    width: 12px;
    transform: rotate(45deg);
    left: 98px;
    cursor: default;
    pointer-events: none;
    cursor: pointer;
  }

  &:active {
    background-color: ${({ theme }) => {
      switch (WhiteLabelAccessor.whiteLabelProvider) {
        case WhiteLabelAccessor.WhiteLabelProvidersEnum.XTM:
          return theme.colors.PrimaryHover;
        default:
          return theme.colors.PrimaryClick;
      }
    }};
    cursor: pointer;
  }
  &.active {
    background-color: ${({ theme }) => {
      switch (WhiteLabelAccessor.whiteLabelProvider) {
        case WhiteLabelAccessor.WhiteLabelProvidersEnum.XTM:
          return theme.colors.PrimaryHover;
        default:
          return theme.colors.PrimaryDefault;
      }
    }};
    cursor: pointer;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey3};
    `}
`;

export const NavMenuItemsWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    padding-top: 80px;
  }
`;

export const NavMenuItemButton = styled.button`
  ${(props) => assertBKJThemeType('NavMenuItemButton', props)};
  ${MenuLinkStyles};
  background: inherit;
  border: inherit;
`;

export const InternalMenuLink = styled(NavLink)`
  ${(props) => assertBKJThemeType('InternalMenuLink', props)};
  ${MenuLinkStyles};
`;

export const ExternalMenuLink = styled.a`
  ${(props) => assertBKJThemeType('ExternalMenuLink', props)};
  ${MenuLinkStyles};
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
`;

interface IMenuItemLabelProps {
  $isOpen?: boolean;
  $disabled?: boolean;
  $showPreLine?: boolean;
}

export const MenuItemLabel = styled.span<IMenuItemLabelProps>`
  ${(props) => assertBKJThemeType('MenuItemLabel', props)};
  ${(props) => props.theme.fonts.PoppinsTextMedium};
  font-size: 14px;
  display: block;
  width: ${({ $isOpen }) => ($isOpen ? '100%' : '0%')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  will-change: width, opacity;
  padding-left: 13px;
  white-space: ${({ $isOpen, $showPreLine }) => ($isOpen && $showPreLine ? 'pre-line' : 'pre')};
  cursor: default;

  &:hover {
    cursor: pointer;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  padding-bottom: 74px;
`;
