import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModal } from 'components/BKJSideModal/BKJSideModal';
import {
  TimeOffModalHeaderMap,
  timeOffModalRoutingService,
} from 'components/TimeOffModal/TimeOffModal.constants';
import { useTimeOffModalRoutingContext } from 'components/TimeOffModal/TimeOffModal.routing.context';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const TimeOffModalTemplate: FC = ({ children }) => {
  const { isOpen, timeOffModalActiveModalView } = useTimeOffModalRoutingContext();

  return (
    <BKJSideModal
      data-testid="J360TimeOffModal"
      isOpen={isOpen}
      onClose={timeOffModalRoutingService.close}
      onBack={timeOffModalRoutingService.goToPrevView}
      headerComponent={TimeOffModalHeaderMap[timeOffModalActiveModalView]}
      showBackButton={timeOffModalRoutingService.isBackButtonVisible()}
    >
      <ErrorBoundary componentName="TimeOffModal" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        {children}
      </ErrorBoundary>
    </BKJSideModal>
  );
};
