import { createSelector } from 'reselect';
import { IGlobalReduxStore } from 'store';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

const isUserAgencySecondarySelector = createSelector(
  [getUser],
  (user) => user?.type === 'AgencyManager',
);

export default isUserAgencySecondarySelector;
