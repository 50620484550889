import { BKJBadge, BKJIcon } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';

export const JaneAvatarWrapper = styled(JaneAvatar)`
  margin-right: 28px;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
`;

export const ShiftIdWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  ${({ theme }) => theme.common.Ellipsis};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  line-height: 24px;
`;

export const InProgressHighlight = styled.div`
  position: absolute;
  left: 0;
  width: 15px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.AccentBrand};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const BadgeText = styled.div`
  ${({ theme }) => theme.common.Ellipsis};
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.White};
  font-size: 14px !important;
  font-weight: normal !important;
  ${({ theme }) => theme.common.Ellipsis};
`;

export const DedicatedBKJBadge = styled(BKJBadge)`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.White};
  font-size: 14px !important;
  font-weight: normal !important;
  ${({ theme }) => theme.borders.BoxBorder};
  max-height: 21px;
  min-height: 21px;
  height: 21px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px 2px 8px;
  ${({ theme }) => theme.common.Ellipsis};
`;

export const ShiftCardSectionA = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
`;

export const ShiftCardSectionB = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
  justify-content: space-between;
`;

export const ShiftActionsWrapper = styled(Flex)`
  min-width: fit-content;
  flex-direction: column;
`;

export const ShiftCardWrapper = styled.div`
  ${({ theme }) => theme.common.Ellipsis};
  position: relative;
  padding: 12px;
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.White};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  ${ShiftCardSectionB}:nth-child(2) {
    border-left: 1px solid #c5cad1;
  }

  ${({ theme }) => css`
    ${theme.borders.DefaultBorder};
    ${theme.shadows.Default};
  `};
`;

export const MobileShiftCardWrapper = styled.div`
  ${({ theme }) => theme.common.Ellipsis};
  position: relative;
  padding: 20px;
  height: 100%;
  margin: 12px 0 0 0;
  background-color: ${({ theme }) => theme.colors.White};
  cursor: pointer;
  border-top: 1px solid #dfe3e9;
  border-bottom: 1px solid #dfe3e9;

  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

export const MobileShiftCardTopSection = styled.div`
  width: 100%;
`;

export const SentToAgenciesText = styled.span`
  ${({ theme }) => theme.common.Ellipsis};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  line-height: 24px;
`;

export const ShiftCardIcon = styled(BKJIcon)`
  min-width: 24px;
  padding-right: 5px;
`;

export const ShiftCardText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ theme }) => theme.common.Ellipsis};
  color: ${({ theme }) => theme.colors.Grey7};
  font-size: 16px;
  line-height: 24px;
`;

export const BiddingEndsText = styled.span<{ priority: string }>`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ theme }) => theme.common.Ellipsis};
  color: ${({ theme, priority }) =>
    priority === 'critical' ? theme.colors.ErrorDefault : theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
`;

export const ShiftInfoWrapper = styled(Flex)`
  width: 100%;
  ${({ isVisible }: { isVisible: boolean }) => {
    return (
      isVisible &&
      css`
        width: calc(100% - 192px);
      `
    );
  }};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
  }
`;

export const JaneNameWrapper = styled.span`
  color: ${({ theme }) => theme.colors.Grey7};
  align-items: center;
  display: inline-flex;
  font-size: 16px;
  ${({ theme }) => theme.common.Ellipsis};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    gap: 8px;
  }
`;

export const CallOutStatusWrapper = styled.div`
  display: flex;
`;

export const CallOutStatus = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;
