import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { GQLAPIContextType } from 'types/common.types';
import { fetchOrderBidders_fetchOrder_bidders } from 'types/graphql-types';

export interface IJaneGridSelectProps {
  type: 'Multiple' | 'Single';
  max?: number;
  label?: string;
  context: GQLAPIContextType;
  name: string;
  onChange: ChangeFunctionType;
  value: string[];
  dataPath: string;
  paginationType: JaneGridSelectPaginationType;
  loadMore?: Function;
  pageInfoDataPath?: string;
  errorText?: string;
}

export type JaneGridSelectPaginationType = 'InfiniteScroll' | 'Normal';
export interface IJaneGridCardComponentProps {
  type: 'Multiple' | 'Single';
  max?: number;
  id: string;
  index: number;
  label: string;
  getData: (index: number) => fetchOrderBidders_fetchOrder_bidders;
  name: string;
  value: string[];
  onChange: ChangeFunctionType;
}
export const formatString = (inputString: string) => {
  return inputString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
