/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { useShiftSwapLocationSettingsPageDataContext } from 'pages/LocationSettingsPage/ShiftSwapLocationSettingsPage.data.context';
import { ShiftSwapLocationSettingsPageFormFields as ALSPFFields } from 'pages/LocationSettingsPage/ShiftSwapLocationSettingsPageForm.types';
import pluralize from 'pluralize';
import { Context, createContext, FC, useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { API_POST_updateCustomerShiftSwap } from 'requests/POST_updateCustomerShiftSwap';
import { API_GET_disableCustomerShiftSwap } from 'requests/GET_disableCustomerShiftSwap';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

const ShiftSwapLocationSettingsPageFormContext = createContext<UseFormBehaviors<ALSPFFields, {}>>(
  {} as UseFormBehaviors<ALSPFFields, {}>,
);

export const ShiftSwapLocationSettingsPageFormProvider: FC = ({ children }) => {
  const { data } = useShiftSwapLocationSettingsPageDataContext();
  const { locationId } = useParams<{ locationId: string }>();
  const { apiRequestParallel, apiRequest } = useAPIActions();
  const history = useHistory();
  const { t } = useTranslation();

  const [apiErrors, setApiErrors] = useState(
    {} as { error_messages?: { error_message?: string[] } },
  );
  const form = useFormBehaviors<ALSPFFields, {}>({
    initialState: {
      shift_swap_enabled: data.form.shift_swap_enabled,
      min_hours_before_shift_swap: data.form.min_hours_before_shift_swap
        ? `${data.form.min_hours_before_shift_swap}`
        : `8`,
      communityIds: [`${locationId}`],
    },
    onSubmit: async (formValues: ALSPFFields) => {
      if (!formValues.shift_swap_enabled) {
        // Disabling Shift swap
        const result = await apiRequest(API_GET_disableCustomerShiftSwap(locationId), {
          onSuccess: () => {
            history.push(`/settings/locations/${locationId}/shift-swap`);
            toastService.success(t('success:SHIFT_SWAP_DISABLED'));
          },
          onError: (e) => handleGenericErrorMessage(e, t('errors:FAILED_TO_DISABLE_SHIFT_SWAP')),
        });
        return result;
      } else {
        const result = (await apiRequestParallel(
          API_POST_updateCustomerShiftSwap(formValues.communityIds, formValues),
        )) as unknown as any[];
        const hasErrors = result.some((response: any) => {
          return response?.payload?.data?.error_messages;
        });
        if (!hasErrors) {
          history.push(`/settings/locations/${locationId}/shift-swap`);
          toastService.success(
            `${t('success:SHIFT_SWAP_ENABLED')} ${formValues.communityIds.length} ${pluralize(
              t('common:LOCATION'),
              formValues.communityIds.length,
            )}.`,
          );
        } else {
          setApiErrors(result[0].payload.data);
          toastService.error(
            `${t('errors:FAILED_TO_UPDATE_SHIFT_SWAP_FOR')} ${result.length} ${pluralize(
              t('common:LOCATION'),
              result.length,
            )}.`,
          );
        }
      }
    },
    validations: {},
  });

  const handleChange = useCallback(
    (e) => {
      if (e?.target?.name === 'min_hours_before_shift_swap') setApiErrors({});
      form.onChange(e);
    },
    [form],
  );

  return (
    <ShiftSwapLocationSettingsPageFormContext.Provider
      value={{ ...form, onChange: handleChange, apiErrors }}
    >
      {children}
    </ShiftSwapLocationSettingsPageFormContext.Provider>
  );
};

export const useShiftSwapLocationSettingsPageFormContext = (): Omit<
  UseFormBehaviors<ALSPFFields, {}>,
  'apiErrors'
> & {
  apiErrors: {
    error_messages?: { error_message?: string[] };
  };
} =>
  useContext(
    ShiftSwapLocationSettingsPageFormContext as unknown as Context<
      Omit<UseFormBehaviors<ALSPFFields, {}>, 'apiErrors'> & {
        apiErrors: {
          error_messages?: { error_message?: string[] };
        };
      }
    >,
  );
