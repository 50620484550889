import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect, useMemo } from 'react';

interface IRequestTeamMemberViewProps {}

export const RequestTeamMemberViewPropsContext = createContext<IRequestTeamMemberViewProps>({});

export const RequestTeamMemberViewPropsProvider: FC = ({ children }) => {
  const [, { removeParam }] = useURLParameters();

  useEffect(() => {
    if (!shiftModalRoutingService.isBackButtonVisible())
      removeParam(['shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [removeParam]);

  const contextValue = useMemo(() => ({}), []);

  return (
    <RequestTeamMemberViewPropsContext.Provider value={contextValue}>
      {children}
    </RequestTeamMemberViewPropsContext.Provider>
  );
};

export const useRequestTeamMemberViewPropsContext = () =>
  useContext(RequestTeamMemberViewPropsContext);
