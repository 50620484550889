import { createContext, FC, useContext, useMemo, useState } from 'react';

interface IDashboardPageUnmount {
  unmountValue: number;
  unmountDashboard: () => void;
}

export const DashboardPageUnmountContext = createContext<IDashboardPageUnmount>(
  {} as IDashboardPageUnmount,
);

export const DashboardPageUnmountProvider: FC = ({ children }) => {
  const [unmountValue, setUnmountValue] = useState(0);

  const contextValue = useMemo(
    () => ({
      unmountValue,
      unmountDashboard: () => setUnmountValue(unmountValue + 1),
    }),
    [unmountValue],
  );

  return (
    <DashboardPageUnmountContext.Provider value={contextValue}>
      {children}
    </DashboardPageUnmountContext.Provider>
  );
};

export const useDashboardPageUnmountContext = () => useContext(DashboardPageUnmountContext);
