import styled from 'styled-components';

export const ReviewTimestampText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  margin: 0;
  font-size: 14px;
  line-height: 22px;
`;

export const SeparatingLine = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.colors.Grey5};
`;

export const TooltipWrapper = styled.div`
  width: 250px;
`;

export const LabelAddress = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.TextHighlight2};
`;

export const CallOutWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.Grey1};
  padding: 32px 32px 0 32px;
  width: 100%;
`;
