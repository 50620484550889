import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { FetchMinMaxPricesByPositionIdDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/FetchMinMaxPricesByPositionId.data.context';
import { FetchTeamMemberByJaneIdDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/FetchTeamMemberByJaneId.data.context';
import { FetchTeamMembersDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/FetchTeamMembers.data.context';
import { RequestTeamMemberViewPropsProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/RequestTeamMemberView.props.context';
import { RequestTeamMemberViewComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberView.component';
import { FetchSelectedAgenciesRateDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests/FetchSelectedAgenciesRate.data.context';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';

const _RequestTeamMemberView_: FC = () => {
  return (
    <ErrorBoundary
      componentName="RequestTeamMemberView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <FetchTeamMembersDataProvider>
        <FetchTeamMemberByJaneIdDataProvider>
          <FetchMinMaxPricesByPositionIdDataProvider>
            <FetchSelectedAgenciesRateDataProvider>
              <RequestTeamMemberViewPropsProvider>
                <RequestTeamMemberViewComponent />
              </RequestTeamMemberViewPropsProvider>
            </FetchSelectedAgenciesRateDataProvider>
          </FetchMinMaxPricesByPositionIdDataProvider>
        </FetchTeamMemberByJaneIdDataProvider>
      </FetchTeamMembersDataProvider>
    </ErrorBoundary>
  );
};
export const RequestTeamMemberView = withMemo(_RequestTeamMemberView_);
