import { createContext } from 'react';
import { IBKJCalendarContextValue } from './BKJCalendar.types';
import { getCurrentMonthCalendarRange } from '../../../BKJDatePicker.utils';

export const BKJCalendarContext = createContext<IBKJCalendarContextValue>({
  name: 'filterDate',
  type: 'SingleDateRange',
  value: getCurrentMonthCalendarRange(),
  dateRangeInMonthView: getCurrentMonthCalendarRange(),
  onChange: () => {},
  setIsCalendarOpen: () => {},
  handleYearViewOnChange: () => {},
  handleMonthViewOnChange: () => {},
  handleClickMonthViewDateDisplay: () => {},
});
