import { QuerySwitch } from 'components/QuerySwitch';
import {
  FetchAvailableTeamMembersCountDataContext,
  FetchAvailableTeamMembersCountDataProvider,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/OpenShift/FetchAvailableTeamMembersCount.data.context';
import { OpenShiftComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/OpenShift/OpenShift.component';
import { FC } from 'react';

export const OpenShift: FC = () => (
  <FetchAvailableTeamMembersCountDataProvider>
    <QuerySwitch
      component={OpenShiftComponent}
      context={FetchAvailableTeamMembersCountDataContext}
    />
  </FetchAvailableTeamMembersCountDataProvider>
);
