import { InputNameType, isInputNameType } from 'types/common.types';

export const assertIsInputNameType: (arg: unknown) => asserts arg is InputNameType = (
  arg: unknown,
) => {
  if (!isInputNameType(arg))
    throw new TypeError(
      "'name' is not compatible with InputNameType. It must be a lower camelCase or lower snake_case string",
    );
};
