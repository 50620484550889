import { css } from 'styled-components';
import { IBKJThemeButton } from '@bookjane2/bookjane-design-library/lib/components/BKJButton//BKJButton.types';

export const XtmThemeButton: IBKJThemeButton = {
  PurplePill: css`
    color: ${({ theme }) => theme.colors.AccentPurple};
    border-radius: 19px;
    background-color: ${({ theme }) => theme.colors.AccentBrand};
    &:focus {
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.PrimaryDefault};
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.PrimaryHover};
      border: none;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
      border: none;
    }
    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.Grey3};
      color: ${({ theme }) => theme.colors.Grey5};
      fill: ${({ theme }) => theme.colors.Grey5};
      &:hover {
        background-color: ${({ theme }) => theme.colors.Grey4};
        cursor: not-allowed;
      }
    }
  `,
  PurpleSolid: css`
    color: ${({ theme }) => theme.colors.AccentPurple};
    background-color: ${({ theme }) => theme.colors.AccentBrand};
    &:focus {
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.PrimaryDefault};
      background-color: ${({ theme }) => theme.colors.PrimaryHover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.PrimaryHover};
      border: none;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.PrimaryClick};
      border: none;
    }
    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.Grey3};
      color: ${({ theme }) => theme.colors.Grey5};
      fill: ${({ theme }) => theme.colors.Grey5};
      &:hover {
        background-color: ${({ theme }) => theme.colors.Grey4};
        cursor: not-allowed;
      }
    }
  `,
  GreenSolid: css`
    color: ${({ theme }) => theme.colors.AccentPurple};
    background-color: ${({ theme }) => theme.colors.AccentBrand};
    &:focus {
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.SuccessDefault};
      background-color: ${({ theme }) => theme.colors.SuccessHover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.SuccessHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.SuccessClick};
    }
    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.Grey3};
      color: ${({ theme }) => theme.colors.Grey5};
      fill: ${({ theme }) => theme.colors.Grey5};
      &:hover {
        background-color: ${({ theme }) => theme.colors.Grey4};
        cursor: not-allowed;
      }
    }
  `,
  RedSolid: css`
    color: ${({ theme }) => theme.colors.White};
    background-color: ${({ theme }) => theme.colors.ErrorDefault};
    &:focus {
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.ErrorDefault};
      background-color: ${({ theme }) => theme.colors.ErrorHover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.ErrorHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.ErrorClick};
    }
    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.Grey3};
      color: ${({ theme }) => theme.colors.Grey5};
      fill: ${({ theme }) => theme.colors.Grey5};
      &:hover {
        background-color: ${({ theme }) => theme.colors.Grey4};
        cursor: not-allowed;
      }
    }
  `,
  PurpleOutlined: css`
    background-color: ${({ theme }) => theme.colors.White};
    border: 1px solid ${({ theme }) => theme.colors.AccentPurple};
    color: ${({ theme }) => theme.colors.AccentPurple};
    fill: ${({ theme }) => theme.colors.PrimaryDefault};

    &:hover {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.TextDarkGrey};
      fill: ${({ theme }) => theme.colors.PrimaryHover};
      border: 1px solid ${({ theme }) => theme.colors.PrimaryHover};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.PrimaryDefault};
      background-color: ${({ theme }) => theme.colors.Grey1};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.TextDarkGrey};
      fill: ${({ theme }) => theme.colors.PrimaryClick};
    }
    &:disabled {
      cursor: default;
      border: 1px solid ${({ theme }) => theme.colors.Grey4};
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.Grey4};
        background-color: ${({ theme }) => theme.colors.White};
        cursor: not-allowed;
      }
    }
  `,
  PurplePillOutlined: css`
    background-color: ${({ theme }) => theme.colors.AccentPurple};
    border: 1px solid ${({ theme }) => theme.colors.PrimaryDefault};
    color: ${({ theme }) => theme.colors.TextDarkGrey};
    fill: ${({ theme }) => theme.colors.PrimaryDefault};
    border-radius: 19px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.TextDarkGrey};
      fill: ${({ theme }) => theme.colors.PrimaryHover};
      border: 1px solid ${({ theme }) => theme.colors.PrimaryHover};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.PrimaryDefault};
      background-color: ${({ theme }) => theme.colors.White};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.TextDarkGrey};
      fill: ${({ theme }) => theme.colors.PrimaryClick};
    }
    &:disabled {
      cursor: default;
      border: 1px solid ${({ theme }) => theme.colors.Grey4};
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.Grey4};
        background-color: ${({ theme }) => theme.colors.White};
        cursor: not-allowed;
      }
    }
  `,
  GreenOutlined: css`
    background-color: ${({ theme }) => theme.colors.White};
    border: 1px solid ${({ theme }) => theme.colors.SuccessDefault};
    color: ${({ theme }) => theme.colors.SuccessDefault};
    fill: ${({ theme }) => theme.colors.SuccessDefault};
    &:focus {
      background-color: ${({ theme }) => theme.colors.Grey1};
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.SuccessDefault};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.Grey1};
      border: 1px solid ${({ theme }) => theme.colors.SuccessDefault};
      color: ${({ theme }) => theme.colors.SuccessDefault};
      fill: ${({ theme }) => theme.colors.SuccessDefault};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.SuccessClick};
      fill: ${({ theme }) => theme.colors.SuccessClick};
    }
    &:disabled {
      cursor: default;
      border: 1px solid ${({ theme }) => theme.colors.Grey4};
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.Grey3};
        background-color: ${({ theme }) => theme.colors.White};
        cursor: not-allowed;
      }
    }
  `,
  RedOutlined: css`
    background-color: ${({ theme }) => theme.colors.White};
    border: 1px solid ${({ theme }) => theme.colors.ErrorDefault};
    color: ${({ theme }) => theme.colors.ErrorDefault};
    fill: ${({ theme }) => theme.colors.ErrorDefault};
    &:focus {
      background-color: ${({ theme }) => theme.colors.Grey1};
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.ErrorDefault};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.Grey1};
      border: 1px solid ${({ theme }) => theme.colors.ErrorDefault};
      color: ${({ theme }) => theme.colors.ErrorHover};
      fill: ${({ theme }) => theme.colors.ErrorHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.ErrorClick};
      fill: ${({ theme }) => theme.colors.ErrorClick};
    }
    &:disabled {
      cursor: default;
      border: 1px solid ${({ theme }) => theme.colors.Grey4};
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.Grey3};
        background-color: ${({ theme }) => theme.colors.White};
        cursor: not-allowed;
      }
    }
  `,
  PurpleText: css`
    background: none;
    background-color: ${({ theme }) => theme.colors.Transparent};
    color: ${({ theme }) => theme.colors.PrimaryDefault};
    fill: ${({ theme }) => theme.colors.PrimaryDefault};
    text-decoration: underline;
    &:focus {
      background: none;
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.TextHighlight2};
      color: ${({ theme }) => theme.colors.PrimaryDefault};
      fill: ${({ theme }) => theme.colors.PrimaryDefault};
    }
    &:hover {
      background: none;
      color: ${({ theme }) => theme.colors.PrimaryHover};
      fill: ${({ theme }) => theme.colors.PrimaryHover};
    }
    &:active {
      color: ${({ theme }) => theme.colors.PrimaryClick};
      fill: ${({ theme }) => theme.colors.PrimaryClick};
    }
    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        cursor: not-allowed;
      }
    }
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    min-height: auto;
    height: auto;
    width: fit-content !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
  `,
  GreenText: css`
    background: none;
    background-color: ${({ theme }) => theme.colors.Transparent};
    color: ${({ theme }) => theme.colors.SuccessDefault};
    fill: ${({ theme }) => theme.colors.SuccessDefault};
    text-decoration: underline;
    &:focus {
      background: none;
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.SuccessDefault};
      color: ${({ theme }) => theme.colors.SuccessDefault};
      fill: ${({ theme }) => theme.colors.SuccessDefault};
    }
    &:hover {
      background: none;
      color: ${({ theme }) => theme.colors.SuccessDefault};
      fill: ${({ theme }) => theme.colors.SuccessDefault};
    }
    &:active {
      color: ${({ theme }) => theme.colors.SuccessClick};
      fill: ${({ theme }) => theme.colors.SuccessClick};
    }
    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        cursor: not-allowed;
      }
    }
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    min-height: auto;
    height: auto;
    width: fit-content !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
  `,
  RedText: css`
    background: none;
    background-color: ${({ theme }) => theme.colors.Transparent};
    color: ${({ theme }) => theme.colors.ErrorDefault};
    fill: ${({ theme }) => theme.colors.ErrorDefault};
    text-decoration: underline;
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    &:focus {
      background: none;
      box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.ErrorDefault};
      color: ${({ theme }) => theme.colors.ErrorDefault};
      fill: ${({ theme }) => theme.colors.ErrorDefault};
    }
    &:hover {
      background: none;
      color: ${({ theme }) => theme.colors.ErrorHover};
      fill: ${({ theme }) => theme.colors.ErrorHover};
    }
    &:active {
      color: ${({ theme }) => theme.colors.ErrorClick};
      fill: ${({ theme }) => theme.colors.ErrorClick};
    }
    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.colors.Grey4};
      fill: ${({ theme }) => theme.colors.Grey4};
      &:hover {
        cursor: not-allowed;
      }
    }
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    min-height: auto;
    height: auto;
    width: fit-content !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
  `,
  PurpleNoDecorations: css`
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    color: ${({ theme }) => theme.colors.AccentPurple};
    background-color: ${({ theme }) => theme.colors.Transparent};
    padding: 0;
    min-height: auto;
    height: auto;
  `,
  UnderlinedTab: css`
    padding: 0;
    margin: 0;
    border: none;
    border-bottom: 4px solid ${({ theme }) => theme.colors.AccentGreen};
    border-radius: 0;
    color: ${({ theme }) => theme.colors.TextDarkGrey};
    background-color: ${({ theme }) => theme.colors.White};
    &:focus {
      background-color: ${({ theme }) => theme.colors.Grey1};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.PrimaryHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.Grey1};
      color: ${({ theme }) => theme.colors.PrimaryClick};
    }
    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.White};
      color: ${({ theme }) => theme.colors.TextDisabled};
      &:hover {
        background-color: ${({ theme }) => theme.colors.Grey3};
        cursor: not-allowed;
      }
    }
  `,
  NoStyles: css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: ${({ theme }) => theme.colors.PrimaryDefault};
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    &:hover {
      color: ${({ theme }) => theme.colors.PrimaryHover};
    }
    &:active {
      color: ${({ theme }) => theme.colors.PrimaryClick};
    }
    &:disabled {
      cursor: default;
      color: ${({ theme }) => theme.colors.TextDisabled};
      &:hover {
        cursor: not-allowed;
      }
    }
  `,
};
