/* eslint-disable import/no-anonymous-default-export */
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import {
  InviteLabel,
  InviteText,
  InviteTitle,
  InviteViewWrapper,
} from 'components/InviteTeamMembersModal/InviteTeamMembers.styled';
import { InviteTeamMemberModalTemplate } from 'components/InviteTeamMembersModal/InviteTeamMembersModalTemplate';
import { LocationFilterInput } from 'components/LocationFilterInput';
import React, { useContext, useEffect } from 'react';
import { SuccessView } from '../SuccessView';
import { SMSViewContext, SMSViewContextProvider } from './SMSView.context';

const SMSView: React.FC = () => {
  const {
    formValues,
    flowView,
    // errorIndices,
    setErrorIndices,
    error,
    setError,
    onChange,
    onSubmit,
    setNumInvites,
  } = useContext(SMSViewContext);

  useEffect(() => {
    let errors = new Set<number>();
    let errorMessage = '';
    // Check phone number formats
    const regex = /\(\d{3}\) \d{3}-\d{4}/;
    formValues?.phoneNumbers?.forEach((phoneNumber: string, index: number) => {
      if (!regex.test(phoneNumber)) {
        errors.add(index);
        errorMessage = 'Please make sure phone numbers are in the correct format.';
      }
    });

    // Check for duplicates
    let dupes: { [key: string]: number[] } = {};
    formValues?.phoneNumbers?.forEach((phoneNumber: string, index: number) => {
      dupes[phoneNumber] = dupes[phoneNumber] || [];
      dupes[phoneNumber].push(index);
    });
    for (const phoneNumber in dupes) {
      if (dupes[phoneNumber].length > 1) {
        dupes[phoneNumber].forEach((index) => {
          errors.add(index);
        });
        errorMessage = 'Please remove any duplicate phone numbers.';
      }
    }
    setError(errorMessage);
    setErrorIndices(errors);
    setNumInvites(formValues?.phoneNumber?.length);
  }, [
    formValues.phoneNumbers,
    formValues?.phoneNumber?.length,
    setErrorIndices,
    setError,
    setNumInvites,
  ]);

  switch (flowView) {
    case 'success':
      return <SuccessView view="SMSView" />;
    default:
      return (
        <InviteTeamMemberModalTemplate>
          <InviteViewWrapper flexDirection="column" childMarginBottom="32px">
            <InviteTitle>Invite via Text Message</InviteTitle>
            <InviteText>
              Enter team members' mobile numbers and select Invite. <br />
              Users will be invited to download the BookJane app and setup their accounts.
            </InviteText>
            <Flex flexDirection="column" childMarginBottom="8px" width="100%">
              <InviteLabel>Facility</InviteLabel>
              <LocationFilterInput
                value={formValues.location}
                onChange={(e: ChangeEventType) => onChange('facility', e.target.value)}
                width="100%"
              />
            </Flex>
            <Flex flexDirection="column" childMarginBottom="8px">
              <InviteLabel>Phone Numbers</InviteLabel>
              {/* <BKJPillInput
                name="phone-numbers"
                value={formValues.phoneNumbers ? formValues.phoneNumbers : []}
                setValue={(value: string[]) => onChange('phoneNumbers', value)}
                errorIndices={Array.from(errorIndices)}
                error={error}
              /> */}
            </Flex>
          </InviteViewWrapper>
          <BKJSideModalFooter>
            <AutoMargin />
            <BKJButton
              width="180px"
              variant="GreenSolid"
              onClick={onSubmit}
              disabled={!!error || !(formValues?.phoneNumbers?.length > 0 && formValues.facility)}
            >
              Invite
            </BKJButton>
          </BKJSideModalFooter>
        </InviteTeamMemberModalTemplate>
      );
  }
};

export default () => (
  <SMSViewContextProvider>
    <SMSView />
  </SMSViewContextProvider>
);
