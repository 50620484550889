import { useRef, useEffect } from 'react';

export function useIntersectionObserver(
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
) {
  const targetRef = useRef<Element | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!targetRef.current) return;
    if (observerRef.current) observerRef.current = null;
    observerRef.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[], observer) => {
        entries.forEach(async (entry) => {
          if (entry.intersectionRatio > 0) {
            if (callback) await callback(entries, observer);
            observer.unobserve(entry.target);
          }
        });
      },
      options,
    );
    observerRef.current.observe(targetRef.current);
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [targetRef, callback, options]);

  return targetRef;
}
