import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { priceConfig } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberViewDataProvider.constants';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext, useEffect } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchMinMaxPricesByPositionIdDataContext = createGQLDataContext<{
  min: string;
  max: string;
}>(
  {} as {
    min: string;
    max: string;
  },
);

export const FetchMinMaxPricesByPositionIdDataProvider: FC = ({ children }) => {
  const { data: fetchOrderData } = useShiftModalDataContext();
  const { fetch, ...rest } = useGQLQuery(
    priceConfig({ positionId: fetchOrderData.fetchOrder!.position.id || 0 }),
  );

  useEffect(() => {
    if (fetchOrderData.fetchOrder!.position.id) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FetchMinMaxPricesByPositionIdDataContext.Provider value={rest}>
      {children}
    </FetchMinMaxPricesByPositionIdDataContext.Provider>
  );
};

export const useFetchMinMaxPricesByPositionIdDataContext = (): UseGQLQuery<{
  min: string;
  max: string;
}> => useContext(FetchMinMaxPricesByPositionIdDataContext);
