import { CustomFieldTypeEnum } from 'types/graphql-types';

export type CustomFieldType = 'Single-Line Text' | 'Paragraph Text' | 'Single-Select Dropdown';

export const CustomFieldTypeMap: Record<CustomFieldType, keyof typeof CustomFieldTypeEnum> = {
  'Single-Line Text': 'input',
  'Paragraph Text': 'text_area',
  'Single-Select Dropdown': 'dropdown',
};

export const CustomFieldEnumMap: Record<CustomFieldTypeEnum, CustomFieldType> = {
  input: 'Single-Line Text',
  text_area: 'Paragraph Text',
  dropdown: 'Single-Select Dropdown',
};

export interface ICustomField {
  id: number;
  name: string;
  mobile: boolean;
  type: CustomFieldType;
  placeholder: string;
  allFacilities: boolean;
  communityIds: string[];
  preFillDefault?: boolean;
  dropdownOptions?: string[];
}

export type CustomFieldInfoFormSchema = {
  type: CustomFieldType[];
  name: string;
  mobile: boolean;
  id: number;
  placeholder: string;
  preFillDefault: boolean;
  dropdownOptions: string[];
};

export type ApplyFacilitiesFormSchema = {
  communityIds: string[];
  allFacilities: boolean;
};
