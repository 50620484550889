import { usePersistentState } from 'hooks';
import { useTranslation } from 'hooks/useTranslation';
import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react';
interface ISettingsNavigationProps {
  locationsLabel: string;
  isOpen: boolean;
  handleClick: () => void;
}

export const SettingsNavigationPropsContext = createContext<ISettingsNavigationProps>(
  {} as ISettingsNavigationProps,
);

export const SettingsNavigationPropsProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = usePersistentState(true, 'bjk-settings-nav');
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const locationsLabel = t('settings:Locations');

  useEffect(() => {
    if (!window.j360.toggleSettingsNavigationOpen) {
      window.j360.toggleSettingsNavigationOpen = () => {
        setIsOpen(!isOpen);
      };
    }
  }, []); // eslint-disable-line

  const contextValue = useMemo(
    () => ({
      isOpen,
      locationsLabel,
      handleClick,
    }),
    [handleClick, isOpen, locationsLabel],
  );

  return (
    <SettingsNavigationPropsContext.Provider value={contextValue}>
      {children}
    </SettingsNavigationPropsContext.Provider>
  );
};

export const useSettingsNavigationPropsContext = (): ISettingsNavigationProps =>
  useContext(SettingsNavigationPropsContext);
