import {
  Grid,
  ShiftDistributionRulesTableContentCell,
  Text,
  UnderlineButton,
  ShiftDistributionRulesTableContentCellEmptyFooter,
} from 'pages/ShiftDistributionPage/ShiftDistributionPage.styled';

import { Flex } from '@bookjane2/bookjane-design-library';
import { tieringDetailsModalRoutingService } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.ui.context';
import { FC, Fragment, useCallback } from 'react';
import { useShiftDistributionDataContext } from 'pages/ShiftDistributionPage/ShiftDistributionPage.data.context';
import { shiftDistributionRulesGroupsModalRoutingService } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';

const ShiftDistributionRuleComponent: FC = () => {
  const {
    data: {
      fetchCommunity: { communityFeatures },
    },
    sharingCommunities,
  } = useShiftDistributionDataContext();

  const { t } = useTranslation();

  const isShiftBiddingFeatureEnabled = useSelector(isShiftBiddingFeatureEnabledSelector);

  const isFeatureOn = useCallback(
    (Feature: string) => {
      const enabledFeatures =
        communityFeatures?.filter(
          (communityFeature) =>
            communityFeature?.feature?.name === Feature && communityFeature?.feature?.enabled,
        ) || [];
      return enabledFeatures.length > 0;
    },
    [communityFeatures],
  );

  const nrOfSharingCommunities = sharingCommunities ? sharingCommunities.length : 0;

  return (
    <Fragment>
      <Grid gridTemplateColumns="3">
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {t('location_settings:SEND_TO_AVAILABLE')}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell
          data-cy="read-position-name"
          isGrey={true}
        ></ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {isFeatureOn('AVAILABILITY') ? t('location_settings:YES') : t('location_settings:NO')}
        </ShiftDistributionRulesTableContentCell>
      </Grid>
      <Grid gridTemplateColumns="3">
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={false}>
          {t('location_settings:TIER_BETWEEN')}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={false}>
          {isFeatureOn('TIERING') && (
            <Flex flexDirection="column" alignItems="flex-start">
              <Text>
                {t('location_settings:TYPE')}
                {': '}
                {isFeatureOn('ROTATIONAL')
                  ? t('location_settings:ROTATIONAL')
                  : t('location_settings:STANDARD')}
              </Text>
              <UnderlineButton
                onClick={() => tieringDetailsModalRoutingService.open({}, 'TieringDetails')}
              >
                {t('common:VIEW_DETAILS')}
              </UnderlineButton>
            </Flex>
          )}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={false}>
          {isFeatureOn('TIERING') ? t('location_settings:YES') : t('location_settings:NO')}
        </ShiftDistributionRulesTableContentCell>
      </Grid>
      <Grid gridTemplateColumns="3">
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {t('location_settings:SHARE_SHIFTS')}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {isFeatureOn('SHARING') && (
            <Flex flexDirection="column" alignItems="flex-start">
              <Text>{`${nrOfSharingCommunities} ${t('location_settings:LOCATIONS')}`}</Text>
              <UnderlineButton
                onClick={() => tieringDetailsModalRoutingService.open({}, 'SharingDetails')}
              >
                {t('common:VIEW_DETAILS')}
              </UnderlineButton>
            </Flex>
          )}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {isFeatureOn('SHARING') ? t('location_settings:YES') : t('location_settings:NO')}
        </ShiftDistributionRulesTableContentCell>
      </Grid>
      <Grid gridTemplateColumns="3">
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={false}>
          {t('location_settings:SEND_SHIFTS')}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={false}>
          {isFeatureOn('GROUPS') && (
            <Flex flexDirection="column" alignItems="flex-start">
              <UnderlineButton
                onClick={() =>
                  shiftDistributionRulesGroupsModalRoutingService.open({}, 'GroupsRules')
                }
              >
                {t('common:VIEW_DETAILS')}
              </UnderlineButton>
            </Flex>
          )}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={false}>
          {isFeatureOn('GROUPS') ? t('location_settings:YES') : t('location_settings:NO')}
        </ShiftDistributionRulesTableContentCell>
      </Grid>
      <Grid gridTemplateColumns="3">
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {t('location_settings:SHIFT_BIDDING_RULES')}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {isShiftBiddingFeatureEnabled && (
            <Flex flexDirection="column" alignItems="flex-start">
              <UnderlineButton
                onClick={() => tieringDetailsModalRoutingService.open({}, 'ShiftBiddingDetails')}
              >
                {t('common:VIEW_DETAILS')}
              </UnderlineButton>
            </Flex>
          )}
        </ShiftDistributionRulesTableContentCell>
        <ShiftDistributionRulesTableContentCell data-cy="read-position-name" isGrey={true}>
          {isShiftBiddingFeatureEnabled ? t('location_settings:YES') : t('location_settings:NO')}
        </ShiftDistributionRulesTableContentCell>
      </Grid>
    </Fragment>
  );
};

const ShiftDistributionRuleTable = () => {
  return (
    <Fragment>
      <ShiftDistributionRuleComponent />
      <Grid>
        <ShiftDistributionRulesTableContentCellEmptyFooter />
      </Grid>
    </Fragment>
  );
};
export default ShiftDistributionRuleTable;
