import { BKJCheckbox, BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { BodyS } from 'assets/scheduling/typography';
import { css } from 'styled-components';
import {
  PreferenceRow,
  PreferencesContainer,
} from 'pages/SettingsPage/AdministratorSettingsPage/LocationPageAdministratorSettings.styled';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJForm } from 'components/FormWrapper';
import { Flex } from '@bookjane2/bookjane-design-library';
import { FC, Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import {
  AdministratorSettingsDataProvider,
  useAdministratorSettingsDataContext,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import {
  LocationPageFromAdministratorSettingsProvider,
  useLocationPageFormAdministratorSettingsContext,
} from 'pages/SettingsPage/AdministratorSettingsPage/LocationPageFormAdministratorSettings.form.context';
import { useParams } from 'react-router-dom';
import { H3 } from 'components/Typography';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { AdministratorSettingsLoadingSpinner } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettingsLoadingSpinner';
const Component: FC = () => {
  const { t } = useTranslation();

  const { onSubmit, onChange, apiErrors, isSubmitting, isDisabled, values } =
    useLocationPageFormAdministratorSettingsContext();

  const [checkAllAG, setCheckAllAG] = useState<boolean>(false);
  const [checkAllSE, setCheckAllSE] = useState<boolean>(false);

  useEffect(() => {
    let allCheckedAG = true;
    let allCheckedSE = true;
    values.communities.forEach((item) => {
      if (!item.isIncluded) {
        allCheckedAG = false;
      }
      if (!item.shift_emails) {
        allCheckedSE = false;
      }
    });
    setCheckAllAG(allCheckedAG);
    setCheckAllSE(allCheckedSE);
  }, [values]);

  function handleChangeAG(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    values.communities[index].isIncluded = !values.communities[index].isIncluded;
  }
  function handleChangeSE(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    values.communities[index].shift_emails = !values.communities[index].shift_emails;
  }

  return (
    <Fragment>
      <div>
        <H3>{`${values.first_name} ${values.last_name}`}</H3>

        <BodyS
          style={{
            paddingTop: '18px',
            paddingBottom: '12px',
          }}
        >
          {t('settings:SELECT_THE_LOCATIONS')}
        </BodyS>
        <PreferencesContainer>
          <BKJForm
            data-cy="formSettingsValidation"
            onSubmit={onSubmit}
            width="100%"
            maxWidth="100%"
            gap="22px"
          >
            <Flex flexDirection="column">
              <Flex justifyContent="space-between" alignItems="center" padding="20px">
                <Flex
                  style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#45424E',
                  }}
                  width="50%"
                >
                  {t('settings:LOCATION')}
                </Flex>

                <Flex width="25%" justifyContent="center" alignItems="center">
                  {t('settings:ACCESS_GRANTED')}
                </Flex>
                <Flex width="25%" justifyContent="center" alignItems="center">
                  <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                    {t('settings:SHIFT_EMAIL_NOTIFICATIONS')}
                  </UserGuard>
                </Flex>
                <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                  <BKJIconTooltip iconName="Info" placement="top">
                    {t('settings:ENABLE_RECEIVE_EMAIL')}
                  </BKJIconTooltip>
                </UserGuard>
              </Flex>

              <Flex
                justifyContent="space-between"
                alignItems="center"
                padding="20px"
                style={{
                  backgroundColor: '#f6f7fb',
                }}
              >
                <Flex width="50%">{t('settings:ALL_LOCATIONS')}</Flex>

                <Flex width="25%" justifyContent="center" alignItems="center">
                  <BKJCheckbox
                    variant="GreenSolid"
                    value={checkAllAG}
                    onChange={() => {
                      setCheckAllAG(!checkAllAG);
                      values.communities.forEach((item) => {
                        item.isIncluded = !checkAllAG;
                      });
                    }}
                  />
                </Flex>
                <Flex width="25%" justifyContent="center" alignItems="center">
                  <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                    <BKJCheckbox
                      variant="GreenSolid"
                      onChange={() => {
                        setCheckAllSE(!checkAllSE);
                        values.communities.forEach((item) => {
                          item.shift_emails = !checkAllSE;
                        });
                      }}
                      value={checkAllAG ? checkAllSE : false}
                      disabled={!checkAllAG}
                    />
                  </UserGuard>
                </Flex>
              </Flex>
              {values &&
                values.communities.map((item, index) => {
                  return (
                    <PreferenceRow key={item.id}>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        key={item.id}
                        padding="20px"
                      >
                        <Flex width="50%">{item.name}</Flex>

                        <Flex width="25%" justifyContent="center" alignItems="center">
                          <BKJCheckbox
                            variant="GreenSolid"
                            value={item.isIncluded}
                            name={`c.${index}.isIncluded`}
                            onChange={(e) => {
                              handleChangeAG(e, index);
                              onChange(e);
                            }}
                          />
                        </Flex>
                        <Flex width="25%" justifyContent="center" alignItems="center">
                          <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                            <BKJCheckbox
                              variant="GreenSolid"
                              value={item.isIncluded ? item.shift_emails : false}
                              name={`c.${index}.shift_emails`}
                              onChange={(e) => {
                                handleChangeSE(e, index);
                                onChange(e);
                              }}
                              disabled={!item.isIncluded}
                            />
                          </UserGuard>
                        </Flex>
                      </Flex>
                    </PreferenceRow>
                  );
                })}
            </Flex>
            {apiErrors && <span>{apiErrors.message}</span>}
            <BKJButton
              type="submit"
              variant="GreenSolid"
              width="192px"
              disabled={isDisabled}
              isLoading={isSubmitting}
              data-cy="btn-save"
              css={css`
                margin: 1em;
              `}
            >
              {t('auth:SAVE')}
            </BKJButton>
          </BKJForm>
        </PreferencesContainer>
      </div>
    </Fragment>
  );
};

export const LocationAdministratorSettingPageFormView: FC = () => {
  const data = useAdministratorSettingsDataContext();
  if (!data || !data?.secondary_users || !data?.communities || !data?.communities?.length)
    return <AdministratorSettingsLoadingSpinner />;

  return (
    <LocationPageFromAdministratorSettingsProvider>
      <Component />
    </LocationPageFromAdministratorSettingsProvider>
  );
};

export const LocationAdministratorSettingPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <AdministratorSettingsDataProvider key={id}>
      <LocationAdministratorSettingPageFormView />
    </AdministratorSettingsDataProvider>
  );
};
