import { createSelector } from 'reselect';
import { IGlobalReduxStore } from 'store';
import { isUserObjectAgencyType, isUserObjectB2bType } from 'types/common.types';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

const isUserEssentialPrimarySelector = createSelector([getUser], (user) => {
  if (!user) return false;
  if (isUserObjectB2bType(user))
    return user.user_type === 'B2bCustomer' && user.user_role === 'essential';
  if (isUserObjectAgencyType(user)) return false;
  return false;
});

export default isUserEssentialPrimarySelector;
