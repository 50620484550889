import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';
import { QUERY_shiftDistributionRulesGroups } from 'queries';
import { shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes } from 'types/graphql-types';

export interface GroupsRulesData {
  groupsRules: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes[];
}

const groupsRulesApolloClient = createApolloClient({ cache: createApolloCache() });

export const groupsRulesConfig = (locationId: number): ISSFBehaviorsOptions => {
  return {
    key: 'SHIFT_DISTRIBUTION_RULES_GROUPS_QUERY',
    query: QUERY_shiftDistributionRulesGroups,
    paginationType: 'InfiniteScroll',
    pageSize: 7,
    nextFetchPolicy: 'network-only',
    client: groupsRulesApolloClient,
    schema: {
      locationId: {
        initialValue: locationId,
        isSessionCached: true,
      },
    },
    payloadTransformer: (values) => ({
      ...values,
      filter: {
        forCommunities: {
          withId: [locationId],
        },
      },
    }),
  };
};
