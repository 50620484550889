import { captureException } from 'utils/captureException';

/**
 * @description object containing utility methods to access localStorage.
 */
export const localPersistenceService = {
  setItem: (key: string, value: string): void => {
    window.localStorage.setItem(key, value);
    return void 0;
  },

  getItem: (key: string): string | undefined => {
    return window.localStorage.getItem(key) || undefined;
  },
  /**
   * @description Method to retrieve and parse values in localStorage.
   * @param key The key of the value in localStorage.
   * @param fallbackValue The fallback value if the key has an undefined value OR if there is an exception thrown when applying JSON.parse
   */
  getParsedItem: function <T = unknown>(key: string, fallbackValue: T): T {
    const value = window.localStorage.getItem(key) || undefined;
    try {
      const parsed = JSON.parse(value!);
      return parsed as T;
    } catch (error) {
      captureException(error as Error, {
        tags: { type: 'JSONParseError' },
        extra: {
          key,
          value: value || 'undefined',
        },
      });
      return fallbackValue;
    }
  },

  removeItem: (key: string): void => {
    window.localStorage.removeItem(key);
    return void 0;
  },
};
