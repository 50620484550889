/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MUTATION_assignAgencyTeamMemberAssignOrder
// ====================================================

export interface MUTATION_assignAgencyTeamMemberAssignOrder_assignOrder {
  clientMutationId: string | null; // A unique identifier for the client performing the mutation.
  errors: string[] | null;
  status: MutationOutcomeStatusEnum;
}

export interface MUTATION_assignAgencyTeamMemberAssignOrder {
  assignOrder: MUTATION_assignAgencyTeamMemberAssignOrder_assignOrder; // agency users assign orders to a given jane
}

export interface MUTATION_assignAgencyTeamMemberAssignOrderVariables {
  orderId: string;
  janeId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MUTATION_deleteTimeOffById
// ====================================================

export interface MUTATION_deleteTimeOffById_deleteTimeOff {
  clientMutationId: string | null; // A unique identifier for the client performing the mutation.
  errors: string[] | null;
  status: MutationOutcomeStatusEnum;
}

export interface MUTATION_deleteTimeOffById {
  deleteTimeOff: MUTATION_deleteTimeOffById_deleteTimeOff | null; // delete a time off by id
}

export interface MUTATION_deleteTimeOffByIdVariables {
  timeOffId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MUTATION_manuallyApproveCallout
// ====================================================

export interface MUTATION_manuallyApproveCallout_approveCallout {
  clientMutationId: string | null; // A unique identifier for the client performing the mutation.
  errors: string[] | null;
  status: MutationOutcomeStatusEnum;
}

export interface MUTATION_manuallyApproveCallout {
  approveCallout: MUTATION_manuallyApproveCallout_approveCallout | null; // manually approve the callout to continue callout escalation
}

export interface MUTATION_manuallyApproveCalloutVariables {
  calloutListId: string;
  enableOvertime?: EnableOvertimeStrategyEnum | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QUERY_agencyFilter
// ====================================================

export interface QUERY_agencyFilter_fetchAgencies_nodes {
  name: string;
  id: number;
}

export interface QUERY_agencyFilter_fetchAgencies {
  nodes: QUERY_agencyFilter_fetchAgencies_nodes[]; // A list of nodes.
}

export interface QUERY_agencyFilter {
  fetchAgencies: QUERY_agencyFilter_fetchAgencies;
}

export interface QUERY_agencyFilterVariables {
  byName?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: agencyMemberPage
// ====================================================

export interface agencyMemberPage_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface agencyMemberPage_fetchJanes_edges_node_agencyCommunity {
  name: string;
}

export interface agencyMemberPage_fetchJanes_edges_node_avatarUrls {
  origin: string | null;
}

export interface agencyMemberPage_fetchJanes_edges_node_primaryPosition_details {
  name: string;
}

export interface agencyMemberPage_fetchJanes_edges_node_primaryPosition {
  details: agencyMemberPage_fetchJanes_edges_node_primaryPosition_details;
}

export interface agencyMemberPage_fetchJanes_edges_node_positions_details {
  name: string;
}

export interface agencyMemberPage_fetchJanes_edges_node_positions {
  details: agencyMemberPage_fetchJanes_edges_node_positions_details;
}

export interface agencyMemberPage_fetchJanes_edges_node_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface agencyMemberPage_fetchJanes_edges_node_accessStatus {
  name: AccessStatusEnum;
}

export interface agencyMemberPage_fetchJanes_edges_node {
  id: string;
  firstName: string;
  lastName: string;
  agencyCommunity: agencyMemberPage_fetchJanes_edges_node_agencyCommunity | null;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: agencyMemberPage_fetchJanes_edges_node_avatarUrls;
  primaryPosition: agencyMemberPage_fetchJanes_edges_node_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  positions: agencyMemberPage_fetchJanes_edges_node_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
  employmentStatus: agencyMemberPage_fetchJanes_edges_node_employmentStatus;
  phone: string | null;
  email: string;
  accessStatus: agencyMemberPage_fetchJanes_edges_node_accessStatus;
}

export interface agencyMemberPage_fetchJanes_edges {
  node: agencyMemberPage_fetchJanes_edges_node | null; // The item at the end of the edge.
}

export interface agencyMemberPage_fetchJanes {
  pageInfo: agencyMemberPage_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: agencyMemberPage_fetchJanes_edges[]; // A list of edges.
  pageCount: number;
}

export interface agencyMemberPage {
  fetchJanes: agencyMemberPage_fetchJanes; // find all janes
}

export interface agencyMemberPageVariables {
  after?: string | null;
  before?: string | null;
  external?: boolean | null;
  filter?: FilterableJaneAttributes | null;
  first?: number | null;
  last?: number | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId
// ====================================================

export interface assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId_fetchOrder {
  id: number;
  canAssign: boolean;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId_requestJanes_nodes {
  id: string;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId_requestJanes {
  nodes: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId_requestJanes_nodes[]; // A list of nodes.
}

export interface assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId {
  fetchOrder: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId_fetchOrder; // Find an order by ID
  requestJanes: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId_requestJanes;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderIdVariables {
  janeId: string;
  orderRelayId?: string | null;
  orderId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assignToAgencyTeamMemberFetchAgencyJaneById
// ====================================================

export interface assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane_agencyCommunity_agency {
  name: string;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane_agencyCommunity {
  agency: assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane_agencyCommunity_agency;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane {
  relayId: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane_avatarUrls;
  agencyCommunity: assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane_agencyCommunity | null;
}

export interface assignToAgencyTeamMemberFetchAgencyJaneById {
  fetchJane: assignToAgencyTeamMemberFetchAgencyJaneById_fetchJane; // find a single jane
}

export interface assignToAgencyTeamMemberFetchAgencyJaneByIdVariables {
  janeId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assignToAgencyTeamMemberFetchAgencyJanes
// ====================================================

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_agencyCommunity_agency {
  name: string;
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_agencyCommunity {
  agency: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_agencyCommunity_agency;
  name: string;
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_customerReviews {
  averageRating: number | null;
  count: number;
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_avatarUrls {
  origin: string | null;
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_positions_details {
  janePayout: any; // the amount a jane is paid for an open shift
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_positions {
  details: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_positions_details;
  agencyDetails: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_positions_agencyDetails | null;
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes {
  agencyCommunity: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_agencyCommunity | null;
  id: string;
  firstName: string;
  lastName: string;
  customerReviews: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_customerReviews;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_avatarUrls;
  positions: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface assignToAgencyTeamMemberFetchAgencyJanes_requestJanes {
  pageInfo: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_pageInfo; // Information to aid in pagination.
  nodes: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes_nodes[]; // A list of nodes.
}

export interface assignToAgencyTeamMemberFetchAgencyJanes {
  requestJanes: assignToAgencyTeamMemberFetchAgencyJanes_requestJanes;
}

export interface assignToAgencyTeamMemberFetchAgencyJanesVariables {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  byFullName?: string | null;
  byPositionId?: number | null;
  orderId?: string | null;
  byAgencyCommunityId?: number[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assignToAgencyTeamMemberFetchAgencyJanesCount
// ====================================================

export interface assignToAgencyTeamMemberFetchAgencyJanesCount_requestJanes {
  totalCount: number;
}

export interface assignToAgencyTeamMemberFetchAgencyJanesCount {
  requestJanes: assignToAgencyTeamMemberFetchAgencyJanesCount_requestJanes;
}

export interface assignToAgencyTeamMemberFetchAgencyJanesCountVariables {
  orderId?: string | null;
  byAgencyCommunityId?: number[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: availableShiftsMapView
// ====================================================

export interface availableShiftsMapView_aggregateOrdersByLocation_group {
  communityId: number;
  latitude: number;
  longitude: number;
}

export interface availableShiftsMapView_aggregateOrdersByLocation {
  group: availableShiftsMapView_aggregateOrdersByLocation_group; // Group containing a facility with its co-ordinates
  count: number;
}

export interface availableShiftsMapView {
  aggregateOrdersByLocation: availableShiftsMapView_aggregateOrdersByLocation[];
}

export interface availableShiftsMapViewVariables {
  filter: FilterableOrderAttributes;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: availableTeamMembers
// ====================================================

export interface availableTeamMembers_fetchOrder_community_location {
  timeZone: string | null;
}

export interface availableTeamMembers_fetchOrder_community {
  location: availableTeamMembers_fetchOrder_community_location | null;
}

export interface availableTeamMembers_fetchOrder_orderAvailabilities_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface availableTeamMembers_fetchOrder_orderAvailabilities_nodes_jane_avatarUrls {
  origin: string | null;
}

export interface availableTeamMembers_fetchOrder_orderAvailabilities_nodes_jane {
  id: string;
  shorthandName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: availableTeamMembers_fetchOrder_orderAvailabilities_nodes_jane_avatarUrls;
  belongsToPool: boolean; // This boolean field is used to show the tick on the Agency Members
}

export interface availableTeamMembers_fetchOrder_orderAvailabilities_nodes {
  id: string;
  jane: availableTeamMembers_fetchOrder_orderAvailabilities_nodes_jane;
  createdAt: any;
}

export interface availableTeamMembers_fetchOrder_orderAvailabilities {
  pageInfo: availableTeamMembers_fetchOrder_orderAvailabilities_pageInfo; // Information to aid in pagination.
  nodes: availableTeamMembers_fetchOrder_orderAvailabilities_nodes[]; // A list of nodes.
}

export interface availableTeamMembers_fetchOrder {
  community: availableTeamMembers_fetchOrder_community;
  orderAvailabilities: availableTeamMembers_fetchOrder_orderAvailabilities;
}

export interface availableTeamMembers {
  fetchOrder: availableTeamMembers_fetchOrder; // Find an order by ID
}

export interface availableTeamMembersVariables {
  id: number;
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: b2bDepartmentsNavigationFetchDepartments
// ====================================================

export interface b2bDepartmentsNavigationFetchDepartments_me_AgencyUser {}

export interface b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer_serviceablePositions_department {
  id: number;
  name: string;
}

export interface b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer_serviceablePositions {
  department: b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer_serviceablePositions_department | null;
}

export interface b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer {
  serviceablePositions: b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer_serviceablePositions[];
}

export interface b2bDepartmentsNavigationFetchDepartments_me_SecondaryUser_serviceablePositions_department {
  id: number;
  name: string;
}

export interface b2bDepartmentsNavigationFetchDepartments_me_SecondaryUser_serviceablePositions {
  department: b2bDepartmentsNavigationFetchDepartments_me_SecondaryUser_serviceablePositions_department | null;
}

export interface b2bDepartmentsNavigationFetchDepartments_me_SecondaryUser {
  serviceablePositions: b2bDepartmentsNavigationFetchDepartments_me_SecondaryUser_serviceablePositions[];
}

export type b2bDepartmentsNavigationFetchDepartments_me =
  | b2bDepartmentsNavigationFetchDepartments_me_AgencyUser
  | b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer
  | b2bDepartmentsNavigationFetchDepartments_me_SecondaryUser;

export interface b2bDepartmentsNavigationFetchDepartments {
  me: b2bDepartmentsNavigationFetchDepartments_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: b2bPositionsSettingsPageFetchPositionsByDepartmentId
// ====================================================

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_AgencyUser {}

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer_serviceablePositions_department {
  id: number;
  name: string;
}

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer_serviceablePositions {
  department: b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer_serviceablePositions_department | null;
  name: string;
  id: number;
  openShiftPolicy: OpenShiftPolicyEnum;
}

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer {
  serviceablePositions: b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer_serviceablePositions[];
}

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_SecondaryUser_serviceablePositions_department {
  id: number;
  name: string;
}

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_SecondaryUser_serviceablePositions {
  department: b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_SecondaryUser_serviceablePositions_department | null;
  name: string;
  id: number;
  openShiftPolicy: OpenShiftPolicyEnum;
}

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_SecondaryUser {
  serviceablePositions: b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_SecondaryUser_serviceablePositions[];
}

export type b2bPositionsSettingsPageFetchPositionsByDepartmentId_me =
  | b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_AgencyUser
  | b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer
  | b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_SecondaryUser;

export interface b2bPositionsSettingsPageFetchPositionsByDepartmentId {
  me: b2bPositionsSettingsPageFetchPositionsByDepartmentId_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: communityBudgetAllocationsForPosition
// ====================================================

export interface communityBudgetAllocationsForPosition_fetchCommunity_activeBudgetAllocation {
  id: string;
  startDate: any;
  occupancy: number;
  morningErrorMargin: number;
  morningRatio: number | null;
  eveningErrorMargin: number;
  eveningRatio: number | null;
  nightErrorMargin: number;
  nightRatio: number | null;
}

export interface communityBudgetAllocationsForPosition_fetchCommunity_nextBudgetAllocation {
  id: string;
  startDate: any;
  occupancy: number;
  morningErrorMargin: number;
  morningRatio: number | null;
  eveningErrorMargin: number;
  eveningRatio: number | null;
  nightErrorMargin: number;
  nightRatio: number | null;
}

export interface communityBudgetAllocationsForPosition_fetchCommunity {
  __typename: 'Community';
  displayId: string;
  name: string;
  activeBudgetAllocation: communityBudgetAllocationsForPosition_fetchCommunity_activeBudgetAllocation | null;
  nextBudgetAllocation: communityBudgetAllocationsForPosition_fetchCommunity_nextBudgetAllocation | null;
}

export interface communityBudgetAllocationsForPosition {
  fetchCommunity: communityBudgetAllocationsForPosition_fetchCommunity; // find a Community by ID
}

export interface communityBudgetAllocationsForPositionVariables {
  locationId: number;
  positionId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: communityCustomFields
// ====================================================

export interface communityCustomFields_fetchCustomFields_nodes_customFieldOptions {
  __typename: 'CustomFieldOption';
  value: string;
}

export interface communityCustomFields_fetchCustomFields_nodes_communities {
  __typename: 'Community';
  name: string;
}

export interface communityCustomFields_fetchCustomFields_nodes_organization {
  __typename: 'Organization';
  name: string;
}

export interface communityCustomFields_fetchCustomFields_nodes_activities {
  __typename: 'Activity';
  event: ActivityEventEnum;
  createdAt: any;
}

export interface communityCustomFields_fetchCustomFields_nodes {
  __typename: 'CustomField';
  id: number;
  name: string;
  mobile: boolean;
  fieldType: CustomFieldTypeEnum;
  placeholder: string;
  prefillDefault: boolean;
  createdAt: any | null;
  lastEditedAt: any | null;
  customFieldOptions: communityCustomFields_fetchCustomFields_nodes_customFieldOptions[] | null;
  communities: communityCustomFields_fetchCustomFields_nodes_communities[] | null;
  organization: communityCustomFields_fetchCustomFields_nodes_organization;
  activities: communityCustomFields_fetchCustomFields_nodes_activities[];
}

export interface communityCustomFields_fetchCustomFields {
  __typename: 'CustomFieldConnection';
  nodes: communityCustomFields_fetchCustomFields_nodes[]; // A list of nodes.
}

export interface communityCustomFields {
  fetchCustomFields: communityCustomFields_fetchCustomFields; // returns all custom fields for users organization
}

export interface communityCustomFieldsVariables {
  filter?: FilterableCustomFieldAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: communityGroups
// ====================================================

export interface communityGroups_fetchCommunity_groups_nodes_StaticGroup {
  id: string;
  displayId: number;
  name: string;
}

export interface communityGroups_fetchCommunity_groups_nodes_DynamicGroup {
  id: string;
  displayId: number;
  name: string;
}

export type communityGroups_fetchCommunity_groups_nodes =
  | communityGroups_fetchCommunity_groups_nodes_StaticGroup
  | communityGroups_fetchCommunity_groups_nodes_DynamicGroup;

export interface communityGroups_fetchCommunity_groups_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface communityGroups_fetchCommunity_groups {
  __typename: 'GroupUnionConnection';
  nodes: communityGroups_fetchCommunity_groups_nodes[]; // A list of nodes.
  pageCount: number;
  pageInfo: communityGroups_fetchCommunity_groups_pageInfo; // Information to aid in pagination.
}

export interface communityGroups_fetchCommunity {
  __typename: 'Community';
  id: number;
  name: string;
  groups: communityGroups_fetchCommunity_groups; // Fetch community groups
}

export interface communityGroups {
  fetchCommunity: communityGroups_fetchCommunity; // find a Community by ID
}

export interface communityGroupsVariables {
  locationId: number;
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  filter?: FilterableGroupAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: communityTimeFrames
// ====================================================

export interface communityTimeFrames_fetchCommunity_timeFrame {
  eveningStartsAt: string;
  morningStartsAt: string;
  nightStartsAt: string;
}

export interface communityTimeFrames_fetchCommunity {
  __typename: 'Community';
  displayId: string;
  name: string;
  timeFrame: communityTimeFrames_fetchCommunity_timeFrame;
}

export interface communityTimeFrames {
  fetchCommunity: communityTimeFrames_fetchCommunity; // find a Community by ID
}

export interface communityTimeFramesVariables {
  locationId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: companyProfilePage
// ====================================================

export interface companyProfilePage_me_Jane {}

export interface companyProfilePage_me_AgencyUser_agency_directDeposit {
  chequeImageUrl: string | null;
  hstNumber: string;
  id: string;
}

export interface companyProfilePage_me_AgencyUser_agency_liability_insuranceDocuments_documentUrls {
  origin: string | null;
}

export interface companyProfilePage_me_AgencyUser_agency_liability_insuranceDocuments {
  documentUrls: companyProfilePage_me_AgencyUser_agency_liability_insuranceDocuments_documentUrls;
}

export interface companyProfilePage_me_AgencyUser_agency_liability {
  coverageAmount: any | null;
  expiryDate: any | null;
  insuranceNumber: string | null;
  id: string;
  licenceNumber: string | null;
  insuranceDocuments: companyProfilePage_me_AgencyUser_agency_liability_insuranceDocuments[];
}

export interface companyProfilePage_me_AgencyUser_agency {
  id: number;
  name: string;
  directDeposit: companyProfilePage_me_AgencyUser_agency_directDeposit | null;
  liability: companyProfilePage_me_AgencyUser_agency_liability | null;
}

export interface companyProfilePage_me_AgencyUser {
  agency: companyProfilePage_me_AgencyUser_agency | null;
}

export interface companyProfilePage_me_B2bCustomer_communities_location {
  fullAddress: string | null;
  flat: string | null;
}

export interface companyProfilePage_me_B2bCustomer_communities {
  id: number;
  name: string;
  location: companyProfilePage_me_B2bCustomer_communities_location | null;
  main: boolean;
}

export interface companyProfilePage_me_B2bCustomer {
  communities: companyProfilePage_me_B2bCustomer_communities[];
}

export type companyProfilePage_me =
  | companyProfilePage_me_Jane
  | companyProfilePage_me_AgencyUser
  | companyProfilePage_me_B2bCustomer;

export interface companyProfilePage {
  me: companyProfilePage_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: continueCalloutDialog
// ====================================================

export interface continueCalloutDialog_fetchOrder_currentlyActiveCalloutList_manualApprovalIndexes {
  groupName: string;
}

export interface continueCalloutDialog_fetchOrder_currentlyActiveCalloutList {
  id: string;
  manualApprovalIndexes: continueCalloutDialog_fetchOrder_currentlyActiveCalloutList_manualApprovalIndexes[];
}

export interface continueCalloutDialog_fetchOrder {
  id: number;
  allowOvertime: boolean;
  currentlyActiveCalloutList: continueCalloutDialog_fetchOrder_currentlyActiveCalloutList | null;
}

export interface continueCalloutDialog {
  fetchOrder: continueCalloutDialog_fetchOrder; // Find an order by ID
}

export interface continueCalloutDialogVariables {
  orderId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: createCustomFieldFetchCommunities
// ====================================================

export interface createCustomFieldFetchCommunities_fetchCommunities_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
  __typename: 'PageInfo';
}

export interface createCustomFieldFetchCommunities_fetchCommunities_nodes {
  __typename: 'Community';
  id: number;
  name: string;
}

export interface createCustomFieldFetchCommunities_fetchCommunities {
  __typename: 'CommunityConnection';
  pageInfo: createCustomFieldFetchCommunities_fetchCommunities_pageInfo; // Information to aid in pagination.
  nodes: createCustomFieldFetchCommunities_fetchCommunities_nodes[]; // A list of nodes.
}

export interface createCustomFieldFetchCommunities {
  __typename: 'Query';
  fetchCommunities: createCustomFieldFetchCommunities_fetchCommunities; // returns all communities for the current user
}

export interface createCustomFieldFetchCommunitiesVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  byName?: string | null;
  withId?: number[] | null;
  sort?: SortableCommunityAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: requestTeamMemberView
// ====================================================

export interface requestTeamMemberView_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface requestTeamMemberView_fetchJanes_nodes_agencyCommunity_agency {
  name: string;
}

export interface requestTeamMemberView_fetchJanes_nodes_agencyCommunity {
  name: string;
  agency: requestTeamMemberView_fetchJanes_nodes_agencyCommunity_agency;
}

export interface requestTeamMemberView_fetchJanes_nodes_avatarUrls {
  origin: string | null;
}

export interface requestTeamMemberView_fetchJanes_nodes_primaryCommunity {
  name: string;
}

export interface requestTeamMemberView_fetchJanes_nodes_customerReviews {
  averageRating: number | null;
  count: number;
}

export interface requestTeamMemberView_fetchJanes_nodes_primaryPosition_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface requestTeamMemberView_fetchJanes_nodes_primaryPosition {
  agencyDetails: requestTeamMemberView_fetchJanes_nodes_primaryPosition_agencyDetails | null;
}

export interface requestTeamMemberView_fetchJanes_nodes_positions_details {
  janePayout: any; // the amount a jane is paid for an open shift
}

export interface requestTeamMemberView_fetchJanes_nodes_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface requestTeamMemberView_fetchJanes_nodes_positions {
  details: requestTeamMemberView_fetchJanes_nodes_positions_details;
  agencyDetails: requestTeamMemberView_fetchJanes_nodes_positions_agencyDetails | null;
}

export interface requestTeamMemberView_fetchJanes_nodes {
  id: string;
  firstName: string;
  lastName: string;
  agencyCommunity: requestTeamMemberView_fetchJanes_nodes_agencyCommunity | null;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: requestTeamMemberView_fetchJanes_nodes_avatarUrls;
  primaryCommunity: requestTeamMemberView_fetchJanes_nodes_primaryCommunity | null;
  customerReviews: requestTeamMemberView_fetchJanes_nodes_customerReviews;
  primaryPosition: requestTeamMemberView_fetchJanes_nodes_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  positions: requestTeamMemberView_fetchJanes_nodes_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface requestTeamMemberView_fetchJanes {
  totalCount: number;
  pageInfo: requestTeamMemberView_fetchJanes_pageInfo; // Information to aid in pagination.
  nodes: requestTeamMemberView_fetchJanes_nodes[]; // A list of nodes.
}

export interface requestTeamMemberView {
  fetchJanes: requestTeamMemberView_fetchJanes; // find all janes
}

export interface requestTeamMemberViewVariables {
  after?: string | null;
  before?: string | null;
  external?: boolean | null;
  first?: number | null;
  last?: number | null;
  sort?: SortableJanesAttributes[] | null;
  byEmploymentStatus?: UserEmploymentStatusEnum[] | null;
  byFullName?: string | null;
  byPositionIdArray?: number[] | null;
  byPositionIdInt?: number | null;
  byFacilityId?: number | null;
  byAgency?: number[] | null;
  isDedicated?: boolean | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
  allowOvertime?: boolean | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: requestTeamMemberRecurringView
// ====================================================

export interface requestTeamMemberRecurringView_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_agencyCommunity_agency {
  name: string;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_agencyCommunity {
  name: string;
  agency: requestTeamMemberRecurringView_fetchJanes_nodes_agencyCommunity_agency;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_avatarUrls {
  origin: string | null;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_primaryCommunity {
  name: string;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_customerReviews {
  averageRating: number | null;
  count: number;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_primaryPosition_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_primaryPosition {
  agencyDetails: requestTeamMemberRecurringView_fetchJanes_nodes_primaryPosition_agencyDetails | null;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_conflictingDays {
  startTime: any;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_positions_details {
  janePayout: any; // the amount a jane is paid for an open shift
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes_positions {
  details: requestTeamMemberRecurringView_fetchJanes_nodes_positions_details;
  agencyDetails: requestTeamMemberRecurringView_fetchJanes_nodes_positions_agencyDetails | null;
}

export interface requestTeamMemberRecurringView_fetchJanes_nodes {
  id: string;
  firstName: string;
  lastName: string;
  agencyCommunity: requestTeamMemberRecurringView_fetchJanes_nodes_agencyCommunity | null;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: requestTeamMemberRecurringView_fetchJanes_nodes_avatarUrls;
  primaryCommunity: requestTeamMemberRecurringView_fetchJanes_nodes_primaryCommunity | null;
  customerReviews: requestTeamMemberRecurringView_fetchJanes_nodes_customerReviews;
  primaryPosition: requestTeamMemberRecurringView_fetchJanes_nodes_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  conflictingDays: requestTeamMemberRecurringView_fetchJanes_nodes_conflictingDays[]; // get all of the existing shifts that conflict with the given arguments
  positions: requestTeamMemberRecurringView_fetchJanes_nodes_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface requestTeamMemberRecurringView_fetchJanes {
  totalCount: number;
  pageInfo: requestTeamMemberRecurringView_fetchJanes_pageInfo; // Information to aid in pagination.
  nodes: requestTeamMemberRecurringView_fetchJanes_nodes[]; // A list of nodes.
}

export interface requestTeamMemberRecurringView {
  fetchJanes: requestTeamMemberRecurringView_fetchJanes; // find all janes
}

export interface requestTeamMemberRecurringViewVariables {
  after?: string | null;
  before?: string | null;
  external?: boolean | null;
  first?: number | null;
  last?: number | null;
  sort?: SortableJanesAttributes[] | null;
  byEmploymentStatus?: UserEmploymentStatusEnum[] | null;
  byFullName?: string | null;
  byPositionIdArray?: number[] | null;
  byPositionIdInt?: number | null;
  byFacilityId?: number | null;
  byAgency?: number[] | null;
  recurring: RecurringOrderAttributes;
  isDedicated?: boolean | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: customFieldsSettingsFetchCustomFields
// ====================================================

export interface customFieldsSettingsFetchCustomFields_fetchCustomFields_nodes_communities {
  __typename: 'Community';
  name: string;
}

export interface customFieldsSettingsFetchCustomFields_fetchCustomFields_nodes {
  __typename: 'CustomField';
  id: number;
  allFacilities: boolean;
  name: string;
  mobile: boolean;
  fieldType: CustomFieldTypeEnum;
  communities: customFieldsSettingsFetchCustomFields_fetchCustomFields_nodes_communities[] | null;
  lastEditedAt: any | null;
}

export interface customFieldsSettingsFetchCustomFields_fetchCustomFields {
  nodes: customFieldsSettingsFetchCustomFields_fetchCustomFields_nodes[]; // A list of nodes.
}

export interface customFieldsSettingsFetchCustomFields {
  fetchCustomFields: customFieldsSettingsFetchCustomFields_fetchCustomFields; // returns all custom fields for users organization
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchFacilitiesCount
// ====================================================

export interface fetchFacilitiesCount_fetchCommunities {
  totalCount: number;
  __typename: 'CommunityConnection';
}

export interface fetchFacilitiesCount {
  __typename: 'Query';
  fetchCommunities: fetchFacilitiesCount_fetchCommunities; // returns all communities for the current user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardAvailableShiftsPage
// ====================================================

export interface dashboardAvailableShiftsPage_fastGroupOrders_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_orderSchedule {
  id: number;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_status {
  name: any;
  value: OrderStatusEnum;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_community_location {
  timeZone: string | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_community_location | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_position {
  name: string;
  id: number;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity {
  id: string;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls {
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity {
  id: number;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity | null;
  avatarUrls: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls;
  agencyCommunity: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_review {
  rating: number | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders {
  __typename: 'Order';
  orderSchedule: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_orderSchedule;
  allowOvertime: boolean;
  id: number;
  dedicated: boolean;
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  canAssign: boolean;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  status: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_status;
  startAt: any | null;
  endAt: any | null;
  community: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_community;
  position: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_position;
  mainJane: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_mainJane | null;
  review: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_review | null;
  recurrence: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders_recurrence | null;
}

export interface dashboardAvailableShiftsPage_fastGroupOrders_nodes {
  group: any;
  orders: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders[];
}

export interface dashboardAvailableShiftsPage_fastGroupOrders {
  pageInfo: dashboardAvailableShiftsPage_fastGroupOrders_pageInfo; // Information to aid in pagination.
  totalCount: number;
  nodes: dashboardAvailableShiftsPage_fastGroupOrders_nodes[]; // A list of nodes.
}

export interface dashboardAvailableShiftsPage {
  fastGroupOrders: dashboardAvailableShiftsPage_fastGroupOrders;
}

export interface dashboardAvailableShiftsPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sortOrders?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardMyUpcomingShiftsPage
// ====================================================

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_orderSchedule {
  id: number;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_status {
  name: any;
  value: OrderStatusEnum;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_community_location {
  timeZone: string | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_community_location | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_position {
  name: string;
  id: number;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity {
  id: string;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls {
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity {
  id: number;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity | null;
  avatarUrls: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls;
  agencyCommunity: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_review {
  rating: number | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders {
  __typename: 'Order';
  orderSchedule: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_orderSchedule;
  id: number;
  dedicated: boolean;
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  canAssign: boolean;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  status: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_status;
  startAt: any | null;
  endAt: any | null;
  community: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_community;
  position: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_position;
  mainJane: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane | null;
  review: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_review | null;
  recurrence: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders_recurrence | null;
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes {
  group: any;
  orders: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes_orders[];
}

export interface dashboardMyUpcomingShiftsPage_fastGroupOrders {
  pageInfo: dashboardMyUpcomingShiftsPage_fastGroupOrders_pageInfo; // Information to aid in pagination.
  nodes: dashboardMyUpcomingShiftsPage_fastGroupOrders_nodes[]; // A list of nodes.
}

export interface dashboardMyUpcomingShiftsPage {
  fastGroupOrders: dashboardMyUpcomingShiftsPage_fastGroupOrders;
}

export interface dashboardMyUpcomingShiftsPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sortOrders?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardPastShiftsPage
// ====================================================

export interface dashboardPastShiftsPage_fastGroupOrders_pageInfo {
  __typename: 'PageInfo';
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_currentlyActiveCalloutList {
  id: string;
  isCompleted: boolean;
  createdAt: any;
  updatedAt: any;
  totalJanes: number;
  waitingForManualApproval: boolean;
  estimatedCompletedAt: any | null;
  totalNotifiedAndSkipped: number;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_orderSchedule {
  id: number;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_activities_event {
  name: ActivityEventEnum;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_activities {
  event: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_activities_event;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_status {
  __typename: 'OrderStatus';
  name: any;
  value: OrderStatusEnum;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_community_location {
  timeZone: string | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_community_location | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_position {
  __typename: 'PositionDetails';
  name: string;
  id: number;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity {
  id: string;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls {
  __typename: 'AvatarUrl';
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity {
  __typename: 'AgencyCommunity';
  id: number;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane {
  __typename: 'Jane';
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity | null;
  avatarUrls: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls;
  agencyCommunity: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_review {
  __typename: 'Review';
  rating: number | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders_recurrence {
  __typename: 'Recurrence';
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes_orders {
  __typename: 'Order';
  currentlyActiveCalloutList: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_currentlyActiveCalloutList | null;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  priority: OrderPriorityEnum;
  bidders: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_bidders[];
  autoRebid: boolean;
  orderSchedule: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_orderSchedule;
  activities: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_activities[];
  allowOvertime: boolean;
  auditTrailVersion: AuditTrailVersionEnum;
  id: number;
  canAssign: boolean;
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  status: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_status;
  dedicated: boolean;
  startAt: any | null;
  endAt: any | null;
  community: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_community;
  position: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_position;
  mainJane: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_mainJane | null;
  review: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_review | null;
  recurrence: dashboardPastShiftsPage_fastGroupOrders_nodes_orders_recurrence | null;
}

export interface dashboardPastShiftsPage_fastGroupOrders_nodes {
  group: any;
  orders: dashboardPastShiftsPage_fastGroupOrders_nodes_orders[];
}

export interface dashboardPastShiftsPage_fastGroupOrders {
  __typename: 'GroupedOrdersConnection';
  pageInfo: dashboardPastShiftsPage_fastGroupOrders_pageInfo; // Information to aid in pagination.
  nodes: dashboardPastShiftsPage_fastGroupOrders_nodes[]; // A list of nodes.
}

export interface dashboardPastShiftsPage {
  fastGroupOrders: dashboardPastShiftsPage_fastGroupOrders;
}

export interface dashboardPastShiftsPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sortOrders?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardRecentlyCreatedShiftsPage
// ====================================================

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_currentlyActiveCalloutList {
  id: string;
  isCompleted: boolean;
  createdAt: any;
  updatedAt: any;
  totalJanes: number;
  waitingForManualApproval: boolean;
  estimatedCompletedAt: any | null;
  totalNotifiedAndSkipped: number;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_orderSchedule {
  id: number;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_activities_event {
  name: ActivityEventEnum;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_activities {
  event: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_activities_event;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_status {
  name: any;
  value: OrderStatusEnum;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_community_location {
  timeZone: string | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_community_location | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_position {
  name: string;
  id: number;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity {
  id: string;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls {
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity {
  id: number;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity | null;
  avatarUrls: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls;
  agencyCommunity: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_review {
  rating: number | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders {
  currentlyActiveCalloutList: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_currentlyActiveCalloutList | null;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  priority: OrderPriorityEnum;
  bidders: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_bidders[];
  autoRebid: boolean;
  __typename: 'Order';
  orderSchedule: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_orderSchedule;
  allowOvertime: boolean;
  auditTrailVersion: AuditTrailVersionEnum;
  dedicated: boolean;
  id: number;
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  canAssign: boolean;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  activities: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_activities[];
  status: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_status;
  startAt: any | null;
  endAt: any | null;
  community: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_community;
  position: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_position;
  mainJane: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_mainJane | null;
  review: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_review | null;
  recurrence: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders_recurrence | null;
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes {
  group: any;
  orders: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders[];
}

export interface dashboardRecentlyCreatedShiftsPage_fastGroupOrders {
  pageInfo: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_pageInfo; // Information to aid in pagination.
  nodes: dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes[]; // A list of nodes.
}

export interface dashboardRecentlyCreatedShiftsPage {
  fastGroupOrders: dashboardRecentlyCreatedShiftsPage_fastGroupOrders;
}

export interface dashboardRecentlyCreatedShiftsPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sortOrders?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dashboardUpcomingShiftsPage
// ====================================================

export interface dashboardUpcomingShiftsPage_fastGroupOrders_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_currentlyActiveCalloutList {
  id: string;
  isCompleted: boolean;
  createdAt: any;
  updatedAt: any;
  totalJanes: number;
  waitingForManualApproval: boolean;
  estimatedCompletedAt: any | null;
  totalNotifiedAndSkipped: number;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_orderSchedule {
  id: number;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_activities_event {
  name: ActivityEventEnum;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_activities {
  event: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_activities_event;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_status {
  name: any;
  value: OrderStatusEnum;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_community_location {
  timeZone: string | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_community_location | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_position {
  name: string;
  id: number;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity {
  id: string;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls {
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity {
  id: number;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_primaryCommunity | null;
  avatarUrls: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_avatarUrls;
  agencyCommunity: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane_agencyCommunity | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_review {
  rating: number | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders {
  __typename: 'Order';
  currentlyActiveCalloutList: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_currentlyActiveCalloutList | null;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  priority: OrderPriorityEnum;
  bidders: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_bidders[];
  autoRebid: boolean;
  orderSchedule: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_orderSchedule;
  allowOvertime: boolean;
  auditTrailVersion: AuditTrailVersionEnum;
  id: number;
  dedicated: boolean;
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  canAssign: boolean;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  activities: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_activities[];
  status: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_status;
  startAt: any | null;
  endAt: any | null;
  community: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_community;
  position: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_position;
  mainJane: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_mainJane | null;
  review: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_review | null;
  recurrence: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders_recurrence | null;
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders_nodes {
  group: any;
  orders: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders[];
}

export interface dashboardUpcomingShiftsPage_fastGroupOrders {
  pageInfo: dashboardUpcomingShiftsPage_fastGroupOrders_pageInfo; // Information to aid in pagination.
  nodes: dashboardUpcomingShiftsPage_fastGroupOrders_nodes[]; // A list of nodes.
}

export interface dashboardUpcomingShiftsPage {
  fastGroupOrders: dashboardUpcomingShiftsPage_fastGroupOrders;
}

export interface dashboardUpcomingShiftsPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sortOrders?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: editCustomFieldFetchCustomFieldById
// ====================================================

export interface editCustomFieldFetchCustomFieldById_fetchCustomField_communities {
  id: number;
  name: string;
}

export interface editCustomFieldFetchCustomFieldById_fetchCustomField_customFieldOptions {
  value: string;
}

export interface editCustomFieldFetchCustomFieldById_fetchCustomField {
  id: number;
  allFacilities: boolean;
  communities: editCustomFieldFetchCustomFieldById_fetchCustomField_communities[] | null;
  name: string;
  mobile: boolean;
  fieldType: CustomFieldTypeEnum;
  customFieldOptions:
    | editCustomFieldFetchCustomFieldById_fetchCustomField_customFieldOptions[]
    | null;
  placeholder: string;
  prefillDefault: boolean;
}

export interface editCustomFieldFetchCustomFieldById {
  fetchCustomField: editCustomFieldFetchCustomFieldById_fetchCustomField; // find a custom_field by id
}

export interface editCustomFieldFetchCustomFieldByIdVariables {
  fetchCustomFieldId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchAgencyCommunities
// ====================================================

export interface fetchAgencyCommunities_fetchAgency_agencyCommunities_location {
  latitude: number | null;
  longitude: number | null;
  timeZone: string | null;
  country: string | null;
}

export interface fetchAgencyCommunities_fetchAgency_agencyCommunities {
  __typename: 'AgencyCommunity';
  id: number;
  name: string;
  location: fetchAgencyCommunities_fetchAgency_agencyCommunities_location | null;
}

export interface fetchAgencyCommunities_fetchAgency {
  __typename: 'Agency';
  id: number;
  agencyCommunities: fetchAgencyCommunities_fetchAgency_agencyCommunities[];
}

export interface fetchAgencyCommunities {
  fetchAgency: fetchAgencyCommunities_fetchAgency;
}

export interface fetchAgencyCommunitiesVariables {
  id: string;
  byName?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchCommunities
// ====================================================

export interface fetchCommunities_fetchCommunities_edges_node {
  __typename: 'Community';
  id: number;
  name: string;
}

export interface fetchCommunities_fetchCommunities_edges {
  __typename: 'CommunityEdge';
  cursor: string; // A cursor for use in pagination.
  node: fetchCommunities_fetchCommunities_edges_node | null; // The item at the end of the edge.
}

export interface fetchCommunities_fetchCommunities {
  __typename: 'CommunityConnection';
  edges: fetchCommunities_fetchCommunities_edges[]; // A list of edges.
}

export interface fetchCommunities {
  __typename: 'Query';
  fetchCommunities: fetchCommunities_fetchCommunities; // returns all communities for the current user
}

export interface fetchCommunitiesVariables {
  sort?: SortableCommunityAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchCommunitiesPaginated
// ====================================================

export interface fetchCommunitiesPaginated_fetchCommunities_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  __typename: 'PageInfo';
}

export interface fetchCommunitiesPaginated_fetchCommunities_nodes_location {
  timeZone: string | null;
  country: string | null;
}

export interface fetchCommunitiesPaginated_fetchCommunities_nodes {
  id: number;
  __typename: 'Community';
  name: string;
  location: fetchCommunitiesPaginated_fetchCommunities_nodes_location | null;
}

export interface fetchCommunitiesPaginated_fetchCommunities {
  __typename: 'CommunityConnection';
  pageInfo: fetchCommunitiesPaginated_fetchCommunities_pageInfo; // Information to aid in pagination.
  nodes: fetchCommunitiesPaginated_fetchCommunities_nodes[]; // A list of nodes.
  currentPage: number | null;
  pageCount: number;
  pageSize: number;
  totalCount: number;
}

export interface fetchCommunitiesPaginated {
  __typename: 'Query';
  fetchCommunities: fetchCommunitiesPaginated_fetchCommunities; // returns all communities for the current user
}

export interface fetchCommunitiesPaginatedVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  byName?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchCommunityBiddingPeriod
// ====================================================

export interface fetchCommunityBiddingPeriod_fetchCommunity_biddingTimes {
  id: string;
  leadTime: number;
  priorityTime: number;
}

export interface fetchCommunityBiddingPeriod_fetchCommunity_leadTimes {
  id: string;
  priority: LeadTimePriorityEnum;
  priorityTime: number;
}

export interface fetchCommunityBiddingPeriod_fetchCommunity {
  id: number;
  name: string;
  __typename: 'Community';
  biddingTimes: fetchCommunityBiddingPeriod_fetchCommunity_biddingTimes[] | null;
  leadTimes: fetchCommunityBiddingPeriod_fetchCommunity_leadTimes[] | null;
}

export interface fetchCommunityBiddingPeriod {
  fetchCommunity: fetchCommunityBiddingPeriod_fetchCommunity; // find a Community by ID
}

export interface fetchCommunityBiddingPeriodVariables {
  locationId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchDynamicGroupEmploymentStatuses
// ====================================================

export interface fetchDynamicGroupEmploymentStatuses_fetchMetadata_dynamicGroupCriteriaEmploymentStatuses {
  displayText: string;
  id: string;
}

export interface fetchDynamicGroupEmploymentStatuses_fetchMetadata {
  dynamicGroupCriteriaEmploymentStatuses: fetchDynamicGroupEmploymentStatuses_fetchMetadata_dynamicGroupCriteriaEmploymentStatuses[];
}

export interface fetchDynamicGroupEmploymentStatuses {
  fetchMetadata: fetchDynamicGroupEmploymentStatuses_fetchMetadata;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchMe
// ====================================================

export interface fetchMe_me_Jane {}

export interface fetchMe_me_B2bCustomer_accessStatus {
  name: AccessStatusEnum;
  value: any;
}

export interface fetchMe_me_B2bCustomer_avatarUrls {
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface fetchMe_me_B2bCustomer_communities_location {
  city: string | null;
  country: string | null;
  description: string | null;
  flat: string | null;
  id: number;
  latitude: number | null;
  longitude: number | null;
  state: string | null;
  street: string | null;
  streetNumber: string | null;
  timeZone: string | null;
  zipCode: string | null;
}

export interface fetchMe_me_B2bCustomer_communities {
  contactEmail: string | null;
  contactName: string | null;
  contactPhone: string | null;
  location: fetchMe_me_B2bCustomer_communities_location | null;
}

export interface fetchMe_me_B2bCustomer_serviceablePositions {
  id: number;
}

export interface fetchMe_me_B2bCustomer {
  accessLevelId: number;
  accessStatus: fetchMe_me_B2bCustomer_accessStatus;
  adpCustomIdentifier: string | null;
  agencyCustomer: boolean;
  availabilityStatus: string;
  avatarUrls: fetchMe_me_B2bCustomer_avatarUrls;
  communities: fetchMe_me_B2bCustomer_communities[];
  completedOnboardingSteps: number;
  email: string;
  firstName: string;
  hasPools: boolean;
  id: string;
  industry: string | null;
  lastName: string;
  payrollManagement: boolean;
  phone: string | null;
  serviceablePositions: fetchMe_me_B2bCustomer_serviceablePositions[];
  tieringStatus: string;
  type: string;
  userRole: string;
  userType: string;
}

export interface fetchMe_me_AgencyUser_agency_agencyCommunities {
  id: number;
  name: string;
}

export interface fetchMe_me_AgencyUser_agency_communityPositions_positionDetails {
  id: number;
  name: string;
}

export interface fetchMe_me_AgencyUser_agency_communityPositions {
  id: number;
  openPayout: any; // the amount a jane is paid per hour or an open shift
  openRate: any; // the rate charged to the customer for an open shift
  positionDetails: fetchMe_me_AgencyUser_agency_communityPositions_positionDetails;
}

export interface fetchMe_me_AgencyUser_agency_directDeposit {
  id: string;
}

export interface fetchMe_me_AgencyUser_agency_liability_insuranceDocuments_documentUrls {
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface fetchMe_me_AgencyUser_agency_liability_insuranceDocuments {
  archived: boolean;
  documentUrls: fetchMe_me_AgencyUser_agency_liability_insuranceDocuments_documentUrls;
  id: string;
}

export interface fetchMe_me_AgencyUser_agency_liability {
  id: string;
  coverageAmount: any | null;
  expiryDate: any | null;
  insuranceDocuments: fetchMe_me_AgencyUser_agency_liability_insuranceDocuments[];
  insuranceNumber: string | null;
}

export interface fetchMe_me_AgencyUser_agency {
  agencyCommunities: fetchMe_me_AgencyUser_agency_agencyCommunities[];
  communityPositions: fetchMe_me_AgencyUser_agency_communityPositions[];
  createdAt: any;
  directDeposit: fetchMe_me_AgencyUser_agency_directDeposit | null;
  id: number;
  liability: fetchMe_me_AgencyUser_agency_liability | null;
  name: string;
  updatedAt: any;
}

export interface fetchMe_me_AgencyUser_avatarUrls {
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface fetchMe_me_AgencyUser_janes {
  id: string;
}

export interface fetchMe_me_AgencyUser_accessStatus {
  name: AccessStatusEnum;
  value: any;
}

export interface fetchMe_me_AgencyUser {
  agency: fetchMe_me_AgencyUser_agency | null;
  agencyId: number | null;
  avatarUrls: fetchMe_me_AgencyUser_avatarUrls;
  email: string;
  firstName: string;
  hasPools: boolean;
  id: string;
  janes: fetchMe_me_AgencyUser_janes[] | null;
  payrollManagement: boolean;
  phone: string | null;
  lastName: string;
  type: string;
  accessStatus: fetchMe_me_AgencyUser_accessStatus;
}

export interface fetchMe_me_SecondaryUser_accessStatus {
  name: AccessStatusEnum;
  value: any;
}

export interface fetchMe_me_SecondaryUser_avatarUrls {
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface fetchMe_me_SecondaryUser_communities_location {
  city: string | null;
  country: string | null;
  description: string | null;
  flat: string | null;
  id: number;
  latitude: number | null;
  longitude: number | null;
  state: string | null;
  street: string | null;
  streetNumber: string | null;
  timeZone: string | null;
  zipCode: string | null;
}

export interface fetchMe_me_SecondaryUser_communities {
  contactEmail: string | null;
  contactName: string | null;
  contactPhone: string | null;
  location: fetchMe_me_SecondaryUser_communities_location | null;
}

export interface fetchMe_me_SecondaryUser_invitedBy {
  id: string;
}

export interface fetchMe_me_SecondaryUser_serviceablePositions {
  id: number;
}

export interface fetchMe_me_SecondaryUser {
  accessLevelId: number;
  accessStatus: fetchMe_me_SecondaryUser_accessStatus;
  adpCustomIdentifier: string | null;
  agencyCustomer: boolean;
  availabilityStatus: string;
  avatarUrls: fetchMe_me_SecondaryUser_avatarUrls;
  communities: fetchMe_me_SecondaryUser_communities[];
  completedOnboardingSteps: number;
  email: string;
  firstName: string;
  hasPools: boolean;
  id: string;
  industry: string | null;
  invitedBy: fetchMe_me_SecondaryUser_invitedBy | null;
  lastName: string;
  payrollManagement: boolean;
  phone: string | null;
  serviceablePositions: fetchMe_me_SecondaryUser_serviceablePositions[];
  tieringStatus: string;
  type: string;
  unreadConversations: number | null;
  userRole: string;
  userType: string;
}

export type fetchMe_me =
  | fetchMe_me_Jane
  | fetchMe_me_B2bCustomer
  | fetchMe_me_AgencyUser
  | fetchMe_me_SecondaryUser;

export interface fetchMe {
  me: fetchMe_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchOrderBidders
// ====================================================

export interface fetchOrderBidders_fetchOrder_orderSchedule {
  id: number;
}

export interface fetchOrderBidders_fetchOrder_status {
  name: any;
  value: OrderStatusEnum;
}

export interface fetchOrderBidders_fetchOrder_bidders_avatarUrls {
  origin: string | null;
}

export interface fetchOrderBidders_fetchOrder_bidders_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface fetchOrderBidders_fetchOrder_bidders_totalWeeklyHours {
  weeklyHours: number | null;
}

export interface fetchOrderBidders_fetchOrder_bidders_primaryPosition_details {
  name: string;
}

export interface fetchOrderBidders_fetchOrder_bidders_primaryPosition {
  details: fetchOrderBidders_fetchOrder_bidders_primaryPosition_details;
}

export interface fetchOrderBidders_fetchOrder_bidders {
  firstName: string;
  lastName: string;
  orderRank: number | null; // Jane Callout Rank
  id: string;
  avatarUrls: fetchOrderBidders_fetchOrder_bidders_avatarUrls;
  employmentStatus: fetchOrderBidders_fetchOrder_bidders_employmentStatus;
  totalWeeklyHours: fetchOrderBidders_fetchOrder_bidders_totalWeeklyHours | null; // Total Weekly hours of the jane with given order Id
  primaryPosition: fetchOrderBidders_fetchOrder_bidders_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
}

export interface fetchOrderBidders_fetchOrder {
  orderSchedule: fetchOrderBidders_fetchOrder_orderSchedule;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  id: number;
  status: fetchOrderBidders_fetchOrder_status;
  bidders: fetchOrderBidders_fetchOrder_bidders[];
}

export interface fetchOrderBidders {
  fetchOrder: fetchOrderBidders_fetchOrder; // Find an order by ID
}

export interface fetchOrderBiddersVariables {
  orderId: number;
  byFullName?: string | null;
  byEmploymentStatus?: UserEmploymentStatusEnum[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchOrderCanAssign
// ====================================================

export interface fetchOrderCanAssign_fetchOrder {
  canAssign: boolean;
}

export interface fetchOrderCanAssign {
  fetchOrder: fetchOrderCanAssign_fetchOrder; // Find an order by ID
}

export interface fetchOrderCanAssignVariables {
  orderId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchSecondaryCommunities
// ====================================================

export interface fetchSecondaryCommunities_fetchCommunities_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  __typename: 'PageInfo';
}

export interface fetchSecondaryCommunities_fetchCommunities_nodes_location {
  timeZone: string | null;
}

export interface fetchSecondaryCommunities_fetchCommunities_nodes {
  id: number;
  __typename: 'Community';
  name: string;
  location: fetchSecondaryCommunities_fetchCommunities_nodes_location | null;
}

export interface fetchSecondaryCommunities_fetchCommunities {
  __typename: 'CommunityConnection';
  pageInfo: fetchSecondaryCommunities_fetchCommunities_pageInfo; // Information to aid in pagination.
  nodes: fetchSecondaryCommunities_fetchCommunities_nodes[]; // A list of nodes.
  currentPage: number | null;
  pageCount: number;
  pageSize: number;
  totalCount: number;
}

export interface fetchSecondaryCommunities {
  __typename: 'Query';
  fetchCommunities: fetchSecondaryCommunities_fetchCommunities; // returns all communities for the current user
}

export interface fetchSecondaryCommunitiesVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  byName?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchTeamMemberByIdTeamMembersPage
// ====================================================

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_invitation {
  id: number;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_avatarUrls {
  origin: string | null;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_primaryPosition_details {
  name: string;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_primaryPosition {
  details: fetchTeamMemberByIdTeamMembersPage_fetchJane_primaryPosition_details;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_positions_details {
  name: string;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_positions {
  details: fetchTeamMemberByIdTeamMembersPage_fetchJane_positions_details;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_secondaryPositions_details {
  id: number;
  name: string;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_secondaryPositions {
  details: fetchTeamMemberByIdTeamMembersPage_fetchJane_secondaryPositions_details;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane_accessStatus {
  name: AccessStatusEnum;
}

export interface fetchTeamMemberByIdTeamMembersPage_fetchJane {
  id: string;
  invitation: fetchTeamMemberByIdTeamMembersPage_fetchJane_invitation | null;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: fetchTeamMemberByIdTeamMembersPage_fetchJane_avatarUrls;
  primaryPosition: fetchTeamMemberByIdTeamMembersPage_fetchJane_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  positions: fetchTeamMemberByIdTeamMembersPage_fetchJane_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
  secondaryPositions: fetchTeamMemberByIdTeamMembersPage_fetchJane_secondaryPositions[]; // only secondary positions for this Jane
  employmentStatus: fetchTeamMemberByIdTeamMembersPage_fetchJane_employmentStatus;
  phone: string | null;
  email: string;
  accessStatus: fetchTeamMemberByIdTeamMembersPage_fetchJane_accessStatus;
}

export interface fetchTeamMemberByIdTeamMembersPage {
  fetchJane: fetchTeamMemberByIdTeamMembersPage_fetchJane; // find a single jane
}

export interface fetchTeamMemberByIdTeamMembersPageVariables {
  teamMemberId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchTimeOffCustomFields
// ====================================================

export interface fetchTimeOffCustomFields_fetchCustomFields_nodes_customFieldOptions {
  __typename: 'CustomFieldOption';
  value: string;
}

export interface fetchTimeOffCustomFields_fetchCustomFields_nodes_communities {
  __typename: 'Community';
  name: string;
}

export interface fetchTimeOffCustomFields_fetchCustomFields_nodes_organization {
  __typename: 'Organization';
  name: string;
}

export interface fetchTimeOffCustomFields_fetchCustomFields_nodes_activities {
  __typename: 'Activity';
  event: ActivityEventEnum;
  createdAt: any;
}

export interface fetchTimeOffCustomFields_fetchCustomFields_nodes {
  __typename: 'CustomField';
  id: number;
  name: string;
  mobile: boolean;
  fieldType: CustomFieldTypeEnum;
  placeholder: string;
  prefillDefault: boolean;
  createdAt: any | null;
  lastEditedAt: any | null;
  customFieldOptions: fetchTimeOffCustomFields_fetchCustomFields_nodes_customFieldOptions[] | null;
  communities: fetchTimeOffCustomFields_fetchCustomFields_nodes_communities[] | null;
  organization: fetchTimeOffCustomFields_fetchCustomFields_nodes_organization;
  activities: fetchTimeOffCustomFields_fetchCustomFields_nodes_activities[];
}

export interface fetchTimeOffCustomFields_fetchCustomFields {
  __typename: 'CustomFieldConnection';
  nodes: fetchTimeOffCustomFields_fetchCustomFields_nodes[]; // A list of nodes.
}

export interface fetchTimeOffCustomFields {
  fetchCustomFields: fetchTimeOffCustomFields_fetchCustomFields; // returns all custom fields for users organization
}

export interface fetchTimeOffCustomFieldsVariables {
  filter?: FilterableCustomFieldAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchLocale
// ====================================================

export interface fetchLocale_me_Jane {}

export interface fetchLocale_me_B2bCustomer {
  __typename: 'B2bCustomer';
  locale: string;
}

export interface fetchLocale_me_AgencyUser {
  __typename: 'AgencyUser';
  locale: string;
}

export interface fetchLocale_me_SecondaryUser {
  __typename: 'SecondaryUser';
  locale: string;
}

export type fetchLocale_me =
  | fetchLocale_me_Jane
  | fetchLocale_me_B2bCustomer
  | fetchLocale_me_AgencyUser
  | fetchLocale_me_SecondaryUser;

export interface fetchLocale {
  me: fetchLocale_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: generalAgencyLocationSettingsPage
// ====================================================

export interface generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_wsib_documents_urls {
  __typename: 'DocumentUrl';
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_wsib_documents {
  __typename: 'WsibDocument';
  originalFilename: string;
  archive: boolean;
  urls: generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_wsib_documents_urls;
}

export interface generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_wsib {
  __typename: 'Wsib';
  regulatoryId: string | null;
  documents: generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_wsib_documents[];
}

export interface generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_location {
  id: number;
  city: string | null;
  flat: string | null;
  state: string | null;
  street: string | null;
  country: string | null;
  zipCode: string | null;
  timeZone: string | null;
  latitude: number | null;
  longitude: number | null;
  __typename: 'Location';
  fullAddress: string | null;
  description: string | null;
  streetNumber: string | null;
}

export interface generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities {
  id: number;
  name: string;
  __typename: 'AgencyCommunity';
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  wsib: generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_wsib | null;
  location: generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities_location | null;
}

export interface generalAgencyLocationSettingsPage_fetchAgency {
  __typename: 'Agency';
  agencyCommunities: generalAgencyLocationSettingsPage_fetchAgency_agencyCommunities[];
}

export interface generalAgencyLocationSettingsPage {
  fetchAgency: generalAgencyLocationSettingsPage_fetchAgency;
}

export interface generalAgencyLocationSettingsPageVariables {
  agencyId: string;
  agencyCommunityId?: number[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: generalLocationSettingsPage
// ====================================================

export interface generalLocationSettingsPage_fetchCommunity_location {
  id: number;
  city: string | null;
  flat: string | null;
  state: string | null;
  street: string | null;
  country: string | null;
  zipCode: string | null;
  timeZone: string | null;
  latitude: number | null;
  longitude: number | null;
  __typename: 'Location';
  fullAddress: string | null;
  description: string | null;
  streetNumber: string | null;
}

export interface generalLocationSettingsPage_fetchCommunity {
  id: number;
  name: string;
  __typename: 'Community';
  contactName: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
  location: generalLocationSettingsPage_fetchCommunity_location | null;
}

export interface generalLocationSettingsPage {
  fetchCommunity: generalLocationSettingsPage_fetchCommunity; // find a Community by ID
}

export interface generalLocationSettingsPageVariables {
  locationId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: janeModalJaneDetails
// ====================================================

export interface janeModalJaneDetails_fetchJane_invitation {
  id: number;
  lastSendAttempt: any | null;
  invitationId: string;
}

export interface janeModalJaneDetails_fetchJane_agencyCommunity {
  id: number;
  name: string;
}

export interface janeModalJaneDetails_fetchJane_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface janeModalJaneDetails_fetchJane_location {
  fullAddress: string | null;
}

export interface janeModalJaneDetails_fetchJane_secondaryCommunities {
  name: string;
  displayId: string;
}

export interface janeModalJaneDetails_fetchJane_primaryPosition_details {
  name: string;
  id: number;
}

export interface janeModalJaneDetails_fetchJane_primaryPosition {
  details: janeModalJaneDetails_fetchJane_primaryPosition_details;
  id: number;
  type: PositionTypeEnum;
}

export interface janeModalJaneDetails_fetchJane_positions_details {
  name: string;
  id: number;
}

export interface janeModalJaneDetails_fetchJane_positions {
  details: janeModalJaneDetails_fetchJane_positions_details;
  id: number;
  type: PositionTypeEnum;
}

export interface janeModalJaneDetails_fetchJane_secondaryPositions_details {
  id: number;
  name: string;
}

export interface janeModalJaneDetails_fetchJane_secondaryPositions {
  details: janeModalJaneDetails_fetchJane_secondaryPositions_details;
  id: number;
  type: PositionTypeEnum;
}

export interface janeModalJaneDetails_fetchJane_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface janeModalJaneDetails_fetchJane_accessStatus {
  name: AccessStatusEnum;
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews_jane_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews_jane {
  avatarUrls: janeModalJaneDetails_fetchJane_customerReviews_reviews_jane_avatarUrls;
  shorthandName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews_reviewer_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews_reviewer {
  firstName: string;
  lastName: string;
  avatarUrls: janeModalJaneDetails_fetchJane_customerReviews_reviews_reviewer_avatarUrls;
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews_order_community {
  displayId: string;
  name: string;
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews_order {
  community: janeModalJaneDetails_fetchJane_customerReviews_reviews_order_community;
}

export interface janeModalJaneDetails_fetchJane_customerReviews_reviews {
  comment: string | null;
  createdAt: any;
  rating: number | null;
  jane: janeModalJaneDetails_fetchJane_customerReviews_reviews_jane;
  reviewer: janeModalJaneDetails_fetchJane_customerReviews_reviews_reviewer;
  order: janeModalJaneDetails_fetchJane_customerReviews_reviews_order | null;
}

export interface janeModalJaneDetails_fetchJane_customerReviews {
  averageRating: number | null;
  count: number;
  reviews: janeModalJaneDetails_fetchJane_customerReviews_reviews[];
}

export interface janeModalJaneDetails_fetchJane_primaryCommunity {
  id: string;
  name: string;
}

export interface janeModalJaneDetails_fetchJane {
  id: string;
  invitation: janeModalJaneDetails_fetchJane_invitation | null;
  agencyCommunity: janeModalJaneDetails_fetchJane_agencyCommunity | null;
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  employeeStartDate: any | null;
  bio: string | null; // Description of the jane
  locale: string;
  lastActivityAt: any | null;
  avatarUrls: janeModalJaneDetails_fetchJane_avatarUrls;
  location: janeModalJaneDetails_fetchJane_location | null; // Address of the jane
  secondaryCommunities: janeModalJaneDetails_fetchJane_secondaryCommunities[];
  primaryPosition: janeModalJaneDetails_fetchJane_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  positions: janeModalJaneDetails_fetchJane_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
  secondaryPositions: janeModalJaneDetails_fetchJane_secondaryPositions[]; // only secondary positions for this Jane
  employmentStatus: janeModalJaneDetails_fetchJane_employmentStatus;
  phone: string | null;
  email: string;
  shorthandName: string;
  accessStatus: janeModalJaneDetails_fetchJane_accessStatus;
  customerReviews: janeModalJaneDetails_fetchJane_customerReviews;
  primaryCommunity: janeModalJaneDetails_fetchJane_primaryCommunity | null;
  birthDay: number | null;
  birthMonth: number | null;
  hourlyRate: number | null;
  employeeNumber: string | null; // Employee ID
}

export interface janeModalJaneDetails {
  fetchJane: janeModalJaneDetails_fetchJane; // find a single jane
}

export interface janeModalJaneDetailsVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: locationDropdownData
// ====================================================

export interface locationDropdownData_me_Jane {
  __typename: 'Jane';
}

export interface locationDropdownData_me_SecondaryUser_communities_location {
  timeZone: string | null;
  country: string | null;
}

export interface locationDropdownData_me_SecondaryUser_communities {
  id: number;
  name: string;
  location: locationDropdownData_me_SecondaryUser_communities_location | null;
}

export interface locationDropdownData_me_SecondaryUser {
  __typename: 'SecondaryUser';
  communities: locationDropdownData_me_SecondaryUser_communities[];
}

export interface locationDropdownData_me_B2bCustomer_communities_location {
  timeZone: string | null;
  country: string | null;
}

export interface locationDropdownData_me_B2bCustomer_communities {
  id: number;
  name: string;
  location: locationDropdownData_me_B2bCustomer_communities_location | null;
}

export interface locationDropdownData_me_B2bCustomer {
  __typename: 'B2bCustomer';
  communities: locationDropdownData_me_B2bCustomer_communities[];
}

export interface locationDropdownData_me_AgencyUser_agency_agencyCommunities_location {
  latitude: number | null;
  longitude: number | null;
  timeZone: string | null;
  country: string | null;
}

export interface locationDropdownData_me_AgencyUser_agency_agencyCommunities {
  name: string;
  id: number;
  location: locationDropdownData_me_AgencyUser_agency_agencyCommunities_location | null;
}

export interface locationDropdownData_me_AgencyUser_agency {
  agencyCommunities: locationDropdownData_me_AgencyUser_agency_agencyCommunities[];
}

export interface locationDropdownData_me_AgencyUser {
  __typename: 'AgencyUser';
  agency: locationDropdownData_me_AgencyUser_agency | null;
}

export type locationDropdownData_me =
  | locationDropdownData_me_Jane
  | locationDropdownData_me_SecondaryUser
  | locationDropdownData_me_B2bCustomer
  | locationDropdownData_me_AgencyUser;

export interface locationDropdownData {
  me: locationDropdownData_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: locationNameLabel
// ====================================================

export interface locationNameLabel_fetchCommunity {
  id: number;
  name: string;
}

export interface locationNameLabel {
  fetchCommunity: locationNameLabel_fetchCommunity; // find a Community by ID
}

export interface locationNameLabelVariables {
  id: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: positionByDepartmentSelectInput
// ====================================================

export interface positionByDepartmentSelectInput_groupPositions_edges_node_positions {
  __typename: 'PositionDetails';
  name: string;
  id: number;
  openShiftPolicy: OpenShiftPolicyEnum;
}

export interface positionByDepartmentSelectInput_groupPositions_edges_node {
  group: any;
  positions: positionByDepartmentSelectInput_groupPositions_edges_node_positions[];
}

export interface positionByDepartmentSelectInput_groupPositions_edges {
  node: positionByDepartmentSelectInput_groupPositions_edges_node | null; // The item at the end of the edge.
}

export interface positionByDepartmentSelectInput_groupPositions_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface positionByDepartmentSelectInput_groupPositions {
  __typename: 'GroupedPositionsConnection';
  edges: positionByDepartmentSelectInput_groupPositions_edges[]; // A list of edges.
  pageInfo: positionByDepartmentSelectInput_groupPositions_pageInfo; // Information to aid in pagination.
}

export interface positionByDepartmentSelectInput {
  groupPositions: positionByDepartmentSelectInput_groupPositions;
}

export interface positionByDepartmentSelectInputVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  filter?: FilterablePositionAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: positionNameLabel
// ====================================================

export interface positionNameLabel_fetchPosition_openPriceRange {
  max: any | null;
  min: any | null;
}

export interface positionNameLabel_fetchPosition {
  id: number;
  name: string;
  openPriceRange: positionNameLabel_fetchPosition_openPriceRange | null;
}

export interface positionNameLabel {
  fetchPosition: positionNameLabel_fetchPosition;
}

export interface positionNameLabelVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: positionsByDepartment
// ====================================================

export interface positionsByDepartment_groupPositions_edges_node_positions {
  __typename: 'PositionDetails';
  name: string;
  id: number;
}

export interface positionsByDepartment_groupPositions_edges_node {
  __typename: 'GroupedPositions';
  group: any;
  positions: positionsByDepartment_groupPositions_edges_node_positions[];
}

export interface positionsByDepartment_groupPositions_edges {
  __typename: 'GroupedPositionsEdge';
  node: positionsByDepartment_groupPositions_edges_node | null; // The item at the end of the edge.
}

export interface positionsByDepartment_groupPositions {
  __typename: 'GroupedPositionsConnection';
  edges: positionsByDepartment_groupPositions_edges[]; // A list of edges.
}

export interface positionsByDepartment {
  groupPositions: positionsByDepartment_groupPositions;
}

export interface positionsByDepartmentVariables {
  filter?: FilterablePositionAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: positionsPriceRanges
// ====================================================

export interface positionsPriceRanges_fetchPositions_edges_node_openPriceRange {
  max: any | null;
  min: any | null;
}

export interface positionsPriceRanges_fetchPositions_edges_node {
  id: number;
  name: string;
  hasPools: boolean;
  openShiftPolicy: OpenShiftPolicyEnum;
  openPriceRange: positionsPriceRanges_fetchPositions_edges_node_openPriceRange | null;
}

export interface positionsPriceRanges_fetchPositions_edges {
  node: positionsPriceRanges_fetchPositions_edges_node | null; // The item at the end of the edge.
}

export interface positionsPriceRanges_fetchPositions {
  edges: positionsPriceRanges_fetchPositions_edges[]; // A list of edges.
}

export interface positionsPriceRanges {
  fetchPositions: positionsPriceRanges_fetchPositions;
}

export interface positionsPriceRangesVariables {
  filter?: FilterablePositionAttributes | null;
  communityId?: number | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: positionsSettingsPageFetchCommunityById
// ====================================================

export interface positionsSettingsPageFetchCommunityById_fetchAgency_agencyCommunities_positions {
  id: number;
  name: string;
  workTeam: WorkTeamEnum;
  clientCost: any; // the rate charged to the customer for an open shift
  updatedAt: any;
}

export interface positionsSettingsPageFetchCommunityById_fetchAgency_agencyCommunities {
  id: number;
  name: string;
  positions: positionsSettingsPageFetchCommunityById_fetchAgency_agencyCommunities_positions[];
}

export interface positionsSettingsPageFetchCommunityById_fetchAgency {
  agencyCommunities: positionsSettingsPageFetchCommunityById_fetchAgency_agencyCommunities[];
}

export interface positionsSettingsPageFetchCommunityById {
  fetchAgency: positionsSettingsPageFetchCommunityById_fetchAgency;
}

export interface positionsSettingsPageFetchCommunityByIdVariables {
  agencyId: string;
  filter?: ForAgencyCommunityAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: positionsSettingsPageFetchPositions
// ====================================================

export interface positionsSettingsPageFetchPositions_me_B2bCustomer {}

export interface positionsSettingsPageFetchPositions_me_AgencyUser_agency_agencyCommunities_agencyPositionDetails_positionDetails {
  id: number;
  name: string;
  prettyWorkTeam: string;
  workTeam: WorkTeamEnum;
  updatedAt: any;
}

export interface positionsSettingsPageFetchPositions_me_AgencyUser_agency_agencyCommunities_agencyPositionDetails {
  openRate: any; // the rate charged to the customer for an open shift
  positionDetails: positionsSettingsPageFetchPositions_me_AgencyUser_agency_agencyCommunities_agencyPositionDetails_positionDetails;
}

export interface positionsSettingsPageFetchPositions_me_AgencyUser_agency_agencyCommunities {
  name: string;
  agencyPositionDetails: positionsSettingsPageFetchPositions_me_AgencyUser_agency_agencyCommunities_agencyPositionDetails[];
}

export interface positionsSettingsPageFetchPositions_me_AgencyUser_agency {
  id: number;
  agencyCommunities: positionsSettingsPageFetchPositions_me_AgencyUser_agency_agencyCommunities[];
}

export interface positionsSettingsPageFetchPositions_me_AgencyUser {
  agency: positionsSettingsPageFetchPositions_me_AgencyUser_agency | null;
}

export type positionsSettingsPageFetchPositions_me =
  | positionsSettingsPageFetchPositions_me_B2bCustomer
  | positionsSettingsPageFetchPositions_me_AgencyUser;

export interface positionsSettingsPageFetchPositions {
  me: positionsSettingsPageFetchPositions_me; // returns the currently authenticated user
}

export interface positionsSettingsPageFetchPositionsVariables {
  communityId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: requestTeamMembers
// ====================================================

export interface requestTeamMembers_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface requestTeamMembers_fetchJanes_nodes_agencyCommunity_agency {
  name: string;
}

export interface requestTeamMembers_fetchJanes_nodes_agencyCommunity {
  agency: requestTeamMembers_fetchJanes_nodes_agencyCommunity_agency;
  name: string;
}

export interface requestTeamMembers_fetchJanes_nodes_avatarUrls {
  origin: string | null;
}

export interface requestTeamMembers_fetchJanes_nodes_customerReviews {
  averageRating: number | null;
  count: number;
}

export interface requestTeamMembers_fetchJanes_nodes_primaryPosition_details {
  name: string;
  id: number;
}

export interface requestTeamMembers_fetchJanes_nodes_primaryPosition_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
  openPayout: any; // the amount a jane is paid per hour or an open shift
}

export interface requestTeamMembers_fetchJanes_nodes_primaryPosition {
  details: requestTeamMembers_fetchJanes_nodes_primaryPosition_details;
  agencyDetails: requestTeamMembers_fetchJanes_nodes_primaryPosition_agencyDetails | null;
}

export interface requestTeamMembers_fetchJanes_nodes_positions_details {
  id: number;
  name: string;
}

export interface requestTeamMembers_fetchJanes_nodes_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface requestTeamMembers_fetchJanes_nodes_positions {
  details: requestTeamMembers_fetchJanes_nodes_positions_details;
  agencyDetails: requestTeamMembers_fetchJanes_nodes_positions_agencyDetails | null;
}

export interface requestTeamMembers_fetchJanes_nodes {
  agencyCommunity: requestTeamMembers_fetchJanes_nodes_agencyCommunity | null;
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: requestTeamMembers_fetchJanes_nodes_avatarUrls;
  customerReviews: requestTeamMembers_fetchJanes_nodes_customerReviews;
  primaryPosition: requestTeamMembers_fetchJanes_nodes_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  positions: requestTeamMembers_fetchJanes_nodes_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface requestTeamMembers_fetchJanes {
  totalCount: number;
  pageSize: number;
  pageCount: number;
  pageInfo: requestTeamMembers_fetchJanes_pageInfo; // Information to aid in pagination.
  nodes: requestTeamMembers_fetchJanes_nodes[]; // A list of nodes.
}

export interface requestTeamMembers {
  fetchJanes: requestTeamMembers_fetchJanes; // find all janes
}

export interface requestTeamMembersVariables {
  orderId?: number | null;
  byFacilityId?: number | null;
  positionId: number;
  filter?: FilterableJaneAttributes | null;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: requestTeamMembersCount
// ====================================================

export interface requestTeamMembersCount_fetchJanes {
  totalCount: number;
}

export interface requestTeamMembersCount {
  fetchJanes: requestTeamMembersCount_fetchJanes; // find all janes
}

export interface requestTeamMembersCountVariables {
  orderId?: number | null;
  byFacilityId?: number | null;
  positionId: number;
  filter?: FilterableJaneAttributes | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: requestTeamMembersRecurring
// ====================================================

export interface requestTeamMembersRecurring_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_agencyCommunity_agency {
  name: string;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_agencyCommunity {
  agency: requestTeamMembersRecurring_fetchJanes_nodes_agencyCommunity_agency;
  name: string;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_avatarUrls {
  origin: string | null;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_customerReviews {
  averageRating: number | null;
  count: number;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_primaryPosition_details {
  name: string;
  id: number;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_primaryPosition_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
  openPayout: any; // the amount a jane is paid per hour or an open shift
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_primaryPosition {
  details: requestTeamMembersRecurring_fetchJanes_nodes_primaryPosition_details;
  agencyDetails: requestTeamMembersRecurring_fetchJanes_nodes_primaryPosition_agencyDetails | null;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_conflictingDays {
  startTime: any;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_positions_details {
  id: number;
  name: string;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface requestTeamMembersRecurring_fetchJanes_nodes_positions {
  details: requestTeamMembersRecurring_fetchJanes_nodes_positions_details;
  agencyDetails: requestTeamMembersRecurring_fetchJanes_nodes_positions_agencyDetails | null;
}

export interface requestTeamMembersRecurring_fetchJanes_nodes {
  agencyCommunity: requestTeamMembersRecurring_fetchJanes_nodes_agencyCommunity | null;
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: requestTeamMembersRecurring_fetchJanes_nodes_avatarUrls;
  customerReviews: requestTeamMembersRecurring_fetchJanes_nodes_customerReviews;
  primaryPosition: requestTeamMembersRecurring_fetchJanes_nodes_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  conflictingDays: requestTeamMembersRecurring_fetchJanes_nodes_conflictingDays[]; // get all of the existing shifts that conflict with the given arguments
  positions: requestTeamMembersRecurring_fetchJanes_nodes_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface requestTeamMembersRecurring_fetchJanes {
  totalCount: number;
  pageSize: number;
  pageCount: number;
  pageInfo: requestTeamMembersRecurring_fetchJanes_pageInfo; // Information to aid in pagination.
  nodes: requestTeamMembersRecurring_fetchJanes_nodes[]; // A list of nodes.
}

export interface requestTeamMembersRecurring {
  fetchJanes: requestTeamMembersRecurring_fetchJanes; // find all janes
}

export interface requestTeamMembersRecurringVariables {
  orderId?: number | null;
  byFacilityId?: number | null;
  positionId: number;
  filter?: FilterableJaneAttributes | null;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  recurring: RecurringOrderAttributes;
  byStartTime?: any | null;
  byEndTime?: any | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scheduleTimeOffs
// ====================================================

export interface scheduleTimeOffs_fetchTimeOffs_pageInfo {
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface scheduleTimeOffs_fetchTimeOffs_nodes_jane {
  id: string;
}

export interface scheduleTimeOffs_fetchTimeOffs_nodes_title {
  name: TimeOffTitleEnum;
  value: any;
}

export interface scheduleTimeOffs_fetchTimeOffs_nodes {
  isEditable: boolean;
  id: string;
  startTime: any;
  endTime: any;
  jane: scheduleTimeOffs_fetchTimeOffs_nodes_jane;
  displayId: number;
  title: scheduleTimeOffs_fetchTimeOffs_nodes_title;
}

export interface scheduleTimeOffs_fetchTimeOffs {
  pageInfo: scheduleTimeOffs_fetchTimeOffs_pageInfo; // Information to aid in pagination.
  currentPage: number | null;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  nodes: scheduleTimeOffs_fetchTimeOffs_nodes[]; // A list of nodes.
}

export interface scheduleTimeOffs {
  fetchTimeOffs: scheduleTimeOffs_fetchTimeOffs; // fetch multiple time offs
}

export interface scheduleTimeOffsVariables {
  filter?: FilterableTimeOffAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchSchedulingGroups
// ====================================================

export interface fetchSchedulingGroups_fetchCommunity_groups_nodes_DynamicGroup {}

export interface fetchSchedulingGroups_fetchCommunity_groups_nodes_StaticGroup {
  id: string;
  displayId: number;
  name: string;
  createdAt: any;
  updatedAt: any;
}

export type fetchSchedulingGroups_fetchCommunity_groups_nodes =
  | fetchSchedulingGroups_fetchCommunity_groups_nodes_DynamicGroup
  | fetchSchedulingGroups_fetchCommunity_groups_nodes_StaticGroup;

export interface fetchSchedulingGroups_fetchCommunity_groups_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface fetchSchedulingGroups_fetchCommunity_groups {
  __typename: 'GroupUnionConnection';
  nodes: fetchSchedulingGroups_fetchCommunity_groups_nodes[]; // A list of nodes.
  pageCount: number;
  pageInfo: fetchSchedulingGroups_fetchCommunity_groups_pageInfo; // Information to aid in pagination.
}

export interface fetchSchedulingGroups_fetchCommunity {
  __typename: 'Community';
  id: number;
  name: string;
  groups: fetchSchedulingGroups_fetchCommunity_groups; // Fetch community groups
}

export interface fetchSchedulingGroups {
  fetchCommunity: fetchSchedulingGroups_fetchCommunity; // find a Community by ID
}

export interface fetchSchedulingGroupsVariables {
  locationId: number;
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  filter?: FilterableGroupAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: schedulingPage
// ====================================================

export interface schedulingPage_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface schedulingPage_fetchJanes_edges_node {
  id: string;
  firstName: string;
  lastName: string;
}

export interface schedulingPage_fetchJanes_edges {
  node: schedulingPage_fetchJanes_edges_node | null; // The item at the end of the edge.
}

export interface schedulingPage_fetchJanes {
  pageInfo: schedulingPage_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: schedulingPage_fetchJanes_edges[]; // A list of edges.
}

export interface schedulingPage {
  fetchJanes: schedulingPage_fetchJanes; // find all janes
}

export interface schedulingPageVariables {
  first?: number | null;
  after?: string | null;
  byFullName?: string | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: schedulingTabNavigationInput
// ====================================================

export interface schedulingTabNavigationInput_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface schedulingTabNavigationInput_fetchJanes_edges_node {
  id: string;
}

export interface schedulingTabNavigationInput_fetchJanes_edges {
  node: schedulingTabNavigationInput_fetchJanes_edges_node | null; // The item at the end of the edge.
}

export interface schedulingTabNavigationInput_fetchJanes {
  pageInfo: schedulingTabNavigationInput_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: schedulingTabNavigationInput_fetchJanes_edges[]; // A list of edges.
}

export interface schedulingTabNavigationInput {
  fetchJanes: schedulingTabNavigationInput_fetchJanes; // find all janes
}

export interface schedulingTabNavigationInputVariables {
  first?: number | null;
  after?: string | null;
  byFullName?: string | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchTeamMembers
// ====================================================

export interface searchTeamMembers_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface searchTeamMembers_fetchJanes_edges_node_primaryCommunity {
  id: string;
}

export interface searchTeamMembers_fetchJanes_edges_node_agencyCommunity {
  id: number;
}

export interface searchTeamMembers_fetchJanes_edges_node_avatarUrls {
  small: string | null;
}

export interface searchTeamMembers_fetchJanes_edges_node {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: searchTeamMembers_fetchJanes_edges_node_primaryCommunity | null;
  agencyCommunity: searchTeamMembers_fetchJanes_edges_node_agencyCommunity | null;
  avatarUrls: searchTeamMembers_fetchJanes_edges_node_avatarUrls;
}

export interface searchTeamMembers_fetchJanes_edges {
  node: searchTeamMembers_fetchJanes_edges_node | null; // The item at the end of the edge.
}

export interface searchTeamMembers_fetchJanes {
  pageInfo: searchTeamMembers_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: searchTeamMembers_fetchJanes_edges[]; // A list of edges.
}

export interface searchTeamMembers {
  fetchJanes: searchTeamMembers_fetchJanes; // find all janes
}

export interface searchTeamMembersVariables {
  first?: number | null;
  after?: string | null;
  byFullName?: string | null;
  byPrimaryCommunity?: number[] | null;
  byAgencyCommunity?: number[] | null;
  external?: boolean | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchTeamMembersForGroup
// ====================================================

export interface searchTeamMembersForGroup_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
  __typename: 'PageInfo';
}

export interface searchTeamMembersForGroup_fetchJanes_edges_node_staticGroups {
  id: string;
  displayId: number;
  name: string;
}

export interface searchTeamMembersForGroup_fetchJanes_edges_node_primaryCommunity {
  id: string;
}

export interface searchTeamMembersForGroup_fetchJanes_edges_node_agencyCommunity {
  id: number;
}

export interface searchTeamMembersForGroup_fetchJanes_edges_node_avatarUrls {
  small: string | null;
  __typename: 'AvatarUrl';
}

export interface searchTeamMembersForGroup_fetchJanes_edges_node {
  id: string;
  staticGroups: searchTeamMembersForGroup_fetchJanes_edges_node_staticGroups[]; // fetch all static groups the team member belongs to
  primaryCommunity: searchTeamMembersForGroup_fetchJanes_edges_node_primaryCommunity | null;
  agencyCommunity: searchTeamMembersForGroup_fetchJanes_edges_node_agencyCommunity | null;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: searchTeamMembersForGroup_fetchJanes_edges_node_avatarUrls;
  __typename: 'Jane';
}

export interface searchTeamMembersForGroup_fetchJanes_edges {
  node: searchTeamMembersForGroup_fetchJanes_edges_node | null; // The item at the end of the edge.
  __typename: 'JaneEdge';
}

export interface searchTeamMembersForGroup_fetchJanes {
  pageInfo: searchTeamMembersForGroup_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: searchTeamMembersForGroup_fetchJanes_edges[]; // A list of edges.
  __typename: 'JaneConnection';
}

export interface searchTeamMembersForGroup {
  fetchJanes: searchTeamMembersForGroup_fetchJanes; // find all janes
}

export interface searchTeamMembersForGroupVariables {
  groupsFilter: FilterableGroupAttributes;
  first?: number | null;
  after?: string | null;
  byFullName?: string | null;
  byPrimaryCommunity?: number[] | null;
  byAgencyCommunity?: number[] | null;
  external?: boolean | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToAgenciesOrderDetails
// ====================================================

export interface sendToAgenciesOrderDetails_fetchOrder_recurrence {
  weekdays: WeekdaysEnum[] | null;
  frequency: RecurringFrequencyEnum | null;
  expirationDate: any | null;
}

export interface sendToAgenciesOrderDetails_fetchOrder_position {
  name: string;
}

export interface sendToAgenciesOrderDetails_fetchOrder_rate {
  max: number | null;
  min: number | null;
}

export interface sendToAgenciesOrderDetails_fetchOrder_estimatedTotalCharge {
  max: number | null;
  min: number | null;
  rateLabel: any;
}

export interface sendToAgenciesOrderDetails_fetchOrder_dedicatedPool {
  name: string;
}

export interface sendToAgenciesOrderDetails_fetchOrder {
  startAt: any | null;
  endAt: any | null;
  recurrence: sendToAgenciesOrderDetails_fetchOrder_recurrence | null;
  position: sendToAgenciesOrderDetails_fetchOrder_position;
  rate: sendToAgenciesOrderDetails_fetchOrder_rate | null; // hourly rate charged to the customer
  estimatedTotalCharge: sendToAgenciesOrderDetails_fetchOrder_estimatedTotalCharge | null; // total rate charged to the customer
  dedicatedPool: sendToAgenciesOrderDetails_fetchOrder_dedicatedPool | null;
}

export interface sendToAgenciesOrderDetails {
  fetchOrder: sendToAgenciesOrderDetails_fetchOrder; // Find an order by ID
}

export interface sendToAgenciesOrderDetailsVariables {
  id: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToAgenciesPositionMinMax
// ====================================================

export interface sendToAgenciesPositionMinMax_fetchPosition_openPriceRange {
  min: any | null;
  max: any | null;
}

export interface sendToAgenciesPositionMinMax_fetchPosition {
  openPriceRange: sendToAgenciesPositionMinMax_fetchPosition_openPriceRange | null;
}

export interface sendToAgenciesPositionMinMax {
  fetchPosition: sendToAgenciesPositionMinMax_fetchPosition;
}

export interface sendToAgenciesPositionMinMaxVariables {
  positionId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToAgenciesRequestTeamMemberSelectedJanePriceRange
// ====================================================

export interface sendToAgenciesRequestTeamMemberSelectedJanePriceRange_fetchJane_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface sendToAgenciesRequestTeamMemberSelectedJanePriceRange_fetchJane_positions {
  agencyDetails: sendToAgenciesRequestTeamMemberSelectedJanePriceRange_fetchJane_positions_agencyDetails | null;
}

export interface sendToAgenciesRequestTeamMemberSelectedJanePriceRange_fetchJane {
  positions: sendToAgenciesRequestTeamMemberSelectedJanePriceRange_fetchJane_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface sendToAgenciesRequestTeamMemberSelectedJanePriceRange {
  fetchJane: sendToAgenciesRequestTeamMemberSelectedJanePriceRange_fetchJane; // find a single jane
}

export interface sendToAgenciesRequestTeamMemberSelectedJanePriceRangeVariables {
  janeId: string;
  positionId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToAgenciesSummaryView
// ====================================================

export interface sendToAgenciesSummaryView_fetchJane_avatarUrls {
  small: string | null;
}

export interface sendToAgenciesSummaryView_fetchJane_positions_details {
  id: number;
  name: string;
}

export interface sendToAgenciesSummaryView_fetchJane_positions_agencyDetails_positionDetails_openPriceRange {
  rangeLabel: string | null;
  rawMin: number | null;
  rawMax: number | null;
}

export interface sendToAgenciesSummaryView_fetchJane_positions_agencyDetails_positionDetails {
  openPriceRange: sendToAgenciesSummaryView_fetchJane_positions_agencyDetails_positionDetails_openPriceRange | null;
}

export interface sendToAgenciesSummaryView_fetchJane_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
  positionDetails: sendToAgenciesSummaryView_fetchJane_positions_agencyDetails_positionDetails;
}

export interface sendToAgenciesSummaryView_fetchJane_positions {
  details: sendToAgenciesSummaryView_fetchJane_positions_details;
  agencyDetails: sendToAgenciesSummaryView_fetchJane_positions_agencyDetails | null;
}

export interface sendToAgenciesSummaryView_fetchJane {
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: sendToAgenciesSummaryView_fetchJane_avatarUrls;
  belongsToPool: boolean; // This boolean field is used to show the tick on the Agency Members
  positions: sendToAgenciesSummaryView_fetchJane_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface sendToAgenciesSummaryView {
  fetchJane: sendToAgenciesSummaryView_fetchJane; // find a single jane
}

export interface sendToAgenciesSummaryViewVariables {
  jane_id: string;
  positionId?: number | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToAgenciesSummaryViewTeamMembersCount
// ====================================================

export interface sendToAgenciesSummaryViewTeamMembersCount_fetchJanes {
  totalCount: number;
}

export interface sendToAgenciesSummaryViewTeamMembersCount {
  fetchJanes: sendToAgenciesSummaryViewTeamMembersCount_fetchJanes; // find all janes
}

export interface sendToAgenciesSummaryViewTeamMembersCountVariables {
  orderId?: number | null;
  byFacilityId?: number | null;
  positionId: number;
  filter?: FilterableJaneAttributes | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToSelectedAgenciesFetchAgenciesByOrderId
// ====================================================

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_averageRating {
  count: number;
  value: number | null;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_availableJanes {
  count: number;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agency_liability {
  licenceNumber: string | null;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agency_avatarUrls {
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agency {
  liability: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agency_liability | null;
  avatarUrls: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agency_avatarUrls;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agencyPositionDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes {
  id: number;
  name: string;
  averageRating: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_averageRating;
  availableJanes: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_availableJanes;
  agency: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agency;
  agencyPositionDetails: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes_agencyPositionDetails[];
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities {
  totalCount: number;
  pageSize: number;
  pageCount: number;
  pageInfo: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_pageInfo; // Information to aid in pagination.
  nodes: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities_nodes[]; // A list of nodes.
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderId {
  fetchAgencyCommunities: sendToSelectedAgenciesFetchAgenciesByOrderId_fetchAgencyCommunities; // returns all agency communities for the current user
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdVariables {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  byName?: string | null;
  orderId?: string | null;
  byRateLessThanOrEqualTo?: number | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated
// ====================================================

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_availableJanes {
  count: number;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_agency_liability {
  licenceNumber: string | null;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_agency {
  liability: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_agency_liability | null;
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_agencyPositionDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes {
  id: number;
  name: string;
  availableJanes: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_availableJanes;
  agency: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_agency;
  agencyPositionDetails: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes_agencyPositionDetails[];
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities {
  nodes: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities_nodes[]; // A list of nodes.
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated {
  fetchAgencyCommunities: sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated_fetchAgencyCommunities; // returns all agency communities for the current user
}

export interface sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginatedVariables {
  orderId?: string | null;
  selectedAgencyCommunities?: number[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchGroupDetails
// ====================================================

export interface fetchGroupDetails_node_GroupJane {}

export interface fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane_avatarUrls {
  small: string | null;
}

export interface fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane_avatarUrls;
  __typename: 'Jane';
}

export interface fetchGroupDetails_node_StaticGroup_groupJanes_nodes {
  id: string; // ID of the object.
  displayId: number;
  jane: fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane;
}

export interface fetchGroupDetails_node_StaticGroup_groupJanes {
  nodes: fetchGroupDetails_node_StaticGroup_groupJanes_nodes[]; // A list of nodes.
}

export interface fetchGroupDetails_node_StaticGroup {
  __typename: 'StaticGroup';
  id: string;
  name: string;
  updatedAt: any;
  createdAt: any;
  displayId: number;
  groupJanes: fetchGroupDetails_node_StaticGroup_groupJanes;
}

export interface fetchGroupDetails_node_DynamicGroup_criteria {
  employmentStatuses: GroupEmploymentStatusEnum[];
  positionTypes: PositionTypeEnum[];
  maximumPerDayMinutes: number | null;
  maximumPerPeriodMinutes: number | null;
  minimumPerDayMinutes: number;
  minimumPerPeriodMinutes: number;
}

export interface fetchGroupDetails_node_DynamicGroup {
  __typename: 'DynamicGroup';
  id: string;
  name: string;
  criteria: fetchGroupDetails_node_DynamicGroup_criteria;
  updatedAt: any;
  createdAt: any;
  displayId: number;
}

export type fetchGroupDetails_node =
  | fetchGroupDetails_node_GroupJane
  | fetchGroupDetails_node_StaticGroup
  | fetchGroupDetails_node_DynamicGroup;

export interface fetchGroupDetails {
  node: fetchGroupDetails_node | null; // Fetches an object given its ID.
}

export interface fetchGroupDetailsVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchGroups
// ====================================================

export interface fetchGroups_fetchCommunity_groups_nodes_StaticGroup {
  __typename: 'StaticGroup';
  id: string;
  displayId: number;
  name: string;
  createdAt: any;
  updatedAt: any;
}

export interface fetchGroups_fetchCommunity_groups_nodes_DynamicGroup {
  __typename: 'DynamicGroup';
  id: string;
  displayId: number;
  name: string;
  createdAt: any;
  updatedAt: any;
}

export type fetchGroups_fetchCommunity_groups_nodes =
  | fetchGroups_fetchCommunity_groups_nodes_StaticGroup
  | fetchGroups_fetchCommunity_groups_nodes_DynamicGroup;

export interface fetchGroups_fetchCommunity_groups_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface fetchGroups_fetchCommunity_groups {
  __typename: 'GroupUnionConnection';
  nodes: fetchGroups_fetchCommunity_groups_nodes[]; // A list of nodes.
  pageCount: number;
  pageInfo: fetchGroups_fetchCommunity_groups_pageInfo; // Information to aid in pagination.
}

export interface fetchGroups_fetchCommunity {
  __typename: 'Community';
  id: string;
  name: string;
  groups: fetchGroups_fetchCommunity_groups; // Fetch community groups
}

export interface fetchGroups {
  fetchCommunity: fetchGroups_fetchCommunity; // find a Community by ID
}

export interface fetchGroupsVariables {
  locationId: number;
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  filter?: FilterableGroupAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchCommunity
// ====================================================

export interface fetchCommunity_fetchCommunity_communityFeatures_feature {
  enabled: boolean;
  id: number;
  name: string;
}

export interface fetchCommunity_fetchCommunity_communityFeatures {
  enabled: boolean;
  id: number;
  feature: fetchCommunity_fetchCommunity_communityFeatures_feature | null;
}

export interface fetchCommunity_fetchCommunity_location {
  id: number;
}

export interface fetchCommunity_fetchCommunity_positions {
  name: string;
}

export interface fetchCommunity_fetchCommunity {
  id: number;
  name: string;
  availabilityStatus: number;
  checkInProcedure: string | null;
  communityFeatures: fetchCommunity_fetchCommunity_communityFeatures[] | null;
  document: string | null;
  location: fetchCommunity_fetchCommunity_location | null;
  locationHistory: string | null;
  policies: string | null; // String
  positions: fetchCommunity_fetchCommunity_positions[];
  vaccinationRequired: boolean;
  vaccinationRequiredLastUpdated: any | null;
  vaccinationRequiredLastUpdatedBy: string | null;
}

export interface fetchCommunity {
  fetchCommunity: fetchCommunity_fetchCommunity; // find a Community by ID
}

export interface fetchCommunityVariables {
  id: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftCardFetchOrder
// ====================================================

export interface shiftCardFetchOrder_fetchOrder_orderSchedule {
  id: number;
}

export interface shiftCardFetchOrder_fetchOrder_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface shiftCardFetchOrder_fetchOrder_activities_event {
  name: ActivityEventEnum;
}

export interface shiftCardFetchOrder_fetchOrder_activities {
  event: shiftCardFetchOrder_fetchOrder_activities_event;
}

export interface shiftCardFetchOrder_fetchOrder_currentlyActiveCalloutList {
  id: string;
  isCompleted: boolean;
  createdAt: any;
  updatedAt: any;
  totalJanes: number;
  waitingForManualApproval: boolean;
  estimatedCompletedAt: any | null;
  totalNotifiedAndSkipped: number;
}

export interface shiftCardFetchOrder_fetchOrder_status {
  name: any;
  value: OrderStatusEnum;
}

export interface shiftCardFetchOrder_fetchOrder_community_location {
  timeZone: string | null;
}

export interface shiftCardFetchOrder_fetchOrder_community {
  id: number;
  name: string;
  location: shiftCardFetchOrder_fetchOrder_community_location | null;
}

export interface shiftCardFetchOrder_fetchOrder_position {
  name: string;
  id: number;
}

export interface shiftCardFetchOrder_fetchOrder_mainJane_primaryCommunity {
  id: string;
}

export interface shiftCardFetchOrder_fetchOrder_mainJane_avatarUrls {
  small: string | null;
  origin: string | null;
  large: string | null;
}

export interface shiftCardFetchOrder_fetchOrder_mainJane_agencyCommunity {
  id: number;
}

export interface shiftCardFetchOrder_fetchOrder_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  primaryCommunity: shiftCardFetchOrder_fetchOrder_mainJane_primaryCommunity | null;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: shiftCardFetchOrder_fetchOrder_mainJane_avatarUrls;
  agencyCommunity: shiftCardFetchOrder_fetchOrder_mainJane_agencyCommunity | null;
}

export interface shiftCardFetchOrder_fetchOrder_review {
  rating: number | null;
}

export interface shiftCardFetchOrder_fetchOrder_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface shiftCardFetchOrder_fetchOrder {
  orderSchedule: shiftCardFetchOrder_fetchOrder_orderSchedule;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  priority: OrderPriorityEnum;
  bidders: shiftCardFetchOrder_fetchOrder_bidders[];
  id: number;
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  activities: shiftCardFetchOrder_fetchOrder_activities[];
  canAssign: boolean;
  autoRebid: boolean;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  dedicated: boolean;
  allowOvertime: boolean;
  auditTrailVersion: AuditTrailVersionEnum;
  currentlyActiveCalloutList: shiftCardFetchOrder_fetchOrder_currentlyActiveCalloutList | null;
  status: shiftCardFetchOrder_fetchOrder_status;
  startAt: any | null;
  endAt: any | null;
  community: shiftCardFetchOrder_fetchOrder_community;
  position: shiftCardFetchOrder_fetchOrder_position;
  mainJane: shiftCardFetchOrder_fetchOrder_mainJane | null;
  review: shiftCardFetchOrder_fetchOrder_review | null;
  recurrence: shiftCardFetchOrder_fetchOrder_recurrence | null;
}

export interface shiftCardFetchOrder {
  fetchOrder: shiftCardFetchOrder_fetchOrder; // Find an order by ID
}

export interface shiftCardFetchOrderVariables {
  orderId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftDistributionRulesGroups
// ====================================================

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
  __typename: 'PageInfo';
}

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_community {
  displayId: string;
  name: string;
}

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules_group_StaticGroup {
  id: number;
  name: string;
}

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules_group_DynamicGroup {
  id: number;
  name: string;
}

export type shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules_group =
  | shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules_group_StaticGroup
  | shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules_group_DynamicGroup;

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules {
  id: number;
  rank: number;
  waitTimeMinutes: number | null;
  group: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules_group;
}

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_position {
  id: number;
  name: string;
}

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes {
  id: number; // id for http api calls
  skipWaitTimeThresholdMinutes: number;
  community: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_community;
  groupRules: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_groupRules[];
  createdAt: any;
  position: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes_position | null;
  updatedAt: any;
  __typename: 'ShiftDistributionRule';
}

export interface shiftDistributionRulesGroups_fetchShiftDistributionRules {
  totalCount: number;
  pageInfo: shiftDistributionRulesGroups_fetchShiftDistributionRules_pageInfo; // Information to aid in pagination.
  nodes: shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes[]; // A list of nodes.
  __typename: 'ShiftDistributionRuleConnection';
}

export interface shiftDistributionRulesGroups {
  fetchShiftDistributionRules: shiftDistributionRulesGroups_fetchShiftDistributionRules;
}

export interface shiftDistributionRulesGroupsVariables {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: FilterableShiftDistributionRuleAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchSharingDetails
// ====================================================

export interface fetchSharingDetails_fetchCommunities_nodes_communityFeatures_feature {
  enabled: boolean;
  id: number;
  name: string;
}

export interface fetchSharingDetails_fetchCommunities_nodes_communityFeatures {
  enabled: boolean;
  feature: fetchSharingDetails_fetchCommunities_nodes_communityFeatures_feature | null;
  id: number;
}

export interface fetchSharingDetails_fetchCommunities_nodes {
  __typename: 'Community';
  id: number;
  name: string;
  communityFeatures: fetchSharingDetails_fetchCommunities_nodes_communityFeatures[] | null;
}

export interface fetchSharingDetails_fetchCommunities {
  __typename: 'CommunityConnection';
  nodes: fetchSharingDetails_fetchCommunities_nodes[]; // A list of nodes.
}

export interface fetchSharingDetails {
  __typename: 'Query';
  fetchCommunities: fetchSharingDetails_fetchCommunities; // returns all communities for the current user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fetchTieringDetails
// ====================================================

export interface fetchTieringDetails_fetchCommunity_organization {
  name: string;
}

export interface fetchTieringDetails_fetchCommunity_communityFeatures_feature {
  enabled: boolean;
  id: number;
  name: string;
}

export interface fetchTieringDetails_fetchCommunity_communityFeatures {
  enabled: boolean;
  id: number;
  feature: fetchTieringDetails_fetchCommunity_communityFeatures_feature | null;
}

export interface fetchTieringDetails_fetchCommunity_escalationTimes {
  createdAt: any;
  id: string;
  priority: EscalationTimePriorityEnum;
  priorityTime: number;
  updatedAt: any;
}

export interface fetchTieringDetails_fetchCommunity_biddingTimes {
  id: string;
  leadTime: number;
  priorityTime: number;
}

export interface fetchTieringDetails_fetchCommunity_leadTimes {
  createdAt: any;
  id: string;
  priority: LeadTimePriorityEnum;
  priorityTime: number;
  updatedAt: any;
}

export interface fetchTieringDetails_fetchCommunity {
  id: number;
  name: string;
  organization: fetchTieringDetails_fetchCommunity_organization | null;
  communityFeatures: fetchTieringDetails_fetchCommunity_communityFeatures[] | null;
  escalationTimes: fetchTieringDetails_fetchCommunity_escalationTimes[] | null;
  biddingTimes: fetchTieringDetails_fetchCommunity_biddingTimes[] | null;
  leadTimes: fetchTieringDetails_fetchCommunity_leadTimes[] | null;
}

export interface fetchTieringDetails {
  fetchCommunity: fetchTieringDetails_fetchCommunity; // find a Community by ID
}

export interface fetchTieringDetailsVariables {
  id: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftModalOrderDetails
// ====================================================

export interface shiftModalOrderDetails_fetchOrder_draftShift {
  id: number;
  description: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface shiftModalOrderDetails_fetchOrder_currentlyActiveCalloutList_manualApprovalIndexes {
  index: number;
  groupName: string;
}

export interface shiftModalOrderDetails_fetchOrder_currentlyActiveCalloutList {
  id: string;
  isCompleted: boolean;
  createdAt: any;
  updatedAt: any;
  totalJanes: number;
  waitingForManualApproval: boolean;
  estimatedCompletedAt: any | null;
  totalNotifiedAndSkipped: number;
  manualApprovalIndexes: shiftModalOrderDetails_fetchOrder_currentlyActiveCalloutList_manualApprovalIndexes[];
}

export interface shiftModalOrderDetails_fetchOrder_activities_metadata_acceptedFor {
  name: string;
}

export interface shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits {
  fieldName: string | null;
  fieldType: FeedableEditDropdownEnum | null;
  newValuePretty: string | null;
  oldValuePretty: string | null;
  newValue: string | null;
  oldValue: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_metadata {
  acceptedFor: shiftModalOrderDetails_fetchOrder_activities_metadata_acceptedFor | null;
  calloutListId: string | null;
  qualifiedAgencyJanesCount: number | null;
  reasonMessage: string | null;
  reasonCategory: string | null;
  feedableEdits: shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits[] | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_event {
  name: ActivityEventEnum;
  value: any;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_User_avatarUrls {
  origin: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_User {
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  title: string;
  isMobile: boolean;
  avatarUrls: shiftModalOrderDetails_fetchOrder_activities_user_User_avatarUrls;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_Jane_avatarUrls {
  origin: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_Jane {
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  isMobile: boolean;
  avatarUrls: shiftModalOrderDetails_fetchOrder_activities_user_Jane_avatarUrls;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_B2bCustomer_avatarUrls {
  origin: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_B2bCustomer {
  firstName: string;
  lastName: string;
  avatarUrls: shiftModalOrderDetails_fetchOrder_activities_user_B2bCustomer_avatarUrls;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_AgencyUser_avatarUrls {
  origin: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_AgencyUser {
  firstName: string;
  lastName: string;
  avatarUrls: shiftModalOrderDetails_fetchOrder_activities_user_AgencyUser_avatarUrls;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_SecondaryUser_avatarUrls {
  origin: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_SecondaryUser {
  firstName: string;
  lastName: string;
  avatarUrls: shiftModalOrderDetails_fetchOrder_activities_user_SecondaryUser_avatarUrls;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_Admin_avatarUrls {
  origin: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities_user_Admin {
  firstName: string;
  lastName: string;
  avatarUrls: shiftModalOrderDetails_fetchOrder_activities_user_Admin_avatarUrls;
}

export type shiftModalOrderDetails_fetchOrder_activities_user =
  | shiftModalOrderDetails_fetchOrder_activities_user_User
  | shiftModalOrderDetails_fetchOrder_activities_user_Jane
  | shiftModalOrderDetails_fetchOrder_activities_user_B2bCustomer
  | shiftModalOrderDetails_fetchOrder_activities_user_AgencyUser
  | shiftModalOrderDetails_fetchOrder_activities_user_SecondaryUser
  | shiftModalOrderDetails_fetchOrder_activities_user_Admin;

export interface shiftModalOrderDetails_fetchOrder_activities_order_mainJane {
  shorthandName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
}

export interface shiftModalOrderDetails_fetchOrder_activities_order {
  mainJane: shiftModalOrderDetails_fetchOrder_activities_order_mainJane | null;
}

export interface shiftModalOrderDetails_fetchOrder_activities {
  metadata: shiftModalOrderDetails_fetchOrder_activities_metadata | null;
  createdAt: any;
  event: shiftModalOrderDetails_fetchOrder_activities_event;
  user: shiftModalOrderDetails_fetchOrder_activities_user | null;
  order: shiftModalOrderDetails_fetchOrder_activities_order;
  flag: string | null;
  message: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_community_customFields_customFieldOptions {
  value: string;
}

export interface shiftModalOrderDetails_fetchOrder_community_customFields {
  id: number;
  name: string;
  fieldType: CustomFieldTypeEnum;
  mobile: boolean;
  placeholder: string;
  prefillDefault: boolean;
  customFieldOptions:
    | shiftModalOrderDetails_fetchOrder_community_customFields_customFieldOptions[]
    | null;
}

export interface shiftModalOrderDetails_fetchOrder_community_location {
  timeZone: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_community {
  id: number;
  __typename: 'Community';
  name: string;
  b2bUserShiftEditThreshold: number | null;
  secondaryUserShiftEditThreshold: number | null;
  customFields: shiftModalOrderDetails_fetchOrder_community_customFields[];
  location: shiftModalOrderDetails_fetchOrder_community_location | null;
}

export interface shiftModalOrderDetails_fetchOrder_customFields {
  customFieldId: number;
  mobile: boolean;
  name: string;
  value: string;
}

export interface shiftModalOrderDetails_fetchOrder_estimatedTotalCharge {
  max: number | null;
  min: number | null;
  rateLabel: any;
}

export interface shiftModalOrderDetails_fetchOrder_review {
  comment: string | null;
  rating: number | null;
  updatedAt: any;
}

export interface shiftModalOrderDetails_fetchOrder_location {
  streetNumber: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  latitude: number | null;
  longitude: number | null;
  fullAddress: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_mainJane_primaryCommunity {
  id: string;
}

export interface shiftModalOrderDetails_fetchOrder_mainJane_avatarUrls {
  small: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_mainJane_agencyCommunity {
  id: number;
  name: string;
  contactPhone: string;
}

export interface shiftModalOrderDetails_fetchOrder_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  primaryCommunity: shiftModalOrderDetails_fetchOrder_mainJane_primaryCommunity | null;
  acceptedByAgency: boolean;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: shiftModalOrderDetails_fetchOrder_mainJane_avatarUrls;
  agencyCommunity: shiftModalOrderDetails_fetchOrder_mainJane_agencyCommunity | null;
}

export interface shiftModalOrderDetails_fetchOrder_orderSchedule_mainOrderJane_assignedBy {
  displayId: string;
}

export interface shiftModalOrderDetails_fetchOrder_orderSchedule_mainOrderJane {
  assignedBy: shiftModalOrderDetails_fetchOrder_orderSchedule_mainOrderJane_assignedBy | null;
}

export interface shiftModalOrderDetails_fetchOrder_orderSchedule_timeSheet_status {
  value: TimeSheetStatusEnum;
}

export interface shiftModalOrderDetails_fetchOrder_orderSchedule_timeSheet {
  notes: string | null;
  status: shiftModalOrderDetails_fetchOrder_orderSchedule_timeSheet_status;
}

export interface shiftModalOrderDetails_fetchOrder_orderSchedule {
  id: number;
  breakTime: string;
  mainOrderJane: shiftModalOrderDetails_fetchOrder_orderSchedule_mainOrderJane | null;
  timeSheet: shiftModalOrderDetails_fetchOrder_orderSchedule_timeSheet | null;
}

export interface shiftModalOrderDetails_fetchOrder_dedicatedPool {
  id: string;
}

export interface shiftModalOrderDetails_fetchOrder_position_openPriceRange {
  max: any | null;
  min: any | null;
  rangeLabel: string | null;
}

export interface shiftModalOrderDetails_fetchOrder_position {
  id: number;
  name: string;
  openPriceRange: shiftModalOrderDetails_fetchOrder_position_openPriceRange | null;
}

export interface shiftModalOrderDetails_fetchOrder_rate {
  max: number | null;
  min: number | null;
  rateLabel: any;
}

export interface shiftModalOrderDetails_fetchOrder_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
  recurrenceLabel: string | null;
  interval: number | null;
  weekdays: WeekdaysEnum[] | null;
  expirationDate: any | null;
}

export interface shiftModalOrderDetails_fetchOrder_futureShifts_orderSchedule {
  id: number;
}

export interface shiftModalOrderDetails_fetchOrder_futureShifts {
  orderSchedule: shiftModalOrderDetails_fetchOrder_futureShifts_orderSchedule;
  id: number;
}

export interface shiftModalOrderDetails_fetchOrder_status {
  name: any;
  value: OrderStatusEnum;
}

export interface shiftModalOrderDetails_fetchOrder {
  relayId: string;
  canAssign: boolean;
  draftShift: shiftModalOrderDetails_fetchOrder_draftShift | null;
  biddable: boolean;
  autoRebid: boolean;
  createdAt: any;
  biddingTimeEndsAt: any | null;
  priority: OrderPriorityEnum;
  bidders: shiftModalOrderDetails_fetchOrder_bidders[];
  currentlyActiveCalloutList: shiftModalOrderDetails_fetchOrder_currentlyActiveCalloutList | null;
  activities: shiftModalOrderDetails_fetchOrder_activities[];
  acceptedBySharedTeamMember: boolean | null;
  allowOvertime: boolean;
  auditTrailVersion: AuditTrailVersionEnum;
  canSendToAgency: boolean;
  community: shiftModalOrderDetails_fetchOrder_community;
  customFields: shiftModalOrderDetails_fetchOrder_customFields[];
  dedicated: boolean;
  description: string | null;
  endAt: any | null;
  estimatedTotalCharge: shiftModalOrderDetails_fetchOrder_estimatedTotalCharge | null; // total rate charged to the customer
  id: number;
  isAway: boolean | null;
  isEditable: boolean;
  isDeletable: boolean;
  isJaneReleaseAllowed: boolean;
  isReviewAllowed: boolean;
  noShow: boolean;
  review: shiftModalOrderDetails_fetchOrder_review | null;
  location: shiftModalOrderDetails_fetchOrder_location | null;
  mainJane: shiftModalOrderDetails_fetchOrder_mainJane | null;
  orderSchedule: shiftModalOrderDetails_fetchOrder_orderSchedule;
  dedicatedPool: shiftModalOrderDetails_fetchOrder_dedicatedPool | null;
  position: shiftModalOrderDetails_fetchOrder_position;
  rate: shiftModalOrderDetails_fetchOrder_rate | null; // hourly rate charged to the customer
  recurrence: shiftModalOrderDetails_fetchOrder_recurrence | null;
  futureShifts: shiftModalOrderDetails_fetchOrder_futureShifts[];
  resident: string | null;
  sentToAgencies: boolean;
  startAt: any | null;
  startDate: any | null;
  status: shiftModalOrderDetails_fetchOrder_status;
  totalDuration: number | null;
}

export interface shiftModalOrderDetails {
  fetchOrder: shiftModalOrderDetails_fetchOrder; // Find an order by ID
}

export interface shiftModalOrderDetailsVariables {
  id: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftModalOrderDetailsCalloutList
// ====================================================

export interface shiftModalOrderDetailsCalloutList_node_DynamicGroup {
  id: string; // ID of the object.
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_manualApprovalIndexes {
  groupName: string;
  index: number;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_order_orderSchedule {
  id: number;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_order_location {
  timeZone: string | null;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_order {
  orderSchedule: shiftModalOrderDetailsCalloutList_node_CalloutList_order_orderSchedule;
  biddable: boolean;
  location: shiftModalOrderDetailsCalloutList_node_CalloutList_order_location | null;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_calloutStatus {
  name: any;
  value: CalloutStatusEnum;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_accessStatus {
  name: AccessStatusEnum;
  value: any;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_primaryCommunity {
  id: number;
  name: string;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_avatarUrls {
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane {
  id: string;
  accessStatus: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_accessStatus;
  primaryCommunity: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_primaryCommunity | null;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_avatarUrls;
  employmentStatus: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane_employmentStatus;
  firstName: string;
  lastName: string;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_manualApproval {
  approvedAt: any; // The date and time the callout was manually approved to continue, alias for `created_at`
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_notNotifiedLogs {
  createdAt: any;
  updatedAt: any;
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes {
  calloutStatus: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_calloutStatus;
  estimatedCalloutTime: any | null;
  jane: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_jane;
  manualApproval: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_manualApproval | null;
  notifiedAt: any | null;
  notNotifiedLogs: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes_notNotifiedLogs[];
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes {
  currentPage: number | null;
  pageInfo: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_pageInfo; // Information to aid in pagination.
  pageCount: number;
  pageSize: number;
  totalCount: number;
  nodes: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes_nodes[]; // A list of nodes.
}

export interface shiftModalOrderDetailsCalloutList_node_CalloutList {
  id: string; // ID of the object.
  __typename: 'CalloutList';
  totalJanes: number;
  totalNotifiedAndSkipped: number;
  waitingForManualApproval: boolean;
  updatedAt: any;
  estimatedCompletedAt: any | null;
  manualApprovalIndexes: shiftModalOrderDetailsCalloutList_node_CalloutList_manualApprovalIndexes[];
  order: shiftModalOrderDetailsCalloutList_node_CalloutList_order;
  calloutListJanes: shiftModalOrderDetailsCalloutList_node_CalloutList_calloutListJanes;
}

export type shiftModalOrderDetailsCalloutList_node =
  | shiftModalOrderDetailsCalloutList_node_DynamicGroup
  | shiftModalOrderDetailsCalloutList_node_CalloutList;

export interface shiftModalOrderDetailsCalloutList {
  node: shiftModalOrderDetailsCalloutList_node | null; // Fetches an object given its ID.
}

export interface shiftModalOrderDetailsCalloutListVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftModalRequestSelectAgencies
// ====================================================

export interface shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes_availableJanes_janes_nodes {
  id: string;
  shorthandName: string;
}

export interface shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes_availableJanes_janes {
  nodes: shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes_availableJanes_janes_nodes[]; // A list of nodes.
}

export interface shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes_availableJanes {
  count: number;
  janes: shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes_availableJanes_janes;
}

export interface shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes {
  name: string;
  availableJanes: shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes_availableJanes;
}

export interface shiftModalRequestSelectAgencies_fetchAgencyCommunities {
  nodes: shiftModalRequestSelectAgencies_fetchAgencyCommunities_nodes[]; // A list of nodes.
}

export interface shiftModalRequestSelectAgencies {
  fetchAgencyCommunities: shiftModalRequestSelectAgencies_fetchAgencyCommunities; // returns all agency communities for the current user
}

export interface shiftModalRequestSelectAgenciesVariables {
  byPositionId?: number | null;
  byName?: string | null;
  byRateLessThanOrEqualTo?: number | null;
  orderId?: string | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
  sort?: SortableAgencyCommunityAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftModalTimeSheetDetails
// ====================================================

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_acceptedHourlyRate {
  rawCurrency: number | null;
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_approvedCustomerCost {
  rawCurrency: number | null;
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_approvedTotalTime {
  rawTime: number | null;
  time: any | null;
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_clockInStatus {
  prettyValue: any; // pretty value for ClockInStatus, concrete value always String
  value: ClockInStatusEnum;
  __typename: 'ClockInStatus';
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_clockOutStatus {
  prettyValue: any; // pretty value for ClockOutStatus, concrete value always String
  value: ClockOutStatusEnum;
  __typename: 'ClockOutStatus';
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_status {
  name: any;
  value: TimeSheetStatusEnum;
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet {
  id: number;
  acceptedHourlyRate: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_acceptedHourlyRate | null;
  approvedAt: any | null;
  approvedBy: string | null;
  approvedCustomerCost: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_approvedCustomerCost | null;
  approvedEndTime: any | null;
  approvedStartTime: any | null;
  approvedTotalTime: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_approvedTotalTime | null;
  approvedUnpaidBreakTime: string | null;
  autoApproved: boolean;
  clockInOffSite: boolean;
  clockOutOffSite: boolean;
  clockInDistance: number | null;
  clockOutDistance: number | null;
  clockInStatus: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_clockInStatus | null;
  clockOutStatus: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_clockOutStatus | null;
  clockIn: any | null;
  clockOut: any | null;
  createdAt: any;
  price: number | null;
  status: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet_status;
  unpaidBreakTime: string;
  updatedAt: any;
}

export interface shiftModalTimeSheetDetails_fetchTimeSheet {
  id: number;
  startTime: any;
  endTime: any;
  breakTime: string;
  actualStart: any | null;
  actualEnd: any | null;
  timeSheet: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet | null;
}

export interface shiftModalTimeSheetDetails {
  fetchTimeSheet: shiftModalTimeSheetDetails_fetchTimeSheet; // find a timesheet by order schedule id
}

export interface shiftModalTimeSheetDetailsVariables {
  orderScheduleId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftSummaryJaneRowFetchJane
// ====================================================

export interface shiftSummaryJaneRowFetchJane_fetchJane_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface shiftSummaryJaneRowFetchJane_fetchJane_positions_details {
  id: number;
  name: string;
}

export interface shiftSummaryJaneRowFetchJane_fetchJane_positions_agencyDetails {
  openRate: any; // the rate charged to the customer for an open shift
}

export interface shiftSummaryJaneRowFetchJane_fetchJane_positions {
  details: shiftSummaryJaneRowFetchJane_fetchJane_positions_details;
  agencyDetails: shiftSummaryJaneRowFetchJane_fetchJane_positions_agencyDetails | null;
}

export interface shiftSummaryJaneRowFetchJane_fetchJane {
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: shiftSummaryJaneRowFetchJane_fetchJane_avatarUrls;
  positions: shiftSummaryJaneRowFetchJane_fetchJane_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
}

export interface shiftSummaryJaneRowFetchJane {
  fetchJane: shiftSummaryJaneRowFetchJane_fetchJane; // find a single jane
}

export interface shiftSummaryJaneRowFetchJaneVariables {
  janeId: string;
  positionId?: number | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftSummaryViewTeamMembers
// ====================================================

export interface shiftSummaryViewTeamMembers_fetchJanes_nodes_avatarUrls {
  origin: string | null;
}

export interface shiftSummaryViewTeamMembers_fetchJanes_nodes {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: shiftSummaryViewTeamMembers_fetchJanes_nodes_avatarUrls;
}

export interface shiftSummaryViewTeamMembers_fetchJanes {
  nodes: shiftSummaryViewTeamMembers_fetchJanes_nodes[]; // A list of nodes.
}

export interface shiftSummaryViewTeamMembers {
  fetchJanes: shiftSummaryViewTeamMembers_fetchJanes; // find all janes
}

export interface shiftSummaryViewTeamMembersVariables {
  byJaneIds?: string[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftsCalendarPage
// ====================================================

export interface shiftsCalendarPage_groupCountOrders_group {
  fieldName: string;
  fieldType: string;
  fieldValue: any;
}

export interface shiftsCalendarPage_groupCountOrders_counted_subGroups {
  fieldName: string;
  fieldType: string;
  fieldValue: any;
}

export interface shiftsCalendarPage_groupCountOrders_counted {
  subGroups: shiftsCalendarPage_groupCountOrders_counted_subGroups[];
  value: number;
}

export interface shiftsCalendarPage_groupCountOrders {
  group: shiftsCalendarPage_groupCountOrders_group;
  counted: shiftsCalendarPage_groupCountOrders_counted[];
}

export interface shiftsCalendarPage {
  groupCountOrders: shiftsCalendarPage_groupCountOrders[];
}

export interface shiftsCalendarPageVariables {
  groupBy: GroupCountableOrderAttributes[];
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftPageDaySelection
// ====================================================

export interface shiftPageDaySelection_fetchOrders_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface shiftPageDaySelection_fetchOrders_edges_node_orderSchedule {
  id: number;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_activities_event {
  name: ActivityEventEnum;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_activities {
  event: shiftPageDaySelection_fetchOrders_edges_node_activities_event;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_currentlyActiveCalloutList {
  id: string;
  isCompleted: boolean;
  createdAt: any;
  updatedAt: any;
  totalJanes: number;
  waitingForManualApproval: boolean;
  estimatedCompletedAt: any | null;
  totalNotifiedAndSkipped: number;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_status {
  name: any;
  value: OrderStatusEnum;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_community_location {
  timeZone: string | null;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: shiftPageDaySelection_fetchOrders_edges_node_community_location | null;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_position {
  name: string;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_mainJane_primaryCommunity {
  id: string;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_mainJane_avatarUrls {
  small: string | null;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_mainJane_agencyCommunity {
  id: number;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: shiftPageDaySelection_fetchOrders_edges_node_mainJane_primaryCommunity | null;
  avatarUrls: shiftPageDaySelection_fetchOrders_edges_node_mainJane_avatarUrls;
  agencyCommunity: shiftPageDaySelection_fetchOrders_edges_node_mainJane_agencyCommunity | null;
}

export interface shiftPageDaySelection_fetchOrders_edges_node_recurrence {
  id: number;
  frequency: RecurringFrequencyEnum | null;
}

export interface shiftPageDaySelection_fetchOrders_edges_node {
  id: number;
  orderSchedule: shiftPageDaySelection_fetchOrders_edges_node_orderSchedule;
  allowOvertime: boolean;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  priority: OrderPriorityEnum;
  bidders: shiftPageDaySelection_fetchOrders_edges_node_bidders[];
  autoRebid: boolean;
  activities: shiftPageDaySelection_fetchOrders_edges_node_activities[];
  isReviewAllowed: boolean;
  canSendToAgency: boolean;
  sentToAgencies: boolean;
  acceptedBySharedTeamMember: boolean | null;
  currentlyActiveCalloutList: shiftPageDaySelection_fetchOrders_edges_node_currentlyActiveCalloutList | null;
  status: shiftPageDaySelection_fetchOrders_edges_node_status;
  startAt: any | null;
  endAt: any | null;
  createdAt: any;
  dedicated: boolean;
  community: shiftPageDaySelection_fetchOrders_edges_node_community;
  position: shiftPageDaySelection_fetchOrders_edges_node_position;
  mainJane: shiftPageDaySelection_fetchOrders_edges_node_mainJane | null;
  recurrence: shiftPageDaySelection_fetchOrders_edges_node_recurrence | null;
}

export interface shiftPageDaySelection_fetchOrders_edges {
  node: shiftPageDaySelection_fetchOrders_edges_node | null; // The item at the end of the edge.
}

export interface shiftPageDaySelection_fetchOrders {
  pageInfo: shiftPageDaySelection_fetchOrders_pageInfo; // Information to aid in pagination.
  edges: shiftPageDaySelection_fetchOrders_edges[]; // A list of edges.
}

export interface shiftPageDaySelection {
  fetchOrders: shiftPageDaySelection_fetchOrders; // find all orders for the current user
}

export interface shiftPageDaySelectionVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sort?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shiftsListPage
// ====================================================

export interface shiftsListPage_fetchOrders_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface shiftsListPage_fetchOrders_edges_node_rate {
  min: number | null;
  max: number | null;
  rateLabel: any;
}

export interface shiftsListPage_fetchOrders_edges_node_bidders {
  firstName: string;
  lastName: string;
  id: string;
}

export interface shiftsListPage_fetchOrders_edges_node_estimatedTotalCharge {
  max: number | null;
  min: number | null;
  rateLabel: any;
}

export interface shiftsListPage_fetchOrders_edges_node_orderSchedule {
  id: number;
  breakTime: string;
  totalTime: number | null;
}

export interface shiftsListPage_fetchOrders_edges_node_status {
  name: any;
  value: OrderStatusEnum;
}

export interface shiftsListPage_fetchOrders_edges_node_recurrence {
  id: number;
}

export interface shiftsListPage_fetchOrders_edges_node_community_location {
  city: string | null;
  state: string | null;
  timeZone: string | null;
}

export interface shiftsListPage_fetchOrders_edges_node_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: shiftsListPage_fetchOrders_edges_node_community_location | null;
}

export interface shiftsListPage_fetchOrders_edges_node_position {
  name: string;
}

export interface shiftsListPage_fetchOrders_edges_node_mainJane_primaryCommunity {
  id: string;
}

export interface shiftsListPage_fetchOrders_edges_node_mainJane_avatarUrls {
  small: string | null;
}

export interface shiftsListPage_fetchOrders_edges_node_mainJane_agencyCommunity {
  id: number;
  name: string;
}

export interface shiftsListPage_fetchOrders_edges_node_mainJane {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: shiftsListPage_fetchOrders_edges_node_mainJane_primaryCommunity | null;
  avatarUrls: shiftsListPage_fetchOrders_edges_node_mainJane_avatarUrls;
  agencyCommunity: shiftsListPage_fetchOrders_edges_node_mainJane_agencyCommunity | null;
}

export interface shiftsListPage_fetchOrders_edges_node {
  rate: shiftsListPage_fetchOrders_edges_node_rate | null; // hourly rate charged to the customer
  totalDuration: number | null;
  dedicated: boolean;
  allowOvertime: boolean;
  biddable: boolean;
  biddingTimeEndsAt: any | null;
  bidders: shiftsListPage_fetchOrders_edges_node_bidders[];
  estimatedTotalCharge: shiftsListPage_fetchOrders_edges_node_estimatedTotalCharge | null; // total rate charged to the customer
  orderSchedule: shiftsListPage_fetchOrders_edges_node_orderSchedule;
  id: number;
  acceptedBySharedTeamMember: boolean | null;
  sentToAgencies: boolean;
  status: shiftsListPage_fetchOrders_edges_node_status;
  startAt: any | null;
  endAt: any | null;
  recurrence: shiftsListPage_fetchOrders_edges_node_recurrence | null;
  createdAt: any;
  community: shiftsListPage_fetchOrders_edges_node_community;
  position: shiftsListPage_fetchOrders_edges_node_position;
  mainJane: shiftsListPage_fetchOrders_edges_node_mainJane | null;
}

export interface shiftsListPage_fetchOrders_edges {
  node: shiftsListPage_fetchOrders_edges_node | null; // The item at the end of the edge.
  cursor: string; // A cursor for use in pagination.
}

export interface shiftsListPage_fetchOrders {
  pageInfo: shiftsListPage_fetchOrders_pageInfo; // Information to aid in pagination.
  pageCount: number;
  totalCount: number;
  edges: shiftsListPage_fetchOrders_edges[]; // A list of edges.
}

export interface shiftsListPage {
  fetchOrders: shiftsListPage_fetchOrders; // find all orders for the current user
}

export interface shiftsListPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  sort?: SortableOrderAttributes[] | null;
  filter?: FilterableOrderAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: teamMemberPage
// ====================================================

export interface teamMemberPage_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface teamMemberPage_fetchJanes_edges_node_avatarUrls {
  origin: string | null;
}

export interface teamMemberPage_fetchJanes_edges_node_primaryPosition_details {
  name: string;
}

export interface teamMemberPage_fetchJanes_edges_node_primaryPosition {
  details: teamMemberPage_fetchJanes_edges_node_primaryPosition_details;
}

export interface teamMemberPage_fetchJanes_edges_node_positions_details {
  name: string;
}

export interface teamMemberPage_fetchJanes_edges_node_positions {
  details: teamMemberPage_fetchJanes_edges_node_positions_details;
}

export interface teamMemberPage_fetchJanes_edges_node_secondaryPositions_details {
  id: number;
  name: string;
}

export interface teamMemberPage_fetchJanes_edges_node_secondaryPositions {
  details: teamMemberPage_fetchJanes_edges_node_secondaryPositions_details;
}

export interface teamMemberPage_fetchJanes_edges_node_secondaryCommunities {
  name: string;
  displayId: string;
}

export interface teamMemberPage_fetchJanes_edges_node_primaryCommunity {
  name: string;
  displayId: string;
}

export interface teamMemberPage_fetchJanes_edges_node_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface teamMemberPage_fetchJanes_edges_node_accessStatus {
  name: AccessStatusEnum;
}

export interface teamMemberPage_fetchJanes_edges_node {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: teamMemberPage_fetchJanes_edges_node_avatarUrls;
  primaryPosition: teamMemberPage_fetchJanes_edges_node_primaryPosition | null; // Primary position of the jane. Can be null for jane whose profile is still incomplete.
  positions: teamMemberPage_fetchJanes_edges_node_positions[]; // All the positions satisfied by the jane. Can be [] for jane whose profile is still incomplete.
  secondaryPositions: teamMemberPage_fetchJanes_edges_node_secondaryPositions[]; // only secondary positions for this Jane
  secondaryCommunities: teamMemberPage_fetchJanes_edges_node_secondaryCommunities[];
  primaryCommunity: teamMemberPage_fetchJanes_edges_node_primaryCommunity | null;
  employmentStatus: teamMemberPage_fetchJanes_edges_node_employmentStatus;
  phone: string | null;
  email: string;
  accessStatus: teamMemberPage_fetchJanes_edges_node_accessStatus;
}

export interface teamMemberPage_fetchJanes_edges {
  node: teamMemberPage_fetchJanes_edges_node | null; // The item at the end of the edge.
}

export interface teamMemberPage_fetchJanes {
  pageInfo: teamMemberPage_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: teamMemberPage_fetchJanes_edges[]; // A list of edges.
  pageCount: number;
}

export interface teamMemberPage {
  fetchJanes: teamMemberPage_fetchJanes; // find all janes
}

export interface teamMemberPageVariables {
  after?: string | null;
  before?: string | null;
  external?: boolean | null;
  filter?: FilterableJaneAttributes | null;
  first?: number | null;
  last?: number | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: teamMemberCount
// ====================================================

export interface teamMemberCount_fetchJanes {
  totalCount: number;
}

export interface teamMemberCount {
  fetchJanes: teamMemberCount_fetchJanes; // find all janes
}

export interface teamMemberCountVariables {
  byPositionId?: number | null;
  byFacilityId?: number | null;
  isDedicated?: boolean | null;
  byStartTime?: any | null;
  byEndTime?: any | null;
  allowOvertime?: boolean | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: teamMembersPageFetchJanes
// ====================================================

export interface teamMembersPageFetchJanes_fetchJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface teamMembersPageFetchJanes_fetchJanes_edges_node_primaryCommunity {
  id: string;
}

export interface teamMembersPageFetchJanes_fetchJanes_edges_node_agencyCommunity {
  id: number;
}

export interface teamMembersPageFetchJanes_fetchJanes_edges_node_accessStatus {
  name: AccessStatusEnum;
}

export interface teamMembersPageFetchJanes_fetchJanes_edges_node_avatarUrls {
  small: string | null;
}

export interface teamMembersPageFetchJanes_fetchJanes_edges_node {
  id: string;
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  primaryCommunity: teamMembersPageFetchJanes_fetchJanes_edges_node_primaryCommunity | null;
  agencyCommunity: teamMembersPageFetchJanes_fetchJanes_edges_node_agencyCommunity | null;
  accessStatus: teamMembersPageFetchJanes_fetchJanes_edges_node_accessStatus;
  avatarUrls: teamMembersPageFetchJanes_fetchJanes_edges_node_avatarUrls;
}

export interface teamMembersPageFetchJanes_fetchJanes_edges {
  node: teamMembersPageFetchJanes_fetchJanes_edges_node | null; // The item at the end of the edge.
}

export interface teamMembersPageFetchJanes_fetchJanes {
  pageInfo: teamMembersPageFetchJanes_fetchJanes_pageInfo; // Information to aid in pagination.
  edges: teamMembersPageFetchJanes_fetchJanes_edges[]; // A list of edges.
}

export interface teamMembersPageFetchJanes {
  fetchJanes: teamMembersPageFetchJanes_fetchJanes; // find all janes
}

export interface teamMembersPageFetchJanesVariables {
  first?: number | null;
  after?: string | null;
  byFullName?: string | null;
  byPrimaryCommunity?: number[] | null;
  byAgencyCommunity?: number[] | null;
  external?: boolean | null;
  sort?: SortableJanesAttributes[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: timeFrameLocationSettingsPage
// ====================================================

export interface timeFrameLocationSettingsPage_fetchCommunity_timeFrame {
  morningStartsAt: string;
  eveningStartsAt: string;
  nightStartsAt: string;
}

export interface timeFrameLocationSettingsPage_fetchCommunity {
  id: number;
  timeFrame: timeFrameLocationSettingsPage_fetchCommunity_timeFrame;
}

export interface timeFrameLocationSettingsPage {
  fetchCommunity: timeFrameLocationSettingsPage_fetchCommunity; // find a Community by ID
}

export interface timeFrameLocationSettingsPageVariables {
  locationId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: timeOffFetchJane
// ====================================================

export interface timeOffFetchJane_fetchJane_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface timeOffFetchJane_fetchJane {
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: timeOffFetchJane_fetchJane_avatarUrls;
}

export interface timeOffFetchJane {
  fetchJane: timeOffFetchJane_fetchJane; // find a single jane
}

export interface timeOffFetchJaneVariables {
  janeId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: timeOffModalFetchTimeOffById
// ====================================================

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_User_avatarUrls {
  origin: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_User {
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  title: string;
  isMobile: boolean;
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_User_avatarUrls;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Jane_avatarUrls {
  origin: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Jane {
  firstName: string;
  lastName: string;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  isMobile: boolean;
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Jane_avatarUrls;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_B2bCustomer_avatarUrls {
  origin: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_B2bCustomer {
  firstName: string;
  lastName: string;
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_B2bCustomer_avatarUrls;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_AgencyUser_avatarUrls {
  origin: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_AgencyUser {
  firstName: string;
  lastName: string;
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_AgencyUser_avatarUrls;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_SecondaryUser_avatarUrls {
  origin: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_SecondaryUser {
  firstName: string;
  lastName: string;
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_SecondaryUser_avatarUrls;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Admin_avatarUrls {
  origin: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Admin {
  firstName: string;
  lastName: string;
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Admin_avatarUrls;
}

export type timeOffModalFetchTimeOffById_fetchTimeOff_activities_user =
  | timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_User
  | timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Jane
  | timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_B2bCustomer
  | timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_AgencyUser
  | timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_SecondaryUser
  | timeOffModalFetchTimeOffById_fetchTimeOff_activities_user_Admin;

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_event {
  name: ActivityEventEnum;
  value: any;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_metadata_acceptedFor {
  name: string;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_metadata_feedableEdits {
  fieldName: string | null;
  fieldType: FeedableEditDropdownEnum | null;
  oldValuePretty: string | null;
  newValuePretty: string | null;
  newValue: string | null;
  oldValue: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities_metadata {
  acceptedFor: timeOffModalFetchTimeOffById_fetchTimeOff_activities_metadata_acceptedFor | null;
  calloutListId: string | null;
  qualifiedAgencyJanesCount: number | null;
  feedableEdits:
    | timeOffModalFetchTimeOffById_fetchTimeOff_activities_metadata_feedableEdits[]
    | null;
  reasonCategory: string | null;
  reasonMessage: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_activities {
  createdAt: any;
  user: timeOffModalFetchTimeOffById_fetchTimeOff_activities_user | null;
  event: timeOffModalFetchTimeOffById_fetchTimeOff_activities_event;
  metadata: timeOffModalFetchTimeOffById_fetchTimeOff_activities_metadata | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_community {
  displayId: string;
  name: string;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_customFields {
  name: string;
  value: string;
  customFieldId: number;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_jane_avatarUrls {
  origin: string | null;
  small: string | null;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_jane {
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: timeOffModalFetchTimeOffById_fetchTimeOff_jane_avatarUrls;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
  firstName: string;
  lastName: string;
  id: string;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff_title {
  name: TimeOffTitleEnum;
  value: any;
}

export interface timeOffModalFetchTimeOffById_fetchTimeOff {
  id: string;
  displayId: number;
  isEditable: boolean;
  activities: timeOffModalFetchTimeOffById_fetchTimeOff_activities[];
  community: timeOffModalFetchTimeOffById_fetchTimeOff_community;
  customFields: timeOffModalFetchTimeOffById_fetchTimeOff_customFields[];
  jane: timeOffModalFetchTimeOffById_fetchTimeOff_jane;
  startTime: any;
  endTime: any;
  notes: string;
  title: timeOffModalFetchTimeOffById_fetchTimeOff_title;
}

export interface timeOffModalFetchTimeOffById {
  fetchTimeOff: timeOffModalFetchTimeOffById_fetchTimeOff; // fetch a single time off by id
}

export interface timeOffModalFetchTimeOffByIdVariables {
  timeOffId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: timesheetCard
// ====================================================

export interface timesheetCard_fetchTimeSheet_order_orderSchedule {
  id: number;
}

export interface timesheetCard_fetchTimeSheet_order_community_location {
  timeZone: string | null;
}

export interface timesheetCard_fetchTimeSheet_order_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: timesheetCard_fetchTimeSheet_order_community_location | null;
}

export interface timesheetCard_fetchTimeSheet_order_status {
  value: OrderStatusEnum;
  name: any;
}

export interface timesheetCard_fetchTimeSheet_order_position {
  name: string;
}

export interface timesheetCard_fetchTimeSheet_order {
  orderSchedule: timesheetCard_fetchTimeSheet_order_orderSchedule;
  id: number;
  community: timesheetCard_fetchTimeSheet_order_community;
  status: timesheetCard_fetchTimeSheet_order_status;
  position: timesheetCard_fetchTimeSheet_order_position;
  dedicated: boolean;
  allowOvertime: boolean;
}

export interface timesheetCard_fetchTimeSheet_mainJane_avatarUrls {
  small: string | null;
}

export interface timesheetCard_fetchTimeSheet_mainJane_agencyCommunity {
  name: string;
  id: number;
}

export interface timesheetCard_fetchTimeSheet_mainJane {
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: timesheetCard_fetchTimeSheet_mainJane_avatarUrls;
  agencyCommunity: timesheetCard_fetchTimeSheet_mainJane_agencyCommunity | null;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
}

export interface timesheetCard_fetchTimeSheet_timeSheet_status {
  name: any;
  value: TimeSheetStatusEnum;
}

export interface timesheetCard_fetchTimeSheet_timeSheet_clockInStatus {
  prettyValue: any; // pretty value for ClockInStatus, concrete value always String
  value: ClockInStatusEnum;
  __typename: 'ClockInStatus';
}

export interface timesheetCard_fetchTimeSheet_timeSheet_clockOutStatus {
  prettyValue: any; // pretty value for ClockOutStatus, concrete value always String
  value: ClockOutStatusEnum;
  __typename: 'ClockOutStatus';
}

export interface timesheetCard_fetchTimeSheet_timeSheet_approvedCustomerCost {
  rawCurrency: number | null;
  currency: any | null;
}

export interface timesheetCard_fetchTimeSheet_timeSheet {
  id: number;
  status: timesheetCard_fetchTimeSheet_timeSheet_status;
  clockIn: any | null;
  clockInStatus: timesheetCard_fetchTimeSheet_timeSheet_clockInStatus | null;
  clockInOffSite: boolean;
  clockOutOffSite: boolean;
  clockOutStatus: timesheetCard_fetchTimeSheet_timeSheet_clockOutStatus | null;
  clockOut: any | null;
  approvedCustomerCost: timesheetCard_fetchTimeSheet_timeSheet_approvedCustomerCost | null;
  isEdited: boolean;
  approvedAt: any | null;
  approvedBy: string | null;
  approvedStartTime: any | null;
  approvedEndTime: any | null;
  approvedUnpaidBreakTime: string | null;
  updatedAt: any;
}

export interface timesheetCard_fetchTimeSheet {
  id: number;
  startTime: any;
  endTime: any;
  status: OrderScheduleStatusEnum;
  totalTime: number | null;
  order: timesheetCard_fetchTimeSheet_order;
  mainJane: timesheetCard_fetchTimeSheet_mainJane | null;
  timeSheet: timesheetCard_fetchTimeSheet_timeSheet | null;
}

export interface timesheetCard {
  fetchTimeSheet: timesheetCard_fetchTimeSheet; // find a timesheet by order schedule id
}

export interface timesheetCardVariables {
  orderScheduleId: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: timesheetsPage
// ====================================================

export interface timesheetsPage_fetchTimeSheets_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasNextPage: boolean; // When paginating forwards, are there more items?
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
}

export interface timesheetsPage_fetchTimeSheets_edges_node_order_orderSchedule {
  id: number;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_order_community_location {
  timeZone: string | null;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_order_community {
  id: number;
  __typename: 'Community';
  name: string;
  location: timesheetsPage_fetchTimeSheets_edges_node_order_community_location | null;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_order_position {
  name: string;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_order {
  orderSchedule: timesheetsPage_fetchTimeSheets_edges_node_order_orderSchedule;
  id: number;
  community: timesheetsPage_fetchTimeSheets_edges_node_order_community;
  position: timesheetsPage_fetchTimeSheets_edges_node_order_position;
  dedicated: boolean;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_mainJane_avatarUrls {
  small: string | null;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_mainJane_agencyCommunity {
  name: string;
  id: number;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_mainJane {
  firstName: string;
  lastName: string;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: timesheetsPage_fetchTimeSheets_edges_node_mainJane_avatarUrls;
  agencyCommunity: timesheetsPage_fetchTimeSheets_edges_node_mainJane_agencyCommunity | null;
  communityName: string; // Agency Community name for agency janes and Primary Community name for internal team members
}

export interface timesheetsPage_fetchTimeSheets_edges_node_timeSheet_status {
  name: any;
  value: TimeSheetStatusEnum;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_timeSheet_clockInStatus {
  __typename: 'ClockInStatus';
  prettyValue: any; // pretty value for ClockInStatus, concrete value always String
  value: ClockInStatusEnum;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_timeSheet_clockOutStatus {
  __typename: 'ClockOutStatus';
  prettyValue: any; // pretty value for ClockOutStatus, concrete value always String
  value: ClockOutStatusEnum;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_timeSheet_approvedCustomerCost {
  rawCurrency: number | null;
  currency: any | null;
}

export interface timesheetsPage_fetchTimeSheets_edges_node_timeSheet {
  id: number;
  status: timesheetsPage_fetchTimeSheets_edges_node_timeSheet_status;
  clockInStatus: timesheetsPage_fetchTimeSheets_edges_node_timeSheet_clockInStatus | null;
  clockOutStatus: timesheetsPage_fetchTimeSheets_edges_node_timeSheet_clockOutStatus | null;
  clockInOffSite: boolean;
  clockOutOffSite: boolean;
  clockIn: any | null;
  clockOut: any | null;
  approvedCustomerCost: timesheetsPage_fetchTimeSheets_edges_node_timeSheet_approvedCustomerCost | null;
  isEdited: boolean;
  approvedAt: any | null;
  approvedBy: string | null;
  approvedStartTime: any | null;
  approvedEndTime: any | null;
  approvedUnpaidBreakTime: string | null;
  updatedAt: any;
}

export interface timesheetsPage_fetchTimeSheets_edges_node {
  id: number;
  startTime: any;
  endTime: any;
  status: OrderScheduleStatusEnum;
  totalTime: number | null;
  order: timesheetsPage_fetchTimeSheets_edges_node_order;
  mainJane: timesheetsPage_fetchTimeSheets_edges_node_mainJane | null;
  timeSheet: timesheetsPage_fetchTimeSheets_edges_node_timeSheet | null;
}

export interface timesheetsPage_fetchTimeSheets_edges {
  node: timesheetsPage_fetchTimeSheets_edges_node | null; // The item at the end of the edge.
  cursor: string; // A cursor for use in pagination.
}

export interface timesheetsPage_fetchTimeSheets {
  pageInfo: timesheetsPage_fetchTimeSheets_pageInfo; // Information to aid in pagination.
  edges: timesheetsPage_fetchTimeSheets_edges[]; // A list of edges.
}

export interface timesheetsPage {
  fetchTimeSheets: timesheetsPage_fetchTimeSheets;
}

export interface timesheetsPageVariables {
  first?: number | null;
  last?: number | null;
  after?: string | null;
  before?: string | null;
  order?: SortableTimeSheetAttributes[] | null;
  filter?: FilterableTimeSheetAttributes | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userProfilePage
// ====================================================

export interface userProfilePage_me_Jane {}

export interface userProfilePage_me_AgencyUser {
  id: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  email: string;
  locale: string;
}

export interface userProfilePage_me_B2bCustomer {
  id: string;
  adpCustomIdentifier: string | null;
  firstName: string;
  lastName: string;
  phone: string | null;
  email: string;
  locale: string;
}

export interface userProfilePage_me_SecondaryUser {
  id: string;
  adpCustomIdentifier: string | null;
  firstName: string;
  lastName: string;
  phone: string | null;
  email: string;
  locale: string;
}

export type userProfilePage_me =
  | userProfilePage_me_Jane
  | userProfilePage_me_AgencyUser
  | userProfilePage_me_B2bCustomer
  | userProfilePage_me_SecondaryUser;

export interface userProfilePage {
  me: userProfilePage_me; // returns the currently authenticated user
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: calloutListFragment
// ====================================================

export interface calloutListFragment_manualApprovalIndexes {
  groupName: string;
  index: number;
}

export interface calloutListFragment_order_orderSchedule {
  id: number;
}

export interface calloutListFragment_order_location {
  timeZone: string | null;
}

export interface calloutListFragment_order {
  orderSchedule: calloutListFragment_order_orderSchedule;
  biddable: boolean;
  location: calloutListFragment_order_location | null;
}

export interface calloutListFragment_calloutListJanes_pageInfo {
  endCursor: string | null; // When paginating forwards, the cursor to continue.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  hasPreviousPage: boolean; // When paginating backwards, are there more items?
  hasNextPage: boolean; // When paginating forwards, are there more items?
}

export interface calloutListFragment_calloutListJanes_nodes_calloutStatus {
  name: any;
  value: CalloutStatusEnum;
}

export interface calloutListFragment_calloutListJanes_nodes_jane_accessStatus {
  name: AccessStatusEnum;
  value: any;
}

export interface calloutListFragment_calloutListJanes_nodes_jane_primaryCommunity {
  id: number;
  name: string;
}

export interface calloutListFragment_calloutListJanes_nodes_jane_avatarUrls {
  large: string | null;
  origin: string | null;
  small: string | null;
}

export interface calloutListFragment_calloutListJanes_nodes_jane_employmentStatus {
  name: UserEmploymentStatusEnum;
}

export interface calloutListFragment_calloutListJanes_nodes_jane {
  id: string;
  accessStatus: calloutListFragment_calloutListJanes_nodes_jane_accessStatus;
  primaryCommunity: calloutListFragment_calloutListJanes_nodes_jane_primaryCommunity | null;
  isMobile: boolean;
  pendingSignup: boolean; // This boolean field is used to show user is sign up or not
  avatarUrls: calloutListFragment_calloutListJanes_nodes_jane_avatarUrls;
  employmentStatus: calloutListFragment_calloutListJanes_nodes_jane_employmentStatus;
  firstName: string;
  lastName: string;
}

export interface calloutListFragment_calloutListJanes_nodes_manualApproval {
  approvedAt: any; // The date and time the callout was manually approved to continue, alias for `created_at`
}

export interface calloutListFragment_calloutListJanes_nodes_notNotifiedLogs {
  createdAt: any;
  updatedAt: any;
}

export interface calloutListFragment_calloutListJanes_nodes {
  calloutStatus: calloutListFragment_calloutListJanes_nodes_calloutStatus;
  estimatedCalloutTime: any | null;
  jane: calloutListFragment_calloutListJanes_nodes_jane;
  manualApproval: calloutListFragment_calloutListJanes_nodes_manualApproval | null;
  notifiedAt: any | null;
  notNotifiedLogs: calloutListFragment_calloutListJanes_nodes_notNotifiedLogs[];
}

export interface calloutListFragment_calloutListJanes {
  currentPage: number | null;
  pageInfo: calloutListFragment_calloutListJanes_pageInfo; // Information to aid in pagination.
  pageCount: number;
  pageSize: number;
  totalCount: number;
  nodes: calloutListFragment_calloutListJanes_nodes[]; // A list of nodes.
}

export interface calloutListFragment {
  __typename: 'CalloutList';
  totalJanes: number;
  totalNotifiedAndSkipped: number;
  waitingForManualApproval: boolean;
  updatedAt: any;
  estimatedCompletedAt: any | null;
  manualApprovalIndexes: calloutListFragment_manualApprovalIndexes[];
  id: string;
  order: calloutListFragment_order;
  calloutListJanes: calloutListFragment_calloutListJanes;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MutationOutcomeStatusEnum {
  accepted = 'accepted',
  created = 'created',
  forbidden = 'forbidden',
  notFound = 'notFound',
  ok = 'ok',
  unauthorized = 'unauthorized',
  unprocessableEntity = 'unprocessableEntity',
}

// indicates how to proceed with callout after enabling overtime
export enum EnableOvertimeStrategyEnum {
  NO_RESTART = 'NO_RESTART',
  RESTART_NOTIFY = 'RESTART_NOTIFY',
  RESTART_NO_NOTIFY = 'RESTART_NO_NOTIFY',
}

export enum AccessStatusEnum {
  approved = 'approved',
  blocked = 'blocked',
  incomplete = 'incomplete',
  not_approved = 'not_approved',
  on_hold = 'on_hold',
  pending_signup = 'pending_signup',
  rejected = 'rejected',
}

export enum UserEmploymentStatusEnum {
  casual = 'casual',
  flex = 'flex',
  full_time = 'full_time',
  not_sure = 'not_sure',
  part_time = 'part_time',
  terminated = 'terminated',
}

export enum JaneSortableFieldsEnum {
  accessStatus = 'accessStatus',
  agencyCommunityName = 'agencyCommunityName',
  agencyName = 'agencyName',
  createdAt = 'createdAt',
  employmentStatus = 'employmentStatus',
  firstName = 'firstName',
  id = 'id',
  lastName = 'lastName',
  positionName = 'positionName',
}

export enum SortDirectionEnum {
  asc = 'asc',
  desc = 'desc',
}

//       backend doesn't support filtering by "falsey" values by default       so this enum exists as a workaround to be able to do "negative filtering"        ie. orders => has_assigned_jane: false              with a boolean would not work, but using 'f' with this enum will
export enum FilterableBooleanEnum {
  f = 'f',
  t = 't',
}

export enum OrderStatusEnum {
  absent = 'absent',
  accepted = 'accepted',
  assigned = 'assigned',
  cancelled = 'cancelled',
  completed = 'completed',
  in_progress = 'in_progress',
  pending = 'pending',
  rejected = 'rejected',
  requested = 'requested',
  swapped = 'swapped',
  unfulfilled = 'unfulfilled',
}

export enum TimeFrameEnum {
  evening = 'evening',
  morning = 'morning',
  night = 'night',
}

export enum JaneTypeEnum {
  external_agency_staff = 'external_agency_staff',
  external_shared_staff = 'external_shared_staff',
  flex = 'flex',
  internal_casual = 'internal_casual',
  internal_full_time = 'internal_full_time',
  internal_part_time = 'internal_part_time',
}

export enum ActionsRequiredEnum {
  award_shift = 'award_shift',
  callout_completed = 'callout_completed',
  waiting_for_approval = 'waiting_for_approval',
}

export enum WithStaffEnum {
  agency_marketplace = 'agency_marketplace',
  internal = 'internal',
  shared = 'shared',
}

export enum OpenShiftPolicyEnum {
  bidding_required = 'bidding_required',
  tiering_call_out = 'tiering_call_out',
}

export enum CustomFieldTypeEnum {
  dropdown = 'dropdown',
  input = 'input',
  text_area = 'text_area',
}

export enum ActivityEventEnum {
  accepted = 'accepted',
  accepted_by_bookjane = 'accepted_by_bookjane',
  acknowledged = 'acknowledged',
  agency_assigned = 'agency_assigned',
  assigned = 'assigned',
  auto_extend_bid = 'auto_extend_bid',
  auto_released = 'auto_released',
  awarded_shift = 'awarded_shift',
  bidding_end = 'bidding_end',
  callout_list_created = 'callout_list_created',
  callout_paused = 'callout_paused',
  cancelled = 'cancelled',
  clocked_in = 'clocked_in',
  clocked_out = 'clocked_out',
  continued_call_out = 'continued_call_out',
  created = 'created',
  custom_field_created = 'custom_field_created',
  custom_field_disabled = 'custom_field_disabled',
  custom_field_edited = 'custom_field_edited',
  customer_released = 'customer_released',
  declined = 'declined',
  feature_disabled = 'feature_disabled',
  feature_enabled = 'feature_enabled',
  jane_released = 'jane_released',
  new_callout_list_created = 'new_callout_list_created',
  not_clocked_in = 'not_clocked_in',
  not_clocked_out = 'not_clocked_out',
  pending = 'pending',
  placed_bid = 'placed_bid',
  requested = 'requested',
  scheduled_send_to_agencies = 'scheduled_send_to_agencies',
  send_to_agencies = 'send_to_agencies',
  sent_to_bookjane = 'sent_to_bookjane',
  sent_to_select_agencies = 'sent_to_select_agencies',
  swap_expired = 'swap_expired',
  swap_initiated = 'swap_initiated',
  swapped = 'swapped',
  unaccepted_by_bookjane = 'unaccepted_by_bookjane',
  updated = 'updated',
  vaccination_required = 'vaccination_required',
}

export enum GroupTypeEnum {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum CommunitySortableFieldsEnum {
  id = 'id',
  name = 'name',
}

export enum NullPlacementEnum {
  first = 'first',
  last = 'last',
}

export enum RecurringFrequencyEnum {
  day = 'day',
  month = 'month',
  week = 'week',
}

export enum OrderSortableFieldsEnum {
  createdAt = 'createdAt',
  endAt = 'endAt',
  facilityCity = 'facilityCity',
  facilityName = 'facilityName',
  id = 'id',
  janeFirstName = 'janeFirstName',
  janeLastName = 'janeLastName',
  janeName = 'janeName',
  maxRate = 'maxRate',
  minRate = 'minRate',
  positionName = 'positionName',
  startAt = 'startAt',
  totalDuration = 'totalDuration',
  unpaidBreakTime = 'unpaidBreakTime',
}

export enum OrderPriorityEnum {
  critical = 'critical',
  critical_overnight = 'critical_overnight',
  high_priority = 'high_priority',
  shift_exchange = 'shift_exchange',
  urgent = 'urgent',
  vacation_loa = 'vacation_loa',
}

// enum to indicate which audit trail algorithm was used to create the order
export enum AuditTrailVersionEnum {
  v1 = 'v1',
  v2 = 'v2',
}

export enum LeadTimePriorityEnum {
  critical = 'critical',
  high_priority = 'high_priority',
  vacation_loa = 'vacation_loa',
}

export enum PositionTypeEnum {
  additional = 'additional',
  primary = 'primary',
}

export enum WorkTeamEnum {
  child_care = 'child_care',
  custom = 'custom',
  general_position = 'general_position',
  medical = 'medical',
  nurse_practitioner = 'nurse_practitioner',
  physician = 'physician',
  psychology_therapy = 'psychology_therapy',
  registered_nurse = 'registered_nurse',
  registered_practical_nurse = 'registered_practical_nurse',
  senior_care = 'senior_care',
  us_childcare = 'us_childcare',
  us_healthcare = 'us_healthcare',
}

export enum TimeOffTitleEnum {
  float = 'float',
  personal = 'personal',
  sick = 'sick',
  stat = 'stat',
  time_off = 'time_off',
  vacation = 'vacation',
}

export enum WeekdaysEnum {
  dimanche = 'dimanche',
  friday = 'friday',
  jeudi = 'jeudi',
  lundi = 'lundi',
  mardi = 'mardi',
  mercredi = 'mercredi',
  monday = 'monday',
  samedi = 'samedi',
  saturday = 'saturday',
  sunday = 'sunday',
  thursday = 'thursday',
  tuesday = 'tuesday',
  vendredi = 'vendredi',
  wednesday = 'wednesday',
}

export enum GroupEmploymentStatusEnum {
  casual = 'casual',
  flex = 'flex',
  full_time = 'full_time',
  part_time = 'part_time',
}

export enum EscalationTimePriorityEnum {
  critical = 'critical',
  high_priority = 'high_priority',
  shift_exchange = 'shift_exchange',
  vacation_loa = 'vacation_loa',
}

export enum FeedableEditDropdownEnum {
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  input = 'input',
  text_area = 'text_area',
}

export enum TimeSheetStatusEnum {
  approved = 'approved',
  no_show = 'no_show',
  pending = 'pending',
}

export enum CalloutStatusEnum {
  already_notified = 'already_notified',
  in_line = 'in_line',
  invalid_employment_status = 'invalid_employment_status',
  invalid_status = 'invalid_status',
  missing_position = 'missing_position',
  multi_location_callout_disabled = 'multi_location_callout_disabled',
  not_notified = 'not_notified',
  notified = 'notified',
  order_accepted = 'order_accepted',
  out_of_area = 'out_of_area',
  overtime_allowed_in_line = 'overtime_allowed_in_line',
  overtime_allowed_notified = 'overtime_allowed_notified',
  overtime_not_allowed = 'overtime_not_allowed',
  pending_signup = 'pending_signup',
  schedule_conflict = 'schedule_conflict',
  sharing_disabled = 'sharing_disabled',
  time_off_conflict = 'time_off_conflict',
}

export enum AgencyCommunitySortableFieldsEnum {
  createdAt = 'createdAt',
  id = 'id',
  name = 'name',
  openRate = 'openRate',
}

export enum ClockInStatusEnum {
  clocked_in = 'clocked_in',
  late_clock_in = 'late_clock_in',
  not_clocked_in = 'not_clocked_in',
}

export enum ClockOutStatusEnum {
  clocked_out = 'clocked_out',
  not_clocked_out = 'not_clocked_out',
}

export enum GroupCountableOrderAttributesEnum {
  startDate = 'startDate',
  status = 'status',
}

export enum OrderScheduleStatusEnum {
  accepted = 'accepted',
  assigned = 'assigned',
  cancelled = 'cancelled',
  pending = 'pending',
}

export enum TimeSheetSortableFieldsEnum {
  customerCost = 'customerCost',
  endTime = 'endTime',
  id = 'id',
  price = 'price',
  startTime = 'startTime',
  status = 'status',
  totalPayout = 'totalPayout',
  totalPrice = 'totalPrice',
}

export enum TimeSheetFlaggedIssueEnum {
  late = 'late',
  not_clocked_in = 'not_clocked_in',
  offsite = 'offsite',
}

export enum ShiftStatusForTimeSheetsEnum {
  completed = 'completed',
  lateCancellation = 'lateCancellation',
}

// Attributes that janes can be filtered by when querying janes for a B2B or Agency user.
export interface FilterableJaneAttributes {
  byId?: string[] | null;
  byStatus?: AccessStatusEnum[] | null;
  byEmploymentStatus?: UserEmploymentStatusEnum[] | null;
  byPosition?: number[] | null;
  byPrimaryCommunity?: number[] | null;
  byAgency?: number[] | null;
  byAgencyCommunity?: number[] | null;
  byFullName?: string | null;
  byRateLessThanOrEqualTo?: number | null;
  byPendingSignup?: boolean[] | null;
  belongingToTeam?: BelongingToTeamAttributes | null;
  byGroupIds?: number[] | null;
}

// null
export interface BelongingToTeamAttributes {
  forPositions: number[];
  inCommunities: number[];
}

// null
export interface SortableJanesAttributes {
  field: JaneSortableFieldsEnum;
  direction?: SortDirectionEnum | null;
}

// attributes that orders can be filtered by
export interface FilterableOrderAttributes {
  forOrders?: ForOrderAttributes | null;
  forJanes?: FilterableJaneAttributes | null;
  forAgencyCommunities?: ForAgencyCommunityAttributes | null;
}

// Attributes that orders can be filtered by when querying orders for a B2B or Agency user.
export interface ForOrderAttributes {
  sentToAgencies?: FilterableBooleanEnum | null;
  sentToAgenciesWthoutInternal?: FilterableBooleanEnum | null;
  withCommunity?: number[] | null;
  withStatus?: OrderStatusEnum[] | null;
  withTimeFrame?: TimeFrameEnum[] | null;
  withJaneType?: JaneTypeEnum[] | null;
  withPositions?: number[] | null;
  withClientName?: string | null;
  withCaregiverName?: string | null;
  startsAfter?: DateRangeUnboundAttributes | null;
  startBetweenTimeRange?: DateRangeAttributes | null;
  withOverlappingTimeRange?: DateRangeAttributes | null;
  createdOnDate?: any | null;
  createdBetween?: DateRangeUnboundAttributes | null;
  withinBounds?: LocationBoundingBoxAttributes | null;
  withActionsRequired?: ActionsRequiredEnum[] | null;
  withStaff?: OrderWithStaffAttributes | null;
}

// a date range which does not require an upper bound
export interface DateRangeUnboundAttributes {
  from: any;
  to?: any | null;
}

// null
export interface DateRangeAttributes {
  from: any;
  to: any;
}

// attributes that specify the bounding box for the location
export interface LocationBoundingBoxAttributes {
  southWest: LocationAttributes;
  northEast: LocationAttributes;
}

// attributes to specify a single location point
export interface LocationAttributes {
  latitude: number;
  longitude: number;
}

// null
export interface OrderWithStaffAttributes {
  types: WithStaffEnum[];
  facility: FacilityAttributes;
}

// null
export interface FacilityAttributes {
  id: number;
  type: string;
}

// attributes that agencies can be filtered by, when used with a non-Agency query will result in a join and will exclude the parent objects which do not match the agency filter
export interface ForAgencyCommunityAttributes {
  byName?: string | null;
  withAgencyId?: number[] | null;
  withId?: number[] | null;
}

// null
export interface FilterableCustomFieldAttributes {
  withCommunity?: number[] | null;
}

// Attributes that groups can be filtered by when querying groups.
export interface FilterableGroupAttributes {
  withId?: number[] | null;
  withName?: string | null;
  withExactName?: string | null;
  withType?: GroupTypeEnum[] | null;
}

// null
export interface SortableCommunityAttributes {
  field: CommunitySortableFieldsEnum;
  direction?: SortDirectionEnum | null;
  nulls?: NullPlacementEnum | null;
}

// null
export interface RecurringOrderAttributes {
  startTime: any;
  endTime: any;
  frequency: RecurringFrequencyEnum;
  interval: number;
  expirationDate?: any | null;
}

// null
export interface SortableOrderAttributes {
  field: OrderSortableFieldsEnum;
  direction?: SortDirectionEnum | null;
  nulls?: NullPlacementEnum | null;
}

// Attributes that positions can be filtered by.
export interface FilterablePositionAttributes {
  byId?: number[] | null;
  byName?: string | null;
  byAgencyServicedPosition?: number | null;
  byAgencyCommunity?: number[] | null;
}

// available filters for time off
export interface FilterableTimeOffAttributes {
  forTimeOff?: ForTimeOffAttributes | null;
}

// filters for only time off
export interface ForTimeOffAttributes {
  withJanes?: string[] | null;
  startsBetween?: DateRangeAttributes | null;
  occursDuring?: DateRangeAttributes | null;
}

// attributes that shift distribution rules can be filtered by
export interface FilterableShiftDistributionRuleAttributes {
  defaultRules?: boolean | null;
  forCommunities?: FilterableCommunitiesAttributes | null;
  forPositions?: FilterablePositionAttributes | null;
}

// null
export interface FilterableCommunitiesAttributes {
  withId?: number[] | null;
  byName?: string | null;
}

// null
export interface SortableAgencyCommunityAttributes {
  field: AgencyCommunitySortableFieldsEnum;
  direction?: SortDirectionEnum | null;
}

// null
export interface GroupCountableOrderAttributes {
  field: GroupCountableOrderAttributesEnum;
}

// null
export interface SortableTimeSheetAttributes {
  field: TimeSheetSortableFieldsEnum;
  direction?: SortDirectionEnum | null;
  nulls?: NullPlacementEnum | null;
}

// Attributes on timesheets/shifts which can be filtered
export interface FilterableTimeSheetAttributes {
  forTimeSheets?: ForTimeSheetAttributes | null;
  forOrders?: ForOrderAttributes | null;
  forJanes?: FilterableJaneAttributes | null;
}

// attributes that shifts can be filtered by
export interface ForTimeSheetAttributes {
  byStatus?: TimeSheetStatusEnum[] | null;
  byFlaggedIssue?: TimeSheetFlaggedIssueEnum[] | null;
  withShiftStatus?: ShiftStatusForTimeSheetsEnum[] | null;
  autoApproved?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
