import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext, useEffect } from 'react';
import { sendToAgenciesSummaryView, sendToAgenciesSummaryViewVariables } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { fetchJaneByIdQueryConfig } from '../SendToAgenciesSummaryView.constants';

export const FetchJaneByIdDataContext = createGQLDataContext({});

export const FetchJaneByIdDataProvider: FC = ({ children }) => {
  const { values } = useSendToAgenciesFormContext();

  const { data: shiftModalData } = useShiftModalDataContext();

  const janeInfo = useGQLQuery<
    sendToAgenciesSummaryView,
    sendToAgenciesSummaryView,
    sendToAgenciesSummaryViewVariables
  >(fetchJaneByIdQueryConfig(values.jane_id, shiftModalData.fetchOrder?.position?.id));

  useEffect(() => {
    janeInfo.fetch();
  }, []); // eslint-disable-line

  return (
    <FetchJaneByIdDataContext.Provider value={janeInfo}>
      {children}
    </FetchJaneByIdDataContext.Provider>
  );
};

export const useFetchJaneByIdDataContext = (): UseGQLQuery<sendToAgenciesSummaryView> =>
  useContext(FetchJaneByIdDataContext);
