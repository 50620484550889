export const positionsSettingsPagePath = '/settings/positions';
export const locationSettingsPagePath = '/settings/locations';
export const administratorSettingsPagePath = '/settings/administrators';

export const positionsSettingsPageRouteMatch = [
  `${positionsSettingsPagePath}/:id`,
  `${positionsSettingsPagePath}/:id/edit`,
];

export const locationSettingsPageRouteMatch = [
  `${locationSettingsPagePath}/:locationId/general`,
  `${locationSettingsPagePath}/:locationId/availability`,
  `${locationSettingsPagePath}/:locationId/shift-distribution`,
  `${locationSettingsPagePath}/:locationId/shift-distribution/edit`,
  `${locationSettingsPagePath}/:locationId/shift-swap`,
  `${locationSettingsPagePath}/:locationId/time-frames`,
];

export const administratorSettingsPageRouteMatch = [
  `${administratorSettingsPagePath}/:id/general`,
  `${administratorSettingsPagePath}/:id/location`,
];
