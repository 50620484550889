import { createContext, FC, useContext, useMemo, useState } from 'react';

interface IDetailTabViewProps {
  isConfirmAvailabilityModalOpen: boolean;
  openConfirmAvailabilityModal: () => void;
  closeConfirmAvailabilityModal: () => void;
}

export const DetailTabViewPropsContext = createContext<IDetailTabViewProps>(
  {} as IDetailTabViewProps,
);

export const DetailTabViewPropsProvider: FC = ({ children }) => {
  const [isConfirmAvailabilityModalOpen, setIsConfirmAvailabilityModalOpen] = useState(false);

  const contextValue = useMemo(
    () => ({
      isConfirmAvailabilityModalOpen,
      openConfirmAvailabilityModal: () => setIsConfirmAvailabilityModalOpen(true),
      closeConfirmAvailabilityModal: () => setIsConfirmAvailabilityModalOpen(false),
    }),
    [isConfirmAvailabilityModalOpen],
  );

  return (
    <DetailTabViewPropsContext.Provider value={contextValue}>
      {children}
    </DetailTabViewPropsContext.Provider>
  );
};

export const useDetailTabViewPropsContext = (): IDetailTabViewProps =>
  useContext(DetailTabViewPropsContext);
