import { DateTime } from 'luxon';
import { ISO8601Type } from 'types/common.types';

const dateFormatAliasMap = {
  'yyyy-LL-dd': 'yyyy-LL-dd',
  'LLL d, yyyy': 'LLL d, yyyy',
  LL: 'LL',
  dd: 'dd',
  LLLL: 'LLLL',
};

type DateFormatAliasType = keyof typeof dateFormatAliasMap;

export const formatDate = (date: ISO8601Type, format?: DateFormatAliasType) => {
  const formatString = format ? dateFormatAliasMap[format] : format;
  return DateTime.fromISO(date).toFormat(`${formatString}`);
};
