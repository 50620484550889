import { BKJSideModalFooterWrapper } from 'components/BKJSideModalFooter/BKJSideModalFooter.styled';
import { IFlexProps } from 'components/Flex/Flex.types';
import { FC } from 'react';

export const BKJSideModalFooter: FC<IFlexProps> = ({
  children,
  justifyContent = 'flex-start',
  flex = '1',
  ...rest
}) => {
  return (
    <BKJSideModalFooterWrapper {...rest} justifyContent={justifyContent}>
      {children}
    </BKJSideModalFooterWrapper>
  );
};
