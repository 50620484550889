import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from 'config';
import { PUSHER_AUTH_URL } from 'constants/endpoints';
import Pusher from 'pusher-js';
import { SessionService } from 'services/SessionService';
import { SocketConnectionOptions } from 'services/SocketService/SocketService';

export const SocketProviders = {
  Pusher: (options?: SocketConnectionOptions) =>
    new Pusher(PUSHER_APP_KEY, {
      authEndpoint: PUSHER_AUTH_URL(),
      cluster: PUSHER_APP_CLUSTER,
      auth: { headers: { Authorization: `Bearer ${SessionService.getToken()}` } },
      enabledTransports: ['ws'],
      forceTLS: true,
    }),
};

export type SocketServiceProviderType = keyof typeof SocketProviders;
