import { memo, FC } from 'react';
import {
  MainNavigationHeader,
  MobileNavLogoWrapper,
  WhiteLabelLogoContainer,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { NavMenuIcon } from 'components/BKJMainNavigation/src/NavMenuIcon';
import { deepEquals } from 'utils/deepEquals';
import { INavHeaderProps } from 'components/BKJMainNavigation/src/NavHeader.types';
import { WhiteLabelLogo } from 'components/WhiteLabelLogo';
import { GeneralLocationIcon } from 'assets/GeneralLocationIcon';

const Component: FC<INavHeaderProps> = ({ onMouseDown, isOpen, menuIcon }) => {
  return (
    <MainNavigationHeader onMouseDown={onMouseDown}>
      <NavMenuIcon iconName={menuIcon} color="White" />
      <MobileNavLogoWrapper $isOpen={isOpen}>
        <WhiteLabelLogoContainer>
          <WhiteLabelLogo />
        </WhiteLabelLogoContainer>
        <GeneralLocationIcon />
      </MobileNavLogoWrapper>
    </MainNavigationHeader>
  );
};

export const MobileNavHeader = memo(Component, deepEquals);
