import { createGQLDataContext } from 'utils/createDataContext';
import { FC, useContext, useMemo } from 'react';
import { ShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { QUERY_availableTeamMembers } from 'queries';
import { useSSFBehaviors } from 'hooks';
import { pageContextInitialState } from 'types/common.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';

export const AvailableMembersDataContext = createGQLDataContext({ ...pageContextInitialState });

const availableTeamMembersClient = createApolloClient({ cache: createApolloCache() });

export const AvailableMembersDataProvider: FC = ({ children }) => {
  const { shift_id } = useContext(ShiftModalUIContext);

  const config = useMemo(() => availableTeamMembersQueryConfig(shift_id), [shift_id]);
  const [values, opts] = useSSFBehaviors(config);
  const value = {
    values,
    ...opts,
    __typename: 'GraphQLAPI',
  };

  return (
    <AvailableMembersDataContext.Provider value={value}>
      {children}
    </AvailableMembersDataContext.Provider>
  );
};

export const availableTeamMembersQueryConfig = (order_id?: number): ISSFBehaviorsOptions => ({
  key: 'AVAILABLE_TEAM_MEMBERS_PAGE',
  paginationType: 'InfiniteScroll',
  client: availableTeamMembersClient,
  query: QUERY_availableTeamMembers,
  pageSize: 10,
  schema: {
    id: {
      initialValue: order_id,
    },
  },
});
