import { defaultMapper } from 'mappers/defaultMapper';
import { ICustomerPositionResponse } from 'mappers/scheduling/schedule.rest.types';

function GET_customerPositionsMapper(apiResponseData: { positions: ICustomerPositionResponse[] }) {
  const data = { ...apiResponseData };

  const positions_code = data.positions.reduce((obj, position) => {
    return {
      ...obj,
      [position.code]: position,
    };
  }, {});

  return { ...defaultMapper(data), positions_code };
}

export default GET_customerPositionsMapper;
