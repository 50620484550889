import { bindActionCreators, createReducer } from '@reduxjs/toolkit';
import {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkRejectedAction,
} from '@reduxjs/toolkit/dist/matchers';
import { useDispatch } from 'react-redux';
import {
  userLogin,
  userLogout,
  userPermissions,
  userRegistration,
  verifyToken,
} from 'store/reducers/auth/authReducer.actions';
import { initialAuthState as initialState } from 'store/reducers/auth/authReducer.initialState';
import { captureException } from 'utils/captureException';

export const authReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(userRegistration.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    })
    .addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.baseUrl = action.payload.baseUrl;
    })
    .addCase(userLogin.rejected, (state) => {
      state.token = null;
      state.permissions = null;
      state.baseUrl = null;
      state.user = null;
      state.isLoading = false;
      captureException(new Error('Error: userSlice.ts => userLogin.rejected'), {
        tags: { type: 'ReduxRejectedActionError' },
        extra: { state },
      });
    })
    .addCase(userLogout.fulfilled, (state) => {
      state.token = null;
      state.permissions = null;
      state.user = null;
      state.baseUrl = null;
      state.isLoading = false;
    })
    .addCase(userPermissions.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(userPermissions.fulfilled, (state, action) => {
      if (!state.token) state.token = action.payload.token;
      state.permissions = action.payload.permissions;
      state.isLoading = false;
    })
    .addCase(userPermissions.rejected, (state) => {
      state.token = null;
      state.permissions = null;
      state.baseUrl = null;
      state.isLoading = false;
      captureException(new Error('Error: userSlice.ts => userPermissions.rejected'), {
        tags: { type: 'ReduxRejectedActionError' },
        extra: { state },
      });
    })
    .addCase(verifyToken.fulfilled, (state, action) => {
      if (!state.token) state.token = action.payload.token;
      state.permissions = action.payload.permissions;
      state.isLoading = false;
    })
    .addCase(verifyToken.rejected, (state) => {
      state.token = null;
      state.baseUrl = null;
      state.permissions = null;
      state.isLoading = false;
      captureException(new Error('Error: userSlice.ts => verifyToken.rejected'), {
        tags: { type: 'ReduxRejectedActionError' },
        extra: { state },
      });
    })

    .addMatcher(
      (action): action is UnknownAsyncThunkFulfilledAction => action.type.endsWith('/fulfilled'),
      (state) => {
        state.isLoading = false;
      },
    )
    .addMatcher(
      (action): action is UnknownAsyncThunkRejectedAction => action.type.endsWith('/rejected'),
      (state) => {
        state.isLoading = false;
      },
    )
    .addDefaultCase(() => {}),
);

export function useUserActions() {
  return bindActionCreators(
    {
      userLogin,
      userLogout,
      userPermissions,
      userRegistration,
      verifyToken,
    },
    useDispatch(),
  );
}
