import { ErrorBoundary, Flex } from '@bookjane2/bookjane-design-library';
import { GlobalLoading } from 'components/GlobalLoading';
import { formatPascalCase } from 'format/formatPascalCase';
import { FC, ReactElement, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { MountTransition } from 'routes/MountTransition';
import { sessionTokenSelector } from 'store/selectors/userSelectors/sessionTokenSelector';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const PublicRoute: FC<RouteProps> = (props): ReactElement<RouteProps> => {
  const location = useLocation();
  const sessionToken = useSelector(sessionTokenSelector);
  if (sessionToken) return <Redirect to="/" />;
  return (
    <Suspense fallback={<GlobalLoading />}>
      <ErrorBoundary
        showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
        componentName={formatPascalCase(location.pathname.replace('/', ''))}
      >
        <MountTransition>
          <Flex flex="1" flexDirection="column" width="100%">
            <Route {...props} />
          </Flex>
        </MountTransition>
      </ErrorBoundary>
    </Suspense>
  );
};
