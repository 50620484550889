import styled from 'styled-components';
import { Flex } from 'components/Flex';
import { BKJButton } from '@bookjane2/bookjane-design-library';

export const ShiftSummaryBottomPadding = styled.div`
  padding-bottom: 100px;
  margin-bottom: -80px;
`;

export const ShiftSummaryViewWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.White};
  padding: 28px 108px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-top: auto;
    padding: 0;
  } ;
`;

export const Facility = styled.span`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 14px;
  } ;
`;

export const ConfirmationButtons = styled(Flex)`
  justify-content: flex-end;
  width: 100%;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    box-shadow: 0px -4px 4px 0px #0000001a;
    padding: 10px 20px 20px;
    gap: 20px;
    flex-direction: column-reverse;
    align-items: center;
    & > *:nth-child(2) {
      padding: 22px;
    }
  }
`;

export const CreateShiftConfirmationButton = styled(BKJButton)`
  min-width: 192px;
  width: 192px;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    min-width: 0;
    width: 100%;
    font-size: 16px;
  }
`;

export const ShiftSummaryRequestTeamMemberText = styled.span`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.Body};
  width: 60%;
  margin-top: 32px;
  margin-bottom: 40px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 20px;
    margin: 0;
  }
`;

export const ShiftsGroupedByPosition = styled.div`
  background-color: ${({ theme }) => theme.colors.Grey1};
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-bottom: 0;
  }
`;

export const PositionName = styled.div`
  min-height: 75px;
  ${({ theme }) => theme.typography.H4};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  padding: 24px 16px 23px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
`;

export const TableCellValue = styled.div<{ isBold?: Boolean }>`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ isBold, theme }) => isBold && theme.fonts.PoppinsTextBold};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const ShiftStartDate = styled.div`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-size: 16px;
  line-height: 24px;
  padding: 30px 0;
`;

export const ShiftSummaryDetailContainer = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
`;

export const TableCellTitle = styled.div<{ isBold?: Boolean }>`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ isBold, theme }) => isBold && theme.fonts.PoppinsTextBold};
`;

export const MobilePositionName = styled.div`
  min-height: 75px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  padding: 24px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableBody = styled.tbody`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TableHeader = styled.thead`
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TableHeaderCell = styled.th`
  text-align: left;
  padding: 26px 16px;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const TableCell = styled.td<{ isBold?: Boolean }>`
  padding: 26px 16px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};

  ${({ isBold, theme }) => isBold && theme.fonts.PoppinsTextBold};
`;

export const TeamMemberTableCell = styled.td`
  padding: 26px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
  min-width: 300px;
  max-width: 444px;
`;

export const TableRow = styled.tr`
  &:first-child {
    border-top: none;
  }
`;

export const ShiftFooterDataRow = styled.tr`
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
  &:last-child {
    border-bottom: none;
  }
`;

export const AvailableOrRecurring = styled.div`
  padding: 20px 16px;
`;

export const RecurringText = styled.span`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
`;

export const ShiftFooterLabel = styled.span<{ isBold?: boolean }>`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ isBold, theme }) => isBold && theme.fonts.PoppinsTextBold};
`;

export const ShiftBiddingText = styled.div`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  margin-top: 10px;
`;

export const ShiftFooterWrapper = styled.div`
  margin-bottom: 4px;
`;

export const TeamMemberAvatarWrapper = styled.div`
  height: 100%;
  min-width: 30px;
`;

export const TeamMemberHeader = styled.div`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 16px;
  line-height: 21px;
`;

export const TeamMemberRequested = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 21px;
`;
