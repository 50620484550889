import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { IBKJButtonProps } from '@bookjane2/bookjane-design-library/lib/components/BKJButton/BKJButton.types';
import { FC } from 'react';
interface IModalLinkTextProps extends Partial<IBKJButtonProps> {}

export const ModalLinkText: FC<IModalLinkTextProps> = ({ children, ...rest }) => {
  return (
    <BKJButton {...rest} variant="PurpleText" width="auto" minWidth="auto" type="button">
      {children}

      <BKJIcon iconName="ChevronRight" />
    </BKJButton>
  );
};
