/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFeatureSelector } from 'guards/useFeatureSelector';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';
import { useAvailabilityLocationSettingsPageDataContext } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPage.data.context';
import { AvailabilityLocationSettingsPageFormFields as ALSPFFields } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.types';
import pluralize from 'pluralize';
import { Context, createContext, FC, useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API_GET_disableCustomerAvailability } from 'requests/GET_disableCustomerAvailability';
import { API_POST_updateCustomerAvailability } from 'requests/POST_updateCustomerAvailability';
import { API_PUT_enableAvailabilityStatus } from 'requests/PUT_enableAvailabilityStatus';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

const AvailabilityLocationSettingsPageFormContext = createContext<
  UseFormBehaviors<ALSPFFields, {}>
>({} as UseFormBehaviors<ALSPFFields, {}>);

export const AvailabilityLocationSettingsPageFormProvider: FC = ({ children }) => {
  const isTieringEnabled = useFeatureSelector('FEATURE_TYPE_TIERING');
  const { data } = useAvailabilityLocationSettingsPageDataContext();
  const { locationId } = useParams<{ locationId: string }>();
  const { apiRequest, apiRequestParallel } = useAPIActions();
  const history = useHistory();
  const { t } = useTranslation();

  const [apiErrors, setApiErrors] = useState({} as { error_messages?: { deadline?: string[] } });
  const form = useFormBehaviors<ALSPFFields, {}>({
    initialState: {
      isAvailabilityEnabled: data.isAvailabilityEnabled,
      start_at: DateTime.fromJSDate(data.form.start_at).toString(),
      timespan: [`${data.form.timespan}`],
      deadline: `${data.form.deadline}`,
      waiting_hours: `${data?.form?.waiting_hours}` || '4',
      critical: !!data?.form?.critical,
      high_priority: !!data?.form?.high_priority,
      vacation_loa: !!data?.form?.vacation_loa,
      shift_exchange: !!data?.form?.shift_exchange,
      communityIds: [`${locationId}`],
      allFacilities: false,
      byName: '',
      non_mobile: Boolean(data?.form?.non_mobile),
      isApplyToFacilitiesModalOpen: false,
    },
    onSubmit: async (formValues: ALSPFFields) => {
      if (!formValues.isAvailabilityEnabled) {
        // Disabling availability
        const result = await apiRequest(API_GET_disableCustomerAvailability(locationId), {
          onSuccess: () => {
            history.push(`/settings/locations/${locationId}/availability`);
            toastService.success(t('success:AVAILABILITY_DISABLED'));
          },
          onError: (e) => handleGenericErrorMessage(e, t('errors:FAILED_TO_DISABLE_AVAILABILITY')),
        });
        return result;
      } else {
        // Enabling avaiability

        await apiRequest(API_PUT_enableAvailabilityStatus(locationId));

        const result = (await apiRequestParallel(
          API_POST_updateCustomerAvailability(
            formValues.communityIds,
            formValues,
            isTieringEnabled,
          ),
        )) as unknown as any[];
        const hasErrors = result.some((response: any) => {
          return response?.payload?.data?.error_messages;
        });
        if (!hasErrors) {
          history.push(`/settings/locations/${locationId}/availability`);
          toastService.success(
            `${t('success:AVAILABILITY_ENABLED')} ${formValues.communityIds.length} ${pluralize(
              t('common:LOCATION'),
              formValues.communityIds.length,
            )}.`,
          );
        } else {
          setApiErrors(result[0].payload.data);
          toastService.error(
            `${t('errors:FAILED_TO_UPDATE_AVAILABILITY_FOR')} ${result.length} ${pluralize(
              t('common:LOCATION'),
              result.length,
            )}.`,
          );
        }
      }
    },
    validations: {},
  });

  const handleChange = useCallback(
    (e) => {
      if (e?.target?.name === 'start_at') setApiErrors({});
      form.onChange(e);
    },
    [form],
  );

  return (
    <AvailabilityLocationSettingsPageFormContext.Provider
      value={{ ...form, onChange: handleChange, apiErrors }}
    >
      {children}
    </AvailabilityLocationSettingsPageFormContext.Provider>
  );
};

export const useAvailabilityLocationSettingsPageFormContext = (): Omit<
  UseFormBehaviors<ALSPFFields, {}>,
  'apiErrors'
> & {
  apiErrors: {
    error_messages?: { deadline?: string[]; end_at?: string[] };
  };
} =>
  useContext(
    AvailabilityLocationSettingsPageFormContext as unknown as Context<
      Omit<UseFormBehaviors<ALSPFFields, {}>, 'apiErrors'> & {
        apiErrors: {
          error_messages?: { deadline?: string[] };
        };
      }
    >,
  );
