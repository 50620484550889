import styled from 'styled-components';

export const RecurringModalFormLabel = styled.div`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 8px;
`;

export const TextAreaWrapper = styled.div`
  margin-top: 32px;
`;

export const SelectFormGroup = styled.div`
  margin-left: 8px;
  width: 100%;
`;

export const InlineWrapper = styled.div`
  margin-left: 8px;
  width: 100%;
  label {
    display: none;
  }
`;
