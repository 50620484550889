import styled from 'styled-components';
import { Flex } from 'components/Flex';

export const Caption = styled.span`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const ShiftTimeWrapper = styled(Flex)`
  margin-left: 0px;
`;

export const ShiftTimeCaption = styled(Caption)`
  margin-left: 10px;
`;

export const PositionNameCaption = styled(Caption)`
  min-height: 50px;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #8b96a3;
`;
