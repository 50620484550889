import { FC, Fragment } from 'react';
import { H3 } from 'components/Typography';
import { BKJComboBox, BKJTextArea, ErrorBoundary, Flex } from '@bookjane2/bookjane-design-library';
import { useEditViewContext } from 'components/ShiftModal/src/views/EditView/EditView.context';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';
import { InputLabel, RequiredAsterisk } from 'components/InputLabel';
import { reasonForEditsSelectOptions } from 'components/ShiftModal/src/views/ReasonForEdits/ReasonForEdits.constants';

export const ReasonForEdits: FC = () => {
  const { t } = useTranslation();
  const { isSubmitting, values, onChange } = useEditViewContext();
  const isEditsTextAreaRequired = values?.reasonForEdit?.[0] === 'other';

  return (
    <ErrorBoundary
      componentName="ReasonsForEditsView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <Flex flexDirection="column" padding="32px 32px 0" flex="1">
        <Flex padding="0 0 30px 0">
          <H3 color="TextDarkGrey">{t('shift_modal:REASON_FOR_EDITING_1')}</H3>
        </Flex>
        <Flex flexDirection="column" gap="4px" padding="0 0 32px 0">
          <InputLabel>
            {t('shift_modal:REASON_FOR_EDITING_2')}:&nbsp;
            <RequiredAsterisk>*</RequiredAsterisk>
          </InputLabel>
          <BKJComboBox
            type="Single"
            value={values?.reasonForEdit}
            width="100%"
            options={reasonForEditsSelectOptions()}
            name="reasonForEdit"
            action="OpenToggle"
            filter="TimeStartsWith"
            placeholder="Select a reason"
            onChange={onChange}
          />
        </Flex>

        <Fragment>
          <Flex padding="0 0 4px 0">
            <InputLabel>
              {t('dashboard:DETAILS')}:&nbsp;
              {isEditsTextAreaRequired && <RequiredAsterisk>*</RequiredAsterisk>}
            </InputLabel>
          </Flex>

          <BKJTextArea
            required={true}
            maxLength={200}
            disabled={isSubmitting}
            name={'specifiedReasonForEdit'}
            value={values?.specifiedReasonForEdit}
            onChange={onChange}
            placeholder="Reason is only visible to admin."
          />
        </Fragment>
      </Flex>
    </ErrorBoundary>
  );
};
