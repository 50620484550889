import i18next from 'i18next';

export const releaseReasons = [
  {
    key: 'Communicated Absence',
    label: i18next.t('schedule:COMMUNICATED_ABSENCE'),
    value: 'communicated_absence',
  },
  {
    key: 'No Show',
    label: i18next.t('schedule:NO_SHOW'),
    value: 'no_show',
  },
  {
    key: 'Schedule Conflict',
    label: i18next.t('schedule:SCHEDULE_CONFLICT'),
    value: 'conflicts_with_another_shift',
  },
  {
    key: 'Paid Time Off',
    label: i18next.t('schedule:UNPAID_TIME_OFF'),
    value: 'paid_time_off',
  },
  {
    key: 'Unpaid Time Off',
    label: i18next.t('schedule:PAID_TIME_OFF'),
    value: 'unpaid_time_off',
  },
  {
    key: 'Other',
    label: i18next.t('schedule:OTHER'),
    value: 'other',
  },
];
