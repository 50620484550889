import { agencyJaneOrderValidationQueryConfig } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/context.constants';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext } from 'react';
import {
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderIdVariables,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchAgencyTeamMemberAvailabilityByOrderIdDataContext = createGQLDataContext<
  UseGQLQuery<assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId>
>({} as UseGQLQuery<assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId>);

export const FetchAgencyTeamMemberAvailabilityByOrderIdDataProvider: FC<{
  janeId: string;
  orderId: number;
  orderRelayId: string;
  onSuccess: (data: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId) => void;
}> = ({ children, janeId, orderId, orderRelayId, onSuccess }) => {
  const { fetch, ...rest } = useGQLQuery<
    assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
    assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
    assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderIdVariables
  >(agencyJaneOrderValidationQueryConfig({ janeId, orderId, orderRelayId, onSuccess }));

  return (
    <FetchAgencyTeamMemberAvailabilityByOrderIdDataContext.Provider value={{ fetch, ...rest }}>
      {children}
    </FetchAgencyTeamMemberAvailabilityByOrderIdDataContext.Provider>
  );
};

export const useFetchAgencyTeamMemberAvailabilityByOrderIdDataContext =
  (): UseGQLQuery<assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId> =>
    useContext(FetchAgencyTeamMemberAvailabilityByOrderIdDataContext);
