import { CreateShiftButton } from 'components/CreateShiftButton';
import { Flex } from 'components/Flex';
import { useTranslation } from 'hooks/useTranslation';
import { usePathname } from 'hooks/usePathname';
import {
  dashboardPageEmptyBodyCopy,
  dashboardPageEmptyHeaderCopy,
} from 'pages/DashboardPage/DashboardEmptyView/DashboardEmptyView.constants';
import { EmptyBody, EmptyHeader, EmptyMessage } from './DashboardEmptyView.styled';

export function DashboardEmptyView() {
  const { t } = useTranslation();
  const pathname = usePathname();
  return (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
      <EmptyMessage>
        <EmptyHeader>{t(dashboardPageEmptyHeaderCopy[pathname])}</EmptyHeader>
        <EmptyBody>{t(dashboardPageEmptyBodyCopy[pathname])}</EmptyBody>
        <CreateShiftButton />
      </EmptyMessage>
    </Flex>
  );
}
