import styled from 'styled-components';
import { Flex } from 'components/Flex';
import { BKJToggleInput, BKJButton } from '@bookjane2/bookjane-design-library';

export const AutoExtendBidToggleInputLabel = styled.span`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const AutoExtendBidToggleInputWrapper = styled(Flex)`
  margin-left: 24px;
  align-items: center;
  gap: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin: 8px 0 0 0;
    padding: 12px 0;
  }
`;

export const StyledBKJToggleInput = styled(BKJToggleInput)`
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ShiftCardButtonWithNoHeight = styled(BKJButton)`
  width: fit-content;
  min-height: fit-content;
  &:not(:first-child) {
    margin-left: 24px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
