import { CUSTOMER_AVAILABILITY } from 'constants/endpoints';
import { METHOD_GET } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_GET_customerAvailability = (id: string): ApiRequestConfigType => {
  return {
    method: METHOD_GET,
    url: CUSTOMER_AVAILABILITY(id),
    __typename: 'LegacyAPI',
  };
};
