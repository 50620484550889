import { FC } from 'react';
import { IBKJCenteredDialogProps } from 'components/BKJCenteredDialog/BKJCenteredDialog.types';
import { Portal } from 'components/Portal/Portal';
import {
  BKJCenteredDialogBackdrop,
  StyledBKJDialog,
} from 'components/BKJCenteredDialog/BKJCenteredDialog.styled';
import { getJ360ElementNode } from 'utils/getJ360ElementNode';
import { MODAL_ROOT_ID } from 'constants/elements';
let targetElement = getJ360ElementNode(MODAL_ROOT_ID);

export const BKJCenteredDialog: FC<IBKJCenteredDialogProps> = (props): JSX.Element | null => {
  const { isOpen, onClose, bgColor } = props;
  if (!isOpen) return null;

  if (!targetElement) {
    targetElement = document.createElement('div');
    targetElement.setAttribute('id', MODAL_ROOT_ID);
    document.body.appendChild(targetElement);
  }

  return (
    <Portal targetElement={targetElement}>
      <StyledBKJDialog {...props} ref={null} />
      <BKJCenteredDialogBackdrop bgColor={bgColor} onClick={onClose} />
    </Portal>
  );
};
