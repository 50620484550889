import { InviteAdminModalComponent } from 'components/InviteAdminModal/InviteAdminModal.component';
import { InviteAdminModalUIProvider } from 'components/InviteAdminModal/InviteAdminModal.ui.context';
import { FC } from 'react';

export const InviteAdminModal: FC = () => {
  return (
    <InviteAdminModalUIProvider>
      <InviteAdminModalComponent />
    </InviteAdminModalUIProvider>
  );
};
