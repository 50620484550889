import {
  CustomFieldInfoFormSchema,
  CustomFieldType,
} from 'components/CustomFieldModal/CustomFieldModal.types';
import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import i18next from 'i18next';

export const VALIDATION_placeholder: UseFormBehaviors_ValidationFnType<CustomFieldInfoFormSchema> =
  (value, rest): string => {
    const fieldName = value as string;
    if (
      !(['Single-Line Text', 'Paragraph Text'] as CustomFieldType[]).includes(
        rest!.type[0] as CustomFieldType,
      )
    )
      return '';
    if (fieldName.length > 50) return i18next.t('auth:MAX_CHARACTER_LIMIT_ERROR');
    return '';
  };
