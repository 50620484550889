import React from 'react';
import closeIcon from 'assets/scheduling/close.svg';
import { useSelector } from 'react-redux';
import activePositionTabSelector from 'store/selectors/scheduleSelectors/activePositionTabSelector';
import { useScheduleActions } from 'store/reducers/scheduleSlice/scheduleSlice';
import getElementById from 'utils/getElementById';
import { SearchedMembersIcon, StyledTab, TabCloseButton, TabLabel } from './index.styled';
import { Wrapper } from './NewTabInput.styled';
import { useTranslation } from 'hooks/useTranslation';

export const SEARCHED_MEMBERS_TAB = 'SearchedMembers';

function SearchedMembersTab(props) {
  const ID = 'TAB_SEARCHED_MEMBERS';
  const { removePositionTab, setActiveTab, setSelectedJaneIds } = useScheduleActions();
  const activeTab = useSelector(activePositionTabSelector);

  const isActive = activeTab === SEARCHED_MEMBERS_TAB;

  const { t } = useTranslation();

  function handleCloseTab(event) {
    event.stopPropagation();
    removePositionTab(SEARCHED_MEMBERS_TAB);
    setSelectedJaneIds([]);
  }

  function handleTabClick() {
    if (!isActive) {
      setActiveTab(SEARCHED_MEMBERS_TAB);

      const element = getElementById(ID);

      if (element && element.scrollIntoView && typeof element.scrollIntoView === 'function') {
        element.scrollIntoView(true);
      }
    }
  }

  return (
    <Wrapper id={ID}>
      <StyledTab
        role="tab"
        aria-selected={isActive}
        isActive={isActive}
        tabIndex={-1}
        onClick={handleTabClick}
        {...props}
      >
        <SearchedMembersIcon />
        <TabLabel isActive={isActive}>{t('schedule:SEARCHED_MEMBERS')}</TabLabel>

        <TabCloseButton onClick={handleCloseTab}>
          <img alt="Close Icon" width={12} height={12} src={closeIcon} />
        </TabCloseButton>
      </StyledTab>
    </Wrapper>
  );
}

SearchedMembersTab.propTypes = {};

export default SearchedMembersTab;
