import { useDispatch } from 'react-redux';
import { createBookJaneRequest } from 'utils/createBookJaneRequest';
import { createSlice, createAsyncThunk, bindActionCreators } from '@reduxjs/toolkit';
import { IHeartbeat } from 'store';
import { API_GET_j360ApiVersion } from 'requests/GET_j360ApiVersion';
import { AxiosError } from 'axios';

const types = {
  HEARTBEAT_J360_API: 'heartbeat/j360-api',
};

export const j360ApiHeartbeat = createAsyncThunk(
  types.HEARTBEAT_J360_API,
  async (_, { rejectWithValue }) => {
    try {
      await createBookJaneRequest(API_GET_j360ApiVersion);
      return true;
    } catch (error) {
      return rejectWithValue((error as AxiosError)?.response?.data);
    }
  },
);

export const heartbeatSlice = createSlice({
  name: 'heartbeat',
  initialState: {
    isJ360ApiDown: false,
  },
  reducers: {},
  extraReducers: {
    [j360ApiHeartbeat.fulfilled as unknown as string]: (state: IHeartbeat) => {
      state.isJ360ApiDown = false;
    },
    [j360ApiHeartbeat.rejected as unknown as string]: (state: IHeartbeat) => {
      state.isJ360ApiDown = true;
    },
  },
});

export function useHeartbeatActions() {
  return bindActionCreators(
    {
      j360ApiHeartbeat,
      ...heartbeatSlice.actions,
    },
    useDispatch(),
  );
}

const heartbeatReducer = heartbeatSlice.reducer;
export default heartbeatReducer;
