import { InMemoryCache } from '@apollo/client';
import {
  CustomFieldEnumMap,
  ICustomField,
} from 'components/CustomFieldModal/CustomFieldModal.types';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloClient } from 'providers/ApolloProvider';
import {
  QUERY_createCustomFieldFetchCommunities,
  QUERY_editCustomFieldFetchCustomFieldById,
} from 'queries';
import { editCustomFieldFetchCustomFieldById } from 'types/graphql-types';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        fetchCommunities: {
          keyArgs: ['__ref'],
          merge(existing = { nodes: [] }, incoming = { nodes: [] }, { readField }) {
            const nextNodes = [...existing.nodes];
            if (incoming.nodes?.length > 0)
              incoming.nodes.forEach((record: { record: {} }) => {
                const id = readField('id', record);
                if (id)
                  if (
                    !nextNodes.some((nodeRef) => {
                      const nodeRefId = readField('id', nodeRef);
                      return nodeRefId === id;
                    })
                  ) {
                    nextNodes.push(record);
                  }
              });
            return { ...existing, ...incoming, nodes: nextNodes };
          },
        },
      },
    },
  },
});

const CreateEditCustomFieldsApolloClient = createApolloClient({ cache });

export function fetchCommunitiesQueryConfig(communityPermissions: string[]): ISSFBehaviorsOptions {
  return {
    client: CreateEditCustomFieldsApolloClient,
    key: 'CREATE_EDIT_CUSTOM_FIELD_MODAL_FETCH_COMMUNITIES',
    query: QUERY_createCustomFieldFetchCommunities,
    paginationType: 'InfiniteScroll',
    payloadTransformer: (variables) => ({
      ...variables,
      withId: communityPermissions.map((communityId) => Number.parseInt(communityId)),
    }),
    schema: {
      byName: {
        initialValue: '',
      },
      sort: {
        initialValue: {
          field: 'name',
          direction: 'asc',
        },
      },
    },
    pageSize: 10,
  };
}

export function fetchCustomFieldByIdQueryConfig(
  customFieldId: number,
): UseGQLQueryConfig<
  editCustomFieldFetchCustomFieldById,
  ICustomField,
  { fetchCustomFieldId: number }
> {
  return {
    key: 'CREATE_EDIT_CUSTOM_FIELD_MODAL_FETCH_CUSTOM_FIELD_BY_ID',
    query: QUERY_editCustomFieldFetchCustomFieldById,
    variables: {
      fetchCustomFieldId: customFieldId,
    },
    responseTransformer: (data) => ({
      id: data?.fetchCustomField.id,
      name: data?.fetchCustomField.name,
      mobile: data?.fetchCustomField.mobile,
      type: CustomFieldEnumMap[data?.fetchCustomField.fieldType],
      communityIds: data?.fetchCustomField.communities?.map((community) => `${community.id}`) || [],
      preFillDefault: data?.fetchCustomField.prefillDefault,
      allFacilities: data?.fetchCustomField.allFacilities,
      dropdownOptions:
        data?.fetchCustomField.customFieldOptions?.map((customField) => customField.value) || [],
      placeholder: data?.fetchCustomField.placeholder,
    }),
  };
}
