import styled from 'styled-components';

export const FormErrorBox = styled.div`
  background-color: #fff6f6;
  color: #9f3a38;
  padding: 1em 1.5em;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  box-sizing: border-box;
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
`;
