import { globalStore } from 'reduxStore';
import { sessionService, SessionService } from 'services/SessionService';
import axios, { AxiosResponse, Method } from 'axios';
import qs, { IStringifyOptions } from 'qs';
import { ApiRequestConfigType } from 'requests/index.types';
import { getTimeZone } from 'utils/getTimeZone';
import { j360ApiHeartbeat } from 'store/reducers/heartbeat/heartbeatSlice';
import { captureException } from 'utils/captureException';
import { getOperatingSystem } from 'utils/getOperatingSystem';
import { getWebVersion } from 'utils/getWebVersion';
import { userLogout } from 'store/reducers/auth/authReducer.actions';

const defaultQueryStringSerializerOpts: IStringifyOptions = {
  arrayFormat: 'brackets',
  encodeValuesOnly: true,
};

export interface ICreateBookJaneRequestOptions {
  url: string;
  method: Method;
  headers?: Record<string, string>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: Record<string, any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Record<string, any>;
  queryStringOpts?: IStringifyOptions;
  responseType?: 'blob';
}

export interface ICreateBookJaneRequestConfig extends ApiRequestConfigType {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsSerializer: (params: Record<string, any>) => string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
  headers: Record<string, string>;
  responseType?: 'blob';
}

export function createBookJaneRequestHeaders(headers: Record<string, string> = {}) {
  const sessionToken = SessionService.getToken();

  const _headers: Record<string, string> = {
    'CONTENT-TYPE': 'application/json',
    'USER-LOCAL-TIMEZONE': getTimeZone(),
    'X-APP-VERSION': getWebVersion(),
    'X-APP': 'Web',
    'X-OS': getOperatingSystem(),
    ...headers,
  };

  if (sessionToken) _headers['AUTHORIZATION'] = `Bearer ${sessionToken}`;
  return _headers;
}

export async function createBookJaneRequest<TData = unknown>(
  opts: ICreateBookJaneRequestOptions,
  disableAuth = false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<TData>> {
  const {
    url,
    method,
    headers = {},
    body,
    query,
    responseType = null,
    queryStringOpts = defaultQueryStringSerializerOpts,
  } = opts;
  if (!method) throw new Error('Required parameter method was undefined.');
  if (!url) throw new Error('Required parameter url was undefined.');

  const config: ICreateBookJaneRequestConfig = {
    url,
    data: body,
    method,
    headers: createBookJaneRequestHeaders(headers),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paramsSerializer: (params: Record<string, any>) => qs.stringify(params, queryStringOpts),

    __typename: 'LegacyAPI',
  };

  if (query) config.params = query;

  if (responseType) config.responseType = responseType;

  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      captureException(error, {
        tags: {
          type: 'LegacyAPIErrorResponse',
        },
        extra: {
          config: {
            url,
            data: body,
            method,
            headers: createBookJaneRequestHeaders(headers),
            __typename: 'LegacyAPI',
          },
        },
      });
      if (error.response) {
        switch (error.response.status) {
          case 502:
          case 503:
          case 401:
            if ([503, 503].includes(error.response.status))
              void globalStore.dispatch(j360ApiHeartbeat());
            void globalStore.dispatch(userLogout());
            sessionService.onUserLoggedOut();
            return Promise.reject(error);
          default:
            return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    },
  );
  if (disableAuth) delete config.headers['AUTHORIZATION'];
  const response = await axios.request<TData>(config);
  return response;
}
