import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const ReviewBoxWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.Grey4};
  box-sizing: border-box;
  border-radius: 4px;
  min-height: fit-content;
  height: fit-content;
`;

export const ReviewBoxHeader = styled(Flex)`
  position: relative;
  overflow: hidden;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  align-items: center;
  padding: 0 15px;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const ReviewBoxTitle = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextWhite};
  font-size: 20px;
  line-height: 30px;
`;

export const ReviewBoxBody = styled(Flex)`
  padding: 12px 16px;
  margin-bottom: -35px;
`;
