import i18next from 'i18next';
import {
  FeedableEditDropdownEnum,
  shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits,
} from 'types/graphql-types';

export const shiftTypeDetailsMapper: Record<
  FeedableEditDropdownEnum,
  (shiftEdit: shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits) => string
> = {
  dropdown: (shiftEdit: shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits) =>
    `${shiftEdit.fieldName} ${i18next.t('shifts_calendar:FROM')} "${
      shiftEdit.oldValuePretty
    }" ${i18next.t('shifts_calendar:TO')} "${shiftEdit.newValuePretty}"`,

  text_area: (shiftEdit: shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits) =>
    `${shiftEdit.fieldName} ${i18next.t('shifts_calendar:MODIFIED')}`,
  input: (shiftEdit: shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits) =>
    `${shiftEdit.fieldName} ${i18next.t('shifts_calendar:MODIFIED')}`,
  checkbox: (shiftEdit: shiftModalOrderDetails_fetchOrder_activities_metadata_feedableEdits) =>
    `${shiftEdit.fieldName} ${i18next.t('shifts_calendar:MODIFIED')}`,
};
