import { useCallback, useEffect } from 'react';

interface HTMLElementRef {
  current: HTMLElement | null;
}

export const useClickOutside = (
  refs: Array<HTMLElementRef | HTMLElement | null>,
  handler: (e: MouseEvent) => void,
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent): void => {
      if (
        refs.length > 0 &&
        !refs.some(
          (ref) =>
            (ref instanceof Node && ref && ref?.contains(event.target as Node)) ||
            ((ref as HTMLElementRef)?.current &&
              !(ref as HTMLElementRef)?.current?.contains(event.target as Node)),
        )
      )
        handler(event);
    },
    [handler, refs],
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [handleClickOutside]);
};
