import {
  BKJButton,
  BKJCheckbox,
  BKJPillTextArea,
  BKJTextInput,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJFormWrapper } from 'components/BKJFormWrapper';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useCreateCustomFieldFormContext } from 'components/CustomFieldCreateModal/contexts/CreateCustomField.form.context';
import {
  CUSTOM_FIELDS_MODAL_DESCRIPTION,
  CUSTOM_FIELDS_MODAL_DROPDOWN_OPTIONS_PLACEHOLDER,
  DropdownOptionLimit,
  whatsThisUrlRedirect,
} from 'components/CustomFieldModal/CustomFieldModal.constants';
import {
  Description,
  Heading,
  MobileCheckboxTextWrapper,
  TextButtonWrapper,
} from 'components/CustomFieldModal/CustomFieldModal.styled';
import { CustomFieldTypeSelectInput } from 'components/CustomFieldTypeSelectInput';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const CreateCustomField: FC = () => {
  const { onChange, values, fieldErrors, isDisabled, onSubmit } = useCreateCustomFieldFormContext();
  const { t } = useTranslation();
  const { isPetalProvider } = WhiteLabelAccessor;

  return (
    <BKJFormWrapper onSubmit={(e) => onSubmit(e)}>
      <Flex flexDirection="column" gap="32px" padding="32px" flex="1">
        <Heading data-cy="hdr-create-new-field">{t('settings:CREATE_NEW_FIELD')}:</Heading>

        <Description data-cy="msg-custom-field-modal">
          {t(CUSTOM_FIELDS_MODAL_DESCRIPTION)}
        </Description>

        <Flex gap="4px" flexDirection="column">
          <Description data-cy="hdr-field-type">{t('settings:FIELD_TYPE')}</Description>
          <CustomFieldTypeSelectInput
            value={values.type}
            onChange={onChange}
            name={'type'}
            placeholder={''}
          />

          {!isPetalProvider && (
            <form action={whatsThisUrlRedirect} target="_blank">
              <TextButtonWrapper type="submit" variant="PurpleText" data-cy="link-whats-this">
                {t('settings:WHAT_IS_THIS')}?
              </TextButtonWrapper>
            </form>
          )}
        </Flex>

        <Flex gap="4px" flexDirection="column">
          <BKJTextInput
            value={values.name}
            onChange={onChange}
            label={t('settings:FIELD_NAME')}
            required
            name={'name'}
            placeholder={t('settings:ENTER_FIELD_NAME')}
            error={fieldErrors.name}
            autoComplete="off"
          />
        </Flex>

        {['Single-Line Text', 'Paragraph Text'].includes(values.type[0]) ? (
          <Flex gap="4px" flexDirection="column">
            <BKJTextInput
              value={values.placeholder}
              onChange={onChange}
              label={t('settings:PLACEHOLDER_TEXT')}
              name={'placeholder'}
              placeholder={t('settings:PLACEHOLDER_TEXT_APPEARS')}
              error={fieldErrors.placeholder}
              autoComplete="off"
            />
          </Flex>
        ) : null}

        {values.type[0] === 'Single-Select Dropdown' && (
          <Fragment>
            <Flex gap="4px" flexDirection="column">
              <BKJPillTextArea
                label={t('settings:DROPDOWN_VALUES')}
                placeholder={t(CUSTOM_FIELDS_MODAL_DROPDOWN_OPTIONS_PLACEHOLDER)}
                valueDescription="values"
                value={values.dropdownOptions}
                required={true}
                onChange={onChange}
                validations={[]}
                max={DropdownOptionLimit}
                name="dropdownOptions"
                height="216px"
                error={fieldErrors.dropdownOptions}
                autoComplete="off"
              />
            </Flex>
            {/* https://bookjane.atlassian.net/browse/WEB-2504: Remove 'prefill dropdown value with first value in the list' */}
            {/* <Flex>
              <Description>
                <BKJCheckbox
                  value={values.preFillDefault}
                  name="preFillDefault"
                  variant={'GreenSolid'}
                  onChange={onChange}
                />
                {t(CUSTOM_FIELDS_MODAL_DROPDOWN_PLACEHOLDER)}
              </Description>
            </Flex> */}
          </Fragment>
        )}

        <Flex>
          <BKJCheckbox
            variant="GreenSolid"
            value={values.mobile}
            name="mobile"
            onChange={onChange}
          />
          <MobileCheckboxTextWrapper>{t('settings:SHOW_FIELD_NOTE')}</MobileCheckboxTextWrapper>
        </Flex>
      </Flex>

      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton variant="PurpleSolid" width="192px" disabled={isDisabled} type="submit">
          {t('auth:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </BKJFormWrapper>
  );
};
