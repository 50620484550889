import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { ShiftModalTemplate } from 'components/ShiftModal/ShiftModalTemplate';
import {
  EditViewRenderer,
  SendToAgenciesViewRenderer,
  AuditTrailViewRenderer,
  AwardTeamMemberViewRender,
} from 'components/ShiftModal/src/views';
import { AssignToAgencyTeamMemberViewRenderer } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/AssignToAgencyTeamMemberView';
import { FC, useMemo } from 'react';

export const ShiftModalComponent: FC = () => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();

  const Component = useMemo(() => {
    return {
      ...ShiftModalComponentMap,
      EditView: EditViewRenderer,
      NotifyTeamMember: EditViewRenderer,
      ReasonForEdits: EditViewRenderer,
      EditingReasonAndNotifyView: EditViewRenderer,
      ConfirmEditsView: EditViewRenderer,
      PreRequestOptionsView: SendToAgenciesViewRenderer,
      SendToAgenciesSummaryView: SendToAgenciesViewRenderer,
      RequestTeamMemberView: SendToAgenciesViewRenderer,
      PostRequestOptionsView: SendToAgenciesViewRenderer,
      SelectAgenciesView: SendToAgenciesViewRenderer,
      SendToSelectedAgenciesSummaryView: SendToAgenciesViewRenderer,
      AssignToAgencyTeamMemberView: AssignToAgencyTeamMemberViewRenderer,
      AssignToAgencyTeamMemberSummaryView: AssignToAgencyTeamMemberViewRenderer,
      TeamMemberUnavailableView: AssignToAgencyTeamMemberViewRenderer,
      ShiftUnavailableView: AssignToAgencyTeamMemberViewRenderer,
      AuditTrailViewRenderer: AuditTrailViewRenderer,
      AwardTeamMemberView: AwardTeamMemberViewRender,
      AwardUnavailableView: AwardTeamMemberViewRender,
    }[shiftModalActiveModalView];
  }, [shiftModalActiveModalView]);
  return (
    <ShiftModalTemplate>
      <Component />
    </ShiftModalTemplate>
  );
};
