import { FC } from 'react';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import {
  BKJIconNameType,
  IBKJIconProps,
} from '@bookjane2/bookjane-design-library/lib/components/BKJIcon/BKJIcon.types';

const style = { minWidth: 22, width: 22 };

interface INavMenuIconProps extends IBKJIconProps {
  iconName: BKJIconNameType;
}

export const NavMenuIcon: FC<INavMenuIconProps> = (props) => {
  return <BKJIcon {...props} style={style} color="White" />;
};
