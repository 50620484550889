import styled, { css } from 'styled-components';
import { zIndexLayers } from 'constants/zIndexLayers';
import { BKJCalendarPopUpVariantType, ICalendarWrapperProps } from './CalendarPopUp.types';

export const CalendarBox = styled.div<ICalendarWrapperProps>`
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #0d33204d;
  width: 358px;
`;

const getCalendarPopUpZIndex = (variant?: BKJCalendarPopUpVariantType) => {
  switch (variant) {
    case 'SSFTopBar': {
      return zIndexLayers.ssfCalendarPopUp;
    }
    case 'SideModal': {
      return zIndexLayers.aboveSideModalDrawer;
    }
    default:
      return zIndexLayers.ssfCalendarPopUp;
  }
};

export const CalendarPopUpWrapper = styled.div<ICalendarWrapperProps>`
  width: 360px;
  min-width: 360px;
  height: 414px;
  box-shadow: 0px 2px 8px 0px #0d33204d;
  border-radius: 8px;
  padding: 12px 12px 20px 12px;
  background-color: ${({ theme }) => theme.colors.White};
  z-index: ${zIndexLayers.ssfCalendarPopUp};

  ${({ variant }) =>
    variant &&
    css`
      z-index: ${getCalendarPopUpZIndex(variant)};
    `};

  ${({ width }) =>
    width &&
    css`
      width: width;
    `};
`;
