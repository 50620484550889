import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IXtmLogoProps } from './XtmLogo.types';

export const XtmLogo: FC<IXtmLogoProps> = (props) => {
  const themeContext = useContext(ThemeContext);
  const { color = themeContext.colors.White, fontSize = 40, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M38.695 21.9423C40.3396 22.9655 40.4508 25.3453 38.909 26.5215L16.6849 43.4743C14.4509 45.1785 11.5858 42.3927 13.1743 40.0608L21.7021 27.5428C23.0673 25.5387 22.8071 22.8293 21.0861 21.1295L7.67376 7.88292C7.24296 7.45743 7 6.8737 7 6.26413C7 4.49664 8.91198 3.41287 10.3986 4.33774L38.695 21.9423Z"
        fill={color}
      />
    </svg>
  );
};
