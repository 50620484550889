import { BKJAvatar, BKJButton, BKJThemeColorType, Flex } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

export const ActivityTabTableWrapper = styled(Flex)`
  height: 100%;
  overflow-y: scroll;
`;

// temporarily updated Width for petal demo
export const ActivityTabTableStyled = styled.div`
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;
  grid-template-columns: 144px 186px 1fr;
  overflow-y: scroll;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const ColorDot = styled.span<{ color: BKJThemeColorType }>`
  font-size: 2rem;
  padding: 0 12px 0 0;
  ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => theme.colors[color]};
    `}
`;

export const TableCell = styled.div<{
  isGrey: boolean;
  detailsShown?: boolean;
  spanTwoRows?: boolean;
  hasDetails?: boolean;
}>`
  display: flex;
  align-items: ${({ hasDetails }) => (hasDetails ? 'flex-start' : 'center')};
  padding: 12px 16px 12px 32px;
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
  border-bottom: ${({ detailsShown, theme }) => !detailsShown && `1px solid ${theme.colors.Grey4}`};
  grid-row: ${({ spanTwoRows }) => spanTwoRows && 'span 2'}; ;
`;

export const MobileAuditTrailContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
  height: auto;
  padding: 12px 20px;
`;

export const MobileActivityDate = styled.div`
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  height: auto;
`;

export const MobileTableCell = styled.div`
  font-size: 16px;
  line-height: 24px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-weight: 500;
`;

export const EditedFields = styled.div`
  word-break: break-word;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 14px;
  }
`;

export const CancellationReason = styled.div`
  font-size: 14px;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const TableRowCell = styled(TableCell)`
  min-height: 72px;
`;

export const TableHeaderCell = styled.div`
  top: 0;
  position: sticky;
  z-index: 2;
  min-height: 72px;
  max-height: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0px 16px 0px 32px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  background-color: white;
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TableRowViewDetailsCell = styled(TableCell)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px 12px 36px;
  grid-column: 2 / span 2;
`;

export const TableRowViewDetailsCellAuditTrailV1 = styled(TableRowViewDetailsCell)`
  padding: 0 16px 12px 68px;
`;

export const AwardedShift = styled.div`
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const MobileTableRowViewDetailsCell = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  padding: 0 0 0 38.5px;
`;

export const InlineBKJButton = styled(BKJButton)`
  display: inline;
  padding: 0 0 0 0;
  width: fit-content;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 16px;
  }
`;

export const BKJAvatarStyled = styled(BKJAvatar)``;

export const AuditTrailActivityTabButton = styled(BKJButton)`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: auto;
    font-size: 16px;
  }
`;
