import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const EmptyViewFlex = styled(Flex)`
  margin-top: 16px;
  background-color: ${({ theme }) => theme.colors.White};
`;

export const EmptyMessage = styled.div`
  max-width: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const EmptyHeader = styled.h3`
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  ${({ theme }) => theme.fonts.PoppinsText};
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 12px;
`;

export const EmptyBody = styled.span`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 24px;
  margin-bottom: 24px;
`;
