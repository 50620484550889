import { BKJIcon, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { AppLink } from 'components/AppLink/AppLink';
import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISettingsNavigationProps } from 'components/SettingsNavigation/ExpandableMenuLink/ExpandableMenuLink.types';
import {
  locationSettingsPagePath,
  locationSettingsPageRouteMatch,
} from 'components/SettingsNavigation/SettingsNavigation.constants';
import {
  AddNewButton,
  AddNewLinkWrapper,
  LocationSearchInputWrapper,
  MenuAccordion,
} from 'components/SettingsNavigation/SettingsNavigation.styled';
import { SettingsNavMenuItem } from 'components/SettingsNavigation/SettingsNavMenuItem';
import { useCachedScrollRef } from 'hooks/useCachedScrollRef';
import { useHandleSearch } from 'hooks/useHandleSearch';
import { useNumSettingsMenuItems } from 'hooks/useNumSettingsMenuItems';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
// import { AdministratorSettingsDataContext } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { filterStrictMatch } from 'utils/filterStrictMatch';
import { useSelector } from 'react-redux';
import isUserSecondarySelector from 'store/selectors/userSelectors/isUserSecondarySelector';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FeatureGuard } from 'guards/FeatureGuard';
import { isAccushieldSyncingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isAccushieldSyncingFeatureEnabledSelector';
import { useTranslation } from 'hooks/useTranslation';
export const LocationSettingsNavigation: FC<ISettingsNavigationProps> = ({
  isOpen,
  isExpanded,
}) => {
  const numMenuItems = useNumSettingsMenuItems();
  const isUserSecondary = useSelector(isUserSecondarySelector);

  const {
    locationFilter: { data },
  } = useAuthRouteDataContext();

  const { scrollElementRef, handleScroll } = useCachedScrollRef(
    'LocationSettingsNavigationScrollTop',
  );

  const { search, onChange, onReset, handleKeyDown } = useHandleSearch();
  const isAccushieldSyncing = useSelector(isAccushieldSyncingFeatureEnabledSelector);

  const match = useRouteMatch<{ locationId: string }>(locationSettingsPageRouteMatch);
  const locationId = match?.params?.locationId;

  const items = useMemo(() => {
    return filterStrictMatch<LocationOptionType>(search, data, (item) => {
      return item.label;
    });
  }, [data, search]);
  const { t } = useTranslation();

  return (
    <MenuAccordion
      $isOpen={isOpen}
      onScroll={handleScroll}
      $isExpanded={isExpanded}
      numMenuItems={numMenuItems}
      ref={scrollElementRef}
    >
      <FeatureGuard NOT type="FEATURE_TYPE_ACCUSHIELD_SYNCING">
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
            UserPermissionEnum.USER_TYPE_ESSENTIAL_PRIMARY,
            UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
          ]}
        >
          <AddNewLinkWrapper
            isActive={!!match?.url.includes('new')}
            to="/settings/locations/new/general"
          >
            <AddNewButton data-cy="btn-Add Facility">
              <BKJIcon iconName="Add" />
              {t('settings:ADD_LOCATION')}
            </AddNewButton>
          </AddNewLinkWrapper>
        </UserGuard>
      </FeatureGuard>
      <LocationSearchInputWrapper
        width="100%"
        justifyContent="center"
        alignItems="center"
        padding="0 16px"
        isTopStickied={isUserSecondary}
        isAdjustForReadOnly={isAccushieldSyncing}
      >
        <BKJTextInput
          name="search"
          onChange={onChange}
          value={search}
          placeholder={t('common:SEARCH')}
          onKeyDown={handleKeyDown}
          leftIconProps={{ iconName: 'Search' }}
          rightIconProps={
            search
              ? {
                  iconName: 'CloseBold',
                  onClick: () => onReset(),
                }
              : undefined
          }
        />
      </LocationSearchInputWrapper>
      {items.length === 0 && search.length > 0 && (
        <SettingsNavMenuItem
          active={false}
          label={t('common:NO_RESULTS')}
          scrollElement={scrollElementRef.current}
        />
      )}
      {items.map((option) => {
        const active = locationId === option.value.toString();
        return (
          <AppLink key={option.key} to={`${locationSettingsPagePath}/${option.value}/general`}>
            <SettingsNavMenuItem
              active={active}
              label={option.label}
              scrollElement={scrollElementRef.current}
            />
          </AppLink>
        );
      })}
    </MenuAccordion>
  );
};
