import { BKJIcon } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const Rating = styled.div`
  width: 160px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 51px;
  background-color: ${({ theme }) => theme.colors.Grey1};
`;

export const RatingWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
`;

export const StarIcon = styled(BKJIcon)`
  cursor: pointer;
`;
