import { DateTime } from 'luxon';

export interface TimeFrameLocationSettingsFormFields {
  morningStartsAt?: string;
  eveningStartsAt?: string;
  nightStartsAt?: string;
}
export interface TimeFrameLocationSettingsFormFieldsArray {
  communities: TimeFrameLocationSettingsFormFields[];
}

export interface TimeFrameLocationSettingsFormApiError {
  message?: string;
}
export const formatTimeWithAP = (timeString: string) =>
  DateTime.fromFormat(timeString, 'HH:mm:ss').toFormat('h:mm a');

export const calculateTimeDifference = (startTime: string, endTime: string): number => {
  const start = DateTime.fromFormat(startTime, 'HH:mm:ss');
  let end = DateTime.fromFormat(endTime, 'HH:mm:ss');

  // If end time is earlier than start time, add a day to end time
  if (end < start) {
    end = end.plus({ day: 1 });
  }

  const durationInMinutes = end.diff(start, 'minutes').minutes;

  return durationInMinutes / 60;
};
export const calculateTotalHours = (data: TimeFrameLocationSettingsFormFields) => {
  const morningEveningD = calculateTimeDifference(
    String(data?.morningStartsAt),
    String(data?.eveningStartsAt),
  );
  const eveningNightD = calculateTimeDifference(
    String(data?.eveningStartsAt),
    String(data?.nightStartsAt),
  );
  const nightMorningD = calculateTimeDifference(
    String(data?.nightStartsAt),
    String(data?.morningStartsAt),
  );
  return morningEveningD + eveningNightD + nightMorningD;
};
