export const formatPhoneNumber = (phoneNumber: string): string => {
  // Remove all non-numeric characters from the phone number
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  // Format the phone number as (XXX)XXX-XXXX
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]})${match[2]}-${match[3]}`;
  }

  // Return the original phone number if the format is not recognized
  return phoneNumber;
};
