import { Flex } from 'components/Flex';
import { CalendarMoreOptionsWrapper, Circle } from 'components/ShiftCalendar/ShiftCalendar.styled';
import { CalendarDayMoreOptionsProps } from 'components/ShiftCalendar/ShiftCalendar.types';
import { shiftStatusMap } from 'constants/shiftStatusMap';
import { useTranslation } from 'hooks/useTranslation';
import { FC } from 'react';

export const CalendarMoreOptions: FC<CalendarDayMoreOptionsProps> = ({ truncatedStatuses }) => {
  const { t } = useTranslation();
  return (
    <CalendarMoreOptionsWrapper>
      <Flex flexGrow={1}>
        + {truncatedStatuses.length} {t('shifts_calendar:MORE_SHIFTS')}
      </Flex>
      <Flex height="10px" justifyContent={'space-between'}>
        {truncatedStatuses.map((orderStatus, index) => (
          <Circle key={`${orderStatus}-${index}`} color={shiftStatusMap[orderStatus]} />
        ))}
      </Flex>
    </CalendarMoreOptionsWrapper>
  );
};
