import createCachedSelector from 're-reselect';
import { IGlobalReduxStore } from 'store';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

export const userIdSelector = createCachedSelector(getUser, (user) => {
  if (!user) return null;
  return user.id;
})((store) => {
  if (!store.auth.user) return 'NO_SESSION';

  return store.auth.user.id;
});
