import { BKJAvatar, BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  NavigationButton,
  NavigationButtonsWrapper,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import { useFetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import { formatDetailTimeOffStartTimeEndTime } from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.constants';
import { useDetailTimeOffViewPropsContext } from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.props.context';
import {
  DetailTimeOffViewBlockWrapper,
  DetailTimeOffViewHeaderWrapper,
  DetailTimeOffViewJaneInfoWrapper,
  DetailTimeOffViewStartTimeEndTimeWrapper,
  DetailTimeOffViewWrapper,
  MainTitle,
} from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.styled';
import { DetailTimeOffViewTabComponentMap } from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.types';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment } from 'react';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const DetailTimeOffViewComponent: FC = () => {
  const {
    isEditable,
    goToEditTimeOffView,
    goToDeleteTimeOffView,
    activeTab,
    goToGeneralTab,
    goToActivityTab,
  } = useDetailTimeOffViewPropsContext();

  const ActiveTabComponent = DetailTimeOffViewTabComponentMap[activeTab];

  const { t } = useTranslation();

  const {
    data: {
      fetchTimeOff: {
        activities,
        startTime,
        endTime,
        community,
        title: { value: timeOffTitle = t('schedule:TIME_OFF') },
        jane: {
          firstName,
          lastName,
          communityName,
          avatarUrls: { origin },
          pendingSignup,
        },
      },
    },
  } = useFetchTimeOffDataContext();

  const { timeZone, value } = getCurrentlySelectedLocation();
  const isShiftFromCurrentLocation = community?.displayId === value;

  return (
    <Fragment>
      <NavigationButtonsWrapper>
        <NavigationButton
          isDisabled={false}
          isActive={activeTab === 'General'}
          onClick={goToGeneralTab}
        >
          {t('common:GENERAL')}
        </NavigationButton>
        <NavigationButton
          isDisabled={false}
          isActive={activeTab === 'Activity'}
          onClick={goToActivityTab}
        >
          {t('common:ACTIVITY')}
        </NavigationButton>
      </NavigationButtonsWrapper>
      {activeTab === 'General' && (
        <DetailTimeOffViewWrapper>
          <DetailTimeOffViewHeaderWrapper>
            <MainTitle>{timeOffTitle}</MainTitle>
            <DetailTimeOffViewBlockWrapper>
              <BKJAvatar
                width="30px"
                height="30px"
                fallbackAvatarColor={'TextDarkGrey'}
                src={origin}
                showPendingSignUp={pendingSignup}
              />
              <DetailTimeOffViewJaneInfoWrapper>
                <div>{`${firstName} ${lastName}`}</div>
                <div>{`${communityName}`}</div>
              </DetailTimeOffViewJaneInfoWrapper>
            </DetailTimeOffViewBlockWrapper>
            <DetailTimeOffViewStartTimeEndTimeWrapper>
              <div>
                {t('schedule:STARTS')}: {formatDetailTimeOffStartTimeEndTime(startTime, timeZone)}
              </div>
              <div>
                {t('schedule:ENDS')}: {formatDetailTimeOffStartTimeEndTime(endTime, timeZone)}
              </div>
            </DetailTimeOffViewStartTimeEndTimeWrapper>
          </DetailTimeOffViewHeaderWrapper>
        </DetailTimeOffViewWrapper>
      )}
      <ActiveTabComponent timeZone={timeZone} activities={activities} />

      {isEditable && isShiftFromCurrentLocation && (
        <BKJSideModalFooter justifyContent="space-between">
          <BKJButton variant="RedText" onClick={goToDeleteTimeOffView} width="fit-content">
            {t('auth:DELETE')}
          </BKJButton>
          <BKJButton variant="PurpleOutlined" onClick={goToEditTimeOffView} width="180px">
            {t('schedule:EDIT')}
          </BKJButton>
        </BKJSideModalFooter>
      )}
    </Fragment>
  );
};
