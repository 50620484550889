import { Flex } from 'components/Flex';
import { DEFAULT_BASE_URL, UK_BASE_URL } from 'config';
import { BKJ_J360_VERSION } from 'config/web-version';
import { Fragment, useEffect, useState } from 'react';
import { getWebVersion } from 'utils/getWebVersion';

export const Version = () => {
  const [backendVersion, setBackendVersion] = useState('');
  const [backendUKVersion, setBackendUKVersion] = useState('');

  useEffect(() => {
    fetch(`${DEFAULT_BASE_URL}/api_version`)
      .then((response) => response.text())
      .then((data) => setBackendVersion(data))
      .catch((error) => console.error('Error:', error));

    fetch(`${UK_BASE_URL}/api_version`)
      .then((response) => response.text())
      .then((data) => setBackendUKVersion(data))
      .catch((error) => console.error('Error:', error));
  }, []);

  return (
    <Fragment>
      <Flex padding="0 0 10px 0">
        <Flex flexDirection="column" gap="4px">
          <div>FE version:</div>
          <div>{BKJ_J360_VERSION}</div>
          <div>{getWebVersion()}</div>
        </Flex>
      </Flex>
      <Flex>
        <Flex flexDirection="column" gap="4px">
          <div>BE version:</div>
          <div>NA: {backendVersion}</div>
          <div>UK: {backendUKVersion}</div>
        </Flex>
      </Flex>
    </Fragment>
  );
};
