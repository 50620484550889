import { SSFBarsProvider } from 'components/SSFBars/SSFBars.context';
import { useSSFBehaviors } from 'hooks';
import { DateTime } from 'luxon';
import { shiftsCalendarPageConfig } from 'pages/ShiftsCalendarPage/ShiftsCalendarPage.context';
import { shiftsListPageConfig } from 'pages/ShiftsListPage/ShiftsListPage.context';
import {
  ShiftsPageRouteType,
  UseShiftsPageContextReturnType,
} from 'pages/ShiftsPage/ShiftsPage.types';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { createContext, FC, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { GQLAPIContextType, ISO8601Type, pageContextInitialState } from 'types/common.types';

export const ShiftsPageContext = createContext<GQLAPIContextType<{ date: string }>>({
  ...pageContextInitialState,
}) as GQLAPIContextType;

const shiftsPageConfigMap = {
  '/shifts/calendar': shiftsCalendarPageConfig,
  '/shifts/list': shiftsListPageConfig,
};

export const ShiftsPageProvider: FC = ({ children }) => {
  const location = useLocation();
  const activeTab = location.pathname as ShiftsPageRouteType;
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );
  const { locationFilter } = useAuthRouteDataContext();
  const [values, opts] = useSSFBehaviors(
    shiftsPageConfigMap[activeTab]({
      location: locationFilter.initialValue,
      isUserAgencyAndAgencyLocationFilterForcedDisabled,
    }),
  );
  const [selectedDay, setSelectedDay] = useState<ISO8601Type | null>(null);
  let numFiltersApplied = 0;
  if (values.employmentStatus) numFiltersApplied += values.employmentStatus.length;
  if (values.shiftStatus) numFiltersApplied += values.shiftStatus.length;
  if (values.position_id) numFiltersApplied += values.position_id.length;
  if (values.internalJaneType) numFiltersApplied += values.internalJaneType.length;
  if (values.externalJaneType) numFiltersApplied += values.externalJaneType.length;
  if (values.timeFrameType) numFiltersApplied += values.timeFrameType.length;
  if (values.actionsRequiredType) numFiltersApplied += values.actionsRequiredType.length;

  const isOutsideOfMonth = useCallback(
    (date: string) => {
      const { filterDate } = values;
      const diff = DateTime.fromISO(filterDate[1]).diff(
        DateTime.fromISO(filterDate[0]),
        'days',
      ).days;
      const mid = DateTime.fromISO(filterDate[0]).plus({ days: diff / 2 });
      return !DateTime.fromISO(date).hasSame(mid, 'month');
    },
    [values],
  );

  const value = {
    values,
    numFiltersApplied,
    Consumer: ShiftsPageContext.Consumer,
    Provider: ShiftsPageContext.Provider,
    isOutsideOfMonth,
    selectedDay,
    setSelectedDay,
    ...opts,
  };

  return (
    <ShiftsPageContext.Provider value={value}>
      <SSFBarsProvider>{children}</SSFBarsProvider>
    </ShiftsPageContext.Provider>
  );
};

export const useShiftsPageContext = (): UseShiftsPageContextReturnType =>
  useContext(ShiftsPageContext);
