import { QuerySwitch } from 'components/QuerySwitch';
import { AgencyRequestsComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests/AgencyRequests.component';
import {
  FetchAgenciesByAgencyIdsDataContext,
  FetchAgenciesByAgencyIdsDataProvider,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests/FetchAgenciesByAgencyIds.data.context';
import { FC } from 'react';

export const AgencyRequests: FC = () => (
  <FetchAgenciesByAgencyIdsDataProvider>
    <QuerySwitch
      component={AgencyRequestsComponent}
      context={FetchAgenciesByAgencyIdsDataContext}
    />
  </FetchAgenciesByAgencyIdsDataProvider>
);
