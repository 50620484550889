import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_timeOffModalFetchTimeOffById } from 'queries';
import { timeOffModalFetchTimeOffById } from 'types/graphql-types';

export function fetchTimeOffQueryConfig(
  timeOffId: string,
): UseGQLQueryConfig<timeOffModalFetchTimeOffById> {
  return {
    key: 'TIME_OFF_MODAL_FETCH_TIME_OFF_BY_ID',
    query: QUERY_timeOffModalFetchTimeOffById,
    variables: {
      timeOffId,
    },
  };
}
