import { BKJButton } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const ScheduleRulesWrapper = styled.div`
  padding: 32px 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  align-items: 'flex-start';
  width: 100%;
`;

export const TableRowCell = styled.div<{ isGrey?: boolean }>`
  word-break: break-word;
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
  width: 100%;
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0 12px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TableRowCellContent = styled.div`
  padding-left: 26px;
  flex-direction: row;
  display: flex;
`;

export const ViewDetailsLink = styled.div`
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  cursor: pointer;
  line-height: 24px;
  font-weight: 600;
  text-decoration: underline;
  padding-right: 10px;
`;

export const TableRowCellButton = styled(BKJButton)`
  width: fit-content;
  height: fit-content;
  min-height: fit-content;
  max-height: fit-content;
  padding-left: 18px;
  font-size: 16px;
  cursor: pointer;
`;

export const ScheduleRuleInformationSection = styled.div`
  color: ${({ theme }) => theme.colors.Grey6};
  font-size: 14px;
`;

export const TableRowFirstCell = styled(TableRowCell)`
  border-top: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TableHeader = styled.div`
  padding-bottom: 20px;
`;
export const LocationHeader = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
`;
export const LocationName = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
export const DownloadHeader = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
export const UploadHeader = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const TeamMemberCSVWrapper = styled.div``;
export const H3 = styled.div`
  ${({ theme }) => theme.fonts.Poppins};
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  word-break: break-all;
`;

export const CSVBorderHeaderStyle = 'solid 1px #8b96a350';
