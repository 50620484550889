import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const RateShiftViewWrapper = styled(Flex)`
  padding: 32px;
  height: 100%;
`;

export const RateShiftTitle = styled.h3`
  ${({ theme }) => theme.typography.H3};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 16px;
`;
