import { GROUPS } from 'constants/endpoints';
import { METHOD_DELETE } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_DELETE_group = (groupId: number): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: `${GROUPS}/${groupId}`,
    method: METHOD_DELETE,
  };
};
