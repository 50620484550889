import { Fragment, FC } from 'react';
import i18next from 'i18next';
import { ShiftModalViewType } from 'components/ShiftModal/ShiftModal.constants';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { formatDate } from 'format/formatDate';

export function DefaultShiftModalHeaderComponent() {
  const { data, isLoading } = useShiftModalDataContext();
  if (isLoading) return <Fragment />;
  return (
    <Fragment>{`${i18next.t('shift_modal:SHIFT_NUMBER')}${
      data?.fetchOrder?.orderSchedule?.id
    }`}</Fragment>
  );
}

export function DeleteShiftModalHeaderComponent() {
  const { data, isLoading } = useShiftModalDataContext();
  if (isLoading) return <Fragment />;
  return (
    <Fragment>{`${i18next.t('shift_modal:DELETE_SHIFT')} #${
      data?.fetchOrder?.orderSchedule?.id
    }`}</Fragment>
  );
}

export function AuditTrailHeaderComponent() {
  const { data, isLoading } = useShiftModalDataContext();
  const startDate = data?.fetchOrder?.startDate;
  const date = formatDate(startDate, 'LLL d, yyyy');

  if (isLoading) {
    return <Fragment />;
  } else {
    return <Fragment>{`${i18next.t('shift_modal:AUDIT_TRAIL')} - ${date}`}</Fragment>;
  }
}

export const ShiftModalHeaderMap: Record<
  ShiftModalViewType,
  | string
  | FC<{
      closeModal: () => void;
    }>
  | (() => JSX.Element)
> = {
  AssignToAgencyTeamMemberView: () => <>{i18next.t('shift_modal:ASSIGN_SHIFT')}</>,
  AssignToAgencyTeamMemberSummaryView: () => <>{i18next.t('shift_modal:ASSIGN_SHIFT')}</>,
  TeamMemberUnavailableView: () => <>{i18next.t('shift_modal:ASSIGN_SHIFT')}</>,
  ShiftUnavailableView: () => <>{i18next.t('shift_modal:ASSIGN_SHIFT')}</>,
  DetailView: DefaultShiftModalHeaderComponent,
  AuditTrailView: AuditTrailHeaderComponent,
  EditView: DefaultShiftModalHeaderComponent,
  DeleteView: DeleteShiftModalHeaderComponent,
  RateShiftView: DefaultShiftModalHeaderComponent,
  ReleaseShiftView: () => <>{i18next.t('shift_modal:RELEASE_TEAM_MEMBER')}</>,
  SendToAgencyConfirmationView: () => <>{i18next.t('shift_modal:SEND_TO_AGENCIES')}</>,
  PreRequestOptionsView: () => <>{i18next.t('shift_modal:SEND_TO_AGENCIES')}</>,
  SendToAgenciesSummaryView: () => <>{i18next.t('shift_modal:SEND_TO_AGENCIES')}</>,
  RequestTeamMemberView: () => <>{i18next.t('shift_modal:SEND_TO_AGENCIES')}</>,
  PostRequestOptionsView: () => <>{i18next.t('shift_modal:SEND_TO_AGENCIES')}</>,
  SelectAgenciesView: () => <>{i18next.t('shift_modal:SEND_TO_AGENCIES')}</>,
  NotifyTeamMember: DefaultShiftModalHeaderComponent,
  AwardTeamMemberView: () => <>{i18next.t('shift_modal:AWARD_SHIFT')}</>,
  AwardUnavailableView: () => <>{i18next.t('shift_modal:AWARD_SHIFT')}</>,
  ReasonForEdits: DefaultShiftModalHeaderComponent,
  EditingReasonAndNotifyView: DefaultShiftModalHeaderComponent,
  ConfirmEditsView: DefaultShiftModalHeaderComponent,
};
