import { Context } from '@apollo/client';
import {
  ChangeEventType,
  ChangeFunctionType,
} from '@bookjane2/bookjane-design-library/lib/common.types';
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { UserGuardType, UserPermissionEnum } from 'guards/UserGuard.types';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { isValidElement, FormEvent, RefObject, Provider, Consumer, Key } from 'react';
import { ApiRequestConfigType } from 'requests/index.types';
import {
  AgencyLoginResponseUserObject,
  B2BLoginResponseUserObject,
} from 'store/reducers/auth/authReducer.types';
import { OrderStatusEnum } from 'types/graphql-types';

export type BKJInputValueType = string | number | boolean | undefined;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isString(value?: any): value is string {
  return typeof value === 'string';
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArray(value?: any): value is any[] {
  return Array.isArray(value);
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBoolean(value?: any): value is boolean {
  return typeof value === 'boolean';
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isReactComponent(value?: any): value is boolean {
  const Component = value;
  if (typeof Component === 'function') return isValidElement(<Component />);
  else return false;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isReactElement(value?: any): value is boolean {
  return isValidElement(value);
}

export function isOrderStatusInProgress(
  status: keyof typeof OrderStatusEnum,
): status is 'in_progress' {
  return status === 'in_progress';
}

export function isOrderStatusUnfulfilled(
  status: keyof typeof OrderStatusEnum,
): status is 'unfulfilled' {
  return status === 'unfulfilled';
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUserGuardType(userType: any): userType is UserGuardType {
  return Object.values(UserPermissionEnum).includes(userType);
}

export function isBKJChangeEventType(event: ChangeEventType): event is ChangeEventType {
  if (
    typeof event !== 'undefined' &&
    typeof event.target !== 'undefined' &&
    typeof event.target.name !== 'undefined' &&
    typeof event.target.value !== 'undefined'
  )
    return true;
  return false;
}

export type J360ApiEndpointURL = string;

export type UIElementIdType =
  | 'modal-root'
  | 'main-menu-wrapper'
  | 'dropdown-root'
  | 'SettingsNavigationWrapper';

export type BKJAuthTokenType = string;

export const ISO8601Regex =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

export type ISO8601Type = string;

export type ISO8601TupleType = [i0: ISO8601Type, i1: ISO8601Type];

export type DateStringType = string;

export function isISO8601Type(date: unknown): date is ISO8601Type {
  return isString(date) && ISO8601Regex.test(date);
}

export function isISO8601Tuple(tuple: unknown): tuple is ISO8601TupleType {
  if (!Array.isArray(tuple)) return false;
  return tuple.length === 2 && tuple.every(isISO8601Type);
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isGQLContextType(context: any): context is GQLAPIContextType {
  return (
    context?.__typename === 'GraphQLAPI' || context?._currentValue?.__typename === 'GraphQLAPI'
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLegacyAPIType(requestOptions: any): requestOptions is ApiRequestConfigType {
  return (
    requestOptions?.__typename === 'LegacyAPI' ||
    requestOptions?._currentValue?.__typename === 'LegacyAPI'
  );
}

export type ShiftDateTimeRangeType = 'ddd, MMM DD at hh:mm a - hh:mm a' & DateStringType;
export type ShiftDateTimeType = 'MMM DD, YYYY at hh:mm a' & DateStringType;

export type ShiftDateType = 'MMM DD, YYYY' & DateStringType;

export type ShiftQuantityType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SSFFieldValueType = string | number | any;

export type SSFBehaviorsValueType = {
  [key: string]: SSFFieldValueType;
};

export type FeaturePermissionLevelType = 'read' & string;

export type RenderingStateType = 'LOADING' | 'ERROR' | 'READY';

export type ComponentMapType = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (ref?: any, opts?: any) => JSX.Element | null;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pageContextInitialState: GQLAPIContextType & any = {
  data: null,
  onChange: () => null,
  isLoading: true,
  onReset: () => null,
  fetch: () => null,
  loadMore: () => null,
  firstPage: () => null,
  nextPage: () => null,
  prevPage: () => null,
  lastPage: () => null,
  status: 'LOADING',
  values: {},
  numFiltersApplied: 0,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Provider: {} as any,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Consumer: {} as any,
  __typename: 'GraphQLAPI',
};

export type APIRequestContextType = {
  data: AxiosResponse['data'];
  error: AxiosError;
  isError: boolean;
  isLoading: boolean;
  isPending: boolean;
  method: Method;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: Record<string, any>;
  request_id: string;
  status: RenderingStateType;
  statusText: AxiosResponse['statusText'];
  statusCode: AxiosResponse['status'];
  timestamp_utc: string;
  url: AxiosRequestConfig['url'];
  __typename: 'LegacyAPISlice';
};

export interface GQLAPIContextType<DataType = any>
  extends Omit<UseGQLQuery<DataType>, 'fetch' | 'query'>,
    Context {
  key: string;
  data: DataType;
  onChange: ChangeFunctionType;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Provider: Provider<any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Consumer: Consumer<any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch: (arg?: any) => void;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>;
  status: RenderingStateType;
  __typename: 'GraphQLAPI';
  onReset: Function;
  loadMore: Function;
}

export type LegacyAPIContextType<T = unknown> = {
  data: AxiosResponse<T>['data'];
  onChange?: ChangeFunctionType;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Provider: Provider<any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Consumer: Consumer<any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Record<string, any>;
  status: RenderingStateType;
  __typename: 'LegacyAPI';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type RefType =
  | ((instance: HTMLDivElement | null) => void)
  | RefObject<HTMLDivElement>
  | null
  | undefined;

export type DataServiceType = 'LegacyAPI' | 'GraphQLAPI';
export enum ShiftStatusTextToColorEnum {
  Open = 'ShiftOpen',
  Requested = 'ShiftRequested',
  Assigned = 'ShiftAssigned',
  Accepted = 'ShiftAccepted',
  'In Progress' = 'ShiftInProgress',
  Completed = 'ShiftCompleted',
  Cancelled = 'ShiftCancelled',
  Unfulfilled = 'ShiftUnfulfilledStroke',
  Swapped = 'ShiftSwapped',
  Absent = 'ShiftAbsent',
}
export type ShiftStatusTextToColorEnumKey = keyof typeof ShiftStatusTextToColorEnum;

export type FieldNameType = string;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldValueType = any;

export interface IFieldErrors {
  [key: string]: FormValidationErrorMessageType;
}

export type FormValuesType = Record<FieldNameType, FieldValueType>;

export type FormValidationArgsType = {
  formValues: FormValuesType;
  dirtyFields?: FieldNameType[];
};

export type FormValidationErrorMessageType = string | string[] | undefined;

export type FormValidationsType = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: ({ formValues, value, dirtyFields }: FormValidationArgsType & { value: any }) => {
    valFieldErrors?: FormValidationErrorMessageType;
    valFormErrors?: FormValidationErrorMessageType;
  };
};

export type EventTarget = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label?: string;
  checked?: boolean;
  dataset?: {
    [key: string]: string;
  };
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any> &
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any;

export type FormBehaviorsSubmitEvent = FormEvent<HTMLButtonElement>;

export type FieldValuesType = Record<string, {} | null>;
export type DropdownOptionType = {
  name: string;
  key: Key;
  label: string;
  value: string;
};

const LowerCamelCaseRegex = /\b([a-z]+[0-9]*)([A-Z][a-z0-9]+)+\b/;

export type LowerCamelCaseType = string;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLowerCamelCaseType(arg: any): arg is LowerCamelCaseType {
  if (!isString(arg)) return false;
  return LowerCamelCaseRegex.test(arg);
}

const SnakeCaseRegex = /(?:^[a-z])(?:\w)*(?:[a-z0-9]$)/;

export type SnakeCaseType = string;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSnakeCaseType(arg: any): arg is SnakeCaseType {
  if (!isString(arg)) return false;
  return SnakeCaseRegex.test(arg);
}

export type InputNameType = LowerCamelCaseType | SnakeCaseType;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isInputNameType(arg: any): arg is InputNameType {
  if (!isString(arg)) return false;
  return isSnakeCaseType(arg) || isLowerCamelCaseType(arg);
}

export interface QueryPayloadPaginationVariables {
  first: number;
  last: number;
  before: string;
  after: string;
  currentPage: number;
}

export type PaginationVariables<T extends {}> = T & QueryPayloadPaginationVariables;

export type JaneTypeInternalAndExternal =
  | 'internal_full_time'
  | 'internal_part_time'
  | 'internal_casual'
  | 'agency_marketplace'
  | 'shared'
  | 'internal'
  | 'flex';

export type SSFPayloadTransformer<Variables extends {}, Payload extends {}> = (
  variables: PaginationVariables<Variables>,
) => Payload;

export function isUserObjectB2bType(
  user: Nullable<AgencyLoginResponseUserObject | B2BLoginResponseUserObject>,
): user is B2BLoginResponseUserObject {
  let __USER__ = user as unknown as Record<string, unknown>;
  if (!user) return false;
  if (!__USER__.user_role) return false;
  return __USER__.user_role === 'essential' || __USER__.user_role === 'premium';
}
export function isUserObjectAgencyType(
  user: Nullable<AgencyLoginResponseUserObject | B2BLoginResponseUserObject>,
): user is AgencyLoginResponseUserObject {
  if (!user) return false;
  return user.type === 'AgencyManager' || user.type === 'AgencyOwner';
}
