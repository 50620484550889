export const sessionPersistenceService = {
  setItem: (key: string, value: string): void => {
    window.sessionStorage.setItem(key, value);
    return void 0;
  },

  getItem: (key: string): string | undefined => {
    return window.sessionStorage.getItem(key) || undefined;
  },

  removeItem: (key: string): void => {
    window.sessionStorage.removeItem(key);
    return void 0;
  },
};
