import { KeyOption, matchSorter } from 'match-sorter';

export function filterStrictMatch<ItemType extends {}>(
  inputValue: string,
  options: ItemType[],
  ...args: ReadonlyArray<KeyOption<ItemType>>
) {
  return matchSorter(options, inputValue, {
    threshold: matchSorter.rankings.CONTAINS,
    keys: args,
  });
}
