import React, { FC } from 'react';
import { BKJLoadingSpinner } from '@bookjane2/bookjane-design-library';
import { MountTransition } from 'routes/MountTransition';
import styled from 'styled-components';

const LoadingSpinnerOuterWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
`;

const GlobalLoading: FC = () => {
  return (
    <LoadingSpinnerOuterWrapper>
      <MountTransition>
        <BKJLoadingSpinner variant="Default" />
      </MountTransition>
    </LoadingSpinnerOuterWrapper>
  );
};

export default GlobalLoading;
