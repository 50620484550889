import { useSSFBehaviors } from 'hooks';
import { ICustomField } from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettings.types';
import { fetchCustomFieldsQueryConfig } from 'pages/SettingsPage/CustomFieldsSettingsPage/contexts/DataContext.constants';
import { FC, useContext } from 'react';
import { GQLAPIContextType } from 'types/common.types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchCustomFieldsDataContext = createGQLDataContext<ICustomField[]>([]);

export const FetchCustomFieldsDataProvider: FC = ({ children }) => {
  const [, useQueryOptions] = useSSFBehaviors(fetchCustomFieldsQueryConfig());

  return (
    <FetchCustomFieldsDataContext.Provider value={{ ...useQueryOptions }}>
      {children}
    </FetchCustomFieldsDataContext.Provider>
  );
};

export const useFetchCustomFieldsDataContext = (): Partial<GQLAPIContextType<ICustomField[]>> =>
  useContext(FetchCustomFieldsDataContext);
