import styled, { css } from 'styled-components';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RadioItemWrapper = styled.label<any>`
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.typography.Body}
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  span span {
    color: #8b96a3;
  }

  svg {
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: #aeb8c5;
      span span {
        color: #aeb8c5;
      }
    `};
`;

export const HiddenRadioInput = styled.input`
  position: absolute;
  visibility: hidden;
`;
interface IRadioWrapperProps {
  $maxWidth?: string;
  $width?: string;
  $minWidth?: string;
}

export const RadioWrapper = styled.div<IRadioWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  ${({ $width }) => {
    if ($width)
      return css`
        width: ${$width};
      `;
  }};
  ${({ $minWidth }) => {
    if ($minWidth)
      return css`
        min-width: ${$minWidth};
      `;
  }};
  ${({ $maxWidth }) => {
    if ($maxWidth)
      return css`
        max-width: ${$maxWidth};
      `;
  }};
`;

export const RadioText = styled.div`
  font-family: 'Poppins Medium';
  font-size: 14px;
  padding-left: 12px;
  text-align: left;
  pointer-events: none;
`;
