import {
  ShiftModalUIContext,
  shiftModalRoutingService,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { defaultApolloClient } from 'providers/ApolloProvider';
import { createContext, useContext, useCallback, useMemo } from 'react';
import { API_POST_releasePublishedShifts } from 'requests/POST_releaseShift';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

export type ReleaseType = {
  reason_category: string;
  reason_message: string;
  recurring: string;
};

export const ReleaseShiftViewContext = createContext({} as UseFormBehaviors<ReleaseType>);

export const ReleaseShiftViewProvider: React.FC = ({ children }) => {
  const { apiRequest } = useAPIActions();

  const { shift_id } = useContext(ShiftModalUIContext);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values) => {
      await apiRequest(API_POST_releasePublishedShifts({ ...values, shift_id }), {
        onSuccess: () => {
          toastService.success(t('success:SHIFT_RELEASED'));
          defaultApolloClient.refetchQueries({ include: 'all' });
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${shift_id}`);
          window.scheduleUtils.forceDataUpdate();
          shiftModalRoutingService.close();
        },
        onError: (e) =>
          handleGenericErrorMessage(e, t('errors:SOMETHING_WENT_WRONG_RELEASING_SHIFT')),
      });
    },
    [apiRequest, shift_id, t],
  );

  const formUtils = useFormBehaviors<ReleaseType>({
    initialState: {
      reason_category: '',
      reason_message: '',
      recurring: '',
    },
    validations: {},
    onSubmit: handleSubmit,
    isDirtyCheckEnabled: true,
  });

  const handleChange = useCallback(
    (event) => {
      const newValue = { ...event };
      if (event.target.name === 'reason_category') newValue.target.value = newValue.target.value[0];
      formUtils.onChange(newValue);
    },
    [formUtils],
  );

  const isDisabled =
    formUtils.values.reason_category === '' ||
    (formUtils.values.reason_category === 'other' && formUtils.values.reason_message === '');

  const value = useMemo(() => {
    return {
      ...formUtils,
      onChange: handleChange,
      isDisabled,
    };
  }, [handleChange, formUtils, isDisabled]);

  return (
    <ReleaseShiftViewContext.Provider value={value as UseFormBehaviors<ReleaseType>}>
      {children}
    </ReleaseShiftViewContext.Provider>
  );
};

export const useReleaseShiftViewContext = (): UseFormBehaviors<ReleaseType> =>
  useContext(ReleaseShiftViewContext) as UseFormBehaviors<ReleaseType>;
