import { BKJButton, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import {
  SendToAgencyConfirmationViewBody,
  SendToAgencyConfirmationViewContainer,
  SendToAgencyConfirmationViewTitle,
} from 'components/ShiftModal/src/views/SendToAgencyConfirmationView/SendToAgencyConfirmationView.styled';
import { useTranslation } from 'hooks/useTranslation';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const SendToAgencyConfirmationView: FC = () => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary
      componentName="ReasonsForEditsView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <SendToAgencyConfirmationViewContainer flexDirection="column" flex="1" padding="40px 32px">
        <SendToAgencyConfirmationViewTitle>
          {t('shift_modal:SEND_TO_AGENCIES_CONFIRMATION_TITLE')}
        </SendToAgencyConfirmationViewTitle>
        <SendToAgencyConfirmationViewBody>
          {t('shift_modal:SEND_TO_AGENCIES_CONFIRMATION_BODY')}
        </SendToAgencyConfirmationViewBody>
      </SendToAgencyConfirmationViewContainer>
      <BKJSideModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <Flex width="100%" justifyContent="flex-end" gap="4px ">
            <BKJButton
              variant="PurpleOutlined"
              onClick={() => shiftModalRoutingService.close()}
              type="button"
              width="180px"
            >
              {t('auth:CANCEL')}
            </BKJButton>
            <BKJButton
              width="180px"
              variant="PurpleSolid"
              onClick={() => shiftModalRoutingService.goToView('PreRequestOptionsView', {})}
            >
              {t('shift_modal:CONTINUE')}
            </BKJButton>
          </Flex>
        </Flex>
      </BKJSideModalFooter>
    </ErrorBoundary>
  );
};
