import { BKJLoadingSpinner } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';

export const AdministratorSettingsLoadingSpinner = () => {
  return (
    <Flex height="150px">
      <BKJLoadingSpinner variant="Default" />
    </Flex>
  );
};
