import {
  EmptyBody,
  EmptyHeader,
  EmptyMessage,
  EmptyViewFlex,
} from './AgencyMemberEmptyView.styled';

export function AgencyMemberEmptyView() {
  return (
    <EmptyViewFlex width="100%" height="100%" justifyContent="center" alignItems="center">
      <EmptyMessage>
        <EmptyHeader>Send shifts to agencies to view agency members here</EmptyHeader>
        <EmptyBody>
          Ageny staff that have completed or accepted your shifts in the last month will appear
          here.
        </EmptyBody>
      </EmptyMessage>
    </EmptyViewFlex>
  );
}
