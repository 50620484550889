import i18next from 'i18next';
import { DateTime } from 'luxon';
import { getShiftStartTimeAndEndTime } from 'pages/CreateShiftsPage/CreateShiftsPage.utils';

export const prettyShiftTime = (
  startDate: string,
  startAt: string,
  endAt: string,
  locale: string,
): string => {
  const [startTime, endTime] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
  });

  const startDateTime = DateTime.fromISO(startDate);

  const dayName = startDateTime.toFormat('EEE');
  const translatedDayName = i18next.t(`translations:${dayName.toUpperCase()}`);

  const monthName = startDateTime.toFormat('MMM');
  const translatedMonthName = i18next.t(`translations:${monthName.toUpperCase()}`);

  const startStr = DateTime.fromISO(startTime).setLocale(locale).toFormat('h:mm a').toLowerCase();
  const endStr = DateTime.fromISO(endTime).setLocale(locale).toFormat('h:mm a').toLowerCase();

  return `${translatedDayName}, ${translatedMonthName} ${startDateTime.toFormat(
    'dd',
  )} • ${startStr} - ${endStr}`;
};
