import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useSSFBehaviors } from 'hooks';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { requestTeamConfig } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RequestTeamView/RequestTeamView.constants';
import { CreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';

import { FC, MouseEventHandler, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import { FieldNameType, GQLAPIContextType } from 'types/common.types';
import { requestTeamMemberView } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { getShiftStartTimeAndEndTime } from 'utils/time';

export const RequestTeamsContext = createGQLDataContext({});

export const RequestTeamProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const isUserPremium = useSelector(isUserPremiumSelector);
  const { saveAndCloseModal, createShift } = useContext(CreateShiftCardContext);
  const {
    groupedFormsBehaviors: {
      values: { location },
    },
    locationName,
  } = useContext(CreateShiftsPageContext);

  const {
    values: {
      position_id,
      dedicated = false,
      allow_overtime = false,
      request_janes = '',
      quantity,
      startAt,
      endAt,
      startDate,
      recurring_expiration_date,
      recurring_frequency,
      recurring_interval,
    },
  } = createShift;

  const [start_time, end_time] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
    startDate,
  });

  const config: ISSFBehaviorsOptions = requestTeamConfig({
    byStartTime: start_time,
    byEndTime: end_time,
    facilityId: Number(location.value),
    isDedicated: dedicated,
    positionId: Number.parseInt(position_id[0]),
    allowOvertime: allow_overtime,
    recurring: recurring_frequency[0]
      ? {
          startTime: start_time,
          endTime: end_time,
          frequency: recurring_frequency[0],
          interval: Number.parseFloat(recurring_interval[0]),
          expirationDate: recurring_expiration_date ? recurring_expiration_date : undefined,
        }
      : undefined,
  });

  const [values, { ...rest }] = useSSFBehaviors(config);

  const [modalValues, setModalValues] = useState({
    request_janes: request_janes,
    estimatedTotalPayout: 0,
  });

  const [dirtyFields, setDirtyFields] = useState<FieldNameType[]>([]);
  const selectedJanes = modalValues.request_janes!.split(',').filter((id) => id !== '');

  const handleChange = useCallback(
    (e: { target: { name: string; value: string } }) => {
      const {
        target: { value: request_janes },
      } = e;

      const _modalValues = { ...modalValues };
      setDirtyFields([...dirtyFields].concat(['request_janes']));

      if (quantity[0] === '1') {
        _modalValues['request_janes'] = request_janes;
      } else {
        let updatedSelectedJanes;
        if (selectedJanes.includes(request_janes)) {
          updatedSelectedJanes = selectedJanes.filter((id) => id !== request_janes);
        } else {
          updatedSelectedJanes = [...selectedJanes, request_janes];
        }

        _modalValues['request_janes'] = updatedSelectedJanes.join(',');
      }
      setModalValues(_modalValues);
    },
    [dirtyFields, modalValues, quantity, selectedJanes],
  );

  const handleSaveAndClose = (): void => {
    saveAndCloseModal([
      {
        target: {
          name: 'request_janes',
          value: modalValues.request_janes,
        },
      },
      {
        target: {
          name: 'estimatedTotalPayout',
          value: modalValues.estimatedTotalPayout,
        },
      },
    ]);
  };

  return (
    <RequestTeamsContext.Provider
      value={{
        values,
        handleChange,
        handleSaveAndClose,
        selectedJanes,
        locationName,
        data: rest.data,
        actionableTitle: isUserPremium
          ? t('create_shifts:REQUEST_TEAM_MEMBER')
          : t('create_shifts:REQUEST_AGENCY_MEMBER'),
        ...rest,
      }}
    >
      {children}
    </RequestTeamsContext.Provider>
  );
};

export const useRequestTeamViewContext = (): GQLAPIContextType<requestTeamMemberView> & {
  handleChange: ChangeFunctionType;
  handleSaveAndClose: MouseEventHandler<HTMLButtonElement>;
  selectedJanes: string[];
  locationName: string;
  onReset: MouseEventHandler<HTMLDivElement>;
  data: requestTeamMemberView;
  actionableTitle: string;
} => useContext(RequestTeamsContext);
