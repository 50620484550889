import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { AUTH_LOGIN } from 'constants/endpoints';
import { LoginFormSchema } from 'pages/LoginPage/LoginPage';

export const API_POST_signIn = (params: LoginFormSchema): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url: AUTH_LOGIN,
    body: {
      username: params.username,
      password: params.password,
    },
  };
};
