import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const TotalSummaryFooterWrapper = styled.div`
  width: calc(100vw - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  max-height: 65px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0 60px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    padding: 0px 15px;
    width: 100%;
  }
`;

// temporarily added css property for petal demo
export const TotalSummaryFooterContentWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  max-width: max-content;
  gap: 10px;
  margin-right: auto;
  ${({ theme }) => theme.common.Ellipsis};
`;

export const TotalSummaryFooterTitleWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) =>
    WhiteLabelAccessor.isXtmProvider ? theme.colors.AccentPurple : theme.colors.AccentBrand};
  ${({ theme }) => theme.common.Ellipsis};
`;

export const TotalSummaryFooterEntryWrapper = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  justify-content: center;
  ${({ theme }) => theme.fonts.PoppinsText};
  ${({ theme }) => theme.common.Ellipsis};
`;

export const TotalSummaryFooterText = styled.span`
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  ${({ theme }) => theme.common.Ellipsis};
`;

export const ColorDot = styled.span<{ color: BKJThemeColorType }>`
  font-size: 2rem;
  height: 18px;
  display: flex;
  max-height: 18px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, color }) => theme.colors[color]};
`;
