import { createContext } from 'react';
import { GQLAPIContextType, LegacyAPIContextType } from 'types/common.types';

export function createGQLDataContext<T = { [key: string]: unknown }>(
  initialState?: T,
): GQLAPIContextType<T> {
  return createContext({
    ...(initialState || {}),
    status: 'LOADING',
    __typename: 'GraphQLAPI',
  }) as GQLAPIContextType<T>;
}

export function createLegacyDataContext<T = { [key: string]: unknown }>(
  initialState?: T,
): LegacyAPIContextType<T> {
  return createContext({
    ...initialState,
    status: 'LOADING',
    __typename: 'LegacyAPI',
  }) as unknown as LegacyAPIContextType<T>;
}
