import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloClient, createApolloCache } from 'providers/ApolloProvider';
import {
  QUERY_requestTeamMembers,
  QUERY_requestTeamMembersRecurring,
  QUERY_sendToAgenciesPositionMinMax,
  QUERY_sendToAgenciesSummaryView,
} from 'queries';
import { RecurringOrderAttributes, sendToAgenciesPositionMinMax } from 'types/graphql-types';
import { castStringToInteger } from 'utils';

interface IRequestTeamMemberConfig {
  facilityId: number;
  isDedicated: boolean;
  orderId: number;
  positionId: number;
  communityId: number;
  recurring?: RecurringOrderAttributes;
  byStartTime: string;
  byEndTime: string;
}

interface IRequestTeamMemberSchema {
  byAgency: number[];
  byFullName: string;
  byRateLessThanOrEqualTo: number[];
  orderId: number;
  isDedicated: boolean;
  positionId: number;
  byFacilityId: number;
  communityId?: number;
  recurring?: RecurringOrderAttributes;
}

const requestTeamMemberApolloClient = createApolloClient({ cache: createApolloCache() });

export const requestTeamMemberConfig = ({
  facilityId,
  isDedicated,
  orderId,
  positionId,
  communityId,
  recurring,
  byStartTime,
  byEndTime,
}: IRequestTeamMemberConfig): ISSFBehaviorsOptions => ({
  debounceTimeout: 500,
  key: 'SEND_TO_AGENCIES_REQUEST_TEAM_MEMBER_VIEW_QUERY',
  query: recurring ? QUERY_requestTeamMembersRecurring : QUERY_requestTeamMembers,
  paginationType: 'InfiniteScroll',
  client: requestTeamMemberApolloClient,
  payloadTransformer: (variables: IRequestTeamMemberSchema) => {
    return {
      ...variables,
      orderId: variables.orderId,
      byFacilityId: variables.byFacilityId,
      positionId: variables.positionId,
      recurring: recurring,
      filter: {
        byRateLessThanOrEqualTo: variables.byRateLessThanOrEqualTo[0],
        byFullName: variables.byFullName,
        byAgency: variables.byAgency,
        belongingToTeam: variables.isDedicated
          ? {
              forPositions: [variables.positionId],
              inCommunities: [variables.communityId],
            }
          : undefined,
      },
    };
  },
  pageSize: 18,
  variables: {},
  schema: {
    byAgency: {
      initialValue: [],
    },
    byFullName: {
      initialValue: '',
    },
    byRateLessThanOrEqualTo: {
      initialValue: [],
      mapper: (value: string[]) => value.map((str: string) => castStringToInteger(str)),
    },
    orderId: {
      initialValue: orderId,
    },
    isDedicated: {
      initialValue: isDedicated,
    },
    positionId: {
      initialValue: positionId,
    },
    facilityId: {
      initialValue: facilityId,
    },
    communityId: {
      initialValue: communityId,
    },
    includeRecurring: {
      initialValue: !!recurring,
    },
    byStartTime: {
      initialValue: byStartTime,
    },
    byEndTime: {
      initialValue: byEndTime,
    },
  },
});

export const requestTeamMemberCountConfig = ({
  facilityId,
  isDedicated,
  orderId,
  positionId,
  communityId,
  byStartTime,
  byEndTime,
}: IRequestTeamMemberConfig): ISSFBehaviorsOptions => ({
  debounceTimeout: 500,
  key: 'SEND_TO_AGENCIES_REQUEST_TEAM_MEMBER_VIEW_QUERY',
  query: QUERY_requestTeamMembers,
  paginationType: 'InfiniteScroll',
  client: requestTeamMemberApolloClient,
  payloadTransformer: (variables: IRequestTeamMemberSchema) => {
    return {
      ...variables,
      orderId: variables.orderId,
      byFacilityId: variables.byFacilityId,
      positionId: variables.positionId,
      filter: {
        byRateLessThanOrEqualTo: variables.byRateLessThanOrEqualTo[0],
        byFullName: variables.byFullName,
        byAgency: variables.byAgency,
        belongingToTeam: variables.isDedicated
          ? {
              forPositions: [variables.positionId],
              inCommunities: [variables.communityId],
            }
          : undefined,
      },
    };
  },
  pageSize: 18,
  variables: {},
  schema: {
    orderId: {
      initialValue: orderId,
    },
    isDedicated: {
      initialValue: isDedicated,
    },
    positionId: {
      initialValue: positionId,
    },
    facilityId: {
      initialValue: facilityId,
    },
    communityId: {
      initialValue: communityId,
    },
    byStartTime: {
      initialValue: byStartTime,
    },
    byEndTime: {
      initialValue: byEndTime,
    },
  },
});

export const priceConfig = ({
  positionId,
}: {
  positionId: number;
}): UseGQLQueryConfig<sendToAgenciesPositionMinMax, { min: string; max: string }> => ({
  key: 'SEND_TO_AGENCIES_REQUEST_TEAM_MEMBER_PRICE_QUERY',
  query: QUERY_sendToAgenciesPositionMinMax,
  variables: {
    positionId: Number(positionId),
  },
  responseTransformer: (data) => {
    const openPriceRange = data?.fetchPosition?.openPriceRange;
    return openPriceRange
      ? { min: openPriceRange?.min, max: openPriceRange?.max }
      : { min: '', max: '' };
  },
});

export const fetchJaneByIdConfig = ({
  janeId,
  positionId,
}: {
  janeId: string;
  positionId: number;
}) => ({
  key: 'SEND_TO_AGENCIES_FETCH_JANE_BY_ID_QUERY',
  query: QUERY_sendToAgenciesSummaryView,
  schema: {
    jane_id: {
      initialValue: janeId,
    },
    positionId: {
      initialValue: positionId,
    },
  },
});
