import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { fetchDepartmentsQueryConfig } from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/B2BPositionsSettingsPage.constants';
import { IFetchDepartmentsData } from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/B2BPositionsSettingsPage.types';
import { FC, useContext, useEffect } from 'react';
import { b2bDepartmentsNavigationFetchDepartments } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchDepartmentsDataContext = createGQLDataContext<UseGQLQuery<IFetchDepartmentsData>>(
  {} as UseGQLQuery<IFetchDepartmentsData>,
);

export const FetchDepartmentsDataProvider: FC = ({ children }) => {
  const { fetch, ...rest } = useGQLQuery<
    b2bDepartmentsNavigationFetchDepartments,
    IFetchDepartmentsData,
    {}
  >(fetchDepartmentsQueryConfig);

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <FetchDepartmentsDataContext.Provider value={rest}>
      {children}
    </FetchDepartmentsDataContext.Provider>
  );
};

export const useFetchDepartmentsDataContext = (): UseGQLQuery<IFetchDepartmentsData> =>
  useContext(FetchDepartmentsDataContext);
