import { BannerWrapper, Text } from 'components/SetupBanner/SetupBanner.styled';

import { BKJButton } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { AppLink } from 'components/AppLink/AppLink';
import { isUserPendingApprovalSelector } from 'store/selectors/userSelectors/isUserPendingApprovalSelector';
import { useSelector } from 'react-redux';

import backgroundSVG from 'assets/background-banner.svg';
import { useTranslation } from 'hooks/useTranslation';

export const SetupBanner = () => {
  const { t } = useTranslation();
  const isPendingApproval = useSelector(isUserPendingApprovalSelector);
  if (!isPendingApproval) return null;
  return (
    <BannerWrapper background={backgroundSVG}>
      <Flex width="100%" justifyContent="flex-start">
        <Text>{t('settings:PENDING_APPROVAL')}</Text>
      </Flex>
      <Flex justifyContent="flex-end">
        <AppLink to="/settings/positions">
          <BKJButton width="200px" variant={'PurpleOutlined'}>
            {t('settings:CONTINUE_SETUP')}
          </BKJButton>
        </AppLink>
      </Flex>
    </BannerWrapper>
  );
};
