import styled, { css } from 'styled-components';

export const DayOfWeek = styled.button<{ isSelected: boolean }>`
  width: 44px;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.Grey2};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.PrimaryHover};
    color: white;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.PrimaryClick};
    color: white;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
      color: white;
    `}
`;
