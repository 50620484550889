import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { snakeCase } from 'lodash';
import { QUERY_fetchMe } from 'queries';
import {
  AgencyLoginResponseUserObject,
  B2BLoginResponseUserObject,
} from 'store/reducers/auth/authReducer.types';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toSnakeCase = (obj: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const snakeCaseObj: any = {};
  for (const key of Object.keys(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      snakeCaseObj[snakeCase(key)] = obj[key];
    }
  }

  return snakeCaseObj;
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: createBookJaneRequestHeaders(headers),
  };
});

const createApolloClient = (baseUrl: string) =>
  new ApolloClient({
    link: authLink.concat(createHttpLink({ uri: `${baseUrl}/graphql` })),
    cache: new InMemoryCache({}),
  });

export const fetchMeData = async ({ baseUrl }: { baseUrl: string }) => {
  const apolloClient = createApolloClient(baseUrl);

  const res = await apolloClient.query({ query: QUERY_fetchMe });

  let userWithRoleData = {};

  if (res.data) {
    const snakeCased = {
      ...toSnakeCase(res.data.me),
      access_status: res.data.me.accessStatus?.name,
    };
    const mappedResponse = {
      ...snakeCased,
      communities_attributes: snakeCased.communities?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (community: any, index: number) => ({
          ...toSnakeCase(community),
          location_attributes: {
            ...toSnakeCase(community?.location),
            label: community?.fullAddress,
          },
        }),
      ),
    };
    userWithRoleData = mappedResponse;
  }

  return userWithRoleData as B2BLoginResponseUserObject | AgencyLoginResponseUserObject;
};
