import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { BKJLoadingSpinner } from '@bookjane2/bookjane-design-library';
import { FC, Fragment } from 'react';
import { GOOGLE_API_KEY } from 'config';

const renderLoadingOrFailed = (status: Status) => {
  if (status === Status.FAILURE) {
    console.log('Failed to render map');
    return <Fragment />;
  }
  return <BKJLoadingSpinner variant={'Default'} />;
};

export const createGoogleMapsUrl = (fullAddress: string) =>
  `https://maps.google.com/?q=${fullAddress}`;

export const BKJGoogleMapsProvider: FC = ({ children }) => (
  <Wrapper apiKey={GOOGLE_API_KEY} render={renderLoadingOrFailed}>
    {children}
  </Wrapper>
);
