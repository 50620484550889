import { ApplyFacilitiesView } from 'components/CustomFieldCreateModal/views/ApplyFacilitiesView/ApplyFacilitiesView';
import { CreateCustomFieldView } from 'components/CustomFieldCreateModal/views/CreateCustomFieldView';
import i18next from 'i18next';

export const CustomFieldCreateModalViewMap = {
  CreateCustomFieldView,
  ApplyFacilitiesView,
};

export type CustomFieldCreateModalViewType = keyof typeof CustomFieldCreateModalViewMap;

export function getHeaderComponent(activeModalView: CustomFieldCreateModalViewType) {
  switch (activeModalView) {
    case 'CreateCustomFieldView':
    case 'ApplyFacilitiesView':
      return i18next.t('settings:CREATE_NEW_FIELD');
  }
}
