import { positionsSettingsPagePath } from 'components/SettingsNavigation/SettingsNavigation.constants';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { useFetchDepartmentsDataContext } from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/contexts/FetchDepartments.data.context';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';

export const PositionsSettingsRedirectPageComponent = () => {
  const { locationFilter } = useAuthRouteDataContext();
  const { data } = useFetchDepartmentsDataContext();

  const redirectIdMap = useMemo(
    () => ({
      Agency: locationFilter.initialValue.value,
      B2B: data?.departments[0]?.value,
    }),
    [data?.departments, locationFilter.initialValue.value],
  );
  const redirectId = useUserTypeSwitch(redirectIdMap);

  return <Redirect to={`${positionsSettingsPagePath}/${redirectId}`} />;
};
