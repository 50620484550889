import {
  GroupRule,
  createGroupRule,
  createInitialGroupRule,
  handleApiError,
  updateGroupId,
  updateWaitTimeMinutes,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';
import { shiftDistributionRulesGroupsModalRoutingService } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import {
  API_POST_shiftDistirbutionRules,
  API_POST_shiftDistirbutionRulesGroupRuleBatch,
  AddPositionRuleFormSchema,
  GroupRulesFormActions,
  GroupRulesFormSchema,
  shiftDistributionGroupRulesPayloadTransformer,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/AddPositionRule.constants';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviorsEventType,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';

type AddPositionRuleFormContextType = UseFormBehaviors<AddPositionRuleFormSchema> &
  GroupRulesFormSchema &
  GroupRulesFormActions & { positionApiError: string };

const VALIDATION_position_id = (value: string[]) => {
  if (value.length === 0 || !value[0]) {
    return 'Position is required';
  }
  return '';
};

const AddPositionRuleInitialState = {
  position_id: [''],
  skip_wait_time_threshold_minutes: ['1440'],
};

export const AddPositionRuleFormContext = createContext<AddPositionRuleFormContextType>(
  {} as AddPositionRuleFormContextType,
);

export const AddPositionRuleFormProvider: FC = ({ children }) => {
  const [groupRules, setGroupRules] = useState<GroupRule[]>([createInitialGroupRule()]);
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const [positionApiError, setPositionApiError] = useState<string>('');

  const { apiRequest } = useAPIActions();

  const { t } = useTranslation();

  const handleAddGroupRule = useCallback(
    () => setGroupRules([...groupRules, createGroupRule()]),
    [groupRules],
  );

  const handleGroupSelect = useCallback(
    (groupOption, indexId) =>
      setGroupRules([...groupRules.map(updateGroupId({ groupOption, indexId }))]),
    [groupRules],
  );
  const handleGroupDelete = useCallback(
    (index) => {
      groupRules.splice(index, 1);
      setGroupRules([...groupRules]);
    },
    [groupRules],
  );

  const handleWaitTimeSelect = useCallback(
    (waitTimeMinutesOptionValue, indexId) =>
      setGroupRules([
        ...groupRules.map(updateWaitTimeMinutes({ waitTimeMinutesOptionValue, indexId })),
      ]),
    [groupRules],
  );

  const handleSubmit = useCallback(
    async (values) => {
      const payload = shiftDistributionGroupRulesPayloadTransformer({
        ...values,
        groupRules,
        community_id: locationId,
      });
      return await apiRequest(API_POST_shiftDistirbutionRules(payload), {
        onSuccess: async (response) => {
          if (!!response?.data?.shift_distribution_rule?.id) {
            const shiftDistributionRuleId = response.data.shift_distribution_rule.id;
            return await apiRequest(
              API_POST_shiftDistirbutionRulesGroupRuleBatch({
                group_rules: payload.group_rules,
                shiftDistributionRuleId,
              }),
              {
                onSuccess: async () => {
                  toastService.success(t('success:RULE_SAVED'));
                  shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
                },
                onError: async (error) => {
                  toastService.error(JSON.stringify(error?.data?.group_rules?.errors));
                  shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
                },
              },
            );
          } else {
            shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (errors: any) => {
          if (!!errors?.data?.position && errors?.data?.position.length > 0) {
            setPositionApiError(t('errors:SEND_GROUP_RULE_ALREADY_EXISTS'));
          } else {
            return handleApiError(errors);
          }
        },
      });
    },
    [apiRequest, groupRules, locationId, t],
  );

  const addPositionRuleFormBehaviorsOptions: UseFormBehaviors_Options<AddPositionRuleFormSchema> = {
    initialState: { ...AddPositionRuleInitialState },
    isDirtyCheckEnabled: true,
    type: 'CREATE',
    onSubmit: handleSubmit,
    validations: {
      position_id: VALIDATION_position_id,
    },
  };

  const addPositionRuleForm = useFormBehaviors<AddPositionRuleFormSchema>(
    addPositionRuleFormBehaviorsOptions,
  );
  const value = useMemo(
    () => ({
      ...addPositionRuleForm,
      onChange: (e: UseFormBehaviorsEventType | UseFormBehaviorsEventType[]) => {
        addPositionRuleForm.onChange(e);
        setPositionApiError('');
      },
      addGroupRule: handleAddGroupRule,
      onGroupSelect: handleGroupSelect,
      onWaitTimeSelect: handleWaitTimeSelect,
      onGroupDelete: handleGroupDelete,
      groupRules,
      positionApiError,
      isDisabled:
        VALIDATION_position_id(addPositionRuleForm.values.position_id) !== '' ||
        !!groupRules.find((rule) => !rule.groupOption.value || rule.wait_time_minutes === ''),
    }),
    [
      addPositionRuleForm,
      groupRules,
      positionApiError,
      handleAddGroupRule,
      handleGroupDelete,
      handleGroupSelect,
      handleWaitTimeSelect,
    ],
  );

  return (
    <AddPositionRuleFormContext.Provider value={value}>
      {children}
    </AddPositionRuleFormContext.Provider>
  );
};

export const useAddPositionRuleFormContext = (): AddPositionRuleFormContextType =>
  useContext(AddPositionRuleFormContext);
