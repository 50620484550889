import styled from 'styled-components';

export const CalendarDayCellInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 117px;
`;

export const StatusWireframe = styled.div`
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  width: 100%;
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.Grey3};
  border-radius: 2px;
`;
