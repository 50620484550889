import { SSFBarsProvider } from 'components/SSFBars/SSFBars.context';
import { useSSFBehaviors } from 'hooks';
import { agencyMemberPageConfig } from 'pages/AgencyMemberPage/AgencyMemberPage.constants';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC } from 'react';
import { agencyMemberPage } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const AgencyMemberPageContext = createGQLDataContext({});

export const AgencyMemberPageProvider: FC = ({ children }) => {
  const { locationFilter } = useAuthRouteDataContext();
  const [values, opts] = useSSFBehaviors(
    agencyMemberPageConfig({ location: locationFilter?.initialValue }),
  );
  const value = { values, ...opts, data: opts.data as agencyMemberPage };
  return (
    <AgencyMemberPageContext.Provider value={value}>
      <SSFBarsProvider>{children}</SSFBarsProvider>
    </AgencyMemberPageContext.Provider>
  );
};
