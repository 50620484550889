import { useSSFBehaviors } from 'hooks';
import { createGQLDataContext } from 'utils/createDataContext';
import { FC, ReactNode, useContext } from 'react';
import { fetchJaneQueryConfig } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryJaneRow/ShiftSummaryJaneRow.constants';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { shiftSummaryJaneRowFetchJane } from 'types/graphql-types';

export const ShiftSummaryJaneRowDataContext = createGQLDataContext({});

export const ShiftSummaryJaneRowDataProvider: FC<{
  children: ReactNode;
  positionId: number;
  janeId: string;
}> = ({
  children,
  positionId,
  janeId,
}: {
  children: ReactNode;
  positionId: number;
  janeId: string;
}) => {
  const [values, opts] = useSSFBehaviors(fetchJaneQueryConfig({ positionId, janeId }));

  const value = { values, ...opts };

  return (
    <ShiftSummaryJaneRowDataContext.Provider value={value}>
      {children}
    </ShiftSummaryJaneRowDataContext.Provider>
  );
};

export const useShiftSummaryJaneRowDataContext = (): UseGQLQuery<shiftSummaryJaneRowFetchJane> =>
  useContext(ShiftSummaryJaneRowDataContext);
