export interface IResponsiveBreakPoint {
  Mobile: number;
  Tablet: number;
  Desktop: number;
}

export const ResponsiveBreakPoints: IResponsiveBreakPoint = {
  Mobile: 500,
  Tablet: 768,
  Desktop: 1024,
};
