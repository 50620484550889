import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const EmptyMemberView = styled(Flex)`
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export const SearchTeamMembersTitle = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
`;

export const H3 = styled.div<{ paddingBottom?: string }>`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-size: 28px;
  line-height: 34px;
  word-break: break-all;

  padding-bottom: ${({ paddingBottom = '0px' }) => paddingBottom};
`;

export const H4 = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const MemberCountLabel = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.Grey6};
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const GroupsDetailsWrapper = styled.div`
  padding: 32px 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  align-items: 'flex-start';
  width: 100%;
`;

export const GroupDetailsHeaderBorderStyle = 'solid 1px #8b96a350';
