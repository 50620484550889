import {
  PositionByDepartmentSelectDataType,
  PositionByDepartmentSelectOptionType,
} from 'components/PositionByDepartmentSelectInput/PositionsByDepartmentSelectInput.types';

export const getPositionByDepartmentSelectOptions = (data: PositionByDepartmentSelectDataType) => {
  return Object.values(data).reduce(
    (
      acc: PositionByDepartmentSelectOptionType[],
      groupValues: PositionByDepartmentSelectOptionType[],
    ) => {
      acc = acc.concat(
        groupValues.map(({ value, label, key }) => {
          return {
            key: key,
            value: value,
            label: label,
          };
        }),
      );
      return acc;
    },
    [],
  );
};
