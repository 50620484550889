import { AccushieldLogo } from 'components/WhiteLabelAssetComponents/Accushield/AccushieldLogo';
import { PetalLogo } from 'components/WhiteLabelAssetComponents/Petal/PetalLogo';
import { J360Logo } from 'components/BKJMainNavigation/src/J360Logo';
import { FC, Fragment, useCallback } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { XtmLogo } from 'components/WhiteLabelAssetComponents/Xtm/XtmLogo';

export const WhiteLabelLogo: FC = () => {
  const { WhiteLabelProvidersEnum, whiteLabelProvider } = WhiteLabelAccessor;

  const RenderLogoComponent = useCallback(() => {
    switch (whiteLabelProvider) {
      case WhiteLabelProvidersEnum.ACCUSHIELD:
        return <AccushieldLogo />;
      case WhiteLabelProvidersEnum.PETAL:
        return <PetalLogo />;
      case WhiteLabelProvidersEnum.XTM:
        return <XtmLogo />;
      case WhiteLabelProvidersEnum.WHITE_LABEL_DEMO:
        return <Fragment />;
      default:
        return <J360Logo />;
    }
  }, [
    WhiteLabelProvidersEnum.ACCUSHIELD,
    WhiteLabelProvidersEnum.WHITE_LABEL_DEMO,
    WhiteLabelProvidersEnum.PETAL,
    WhiteLabelProvidersEnum.XTM,
    whiteLabelProvider,
  ]);

  return <RenderLogoComponent />;
};
