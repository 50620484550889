import DesktopLoginPageWrapper from 'pages/LoginPage/DesktopLoginPageWrapper';
import MobileLoginPageWrapper from 'pages/LoginPage/MobileLoginPageWrapper';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';

export function isResponseSuccessful(responseType: { type: string }) {
  const split = responseType?.type?.split('/');
  const actionStatus = split[split.length - 1];
  return actionStatus === 'fulfilled';
}

export interface LoginFormSchema {
  username: string;
  password: string;
}

function LoginPage() {
  const { isMobile } = useResponsive();
  const LoginPageWrapper = isMobile ? MobileLoginPageWrapper : DesktopLoginPageWrapper;
  return <LoginPageWrapper />;
}

export default LoginPage;
