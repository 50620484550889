import { BKJHorizontalRule } from '@bookjane2/bookjane-design-library';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';
import { Flex } from 'components/Flex';

export const SectionDivider = styled(BKJHorizontalRule)`
  margin: 0px 0px 0px 0px;
`;

export const SendToAgenciesSummaryViewTitle = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const SendToAgenciesContainer = styled(Flex)`
  padding: 40px 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px;
  }
`;

export const ConfirmationButtons = styled(BKJButton)`
  width: 180px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    padding: 22px;
  }
`;
