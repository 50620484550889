import { customFieldCreateModalRoutingService } from 'components/CustomFieldCreateModal/contexts/CustomFieldCreateModal.ui.context';
import { CustomFieldInfoFormInitialState } from 'components/CustomFieldModal/CustomFieldModal.constants';
import { CustomFieldInfoFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';

import { useFetchCustomFieldsDataContext } from 'pages/SettingsPage/CustomFieldsSettingsPage/contexts/FetchCustomFields.data.context';
import { createContext, FC, useContext, useMemo } from 'react';
import { VALIDATION_dropdownOptions } from 'validations/VALIDATION_dropdownOptions';
import { VALIDATION_fieldName } from 'validations/VALIDATION_fieldName';
import { VALIDATION_placeholder } from 'validations/VALIDATION_placeholder';

type CustomFieldCreateModalFormContextType = UseFormBehaviors<CustomFieldInfoFormSchema>;

export const CustomFieldCreateModalFormContext =
  createContext<CustomFieldCreateModalFormContextType>({} as CustomFieldCreateModalFormContextType);

export const CustomFieldCreateModalFormProvider: FC = ({ children }) => {
  const { data: customFields = [] } = useFetchCustomFieldsDataContext();

  const customFieldInfoFormBehaviorsOptions: UseFormBehaviors_Options<CustomFieldInfoFormSchema> = {
    initialState: CustomFieldInfoFormInitialState,
    isDirtyCheckEnabled: true,
    type: 'CREATE',
    onSubmit: () => {
      customFieldCreateModalRoutingService.goToView('ApplyFacilitiesView');
    },
    validations: {
      name: VALIDATION_fieldName(customFields),
      placeholder: VALIDATION_placeholder,
      dropdownOptions: VALIDATION_dropdownOptions,
    },
  };

  const customFieldInfoForm = useFormBehaviors<CustomFieldInfoFormSchema>(
    customFieldInfoFormBehaviorsOptions,
  );

  const value = useMemo(
    () => ({
      ...customFieldInfoForm,
      isDisabled:
        customFieldInfoForm.isDisabled ||
        VALIDATION_dropdownOptions(
          customFieldInfoForm.values.dropdownOptions,
          customFieldInfoForm.values,
        ) !== '',
      onReset: () => customFieldInfoForm.onReset(CustomFieldInfoFormInitialState),
    }),
    [customFieldInfoForm],
  );

  return (
    <CustomFieldCreateModalFormContext.Provider value={value}>
      {children}
    </CustomFieldCreateModalFormContext.Provider>
  );
};

export const useCreateCustomFieldFormContext = (): CustomFieldCreateModalFormContextType =>
  useContext(CustomFieldCreateModalFormContext);
