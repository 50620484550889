import { FC, useContext, useEffect, useMemo } from 'react';
import { useGQLQuery } from 'hooks';
import { shiftModalDetailsQueryConfig } from 'components/ShiftModal/ShiftModal.constants';
import { createGQLDataContext } from 'utils/createDataContext';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { shiftModalOrderDetails } from 'types/graphql-types';
import { SSF_GLOBAL_CACHE_KEY } from 'services/ssfPersistenceService';
import { localPersistenceService } from 'services/localPersistenceService';

export const ShiftModalDataContext = createGQLDataContext({});

export const ShiftModalDataProvider: FC = ({ children }) => {
  const { shift_id } = useShiftModalUIContext();

  const cacheJson = localPersistenceService.getItem(SSF_GLOBAL_CACHE_KEY());

  const facility_id = useMemo(() => {
    if (!cacheJson) return -1;
    const parsedJson = JSON.parse(cacheJson);
    return Number(parsedJson?.location?.value);
  }, [cacheJson]);

  const value = useGQLQuery<
    shiftModalOrderDetails,
    shiftModalOrderDetails,
    { id: number | undefined }
  >(shiftModalDetailsQueryConfig(facility_id, shift_id));

  useEffect(() => {
    if (typeof shift_id === 'number') value.fetch();
  }, [shift_id]); // eslint-disable-line

  return <ShiftModalDataContext.Provider value={value}>{children}</ShiftModalDataContext.Provider>;
};

export const useShiftModalDataContext = (): UseGQLQuery<shiftModalOrderDetails> =>
  useContext(ShiftModalDataContext);
