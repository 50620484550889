import { IBKJDayOfWeekOption } from './BKJDaysOfWeek.types';
import i18next from 'i18next';

export const daysOfWeekOptions = (): IBKJDayOfWeekOption[] => [
  {
    key: 'sunday',
    value: 'sunday',
    label: i18next.t('create_shifts:SUN'),
  },
  {
    key: 'monday',
    value: 'monday',
    label: i18next.t('create_shifts:MON'),
  },
  {
    key: 'tuesday',
    value: 'tuesday',
    label: i18next.t('create_shifts:TUE'),
  },
  {
    key: 'wednesday',
    value: 'wednesday',
    label: i18next.t('create_shifts:WED'),
  },
  {
    key: 'thursday',
    value: 'thursday',
    label: i18next.t('create_shifts:THU'),
  },
  {
    key: 'friday',
    value: 'friday',
    label: i18next.t('create_shifts:FRI'),
  },
  {
    key: 'saturday',
    value: 'saturday',
    label: i18next.t('create_shifts:SAT'),
  },
];

export const recurringDayOfWeekMap: { [key: string]: number } = {
  sunday: 1,
  monday: 2,
  tuesday: 4,
  wednesday: 8,
  thursday: 16,
  friday: 32,
  saturday: 64,
};
