type UniqByFn = <T>(array: T[], iteratee: (item: T) => unknown) => T[];

export const uniqBy: UniqByFn = (array, iteratee) => {
  const seen = new Set();
  return array.filter((item) => {
    const key = iteratee(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};
