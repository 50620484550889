import styled from 'styled-components';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { Caption } from 'components/Typography';

export const PriceDrawerFlex = styled(Flex)``;

export const ResetButton = styled(BKJButton)`
  padding: 0;
  margin: -16px 0 18px;
  width: fit-content;
  align-self: flex-end;
`;

export const Text = styled(Caption)`
  padding-top: 18px;
  padding-bottom: 26px;
`;
