import { IBKJComboBoxProps } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBox/BKJComboBox.types';

export const shiftQuantityDropdownOptions: IBKJComboBoxProps['options'] = [...Array(9)].map(
  (_, i) => {
    const unique = `${i + 1}`;
    return {
      key: unique,
      value: unique,
      label: `x${i + 1}`,
      id: unique,
    };
  },
);
