import React, { useContext } from 'react';
import { InviteTeamMembersModalContext } from './InviteTeamMembers.context';
import SMSView from './views/SMSView/SMSView';
import EmailView from './views/EmailView/EmailView';
import { CSVView } from './views/CSVView';
// import { InviteTeamMemberModalTemplate } from './InviteTeamMembersModalTemplate';

const Component: React.FC = () => {
  const { activeView } = useContext(InviteTeamMembersModalContext);

  const Component = { SMSView, EmailView, CSVView }[activeView];
  return <Component />;
};

export default Component;
