import { ITabNavigationButton } from 'components/TabNavigationButton/TabNavigationButton.types';
import { FC } from 'react';
import { NavigationButton } from './TabNavigationButton.styled';

export const TabNavigationButton: FC<ITabNavigationButton> = ({ children, isActive, order }) => {
  return (
    <NavigationButton
      role="tab"
      aria-selected={isActive}
      aria-controls={`panel-${order}`}
      id={`tab-${order}`}
      isActive={isActive}
    >
      {children}
    </NavigationButton>
  );
};
