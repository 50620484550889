import styled from 'styled-components';
import { Flex } from 'components/Flex';
import { Body, Caption } from 'components/Typography';

export const ShiftJaneWrapper = styled.div`
  width: 100%;
`;

export const Jane = styled(Flex)`
  width: 100%;
  padding: 14px 25px;
  background-color: ${({ theme }) => theme.colors.Grey1};
`;

export const JaneName = styled(Body)`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;
export const JaneFacility = styled(Caption)`
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  ${({ theme }) => theme.common.Ellipsis};
  max-width: 350px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    max-width: 240px;
  }
`;
