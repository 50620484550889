import { Flex } from 'components/Flex';
import { BKJButton, BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { FC, useMemo } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useTieringDetailsModalUIContext } from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.ui.context';
import {
  H3,
  TieringDetailsTableContentCell,
  TieringRulesTableHeaderDivider,
  TieringDetailsSubHeader,
  TieringDetailsBody,
  TieringDetailsWrapper,
  TieringDetailsTableRow,
  TieringDetailsTableHeaderCell,
  BiddingRuleDetails,
  BiddingRuleContainer,
  ShiftCreationToolTipText,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.styled';
import {
  ShiftDistributionLocationDataProvider,
  useShiftDistributionLocationDataContext,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/views/ShiftDistributionLocation.data.context';
import { useTranslation } from 'hooks/useTranslation';
import {
  getBiddingPeriodTime,
  getBiddingRuleTime,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesTieringModal/views/ShiftBiddingDetails.constants';
import { fetchTieringDetails_fetchCommunity_biddingTimes } from 'types/graphql-types';

const ShiftBiddingDetailsViewComponent: FC = () => {
  const { onClose } = useTieringDetailsModalUIContext();
  const { locationName, biddingTimes } = useShiftDistributionLocationDataContext();

  const { t } = useTranslation();

  const BiddingRules = useMemo(() => {
    if (!biddingTimes) {
      return null;
    }
    return biddingTimes.map(
      (rule: fetchTieringDetails_fetchCommunity_biddingTimes, index: number) => {
        const isGrey = index % 2 === 0;

        const biddingTimesLength = biddingTimes.length;
        const isLastTwoLeadTimeIdentical =
          biddingTimesLength >= 2 &&
          biddingTimes[biddingTimesLength - 1].leadTime ===
            biddingTimes[biddingTimesLength - 2].leadTime &&
          index === biddingTimesLength - 1;

        return (
          <TieringDetailsTableRow>
            <TieringDetailsTableContentCell isGrey={isGrey}>
              <Flex flexDirection="column">
                <div>{t('location_settings:RULE', { value: index + 1 })}</div>
                {isLastTwoLeadTimeIdentical ? (
                  <BiddingRuleDetails>
                    {t('location_settings:BIDDING_RULE_MAX_DAYS', {
                      days: getBiddingRuleTime(rule),
                    })}
                  </BiddingRuleDetails>
                ) : (
                  <BiddingRuleDetails>
                    {index === 0 ? `0` : `${getBiddingRuleTime(biddingTimes[index - 1])} `} -&nbsp;
                    {`${getBiddingRuleTime(rule)} `}
                    {t('location_settings:BEFORE_SHIFT_START')}
                  </BiddingRuleDetails>
                )}
              </Flex>
            </TieringDetailsTableContentCell>
            <TieringDetailsTableContentCell isGrey={isGrey}>
              {getBiddingPeriodTime(rule)}
            </TieringDetailsTableContentCell>
          </TieringDetailsTableRow>
        );
      },
    );
  }, [biddingTimes, t]);

  if (!BiddingRules) return null;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
    >
      <TieringDetailsWrapper>
        <H3>{locationName}</H3>
        <Flex flexDirection="column" width="100%">
          <TieringDetailsSubHeader>
            {t('location_settings:SHIFT_BIDDING_RULES')}
          </TieringDetailsSubHeader>
          <TieringDetailsBody>
            {t('location_settings:SHIFT_BIDDING_DESCRIPTION')}
          </TieringDetailsBody>
        </Flex>
        <Flex flexDirection="column" padding="30px 0 0 0">
          <TieringDetailsTableRow>
            <TieringDetailsTableHeaderCell>
              <Flex padding="0 0 0 10px">{t('location_settings:SHIFT_CREATION')}&nbsp;</Flex>
              <BKJIconTooltip iconName="Info" placement="top">
                <Flex flexDirection="column" width="258px">
                  <ShiftCreationToolTipText>
                    {t('location_settings:SHIFT_CREATION_TOOLTIP')}
                  </ShiftCreationToolTipText>
                </Flex>
              </BKJIconTooltip>
            </TieringDetailsTableHeaderCell>
            <TieringDetailsTableHeaderCell>
              {t('location_settings:BIDDING_PERIOD')}
            </TieringDetailsTableHeaderCell>
          </TieringDetailsTableRow>
          <TieringRulesTableHeaderDivider />
          <BiddingRuleContainer>{BiddingRules}</BiddingRuleContainer>
        </Flex>
      </TieringDetailsWrapper>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton variant="PurpleSolid" width="180px" onClick={onClose}>
          {t('common:CLOSE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const ShiftBiddingDetails: FC = () => {
  return (
    <ShiftDistributionLocationDataProvider>
      <ShiftBiddingDetailsViewComponent />
    </ShiftDistributionLocationDataProvider>
  );
};
