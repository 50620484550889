import { Fragment } from 'react';
import { Flex } from 'components/Flex';
import { CreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { FC, useContext } from 'react';
import { PositionByDepartmentSelectInput } from 'components/PositionByDepartmentSelectInput';
import { ShiftTimeSelectInput } from 'components/ShiftTimeSelectInput';
import { BKJIcon, BKJTooltipWrapper } from '@bookjane2/bookjane-design-library';
import {
  WebCreateShiftCardWrapper,
  DeleteIcon,
  Duration,
  HideOnMobile,
  IconWithMarginRight,
  LeftSideActions,
  RightSideActions,
  SectionA,
  SectionB,
  ShiftCardButton,
  ShiftBiddingSection,
  FormLabel,
  SectionDivider,
  BiddingPeriod,
} from './CreateShiftCard.styled';
import { RecurringText } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.styled';
import { getRecurringLabel } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import { isRecurring } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { ShiftUnpaidBreakTimeSelectInput } from 'components/ShiftUnpaidBreakTimeSelectInput';
import { ShiftQuantitySelectInput } from 'components/ShiftQuantitySelectInput';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { AvailableTeamMembers } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/AvailableTeamMember';
import { DedicatedTeamMembersToggleInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/DedicatedTeamMembersToggleInput';
import {
  calculateShiftDurationInMinutes,
  formatInterval,
  getShiftStartTimeAndEndTime,
} from 'utils/time';
import {
  createShiftsCardErrorMessages,
  getBiddingPeriod,
} from 'pages/CreateShiftsPage/CreateShiftsPage.constants';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { FeatureGuard } from 'guards/FeatureGuard';
import { UserGuard } from 'guards/UserGuard';
import { AllowOvertimeToggleInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/AllowOvertimeToggleInput';
import { useSelector } from 'react-redux';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';
import { AutoExtendBidToggleInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/AutoExtendBidToggleInput';
import { OpenShiftPolicyEnum } from 'types/graphql-types';

export const WebCreateShiftCard: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { groupedFormsBehaviors, biddingPeriods } = useContext(CreateShiftsPageContext);
  const {
    createShift,
    goToModalView,
    fieldErrors,
    formErrors,
    handleChange,
    teamMembersCount,
    location,
    isSchedulingEnabled,
  } = useContext(CreateShiftCardContext);
  const { values } = createShift;
  const { startAt, endAt, startDate } = values;
  const [start_time] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
    startDate,
  });
  const calculatedBiddingPeriod = getBiddingPeriod(start_time, biddingPeriods);
  const createShifts = groupedFormsBehaviors.groupedForms as unknown as ICreateShift[];
  const { PAID_DURATION_LOW, SHIFT_START_TIME_IN_PAST, BREAK_TIME_LONGER_THAN_DURATION } =
    createShiftsCardErrorMessages;

  const moreThanOneShiftCard = createShifts.length > 1;
  const isFirstShiftCard = !(createShifts.length > 0 && createShifts[0].values.id !== values.id);

  const removeShift = () => {
    if (moreThanOneShiftCard) groupedFormsBehaviors.removeFormItem({ id: values.id });
  };

  const isShiftBiddingFeatureEnabled = useSelector(isShiftBiddingFeatureEnabledSelector);
  const isShiftBiddingRequired =
    values?.openShiftPolicy === OpenShiftPolicyEnum.bidding_required &&
    isShiftBiddingFeatureEnabled;

  return (
    <Fragment>
      <WebCreateShiftCardWrapper
        flexDirection="column"
        justifyContent="space-between"
        padding="16px 16px 0px 16px"
        minHeight="145px"
        minWidth="362px"
      >
        {!isFirstShiftCard && <DeleteIcon iconName="Delete" onClick={removeShift} />}
        <SectionA width="100%" justifyContent="space-between" alignItems="stretch" flexWrap="wrap">
          <Flex flexDirection="column" padding="4px" flex="4">
            <FormLabel data-cy="lbl-position">{t('common:POSITION')}</FormLabel>
            <PositionByDepartmentSelectInput
              location={location}
              dropdownVariant="Square"
              placeholder={t('common:SELECT_POSITION')}
              onChange={handleChange}
              type="Single"
              name="position_id"
              variant="GreyOutlined"
              value={values.position_id}
              error={fieldErrors?.position_id}
              iconComponent={BKJIcon}
              width="100%"
              dropdownProps={{ dropdownListItemVariant: 'List', isSearchable: false }}
              iconProps={{
                iconName: 'ShiftsClient',
              }}
            />
          </Flex>
          <Flex flexDirection="column" padding="4px" flex="2">
            <FormLabel data-cy="lbl-date">Date</FormLabel>
            <ShiftDateCalendarInput
              placeholder={t('create_shifts:SELECT_DATE')}
              dropdownVariant="Square"
              name="startDate"
              value={values.startDate}
              error={fieldErrors?.startDate || formErrors?.includes(SHIFT_START_TIME_IN_PAST())}
              isEventValueNormalized
              onChange={handleChange}
              iconComponent={BKJIcon}
              iconProps={{
                iconName: 'ShiftsDate',
              }}
              dropdownProps={{
                disabledTo: DateTime.now().minus({ days: 1 }).toISO(),
                disabledFrom: DateTime.now().plus({ months: 6 }).toISO(),
              }}
            />
          </Flex>
          <Flex flexDirection="column" padding="4px" minWidth="177.5px" width="277.5px" flex="1.8">
            <ShiftTimeSelectInput
              label={t('create_shifts:START_TIME')}
              placeholder={t('create_shifts:START_TIME')}
              onChange={handleChange}
              error={!!fieldErrors?.startAt?.length}
              name="startAt"
              value={values.startAt}
            />
            <Duration
              isError={
                !!formErrors?.some((error) =>
                  [PAID_DURATION_LOW(), BREAK_TIME_LONGER_THAN_DURATION()].includes(error),
                )
              }
            >
              {t('create_shifts:DURATION')}:&nbsp;
              {formatInterval(
                calculateShiftDurationInMinutes({
                  startDate: values.startDate,
                  startAt: values.startAt,
                  endAt: values.endAt,
                  unpaidBreakTime: values.unpaidBreakTime,
                  isPaidDuration: true,
                }),
              )}
            </Duration>
          </Flex>

          <Flex flexDirection="column" padding="4px" minWidth="177.5px" flex="1.8">
            <ShiftTimeSelectInput
              label={t('create_shifts:END_TIME')}
              placeholder={t('create_shifts:END_TIME')}
              onChange={handleChange}
              error={!!fieldErrors?.endAt?.length}
              name="endAt"
              value={values.endAt}
            />
          </Flex>

          <Flex flexDirection="column" padding="4px" minWidth="166px" flex="1.7">
            <ShiftUnpaidBreakTimeSelectInput
              placeholder={t('create_shifts:UNPAID_BREAK')}
              label={t('create_shifts:UNPAID_BREAK')}
              onChange={handleChange}
              error={!!fieldErrors?.unpaidBreakTime?.length}
              name="unpaidBreakTime"
              value={values.unpaidBreakTime}
            />
          </Flex>

          <Flex flexDirection="column" padding="4px" minWidth="102px" flex="1.1">
            <ShiftQuantitySelectInput
              placeholder={t('common:SHIFT_QUANTITY')}
              onChange={handleChange}
              error={fieldErrors?.quantity}
              name="quantity"
              value={values.quantity}
              label={t('common:SHIFT_QUANTITY')}
            />
          </Flex>
        </SectionA>
        <SectionDivider variant="Thin" />
        <SectionB alignItems="center" justifyContent="space-between" margin="4px">
          <LeftSideActions>
            {!isShiftBiddingRequired && (
              <ShiftCardButton
                onClick={() => goToModalView('Recurring')}
                variant="PurpleNoDecorations"
                data-cy="btn-recurringShift"
                disabled={!values.startDate || !values.position_id[0]}
              >
                <IconWithMarginRight iconName="Recurring" color="PrimaryClick" />
                <RecurringText>{getRecurringLabel(values)}</RecurringText>
              </ShiftCardButton>
            )}
            <ShiftCardButton
              variant="PurpleNoDecorations"
              data-cy="btn-notes"
              onClick={() => goToModalView('Details')}
              disabled={!values.startDate || !values.position_id[0]}
            >
              <IconWithMarginRight iconName="Notes1" color="PrimaryClick" />
              <HideOnMobile>
                {values.description ? t('create_shifts:VIEW') : t('create_shifts:EDIT')}&nbsp;
              </HideOnMobile>
              {t('create_shifts:DETAILS')}
            </ShiftCardButton>
            {isShiftBiddingRequired && (
              <ShiftBiddingSection>
                {t('create_shifts:SHIFT_BIDDING')}
                <BiddingPeriod>
                  {t('create_shifts:BIDDING_PERIOD')}:&nbsp;
                  {values.startDate ? calculatedBiddingPeriod : '-'}
                </BiddingPeriod>
              </ShiftBiddingSection>
            )}
            {(isSchedulingEnabled || isShiftBiddingRequired) && (
              <FeatureGuard type="FEATURE_TYPE_SCHEDULING">
                {!isRecurring(values) || isShiftBiddingRequired ? (
                  <AllowOvertimeToggleInput />
                ) : (
                  <BKJTooltipWrapper placement="bottom" Component={AllowOvertimeToggleInput}>
                    <Flex flexDirection="column" gap="20px" width="209px">
                      <div>{t('SHIFT_MODAL_OVERTIME_CANNOT_BE_ALLOWED')}</div>
                    </Flex>
                  </BKJTooltipWrapper>
                )}
              </FeatureGuard>
            )}
            {isShiftBiddingRequired && <AutoExtendBidToggleInput />}
            <UserGuard userType={'USER_TYPE_ESSENTIAL'}>
              <DedicatedTeamMembersToggleInput />
            </UserGuard>
          </LeftSideActions>
          {!isShiftBiddingRequired && (
            <RightSideActions>
              <AvailableTeamMembers
                count={teamMembersCount}
                onClick={() => goToModalView('RequestTeamMembers')}
              />
            </RightSideActions>
          )}
        </SectionB>
      </WebCreateShiftCardWrapper>
    </Fragment>
  );
};
