import { DetailsView } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView';
import { RecurringView } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RecurringView/RecurringView';
import { RequestTeamMembers } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RequestTeamView/RequestTeamView';
import { ComponentMapType } from 'types/common.types';

export const createShiftModalComponentMap: ComponentMapType = {
  Details: DetailsView,
  Recurring: RecurringView,
  RequestTeamMembers,
};
