import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment } from 'react';
import { ContentWrapper, MainTitleContainer, HeaderWrapper } from './AwardUnavailableView.styled';
import { EmptyWrapper } from 'components/JaneModal/views/JaneDetailsView/GeneralTab/GeneralTab.styled';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { AutoMargin } from 'components/AutoMargin';
import { BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import { CSVBorderHeaderStyle } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.styled';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
export const AwardUnavailableView: FC = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <HeaderWrapper borderTop={CSVBorderHeaderStyle}>
        <MainTitleContainer justifyContent="space-between" alignItems="baseline">
          {t('shift_modal:TEAM_MEMBER_UNAVAILABLE')}
        </MainTitleContainer>
        <ContentWrapper>
          <EmptyWrapper>{t('shift_modal:THE_TEAM_MEMBER_YOU_SELECTED')}</EmptyWrapper>
          <EmptyWrapper>{t('shift_modal:YOU_CAN_GO_BACK_AND_AWARD')}</EmptyWrapper>
        </ContentWrapper>
        <Flex height="100%"></Flex>
        <BKJSideModalFooter>
          <AutoMargin />
          <BKJButton
            width="180px"
            variant={'PurpleSolid'}
            onClick={() => {
              shiftModalRoutingService.goToPrevView();
            }}
          >
            {t('GO_BACK')}
          </BKJButton>
        </BKJSideModalFooter>
      </HeaderWrapper>
    </Fragment>
  );
};
