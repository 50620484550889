import { TableCell, MobileTableCell } from 'components/ActivityTab/ActivityTab.styled';
import styled from 'styled-components';

export const AuditTrailActivityCell = styled(TableCell)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const MobileAuditTrailActivityCell = styled(MobileTableCell)`
  padding: 12px 0;
`;
