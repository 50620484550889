import { useGQLQuery } from 'hooks';

import { TimeFrameLocationSettingsFormFields } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.types';
import { QUERY_timeFrameLocationSettingsPage } from 'queries';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GQLAPIContextType } from 'types/common.types';
import {
  timeFrameLocationSettingsPage,
  timeFrameLocationSettingsPageVariables,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { withMemo } from 'utils/withMemo';

export const TimeFrameLocationSettingsPageDataContext =
  createGQLDataContext<TimeFrameLocationSettingsFormFields>();

export const Component: FC = ({ children }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data, isLoading, fetch, ...query } = useGQLQuery<
    timeFrameLocationSettingsPage,
    TimeFrameLocationSettingsFormFields,
    timeFrameLocationSettingsPageVariables
  >({
    query: QUERY_timeFrameLocationSettingsPage,
    key: 'TIME_FRAME_LOCATION_SETTINGS_PAGE',
    variables: {
      locationId: Number.parseInt(locationId),
    },
    responseTransformer(response: timeFrameLocationSettingsPage) {
      if (!response) return {};
      const formValues: TimeFrameLocationSettingsFormFields = {
        morningStartsAt: response?.fetchCommunity?.timeFrame?.morningStartsAt,
        eveningStartsAt: response?.fetchCommunity?.timeFrame?.eveningStartsAt,
        nightStartsAt: response?.fetchCommunity?.timeFrame?.nightStartsAt,
      };
      return formValues;
    },
  });
  useEffect(() => fetch(), [fetch]);
  const contextValue = useMemo(() => ({ ...query, data }), [data, query]);
  return (
    <TimeFrameLocationSettingsPageDataContext.Provider value={contextValue}>
      {children}
    </TimeFrameLocationSettingsPageDataContext.Provider>
  );
};

export const useTimeFrameLocationSettingsPageDataContext =
  (): GQLAPIContextType<TimeFrameLocationSettingsFormFields> =>
    useContext(TimeFrameLocationSettingsPageDataContext);

export const TimeFrameLocationSettingsPageDataProvider = withMemo(Component);
