import { DayOfWeek } from './BKJDaysOfWeek.styled';
import {
  IBKJDayOfWeekOption,
  IBKJDaysOfWeekInputProps,
} from 'components/BKJDaysOfWeekInput/BKJDaysOfWeek.types';
import { daysOfWeekOptions } from './BKJDaysOfWeekInput.constants';
import { FC } from 'react';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { Flex } from 'components/Flex';

export const BKJDaysOfWeekInput: FC<IBKJDaysOfWeekInputProps> = ({ name, value, onChange }) => {
  const handleChange: ChangeFunctionType = (event) => {
    let _value = [...value];
    const _event = { ...event };
    const dayIndex = _value.findIndex((day) => day === event.target.value);
    if (dayIndex === -1) {
      _value = _value.concat([event.target.value]);
    } else {
      _value.splice(dayIndex, 1);
    }
    _event.target.value = _value;
    onChange(_event);
  };
  const translatedDaysOfWeekOption = daysOfWeekOptions();
  return (
    <Flex>
      {translatedDaysOfWeekOption.map((day: IBKJDayOfWeekOption) => {
        return (
          <DayOfWeek
            key={day.key}
            name={name || 'recurring_weekday'}
            value={day.value}
            onClick={() => handleChange({ target: { name, value: day.value } })}
            isSelected={value.some((valueDay) => valueDay === day.value)}
          >
            {day.label}
          </DayOfWeek>
        );
      })}
    </Flex>
  );
};
