import { SendToAgenciesSummaryViewComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/SendToAgenciesSummaryView.component';
import { SendToAgenciesSummaryViewPropsProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/SendToAgenciesSummaryView.props.context';
import { FC } from 'react';

export const SendToAgenciesSummaryView: FC = () => {
  return (
    <SendToAgenciesSummaryViewPropsProvider>
      <SendToAgenciesSummaryViewComponent />
    </SendToAgenciesSummaryViewPropsProvider>
  );
};
