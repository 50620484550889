import { Rating, RatingWrapper, StarIcon } from './StarReview.styled';
import { IStarReviewProps } from './StarReview.types';

export function StarReview(props: IStarReviewProps): JSX.Element {
  const { rating, onChange } = props;
  return (
    <Rating>
      <RatingWrapper>
        {Array.from({ length: 5 }).map((value, index) => (
          <StarIcon
            key={`StarIcon-${index}`}
            iconName="Star"
            fontSize={30}
            color={index <= rating - 1 ? 'AccentGreen' : 'Grey4'}
            onClick={() => onChange(index === rating - 1 ? 0 : index + 1)}
          />
        ))}
      </RatingWrapper>
    </Rating>
  );
}
