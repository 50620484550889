import {
  customFieldsPayloadConverter,
  ICustomFields,
  startOfDay,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  API_PUT_updateTimeOffParamsType,
  CustomFieldEntity,
} from 'requests/PUT_updateTimeOff.types';
import { fetchTimeOffCustomFields_fetchCustomFields_nodes } from 'types/graphql-types';
import { getStartEndDateTime } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.constants';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const updateTimeOffPayloadTransformer = (payload: {
  allDay: boolean;
  startDate: string[];
  endDate: string[];
  startTime: string[];
  endTime: string[];
  title: string[];
  notes: string;
  customFields: ICustomFields;
  timeOffCustomFields: fetchTimeOffCustomFields_fetchCustomFields_nodes[];
}): API_PUT_updateTimeOffParamsType => {
  const {
    allDay,
    startDate,
    endDate,
    startTime,
    endTime,
    title,
    notes,
    customFields,
    timeOffCustomFields,
  } = payload;

  let [fromForTimeZone, toForTimeZone] = getStartEndDateTime({
    startDate,
    endDate,
    startTime,
    endTime,
    allDay,
  });

  const { timeZone } = getCurrentlySelectedLocation();

  const fromDateTime = DateTime.fromISO(fromForTimeZone).setZone(timeZone);
  let endDateTime = DateTime.fromISO(toForTimeZone).setZone(timeZone);

  if (endDateTime.toFormat('HH:mm:ss') === startOfDay[0])
    endDateTime = endDateTime.minus({ second: 1 });

  // Continue with the rest of the code...
  const custom_fields = customFieldsPayloadConverter(
    customFields,
    timeOffCustomFields,
  ) as CustomFieldEntity[];

  const start_date_time = {
    date: fromDateTime.toFormat('yyyy-MM-dd'),
    hour: fromDateTime.get('hour'),
    minute: fromDateTime.get('minute'),
  };

  const end_date_time = {
    date: endDateTime.toFormat('yyyy-MM-dd'),
    hour: endDateTime.get('hour'),
    minute: endDateTime.get('minute'),
  };

  return {
    time_off: {
      start_date_time,
      end_date_time,
      title: title[0],
      notes,
      custom_fields,
    },
  };
};
