import {
  Caption,
  MobileBookingButton,
  CreateShiftCardsWrapper,
  ConfirmButtonWrapper,
  Heading,
  IconWithMarginRight,
  HeaderSection,
  MobileAddAnOtherShift,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/BulkCreateShiftView.styled';
import { CreateShiftCard } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import { useCreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { getInitialCreateShiftForm } from 'pages/CreateShiftsPage/CreateShiftsPage.utils';
import { MountTransition } from 'routes/MountTransition';
import { CreateShiftCardProvider } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { FC, Fragment } from 'react';
import { Flex } from 'components/Flex';
import { useTranslation } from 'react-i18next';
import { SelectDatesButton } from 'pages/CreateShiftsPage/BulkCreateShiftView/SelectDatesButton';

export const MobileBulkCreateShiftView: FC = () => {
  const { groupedFormsBehaviors, onConfirmBooking } = useCreateShiftsPageContext();
  const {
    addFormItem,
    groupedForms,
    isSubmitDisabled,
    groupedFormsFieldErrors,
    groupedFormsFormErrors,
  } = groupedFormsBehaviors;

  const createShifts = groupedForms as ICreateShift[];

  const addBlankShift = () => {
    addFormItem(getInitialCreateShiftForm({}));
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      <HeaderSection>
        <Heading data-cy="hdr-selectDates">{t('create_shifts:SELECT_DATES')}</Heading>
        <Caption data-cy="hdr-caption">{t('create_shifts:SELECT_DATES_INFO')}</Caption>
        <SelectDatesButton>{t('create_shifts:SELECT_DATES')}</SelectDatesButton>
      </HeaderSection>
      <CreateShiftCardsWrapper>
        {createShifts.map((createShift, index) => (
          <MountTransition key={createShift.values.id}>
            <CreateShiftCardProvider
              key={createShift.values.id}
              createShift={createShift}
              fieldErrors={groupedFormsFieldErrors[index]}
              formErrors={groupedFormsFormErrors[index]}
            >
              <CreateShiftCard />
            </CreateShiftCardProvider>
          </MountTransition>
        ))}
      </CreateShiftCardsWrapper>
      <Flex width="100%">
        <MobileAddAnOtherShift
          variant="PurpleNoDecorations"
          onClick={addBlankShift}
          minWidth="fit-content"
          width="fit-content"
          data-cy="btn-addAnothrShift"
        >
          <IconWithMarginRight iconName="Add" />
          {t('create_shifts:ADD_ANOTHER_SHIFT')}
        </MobileAddAnOtherShift>
      </Flex>
      <ConfirmButtonWrapper>
        <MobileBookingButton
          variant="PurpleSolid"
          onClick={onConfirmBooking}
          isSubmitDisabled={isSubmitDisabled}
          data-cy="btn-confirmBooking"
        >
          {t('create_shifts:CONFIRM_BOOKING')}
        </MobileBookingButton>
      </ConfirmButtonWrapper>
    </Fragment>
  );
};
