import pluralize from 'pluralize';

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

const toLabel = (value: number, label: string) =>
  value ? `${value} ${pluralize(label, value)}` : '';

export const snakeToTitleCase = (s: string) => s.split('_').map(capitalize).join(' ');

export const toNumber = (value: string[]) => +(value[0] || 0);

export const generateLabelFromRange = ({
  minimum: { hours: minimumHours = 0, mins: minimumMins = 0 },
  maximum: { hours: maximumHours = 0, mins: maximumMins = 0 },
  labels: {
    min: minLabel = 'min',
    hour: hourLabel = 'hr',
    lessThan: lessThanLabel = 'or less',
    moreThan: moreThanLabel = 'or more',
  },
}: {
  minimum: { hours: number; mins: number };
  maximum: { hours: number; mins: number };
  labels: { min: string; hour: string; lessThan: string; moreThan: string };
}) => {
  const hasUpperBound = !!maximumHours || !!maximumMins;
  const hasLowerBound = !!minimumHours || !!minimumMins;

  if (hasUpperBound && hasLowerBound) {
    if (minimumMins || maximumMins) {
      const min = `${toLabel(minimumHours, hourLabel)} ${toLabel(minimumMins, minLabel)}`;
      const max = `${toLabel(maximumHours, hourLabel)} ${toLabel(maximumMins, minLabel)}`;
      return `${min} - ${max}`;
    }
    return `${minimumHours} - ${maximumHours} ${hourLabel}`;
  }

  if (!hasUpperBound && hasLowerBound) {
    const hoursMinsLabel = `${toLabel(minimumHours, hourLabel)} ${toLabel(minimumMins, minLabel)}`;
    return `${hoursMinsLabel} ${moreThanLabel}`;
  }

  if (hasUpperBound && !hasLowerBound) {
    const hoursMinsLabel = `${toLabel(maximumHours, hourLabel)} ${toLabel(maximumMins, minLabel)}`;
    return `${hoursMinsLabel} ${lessThanLabel}`;
  }
  return '-';
};
