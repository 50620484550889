import { Flex } from '@bookjane2/bookjane-design-library';
import { QuerySwitch } from 'components/QuerySwitch';
import { useCalendarDayCellPropsContext } from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCell.props.context';
import { StatusWireframe } from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCell.styled';
import { CalendarCellStatuses } from 'components/ShiftCalendar/CalendarDayCell/CalendarStatuses';
import { Circle } from 'components/ShiftCalendar/ShiftCalendar.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { ShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import { FC } from 'react';

const CalendarDayCellStatusAggregateContentSkeleton = () => {
  const { date } = useCalendarDayCellPropsContext();

  const offset = Number.parseInt(date.split('-').pop() as string, 10);
  return (
    <AnimatePresence initial>
      <motion.div
        key={`Skeleton-${date}`}
        initial={{ opacity: 0.3 }}
        animate={{ opacity: 0.7 }}
        exit={{ opacity: 0 }}
        transition={{
          delay: offset % 3,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'mirror',
          repeatDelay: 0.1,
          duration: 0.8,
        }}
      >
        <Flex flexDirection="column" gap="4px" width="100%" flex="1" height="117px">
          <StatusWireframe />
          <StatusWireframe />
          <StatusWireframe />
          <Flex
            height="10px"
            width="100%"
            flexDirection="row-reverse"
            margin="auto 0 0 0"
            justifySelf="flex-end"
          >
            {[...Array(3)].map((_, i) => (
              <Circle key={`CalendarDayCellSkeletonCircle-${i}`} color="Grey3" />
            ))}
          </Flex>
        </Flex>
      </motion.div>
    </AnimatePresence>
  );
};

export const CalendarDayCellStatusAggregateContent: FC = () => {
  return (
    <QuerySwitch
      context={ShiftsPageContext}
      component={CalendarCellStatuses}
      loadingComponent={CalendarDayCellStatusAggregateContentSkeleton}
    />
  );
};
