import { FC } from 'react';
import { ICalendarNavigationProps } from './CalenderNavigation.types';
import {
  CalendarNavigationWrapper,
  CalendarNavigationButton,
  CalendarGoToViewButton,
} from './CalendarNavigation.styled';
import { BKJIcon } from '@bookjane2/bookjane-design-library';

export const CalendarNavigation: FC<ICalendarNavigationProps> = ({
  displayLabel,
  onClickPrevious,
  onClickNext,
  onClickDisplay,
}): JSX.Element => {
  const isGoToViewButtonDisabled = onClickDisplay ? false : true;
  return (
    <CalendarNavigationWrapper>
      <CalendarNavigationButton onClick={onClickPrevious}>
        <BKJIcon data-cy="ArrowLeft" iconName="ArrowLeft" color="PrimaryDefault" />
      </CalendarNavigationButton>
      <CalendarGoToViewButton
        data-cy="monthYearDetail"
        disabled={isGoToViewButtonDisabled}
        onClick={onClickDisplay}
      >
        {displayLabel}
      </CalendarGoToViewButton>
      <CalendarNavigationButton onClick={onClickNext}>
        <BKJIcon data-cy="ArrowRight" iconName="ArrowRight" color="PrimaryDefault" />
      </CalendarNavigationButton>
    </CalendarNavigationWrapper>
  );
};
