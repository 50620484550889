import { FC, KeyboardEvent, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { I18nLanguageSwitcher } from './TranslationSwitch.styled';
import { sessionTokenSelector } from 'store/selectors/userSelectors/sessionTokenSelector';
import { useSelector } from 'react-redux';

export const TranslationSwitch: FC = (): JSX.Element => {
  const isUserLoggedIn = useSelector(sessionTokenSelector);

  const isDebugMode = window.localStorage.getItem('BKJDebugMode');

  const { i18n } = useTranslation();

  const getNextLanguage = (language: string): string => {
    switch (language) {
      case 'en':
        return 'fr';
      case 'fr':
        return 'en';
      default:
        return 'fr';
    }
  };

  const switchLanguages = (language: string) => i18n.changeLanguage(getNextLanguage(language));

  const displayLanguage = getNextLanguage(i18n.language);

  const handleKeyPress = (e: KeyboardEvent<HTMLImageElement>): void => {
    if (e.code === 'Space') switchLanguages(i18n.language);
  };

  const showLanguageSelector =
    (process.env.REACT_APP_WHITE_LABEL === 'petal' && !isUserLoggedIn) || isDebugMode;

  return (
    <Fragment>
      {showLanguageSelector && (
        <I18nLanguageSwitcher
          role="button"
          tabIndex={0}
          onClick={() => switchLanguages(i18n.language)}
          onKeyPress={handleKeyPress}
        >
          {displayLanguage.toUpperCase().substring(0, 2)}
        </I18nLanguageSwitcher>
      )}
    </Fragment>
  );
};
