import { QuerySwitch } from 'components/QuerySwitch';
import { FetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import { DetailTimeOffViewComponent } from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.component';
import { DetailTimeOffViewPropsProvider } from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffView.props.context';
import { FC } from 'react';

export const DetailTimeOffView: FC = () => {
  return (
    <QuerySwitch
      context={FetchTimeOffDataContext}
      component={() => (
        <DetailTimeOffViewPropsProvider>
          <DetailTimeOffViewComponent />
        </DetailTimeOffViewPropsProvider>
      )}
    />
  );
};
