import { FooterLinks } from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { ExternalNavMenuItem } from 'components/BKJMainNavigation/src/ExternalNavMenuItem';
import { IWebNavFooterProps } from 'components/BKJMainNavigation/src/Web/WebNavFooter.types';
import { NavMenuItem } from 'components/BKJMainNavigation/src/NavMenuItem';
import { FC, memo } from 'react';
import { useUserActions } from 'store/reducers/auth/authReducer';
import { deepEquals } from 'utils/deepEquals';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

const getHelpDeskLink = () => {
  const { whiteLabelProvider, WhiteLabelProvidersEnum } = WhiteLabelAccessor;
  switch (whiteLabelProvider) {
    case WhiteLabelProvidersEnum.XTM:
      return 'https://www.paidtoday.io/support';
    case WhiteLabelProvidersEnum.ACCUSHIELD:
      return 'https://flexaccushield.zendesk.com';
    case WhiteLabelProvidersEnum.PETAL:
      return 'https://support.petalmd.com/hc/en-ca';
    default:
      return 'https://bookjane.zendesk.com';
  }
};

const Component: FC<IWebNavFooterProps> = ({ disabled, isOpen, onClose }) => {
  const { userLogout } = useUserActions();
  const { isXtmProvider } = WhiteLabelAccessor;
  const { t } = useTranslation();
  return (
    <FooterLinks>
      <ExternalNavMenuItem
        href={getHelpDeskLink()}
        label={t('navigation:HELP')}
        icon="Question"
        disabled={disabled.current}
        isOpen={isOpen}
        onClick={(e) => {
          onClose(e);
          e.preventDefault();
          return window.open(getHelpDeskLink(), '_blank');
        }}
      />
      {!isXtmProvider && (
        <NavMenuItem
          label={t('navigation:LOGOUT')}
          icon="Logout"
          data-cy="logout"
          disabled={disabled.current}
          isOpen={isOpen}
          onClick={(e) => {
            userLogout();
            onClose(e);
          }}
        />
      )}
    </FooterLinks>
  );
};

export const WebNavFooter = memo(Component, deepEquals);
