import { FC, Fragment } from 'react';
import { CalendarDayWrapper } from 'components/ShiftCalendar/CalendarDayWrapper';
import { CalendarHeaderRow } from 'components/ShiftCalendar/CalendarHeaderRow';
import { ShiftsCalenderWrapper } from 'pages/ShiftsCalendarPage/ShiftsCalendarPage.styled';
import { CalenderTotalSummaryFooter } from 'components/ShiftCalendar/CalenderTotalSummaryFooter';

export const ShiftsPageCalendar: FC = () => {
  return (
    <Fragment>
      <ShiftsCalenderWrapper>
        <CalendarHeaderRow />
        <CalendarDayWrapper />
      </ShiftsCalenderWrapper>
      <CalenderTotalSummaryFooter />
    </Fragment>
  );
};
