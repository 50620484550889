import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getAuth = (state: IGlobalReduxStore) => state.auth;

export const customFieldFeatureEnabledSelector = createSelector([getAuth], (auth) => {
  return auth?.permissions &&
    auth.permissions?.CUSTOM_FIELDS &&
    auth.permissions.CUSTOM_FIELDS.communities
    ? Object.keys(auth.permissions.CUSTOM_FIELDS.communities)
    : [];
});
