import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { getHourMinuteSecond } from 'utils/time';

interface State {
  startDate: string[];
  startTime: string[];
  endDate: string[];
  endTime: string[];
}

export const timeOffValidation = {
  END_TIME_EARLIER_THAN_START: 'End time cannot be earlier than start time.',
  DURATION_MUST_BE_MINIMUM_1H: 'Duration must be minimum of 1 hour.',
};

export const createDateWithHourAndMinute = (
  arraySourceDate: string[],
  arrayHourAndMinute: string[],
) => {
  const [sourceDate] = arraySourceDate;
  const [hourAndMinute] = arrayHourAndMinute;

  const [hour, minute, second = 0]: number[] = getHourMinuteSecond(hourAndMinute);
  const { timeZone } = getCurrentlySelectedLocation();
  return DateTime.fromISO(sourceDate)
    .setZone(timeZone)
    .set({ hour: hour, minute: minute, second: second })
    .toString();
};

export const VALIDATION_endETS: UseFormBehaviors_ValidationFnType<State> = (
  value,
  { endDate, endTime, startDate, startTime },
  errorMsg = ' ',
) => {
  if (!value) return '';

  const start = createDateWithHourAndMinute(startDate, startTime);
  const end = createDateWithHourAndMinute(endDate, endTime);

  return DateTime.fromISO(end) < DateTime.fromISO(start) ? errorMsg : '';
};

export const VALIDATION_durationMin1Hr: UseFormBehaviors_ValidationFnType<State> = (
  value,
  { endDate, endTime, startDate, startTime },
) => {
  if (!value) return '';

  const start = createDateWithHourAndMinute(startDate, startTime);
  const end = createDateWithHourAndMinute(endDate, endTime);

  return Math.abs(DateTime.fromISO(start).diff(DateTime.fromISO(end), 'hour').hours) < 1
    ? timeOffValidation.DURATION_MUST_BE_MINIMUM_1H
    : '';
};

export const VALIDATION_without_msg: UseFormBehaviors_ValidationFnType<State> = (value, rest) =>
  VALIDATION_endETS(value, rest);

export const VALIDATION_endDate: UseFormBehaviors_ValidationFnType<State> = (value, rest) =>
  VALIDATION_endETS(value, rest, timeOffValidation.END_TIME_EARLIER_THAN_START);
