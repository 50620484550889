import { AGENCY_SECONDARY_USERS } from 'constants/endpoints';
import { AdministratorSettingsLocationFormFields } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_PUT_updateAgencySecondaryDetailsLocation = (
  id: string,
  payload: AdministratorSettingsLocationFormFields,
): ApiRequestConfigType => {
  const communities = payload.communities.filter((item) => item.isIncluded).map((item) => item.id);

  return {
    __typename: 'LegacyAPI',
    method: METHOD_PUT,
    url: `${AGENCY_SECONDARY_USERS}/${id}`,
    body: {
      agency_user: {
        community_ids: communities,
      },
    },
  };
};
