import { FC, Fragment } from 'react';
import { QuerySwitch } from 'components/QuerySwitch';
import { GQLAPIContextType } from 'types/common.types';
import { parseDate } from 'utils';
import { dashboardRecentlyCreatedShiftsPage } from 'types/graphql-types';
import {
  DashboardPageDateGroupText,
  DashboardPageDateGroupWrapper,
} from 'pages/DashboardPage/DashboardPage.styled';
import { useTranslation } from 'hooks/useTranslation';
import { isDateToday } from 'utils/isDateToday';
import { DashboardEmptyView } from 'pages/DashboardPage/DashboardEmptyView';
import { DashboardPageContext } from 'pages/DashboardPage/DashboardPage.context';
import { MountTransition } from 'routes/MountTransition';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { GroupOrdersComponent } from 'pages/DashboardPage/GroupOrdersComponent';

const DashboardRecentlyCreatedShiftsPageList: FC<
  GQLAPIContextType<dashboardRecentlyCreatedShiftsPage>
> = ({ loadMore, data }) => {
  const { t } = useTranslation();
  if (!data) return null;
  if (!data?.fastGroupOrders) return null;
  if (!data?.fastGroupOrders?.nodes) return null;
  if (
    data?.fastGroupOrders?.nodes.filter((record) => {
      const { orders: _orders } = record;

      return _orders.filter(Boolean).length > 0;
    }).length === 0 &&
    !data?.fastGroupOrders?.pageInfo.hasNextPage
  )
    return <DashboardEmptyView />;
  return (
    <Fragment>
      {data?.fastGroupOrders?.nodes.map((record, groupIndex) => {
        const { group: date, orders: _orders } = record;

        const orders = _orders.filter(Boolean);
        return (
          orders?.length > 0 && (
            <DashboardPageDateGroupWrapper key={date}>
              <DashboardPageDateGroupText>
                {t('dashboard:CREATED')}&nbsp;
                {isDateToday(date) && `${t('dashboard:TODAY')} • `}
                {parseDate(date, 'dd, yyyy')}
              </DashboardPageDateGroupText>
              {orders.map((order, orderIndex) => {
                return (
                  <MountTransition key={order.id}>
                    <GroupOrdersComponent groupIndex={groupIndex} orderIndex={orderIndex} />
                  </MountTransition>
                );
              })}
            </DashboardPageDateGroupWrapper>
          )
        );
      })}
      {data.fastGroupOrders.pageInfo.hasNextPage && (
        <InfiniteScrollLoading onViewportEnter={loadMore} />
      )}
    </Fragment>
  );
};

export const DashboardRecentlyCreatedShiftsPage: FC = () => {
  return (
    <QuerySwitch
      context={DashboardPageContext}
      component={DashboardRecentlyCreatedShiftsPageList}
    />
  );
};
