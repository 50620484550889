export enum UserPermissionEnum {
  USER_TYPE_B2B = 'USER_TYPE_B2B',
  USER_TYPE_AGENCY = 'USER_TYPE_AGENCY',
  USER_TYPE_AGENCY_PRIMARY = 'USER_TYPE_AGENCY_PRIMARY',
  USER_TYPE_AGENCY_SECONDARY = 'USER_TYPE_AGENCY_SECONDARY',
  USER_TYPE_ESSENTIAL = 'USER_TYPE_ESSENTIAL',
  USER_TYPE_ESSENTIAL_PRIMARY = 'USER_TYPE_ESSENTIAL_PRIMARY',
  USER_TYPE_ESSENTIAL_SECONDARY = 'USER_TYPE_ESSENTIAL_SECONDARY',
  USER_TYPE_PREMIUM = 'USER_TYPE_PREMIUM',
  USER_TYPE_PREMIUM_PRIMARY = 'USER_TYPE_PREMIUM_PRIMARY',
  USER_TYPE_PREMIUM_SECONDARY = 'USER_TYPE_PREMIUM_SECONDARY',
  USER_TYPE_APPROVED = 'USER_TYPE_APPROVED',
  USER_TYPE_PENDING = 'USER_TYPE_PENDING',
}

export const SECONDARY_USER_PERMISSIONS = [
  UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
  UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
  UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
];

export const CUSTOMER_PERMISSIONS = [
  UserPermissionEnum.USER_TYPE_B2B,
  ...SECONDARY_USER_PERMISSIONS,
];

export type UserGuardType = keyof typeof UserPermissionEnum;

export type UserLevelType = 'B2bCustomer' | 'SecondaryUser' | 'AgencyOwner' | 'AgencyManager';

export type UserType = 'B2bCustomer' | 'SecondaryUser';

export type UserRoleType = 'essential' | 'premium';

export type J360UserType = 'Agency' | 'B2B';

export interface IUserGuardProps {
  userType: UserGuardType | UserGuardType[];
}
