import {
  isAvailabilityFeatureEnabledSelector,
  isPayrollFeatureEnabledSelector,
  isSchedulingFeatureEnabledSelector,
  isRecurringFeatureEnabledSelector,
  isTieringFeatureEnabledSelector,
  isAllFacilitiesFilterFeatureEnabledSelector,
  isYMCAReadOnlySecondarySelector,
  isCustomFieldsFeatureEnabledSelector,
  isSendToSelectedAgenciesEnabledSelector,
} from 'store/selectors/featureFlagSelectors';
import { IFeatureSelector } from 'guards/FeatureGuard.types';
import { isAgencyLocationFilteringEnabledSelector } from 'store/selectors/featureFlagSelectors/isAgencyLocationFilteringEnabledSelector';
import { isShiftDistributionFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isShiftDistributionFeatureEnabledSelector';
import { isShiftSwapFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isShiftSwapFeatureEnabledSelector';
import { isAccushieldSyncingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isAccushieldSyncingFeatureEnabledSelector';

export const FEATURE_SELECTORS_MAP: IFeatureSelector = {
  FEATURE_TYPE_AVAILABILITY: isAvailabilityFeatureEnabledSelector,
  FEATURE_TYPE_PAYROLL: isPayrollFeatureEnabledSelector,
  FEATURE_TYPE_SCHEDULING: isSchedulingFeatureEnabledSelector,
  FEATURE_TYPE_RECURRING: isRecurringFeatureEnabledSelector,
  FEATURE_TYPE_TIERING: isTieringFeatureEnabledSelector,
  FEATURE_TYPE_ALL_FACILITIES_FILTER: isAllFacilitiesFilterFeatureEnabledSelector,
  FEATURE_TYPE_SEND_TO_SELECTED_AGENCY: isSendToSelectedAgenciesEnabledSelector,
  FEATURE_TYPE_AGENCY_LOCATION_FILTERING: isAgencyLocationFilteringEnabledSelector,
  FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER: isYMCAReadOnlySecondarySelector,
  FEATURE_TYPE_CUSTOM_FIELDS: isCustomFieldsFeatureEnabledSelector,
  FEATURE_TYPE_VACCINATION: isShiftDistributionFeatureEnabledSelector,
  FEATURE_TYPE_SHIFT_SWAP: isShiftSwapFeatureEnabledSelector,
  FEATURE_TYPE_ACCUSHIELD_SYNCING: isAccushieldSyncingFeatureEnabledSelector,
};
