import {
  ChangeEventType,
  ChangeFunctionType,
} from '@bookjane2/bookjane-design-library/lib/common.types';
import { AutoMargin } from 'components/AutoMargin';
import { BKJDaysOfWeekInput } from 'components/BKJDaysOfWeekInput';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { ShiftTimeAndPosition } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/components/ShiftTimeAndPosition/ShiftTimeAndPosition';
import {
  InnerHeaderWrapper,
  MainTitle,
  ModalFooterButton,
  ModalSection,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/CreateShiftModal.styled';
import { shiftRecurringIntervalOptions } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RecurringView/RecurringView.constants';
import {
  RecurringModalFormLabel,
  SelectFormGroup,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RecurringView/RecurringView.styled';
import { ShiftRecurringFrequencySelectInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RecurringView/ShiftRecurringFrequencySelectInput';
import { ShiftRecurringIntervalSelectionInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RecurringView/ShiftRecurringIntervalSelectInput';
import { useContext, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { FieldNameType } from 'types/common.types';
import { WeekdaysEnum } from 'types/graphql-types';
import { castStringToInteger } from 'utils';
import { deepEquals } from 'utils/deepEquals';
import { DateTime } from 'luxon';
import { BKJIcon } from '@bookjane2/bookjane-design-library';

export const RecurringView = () => {
  const { t } = useTranslation();
  const { saveAndCloseModal, createShift } = useContext(CreateShiftCardContext);
  const { values } = createShift;
  const { startDate } = values;
  const startDateTime = DateTime.fromISO(startDate);

  const dayValue = startDateTime.toFormat('EEEE').toLowerCase() as keyof typeof WeekdaysEnum;

  const [modalValues, setModalValues] = useState({
    recurring_interval: values.recurring_interval[0] ? values.recurring_interval : ['1'],
    recurring_frequency: values.recurring_frequency[0] ? values.recurring_frequency : ['week'],
    recurring_weekday: values.recurring_weekday[0] ? values.recurring_weekday : [dayValue],
    recurring_expiration_date: values.recurring_expiration_date
      ? values.recurring_expiration_date
      : startDateTime.plus({ week: 1 }).toISO(),
  });
  const [dirtyFields, setDirtyFields] = useState<FieldNameType[]>(['recurring_weekday']);

  const handleChange: ChangeFunctionType = (e: ChangeEventType) => {
    const { target } = e;
    const {
      name,
      value,
    }: { name: keyof typeof modalValues; value: ChangeEventType['target']['value'] } = target;
    const _modalValues = { ...modalValues };
    setDirtyFields([...dirtyFields].concat([e.target.name]));
    if (name && Object.keys(_modalValues).includes(name)) {
      switch (name) {
        case 'recurring_interval': {
          _modalValues[name] = value;
          _modalValues['recurring_expiration_date'] = startDateTime
            .plus({
              [_modalValues.recurring_frequency[0]]: value[0],
            })
            .toISO();
          break;
        }
        case 'recurring_frequency': {
          _modalValues[name] = value;
          _modalValues['recurring_expiration_date'] = startDateTime
            .plus({ [value[0]]: Number.parseInt(_modalValues.recurring_interval[0], 10) })
            .toISO();
          _modalValues['recurring_weekday'] = value[0] === 'week' ? [dayValue] : [];
          break;
        }
        case 'recurring_weekday': {
          const _value = value
            .concat([dayValue])
            .filter(
              (value: string, index: number, array: string[]) => array.indexOf(value) === index,
            );
          _modalValues['recurring_weekday'] = _value;
          break;
        }
        default: {
          _modalValues[name] = value;
        }
      }
    }
    setModalValues(_modalValues);
  };

  const isSaveDisabled = (): boolean => {
    if (
      Object.keys(modalValues).some(
        (name) =>
          dirtyFields.includes(name) &&
          !deepEquals(
            modalValues[name as keyof typeof modalValues],
            values[name as keyof typeof modalValues],
          ),
      )
    ) {
      return false;
    }
    return true;
  };
  const handleSaveAndClose = (): void => {
    const events = Object.entries(modalValues).map(([name, value]) => {
      return {
        target: {
          name,
          value,
        },
      };
    });
    saveAndCloseModal(events);
  };

  const handleCancelRepeatAndClose = (): void => {
    const events = Object.entries(modalValues).map(([name, value]) => {
      return {
        target: {
          name,
          value: Array.isArray(value) ? [] : '',
        },
      };
    });
    saveAndCloseModal(events);
  };
  const recurringOpts = shiftRecurringIntervalOptions(modalValues.recurring_frequency[0]);
  return (
    <Flex width="100%" height="100%" flexDirection="column" justifyContent="space-between">
      <InnerHeaderWrapper>
        <MainTitle>{t('create_shifts:SET_REPEAT_OPTIONS')}</MainTitle>
        <ShiftTimeAndPosition />
        <ModalSection>
          <RecurringModalFormLabel>{t('create_shifts:REPEAT_EVERYDAY')}:</RecurringModalFormLabel>
          <Flex>
            <Flex width="30%">
              <ShiftRecurringIntervalSelectionInput
                options={recurringOpts}
                variant="GreyOutlined"
                name="recurring_interval"
                onChange={handleChange}
                value={modalValues.recurring_interval}
                width="100%"
              ></ShiftRecurringIntervalSelectionInput>
            </Flex>
            <Flex width="100%">
              <SelectFormGroup>
                <ShiftRecurringFrequencySelectInput
                  variant="GreyOutlined"
                  name="recurring_frequency"
                  value={modalValues.recurring_frequency}
                  onChange={handleChange}
                  width="100%"
                />
              </SelectFormGroup>
            </Flex>
          </Flex>
        </ModalSection>
        {modalValues.recurring_frequency[0] === 'week' && (
          <ModalSection>
            <RecurringModalFormLabel css="margin-bottom: 8px !important;">
              {t('create_shifts:REPEAT_ON')}:
            </RecurringModalFormLabel>
            <BKJDaysOfWeekInput
              name="recurring_weekday"
              value={modalValues.recurring_weekday}
              onChange={handleChange}
            />
          </ModalSection>
        )}

        <ModalSection>
          <RecurringModalFormLabel>{t('create_shifts:ENDS')}:</RecurringModalFormLabel>
          <ShiftDateCalendarInput
            width="100%"
            iconComponent={BKJIcon}
            iconProps={{
              iconName: 'ShiftsDate',
            }}
            dropdownVariant="Square"
            name="recurring_expiration_date"
            value={[`${modalValues.recurring_expiration_date}`]}
            dropdownProps={{
              disabledTo: `${startDateTime
                .plus({
                  [modalValues.recurring_frequency[0]]: castStringToInteger(
                    modalValues.recurring_interval[0],
                  ),
                })
                .toISO()}`,
              disabledFrom: DateTime.now().plus({ month: 3 }).toISO(),
            }}
            onChange={(e) => {
              if (!Array.isArray(e)) {
                e.target.value = e.target.value[0];
                handleChange(e);
              }
            }}
          />
        </ModalSection>
      </InnerHeaderWrapper>
      <BKJSideModalFooter>
        <ModalFooterButton variant="PurpleOutlined" onClick={handleCancelRepeatAndClose}>
          {t('create_shifts:CANCEL_REPEAT')}
        </ModalFooterButton>
        <AutoMargin />
        <ModalFooterButton
          onClick={handleSaveAndClose}
          variant="GreenSolid"
          disabled={isSaveDisabled()}
        >
          {t('auth:SAVE')}
        </ModalFooterButton>
      </BKJSideModalFooter>
    </Flex>
  );
};
