import {
  RadioItemWrapper,
  RadioWrapper,
  RadioText,
  HiddenRadioInput,
} from 'components/BKJRadioInput/BKJRadioInput.styled';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';

export interface IBKJRadioInputProps {
  name: string;
  value: string;
  disabled?: boolean;
  label?: string;
  checked: boolean;
  onChange: ChangeFunctionType;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  isFrenchFormat?: boolean;
}

export function BKJRadioInput(props: IBKJRadioInputProps) {
  const {
    name,
    value,
    disabled = false,
    label,
    checked,
    onChange,
    maxWidth,
    minWidth,
    width,
    isFrenchFormat,
  } = props;

  return (
    <RadioWrapper $maxWidth={maxWidth} $minWidth={minWidth} $width={width}>
      <RadioItemWrapper
        {...props}
        disabled={disabled}
        style={{ whiteSpace: isFrenchFormat && 'normal' }}
      >
        <HiddenRadioInput
          type="radio"
          name={name}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          value={value}
        />
        {!checked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
          >
            <path
              stroke="#8B96A3"
              d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
          >
            <path
              stroke="#8B96A3"
              d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z"
              clipRule="evenodd"
            />
            <path
              fill="#4E4785"
              fillRule="evenodd"
              d="M11 17a6 6 0 100-12 6 6 0 000 12z"
              clipRule="evenodd"
            />
          </svg>
        )}
        <RadioText>{label}</RadioText>
      </RadioItemWrapper>
    </RadioWrapper>
  );
}
