import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { useSelector } from 'react-redux';
import {
  API_GET_customerSecondaryAdmins,
  API_GET_agencySecondaryAdmins,
} from 'constants/requestOptions';
import { ApiRequestConfigType } from 'requests/index.types';

import {
  searchData,
  AdministratorSettingsResponse,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import { Communities } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { API_GET_customerCommunitiesV2 } from 'requests/GET_customerCommunitiesV2';
import { API_GET_agencyCommunitiesV1 } from 'requests/GET_agencyCommunitiesV1';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
export const AdministratorSettingsDataContext = createContext<AdministratorSettingsResponse>(
  [] as unknown as AdministratorSettingsResponse,
);

const mapCommunity = (community: Communities) => {
  return {
    id: community.id,
    shift_email: community.shift_emails,
    name: community.name,
  };
};

export const AdministratorSettingsDataProvider: FC = ({ children }) => {
  const [data, setData] = useState<AdministratorSettingsResponse>(
    {} as AdministratorSettingsResponse,
  );
  const [searchData, setSearchData] = useState<searchData[]>([]);
  const [communityIDs, setCommunityIDs] = useState<Communities[]>([]);
  const isUserAgency = useSelector(isUserAgencySelector);
  const { apiRequest } = useAPIActions();

  const getRequestOpts = isUserAgency
    ? API_GET_agencySecondaryAdmins
    : API_GET_customerSecondaryAdmins;
  const [isLoadingDefault, setIsLoadingDefault] = useState(false);
  const fetchData = useCallback(async () => {
    await apiRequest(getRequestOpts as ApiRequestConfigType, {
      onSuccess: (response) => {
        if (response?.data) {
          setData(
            isUserAgency
              ? ({
                  secondary_users: [
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...response.data.agency_managers.map((user: any) => ({
                      communities: user.agency_communities,
                      ...user,
                    })),
                  ],
                } as AdministratorSettingsResponse)
              : response.data,
          );
          setSearchData(
            isUserAgency
              ? response.data.agency_managers.map((search: searchData) => {
                  return {
                    id: search.id,
                    first_name: search.first_name,
                    last_name: search.last_name,
                    key: search.id,
                    value: search.id,
                  };
                })
              : response.data.secondary_users.map((search: searchData) => {
                  return {
                    id: search.id,
                    first_name: search.first_name,
                    last_name: search.last_name,
                    key: search.id,
                    value: search.id,
                  };
                }),
          );
        }
      },
      onError: handleGenericErrorMessage,
    });
  }, [apiRequest, getRequestOpts, isUserAgency]);

  const getCommunitiesRequestOpts = isUserAgency
    ? API_GET_agencyCommunitiesV1
    : API_GET_customerCommunitiesV2;

  const fetchCommunitiesData = useCallback(async () => {
    await apiRequest(getCommunitiesRequestOpts as ApiRequestConfigType, {
      onSuccess: (response) => {
        if (isUserAgency) {
          setCommunityIDs(response?.data.agency_communities.map(mapCommunity));
        } else {
          setCommunityIDs(response?.data.communities.map(mapCommunity));
        }
      },
      onError: handleGenericErrorMessage,
    });
  }, [apiRequest, getCommunitiesRequestOpts, isUserAgency]);

  const refetchData = useCallback(() => setIsLoadingDefault(false), []);

  useEffect(() => {
    if (!isLoadingDefault) {
      setIsLoadingDefault(true);
      fetchData();
      fetchCommunitiesData();
    }
  }, [fetchData, isLoadingDefault, fetchCommunitiesData]);

  const contextValues: AdministratorSettingsResponse = useMemo(() => {
    return {
      secondary_users: data.secondary_users,
      communities: communityIDs,
      searchData: searchData,
      refetchData,
    };
  }, [communityIDs, data.secondary_users, refetchData, searchData]);

  return (
    <AdministratorSettingsDataContext.Provider value={contextValues}>
      {children}
    </AdministratorSettingsDataContext.Provider>
  );
};

export const useAdministratorSettingsDataContext = () =>
  useContext<AdministratorSettingsResponse>(AdministratorSettingsDataContext);
