import { useMutation } from '@apollo/client';
import { UseGQLMutation, UseGQLMutationConfig } from 'hooks/useGQLMutation.ts/useGQLMutation.types';

export function useGQLMutation<T, K>({
  mutation,
  onSuccess,
  ...rest
}: UseGQLMutationConfig<T, K>): UseGQLMutation<T, K> {
  const [mutate, { loading: isLoading, ...restMutationResult }] = useMutation<T, K>(mutation, {
    ...rest,
    onCompleted: onSuccess,
  });

  return {
    mutate,
    isLoading,
    ...restMutationResult,
  };
}
