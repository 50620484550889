import styled from 'styled-components';

export const DetailTimeOffViewGeneralTabWrapper = styled.div`
  padding: 32px 40px;
  height: 100%;
`;

export const DetailTimeOffViewNoAdditionalDetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const DetailTimeOffViewAdditionalDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailTimeOffViewAdditionalDetailNameWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

export const DetailTimeOffViewAdditionalDetailWrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
`;
