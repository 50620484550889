import {
  ApplyFacilitiesFormSchema,
  CustomFieldType,
} from 'components/CustomFieldModal/CustomFieldModal.types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const CUSTOM_FIELDS_MODAL_DESCRIPTION = 'CUSTOM_FIELDS_MODAL_DESCRIPTION';
export const CUSTOM_FIELDS_MODAL_DROPDOWN_PLACEHOLDER = 'CUSTOM_FIELDS_MODAL_DROPDOWN_PLACEHOLDER';
export const CUSTOM_FIELDS_MODAL_FACILITIES_EMPTY_SEARCH =
  'CUSTOM_FIELDS_MODAL_FACILITIES_EMPTY_SEARCH';
export const CUSTOM_FIELDS_MODAL_ALL_FACILITIES = 'CUSTOM_FIELDS_MODAL_ALL_FACILITIES';
export const CUSTOM_FIELDS_MODAL_DROPDOWN_OPTIONS_PLACEHOLDER =
  'CUSTOM_FIELDS_MODAL_DROPDOWN_OPTIONS_PLACEHOLDER';

export const DropdownOptionLimit = 200;

export const whatsThisUrlRedirect = WhiteLabelAccessor.isAccushieldProvider
  ? 'https://flexaccushield.zendesk.com/hc/en-us/articles/12462163730580'
  : 'https://bookjane.zendesk.com/hc/en-us/articles/4417376090388';

export const CustomFieldInfoFormInitialState = {
  type: ['Single-Line Text'] as CustomFieldType[],
  id: 0,
  mobile: false,
  name: '',
  placeholder: '',
  preFillDefault: false,
  dropdownOptions: [],
};

export const CustomFieldFacilitiesFormInitialState: ApplyFacilitiesFormSchema = {
  allFacilities: false,
  communityIds: [],
};

export const SEARCH_FACILITY_INITIAL_STATE = {
  target: {
    name: 'byName',
    value: '',
  },
};
