import { BKJIcon, Flex } from '@bookjane2/bookjane-design-library';
import { AppLink } from 'components/AppLink/AppLink';
import { elementDimensions } from 'constants/elementDimensions';
import { zIndexLayers } from 'constants/zIndexLayers';
import { MutableRefObject, Ref, RefObject } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const {
  DesktopPageHeaderHeight,
  MobilePageHeaderHeight,
  OpenSettingsNavigationWidth,
  ClosedSettingsNavigationWidth,
  SetupBanner,
} = elementDimensions;
const openDuration = '200ms';

interface IToggleSettingsNavIcon {
  $isOpen: boolean;
}
export const ToggleSettingsNavIcon = styled(BKJIcon)<IToggleSettingsNavIcon>`
  background-color: white;
  box-shadow: 0px 2px 8px 0px #0d33204d;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 56px;
  right: -12px;
  transition: left ${openDuration} ease-in-out, right ${openDuration} ease-in-out;
  z-index: ${zIndexLayers.settingsNavigation};
`;

interface SettingsNavWrapper {
  ref?: Ref<HTMLDivElement>;
  $isOpen: boolean;
  id: string;
  isPendingApproval: boolean;
}

export const SettingsNavigationWrapper = styled.div<SettingsNavWrapper>`
  ${({ $isOpen, theme, isPendingApproval }) => {
    return css`
      z-index: ${zIndexLayers.settingsNavigation};
      position: relative;
      transition: width ${openDuration} ease-in-out;
      background-color: ${theme.colors.White};
      height: calc(100vh - ${DesktopPageHeaderHeight + (isPendingApproval ? SetupBanner : 0)}px);
      max-height: calc(
        100vh - ${DesktopPageHeaderHeight + (isPendingApproval ? SetupBanner : 0)}px
      );
      min-width: ${$isOpen ? OpenSettingsNavigationWidth : ClosedSettingsNavigationWidth}px;
      width: ${$isOpen ? OpenSettingsNavigationWidth : ClosedSettingsNavigationWidth}px;
      @media (max-width: ${theme.breakpoints.Tablet}) {
        height: calc(100vh - ${MobilePageHeaderHeight}px);
        max-height: calc(100vh - ${MobilePageHeaderHeight}px);
      }
      position: fixed;
      bottom: 0;
      left: 80px;

      @media (max-width: 768px) {
        left: 0;
      }
      ${theme.shadows.Default};
    `;
  }}
`;

export const SettingsNavItemWrapper = styled.div<{ $isOpen: boolean; $isAccordionOpen?: boolean }>`
  height: 57px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  transition: opacity ${openDuration} ease-in-out 0s;
  animation-delay: ${openDuration};
  background-color: ${({ theme }) => theme.colors.Grey1};
`;

export const SettingsNavItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  height: 100%;
  align-items: center;
  ${({ theme }) => theme.typography.Caption};
  font-weight: 400;
  white-space: pre;

  &:hover {
    background-color: ${({ theme }) => theme.colors.PrimaryDefault};
    color: ${({ theme }) => theme.colors.White};
  }
`;

export const SettingsInternalMenuLink = styled(NavLink)<{ $borderTop?: string }>`
  display: block;
  border-top: 1px solid ${({ theme }) => theme.colors.Grey5};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  text-decoration: none;
  &.active {
    ${SettingsNavItemWrapper} {
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
      color: ${({ theme }) => theme.colors.White};
    }
  }

  &:first-child {
    border-top: none !important;
  }
  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
  }
  ${({ $borderTop }) =>
    $borderTop &&
    css`
      border-top: ${$borderTop};
    `};
`;

interface IMenuAccordion {
  $isOpen: boolean;
  $isExpanded: boolean;
  numMenuItems: number;
  ref?: RefObject<HTMLUListElement> | MutableRefObject<HTMLUListElement>;
}

export const MenuAccordion = styled.ul<IMenuAccordion>`
  position: relative;

  background-color: ${({ theme }) => theme.colors.White};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: overlay;

  height: fit-content;
  max-height: calc(100% - ${({ numMenuItems }) => numMenuItems * 57}px);
  transition: height 250ms ease-in-out;
  will-change: height;
  ${({ $isExpanded }) =>
    !$isExpanded &&
    css`
      height: 0px;
    `}
`;

export const AddNewButton = styled.button`
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  padding: 0 18px;
  height: 57px;
  min-height: 57px;
  max-height: 57px;
  border: none;
  ${({ theme }) => theme.fonts.PoppinsBold};
  gap: 6px;
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  background-color: ${({ theme }) => theme.colors.Transparent};
`;

export const AccordionItem = styled.li<{ active?: boolean }>`
  height: 57px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  background-color: ${({ theme }) => theme.colors.White};

  &:hover {
    ${({ active }) =>
      !active &&
      css`
        span {
          &::after {
            transition: min-width 500ms ease-out, background-color 600ms linear;
            height: 2px;
            min-width: 35%;
            background-color: ${({ theme }) => theme.colors.Grey5};
          }
        }
      `};
  }
  span {
    &::after {
      will-change: min-width, background-color;
      transition: min-width 200ms ease-out, background-color 300ms linear;
      content: '';
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      bottom: 0px;
      min-width: 0px;
      height: 2px;
      background-color: #00c18c00;
      border-radius: 3px;
    }
  }
  ${({ active }) =>
    active &&
    css`
      span {
        &::after {
          height: 2px;
          min-width: 100%;
          background-color: ${({ theme }) => theme.colors.AccentGreen};
        }
        cursor: unset;
      }
    `};
`;

export const AccordionText = styled.span`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 24px;
`;

export const LocationSearchInputWrapper = styled(Flex)<{
  isTopStickied: boolean;
  isAdjustForReadOnly: boolean;
}>`
  position: sticky;
  top: ${({ isTopStickied, isAdjustForReadOnly }) =>
    isAdjustForReadOnly ? '0' : isTopStickied ? '15px' : '57px'};
  padding: 8px 16px;
  /* padding-top: ${({ isTopStickied }) => (isTopStickied ? '18px' : '0px')};
  padding-bottom: ${({ isTopStickied }) => (isTopStickied ? '18px' : '0px')}; */
  left: 0;
  background-color: ${({ theme }) => theme.colors.White};
  z-index: 1;
`;

export const AddNewLinkWrapper = styled(AppLink)<{ isActive: boolean }>`
  top: 0;
  left: 0;
  position: sticky;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.Grey3 : theme.colors.White};
  z-index: 1;
`;
