import {
  SCHEDULING_PUBLISHED_SHIFT_AVAILABILITY,
  EXPORT_CSV_AGENCY_SHIFT_AVAILABILITY,
} from 'constants/endpoints';
import { METHOD_GET } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { SessionService } from 'services';

export const GET_shiftModalTeamMemberAvailability = ({
  shift_id,
  data = '',
}: {
  shift_id: string;
  data: string;
}): ApiRequestConfigType => {
  const query: { data?: string } = {};

  if (data) query.data = data;

  return {
    __typename: 'LegacyAPI',
    url: SessionService.assertUserType('Agency')
      ? EXPORT_CSV_AGENCY_SHIFT_AVAILABILITY(shift_id)
      : SCHEDULING_PUBLISHED_SHIFT_AVAILABILITY(shift_id),
    method: METHOD_GET,
    namespace: 'ShiftModalTeamMemberAvailability',
    query,
  };
};
