import { EditViewFormType } from 'components/ShiftModal/src/views/EditView/EditView.context';
import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import i18next from 'i18next';
import { calculateShiftDurationInMinutes } from 'utils/time';

export const VALIDATION_durationMin1Hr: UseFormBehaviors_ValidationFnType<EditViewFormType> = (
  value,
  { end_time, start_time, unpaid_break_time, zeroHoursWorked },
) => {
  if (!value || !!zeroHoursWorked) return '';
  const errorMessage = i18next.t('errors:MINIMUM_SHIFT_DURATION_ERROR');

  return calculateShiftDurationInMinutes({
    startAt: start_time[0],
    endAt: end_time[0],
    unpaidBreakTime: unpaid_break_time[0],
    isPaidDuration: true,
  }) < 60
    ? errorMessage
    : '';
};

export const VALIDATION_durationMin1HrNoMessage: UseFormBehaviors_ValidationFnType<EditViewFormType> =
  (value, formValues) => (!!VALIDATION_durationMin1Hr(value, formValues) ? ' ' : '');
