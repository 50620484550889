import { QUERY_positionNameLabel } from 'queries';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const positionNameLabelQueryConfig = (position_id: number): any => ({
  key: 'POSITION_NAME_QUERY',
  query: QUERY_positionNameLabel,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseTransformer: (value: any) => value,
  variables: {
    id: position_id,
  },
});
