import { Flex } from 'components/Flex';
import { IGridProps } from 'components/Grid/Grid.types';
import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export const BKJTableRowInfoWrapper = styled.div`
  display: contents;

  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.White};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.Grey1};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey3};
  }
`;

export const BKJTableRowWrapper = styled.div`
  display: contents;
  background-color: inherit;

  cursor: pointer;
`;

export const BKJTableData = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  min-width: max-content;
  width: 100%;
  height: 72px;
  background-color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};

  &:nth-child(1) {
    padding-left: 50px;
  }
`;

export const TeamMemberGroupIconWrapper = styled.div`
  margin: 0 auto 0 4px;
`;

export const BKJStickyTableData = styled(BKJTableData)`
  position: sticky;
  right: 0;
  top: unset;
  ${({ theme }) => theme.shadows.Default};
`;

export const Label = styled.label`
  display: none;
`;

export const TableFlex = styled(Flex)`
  ${({ theme }) => theme.common.Ellipsis};
`;

interface TextProps extends HTMLAttributes<HTMLSpanElement> {}

export const Text = styled.span<TextProps>`
  ${({ theme }) => theme.fonts.PoppinsText}
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.common.Ellipsis};
`;
export const BKJEllipsisTableData = styled(BKJTableData)`
  min-width: unset;
`;
export const BKJEllipsisName = styled(Text)`
  width: 140px;
`;
export const Grid = styled.div<IGridProps>`
  display: grid;
  grid-auto-rows: min-content;

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      grid-template-columns: repeat(${gridTemplateColumns}, 1fr);
    `}
`;
