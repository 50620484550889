import {
  DedicatedTeamTableData,
  DedicatedTeamTableRowWrapper,
} from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamTable/DedicatedTeamTableRow.styled';
import { teamMemberPage_fetchJanes_edges_node } from 'types/graphql-types';
import { Label, TableFlex, Text } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { TeamMemberAvatar } from 'pages/TeamMemberPage/components/TeamMemberAvatar';

export function DedicatedTeamTableRow(props: teamMemberPage_fetchJanes_edges_node) {
  const { id, firstName, lastName, primaryPosition, positions } = props;
  return (
    <DedicatedTeamTableRowWrapper
      key={id}
      onClick={() => window.alert('Clicking this should open the Team Member Sidepanel')}
    >
      <DedicatedTeamTableData>
        <Label>Name</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <TeamMemberAvatar {...props} />
          <Text>{`${firstName} ${lastName}`}</Text>
        </TableFlex>
      </DedicatedTeamTableData>
      <DedicatedTeamTableData>
        <Label>Agency</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <Text>{`${firstName} ${lastName}`}</Text>
        </TableFlex>
      </DedicatedTeamTableData>
      <DedicatedTeamTableData>
        <Label>Position</Label>
        <TableFlex flexDirection="column">
          <Text>
            {primaryPosition?.details?.name}
            <br />
            {positions.length > 0 && `+ ${positions.length} more`}
          </Text>
        </TableFlex>
      </DedicatedTeamTableData>
      <DedicatedTeamTableData>
        <Label>App ID</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <Text>{`${firstName} ${lastName}`}</Text>
        </TableFlex>
      </DedicatedTeamTableData>
      <DedicatedTeamTableData>
        <Label>Status</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <Text>{`${firstName} ${lastName}`}</Text>
        </TableFlex>
      </DedicatedTeamTableData>
      <DedicatedTeamTableData>
        <Label>Action</Label>
        <TableFlex flexDirection="row" alignItems="center">
          <BKJButton
            variant="PurpleText"
            onClick={() => window.alert('Clicking on this should trigger add to dedicated flow')}
          >
            Add to Dedicated
          </BKJButton>
        </TableFlex>
      </DedicatedTeamTableData>
    </DedicatedTeamTableRowWrapper>
  );
}
