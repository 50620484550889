import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { getConfirmAvailabilityQueryConfig } from 'components/ShiftModal/src/views/DetailView/ConfirmAvailabilityModal/ConfirmAvailabilityModal.constants';
import { useGQLQuery } from 'hooks';
import { FC, useContext, useMemo } from 'react';
import { fetchOrderCanAssign, fetchOrderCanAssignVariables } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

interface ICanAssignValidation {
  validateAndExecute: () => void;
}

export const CanAssignValidationContext = createGQLDataContext<ICanAssignValidation>(
  {} as ICanAssignValidation,
);

export const CanAssignValidationProvider: FC = ({ children }) => {
  const {
    data: {
      fetchOrder: { id: orderId },
    },
  } = useShiftModalDataContext();
  const { fetch } = useGQLQuery<
    fetchOrderCanAssign,
    fetchOrderCanAssign,
    fetchOrderCanAssignVariables
  >(
    getConfirmAvailabilityQueryConfig({
      orderId,
      onSuccess: (data: fetchOrderCanAssign) => {
        if (data.fetchOrder.canAssign) {
          shiftModalRoutingService.goToView('AssignToAgencyTeamMemberView');
        } else {
          shiftModalRoutingService.goToView('ShiftUnavailableView');
        }
      },
    }),
  );
  const contextValue = useMemo<ICanAssignValidation>(
    () => ({ validateAndExecute: fetch }),
    [fetch],
  );

  return (
    <CanAssignValidationContext.Provider value={contextValue}>
      {children}
    </CanAssignValidationContext.Provider>
  );
};

export const useCanAssignValidationContext = (): ICanAssignValidation =>
  useContext(CanAssignValidationContext);
