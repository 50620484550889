import { FC, useContext, useEffect, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import { continueCalloutDialog, continueCalloutDialogVariables } from 'types/graphql-types';
import { GQLAPIContextType } from 'types/common.types';
import { continueCalloutDialogConfig } from 'components/ContinueCalloutDialog/ContinueCalloutDialog.constants';

export const ContinueCalloutDialogDataContext =
  createGQLDataContext<GQLAPIContextType<continueCalloutDialog>>();

export const ContinueCalloutDialogDataProvider: FC<{ orderId: number }> = ({
  children,
  orderId,
}) => {
  const { data, fetch, ...restQuery } = useGQLQuery<
    continueCalloutDialog,
    continueCalloutDialog,
    continueCalloutDialogVariables
  >(continueCalloutDialogConfig(orderId));

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  const contextValues = useMemo(() => {
    return {
      calloutListId: data?.fetchOrder?.currentlyActiveCalloutList?.id,
      isAllowOvertime: !!data?.fetchOrder?.allowOvertime,
      nextGroupName:
        data?.fetchOrder?.currentlyActiveCalloutList?.manualApprovalIndexes?.[0]?.groupName,
      data,
      fetch,
      ...restQuery,
    };
  }, [data, fetch, restQuery]);

  return (
    <ContinueCalloutDialogDataContext.Provider value={contextValues}>
      {children}
    </ContinueCalloutDialogDataContext.Provider>
  );
};

export const useContinueCalloutDialogDataContext = (): GQLAPIContextType<continueCalloutDialog> =>
  useContext(ContinueCalloutDialogDataContext);
