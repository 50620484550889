import { QuerySwitch } from 'components/QuerySwitch';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useAssignToAgencyTeamMemberFormContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import { FetchAgencyTeamMemberAvailabilityByOrderIdDataProvider } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMemberAvailabilityByOrderId.data.context';
import {
  FetchAgencyTeamMemberByIdDataContext,
  FetchAgencyTeamMemberByIdDataProvider,
} from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMemberById.data.context';
import { AssignToAgencyTeamMemberSummaryViewComponent } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/AssignToAgencyTeamMemberSummaryView/AssignToAgencyTeamMemberSummaryView.component';
import { FC, FormEvent } from 'react';
import { assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId } from 'types/graphql-types';

export const AssignToAgencyTeamMemberSummaryView: FC = () => {
  const { values, onSubmit } = useAssignToAgencyTeamMemberFormContext();
  const { data } = useShiftModalDataContext();

  /**
   * Done through onSuccess function passed into this validation query
   * -----------------------------------------------------------------
   * Upon successful validation of shift and team member availability, shift will be assigned and flow will be complete
   * Upon failed validation of shift and team member availability, user will be redirected to the unavailable view.
   */
  const onSuccess = (
    result: assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  ) => {
    if (result.fetchOrder.canAssign && result.requestJanes.nodes.length > 0)
      onSubmit({} as FormEvent<HTMLFormElement>);
    else shiftModalRoutingService.goToView('TeamMemberUnavailableView');
  };

  return (
    <FetchAgencyTeamMemberAvailabilityByOrderIdDataProvider
      onSuccess={onSuccess}
      janeId={values.janeId}
      orderId={data.fetchOrder.id}
      orderRelayId={data.fetchOrder.relayId}
    >
      <FetchAgencyTeamMemberByIdDataProvider>
        <QuerySwitch
          component={() => <AssignToAgencyTeamMemberSummaryViewComponent />}
          context={FetchAgencyTeamMemberByIdDataContext}
        />
      </FetchAgencyTeamMemberByIdDataProvider>
    </FetchAgencyTeamMemberAvailabilityByOrderIdDataProvider>
  );
};
