import { DropdownOptionType, InputNameType } from 'types/common.types';
import { assertIsInputNameType } from 'utils/assertIsInputNameType';
import { parseTitle } from 'utils/parseTitle';

export const castEnumToDropdownItems = (
  enumType: Record<string, string>,
  name: InputNameType,
): DropdownOptionType[] => {
  assertIsInputNameType(name);

  return Object.values(enumType).map((str) => ({
    label: parseTitle(str, '_'),
    value: str,
    type: 'Standard',
    name,
    key: str,
  }));
};
