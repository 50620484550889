import {
  IMappedSchedulingHours,
  ISchedulingHoursResponse,
} from 'mappers/scheduling/schedule.rest.types';

function GET_schedulingHourMapper(apiResponseData = { scheduling_hours: {} }) {
  const data = {
    ...apiResponseData,
  } as { scheduling_hours: IMappedSchedulingHours };
  const {
    scheduling_hours: {
      hours_by_day,
      hours_by_shift_type: { hours_by_jane },
    },
  } = apiResponseData as ISchedulingHoursResponse;

  const janeHours = hours_by_jane.reduce((obj, jane) => {
    return {
      ...obj,
      [jane.jane_id]: jane,
    };
  }, {});

  const daysHours = hours_by_day.reduce((obj, day) => {
    return {
      ...obj,
      [day.date]: day,
    };
  }, {});

  data.scheduling_hours.hours_by_day = daysHours;
  data.scheduling_hours.hours_by_shift_type.hours_by_jane = janeHours;

  return data;
}

export default GET_schedulingHourMapper;
