import { BKJListItem, BKJSelect } from '@bookjane2/bookjane-design-library';
import { FC, Fragment } from 'react';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';
import { deleteViewReasonSelectOptions } from './DeleteViewReasonSelect.constants';
import {
  IDeleteShiftReasonOption,
  IDeleteShiftReasonSelectProps,
  IDeleteShiftReasonSelectRenderGroupProps,
} from './DeleteViewReasonSelect.types';

const RenderGroup = ({
  name,
  selected,
  options,
  onChange,
}: IDeleteShiftReasonSelectRenderGroupProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      {options.map(({ key, label, value }: IDeleteShiftReasonOption) => {
        const labelWithoutLeadingSpace = label.trimStart(); //to remove leading spaces
        const translatedLabel = getTranslatedResponse(labelWithoutLeadingSpace, 'status', i18n, t);
        return (
          <BKJListItem
            key={key}
            value={value as string}
            label={translatedLabel}
            name={name}
            selected={selected}
            onChange={onChange}
          />
        );
      })}
    </Fragment>
  );
};

export const DeleteShiftReasonSelect: FC<IDeleteShiftReasonSelectProps> = ({
  name,
  placeholder,
  variant = 'GreyOutlined',
  width,
  dropdownWidth,
  value,
  onChange,
  iconComponent,
  iconProps,
  ...props
}) => (
  <BKJSelect
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {...(props as any)}
    options={deleteViewReasonSelectOptions}
    placeholder={placeholder}
    type="Single"
    variant={variant}
    width={width}
    name={name}
    dropdownWidth={dropdownWidth}
    dropdownPlacement="bottom-start"
    value={value}
    onChange={onChange}
    renderOptions={RenderGroup}
    iconComponent={iconComponent}
    iconProps={iconProps}
  />
);
