import { FC, SyntheticEvent, useCallback } from 'react';
import { searchTeamMembers_fetchJanes_edges_node } from 'types/graphql-types';
import { BKJAvatar, BKJCheckbox } from '@bookjane2/bookjane-design-library';
import { JaneSelect } from 'pages/SettingsPage/modals/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.styled';
import { ListItemWrapper } from '@bookjane2/bookjane-design-library/lib/components/BKJListItem/BKJListItem.styled';
import { Flex } from 'components/Flex';

interface ITeamMemberOption {
  node: searchTeamMembers_fetchJanes_edges_node;
  value: string;
  name: string;
  isChecked: boolean;
  onSelect: (e: unknown) => void;
}

export const TeamMemberOption: FC<ITeamMemberOption> = ({
  node,
  value,
  isChecked,
  name,
  onSelect,
}) => {
  const fullName = `${node?.firstName} ${node?.lastName}`;

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault && event.preventDefault();
      onSelect({
        ...event,
        target: {
          ...event.target,
          node: { ...node },
          isChecked,
        },
      });
    },
    [isChecked, node, onSelect],
  );

  return (
    <JaneSelect key={node.id} alignItems="center" selected={false}>
      <ListItemWrapper
        variant="RowReverseSpaceBetween"
        role="listbox"
        onClick={handleClick}
        checked={isChecked}
      >
        <Flex width="100%" flexDirection="row" alignItems="center" gap="8px;">
          <BKJCheckbox variant="GreenSolid" value={isChecked} />
          <BKJAvatar
            src={node.avatarUrls.small}
            width="24px"
            height="24px"
            fallbackAvatarColor="Grey4"
            showPendingSignUp={node?.pendingSignup}
          />
          <span>{fullName}</span>
        </Flex>
      </ListItemWrapper>
    </JaneSelect>
  );
};
