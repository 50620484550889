import { DeleteViewFormSchema } from 'components/ShiftModal/src/views/DeleteView/DeleteView.types';
import { SCHEDULING_CANCEL_PUBLISH_SHIFT } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from './index.types';
export const API_POST_cancelPublishedShiftsById = (
  params: DeleteViewFormSchema & { shiftId: number },
): ApiRequestConfigType => {
  // TODO
  const { cancellationReason, specifiedOtherReason, cancelRecurring, shiftId } = params;

  const body = {
    cancel: {
      reason_message: specifiedOtherReason,
      reason_category: cancellationReason[0],
      cancel_recurring: cancelRecurring,
    },
  };

  return {
    __typename: 'LegacyAPI',
    url: SCHEDULING_CANCEL_PUBLISH_SHIFT(shiftId.toString()),
    method: METHOD_POST,
    body,
  };
};
