import { useCallback, useRef } from 'react';
import { SessionService } from 'services';

export function parseJson(value: string | null) {
  try {
    if (typeof value !== 'string') return undefined;
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
}

export function usePersistentRef<T>(
  valueIfCacheUndefined: T,
  key: string,
  storageType: 'localStorage' | 'sessionStorage' = 'localStorage',
): readonly [T, (nextValue: T) => void] {
  const uniqueKey = `${SessionService.getUserId()}-${key}`;

  const getValue = useCallback((): T => {
    const savedValue = window[storageType].getItem(uniqueKey);

    if (savedValue === null) {
      window[storageType].setItem(uniqueKey, JSON.stringify(valueIfCacheUndefined));

      return valueIfCacheUndefined;
    } else {
      return parseJson(savedValue);
    }
  }, [storageType, uniqueKey, valueIfCacheUndefined]);

  const valueRef = useRef(getValue());

  const mutateValue = useCallback(
    (nextValue) => {
      valueRef.current = nextValue;
      window[storageType].setItem(uniqueKey, JSON.stringify(nextValue));
    },
    [storageType, uniqueKey],
  );

  return [valueRef.current, mutateValue];
}
