import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

interface ITypographyProps {
  color?: BKJThemeColorType;
}

export const H1 = styled.h1<ITypographyProps>`
  ${({ theme }) => theme.typography.H1};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const H2 = styled.h2<ITypographyProps>`
  ${({ theme }) => theme.typography.H2};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const H3 = styled.h3<ITypographyProps>`
  ${({ theme }) => theme.typography.H3};
  color: ${({ theme, color = 'AccentPurple' }) => color && theme.colors[color]};
`;
export const H4 = styled.h4<ITypographyProps>`
  ${({ theme }) => theme.typography.H4};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const Body = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const BodyUnderline = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.BodyUnderline};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const Bold = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.Bold};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const Caption = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const CaptionBold = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.CaptionBold};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const Button = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.Button};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;
export const TextLink = styled.span<ITypographyProps>`
  ${({ theme }) => theme.typography.TextLink};
  color: ${({ theme, color = 'TextDarkGrey' }) => color && theme.colors[color]};
`;

export const P = styled.span<{ bold?: boolean; error?: boolean }>`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.ErrorDefault};
    `};
  ${({ bold = false, theme }) => (bold ? theme.fonts.PoppinsTextBold : theme.fonts.PoppinsText)};
`;
