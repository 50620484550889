import { Fragment } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useDetailTabViewPropsContext } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.props.context';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useURLParameters } from 'hooks';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { getShiftBiddingStatus } from 'utils/shiftStatus';
import { IAuditTrailFooterProps } from 'components/ShiftModal/src/views/DetailView/AuditTrailDetailView/AuditTrail.types';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { MobileAuditTrailDetailTabViewFooter } from 'components/ShiftModal/src/views/DetailView/AuditTrailDetailView/MobileAuditTrailDetailTabViewFooter';
import { WebAuditTrailDetailTabViewFooter } from 'components/ShiftModal/src/views/DetailView/AuditTrailDetailView/WebAuditTrailDetailTabViewFooter';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export function AuditTrailDetailTabViewFooter() {
  const { data } = useShiftModalDataContext();

  const {
    goToDeleteView,
    goToEditView,
    goToReleaseShiftView,
    goToSendToAgenciesView,
    goToAwardTeamMemberView,
  } = useShiftModalPropsContext();

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [{ agencyCommunityId }] = useURLParameters<{ agencyCommunityId?: string }>();

  const { openConfirmAvailabilityModal } = useDetailTabViewPropsContext();

  const biddingStatus = getShiftBiddingStatus({
    status: data.fetchOrder.status.value,
    biddable: data.fetchOrder.biddable,
    biddingTimeEndsAt: data.fetchOrder.biddingTimeEndsAt,
    bidders: data.fetchOrder.bidders,
  });

  const props: IAuditTrailFooterProps = {
    data,
    goToDeleteView,
    goToEditView,
    goToReleaseShiftView,
    goToSendToAgenciesView,
    goToAwardTeamMemberView,
    openConfirmAvailabilityModal,
    agencyCommunityId,
    isUserAgencyAndAgencyLocationFilterForcedDisabled,
    biddingStatus,
  };

  const { isMobile } = useResponsive();
  const AuditTrailDetailTabViewFooterComponent = isMobile
    ? MobileAuditTrailDetailTabViewFooter
    : WebAuditTrailDetailTabViewFooter;

  if (isReadOnly || data?.fetchOrder?.isAway) return <Fragment />;
  return <AuditTrailDetailTabViewFooterComponent {...props} />;
}
