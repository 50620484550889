import { BKJSideModal } from 'components/BKJSideModal';
import { FetchCommunitiesDataProvider } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import { FetchCommunitiesCountDataProvider } from 'components/CustomFieldModal/Contexts/FetchCommunitiesCount.data.context';
import { FC, useMemo } from 'react';
import { withMemo } from 'utils/withMemo';
import {
  customFieldCreateModalRoutingService,
  CustomFieldCreateModalUIProvider,
  useCustomFieldCreateModalUIContext,
} from 'components/CustomFieldCreateModal/contexts/CustomFieldCreateModal.ui.context';
import {
  CustomFieldCreateModalPropsProvider,
  useCustomFieldCreateModalPropsContext,
} from 'components/CustomFieldCreateModal/contexts/CustomFieldCreateModal.props.context';
import { CustomFieldCreateModalFormProvider } from 'components/CustomFieldCreateModal/contexts/CreateCustomField.form.context';
import {
  CustomFieldCreateModalViewMap,
  getHeaderComponent,
} from 'components/CustomFieldCreateModal/CustomFieldCreateModal.views';
import { FetchCustomFieldsDataProvider } from 'pages/SettingsPage/CustomFieldsSettingsPage/contexts/FetchCustomFields.data.context';
import {
  ApplyFacilitiesFormProvider,
  useApplyFacilitiesFormContext,
} from 'components/CustomFieldCreateModal/contexts/ApplyFacilities.form.context';

const CustomFieldModalComponent: FC = () => {
  const { createCustomFieldActiveModalView, isOpen } = useCustomFieldCreateModalUIContext();
  const { onClose } = useCustomFieldCreateModalPropsContext();
  const { onReset } = useApplyFacilitiesFormContext();

  const Component = useMemo(
    () => CustomFieldCreateModalViewMap[createCustomFieldActiveModalView],
    [createCustomFieldActiveModalView],
  );

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={onClose!}
      headerComponent={getHeaderComponent(createCustomFieldActiveModalView)}
      showBackButton={customFieldCreateModalRoutingService.isBackButtonVisible()}
      onBack={() => {
        onReset();
        customFieldCreateModalRoutingService.goToPrevView();
      }}
    >
      {Component ? <Component /> : null}
    </BKJSideModal>
  );
};

const _CustomFieldModal: FC = () => (
  <CustomFieldCreateModalUIProvider>
    <FetchCommunitiesDataProvider>
      <FetchCommunitiesCountDataProvider>
        <FetchCustomFieldsDataProvider>
          <CustomFieldCreateModalPropsProvider>
            <CustomFieldCreateModalFormProvider>
              <ApplyFacilitiesFormProvider>
                <CustomFieldModalComponent />
              </ApplyFacilitiesFormProvider>
            </CustomFieldCreateModalFormProvider>
          </CustomFieldCreateModalPropsProvider>
        </FetchCustomFieldsDataProvider>
      </FetchCommunitiesCountDataProvider>
    </FetchCommunitiesDataProvider>
  </CustomFieldCreateModalUIProvider>
);

export const CustomFieldCreateModal = withMemo(_CustomFieldModal);
