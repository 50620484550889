import { AGENCY_HOURLY_RATE } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_POST_selectedAgencyCommunitiesHourlyRate = ({
  agencyCommunityIds,
  positionId,
  shiftLength,
}: {
  agencyCommunityIds: number[];
  positionId: number;
  shiftLength: number;
}): ApiRequestConfigType => ({
  __typename: 'LegacyAPI',
  method: METHOD_POST,
  url: AGENCY_HOURLY_RATE,
  body: {
    agency_community_ids: agencyCommunityIds,
    position_id: positionId,
    shift_length: shiftLength,
  },
});
