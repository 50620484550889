import { AGENCY_SECONDARY_USERS } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { AdministratorSettingsGeneralFormFields } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
export const API_PUT_updateAgencySecondaryDetails = (
  id: string,
  formValues: AdministratorSettingsGeneralFormFields,
): ApiRequestConfigType => {
  const communities = formValues.communities.map((item) => item.id);

  return {
    __typename: 'LegacyAPI',
    method: METHOD_PUT,
    url: `${AGENCY_SECONDARY_USERS}/${id}`,
    body: {
      agency_user: { ...formValues, community_ids: communities },
    },
  };
};
