import styled, { css } from 'styled-components';

export const InputLabel = styled.label<{ disabled?: boolean }>`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 4px;
  display: flex;
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.TextDisabled};
    `}
`;

export const RequiredAsterisk = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.ErrorDefault};
  margin-bottom: 0;
`;
