export enum TeamMemberAccessStatusColorEnum {
  approved = 'SecondaryAcceptedHover',
  blocked = 'Grey5',
  incomplete = 'ErrorDefault',
  not_approved = 'SecondaryPendingHover',
  on_hold = 'Grey5',
  pending_signup = 'SecondaryPendingHover',
  rejected = 'Grey5',
  default = 'Grey5',
}

export type TeamMemberAccessStatusColorEnumKey = keyof typeof TeamMemberAccessStatusColorEnum;
