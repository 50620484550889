import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { GlobalLoading } from 'components/GlobalLoading';
import { formatPascalCase } from 'format/formatPascalCase';
import { UserGuardType } from 'guards/UserGuard.types';
import { usePathname } from 'hooks/usePathname';
import { PendingPage } from 'pages';
import { AuthRouteDataProvider } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC, ReactElement, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useIsUserB2bPendingSelector } from 'store/selectors/userSelectors/isUserB2bPendingSelector';
import { sessionTokenSelector } from 'store/selectors/userSelectors/sessionTokenSelector';
import { assertUserPermissions } from 'utils/assertUserPermissions';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';

interface IAuthRouteProps extends Omit<RouteProps, 'component'> {
  userType?: UserGuardType | UserGuardType[];
  component: NonNullable<RouteProps['component']>;
}

export const AuthRoute: FC<IAuthRouteProps> = withMemo(
  ({
    userType,
    location,
    exact,
    sensitive,
    strict,
    path,
    component,
  }): ReactElement<IAuthRouteProps> => {
    const pathname = usePathname();
    const sessionToken = useSelector(sessionTokenSelector);
    const isUserPendingB2B = useIsUserB2bPendingSelector();
    if (!sessionToken) return <Redirect to="/login" />;
    if (userType && !assertUserPermissions(userType)) return <Redirect to="/404" />;
    return (
      <Suspense fallback={<GlobalLoading />}>
        <Flex flex="1" height="100%" maxWidth="100%">
          <ErrorBoundary
            showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
            componentName={formatPascalCase(pathname.replace('/', ''))}
          >
            <AuthRouteDataProvider>
              {isUserPendingB2B ? (
                <Route
                  location={location}
                  exact={exact}
                  sensitive={sensitive}
                  strict={strict}
                  path={path}
                  component={PendingPage}
                />
              ) : (
                <Route
                  location={location}
                  exact={exact}
                  sensitive={sensitive}
                  strict={strict}
                  path={path}
                  component={component}
                />
              )}
            </AuthRouteDataProvider>
          </ErrorBoundary>
        </Flex>
      </Suspense>
    );
  },
);
