import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  SectionDivider,
  SendToAgenciesSummaryViewTitle,
  SendToAgenciesContainer,
  ConfirmationButtons,
} from './SendToAgenciesSummaryView.styled';
import { FC, Fragment, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { OpenShift } from './OpenShift';
import { SummaryInfo } from './SummaryInfo';
import { DirectRequests } from './DirectRequests';
import { AutoMargin } from 'components/AutoMargin';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { AgencyRequests } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests';

export const SendToAgenciesSummaryViewComponent: FC = () => {
  const { t } = useTranslation();
  const {
    onSubmit,
    values: { send_to_agencies_target },
    isDisabled,
    isSubmitting,
  } = useSendToAgenciesFormContext();

  const SummaryCost = useCallback(() => {
    switch (send_to_agencies_target) {
      case 'all':
        return <OpenShift />;
      case 'request_member':
        return <DirectRequests />;
      case 'request_agencies':
        return <AgencyRequests />;
    }
  }, [send_to_agencies_target]);

  return (
    <Fragment>
      <SendToAgenciesContainer flexDirection="column" flex="1" gap="22.5px">
        <SendToAgenciesSummaryViewTitle>{t('shift_modal:SUMMARY')}</SendToAgenciesSummaryViewTitle>
        <SummaryInfo />
        <SectionDivider variant="Thin" />
        <SummaryCost />
      </SendToAgenciesContainer>

      <BKJSideModalFooter>
        <AutoMargin />
        <ConfirmationButtons
          variant="PurpleSolid"
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          disabled={isDisabled}
          isLoading={isSubmitting}
        >
          {t('shift_modal:SEND')}
        </ConfirmationButtons>
      </BKJSideModalFooter>
    </Fragment>
  );
};
