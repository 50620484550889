import { SCHEDULING_RELEASE_PUBLISH_SHIFT, RELEASE_AGENCY_SHIFT } from 'constants/endpoints';
import { METHOD_PATCH, METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { SessionService } from 'services';

export const API_POST_releasePublishedShifts = (params: {
  reason_message: string;
  reason_category: string;
  shift_id: number;
}): ApiRequestConfigType => {
  const { reason_message = '', reason_category = '', shift_id } = params;

  const message = reason_category === 'other' ? reason_message : '';

  const body = {
    release: {
      reason_message: message,
      reason_category,
    },
  };

  return SessionService.assertUserType('Agency')
    ? {
        __typename: 'LegacyAPI',
        url: RELEASE_AGENCY_SHIFT(shift_id),
        method: METHOD_PATCH,
        body,
      }
    : {
        __typename: 'LegacyAPI',
        url: SCHEDULING_RELEASE_PUBLISH_SHIFT(shift_id),
        method: METHOD_POST,
        body,
      };
};
