import styled from 'styled-components';
import { BKJTableRow } from 'components/BKJTable/BKJTable.styled';
import {
  BKJTableData,
  BKJTableRowWrapper,
} from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { AGENCY_MEMBER_TABLE_COLUMNS_WIDTH } from 'pages/AgencyMemberTablePage/AgencyMemberTable/AgencyMemberTable.constants';

export const AgencyMemberTableRow = styled(BKJTableRow)`
  grid-template-columns: ${AGENCY_MEMBER_TABLE_COLUMNS_WIDTH};
`;

export const AgencyMemberTableRowWrapper = styled(BKJTableRowWrapper)`
  grid-template-columns: ${AGENCY_MEMBER_TABLE_COLUMNS_WIDTH};
`;
export const AgencyMemberTableData = styled(BKJTableData)``;
