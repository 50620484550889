export const dashboardPageEmptyHeaderCopy: Record<string, string> = {
  '/dashboard/past': 'dashboard:NO_PAST_SHIFTS',
  '/dashboard/upcoming': 'dashboard:NO_UPCOMING_SHIFTS',
  '/dashboard/myupcoming': 'dashboard:NO_UPCOMING_SHIFTS',
  '/dashboard/recent': 'dashboard:NO_RECENT_SHIFTS',
};

export const dashboardPageEmptyBodyCopy: Record<string, string> = {
  '/dashboard/past': 'agency_dashboard:ADJUST_YOUR_FILTERS_OR_CREATE_A_SHIFT',
  '/dashboard/available': 'dashboard:ADJUST_FILTERS_OR_CREATE_SHIFT',
  '/dashboard/upcoming': 'dashboard:ADJUST_FILTERS_OR_CREATE_SHIFT',
  '/dashboard/myupcoming': 'dashboard:ADJUST_FILTERS_OR_CREATE_SHIFT',
  '/dashboard/recent': 'dashboard:EMPTY_STATES_IN_RECENTLY_CREATED_SHIFTS',
};
