import styled, { css } from 'styled-components';
import {
  IStyledDatePickerWrapperProps,
  StyledDatePickerPreviousNextButtonAttributes,
  IStyledDatePickerDateDisplayButtonProps,
} from './BaseDatePicker.types';

export const DatePickerWrapper = styled.div<IStyledDatePickerWrapperProps>`
  display: flex;
  height: 100%;
  max-width: 300px;
  border-right: 1px solid #dfe3e9;
  border-left: 1px solid #dfe3e9;
  ${({ variant }) =>
    variant &&
    variant !== 'SSFTopBar' &&
    css`
      border: none;
    `};
`;

export const DatePickerNavigationButton = styled.button<StyledDatePickerPreviousNextButtonAttributes>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.White};
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
`;

export const DateDisplayButton = styled.button<IStyledDatePickerDateDisplayButtonProps>`
  height: 100%;
  width: 200px;
  background-color: ${({ theme }) => theme.colors.White};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.typography.H4};
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey2};
    `};
`;
