import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { IStarRatingProps } from 'components/StarRating/StarRating.types';
import { Rating, RatingWrapper } from './StarRating.styled';

export function StarRating(props: IStarRatingProps): JSX.Element {
  const { rating } = props;
  return (
    <Rating>
      <RatingWrapper>
        {Array.from({ length: rating }).map((_, i) => (
          <BKJIcon iconName="Star" key={`${_}-${i}`} />
        ))}
      </RatingWrapper>
    </Rating>
  );
}
