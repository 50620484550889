import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API_POST_selectedAgencyCommunitiesHourlyRate } from 'requests/POST_selectedAgencyCommunitiesHourlyRate';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { LegacyAPIContextType } from 'types/common.types';
import { createLegacyDataContext } from 'utils/createDataContext';

interface IFetchSelectedAgenciesRateData {
  rate: {
    min: number;
    max: number;
  };
  cost: {
    min: number;
    max: number;
  };
}

export const FetchSelectedAgenciesRateDataContext =
  createLegacyDataContext<IFetchSelectedAgenciesRateData>({} as IFetchSelectedAgenciesRateData);

export const FetchSelectedAgenciesRateDataProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const { values } = useSendToAgenciesFormContext();
  const {
    data: {
      fetchOrder: {
        totalDuration,
        position: { id },
      },
    },
  } = useShiftModalDataContext();

  const requestOptions = API_POST_selectedAgencyCommunitiesHourlyRate({
    agencyCommunityIds: values.selectedAgencies,
    positionId: id,
    shiftLength: totalDuration!,
  });
  const result = useSelector((state) => apiDataSelector(state, requestOptions));

  useEffect(() => {
    apiRequest(requestOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedAgencies]);

  return (
    <FetchSelectedAgenciesRateDataContext.Provider value={result}>
      {children}
    </FetchSelectedAgenciesRateDataContext.Provider>
  );
};

export const useFetchSelectedAgenciesRateDataContext =
  (): LegacyAPIContextType<IFetchSelectedAgenciesRateData> =>
    useContext(FetchSelectedAgenciesRateDataContext);
