import { AppLink } from 'components/AppLink/AppLink';
import { MenuAccordion } from 'components/SettingsNavigation/SettingsNavigation.styled';
import { SettingsNavMenuItem } from 'components/SettingsNavigation/SettingsNavMenuItem';
import { useCachedScrollRef } from 'hooks/useCachedScrollRef';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC } from 'react';
import { ISettingsNavigationProps } from 'components/SettingsNavigation/ExpandableMenuLink/ExpandableMenuLink.types';
import { useNumSettingsMenuItems } from 'hooks/useNumSettingsMenuItems';
import { useRouteMatch } from 'react-router-dom';
import {
  positionsSettingsPagePath,
  positionsSettingsPageRouteMatch,
} from 'components/SettingsNavigation/SettingsNavigation.constants';
import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';

export const AgencyCommunitiesNavigation: FC<ISettingsNavigationProps> = ({
  isOpen,
  isExpanded,
}) => {
  const numMenuItems = useNumSettingsMenuItems();
  const match = useRouteMatch<{ id: string }>(positionsSettingsPageRouteMatch);
  const locationId = match?.params?.id;

  const {
    locationFilter: { data: items },
  } = useAuthRouteDataContext();

  const { scrollElementRef, handleScroll } = useCachedScrollRef(
    'AgencyCommunitiesSettingsNavigationScrollTop',
  );

  return (
    <MenuAccordion
      $isOpen={isOpen}
      onScroll={handleScroll}
      $isExpanded={isExpanded}
      numMenuItems={numMenuItems}
      ref={scrollElementRef}
    >
      {items.map((option: LocationOptionType) => {
        const active = locationId === option.value.toString();
        return (
          <AppLink key={option.key} to={`${positionsSettingsPagePath}/${option.value}`}>
            <SettingsNavMenuItem
              active={active}
              label={option.label}
              scrollElement={scrollElementRef.current}
            />
          </AppLink>
        );
      })}
    </MenuAccordion>
  );
};
