import { AxiosResponse } from 'axios';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import {
  GeneralLocationSettingsNewFormApiError as GLSNFApiError,
  GeneralLocationSettingsNewFormFields as GLSNFFields,
} from 'pages/LocationSettingsPage/GeneralLocationSettingsPageNewForm.types';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { createContext, FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { API_POST_createNewAgencyFacility } from 'requests/POST_createNewAgencyFacility';
import { API_POST_createNewFacility } from 'requests/POST_createNewFacility';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { VALIDATION_email, VALIDATION_required } from 'validations';

const GeneralLocationSettingsNewFormContext = createContext<
  UseFormBehaviors<GLSNFFields, GLSNFApiError>
>({} as UseFormBehaviors<GLSNFFields, GLSNFApiError>);

export const GeneralLocationSettingsNewFormProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const {
    locationFilter: { refetch },
  } = useAuthRouteDataContext();
  const history = useHistory();
  const { t } = useTranslation();

  const form = useFormBehaviors<GLSNFFields, GLSNFApiError>({
    type: 'CREATE',
    isDirtyCheckEnabled: true,
    initialState: {
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      full_address: '',
      longitude: null,
      latitude: null,
      flat: '',
      street: '',
      street_number: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      regulatory_id: '',
      document: undefined,
    },
    onSubmit: useUserTypeSwitch({
      Agency: (formValues: GLSNFFields) => {
        return apiRequest(API_POST_createNewAgencyFacility(formValues), {
          onSuccess: async (
            _response: AxiosResponse<{
              community?: { name: string; id?: number };
              agency_community?: { name: string; id?: number };
            }>,
          ) => {
            await refetch!();
            const { id, name } = _response.data.community! || _response.data.agency_community!;

            history.push(`/settings/locations/${id}/general`);
            toastService.success(t('success:CREATED_SUCCESSFULLY', { value: name }));
          },
          onError: (e) => handleGenericErrorMessage(e, t('errors:ERROR_CREATING_FACILITY')),
        });
      },
      B2B: (formValues: GLSNFFields) => {
        return apiRequest(API_POST_createNewFacility(formValues), {
          onSuccess: async (
            response: AxiosResponse<{ community: { name: string; id: number } }>,
          ) => {
            await refetch!();
            history.push(`/settings/locations/${response.data.community.id}/general`);
            toastService.success(
              t('success:CREATED_SUCCESSFULLY', { value: response.data.community.name }),
            );
          },
          onError: (e) => handleGenericErrorMessage(e, t('errors:ERROR_CREATING_FACILITY')),
        });
      },
    }),
    validations: useUserTypeSwitch({
      Agency: {
        name: VALIDATION_required,
        full_address: VALIDATION_required,
        first_name: VALIDATION_required,
        last_name: VALIDATION_required,
        email: VALIDATION_email,
        phone: VALIDATION_required,
      },
      B2B: {
        name: VALIDATION_required,
        full_address: VALIDATION_required,
        first_name: VALIDATION_required,
        last_name: VALIDATION_required,
        email: VALIDATION_email,
        phone: VALIDATION_required,
      },
    }),
  });

  return (
    <GeneralLocationSettingsNewFormContext.Provider value={form}>
      {children}
    </GeneralLocationSettingsNewFormContext.Provider>
  );
};

export const useGeneralLocationSettingsNewFormContext = () =>
  useContext<UseFormBehaviors<GLSNFFields, GLSNFApiError>>(GeneralLocationSettingsNewFormContext);
