import { createSelector } from 'reselect';
import { API_GET_customerCommunitiesV2 } from 'constants/requestOptions';
import { generateRequestId } from 'store/reducers/api/utils';

const getSchedule = (state) => state.schedule;
const getApi = (state) => state.api;

const selectedScheduleFacilityDataSelector = createSelector(
  getApi,
  getSchedule,
  (api, schedule) => {
    const request_key = generateRequestId(API_GET_customerCommunitiesV2);
    if (api[request_key].data.communities[schedule.selectedFacilityId])
      return api[request_key].data.communities[schedule.selectedFacilityId];
    return {};
  },
);

export default selectedScheduleFacilityDataSelector;
