import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import { Text } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import styled from 'styled-components';
import { css } from 'styled-components';
interface IProps {
  size?: number;
}

export const CSVFooterDisclaimer = styled.div`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
`;

export const AuditTrailTableRow = styled.div<{ isGrey?: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
`;

export const AuditTrailTableCell = styled.div<{ padding?: string; cellWidth?: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  ${({ cellWidth }) => cellWidth && `flex-basis: ${cellWidth}`};
  ${({ cellWidth }) => cellWidth && `width: ${cellWidth}`};
  flex-grow: 0;
  flex-shrink: 0;
  ${({ padding }) => padding && `padding: ${padding}`};
`;

export const AuditTrailJaneAvatarWrapper = styled.div<{ isGrey?: boolean }>`
  opacity: ${({ isGrey }) => (isGrey ? '0.5' : '1')};
  /* Asset Provided By Design is Not Centered */
  g {
    path {
      transform: translate(0.5px, -0.5px);
    }
  }
`;

export const AuditTrailTableText = styled(Text)<{ isGrey?: boolean }>`
  ${({ isGrey }) => isGrey && 'color: #6d7682'};
  line-break: auto;
  white-space: normal;
  text-overflow: initial;
  font-size: 14px;
`;

export const AuditTrailTableTextSmall = styled(AuditTrailTableText)`
  font-size: 13px;
`;

export const AuditTrailTableTextLocation = styled(AuditTrailTableText)`
  ${({ theme }) => theme.fonts.PoppinsMedium};
  width: 140px;
`;
export const AuditTrailAvatar = styled(BKJAvatar)<IProps>`
  ${({ size }) => {
    return css`
      min-width: calc(${size}px / 2);
      min-height: calc(${size}px / 2);
    `;
  }};
`;
