import { BKJComboBox } from '@bookjane2/bookjane-design-library';
import { IBKJComboBoxProps } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBox/BKJComboBox.types';
import { FC } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { withMemo } from 'utils/withMemo';

export const HourSelectInput: FC<Omit<IBKJComboBoxProps, 'action'>> = withMemo(
  ({ name, onChange, value, type, placeholder, variant, ...rest }) => {
    const { t } = useTranslation();
    return (
      <BKJComboBox
        {...rest}
        action="OpenToggle"
        type={type}
        name={name}
        value={value}
        variant={variant}
        onChange={onChange}
        placeholder={t('dashboard:SELECT_A_TIME')}
      />
    );
  },
);
