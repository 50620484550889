import { Flex } from 'components/Flex/Flex';
import styled from 'styled-components';
import { BKJButton } from '@bookjane2/bookjane-design-library';

export const HeaderWrapper = styled(Flex)`
  top: 0;
  position: sticky;
  background: white;
  overflow: hidden;
  padding: 40px 0px 0px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 0px 0px 0px;
  }
`;

export const MainTitleContainer = styled(Flex)`
  padding: 0px 32px 0px 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 0 20px;
    flex-direction: column;
  }
`;

export const SearchTeamMembersWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 0 20px;
  }
`;

export const StyledJanesWrapper = styled(Flex)`
  overflow: scroll;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 0;
  }
`;

export const BKJSideModalFooterContainer = styled(Flex)`
  padding: 0px 10px 0px 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 6px 0;
    flex-direction: column;
  }
`;

export const BKJSideModalFooterContent = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin: 0 0 10px 0;
  }
`;

export const BKJSideModalFooterConfirmationButton = styled(BKJButton)`
  width: 180px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
  }
`;

export const ResetButtonWrapper = styled(Flex)`
  cursor: pointer;
`;

export const ResetButtonLabel = styled.span`
  ${({ theme }) => theme.typography.TextLink};
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-decoration: underline;
`;

export const EmptyTextWrapper = styled.div`
  width: 350px;
  text-align: center;
  ${({ theme }) => theme.fonts.PoppinsText};
  line-height: 24px;
  b {
    ${({ theme }) => theme.fonts.PoppinsTextMedium};
  }
`;
export const FooterNumbers = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
`;
export const TotalBidPlacedText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 16px;
  line-height: 21px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 16px;
    margin-bottom: 12px;
  }
`;
