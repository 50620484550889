import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { AGENCY_SECONDARY_USERS } from 'constants/endpoints';
import { AdministratorSettingsInviteAdminFormFields } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';

export const API_POST_inviteAgencySecondaryUsers = (
  params: AdministratorSettingsInviteAdminFormFields,
): ApiRequestConfigType => {
  const communities = params.communities.filter((item) => item.isIncluded).map((item) => item.id);

  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url: AGENCY_SECONDARY_USERS,
    body: {
      agency_user: {
        community_ids: communities,
        email: params.email,
        first_name: params.first_name,
        last_name: params.last_name,
        ...(params.phone && { phone: params.phone }),
      },
    },
  };
};
