import styled from 'styled-components';
import { H3 } from 'components/Typography';
import { Flex } from 'components/Flex';

export const CompanyProfileHeader = styled(H3)`
  padding-bottom: 10px;
`;

export const CompanyProfileContentWrapper = styled.div`
  border-radius: 5px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: white;

  display: flex;
  margin-right: auto;

  padding: 2rem 150px;

  width: 100%;
`;

export const InputLabelText = styled.p`
  font-family: ${({ theme }) => theme.fonts.PoppinsMedium};
  font-size: 14px;
  line-height: 21px;
  color: #45424e;
  margin-bottom: 4px;
`;

export const CompanyProfilePageWrapper = styled(Flex)`
  background-color: rgb(246, 247, 251);
  padding-bottom: 5px;
`;
export const CompanyProfileButtonWrapper = styled(Flex)`
  justify-content: flex-end;
`;
export const CompanyProfileImageWrapper = styled(Flex)`
  flex-direction: column;
  gap: 20px;
  padding: 28px 0 30px;
`;
export const CompanyProfileIconImage = styled.img<{ size?: string }>`
  width: ${(props) => props.size || '100%'};
  max-width: 100%;
  height: auto;
`;
