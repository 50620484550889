import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isYMCAReadOnlySecondarySelector = createSelector(getUserPermissions, (permissions) => {
  if (permissions?.READ_ONLY_SECONDARY_USER?.access)
    return permissions.READ_ONLY_SECONDARY_USER.access.includes('read');
  return false;
});
