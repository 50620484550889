import { FC, Fragment, MouseEventHandler, useCallback } from 'react';
import { Heading } from 'pages/CreateShiftsPage/BulkCreateShiftView/BulkCreateShiftView.styled';
import { useCreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { IconWithMarginRight } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.styled';
import {
  ShiftSummaryViewWrapper,
  ShiftSummaryRequestTeamMemberText,
  ShiftsGroupedByPosition,
  PositionName,
  TableHeader,
  TableHeaderCell,
  TableCell,
  TableRow,
  Table,
  TableBody,
  RecurringText,
  ShiftFooterDataRow,
  ShiftFooterLabel,
  ShiftBiddingText,
  ShiftFooterWrapper,
  CreateShiftConfirmationButton,
  ShiftSummaryBottomPadding,
} from './ShiftSummaryView.styled';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import {
  sortShiftsByStartTime,
  groupShiftsByPosition,
  getRecurringLabel,
  isRecurring,
} from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { UserGuard } from 'guards/UserGuard';
import { ShiftPayRange } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftPayRange';
import { ShiftSummaryViewContext, ShiftSummaryViewProvider } from './ShiftSummaryView.context';
import { QuerySwitch } from 'components/QuerySwitch';
import isUserEssentialSelector from 'store/selectors/userSelectors/isUserEssentialSelector';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { ShiftCountAndLocationLabel } from './ShiftCountAndLocationLabel';
import { formatPlural } from 'utils/formatPlural';
import { castStringToInteger } from 'utils';
import { FeatureGuard } from 'guards/FeatureGuard';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { WebShiftSummaryDetailRow } from 'pages/CreateShiftsPage/ShiftSummaryView/Web/WebShiftSummaryDetailRow';
import { MobileShiftSummaryDetailRow } from 'pages/CreateShiftsPage/ShiftSummaryView/Mobile/MobileShiftSummaryDetailRow';
import { ConfirmationButtons } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.styled';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { OpenShiftPolicyEnum } from 'types/graphql-types';

const ShiftSummaryViewComponent: FC = () => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useResponsive();
  const isUserEssential = useSelector(isUserEssentialSelector);
  const {
    goToView,
    groupedFormsBehaviors: { groupedForms, isSubmitting, isSubmitDisabled, onSubmit, values },
  } = useCreateShiftsPageContext();

  const goToCreateShiftView = useCallback(() => goToView('CREATE_SHIFTS'), [goToView]);

  const createShifts = groupedForms as unknown as ICreateShift[];

  const positionGroupedShifts = groupShiftsByPosition(sortShiftsByStartTime(createShifts));

  const ShiftSummaryDetailRow = isMobile ? MobileShiftSummaryDetailRow : WebShiftSummaryDetailRow;

  const isShiftBiddingFeatureEnabled = useSelector(isShiftBiddingFeatureEnabledSelector);
  const getOpenShiftPolicy = useCallback(
    (values) => {
      return (
        values?.openShiftPolicy === OpenShiftPolicyEnum.bidding_required &&
        isShiftBiddingFeatureEnabled
      );
    },
    [isShiftBiddingFeatureEnabled],
  );

  return (
    <QuerySwitch
      context={ShiftSummaryViewContext}
      component={() => (
        <ShiftSummaryViewWrapper flexDirection="column">
          <Heading>
            {t('create_shifts:SHIFTS_SUMMARY')}
            <ShiftCountAndLocationLabel locationId={castStringToInteger(values.location.value)} />
          </Heading>

          {positionGroupedShifts.map((positionShifts) => {
            const isShiftBiddingRequired = getOpenShiftPolicy(positionShifts[0].values);
            return (
              <Fragment>
                <ShiftsGroupedByPosition key={positionShifts[0].values.position_id[0]}>
                  <PositionName>{positionShifts[0].values.positionName}</PositionName>
                  <Table>
                    {!isMobile && (
                      <TableHeader>
                        <TableRow>
                          <TableHeaderCell>{t('create_shifts:SHIFT_DATE')}</TableHeaderCell>
                          <TableHeaderCell>{t('common:TIME')}</TableHeaderCell>
                          <TableHeaderCell>{t('create_shifts:BREAK_TIME')}</TableHeaderCell>
                          <TableHeaderCell>{t('create_shifts:PAID_DURATION')}</TableHeaderCell>
                          <TableHeaderCell>{t('common:TEAM_MEMBER')}</TableHeaderCell>
                          {isShiftBiddingRequired && (
                            <TableHeaderCell>{t('create_shifts:BIDDING_PERIOD')}</TableHeaderCell>
                          )}
                          {isUserEssential && (
                            <TableHeaderCell>{t('create_shifts:HOURLY_PAY')}</TableHeaderCell>
                          )}
                        </TableRow>
                      </TableHeader>
                    )}

                    <TableBody>
                      {positionShifts.map((shift) => {
                        const isShiftBiddingRequired = getOpenShiftPolicy(shift.values);
                        return (
                          <Fragment key={shift.values.id}>
                            <ShiftSummaryDetailRow shift={shift} />
                            <ShiftFooterDataRow>
                              <TableCell colSpan={5}>
                                {!!shift.values.recurring_frequency[0] && (
                                  <RecurringText>
                                    <IconWithMarginRight
                                      iconName="Recurring"
                                      color="PrimaryClick"
                                    />
                                    {getRecurringLabel(shift.values)}
                                  </RecurringText>
                                )}
                                {!isShiftBiddingRequired && (
                                  <FeatureGuard type="FEATURE_TYPE_SCHEDULING">
                                    <ShiftFooterWrapper>
                                      <ShiftFooterLabel isBold>
                                        {t('create_shifts:ALLOW_OVERTIME')}:&nbsp;
                                      </ShiftFooterLabel>
                                      <ShiftFooterLabel>
                                        {!isRecurring(shift.values) && shift.values.allow_overtime
                                          ? t('create_shifts:YES')
                                          : t('create_shifts:NO')}
                                      </ShiftFooterLabel>
                                    </ShiftFooterWrapper>
                                  </FeatureGuard>
                                )}
                                <ShiftFooterLabel isBold>
                                  {i18n.language === 'fr'
                                    ? `${
                                        shift.values.dedicated
                                          ? t('dashboard:DEDICATED_TEAM_MEMBERS')
                                          : t('common:AVAILABLE_TEAM_MEMBERS')
                                      }: `
                                    : `${
                                        shift.values.dedicated
                                          ? t('create_shifts:Dedicated')
                                          : t('common:AVAILABLE')
                                      } ${t('create_shifts:TEAM')} ${formatPlural(
                                        t('create_shifts:MEMBER'),
                                        shift.values.availableTeamMembersCount || 0,
                                      )}: `}
                                </ShiftFooterLabel>
                                <ShiftFooterLabel>
                                  {shift.values.availableTeamMembersCount || 0}
                                </ShiftFooterLabel>
                                {isShiftBiddingRequired && (
                                  <ShiftBiddingText>
                                    {t('create_shifts:SHIFT_BIDDING_INFO')}
                                  </ShiftBiddingText>
                                )}
                                {isShiftBiddingRequired && shift.values.auto_extend_bid && (
                                  <ShiftBiddingText>
                                    {t('create_shifts:AUTO_RE_BID_MESSAGE')}
                                  </ShiftBiddingText>
                                )}
                                <UserGuard userType="USER_TYPE_ESSENTIAL">
                                  <ShiftPayRange createShift={shift} />
                                </UserGuard>
                              </TableCell>
                            </ShiftFooterDataRow>
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </ShiftsGroupedByPosition>
                {!isShiftBiddingRequired && (
                  <ShiftSummaryRequestTeamMemberText>
                    {t('create_shifts:NOTIFY_INFO')}
                  </ShiftSummaryRequestTeamMemberText>
                )}
              </Fragment>
            );
          })}

          <ConfirmationButtons>
            <CreateShiftConfirmationButton
              variant={isMobile ? 'PurpleNoDecorations' : 'PurpleOutlined'}
              onClick={goToCreateShiftView}
              disabled={isSubmitting}
            >
              {t('create_shifts:EDIT')}
            </CreateShiftConfirmationButton>

            <CreateShiftConfirmationButton
              variant={isMobile ? 'PurpleSolid' : 'GreenSolid'}
              disabled={isSubmitting || isSubmitDisabled}
              onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            >
              {t('create_shifts:SUBMIT')}
            </CreateShiftConfirmationButton>
          </ConfirmationButtons>

          {!isMobile && <ShiftSummaryBottomPadding />}
        </ShiftSummaryViewWrapper>
      )}
    />
  );
};

export function ShiftSummaryView() {
  return (
    <ShiftSummaryViewProvider>
      <ShiftSummaryViewComponent />
    </ShiftSummaryViewProvider>
  );
}
