import { UPDATE_COMMUNITY_SETTINGS } from 'constants/endpoints';
import { METHOD_PATCH } from 'requests';
import { ApiRequestConfigType } from './index.types';

export const API_PATCH_communityVaccinationStatusV2 = ({
  communityId,
  vaccinationRequired,
}: {
  communityId: number;
  vaccinationRequired: boolean;
}): ApiRequestConfigType => {
  return {
    method: METHOD_PATCH,
    url: UPDATE_COMMUNITY_SETTINGS(communityId),
    __typename: 'LegacyAPI',
    body: {
      community: {
        vaccination_required: vaccinationRequired,
      },
    },
  };
};
