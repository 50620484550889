import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isCustomPayPeriodEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    if (userPermissions?.CUSTOM_PAY_PERIOD?.access)
      return (
        userPermissions.CUSTOM_PAY_PERIOD.access.includes('read') &&
        !!userPermissions.CUSTOM_PAY_PERIOD.communities &&
        !!userPermissions.CUSTOM_PAY_PERIOD.communities[communityId] &&
        !!userPermissions.CUSTOM_PAY_PERIOD.communities[communityId].includes('read')
      );
    else return false;
  });
