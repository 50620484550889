import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

interface UseBiddingTimeProps {
  biddingTimeEndsAt: string | null;
  fetch: () => void;
}

export function useBiddingTime({ biddingTimeEndsAt, fetch }: UseBiddingTimeProps) {
  const { timeZone } = getCurrentlySelectedLocation();
  useEffect(() => {
    if (!biddingTimeEndsAt || !timeZone) return;

    const biddingEndTime = DateTime.fromISO(biddingTimeEndsAt).setZone(timeZone);

    const currentTime = DateTime.now().setZone(timeZone);
    const remainingBiddingTime = biddingEndTime.toMillis() - currentTime.toMillis();

    if (remainingBiddingTime > 0) {
      const biddingPeriodTimeout = setTimeout(() => fetch(), remainingBiddingTime);
      return () => {
        clearTimeout(biddingPeriodTimeout);
      };
    }
  }, [timeZone, biddingTimeEndsAt, fetch]);

  return useBiddingTime;
}
