import { Fragment } from 'react';
import { BKJButton, BKJHorizontalRule, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { AppLink } from 'components/AppLink/AppLink';
import { PageWrapper } from 'components/PageWrapper';
import { useFormBehaviors } from 'hooks';
import {
  BelowLoginForm,
  FormBody,
  FormSection,
  FormTitle,
  LoginFlex,
  LogoWrapper,
} from 'pages/LoginPage/LoginPage.styled';
import { useTranslation } from 'hooks/useTranslation';
import { useUserActions } from 'store/reducers/auth/authReducer';
import { css } from 'styled-components';
import { VALIDATION_email, VALIDATION_password } from 'validations';
import { authResponseHandler } from 'pages/LoginPage/LoginPage.constants';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { LoginFormSchema } from 'pages/LoginPage/LoginPage';
import { WhiteLabelMainLogo } from 'components/WhiteLabelMainLogo';

const initialState = {
  username: '',
  password: '',
};
interface APIErrors {
  type: string;
  message: string;
}

function MobileLoginPageWrapper() {
  const { userLogin } = useUserActions();
  const { t } = useTranslation();
  const { isPetalProvider } = WhiteLabelAccessor;

  const { onSubmit, apiErrors, isSubmitting, isDisabled, onChange, values, fieldErrors } =
    useFormBehaviors<Required<LoginFormSchema>, APIErrors>({
      isDirtyCheckEnabled: true,
      initialState,
      validations: {
        username: VALIDATION_email,
        password: VALIDATION_password,
      },
      onSubmit: async ({ username, password }) => {
        const trimmedUsername = username.trim();
        const response = await userLogin({ username: trimmedUsername, password });
        return authResponseHandler(response);
      },
    });

  const translatedError =
    apiErrors?.type === 'NotFound' ? t('errors:NOT_FOUND') : apiErrors?.message;

  return (
    <PageWrapper $minWidth="100%" $maxWidth="100%" $width="100%">
      <LogoWrapper>
        <WhiteLabelMainLogo width="200px" />
      </LogoWrapper>
      <Fragment>
        <LoginFlex justifyContent="center">
          <FormSection onSubmit={onSubmit}>
            <FormBody>
              <FormTitle>{t('auth:LOGIN')}</FormTitle>
              <BKJTextInput
                label={t('auth:EMAIL')}
                value={values.username}
                placeholder={t('auth:ENTER_YOUR_EMAIL_ADDRESS')}
                type="username"
                name="username"
                error={fieldErrors.username}
                required
                onChange={onChange}
                autoComplete="off"
                css={css`
                  margin: 28px 0;
                `}
              />
              <BKJTextInput
                label={t('auth:PASSWORD')}
                placeholder={t('auth:ENTER_YOUR_PASSWORD')}
                value={values.password}
                error={fieldErrors.password}
                type="password"
                name="password"
                required
                onChange={onChange}
                autoComplete="off"
                css={css`
                  margin-bottom: 32px;
                `}
              />
              {apiErrors && <span>{translatedError}</span>}
              <BKJButton
                variant="PurpleSolid"
                type="submit"
                disabled={isDisabled}
                isLoading={isSubmitting}
                css={css`
                  width: 100%;
                  min-height: 48px;
                `}
              >
                {t('auth:LOGIN')}
              </BKJButton>
            </FormBody>
            <BelowLoginForm>
              <AppLink to="/forgot">
                <BKJButton width="100%" variant="PurpleNoDecorations" data-cy="forgot_password">
                  {t('auth:FORGOT_PASSWORD')}
                </BKJButton>
              </AppLink>
              {!isPetalProvider && (
                <Fragment>
                  <BKJHorizontalRule
                    variant="Thin"
                    css={css`
                      margin: 32px 0 32px 0;
                    `}
                  />
                  {t('auth:NOT_REGISTER')}
                  <AppLink
                    to="/register"
                    css={css`
                      width: 100%;
                    `}
                  >
                    <BKJButton
                      variant="GreenSolid"
                      css={css`
                        margin-top: 32px;
                        width: 100%;
                        min-height: 48px;
                      `}
                    >
                      {t('auth:SIGN_UP')}
                    </BKJButton>
                  </AppLink>
                </Fragment>
              )}
            </BelowLoginForm>
          </FormSection>
        </LoginFlex>
      </Fragment>
    </PageWrapper>
  );
}

export default MobileLoginPageWrapper;
