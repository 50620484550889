import { FetchAgenciesByOrderIdDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/FetchAgenciesByOrderId.data.context';
import { FetchSelectedAgenciesRateDataProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/FetchSelectedAgenciesRate.data.context';
import { SelectAgenciesViewPropsProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/SelectAgenciesView.props.context';
import { SelectAgenciesViewComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/SelectAgenciesView.component';
import { FC } from 'react';

export const SelectAgenciesView: FC = () => (
  <FetchAgenciesByOrderIdDataProvider>
    <FetchSelectedAgenciesRateDataProvider>
      <SelectAgenciesViewPropsProvider>
        <SelectAgenciesViewComponent />
      </SelectAgenciesViewPropsProvider>
    </FetchSelectedAgenciesRateDataProvider>
  </FetchAgenciesByOrderIdDataProvider>
);
