import styled, { css } from 'styled-components';
import { elementDimensions } from 'constants/elementDimensions';

export interface IPublicPageContentWrapperProps {
  gridTemplateColumns?: string;
}

const { LogoHeight } = elementDimensions;

export const PublicPageContentWrapper = styled.div<IPublicPageContentWrapperProps>`
  overflow: hidden;
  display: flex;
  width: 80%;
  height: calc(100vh - ${LogoHeight}px);
  max-height: calc(100vh - ${LogoHeight}px);

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      display: grid;
      grid-template-columns: repeat(${gridTemplateColumns}, 1fr);
    `}
`;
