import { FormValidationErrorMessageType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { isBoolean, isString } from 'types/common.types';

export const VALIDATION_required = (value: unknown) => {
  const messages: FormValidationErrorMessageType = [];
  if (isString(value)) {
    if (value.trim().length === 0) messages.push('This field is required');
  }
  if (isBoolean(value)) {
    if (!value) messages.push('This field is required');
  }
  return messages;
};
