import {
  dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders,
  dashboardPastShiftsPage_fastGroupOrders_nodes_orders,
  dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders,
  dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders,
  shiftCardFetchOrderVariables,
} from 'types/graphql-types';

import { useGQLQuery } from 'hooks';
import { useMemo } from 'react';
import { shiftCardFetchOrder, shiftCardFetchOrder_fetchOrder } from 'types/graphql-types';
import { useDashboardPageContext } from 'pages/DashboardPage/DashboardPage.context';
import { fetchOrderQueryConfig } from 'pages/DashboardPage/GroupOrdersComponent/GroupOrdersComponent.constants';
import { IGroupOrdersProps } from 'pages/DashboardPage/GroupOrdersComponent/GroupOrdersComponent.types';

export function useGroupOrdersContext<
  TContext extends Nullable<
    | shiftCardFetchOrder_fetchOrder
    | dashboardPastShiftsPage_fastGroupOrders_nodes_orders
    | dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders
    | dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders
    | dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders
  >,
>(
  props: IGroupOrdersProps,
): {
  order: TContext;
  fetch: () => void;
} {
  const contextValues = useDashboardPageContext();

  const { groupIndex, orderIndex } = props;

  const orderFromContext: Nullable<
    | shiftCardFetchOrder_fetchOrder
    | dashboardPastShiftsPage_fastGroupOrders_nodes_orders
    | dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders
    | dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders
    | dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders
  > = useMemo(() => {
    const orders = contextValues?.data?.fastGroupOrders?.nodes[groupIndex]?.orders;
    return orders ? orders[orderIndex] : null;
  }, [contextValues, groupIndex, orderIndex]);

  const result = useGQLQuery<
    shiftCardFetchOrder,
    shiftCardFetchOrder,
    shiftCardFetchOrderVariables
  >(fetchOrderQueryConfig(orderFromContext!.id));

  return {
    order: (result.called ? result.data?.fetchOrder : orderFromContext) as unknown as TContext,
    fetch: result.fetch,
  };
}
