import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { AppLink } from 'components/AppLink/AppLink';
import {
  AddNewButton,
  AddNewLinkWrapper,
} from 'components/SettingsNavigation/SettingsNavigation.styled';
import { SettingsNavMenuItem } from 'components/SettingsNavigation/SettingsNavMenuItem';
import { forwardRef, Fragment, RefObject } from 'react';
import { useFetchDepartmentsDataContext } from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/contexts/FetchDepartments.data.context';
import { useRouteMatch } from 'react-router-dom';
import {
  positionsSettingsPagePath,
  positionsSettingsPageRouteMatch,
} from 'components/SettingsNavigation/SettingsNavigation.constants';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { FeatureGuard } from 'guards/FeatureGuard';
import { useTranslation } from 'hooks/useTranslation';

export const B2BDepartmentsNavigationComponent = forwardRef<HTMLUListElement>((_, ref) => {
  const { t } = useTranslation();
  const scrollElementRef = ref as RefObject<HTMLUListElement>;
  const match = useRouteMatch<{ id: string }>(positionsSettingsPageRouteMatch);
  const departmentId = match?.params?.id;

  const { data } = useFetchDepartmentsDataContext();
  const items = data?.departments;

  return (
    <Fragment>
      <FeatureGuard NOT type="FEATURE_TYPE_ACCUSHIELD_SYNCING">
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
            UserPermissionEnum.USER_TYPE_ESSENTIAL_PRIMARY,
          ]}
        >
          <AddNewLinkWrapper
            isActive={!!match?.url.includes('new')}
            to={`${positionsSettingsPagePath}/new`}
          >
            <AddNewButton data-cy="btn-add-department">
              <BKJIcon iconName="Add" />
              &nbsp;
              {t('location_settings:ADD_DEPARTMENT')}
            </AddNewButton>
          </AddNewLinkWrapper>
        </UserGuard>
      </FeatureGuard>

      {items.map((option) => {
        const active = departmentId === option.value;
        return (
          <AppLink key={option.key} to={`${positionsSettingsPagePath}/${option.value}`}>
            <SettingsNavMenuItem
              active={active}
              label={option.label}
              scrollElement={(scrollElementRef as RefObject<HTMLUListElement>).current}
            />
          </AppLink>
        );
      })}
    </Fragment>
  );
});
