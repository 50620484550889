import { isLogRocketEnabled, ROCKET_ID, WEB_VERSION } from 'config';
import LogRocket from 'logrocket';
import { useEffect } from 'react';

export function useLogRocket() {
  useEffect(() => {
    if (isLogRocketEnabled)
      LogRocket.init(ROCKET_ID, {
        release: WEB_VERSION,
        console: {
          shouldAggregateConsoleErrors: false,
        },
      });
  }, []);

  return void 0;
}
