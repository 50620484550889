import { PositionsPriceRangesType } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.types';
import { castStringToInteger } from 'utils';

export const getPositionDetails = ({
  positionsPriceRanges,
  position_id,
  dedicated,
}: {
  positionsPriceRanges: PositionsPriceRangesType;
  position_id: string[];
  dedicated?: boolean;
}) => {
  const positionID = castStringToInteger(position_id[0]);
  if (positionsPriceRanges && positionsPriceRanges[positionID]) {
    const positionDetails = positionsPriceRanges[positionID];
    const { name, openPriceRange, openShiftPolicy } = positionDetails;
    const min_rate = openPriceRange?.min;
    const max_rate = openPriceRange?.max;
    return {
      name,
      min_rate,
      max_rate,
      openShiftPolicy,
    };
  }
};

export const doesPositionHaveDedicatedPriceRange = ({
  positionsPriceRanges,
  position_id,
}: {
  positionsPriceRanges: PositionsPriceRangesType;
  position_id: string[];
}) => {
  let doesPositionHaveDedicatedPriceRange = false;
  if (positionsPriceRanges) {
    const positionID = castStringToInteger(position_id[0]);
    const positionDetails = positionsPriceRanges[positionID];
    doesPositionHaveDedicatedPriceRange = positionDetails?.hasPools;
  }
  return doesPositionHaveDedicatedPriceRange;
};
