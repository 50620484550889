import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const DedicatedTeamMembersWrapper = styled(Flex)`
  margin-left: 30px;
`;

export const DedicatedTeamLabel = styled.span`
  margin-left: 8px;
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.PrimaryDefault};
`;
