import { SSFValueType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { localPersistenceService } from 'services/localPersistenceService';
import { sessionPersistenceService } from 'services/sessionPersistenceService';
import { SessionService } from 'services/SessionService';
import { getWebVersion } from 'utils/getWebVersion';

export const SSF_GLOBAL_CACHE_KEY = (): string => {
  return `${SessionService.getUserId()}_ssf-global-cache_${getWebVersion()}`;
};
export const SSF_SESSION_CACHE_KEY = (): string => {
  return `${SessionService.getUserId()}_ssf-session-cache_${getWebVersion()}`;
};

export const ssfPersistenceService = {
  getGlobalCachedSSFValue<T = unknown>(name: string): T {
    try {
      const globalSSFCache = JSON.parse(
        localPersistenceService.getItem(SSF_GLOBAL_CACHE_KEY()) || '{}',
      );
      return globalSSFCache[name];
    } catch (error) {
      localPersistenceService.removeItem(SSF_GLOBAL_CACHE_KEY());
      window.location.reload();
      return undefined as unknown as T;
    }
  },

  setGlobalCachedSSFValue({ name, value }: { name: string; value: SSFValueType }): void {
    try {
      let _globalSSFCache = JSON.parse(
        localPersistenceService.getItem(SSF_GLOBAL_CACHE_KEY()) || '{}',
      );

      _globalSSFCache = { ..._globalSSFCache, [name]: value };

      localPersistenceService.setItem(SSF_GLOBAL_CACHE_KEY(), JSON.stringify(_globalSSFCache));
    } catch (error) {
      localPersistenceService.removeItem(SSF_GLOBAL_CACHE_KEY());
      window.location.reload();
    }
  },

  getSessionCache(): Record<string, Record<string, SSFValueType>> {
    try {
      return JSON.parse(sessionPersistenceService.getItem(SSF_SESSION_CACHE_KEY()) || '{}');
    } catch (error) {
      localPersistenceService.removeItem(SSF_GLOBAL_CACHE_KEY());
      window.location.reload();
      return {};
    }
  },

  setSessionCache(sessionSSFCache: Record<string, Record<string, SSFValueType>>) {
    sessionPersistenceService.setItem(SSF_SESSION_CACHE_KEY(), JSON.stringify(sessionSSFCache));
  },

  getSessionCachedSSFValue(key: string, name: string): SSFValueType {
    let sessionSSFCache: Record<string, Record<string, SSFValueType>> = JSON.parse(
      sessionPersistenceService.getItem(SSF_SESSION_CACHE_KEY()) || '{}',
    );
    return sessionSSFCache[key] ? sessionSSFCache[key][name] : undefined;
  },

  setSessionCachedSSFValue({
    key,
    name,
    value,
  }: {
    key: string;
    name: string;
    value: SSFValueType;
  }): void {
    let sessionSSFCache: Record<string, Record<string, SSFValueType>> = JSON.parse(
      sessionPersistenceService.getItem(SSF_SESSION_CACHE_KEY()) || '{}',
    );
    if (!sessionSSFCache[key]) {
      sessionSSFCache[key] = { [name]: value };
    } else {
      sessionSSFCache[key] = { ...sessionSSFCache[key], [name]: value };
    }
    sessionPersistenceService.setItem(SSF_SESSION_CACHE_KEY(), JSON.stringify(sessionSSFCache));
  },
};
