import { calcOffsetHeight } from 'components/AuthPageContentWrapper/AuthPageContentWrapper';
import { OffsetElementType } from 'components/AuthPageContentWrapper/AuthPageContextWrapper.types';
import { SSFBarsContext } from 'components/SSFBars/SSFBars.context';
import { SSFBottomBarHeight } from 'constants/elementDimensions';
import { createContext, FC, useContext, useMemo } from 'react';
interface IPageContentWrapperContextProviderProps {
  offsetElements: OffsetElementType[];
  heights: number;
  hasSSFBars: boolean;
  calculated: number;
}

export const PageContentWrapperContext = createContext<IPageContentWrapperContextProviderProps>(
  {
    offsetElements: ['DesktopPageHeaderHeight', 'SSFTopBarHeight'],
    heights: 0,
    calculated: 0,
    hasSSFBars: true,
  }!,
);

export const PageContentWrapperContextProvider: FC<
  Pick<IPageContentWrapperContextProviderProps, 'offsetElements'>
> = ({ children, offsetElements }) => {
  const { isOpen: isSSFBottomBarOpen } = useContext(SSFBarsContext);
  const hasSSFBars = offsetElements.includes('SSFTopBarHeight');

  const heights: number = calcOffsetHeight(
    offsetElements.filter((key) => {
      if (hasSSFBars && key === 'SSFBottomBarHeight') return false;
      return true;
    }),
  );

  let calculated: number = window.innerHeight - heights;

  if (hasSSFBars && isSSFBottomBarOpen)
    calculated = window.innerHeight - (heights + SSFBottomBarHeight);

  const value = useMemo(() => {
    return { hasSSFBars, heights, offsetElements, calculated };
  }, [hasSSFBars, heights, offsetElements, calculated]);

  return (
    <PageContentWrapperContext.Provider value={value}>
      {children}
    </PageContentWrapperContext.Provider>
  );
};

export const usePageContentWrapperContext = () => useContext(PageContentWrapperContext);
