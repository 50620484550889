import {
  IUseWeekRangePickerBehaviorsArgs,
  IUseWeekRangePickerReturn,
} from './WeekRangePicker.types';
import {
  getPrettyRange,
  getOneWeekRangeFromPreviousDay,
  getOneWeekRangeFromNextDay,
} from '../../BKJDatePicker.utils';

export function useWeekRangePickerBehaviors({
  value,
}: IUseWeekRangePickerBehaviorsArgs): IUseWeekRangePickerReturn {
  const startDate = value[0];
  const previousValue = getOneWeekRangeFromPreviousDay({ date: startDate });
  const nextValue = getOneWeekRangeFromNextDay({ date: startDate });
  const displayDate = getPrettyRange(value);
  return {
    nextValue,
    previousValue,
    displayDate,
  };
}
