import {
  CalendarHeaderDay,
  HeaderCalendarRowWrapper,
} from 'components/ShiftCalendar/ShiftCalendar.styled';
import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';

export const CalendarHeaderRow: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const startOfWeek = DateTime.now().startOf('week').minus({ day: 1 });
  return (
    <HeaderCalendarRowWrapper>
      {[...Array(7)].map((_, i) => {
        const weekDay = startOfWeek.plus({ day: i });
        return (
          <CalendarHeaderDay key={`${weekDay.toString()}_header`}>
            {t(weekDay.toFormat('ccc').toUpperCase())}
          </CalendarHeaderDay>
        );
      })}
    </HeaderCalendarRowWrapper>
  );
};
