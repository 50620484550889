import { TimeOffJane } from 'components/TimeOffModal/components/TimeOffJane';
import styled from 'styled-components';

export const MainTitle = styled.h1`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 28px;
  color: #45424e;
  line-height: 34px;
  margin-bottom: 20px;
`;

export const TimeOffWrapper = styled.div`
  padding: 32px 32px 0;
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const TimeOffDateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 27px;
`;

export const TimeOffConflictWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.ErrorDefault};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ::first-letter {
    text-transform: capitalize;
  }
`;

export const TimeOffJaneSection = styled(TimeOffJane)`
  margin-bottom: 18px;
`;

export const AllDaySection = styled.label`
  width: fit-content;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;

export const TimeOffRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;
`;

export const TimeOffColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const NotesSection = styled.div`
  margin-top: 20px;
`;

export const CustomFieldsContent = styled.div`
  padding: 10px 0 10px 0;
`;
