import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { AuditTrailVersionEnum } from 'types/graphql-types';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

type IsAuditTrailFeatureEnabledParams = {
  communityId: number;
  auditTrailVersion: AuditTrailVersionEnum;
};

export const isAuditTrailFeatureEnabledForCommunitySelector = ({
  communityId,
  auditTrailVersion,
}: IsAuditTrailFeatureEnabledParams) =>
  createSelector(getUserPermissions, (permissions) => {
    if (permissions?.AUDIT_TRAIL?.access)
      return (
        auditTrailVersion !== AuditTrailVersionEnum.v1 &&
        permissions.AUDIT_TRAIL.access.includes('read') &&
        !!permissions.AUDIT_TRAIL.communities &&
        !!permissions.AUDIT_TRAIL.communities[communityId] &&
        !!permissions.AUDIT_TRAIL.communities[communityId].includes('read')
      );
    else return false;
  });
