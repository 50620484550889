import { createContext } from 'react';
import { IBKJDatePickerContextValue } from './BKJDatePicker.types';
import { getCurrentMonthCalendarRange } from './BKJDatePicker.utils';

export const BKJDatePickerContext = createContext<IBKJDatePickerContextValue>({
  name: 'filterDate',
  type: 'SingleDateRange',
  value: getCurrentMonthCalendarRange(),
  onChange: () => {},
  variant: 'SSFTopBar',
});
