import { FC, useCallback, useRef, useState } from 'react';
import {
  HamburgerMenu,
  MobilePageHeaderWrapper,
} from 'components/MobilePageHeader/MobilePageHeader.styled';
import { IMobilePageHeaderProps } from 'components/MobilePageHeader/MobilePageHeader.types';
import { MobileNavHeader } from 'components/BKJMainNavigation/src/Mobile/MobileNavHeader';
import { MobileNavMenuItems } from 'components/BKJMainNavigation/src/Mobile/MobileNavMenuItems';
import { MobileNavFooter } from 'components/BKJMainNavigation/src/Mobile/MobileNavFooter';
import {
  MobileNavigationWrapper,
  MobileNavigationTitle,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';

export const MobilePageHeader: FC<IMobilePageHeaderProps> = ({
  title,
  children,
  ...rest
}): JSX.Element => {
  const disabled = useRef(false);

  const [isOpen, setOpen] = useState(false);
  const [isShowDesktopItems, setIsShowDesktopItems] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const onMenuClosed = useCallback(() => setOpen(false), []);
  const onMenuToggled = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <MobilePageHeaderWrapper {...rest}>
      <HamburgerMenu
        onMouseDown={onMenuToggled}
        iconName="HamburgerTablet"
        color="White"
        $isOpen={isOpen}
      />
      <MobileNavigationWrapper $isOpen={isOpen} ref={ref}>
        <MobileNavHeader onMouseDown={onMenuToggled} isOpen={isOpen} menuIcon="CloseBold" />
        <MobileNavMenuItems
          disabled={disabled}
          isOpen={isOpen}
          onClose={onMenuClosed}
          isShowDesktopItems={isShowDesktopItems}
        />
        <MobileNavFooter
          disabled={disabled}
          isOpen={isOpen}
          onClose={onMenuClosed}
          isShowDesktopItems={isShowDesktopItems}
          toggleDesktopItems={setIsShowDesktopItems}
        />
      </MobileNavigationWrapper>
      <MobileNavigationTitle>{title}</MobileNavigationTitle>
      {children}
    </MobilePageHeaderWrapper>
  );
};
