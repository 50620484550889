import { IBKJCalendarState } from './BKJCalendar.types';
import { BKJDatePickerValueType, BKJDatePickerType } from '../../../BKJDatePicker.types';
import { isISO8601Tuple, isISO8601Type, ISO8601Type } from 'types/common.types';
import { getCalendarMonthRange, getFirstDayOfThisMonth } from '../../../BKJDatePicker.utils';
import { DateTime } from 'luxon';

export const getCalendarDefaultViewFromType = ({ type }: { type: BKJDatePickerType }) => {
  switch (type) {
    case 'SingleMonth': {
      return 'year';
    }
    default: {
      return 'month';
    }
  }
};

export const getCalendarDefaultState = ({
  type,
  value,
}: {
  type: BKJDatePickerType;
  value: BKJDatePickerValueType;
}): IBKJCalendarState => {
  let _value = getFirstDayOfThisMonth();
  if (value && isISO8601Tuple(value)) {
    _value = value[0];
  } else if (value && isISO8601Type(value)) {
    _value = value;
  }
  return {
    calendarView: getCalendarDefaultViewFromType({ type }),
    dateRangeInMonthView: getCalendarMonthRange(_value),
  };
};

export const getYearInView = (date: ISO8601Type): number => DateTime.fromISO(date).get('year');
