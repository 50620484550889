import { FC, useContext, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useSSFBehaviors } from 'hooks';
import { groupsRulesConfig } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/GroupsRules.constants';
import { GQLAPIContextType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useLocation } from 'react-router-dom';
import { shiftDistributionRulesGroups } from 'types/graphql-types';

export const GroupsRulesDataContext =
  createGQLDataContext<GQLAPIContextType<shiftDistributionRulesGroups>>();

export const GroupsRulesDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];
  const [values, useQueryOption] = useSSFBehaviors(groupsRulesConfig(Number.parseInt(locationId)));

  const contextValues = useMemo(() => {
    return { values, ...useQueryOption };
  }, [values, useQueryOption]);

  return (
    <GroupsRulesDataContext.Provider value={contextValues}>
      {children}
    </GroupsRulesDataContext.Provider>
  );
};

export const useGroupsRulesDataContext = () =>
  useContext(GroupsRulesDataContext) as GQLAPIContextType<shiftDistributionRulesGroups>;
