import { BKJPagination } from '@bookjane2/bookjane-design-library';
import { BKJTableFooter } from 'components/BKJTable/BKJTable.styled';
import { Flex } from 'components/Flex';
import { AgencyMemberPageContext } from 'pages/AgencyMemberPage/AgencyMemberPage.context';
import { AgencyMemberTable } from 'pages/AgencyMemberTablePage/AgencyMemberTable';
import { useContext } from 'react';

export function AgencyMemberTablePage() {
  const { prevPage, nextPage, firstPage, lastPage, currentPage, data } =
    useContext(AgencyMemberPageContext);
  return (
    <>
      <AgencyMemberTable />
      <BKJTableFooter>
        <Flex width="100%" justifyContent="space-between">
          <BKJPagination
            currentPage={currentPage}
            pageCount={data?.fetchJanes?.pageCount}
            onFirstPage={firstPage}
            onPrevPage={prevPage}
            onNextPage={nextPage}
            onLastPage={lastPage}
          />
        </Flex>
      </BKJTableFooter>
    </>
  );
}
