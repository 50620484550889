import { BKJListItem, BKJSelect } from '@bookjane2/bookjane-design-library';
import { IBKJSelectProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { IShiftQuantitySelectInputRenderGroupProps } from 'components/ShiftQuantitySelectInput/ShiftQuantitySelectInput.types';
import { IShiftRecurringIntervalSelectInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/CreateShiftModal.types';
import { forwardRef, Fragment, RefObject } from 'react';

const RenderGroup = ({
  name,
  selected,
  options,
  onChange,
}: IShiftQuantitySelectInputRenderGroupProps) => {
  return (
    <Fragment>
      {options.map(({ key, label, value }) => {
        return (
          <BKJListItem
            key={key}
            value={value}
            label={label}
            name={name}
            onChange={onChange}
            selected={selected}
          />
        );
      })}
    </Fragment>
  );
};

export const ShiftRecurringIntervalSelectionInput = forwardRef<
  HTMLSelectElement,
  IShiftRecurringIntervalSelectInput
>(({ name, variant = 'GreyOutlined', width, value, onChange, ...props }, ref) => {
  return (
    <BKJSelect
      {...(props as IBKJSelectProps)}
      ref={ref as RefObject<HTMLSelectElement>}
      type="Single"
      variant={variant}
      width={width}
      name={name}
      value={value as string[]}
      onChange={onChange}
      renderOptions={RenderGroup as unknown as IBKJSelectProps['renderOptions']}
    />
  );
});
