import { QuerySwitch } from 'components/QuerySwitch';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { API_GET_shiftSwap } from 'requests/GET_ShiftSwap';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { createLegacyDataContext } from 'utils/createDataContext';

export const ShiftSwapLocationSettingsPageDataContext = createLegacyDataContext({});

interface IData {
  shift_swap_enabled: boolean;
  min_hours_before_shift_swap: number;
}

const getDefaultData = (): IData => {
  let defaultValues: IData = {
    shift_swap_enabled: false,
    min_hours_before_shift_swap: 8,
  };

  return defaultValues;
};
interface IShiftSwapState {
  form: IData;
}

const getInitialState = (): IShiftSwapState => ({
  form: getDefaultData(),
});

function responseTransformer(community: {
  min_hours_before_shift_swap: number;
  shift_swap_enabled: boolean;
}): IShiftSwapState {
  const data: IData = {
    min_hours_before_shift_swap: community.min_hours_before_shift_swap,
    shift_swap_enabled: community.shift_swap_enabled,
  };

  const payload = {
    form: { ...data },
  };
  return payload;
}

export const ShiftSwapLocationSettingsPageDataProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const { locationId } = useParams<{ locationId: string }>();

  const requestOptions = API_GET_shiftSwap(locationId);
  const [data, setData] = useState<IShiftSwapState>(getInitialState());
  const request = useSelector((state) => apiDataSelector(state, requestOptions));
  useEffect(() => {
    apiRequest(requestOptions, {
      onSuccess: async (response) => {
        setData({
          ...responseTransformer(response.data.community),
        });
      },
      onError: async (err) => {
        setData({
          ...data,
        });
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ShiftSwapLocationSettingsPageDataContext.Provider value={{ ...request, data }}>
      <QuerySwitch
        component={children}
        context={API_GET_shiftSwap(locationId)}
        overrides={['NO_ERROR']}
      />
    </ShiftSwapLocationSettingsPageDataContext.Provider>
  );
};

export const useShiftSwapLocationSettingsPageDataContext = () =>
  useContext<{ data: IShiftSwapState }>(ShiftSwapLocationSettingsPageDataContext);
