import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const ViewGroupWrapper = styled.div`
  padding: 40px 10px 15px 32px;
`;

export const CriteriaWrapper = styled.div`
  padding: 30px 10px 15px 32px;

  width: 100%;
`;

export const ViewGroupContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 20px;
`;

export const HeaderSection = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  padding-bottom: 16px;
`;

export const SubHeaderSection = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;

  padding-bottom: 5px;
`;

export const TextColumn = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  width: 50%;
  padding-left: 10px;
`;

export const ViewCriteriaHeaderSection = styled(HeaderSection)`
  padding-top: 5px;
  padding-bottom: 25px;
`;
