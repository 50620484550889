import { BKJComboBox } from '@bookjane2/bookjane-design-library';
import { FC } from 'react';
import { shiftTimeDropdownOptions } from './ShiftTimeSelectInput.constants';
import { IShiftTimeSelectInputProps } from './ShiftTimeSelectInput.types';

export const ShiftTimeSelectInput: FC<IShiftTimeSelectInputProps> = ({
  name,
  disabled = false,
  placeholder,
  value,
  onChange,
  label,
  error,
}) => {
  return (
    <BKJComboBox
      leftIconProps={{ iconName: 'ShiftsTime' }}
      disabled={disabled}
      error={error}
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      options={shiftTimeDropdownOptions}
      type="Single"
      action="OpenToggle"
      filter="TimeStartsWith"
    />
  );
};
