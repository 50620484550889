import { BKJComboBox } from '@bookjane2/bookjane-design-library';
import { FC } from 'react';
import { shiftQuantityDropdownOptions } from './ShiftQuantitySelectInput.constants';
import { IShiftQuantitySelectInputProps } from './ShiftQuantitySelectInput.types';

export const ShiftQuantitySelectInput: FC<IShiftQuantitySelectInputProps> = ({
  name,
  placeholder,
  value,
  onChange,
  label,
}) => {
  return (
    <BKJComboBox
      options={shiftQuantityDropdownOptions}
      placeholder={placeholder}
      label={label}
      type="Single"
      name={name}
      value={value}
      onChange={onChange}
      action="OpenToggle"
    />
  );
};
