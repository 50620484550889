import { Flex } from 'components/Flex/Flex';
import styled from 'styled-components';

export const HeaderWrapper = styled(Flex)`
  padding: 40px 0px 0px 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
`;

export const MainTitleContainer = styled(Flex)`
  padding: 0px 10px 0px 30px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 28px;
  font-weight: 600;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 0 20px;
    flex-direction: column;
  }
`;

export const ContentWrapper = styled(Flex)`
  padding: 40px 10px 0px 30px;
  flex-direction: column;
  gap: 30px;
  ${({ theme }) => theme.fonts.Poppins};
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;
