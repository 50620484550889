import { TeamMemberAccessStatusColorEnum } from 'pages/TeamMemberPage/components/TeamMemberAccessStatus/TeamMemberAccessStatus.types';
import {
  TeamMemberPageAvatar,
  TeamMemberToolTip,
} from 'pages/TeamMemberPage/components/TeamMemberAvatar/TeamMemberAvatar.styled';
import { Fragment } from 'react';
import { css } from 'styled-components';
import { AccessStatusEnum } from 'types/graphql-types';

export function TeamMemberAvatar(props: {
  avatarUrls: { origin: string | null };
  accessStatus: {
    name: AccessStatusEnum;
  };
  pendingSignup: boolean;
}) {
  const { avatarUrls = { origin: '' }, pendingSignup, accessStatus } = props;
  let accessStatusName = accessStatus?.name;

  return (
    <Fragment>
      <TeamMemberPageAvatar
        src={avatarUrls?.origin as unknown as string}
        width="35px"
        height="35px"
        size={62}
        fallbackAvatarColor={TeamMemberAccessStatusColorEnum['default']}
        accessStatusName={accessStatusName}
        css={css`
          margin-right: 15px;
        `}
        showPendingSignUp={pendingSignup}
      />
      {pendingSignup && <TeamMemberToolTip />}
    </Fragment>
  );
}
