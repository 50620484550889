import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { IGlobalReduxStore } from 'store';

const getAuth = (state: IGlobalReduxStore) => state.auth;

export const isGlobalLoadingSelector = createCachedSelector(
  getAuth,
  (auth) => auth.isLoading,
)((store) => `${store.auth.isLoading}`);

export const useIsGlobalLoadingSelector = () => useSelector(isGlobalLoadingSelector);
