import { AppLink } from 'components/AppLink/AppLink';
import {
  NavigationButtonsWrapper,
  MobileNavigationButtonsWrapper,
  MobileNavigationWrapper,
  MobileNavigationButton,
  MobileAppLink,
} from 'components/TabNavigation/TabNavigation.styled';
import { ITabItem, ITabNavigation } from 'components/TabNavigation/TabNavigation.types';
import { TabNavigationButton } from 'components/TabNavigationButton';
import { isArray } from 'types/common.types';
import { useTranslation } from 'hooks/useTranslation';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';

function isActive(activeTab: string, activePaths: string | string[]): boolean {
  if (isArray(activePaths)) {
    return activePaths.some((__path__) => {
      return activeTab.includes(__path__);
    });
  }

  return activeTab === activePaths;
}

const MobileTabNavigation: React.FC<ITabNavigation> = ({ activeTab, tabs, className }) => {
  const { t, i18n } = useTranslation();
  return (
    <MobileNavigationWrapper>
      <MobileNavigationButtonsWrapper role="tablist" className={className}>
        {tabs.map(({ label, path, onClick, activePaths }: ITabItem, index) => (
          <MobileAppLink
            data-cy="tab-facility-lbl"
            to={`${path}`}
            key={`tab-${index}`}
            onClick={activeTab === path ? undefined : onClick}
          >
            <MobileNavigationButton
              isActive={isActive(activeTab, activePaths ? activePaths : path)}
            >
              {i18n.exists(`tab_navigation:${label.toUpperCase()}`)
                ? t(`tab_navigation:${label.toUpperCase()}`)
                : label}
            </MobileNavigationButton>
          </MobileAppLink>
        ))}
      </MobileNavigationButtonsWrapper>
    </MobileNavigationWrapper>
  );
};

const WebTabNavigation: React.FC<ITabNavigation> = ({ activeTab, tabs, className }) => {
  const { t, i18n } = useTranslation();
  return (
    <NavigationButtonsWrapper role="tablist" className={className}>
      {tabs.map(({ label, path, onClick, activePaths }: ITabItem, index) => (
        <AppLink
          data-cy="tab-facility-lbl"
          to={`${path}`}
          key={`tab-${index}`}
          onClick={activeTab === path ? undefined : onClick}
        >
          <TabNavigationButton isActive={isActive(activeTab, activePaths ? activePaths : path)}>
            {i18n.exists(`tab_navigation:${label.toUpperCase()}`)
              ? t(`tab_navigation:${label.toUpperCase()}`)
              : label}
          </TabNavigationButton>
        </AppLink>
      ))}
    </NavigationButtonsWrapper>
  );
};

export const TabNavigation: React.FC<ITabNavigation> = ({ activeTab, tabs, className }) => {
  const { isMobile } = useResponsive();
  const TabNavigationComponent = isMobile ? MobileTabNavigation : WebTabNavigation;
  return <TabNavigationComponent activeTab={activeTab} tabs={tabs} className={className} />;
};
