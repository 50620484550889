import styled from 'styled-components';

export const MainTitle = styled.h1`
  ${({ theme }) => theme.typography.H3};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 34px;
  margin-bottom: 8px;
`;

export const FormLabel = styled.div<{ marginTop?: number }>`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
  text-transform: capitalize;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
`;

export const MobileLabel = styled.span`
  text-transform: none;
  ${({ theme }) => theme.fonts.PoppinsTextLight};
  color: ${({ theme }) => theme.colors.Grey6};
`;

export const CustomFieldsFormLabel = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
`;

export const Caption = styled.div`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const TextAreaWrapper = styled.div`
  margin-top: 32px;
`;
