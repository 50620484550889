import { Flex } from 'components/Flex';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';

export const AvailabilityEmptyState = () => {
  const { t } = useTranslation();
  return (
    <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
      {t('shift_modal:NO_AVAILABLE_TEAM_MEMBERS')}
    </Flex>
  );
};
