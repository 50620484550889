import {
  BKJButton,
  BKJCheckbox,
  BKJComboBox,
  BKJIcon,
  BKJTextInput,
  BKJToggleInput,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { getPrettyDayAndDateFromDate } from 'components/BKJDatePicker/src/BKJDatePicker.utils';
import { BKJFieldSet, BKJForm, BKJFieldErrorMessage } from 'components/FormWrapper';
import { ModalLinkText } from 'components/ModalLinkText';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { H3, P } from 'components/Typography';
import { ViewMoreButton } from 'components/ViewMoreButton';
import { HARDCODED_SHOW_ADVANCED_SETTINGS } from 'constants/hardcoded';
import { useFeatureSelector } from 'guards/useFeatureSelector';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { UseFormBehaviorsEventType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';
import { ApplyAvailabilityToOtherLocationsModal } from 'pages/LocationSettingsPage/ApplyAvailabilityToOtherLocationsModal';
import { AvailabilityLocationSettingsPageDataProvider } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPage.data.context';
import { availabilityDurationOptions } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.constants';
import {
  AvailabilityLocationSettingsPageFormProvider,
  useAvailabilityLocationSettingsPageFormContext,
} from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.form.context';
import {
  GreyText,
  ReadViewTableCell,
  ReadViewTableWrapper,
  TableCell,
  TableCellCentered,
  TableHeader,
  TableWrapper,
} from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.styled';
import { useLocationRecordDetailDataContext } from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { BKJFormLabel, H4 } from 'pages/SettingsPage/SettingsPage.styled';
import pluralize from 'pluralize';
import { FC, FormEvent, Fragment, useRef, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

const AdvancedOptionsForm: FC = () => {
  const { values, onChange } = useAvailabilityLocationSettingsPageFormContext();
  const isTieringEnabled = useFeatureSelector('FEATURE_TYPE_TIERING');
  const [isWarningOpen, setWarningOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Fragment>
      {isWarningOpen && (
        <BKJCenteredDialog
          variant="Danger"
          title={t('location_settings:RESET_FOR_OTHER_POSITIONS')}
          text={t('location_settings:RESET_FOR_OTHER_POSITIONS_INFO')}
          isOpen={isWarningOpen}
          cancelLabel={t('auth:CANCEL')}
          actionLabel={t('auth:CONFIRM')}
          onAction={() => {
            onChange({ target: { name: 'isApplyToFacilitiesModalOpen', value: true } });
            setWarningOpen(false);
          }}
          onClose={() => {
            setWarningOpen(false);
          }}
          onCancel={() => {
            setWarningOpen(false);
          }}
        />
      )}
      {isTieringEnabled ? (
        <TableWrapper>
          <TableHeader>{t('location_settings:SHIFT_URGENCY')}</TableHeader>
          <TableHeader>{t('location_settings:NOTIFY_TEAM_MEMBERS_INFO')}</TableHeader>
          <TableCell>{t('location_settings:CRITICAL')}</TableCell>
          <TableCellCentered>
            <BKJCheckbox
              variant="GreenSolid"
              name="critical"
              value={values.critical}
              onChange={onChange}
            />
          </TableCellCentered>
          <TableCell>{t('location_settings:HIGH')}</TableCell>
          <TableCellCentered>
            <BKJCheckbox
              variant="GreenSolid"
              name="high_priority"
              value={values.high_priority}
              onChange={onChange}
            />
          </TableCellCentered>
          <TableCell>{t('location_settings:MEDIUM')}</TableCell>
          <TableCellCentered>
            <BKJCheckbox
              variant="GreenSolid"
              name="vacation_loa"
              value={values.vacation_loa}
              onChange={onChange}
            />
          </TableCellCentered>
          <TableCell>{t('location_settings:LOW')}</TableCell>
          <TableCellCentered>
            <BKJCheckbox
              variant="GreenSolid"
              name="shift_exchange"
              value={values.shift_exchange}
              onChange={onChange}
            />
          </TableCellCentered>
        </TableWrapper>
      ) : (
        <Flex flexDirection="column">
          <BKJFormLabel>{t('location_settings:NUMBER_OF_OPEN_SHIFTS')}:</BKJFormLabel>
          <Flex alignItems="center">
            <Flex maxWidth="70px">
              <BKJTextInput
                type="number"
                name="waiting_hours"
                onChange={onChange}
                value={values.waiting_hours}
                min={1}
                max={168}
              />
            </Flex>
            &nbsp;&nbsp;&nbsp;
            <P>{t('location_settings:HOURS_BEFORE')}</P>
          </Flex>
        </Flex>
      )}
      <Flex width="100%" alignItems="center">
        <ModalLinkText
          type="button"
          onClick={() => {
            setWarningOpen(true);
          }}
        >
          {t('location_settings:APPLY_TO_ALL_LOCATIONS')}&nbsp;
          <GreyText>{`${
            values.communityIds.length - 1 > 0
              ? `(${values.communityIds.length - 1} ${t('common:SELECTED')})`
              : ''
          }`}</GreyText>
        </ModalLinkText>
      </Flex>
    </Fragment>
  );
};

const InnerAvailabilityForm: FC = () => {
  const { values, onChange, apiErrors, fieldErrors } =
    useAvailabilityLocationSettingsPageFormContext();

  const { t } = useTranslation();

  return (
    <Fragment>
      <BKJFieldSet width="414px" maxWidth="414px" gap="14px">
        <ShiftDateCalendarInput
          dropdownVariant="Square"
          required
          label={t('location_settings:START_DATE')}
          name="start_at"
          value={values.start_at}
          error={fieldErrors.start_at || apiErrors?.error_messages?.end_at}
          onChange={onChange}
          isEventValueNormalized
          iconComponent={BKJIcon}
          iconProps={{
            iconName: 'ShiftsDate',
          }}
          dropdownProps={{
            disabledTo: DateTime.now().minus({ day: 1 }).toString(),
            disabledFrom: DateTime.now().plus({ month: 6 }).toString(),
          }}
        />
        <BKJComboBox
          type="Single"
          action="OpenToggle"
          label={t('location_settings:SELECT_DURATION')}
          required
          name="timespan"
          options={availabilityDurationOptions()}
          onChange={onChange}
          value={values.timespan}
        />
      </BKJFieldSet>
      <Flex flexDirection="column">
        <BKJFormLabel>{t('location_settings:SET_DEADLINE')}</BKJFormLabel>
        <Flex alignItems="center">
          <Flex maxWidth="70px">
            <BKJTextInput
              type="number"
              name="deadline"
              onChange={onChange}
              value={values.deadline}
              error={!!apiErrors?.error_messages?.deadline}
              min={1}
              max={90}
            />
          </Flex>
          &nbsp;&nbsp;&nbsp;
          <P error={!!apiErrors?.error_messages?.deadline}>{t('location_settings:DAYS_BEFORE')}</P>
        </Flex>
        {apiErrors?.error_messages?.deadline && (
          <BKJFieldErrorMessage>{apiErrors?.error_messages?.deadline}</BKJFieldErrorMessage>
        )}
      </Flex>
      <Flex alignItems="center">
        <BKJFormLabel>{t('location_settings:INCLUDE_MOBILE')}</BKJFormLabel>
        &nbsp;&nbsp;&nbsp;
        <BKJToggleInput
          variant="Switch"
          onChange={onChange}
          name="non_mobile"
          checked={values.non_mobile}
        />
      </Flex>
      {HARDCODED_SHOW_ADVANCED_SETTINGS && (
        <ViewMoreButton
          component={AdvancedOptionsForm}
          render={({ isOpen }) => (
            <Fragment>{`${isOpen ? 'Hide' : 'View'} Advanced Settings`}</Fragment>
          )}
        />
      )}
    </Fragment>
  );
};

const EditView: FC = () => {
  const eventRef = useRef<Nullable<UseFormBehaviorsEventType | UseFormBehaviorsEventType[]>>(null);
  const { data } = useLocationRecordDetailDataContext();
  const [isWarningOpen, setWarningOpen] = useState(false);
  const { onSubmit, values, onChange } = useAvailabilityLocationSettingsPageFormContext();

  const { t } = useTranslation();

  return (
    <Fragment>
      {isWarningOpen && (
        <BKJCenteredDialog
          variant="Danger"
          title={t('location_settings:TURN_OFF')}
          text={t('location_settings:AVAILABILITY_WILL_NO_LONGER_INFO')}
          isOpen={isWarningOpen}
          cancelLabel={t('auth:CANCEL')}
          actionLabel={t('auth:CONFIRM')}
          onAction={() => {
            onSubmit(eventRef.current! as unknown as FormEvent<HTMLFormElement>);
            eventRef.current = null;
          }}
          onClose={() => {
            onChange({ target: { name: 'isAvailabilityEnabled', value: true } });
            setWarningOpen(false);
          }}
          onCancel={() => {
            onChange({ target: { name: 'isAvailabilityEnabled', value: true } });
            setWarningOpen(false);
          }}
        />
      )}
      <BKJForm onSubmit={onSubmit} width="672px" maxWidth="672px" gap="22px">
        <H3>{data.name}</H3>
        <P>{t('location_settings:AVAILABILITY_INFO')}</P>
        <Flex alignItems="center">
          <BKJFormLabel>
            {t('location_settings:TURN_ON_OFF_AVAILABILITY', {
              value:
                values.isAvailabilityEnabled || isWarningOpen
                  ? t('location_settings:OFF')
                  : t('location_settings:ON'),
            })}
          </BKJFormLabel>
          &nbsp;&nbsp;&nbsp;
          <BKJToggleInput
            variant="Switch"
            onChange={(event) => {
              onChange(event);
              if (values.isAvailabilityEnabled) {
                eventRef.current = event;
                setWarningOpen(true);
              }
            }}
            name="isAvailabilityEnabled"
            checked={values.isAvailabilityEnabled || isWarningOpen}
          />
        </Flex>

        {(values.isAvailabilityEnabled || isWarningOpen) && (
          <Fragment>
            <InnerAvailabilityForm />
            <BKJButton variant="PurpleSolid" width="192px" minWidth="192px" type="submit">
              {t('auth:SAVE')}
            </BKJButton>
          </Fragment>
        )}
      </BKJForm>
      <ApplyAvailabilityToOtherLocationsModal />
    </Fragment>
  );
};

const ReadView: FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { values } = useAvailabilityLocationSettingsPageFormContext();
  const isTieringEnabled = useFeatureSelector('FEATURE_TYPE_TIERING');
  const { data } = useLocationRecordDetailDataContext();
  const [isWarningOpen, setWarningOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Fragment>
      {isWarningOpen && (
        <BKJCenteredDialog
          variant="Danger"
          title={t('location_settings:RESET_AVAILABILITY_SETTINGS')}
          text={t('location_settings:RESET_AVAILABILITY_SETTINGS_DESCRIPTION')}
          isOpen={isWarningOpen}
          cancelLabel={t('auth:CANCEL')}
          actionLabel={t('auth:CONFIRM')}
          onAction={() => {
            setWarningOpen(false);
            history.push(`${match.url}/edit`);
          }}
          onClose={() => {
            setWarningOpen(false);
          }}
          onCancel={() => {
            setWarningOpen(false);
          }}
        />
      )}
      <Flex flexDirection="column" gap="22px">
        <Flex justifyContent="space-between">
          <H3>{data.name}</H3>
          <UserGuard
            userType={[UserPermissionEnum.USER_TYPE_AGENCY, UserPermissionEnum.USER_TYPE_B2B]}
          >
            <BKJButton
              variant="PurpleOutlined"
              width="192px"
              minWidth="192px"
              onClick={() => {
                if (values.isAvailabilityEnabled) {
                  setWarningOpen(true);
                } else {
                  history.push(`${match.url}/edit`);
                }
              }}
            >
              {t('location_settings:EDIT')}
            </BKJButton>
          </UserGuard>
        </Flex>
        <P></P>
        <P bold>
          {t('location_settings:AVAILABILITY_INFO')}:&nbsp;
          <P>
            {values.isAvailabilityEnabled ? t('location_settings:ON') : t('location_settings:OFF')}
          </P>
        </P>
        {values.isAvailabilityEnabled && (
          <Fragment>
            <ReadViewTableWrapper>
              <ReadViewTableCell bgColor="Grey1" topBorder>
                {t('location_settings:START_DATE_INFO')}:
              </ReadViewTableCell>
              <ReadViewTableCell bgColor="Grey1" topBorder>
                {getPrettyDayAndDateFromDate(values.start_at, false)}
              </ReadViewTableCell>
              <ReadViewTableCell>{t('location_settings:DURATION_INFO')}:</ReadViewTableCell>
              <ReadViewTableCell>
                {values.timespan}&nbsp;
                {pluralize(t('WEEK'), Number.parseInt(values.timespan[0]))}
              </ReadViewTableCell>
              <ReadViewTableCell bgColor="Grey1">
                {t('location_settings:DEADLINE_INFO')}:
              </ReadViewTableCell>
              <ReadViewTableCell bgColor="Grey1">
                {values.deadline}&nbsp;
                {pluralize(t('location_settings:DAY'), Number.parseInt(values.deadline))}
                &nbsp;{t('location_settings:BEFORE_THE_START')}
              </ReadViewTableCell>
            </ReadViewTableWrapper>
            {HARDCODED_SHOW_ADVANCED_SETTINGS && (
              <Fragment>
                <H4>Advanced Settings</H4>
                <P>
                  Notify available team members first about open shifts for the following shift
                  urgency levels:
                </P>

                {isTieringEnabled ? (
                  <TableWrapper>
                    <TableHeader>Shift Urgency</TableHeader>
                    <TableHeader>Notify available team members first</TableHeader>
                    <TableCell>Critical</TableCell>

                    <TableCellCentered>
                      {values.critical && <BKJIcon iconName="Checkmark" />}
                    </TableCellCentered>
                    <TableCell>High</TableCell>
                    <TableCellCentered>
                      {values.high_priority && <BKJIcon iconName="Checkmark" />}
                    </TableCellCentered>
                    <TableCell>Medium</TableCell>
                    <TableCellCentered>
                      {values.vacation_loa && <BKJIcon iconName="Checkmark" />}
                    </TableCellCentered>
                    <TableCell>Low</TableCell>
                    <TableCellCentered>
                      {values.shift_exchange && <BKJIcon iconName="Checkmark" />}
                    </TableCellCentered>
                  </TableWrapper>
                ) : (
                  <ReadViewTableWrapper>
                    <ReadViewTableCell bgColor="Grey1" topBorder>
                      Open shifts stay in availability pool:
                    </ReadViewTableCell>
                    <ReadViewTableCell bgColor="Grey1" topBorder>
                      {values.waiting_hours}&nbsp;
                      {pluralize('hour', Number.parseInt(values.waiting_hours))}&nbsp;before it
                      becomes available to all team members
                    </ReadViewTableCell>
                  </ReadViewTableWrapper>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Flex>
    </Fragment>
  );
};

const Component: FC = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path} component={ReadView} />
      <Route path={`${match.path}/edit`} component={EditView} />
    </Switch>
  );
};

export const AvailabilityLocationSettingsPageForm = () => {
  return (
    <AvailabilityLocationSettingsPageDataProvider>
      <AvailabilityLocationSettingsPageFormProvider>
        <Component />
      </AvailabilityLocationSettingsPageFormProvider>
    </AvailabilityLocationSettingsPageDataProvider>
  );
};
