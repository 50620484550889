import { J360UserType } from 'guards/UserGuard.types';
import { SessionService } from 'services';
import { useUserTypeSelector } from 'store/selectors/userSelectors/userTypeSelector';

export function useUserTypeSwitch<T>(mapping: Record<J360UserType, unknown>): T {
  const userType = useUserTypeSelector();
  if (!userType) {
    const user = SessionService.getUser();
    // If we don't have the user in Redux we can extract the user type from typename property of the GraphQL me query...
    const userTypename = user ? (user as unknown as { typename: string })?.typename : null;
    if (userTypename === 'AgencyUser') return mapping.Agency as T;
    else return mapping.B2B as T;
  }
  switch (userType) {
    case 'B2B':
      return mapping[userType] as T;
    case 'Agency':
      return mapping[userType] as T;
  }
}
