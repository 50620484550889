import { BKJIcon } from '@bookjane2/bookjane-design-library';

import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { prettyShiftTime } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/components/ShiftTimeAndPosition/ShiftTimeAndPosition.utils';
import {
  ShiftTimeWrapper,
  PositionNameCaption,
  ShiftTimeCaption,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/components/ShiftTimeAndPosition/ShiftTimeAndPosition.styled';
import {
  PositionNameLabelProvider,
  usePositionNameLabelContext,
} from 'components/PositionNameLabel/PositionNameLabel.context';

import { useContext } from 'react';
import { castStringToInteger } from 'utils';
import { useTranslation } from 'react-i18next';

const PositionLabel = () => {
  const { positionName } = usePositionNameLabelContext();
  return <PositionNameCaption>{positionName || ' '}</PositionNameCaption>;
};

export function ShiftTimeAndPosition() {
  const { createShift } = useContext(CreateShiftCardContext);
  const { position_id, startDate, startAt, endAt } = createShift.values;
  const { i18n } = useTranslation();
  return (
    <>
      <ShiftTimeWrapper alignItems="center">
        <BKJIcon iconName="ShiftsTime" />
        <ShiftTimeCaption>
          {prettyShiftTime(startDate, startAt[0], endAt[0], i18n.language)}
        </ShiftTimeCaption>
      </ShiftTimeWrapper>
      <PositionNameLabelProvider position_id={castStringToInteger(position_id[0])}>
        <PositionLabel />
      </PositionNameLabelProvider>
    </>
  );
}
