// @ts-nocheck

import { bindActionCreators, createAsyncThunk } from '@reduxjs/toolkit';
import { createBookJaneRequest } from 'utils/createBookJaneRequest';
import { defaultMapper } from 'mappers/defaultMapper';
import { useDispatch } from 'react-redux';
import { createAPIActionType, generateRequestId, trimBaseURL } from './utils';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiRequestConfigType } from 'requests/index.types';

export function apiRequest(
  opts: ApiRequestConfigType,
  additionalOpts?: {
    onSuccess?: (response: AxiosResponse) => Promise;
    onError?: (response: AxiosResponse) => Promise;
  } = {
    onSuccess: async (response: AxiosResponse) => null,
    onError: async (error: AxiosError) => null,
  },
) {
  const { body, method, query, url, responseTransformer, namespace = '', queryStringOpts } = opts;
  const { onSuccess, onError } = additionalOpts;
  const config = {};
  if (body) config.body = body;
  if (query) config.query = query;
  if (queryStringOpts) config.queryStringOpts = queryStringOpts;
  config.url = url;
  config.method = method;

  const actionType = createAPIActionType({ method, query, url, namespace });
  return createAsyncThunk(
    `api/${actionType}${namespace ? `_${namespace}` : ''}}`,
    async (args, { rejectWithValue }) => {
      try {
        const response = await createBookJaneRequest(config);
        if (onSuccess) onSuccess(response, responseTransformer);
        return { response, responseTransformer };
      } catch (error) {
        if (onError) onError(error.response);
        return rejectWithValue(error.response);
      }
    },
  )({ url, ...opts });
}

export function apiRequestSeries(
  requestOptions: ApiRequestConfigType[] = [],
  opts: Partial<{
    onEachSuccess: () => void;
    onSuccess: () => void;
    onError: () => void;
    onEachError: () => void;
  }> = {
    onEachSuccess: () => {},
    onSuccess: () => {},
    onError: (error: AxiosError) => {},
    onEachError: () => {},
  },
) {
  return async (dispatch) => {
    const { onSuccess, onEachSuccess, onError, onEachError } = opts;
    try {
      const results = [];
      for (const requestOption of requestOptions) {
        const action = await dispatch(
          apiRequest(requestOption, { onError: onEachError || onError }),
        );
        if (onEachSuccess) onEachSuccess(action);
        results.push(action);
      }
      if (onSuccess) onSuccess(results);

      return results;
    } catch (error) {
      if (onError) onError(error);

      return error;
    }
  };
}

export function apiRequestParallel(
  requestOptions: ApiRequestConfigType[] = [],
  opts: Partial<{
    onEachSuccess: () => void;
    onSuccess: () => void;
    onError: (error: AxiosError) => void;
  }> = {
    onEachSuccess: () => {},
    onSuccess: () => {},
    onError: () => {},
  },
) {
  return async (dispatch) => {
    const { onSuccess, onEachSuccess, onError } = opts;
    try {
      const results = await Promise.all(
        requestOptions.map(async (request) => {
          const result = await dispatch(apiRequest(request));
          if (onEachSuccess) onEachSuccess(result);
          return result;
        }),
      );
      if (onSuccess) onSuccess(results);
      return results;
    } catch (error) {
      if (onError) onError(error);
      return error;
    }
  };
}

export function useAPIActions() {
  return bindActionCreators({ apiRequest, apiRequestSeries, apiRequestParallel }, useDispatch());
}

export function apiReducer(state = {}, action) {
  try {
    const split = action.type.split('/');
    const isActionTypeAPI = split[0] === 'api';
    const timestamp_utc = new Date().getTime();
    if (isActionTypeAPI) {
      const actionStatus = split[split.length - 1];
      switch (actionStatus) {
        case 'pending': {
          const { query } = action.meta.arg;
          const { requestId: request_id, arg } = action.meta;
          const { method, url, namespace } = arg;
          const key = generateRequestId({ method, url, query, namespace });
          return {
            ...state,
            [key]: {
              data: state[key] && state[key]?.data ? state[key]?.data : null,
              error: null,
              isError: false,
              isLoading: true,
              isPending: true,
              method,
              query: query || {},
              request_id,
              status: 'LOADING',
              statusText: null,
              statusCode: null,
              timestamp_utc,
              url: trimBaseURL(url),
            },
          };
        }
        case 'fulfilled': {
          const { query, namespace } = action.meta.arg;
          const { requestId: request_id } = action.meta;
          const {
            response: {
              data,
              status: statusCode,
              statusText,
              config: { method, url },
            },
            responseTransformer = defaultMapper,
          } = action.payload;
          const key = generateRequestId({ method, url, query, namespace });
          return {
            ...state,
            [key]: {
              ...state[key],
              data: responseTransformer(data),
              error: null,
              isError: false,
              isLoading: false,
              isPending: false,
              query: query || {},
              request_id,
              status: 'READY',
              statusText,
              statusCode,
              timestamp_utc,
            },
          };
        }
        case 'rejected': {
          const { query, method, url, namespace } = action.meta.arg;
          const { requestId: request_id } = action.meta;
          const { data: error, status: statusCode, statusText } = action.payload;
          const key = generateRequestId({ method, url, query, namespace });
          return {
            ...state,
            [key]: {
              ...state[key],
              status: 'ERROR',
              data: null,
              error,
              isError: true,
              isLoading: false,
              isPending: false,
              query: query || {},
              statusCode,
              statusText,
              request_id,
              timestamp_utc,
            },
          };
        }
        default:
          return state;
      }
    }
    return state;
  } catch (error) {
    throw new Error(error);
  }
}
