import { BKJLoadingSpinner } from '@bookjane2/bookjane-design-library';
import { BKJCenteredDialogChildren } from 'components/BKJCenteredDialogChildren';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import {
  ContinueCalloutDialogDataProvider,
  useContinueCalloutDialogDataContext,
} from 'components/ContinueCalloutDialog/ContinueCalloutDialog.data.context';
import {
  ContinueCalloutDialogFormProvider,
  IContinueCalloutDialogEnableOvertimeForm,
  useContinueCalloutDialogFormContext,
} from 'components/ContinueCalloutDialog/ContinueCalloutDialog.form.context';
import {
  GroupName,
  TextAlignCenter,
} from 'components/ContinueCalloutDialog/ContinueCalloutDialog.styled';
import { ContinueCalloutDialogProps } from 'components/ContinueCalloutDialog/ContinueCalloutDialog.types';
import { Flex } from 'components/Flex';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EnableOvertimeStrategyEnum } from 'types/graphql-types';

const ContinueCalloutAllowOvertimeView: FC<
  UseFormBehaviors<IContinueCalloutDialogEnableOvertimeForm>
> = ({ values, onChange }) => (
  <Flex flexDirection="column" padding="0 0 10px 90px">
    <BKJRadioInput
      name="enableOvertime"
      value={EnableOvertimeStrategyEnum.NO_RESTART}
      onChange={onChange}
      checked={values.enableOvertime === EnableOvertimeStrategyEnum.NO_RESTART}
      label="Allow Overtime and Continue Call Out"
    />
    <BKJRadioInput
      name="enableOvertime"
      value={'null'}
      onChange={onChange}
      checked={values.enableOvertime === 'null'}
      label="Do not Allow Overtime and Continue Call Out"
    />
  </Flex>
);

const ContinueCaloutNoOvertiemView: FC<{ nextGroupName: string }> = ({ nextGroupName }) => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      padding="0 50px 10px 50px"
      flexDirection="row"
    >
      <TextAlignCenter>
        {t('dialog:CONTINUE_CALL_OUT_APPROVAL_MESSAGE')} <GroupName>{nextGroupName}</GroupName>.
      </TextAlignCenter>
    </Flex>
  );
};

const DialogViewComponent: FC = () => {
  const { isLoading, isAllowOvertime, nextGroupName } = useContinueCalloutDialogDataContext();
  const { handleContinueCallout, ...rest } = useContinueCalloutDialogFormContext();

  if (isLoading) return <BKJLoadingSpinner variant="Default" />;
  if (isAllowOvertime) return <ContinueCaloutNoOvertiemView nextGroupName={nextGroupName} />;

  return <ContinueCalloutAllowOvertimeView {...rest} />;
};

const ContinueCalloutDialogView: FC<ContinueCalloutDialogProps> = ({
  isContinueCallOutConfirmationOpen,
  setIsContinueCallOutConfirmationOpen,
}) => {
  const { t } = useTranslation();
  const { isAllowOvertime, isLoading } = useContinueCalloutDialogDataContext();
  const { handleContinueCallout } = useContinueCalloutDialogFormContext();

  const centerDialogTitle = useMemo(() => {
    if (isLoading) return '';
    if (isAllowOvertime) return t('dialog:CONTINUE_CALL_OUT_CONFIRMATION');
    return t('dialog:ALLOW_OVERTIME_CONFIRMATION');
  }, [isAllowOvertime, isLoading, t]);

  return (
    <BKJCenteredDialogChildren
      isOpen={isContinueCallOutConfirmationOpen}
      variant="Default"
      title={centerDialogTitle}
      cancelLabel={t('auth:CANCEL')}
      actionLabel={t('auth:CONFIRM')}
      onAction={() => {
        setIsContinueCallOutConfirmationOpen(false);
        return handleContinueCallout();
      }}
      onCancel={() => setIsContinueCallOutConfirmationOpen(false)}
      onClose={() => setIsContinueCallOutConfirmationOpen(false)}
    >
      <DialogViewComponent />
    </BKJCenteredDialogChildren>
  );
};

export const ContinueCalloutDialog: FC<ContinueCalloutDialogProps> = (props) => {
  if (!props.isContinueCallOutConfirmationOpen) return null;
  return (
    <ContinueCalloutDialogDataProvider orderId={props.orderId}>
      <ContinueCalloutDialogFormProvider {...props}>
        <ContinueCalloutDialogView {...props} />
      </ContinueCalloutDialogFormProvider>
    </ContinueCalloutDialogDataProvider>
  );
};
