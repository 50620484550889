import { FC } from 'react';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import { ShiftFooterLabel } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.styled';
import { useTranslation } from 'hooks/useTranslation';

export const ShiftPayRange: FC<{
  createShift: ICreateShift;
}> = ({ createShift }) => {
  const { t } = useTranslation();
  const { values } = createShift;
  const { min_rate, max_rate, dedicated } = values;

  if (!min_rate || !max_rate) {
    return null;
  }

  const priceRangeString = `${min_rate} - ${max_rate}`;

  return (
    <ShiftFooterLabel>{` • ${dedicated ? t('status:DEDICATED') : t('status:OPEN')} ${t(
      'create_shifts:SHIFT_PAY_RANGE',
    )}: ${priceRangeString}`}</ShiftFooterLabel>
  );
};
