import {
  BKJAlert,
  BKJButton,
  BKJTextArea,
  BKJTypography,
  ErrorBoundary,
} from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { AutoMargin } from 'components/AutoMargin';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { InputLabel, RequiredAsterisk } from 'components/InputLabel';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';
import { DeleteViewProvider, useDeleteViewContext } from './DeleteView.context';
import { DeleteShiftRadioButtonsWrapper } from './DeleteView.styled';
import { DeleteViewFormSchema } from './DeleteView.types';
import { DeleteShiftReasonSelect } from './DeleteViewReasonSelect';

export const DeleteViewComponent: FC = withMemo(function DeleteViewComponent() {
  const { t } = useTranslation();
  const {
    showDeleteShiftReasonTextArea,
    showRecurrenceRadioButtons,
    showLateWarning,
    isDisabled,
    isSubmitting,
    onChange,
    onSubmit,
    values,
  } = useDeleteViewContext();

  const { cancellationReason, specifiedOtherReason, cancelRecurring } =
    values as DeleteViewFormSchema;

  return (
    <Fragment>
      <Flex flexDirection="column" flex="1" gap="24px" padding="32px">
        <BKJTypography variant={'H3'} element={'h3'}>
          {t('schedule:DELETE_SHIFT')}?
        </BKJTypography>
        {showLateWarning && (
          <BKJAlert status={'warning'}>{t('schedule:DELETE_SHIFT_WITHIN_12_HOURS')}</BKJAlert>
        )}
        {showRecurrenceRadioButtons && (
          <DeleteShiftRadioButtonsWrapper>
            <BKJRadioInput
              name="cancelRecurring"
              value="false"
              onChange={onChange}
              checked={cancelRecurring === 'false'}
              label={t('schedule:DELETE_THIS_SHIFT_ONLY')}
            />
            <BKJRadioInput
              name="cancelRecurring"
              value="true"
              onChange={onChange}
              checked={cancelRecurring === 'true'}
              label={t('schedule:DELETE_ALL_SHIFTS_RECURRING')}
            />
          </DeleteShiftRadioButtonsWrapper>
        )}
        <Flex flexDirection="column" gap="4px">
          <InputLabel>
            {t('schedule:REASON_FOR_CANCELLATION')} :&nbsp;
            <RequiredAsterisk>*</RequiredAsterisk>
          </InputLabel>
          <DeleteShiftReasonSelect
            width="100%"
            placeholder={t('schedule:SELECT_A_REASON')}
            dropdownVariant="Square"
            onChange={onChange}
            name="cancellationReason"
            variant="GreyOutlined"
            value={cancellationReason}
          />
        </Flex>
        {showDeleteShiftReasonTextArea && (
          <BKJTextArea
            label={t('schedule:SPECIFY_REASON')}
            required={true}
            maxLength={200}
            disabled={isSubmitting}
            name={'specifiedOtherReason'}
            value={specifiedOtherReason}
            onChange={onChange}
            placeholder={t('schedule:REASON_VISIBLE_TO_FACILITY_AND_TEAM_MEMBER')}
          />
        )}
        <BKJTypography variant={'Caption'} element={'p'}>
          {t('schedule:TEAM_MEMBER_NOTIFIED')}
        </BKJTypography>
      </Flex>

      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton
          disabled={isDisabled}
          isLoading={isSubmitting}
          onClick={onSubmit as ChangeFunctionType}
          variant={'RedSolid'}
          width={'180px'}
        >
          {t('auth:DELETE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
});

export const DeleteView: FC = withMemo(function _DeleteView(props) {
  return (
    <ErrorBoundary componentName="DeleteView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <DeleteViewProvider>
        <DeleteViewComponent {...props} />
      </DeleteViewProvider>
    </ErrorBoundary>
  );
});
