import { CUSTOMER_COMMUNITIESV2 } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_PUT_enableAvailabilityStatus = (id: string): ApiRequestConfigType => {
  return {
    method: METHOD_PUT,
    url: `${CUSTOMER_COMMUNITIESV2}/${id}`,
    __typename: 'LegacyAPI',
    body: {
      community: {
        availability_status: 'available',
      },
    },
  };
};
