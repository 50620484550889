import { CUSTOMER_COMMUNITIESV2 } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

interface IPutCommunity {
  timeFrameAttributes: {
    morning_starts_at?: string;
    evening_starts_at?: string;
    night_starts_at?: string;
  };
}

export const API_PUT_updateCommunityDetailsTimeFrame = (
  id: string,
  payload: IPutCommunity,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    method: METHOD_PUT,
    url: `${CUSTOMER_COMMUNITIESV2}/${id}`,
    body: {
      community: {
        time_frame_attributes: {
          morning_starts_at: payload.timeFrameAttributes.morning_starts_at?.toString(),
          evening_starts_at: payload.timeFrameAttributes.evening_starts_at?.toString(),
          night_starts_at: payload.timeFrameAttributes.night_starts_at?.toString(),
        },
      },
    },
  };
};
