import { QUERY_shiftSummaryJaneRowFetchJane } from 'queries';

export const fetchJaneQueryConfig = ({
  positionId,
  janeId,
}: {
  positionId: number;
  janeId: string;
}) => ({
  key: 'SHIFT_SUMMARY_JANE_ROW',
  query: QUERY_shiftSummaryJaneRowFetchJane,
  schema: {
    positionId: {
      initialValue: positionId,
    },
    janeId: {
      initialValue: janeId,
    },
  },
});
