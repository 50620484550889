export const transformObjectValuesArrayToSingle = (
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: Record<string, any>, // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any> =>
  Object.entries(args).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value[0],
      };
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
