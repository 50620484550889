import { StyledLink } from 'components/AnchorAppLink/AnchorAppLink.styled';
import { IAnchorAppLinkProps } from 'components/AnchorAppLink/AnchorAppLink.types';
import React, { FC } from 'react';
import { deepEquals } from 'utils/deepEquals';

const AppLinkComponent: FC<IAnchorAppLinkProps> = ({ children, ...props }): JSX.Element => (
  <StyledLink {...props}>{children}</StyledLink>
);

export const AnchorAppLink = React.memo(AppLinkComponent, deepEquals);
