import {
  IUseMonthRangePickerBehaviorsArgs,
  IUseMonthRangePickerReturn,
} from './CustomRangePicker.types';

import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ISO8601TupleType } from 'types/common.types';
import { IBKJDatePickerChangeEvent } from 'components/BKJDatePicker/src/BKJDatePicker.types';
import { IBKJCalendarState } from 'components/BKJDatePicker/src/components/BKJCalendar/src';
import { getPrettyRange } from 'components/BKJDatePicker/src/BKJDatePicker.utils';
import { DateTime } from 'luxon';

export const useCustomDateRange = ({
  onChange,
  setIsCalendarOpen,
}: {
  onChange: (e: IBKJDatePickerChangeEvent) => void;
  setIsCalendarOpen: Dispatch<SetStateAction<boolean>> | undefined;
}) => {
  const [startRangeDate, setStartRangeDate] = useState<Nullable<string>>(null);

  const handleCustomDateRangeMonthViewChange = useCallback(
    (e: IBKJDatePickerChangeEvent): void => {
      if (!startRangeDate) {
        setStartRangeDate(e.target.value[0]);
        return onChange(e);
      }
      const event = {
        target: {
          name: e.target.name,
          value:
            e.target.value[0] >= startRangeDate
              ? [startRangeDate, e.target.value[0]]
              : [e.target.value[0], startRangeDate],
        },
      };
      onChange(event);
      setStartRangeDate(null);
      return setIsCalendarOpen && setIsCalendarOpen(false);
    },
    [onChange, setIsCalendarOpen, startRangeDate],
  );

  const calculateNextStateOnMonthViewChange = useCallback(
    (calendarState: IBKJCalendarState) => {
      const nextDateRangeStateValue = !startRangeDate
        ? calendarState.dateRangeInMonthView
        : ([startRangeDate, startRangeDate] as ISO8601TupleType);
      return {
        ...calendarState,
        dateRangeInMonthView: nextDateRangeStateValue,
        calendarView: 'year',
      } as SetStateAction<IBKJCalendarState>;
    },
    [startRangeDate],
  );

  return {
    handleCustomDateRangeMonthViewChange,
    calculateNextStateOnMonthViewChange,
  };
};

export function useCustomRangePickerBehaviors({
  value,
}: IUseMonthRangePickerBehaviorsArgs): IUseMonthRangePickerReturn {
  const startDate = value[0];
  const endDate = value[1];
  const previousValue: ISO8601TupleType = [
    DateTime.fromISO(startDate).minus({ day: 1 }).toString(),
    DateTime.fromISO(endDate).minus({ day: 1 }).toString(),
  ];
  const nextValue: ISO8601TupleType = [
    DateTime.fromISO(startDate).plus({ day: 1 }).toString(),
    DateTime.fromISO(endDate).plus({ day: 1 }).toString(),
  ];
  const displayDate = getPrettyRange(value);
  return {
    nextValue,
    previousValue,
    displayDate,
  };
}
