import { FC, useContext, useState } from 'react';
import { IBKJCalendarProps, ICalendarViewMap } from './BKJCalendar.types';
import { IBKJDatePickerChangeEvent } from '../../../BKJDatePicker.types';
import { getCalendarDefaultState } from './BKJCalendar.utils';
import { YearView, MonthView } from './components';
import { CalendarWrapper } from './BKJCalendar.styled';
import { isISO8601Tuple } from 'types/common.types';
import { BKJCalendarContext } from './BKJCalendar.context';
import { useCustomDateRange } from 'components/BKJDatePicker/src/components/CustomRangePicker/useCustomRangePickerBehaviors';

export const BKJCalendar: FC<IBKJCalendarProps> = ({ context, setIsCalendarOpen }): JSX.Element => {
  const { type, value, name, onChange, disabledTo, disabledFrom } = useContext(context);
  const [calendarState, setCalendarState] = useState(getCalendarDefaultState({ type, value }));
  const { calendarView, dateRangeInMonthView } = calendarState;
  const { handleCustomDateRangeMonthViewChange, calculateNextStateOnMonthViewChange } =
    useCustomDateRange({ onChange, setIsCalendarOpen });

  const handleYearViewOnChange = (e: IBKJDatePickerChangeEvent) => {
    if (!isISO8601Tuple(e.target.value)) {
      throw new Error('value of Month Cell must be of type ISO8601Tuple');
    }
    if (type === 'SingleMonth') {
      onChange(e);
      setIsCalendarOpen && setIsCalendarOpen(false);
    }
    setCalendarState({ calendarView: 'month', dateRangeInMonthView: e.target.value });
  };

  const handleMonthViewOnChange = (e: IBKJDatePickerChangeEvent): void => {
    switch (e.target.name) {
      case 'dateRangeInMonthView': {
        if (!isISO8601Tuple(e.target.value)) {
          throw new Error('value of Month Cell must be of type ISO8601Tuple');
        }
        setCalendarState({ ...calendarState, dateRangeInMonthView: e.target.value });
        break;
      }
      default:
        if (type === 'CustomDateRange') {
          return handleCustomDateRangeMonthViewChange(e);
        }
        if (type === 'MultipleDates') {
          return onChange(e);
        }
        onChange(e);
        return setIsCalendarOpen && setIsCalendarOpen(false);
    }
  };

  const handleClickMonthViewDateDisplay = () => {
    if (type === 'CustomDateRange') {
      setCalendarState(calculateNextStateOnMonthViewChange(calendarState));
      return void 0;
    }
    setCalendarState({ ...calendarState, calendarView: 'year' });
  };

  const calendarViewMap: ICalendarViewMap = {
    year: <YearView />,
    month: <MonthView />,
  };

  const ViewComponent: () => JSX.Element = () => calendarViewMap[calendarView];

  return (
    <CalendarWrapper>
      <BKJCalendarContext.Provider
        value={{
          name,
          type,
          value,
          dateRangeInMonthView,
          onChange,
          disabledTo,
          disabledFrom,
          setIsCalendarOpen,
          handleYearViewOnChange,
          handleMonthViewOnChange,
          handleClickMonthViewDateDisplay,
        }}
      >
        <ViewComponent />
      </BKJCalendarContext.Provider>
    </CalendarWrapper>
  );
};
