import { QuerySwitch } from 'components/QuerySwitch';
import { GeneralLocationSettingsForm } from 'pages/LocationSettingsPage/GeneralLocationSettingsPageForm';
import { GeneralLocationSettingsNewForm } from 'pages/LocationSettingsPage/GeneralLocationSettingsPageNewForm';
import { GeneralLocationSettingsRead } from 'pages/LocationSettingsPage/GeneralLocationSettingsPageRead';
import {
  LocationRecordDetailDataContext,
  LocationRecordDetailDataProvider,
} from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isUserSecondarySelector from 'store/selectors/userSelectors/isUserSecondarySelector';
import { withMemo } from 'utils/withMemo';

export const Component: FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const isReadOnly = useSelector(isUserSecondarySelector);
  const GeneralLocationSettingsComponent = useMemo(
    () => (isReadOnly ? GeneralLocationSettingsRead : GeneralLocationSettingsForm),
    [isReadOnly],
  );

  switch (locationId) {
    case 'new':
      return <GeneralLocationSettingsNewForm key={locationId} />;
    default:
      return (
        <LocationRecordDetailDataProvider key={locationId}>
          <QuerySwitch
            context={LocationRecordDetailDataContext}
            component={GeneralLocationSettingsComponent}
          />
        </LocationRecordDetailDataProvider>
      );
  }
};

export const GeneralLocationSettingsPage = withMemo(Component);
