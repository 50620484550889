import { GQLAPIContextType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { CALLOUT_LIST_CSV } from 'constants/endpoints';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';
import { QUERY_shiftModalOrderDetailsCalloutList } from 'queries';
import { METHOD_GET } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { shiftModalOrderDetailsCalloutList } from 'types/graphql-types';

export type CalloutListData = GQLAPIContextType<shiftModalOrderDetailsCalloutList> & {
  estimatedTimeRemaining: { mins: number; hours: number; days: number };
};

const calloutListApolloClient = createApolloClient({ cache: createApolloCache() });

export const calloutListQueryConfig = (calloutListId: string): ISSFBehaviorsOptions => ({
  key: 'SHIFT_MODAL_DETAILS_CALLOUT_LIST_QUERY',
  query: QUERY_shiftModalOrderDetailsCalloutList,
  pageSize: 15,
  paginationType: 'InfiniteScroll',
  debounceTimeout: 500,
  variables: { id: calloutListId },
  schema: {
    id: {
      initialValue: calloutListId,
    },
  },
  client: calloutListApolloClient,
});

export const GET_callOutListCSV = ({
  callout_list,
  data = '',
}: {
  callout_list: string;
  data: string;
}): ApiRequestConfigType => {
  const query: { data?: string; callout_list?: { identifier: string } } = {};

  if (data) query.data = data;
  if (callout_list) query.callout_list = { identifier: callout_list };

  return {
    __typename: 'LegacyAPI',
    url: CALLOUT_LIST_CSV,
    method: METHOD_GET,
    namespace: 'CallOutList',
    query,
  };
};
