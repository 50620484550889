import { CustomFieldType } from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettings.types';
import { CustomFieldTypeEnum } from 'types/graphql-types';

export const CUSTOM_FIELDS_SETTINGS_DESCRIPTION_EDIT = 'CUSTOM_FIELDS_SETTINGS_DESCRIPTION_EDIT';
export const CUSTOM_FIELDS_SETTINGS_DESCRIPTION_VIEW = 'CUSTOM_FIELDS_SETTINGS_DESCRIPTION_VIEW';

export const CUSTOM_FIELDS_SETTINGS_MAX_MESSAGE = 'CUSTOM_FIELDS_SETTINGS_MAX_MESSAGE';

export const CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_VIEW =
  'CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_VIEW';

export const CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_EDIT_1 =
  'CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_EDIT_1';
export const CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_EDIT_2 =
  'CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_EDIT_2';
export const CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_EDIT_3 =
  'CUSTOM_FIELDS_SETTINGS_NO_CUSTOM_FIELDS_EDIT_3';

export const MAX_CUSTOM_FIELDS = 10;
export const DATE_FORMAT = 'LLL d, yyyy';

export const CustomFieldTypeLabelMap: Record<CustomFieldTypeEnum, CustomFieldType> = {
  [CustomFieldTypeEnum.dropdown]: 'Dropdown',
  [CustomFieldTypeEnum.input]: 'Single-Line Text',
  [CustomFieldTypeEnum.text_area]: 'Paragraph Text',
};
