import { isEnvDevelopment } from 'config';
import { useGQLQuery } from 'hooks';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { GeneralLocationSettingsFormFields } from 'pages/LocationSettingsPage/GeneralLocationSettingsPageForm.types';
import {
  QUERY_generalAgencyLocationSettingsPage,
  QUERY_generalLocationSettingsPage,
} from 'queries';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { METHOD_GET } from 'requests';
import { SessionService } from 'services';
import { GQLAPIContextType } from 'types/common.types';
import {
  generalAgencyLocationSettingsPage,
  generalLocationSettingsPage,
  generalLocationSettingsPageVariables,
} from 'types/graphql-types';
import { createBookJaneRequest } from 'utils/createBookJaneRequest';
import { createGQLDataContext } from 'utils/createDataContext';
import { withMemo } from 'utils/withMemo';

export const LocationRecordDetailDataContext =
  createGQLDataContext<GeneralLocationSettingsFormFields>();

export const Component: FC = ({ children }) => {
  const { locationId } = useParams<{ locationId: string }>();

  const [data, setData] = useState<GeneralLocationSettingsFormFields>(null!);
  const query = useGQLQuery<
    generalLocationSettingsPage,
    GeneralLocationSettingsFormFields,
    generalLocationSettingsPageVariables
  >(
    useUserTypeSwitch<
      UseGQLQueryConfig<
        generalLocationSettingsPage,
        GeneralLocationSettingsFormFields,
        generalLocationSettingsPageVariables
      >
    >({
      Agency: {
        query: QUERY_generalAgencyLocationSettingsPage,
        key: 'GENERAL_AGENCY_LOCATION_SETTINGS_PAGE',
        variables: {
          agencyId: SessionService.getUserAgencyId(),
          agencyCommunityId: [Number.parseInt(locationId, 10)],
        },

        responseTransformer(response: generalAgencyLocationSettingsPage) {
          if (data) return void 0;

          // TODO: Remove filter when https://bookjane.atlassian.net/browse/API-2856 Rails5 is released
          const agencyCommunity = response?.fetchAgency?.agencyCommunities.filter(
            (agencyCommunity) => agencyCommunity.id === Number.parseInt(locationId, 10),
          )[0];

          const s3Url = agencyCommunity?.wsib?.documents[0]?.urls.large!;
          const originalFilename = agencyCommunity?.wsib?.documents[0]?.originalFilename! || '';
          const defaultFile = undefined;
          const formValues: GeneralLocationSettingsFormFields = {
            id: agencyCommunity?.location?.id!,
            name: agencyCommunity?.name,
            city: agencyCommunity?.location?.city,
            country: agencyCommunity?.location?.country,
            description: agencyCommunity?.location?.description || '',
            full_address: agencyCommunity?.location?.fullAddress || '',
            flat: agencyCommunity?.location?.flat || '',
            state: agencyCommunity?.location?.state,
            street: agencyCommunity?.location?.street,
            zip_code: agencyCommunity?.location?.zipCode,
            street_number: agencyCommunity?.location?.streetNumber,
            latitude: agencyCommunity?.location?.latitude,
            longitude: agencyCommunity?.location?.longitude,
            first_name: agencyCommunity?.contactName?.split(' ')[0] || '',
            last_name: agencyCommunity?.contactName?.split(' ')[1] || '',
            email: agencyCommunity?.contactEmail || '',
            phone: agencyCommunity?.contactPhone || '',

            regulatory_id: agencyCommunity?.wsib?.regulatoryId || '',
          };
          if (!isEnvDevelopment && s3Url) {
            return createBookJaneRequest<Blob>(
              {
                url: s3Url,
                method: METHOD_GET,
                responseType: 'blob',
              },
              true,
            )
              .then((res) => {
                const file = s3Url ? new File([res.data], originalFilename) : defaultFile;

                formValues['document'] = !isEnvDevelopment ? file : defaultFile;
              })
              .finally(() => {
                if (!data && response) setData(formValues);
                return formValues;
              });
          }
          formValues['document'] = defaultFile;
          if (!data && response) setData(formValues);
          return formValues;
        },
      },
      B2B: {
        query: QUERY_generalLocationSettingsPage,
        key: 'GENERAL_LOCATION_SETTINGS_PAGE',
        variables: {
          locationId: Number.parseInt(locationId, 10),
        },
        responseTransformer(response: generalLocationSettingsPage) {
          if (data) return void 0;
          const formValues: GeneralLocationSettingsFormFields = {
            id: response?.fetchCommunity?.location?.id!,
            name: response?.fetchCommunity?.name,
            city: response?.fetchCommunity?.location?.city,
            country: response?.fetchCommunity?.location?.country,
            description: response?.fetchCommunity?.location?.description || '',
            full_address: response?.fetchCommunity?.location?.fullAddress || '',
            latitude: response?.fetchCommunity?.location?.latitude,
            longitude: response?.fetchCommunity?.location?.longitude,
            first_name: response?.fetchCommunity?.contactName?.split(' ')[0] || '',
            last_name: response?.fetchCommunity?.contactName?.split(' ')[1] || '',
            email: response?.fetchCommunity?.contactEmail || '',
            phone: response?.fetchCommunity?.contactPhone || '',
            flat: response?.fetchCommunity?.location?.flat || '',
            state: response?.fetchCommunity?.location?.state,
            street: response?.fetchCommunity?.location?.street,
            zip_code: response?.fetchCommunity?.location?.zipCode,
            street_number: response?.fetchCommunity?.location?.streetNumber,
          };
          if (!data && response) setData(formValues);
          return formValues;
        },
      },
    }),
  );

  useEffect(() => {
    async function fetchData() {
      if (!data) await query.fetch();
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue = useMemo(
    () => ({ ...query, status: data ? 'READY' : 'LOADING', data }),
    [data], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <LocationRecordDetailDataContext.Provider value={contextValue}>
      {children}
    </LocationRecordDetailDataContext.Provider>
  );
};

export const useLocationRecordDetailDataContext =
  (): GQLAPIContextType<GeneralLocationSettingsFormFields> =>
    useContext(LocationRecordDetailDataContext);

export const LocationRecordDetailDataProvider = withMemo(Component);
