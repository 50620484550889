import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { isUserObjectB2bType } from 'types/common.types';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

export const isTieringFeatureEnabledSelector = createSelector([getUser], (user) => {
  if (isUserObjectB2bType(user)) return user.tiering_status === 'on';
  return false;
});
