import { BKJForm } from 'components/FormWrapper';
import { FC, Fragment } from 'react';
import { Caption, H3 } from 'components/Typography';
import { H4, H5, P } from 'pages/SettingsPage/SettingsPage.styled';
import { useShiftDistributionDataContext } from 'pages/ShiftDistributionPage/ShiftDistributionPage.data.context';
import isUserEssentialSelector from 'store/selectors/userSelectors/isUserEssentialSelector';
import { useSelector } from 'react-redux';
import ShiftDistributionRules from 'pages/ShiftDistributionPage/ShiftDistributionRules';
import { Flex } from '@bookjane2/bookjane-design-library';
import {
  ShiftdistributionContentWrapper,
  TextContentTitle,
} from 'pages/ShiftDistributionPage/ShiftDistributionPage.styled';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';

export const ShiftDistributionPageRead: FC = () => {
  const { t } = useTranslation();
  const {
    data: { fetchCommunity },
  } = useShiftDistributionDataContext();

  const lastChanged = `${t('location_settings:LAST_CHANGED')}: ${DateTime.fromISO(
    fetchCommunity?.vaccinationRequiredLastUpdated,
  ).toFormat(`LLL d, yyyy 'at' h:mm a`)} ${t('location_settings:BY')} ${
    fetchCommunity?.vaccinationRequiredLastUpdatedBy
  }`;

  const isUserEssential = useSelector(isUserEssentialSelector);

  return (
    <ShiftdistributionContentWrapper>
      <Flex margin="0 0 40px 0">
        <H3>{fetchCommunity?.name}</H3>
      </Flex>
      <TextContentTitle data-cy="hdr-shiftDistribution-rules">
        Shift Distribution Rules
      </TextContentTitle>
      <div>{!isUserEssential && <ShiftDistributionRules />}</div>
      <BKJForm width="672px" maxWidth="672px" gap="22px">
        <H4>COVID-19 Vaccination</H4>
        {fetchCommunity?.vaccinationRequired ? (
          <Fragment>
            <H5>Mandatory</H5>
            <P>
              Agency staff working shifts at this location must acknowledge they require full
              vaccination to accept the shift. Agency staff are asked to bring proof of vaccination
              for the shift.
            </P>
          </Fragment>
        ) : (
          <Fragment>
            <H5>Optional</H5>
            <P>
              It is preferred, but not required that agency staff be fully vaccinated to work a
              shift at this location.
            </P>
          </Fragment>
        )}
        {fetchCommunity?.vaccinationRequired && fetchCommunity?.vaccinationRequiredLastUpdated && (
          <Caption>{lastChanged}</Caption>
        )}
      </BKJForm>
    </ShiftdistributionContentWrapper>
  );
};
