import { BKJListItem, BKJSelect } from '@bookjane2/bookjane-design-library';
import { CustomFieldTypeSelectOptions } from 'components/CustomFieldTypeSelectInput/CustomFieldTypeSelectInput.constants';
import {
  ICustomFieldTypeSelectInputProps,
  ICustomFieldTypeSelectRenderGroupProps,
} from 'components/CustomFieldTypeSelectInput/CustomFieldTypeSelectInput.types';
import { FC, Fragment } from 'react';

const RenderGroup = ({
  name,
  selected,
  options,
  onChange,
}: ICustomFieldTypeSelectRenderGroupProps) => {
  return (
    <Fragment>
      {options.map(({ key, label, value }) => {
        return (
          <BKJListItem
            key={key}
            value={value}
            label={label}
            name={name}
            onChange={onChange}
            selected={selected}
          />
        );
      })}
    </Fragment>
  );
};

export const CustomFieldTypeSelectInput: FC<ICustomFieldTypeSelectInputProps> = ({
  name,
  placeholder,
  variant = 'GreyOutlined',
  width,
  dropdownWidth,
  value,
  onChange,
  iconComponent,
  iconProps,
  disabled = false,
  ...props
}) => {
  const translatedCustomFieldTypeSelectOptions = CustomFieldTypeSelectOptions();
  const label = translatedCustomFieldTypeSelectOptions.find((opt) => opt.value === value[0])?.label;
  return (
    <BKJSelect
      {...props}
      disabled={disabled}
      options={translatedCustomFieldTypeSelectOptions}
      placeholder={placeholder}
      label={label}
      type="Single"
      variant={variant}
      width={width}
      name={name}
      dropdownWidth={dropdownWidth}
      dropdownPlacement="bottom-start"
      value={value}
      onChange={onChange}
      renderOptions={RenderGroup}
      iconComponent={iconComponent}
      iconProps={iconProps}
    />
  );
};
