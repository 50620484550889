import { customFieldCreateModalRoutingService } from 'components/CustomFieldCreateModal/contexts/CustomFieldCreateModal.ui.context';
import { createContext, FC, useContext } from 'react';

interface ICustomFieldCreateModalProps {
  goToApplyFacilitiesView: () => void;
  onClose: () => void;
}

export const CustomFieldCreateModalPropsContext = createContext<ICustomFieldCreateModalProps>({
  goToApplyFacilitiesView: () => null,
  onClose: () => null,
});

export const CustomFieldCreateModalPropsProvider: FC = ({ children }) => {
  const contextValue = {
    goToApplyFacilitiesView: () =>
      customFieldCreateModalRoutingService.goToView('ApplyFacilitiesView'),
    onClose: () => customFieldCreateModalRoutingService.close(),
  };

  return (
    <CustomFieldCreateModalPropsContext.Provider value={contextValue}>
      {children}
    </CustomFieldCreateModalPropsContext.Provider>
  );
};

export const useCustomFieldCreateModalPropsContext = () =>
  useContext(CustomFieldCreateModalPropsContext);
