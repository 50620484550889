import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { AgencyCommunitiesNavigation } from 'components/SettingsNavigation/AgencyCommunitiesNavigation';
import { B2BDepartmentsNavigation } from 'components/SettingsNavigation/B2BDepartmentsNavigation';
import { ExpandableMenuLink } from 'components/SettingsNavigation/ExpandableMenuLink';
import { LocationSettingsNavigation } from 'components/SettingsNavigation/LocationSettingsNavigation/LocationSettingsNavigation';
import { positionsSettingsPagePath } from 'components/SettingsNavigation/SettingsNavigation.constants';
import { useSettingsNavigationPropsContext } from 'components/SettingsNavigation/SettingsNavigation.props.context';
import { FeatureGuard } from 'guards/FeatureGuard';
import { UserGuard } from 'guards/UserGuard';
import { CUSTOMER_PERMISSIONS, UserPermissionEnum } from 'guards/UserGuard.types';
import { usePathname } from 'hooks/usePathname';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { forwardRef, Fragment, Ref, useState } from 'react';
import { SessionService } from 'services';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { AdministratorSettingsNavigation } from 'components/SettingsNavigation/AdministratorsSettingsNavigation/AdministratorSettingsNavigation';
import {
  SettingsInternalMenuLink,
  SettingsNavigationWrapper,
  SettingsNavItem,
  SettingsNavItemWrapper,
  ToggleSettingsNavIcon,
} from './SettingsNavigation.styled';
import { useTranslation } from 'hooks/useTranslation';

enum MenuItemsEnum {
  PROFILE,
  COMPANY,
  LOCATIONS,
  POSITIONS,
  ADP,
  ADMINISTRATORS,
}

const ArrowIcon = ({
  selectedMenuItem,
  menuItem,
}: {
  selectedMenuItem: MenuItemsEnum;
  menuItem: MenuItemsEnum;
}) => {
  return selectedMenuItem === menuItem ? (
    <BKJIcon color="White" iconName="ChevronUp" />
  ) : (
    <BKJIcon iconName="ChevronDown" />
  );
};

export const SettingsNavigationComponent = forwardRef<HTMLDivElement>((props, ref) => {
  const isPendingApproval = SessionService.getUser()?.access_status === 'not_approved';
  const { isOpen, handleClick, locationsLabel } = useSettingsNavigationPropsContext();

  const defaultMenuSelection = (pathName: string) => {
    if (pathName.includes('/settings/company')) return MenuItemsEnum.COMPANY;
    if (pathName.includes('/settings/user')) return MenuItemsEnum.PROFILE;
    if (pathName.includes('/settings/locations')) return MenuItemsEnum.LOCATIONS;
    if (pathName.includes('/settings/positions')) return MenuItemsEnum.POSITIONS;
    if (pathName.includes('/settings/adp')) return MenuItemsEnum.ADP;
    if (pathName.includes('/settings/administrators')) return MenuItemsEnum.ADMINISTRATORS;
    return MenuItemsEnum.PROFILE;
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultMenuSelection(usePathname()));

  const ExpandablePositionsMenuLink = () => (
    <ExpandableMenuLink
      component={useUserTypeSwitch({
        Agency: AgencyCommunitiesNavigation,
        B2B: B2BDepartmentsNavigation,
      })}
      to={positionsSettingsPagePath}
      isOpen={isOpen}
      handleExpanded={() => setSelectedMenuItem(MenuItemsEnum.POSITIONS)}
    >
      {t('settings:POSITIONS')}
      <ArrowIcon selectedMenuItem={selectedMenuItem} menuItem={MenuItemsEnum.POSITIONS} />
    </ExpandableMenuLink>
  );

  const { isXtmProvider } = WhiteLabelAccessor;
  const { t, i18n } = useTranslation();

  return (
    <SettingsNavigationWrapper
      {...props}
      isPendingApproval={isPendingApproval}
      id="SettingsNavigationWrapper"
      $isOpen={isOpen}
      ref={ref as Ref<HTMLDivElement>}
    >
      <ToggleSettingsNavIcon
        iconName={isOpen ? 'ChevronLeft' : 'ChevronRight'}
        onClick={handleClick}
        $isOpen={isOpen}
      />
      {isOpen && (
        <Fragment>
          <UserGuard userType={CUSTOMER_PERMISSIONS}>
            <FeatureGuard type="FEATURE_TYPE_CUSTOM_FIELDS">
              <SettingsInternalMenuLink
                to="/settings/company"
                $borderTop="none"
                onClick={() => setSelectedMenuItem(MenuItemsEnum.COMPANY)}
              >
                <SettingsNavItemWrapper $isOpen={isOpen}>
                  <SettingsNavItem>{t('common:CUSTOM_FIELDS')}</SettingsNavItem>
                </SettingsNavItemWrapper>
              </SettingsInternalMenuLink>
            </FeatureGuard>
          </UserGuard>

          <UserGuard userType="USER_TYPE_AGENCY">
            <SettingsInternalMenuLink
              to="/settings/company"
              $borderTop="none"
              onClick={() => setSelectedMenuItem(MenuItemsEnum.COMPANY)}
            >
              <UserGuard userType={UserPermissionEnum.USER_TYPE_AGENCY}>
                <SettingsNavItemWrapper $isOpen={isOpen}>
                  <SettingsNavItem>{t('settings:COMPANY_PROFILE')}</SettingsNavItem>
                </SettingsNavItemWrapper>
              </UserGuard>
            </SettingsInternalMenuLink>
          </UserGuard>

          {!isXtmProvider && (
            <SettingsInternalMenuLink
              to="/settings/user"
              onClick={() => setSelectedMenuItem(MenuItemsEnum.PROFILE)}
            >
              <SettingsNavItemWrapper $isOpen={isOpen}>
                <SettingsNavItem>{t('settings:MY_PROFILE')}</SettingsNavItem>
              </SettingsNavItemWrapper>
            </SettingsInternalMenuLink>
          )}

          <ExpandableMenuLink
            to="/settings/locations"
            isOpen={isOpen}
            component={LocationSettingsNavigation}
            handleExpanded={() => setSelectedMenuItem(MenuItemsEnum.LOCATIONS)}
          >
            {i18n.exists(`common:${locationsLabel}`)
              ? t(`common:${locationsLabel}`)
              : locationsLabel}

            <ArrowIcon selectedMenuItem={selectedMenuItem} menuItem={MenuItemsEnum.LOCATIONS} />
          </ExpandableMenuLink>

          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
              UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
              UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
              UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
            ]}
          >
            <ExpandablePositionsMenuLink />
          </UserGuard>

          <FeatureGuard type="FEATURE_TYPE_PAYROLL">
            <SettingsInternalMenuLink
              to="/settings/adp"
              onClick={() => setSelectedMenuItem(MenuItemsEnum.ADP)}
            >
              <SettingsNavItemWrapper $isOpen={isOpen}>
                <SettingsNavItem>ADP</SettingsNavItem>
              </SettingsNavItemWrapper>
            </SettingsInternalMenuLink>
          </FeatureGuard>
          {!isXtmProvider && (
            <UserGuard
              userType={[
                UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
                UserPermissionEnum.USER_TYPE_ESSENTIAL_PRIMARY,
                UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
              ]}
            >
              <ExpandableMenuLink
                to="/settings/administrators"
                isOpen={isOpen}
                component={AdministratorSettingsNavigation}
                handleExpanded={() => setSelectedMenuItem(MenuItemsEnum.ADMINISTRATORS)}
              >
                {t('settings:ADMINISTRATORS')}
                {/* {locationsLabel}  why its comming like this ? */}
                <ArrowIcon
                  selectedMenuItem={selectedMenuItem}
                  menuItem={MenuItemsEnum.ADMINISTRATORS}
                />
              </ExpandableMenuLink>
            </UserGuard>
          )}
        </Fragment>
      )}
    </SettingsNavigationWrapper>
  );
});
