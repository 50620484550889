import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';

export const DropdownWrapper = styled.div`
  max-height: 228px;
  min-height: 38px;
  height: 228px;
  box-sizing: content-box;
  overflow-y: overlay;
  z-index: 1000;
  box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.3);
  border-radius: 0px 0px 8px 8px;
  background-color: ${({ theme }) => theme.colors.White};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InfiniteScrollWrapper = styled.div`
  padding-top: 20px;
  background-color: white;
`;

export const JaneSelect = styled(Flex)<{ selected: boolean }>`
  padding-left: 16px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey2};
      order: -1;
    `};
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
`;

export const NoResultsWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.Grey4};
  font-size: 14px;
  line-height: 24px;
  height: 38px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`;
