import { BKJButton, BKJCheckbox, BKJIconTooltip, Flex } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useInviteAdminModalUIContext } from 'components/InviteAdminModal/InviteAdminModal.ui.context';
import { FC, useEffect, useState } from 'react';
import { useInviteAdminModalFormContext } from 'components/InviteAdminModal/InviteAdminModal.form.context';
import { css } from 'styled-components';
import {
  LocationsWrapper,
  TableCell,
  TableHeaderCell,
  TableRow,
} from 'components/InviteAdminModal/views/NewAdminLocations.styled';
import { BKJForm } from 'components/FormWrapper';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { useTranslation } from 'hooks/useTranslation';

export const NewAdminLocations: FC = () => {
  const { onChange, values, onSubmit } = useInviteAdminModalFormContext();
  const { onClose } = useInviteAdminModalUIContext();
  const [checkAllAG, setCheckAllAG] = useState<boolean>(false);
  const [checkAllSE, setCheckAllSE] = useState<boolean>(false);

  useEffect(() => {
    let allCheckedAG = true;
    let allCheckedSE = true;
    values.communities.forEach((item) => {
      if (!item?.isIncluded) {
        allCheckedAG = false;
      }
      if (!item.shift_emails) {
        allCheckedSE = false;
      }
    });
    setCheckAllAG(allCheckedAG);
    setCheckAllSE(allCheckedSE);
  }, [values]);

  function handleChangeAG(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    values.communities[index].isIncluded = !values.communities[index].isIncluded;
  }
  function handleChangeSE(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    values.communities[index].shift_emails = !values.communities[index].shift_emails;
  }
  const { t } = useTranslation();

  return (
    <BKJForm
      data-cy="formSettingsValidation"
      onSubmit={onSubmit}
      width="100%"
      maxWidth="100%"
      // gap="22px"
    >
      <LocationsWrapper>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          margin="20px 20px 20px 20px"
          height="100%"
        >
          <Flex
            alignItems="flex-start"
            gap="8px"
            width="100%"
            css={css`
              padding-bottom: 20px;
            `}
          >
            <div>{t('settings:SELECT_THE_LOCATIONS')}</div>
          </Flex>
          <Flex
            flexDirection="column"
            gap="20px"
            css={css`
              border: solid 1px #8b96a350;
              margin: 4px;
              width: 100%;
              height: 100%;
            `}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <TableHeaderCell width="50%">{t('settings:LOCATION')}</TableHeaderCell>

              <Flex width="25%" justifyContent="center" alignItems="center">
                {t('settings:ACCESS')} <br />
                {t('settings:GRANTED')}
              </Flex>
              <Flex width="25%" justifyContent="center" alignItems="center">
                <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                  {t('settings:SHIFT_EMAIL_NOTIFICATIONS')}
                </UserGuard>
              </Flex>
              <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                <BKJIconTooltip iconName="Info" placement="top">
                  {t('settings:ENABLE_RECEIVE_EMAIL')}
                </BKJIconTooltip>
              </UserGuard>
            </Flex>

            <TableRow justifyContent="space-between" alignItems="center" isGrey={true}>
              <TableCell width="50%">{t('settings:ALL_LOCATIONS')}</TableCell>

              <TableCell width="25%" justifyContent="center" alignItems="center">
                <BKJCheckbox
                  variant="GreenSolid"
                  value={checkAllAG}
                  onChange={() => {
                    setCheckAllAG(!checkAllAG);
                    values.communities.forEach((item) => {
                      item.isIncluded = !checkAllAG;
                    });
                  }}
                />
              </TableCell>
              <Flex width="25%" justifyContent="center" alignItems="center">
                <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                  <BKJCheckbox
                    variant="GreenSolid"
                    value={checkAllAG ? checkAllSE : false}
                    onChange={() => {
                      setCheckAllSE(!checkAllSE);
                      values.communities.forEach((item) => {
                        item.shift_emails = !checkAllSE;
                      });
                    }}
                    disabled={!checkAllAG}
                  />
                </UserGuard>
              </Flex>
            </TableRow>
            {values &&
              values.communities.map((item, index) => {
                return (
                  <TableRow
                    justifyContent="space-between"
                    alignItems="center"
                    key={item.id}
                    isGrey={index % 2 === 1}
                  >
                    <TableCell width="50%">{item.name}</TableCell>

                    <Flex width="25%" justifyContent="center" alignItems="center">
                      <BKJCheckbox
                        variant="GreenSolid"
                        value={item.isIncluded}
                        name={`c.${index}.isIncluded`}
                        onChange={(e) => {
                          handleChangeAG(e, index);
                          onChange(e);
                        }}
                      />
                    </Flex>
                    <Flex width="25%" justifyContent="center" alignItems="center">
                      <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                        <BKJCheckbox
                          variant="GreenSolid"
                          value={item.isIncluded ? item.shift_emails : false}
                          name={`c.${index}.shift_emails`}
                          onChange={(e) => {
                            handleChangeSE(e, index);
                            onChange(e);
                          }}
                          disabled={!item.isIncluded}
                        />
                      </UserGuard>
                    </Flex>
                  </TableRow>
                );
              })}
          </Flex>
        </Flex>
        <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
          <AutoMargin />

          <BKJButton
            variant="PurpleSolid"
            width="180px"
            onClick={onClose}
            type="submit"
            disabled={false}
          >
            {t('auth:SAVE')}
          </BKJButton>
        </BKJSideModalFooter>
      </LocationsWrapper>
    </BKJForm>
  );
};
