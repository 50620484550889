import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_continueCalloutDialog } from 'queries';
import { continueCalloutDialog, continueCalloutDialogVariables } from 'types/graphql-types';

export const continueCalloutDialogConfig = (
  orderId: number,
): UseGQLQueryConfig<
  continueCalloutDialog,
  continueCalloutDialog,
  continueCalloutDialogVariables
> => {
  return {
    key: 'SHIFT_DISTRIBUTION_FETCH_COMMUNITY_QUERY',
    query: QUERY_continueCalloutDialog,
    variables: {
      orderId,
    },
  };
};
