import { useURLParameters } from 'hooks';
import {
  GroupsDetailsComponentMap,
  GroupsDetailsModalViewType,
} from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.constants';
import { createContext, FC, useCallback, useContext, useEffect } from 'react';
import { ModalRoutingService } from 'services';
import {
  fetchGroupDetails_node_DynamicGroup,
  fetchGroupDetails_node_StaticGroup,
} from 'types/graphql-types';

export const groupsModalRoutingService = new ModalRoutingService(
  'groups',
  'NewGroup',
  GroupsDetailsComponentMap,
  {},
  ['NewGroup', 'ViewStaticGroup', 'ViewDynamicGroup', 'EditGroup'],
);

// Modal Control State for Settings -> Groups Page table data
// FIXME: This workaround was introduced when GroupsPage switched from useGQLQuery to useSSFBehavior in order to leverage pagination
// TODO: Figure out a better solution for this that does not involve global state
export const groupsPageTableDataModalControlState = {
  refresh: false,
};

export type GroupType =
  | fetchGroupDetails_node_StaticGroup['__typename']
  | fetchGroupDetails_node_DynamicGroup['__typename'];

export interface IGroupsDetailsUI {
  isOpen: boolean;
  onClose: () => void;
  goToNextView: (groupType?: { groupType?: GroupType }) => void;
  goToNewGroupView: (groupType?: { groupType?: GroupType }) => void;
  groupsActiveModalView: GroupsDetailsModalViewType;
  groupId: string;
}

export const GroupsDetailsUIContext = createContext<IGroupsDetailsUI>({} as IGroupsDetailsUI);

export const GroupsDetailsUIProvider: FC = ({ children }) => {
  const [{ groupsActiveModalView, groupId }, { removeParam }] =
    useURLParameters<{ groupsActiveModalView: GroupsDetailsModalViewType }>();

  const handleClose = useCallback(() => {
    groupsPageTableDataModalControlState.refresh = true;
    groupsModalRoutingService.close();
  }, []);

  const goToNewGroupView = useCallback(({ groupType = 'StaticGroup' }) => {
    switch (groupType) {
      case 'StaticGroup':
        groupsModalRoutingService.goToView('AddMembers');
        break;
      case 'DynamicGroup':
        groupsModalRoutingService.goToView('SelectCriteria');
        break;
    }
  }, []);

  const goToNextView = useCallback(
    ({ groupType = 'StaticGroup' }) => {
      switch (groupsActiveModalView) {
        case 'EditGroup':
          switch (groupType) {
            case 'StaticGroup':
              groupsModalRoutingService.goToView('EditMembers');
              break;
            case 'DynamicGroup':
              groupsModalRoutingService.goToView('EditCriteria');
              break;
          }
      }
    },
    [groupsActiveModalView],
  );

  useEffect(() => {
    if (!Object.keys(GroupsDetailsComponentMap).includes(groupsActiveModalView)) {
      removeParam(['groupsActiveModalView']);
    }
  }, [groupsActiveModalView, removeParam]);

  return (
    <GroupsDetailsUIContext.Provider
      value={{
        isOpen: !!groupsActiveModalView,
        onClose: handleClose,
        groupsActiveModalView: groupsActiveModalView as GroupsDetailsModalViewType,
        groupId,
        goToNextView,
        goToNewGroupView,
      }}
    >
      {children}
    </GroupsDetailsUIContext.Provider>
  );
};

export const useGroupsDetailsModalUIContext = () =>
  useContext<IGroupsDetailsUI>(GroupsDetailsUIContext);
