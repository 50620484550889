import { InMemoryCache, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { httpLink } from 'providers/ApolloProvider';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';

const authLink = setContext((_, { headers }) => {
  return {
    headers: createBookJaneRequestHeaders({ ...headers, 'X-DISABLE-PAGINATION-GRAPHQL': '1' }),
  };
});

export const nonPaginatedApolloClient = () =>
  new ApolloClient({
    link: authLink.concat(httpLink()),
    cache: new InMemoryCache(),
  });
