import styled from 'styled-components';

export const DetailTimeOffViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: 1px solid #dfe3e9;
`;

export const MainTitle = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const DetailTimeOffViewHeaderWrapper = styled.div`
  height: fit-content;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailTimeOffViewBlockWrapper = styled.div`
  width: 100%;
  padding: 14px 25px;
  display: flex;
  gap: 13px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.Grey1};
`;

export const DetailTimeOffViewJaneInfoWrapper = styled.div`
  & div:first-child {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.TextDarkGrey};
  }
  & div:last-child {
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.TextSubtleGrey};
    ${({ theme }) => theme.common.Ellipsis};
  }
`;

export const DetailTimeOffViewStartTimeEndTimeWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  line-height: 24px;
`;

export const DetailTimeOffViewDetailWrapper = styled.div``;
