import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { FC, Fragment, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
interface IViewMoreButtonProps {
  component: FC<unknown>;
  render: FC<{ isOpen: boolean }>;
}

const StyledIcon = styled(BKJIcon)<{ $isOpen: boolean }>`
  transition: transform 150ms ease-in-out;
  transform: scale(1, 1);
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: scale(1, -1);
    `};
`;

export const ViewMoreButton: FC<IViewMoreButtonProps> = ({
  children,
  component: Component,
  render,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = useCallback(() => setOpen(!isOpen), [isOpen]);
  return (
    <Fragment>
      <BKJButton
        {...rest}
        onClick={handleClick}
        variant="PurpleText"
        width="auto"
        minWidth="auto"
        type="button"
      >
        {render({ isOpen })}
        <StyledIcon iconName="ChevronDown" $isOpen={isOpen} />
      </BKJButton>

      {isOpen && <Component />}
    </Fragment>
  );
};
