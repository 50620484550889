import { BKJIcon, BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';

export const HeaderWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 0px 32px;
  gap: 24px;

  top: 0;
  position: sticky;
  background: white;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px 24px;
    gap: 12px;
  }
`;

export const SendToAgenciesViewTitleContainer = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const SendToAgenciesViewTitle = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 18px;
  }
`;

export const PostRequestRadioOptions = styled(BKJRadioInput)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    white-space: wrap;
  }
`;

export const NextButton = styled(BKJButton)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    padding: 22px;
  }
`;

export const SendToAgenciesText = styled.span`
  font-size: 14px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 21px;
`;

export const TotalAvailableJanesText = styled(SendToAgenciesText)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-left: 0;
  }
`;

export const SendToAgenciesBoldText = styled(SendToAgenciesText)`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
`;

export const SendToAgenciesInlineIcon = styled(BKJIcon)`
  margin-right: 6px;
`;

export const InfoSection = styled(Flex)`
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.colors.Grey1};
`;

export const SendToAgenciesContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px;
  }
`;

export const RequestTeamMemberWrapper = styled(Flex)`
  overflow-y: overlay;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 0;
  }
`;

export const EstimatedTotalPayoutFooterText = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.TextDisabled};
    `}
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-bottom: 10px;
  }
`;

export const TextButtonWrapper = styled(BKJButton)`
  display: inline;
  width: fit-content;
  font-size: 14px;
  line-height: 22px;
  min-height: auto;
  max-height: auto;
  padding: 0 0;
`;

export const ConfirmationButtons = styled(BKJButton)`
  width: 180px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    padding: 22px;
  }
`;
