import {
  IUseSingleMonthPickerBehaviorsArgs,
  IUseSingleMonthPickerReturn,
} from './SingleMonthPicker.types';
import {
  getPrettyMonthAndYear,
  getPreviousMonthCalendarRange,
  getNextMonthCalendarRange,
} from '../../BKJDatePicker.utils';

export function useSingleMonthPickerBehaviors({
  value,
}: IUseSingleMonthPickerBehaviorsArgs): IUseSingleMonthPickerReturn {
  const startDate = value[0];
  const previousValue = getPreviousMonthCalendarRange(startDate);
  const nextValue = getNextMonthCalendarRange(startDate);
  const displayDate = getPrettyMonthAndYear(startDate);

  return {
    nextValue,
    previousValue,
    displayDate,
  };
}
