import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const TableAvailabilityRowWrapper = styled.div`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.Grey1};
  }
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TimeColumn = styled.div`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  margin-right: 20px;
`;

export const TableAvailabilityRow = styled.div`
  display: grid;
  grid-template-columns: 144px 1.5fr 1.5fr;
  padding: 0 32px;
  height: 75px;
`;

export const MemberWrapper = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 20px;
`;

export const JaneImage = styled.img`
  background-color: #dae1e9;
  min-height: 34px;
  height: 34px;
  object-fit: cover;
  width: 34px;
  display: flex;
  border-radius: 50%;
`;

export const AgencyLabel = styled.span`
  background-color: #707985;
  color: white;
  border-radius: 4px;
  padding: 4px 8.4px 4px 7.6px;
  content: 'Agency';
`;

export const JaneWrapper = styled.div``;

export const JaneName = styled.div`
  ${({ theme }) => theme.typography.Body};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
`;
export const JaneFacility = styled.div`
  ${({ theme }) => theme.typography.Caption};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
`;

export const TeamMemberWrapper = styled.div`
  grid-template-columns: 45px 1fr;
  display: grid;
`;

export const TableColumn = styled.div`
  font-size: 12px;
  align-items: center;
  display: flex;
`;

export const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 144px 1.5fr 1.5fr 40px;
  height: 75px;
  min-height: 75px;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 4px solid #dfe3e9;
  padding: 0 32px;
`;

export const TableHeaderColumn = styled.div`
  display: flex;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  white-space: nowrap;
`;

export const StyledBKJAvatar = styled(BKJAvatar)``;

export const StyledFlex = styled(Flex)`
  margin-left: 10px;
`;
