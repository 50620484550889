import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { SendToAgenciesFormSchema } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.types';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { FC, useCallback, useContext, useMemo } from 'react';
import { API_POST_sendOrderToAgencies } from 'requests/POST_orderSendToAgencyById';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { createGQLDataContext } from 'utils/createDataContext';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

const initialValues: SendToAgenciesFormSchema = {
  send_to_agencies_target: 'all',
  notification_strategy: 'now',
  specific_time_selection: ['now'],
  jane_id: '',
  isDedicated: false,
  selectedAgencies: [],
};

export const SendToAgenciesFormContext = createGQLDataContext<
  UseFormBehaviors<SendToAgenciesFormSchema>
>({} as UseFormBehaviors<SendToAgenciesFormSchema>);

export const SendToAgenciesFormProvider: FC = ({ children }) => {
  const { shift_id: orderId } = useShiftModalUIContext();
  const { apiRequest } = useAPIActions();
  const {
    data: {
      fetchOrder: { biddable },
    },
  } = useShiftModalDataContext();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (sendToAgenciesParameters: SendToAgenciesFormSchema) => {
      await apiRequest(
        API_POST_sendOrderToAgencies(orderId, {
          ...sendToAgenciesParameters,
          post_action:
            biddable && sendToAgenciesParameters.send_to_agencies_target === 'request_member'
              ? 'send_to_agencies'
              : sendToAgenciesParameters.post_action,
        }),
        {
          onSuccess: () => {
            sendToAgenciesParameters.send_to_agencies_target === 'request_agencies'
              ? toastService.success(t('success:SHIFT_SENT_TO_SELECT'))
              : toastService.success(t('success:SHIFT_SENT_TO_AGENCIES'));

            queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${orderId}`);
            window.scheduleUtils.forceDataUpdate();
            shiftModalRoutingService.close();
          },
          onError: handleGenericErrorMessage,
        },
      );
    },
    [apiRequest, biddable, orderId, t],
  );

  const formBehaviorsOptions: UseFormBehaviors_Options<SendToAgenciesFormSchema> = useMemo(
    () => ({
      initialState: initialValues,
      isDirtyCheckEnabled: false,
      type: 'CREATE',
      onSubmit,
    }),
    [onSubmit],
  );

  const form = useFormBehaviors<SendToAgenciesFormSchema>(formBehaviorsOptions);

  return (
    <SendToAgenciesFormContext.Provider value={form}>{children}</SendToAgenciesFormContext.Provider>
  );
};

export const useSendToAgenciesFormContext = (): UseFormBehaviors<SendToAgenciesFormSchema> =>
  useContext(SendToAgenciesFormContext);
