import { SessionService } from 'services';
import { IAuthReducer } from 'store/reducers/auth/authReducer.types';

export const initialAuthState: IAuthReducer = {
  user: SessionService.getUser(),
  token: SessionService.getToken(),
  baseUrl: null,
  permissions: null,
  isLoading: false,
};
