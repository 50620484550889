import { BKJDatePickerContext } from '../../BKJDatePicker.context';
import { FC, useContext, forwardRef } from 'react';
import { BaseDatePicker } from '../BaseDatePicker';
import { isISO8601Tuple } from 'types/common.types';
import { useSingleDateRangePickerBehaviors } from './useSingleDateRangePickerBehaviors';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SingleDateRangePicker: FC<{ ref: any }> = forwardRef((_, ref): JSX.Element => {
  const { name, value, onChange, variant } = useContext(BKJDatePickerContext);

  if (!isISO8601Tuple(value)) {
    throw new Error('value provided to SingleDateRangePicker is not of type ISO8601TupleType');
  }

  const { nextValue, previousValue, displayDate } = useSingleDateRangePickerBehaviors({
    value,
  });

  return (
    <BaseDatePicker
      variant={variant}
      ref={ref}
      name={name}
      nextValue={nextValue}
      previousValue={previousValue}
      onChange={onChange}
      displayDate={displayDate}
    />
  );
});
