import { BKJToggleInput } from '@bookjane2/bookjane-design-library';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { FC, useContext } from 'react';
import {
  DedicatedTeamMembersWrapper,
  DedicatedTeamLabel,
} from './DedicatedTeamMembersToggleInput.styled';
import { useTranslation } from 'hooks/useTranslation';

export const DedicatedTeamMembersToggleInput: FC = () => {
  const { createShift, hasDedicatedTeam, handleChange } = useContext(CreateShiftCardContext);
  const { values } = createShift;
  const { dedicated } = values;

  const { t } = useTranslation();

  if (!hasDedicatedTeam) {
    return null;
  }

  return (
    <DedicatedTeamMembersWrapper alignItems="center">
      <BKJToggleInput
        variant="Switch"
        name="dedicated"
        checked={!!dedicated}
        onChange={handleChange}
      />
      <DedicatedTeamLabel>{t('create_shifts:DEDICATED_TEAM')}</DedicatedTeamLabel>
    </DedicatedTeamMembersWrapper>
  );
};
