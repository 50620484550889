import { CustomFieldEditModalUrlParamsType } from 'components/CustomFieldEditModal/CustomFieldEditModal.types';
import {
  CustomFieldEditModalViewMap,
  CustomFieldEditModalViewType,
} from 'components/CustomFieldEditModal/CustomFieldEditModal.views';
import { useURLParameters } from 'hooks';
import { createContext, FC, ReactNode, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalRoutingService } from 'services';
import isUserPremiumSecondarySelector from 'store/selectors/userSelectors/isUserPremiumSecondarySelector';
import { castStringToInteger } from 'utils';

type CustomFieldEditModalUIContextType = {
  isOpen: boolean;
  customFieldId: number;
  editCustomFieldActiveModalView: CustomFieldEditModalViewType;
};

export const CustomFieldEditModalUIContext = createContext<CustomFieldEditModalUIContextType>({
  isOpen: true,
  customFieldId: 0,
  editCustomFieldActiveModalView: 'EditCustomFieldView',
});

export const customFieldEditModalRoutingService = new ModalRoutingService(
  'editCustomField',
  'EditCustomFieldView',
  CustomFieldEditModalViewMap,
  { customFieldId: 'number' },
  [],
);

export const CustomFieldEditModalUIProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ customFieldId, editCustomFieldActiveModalView: activeModalView }, { removeParam }] =
    useURLParameters<CustomFieldEditModalUrlParamsType>();

  const isReadOnly = useSelector(isUserPremiumSecondarySelector);

  const isValidModalView = Object.keys(CustomFieldEditModalViewMap).includes(activeModalView);

  useEffect(() => {
    if (!isValidModalView || isReadOnly) {
      removeParam(['editCustomFieldActiveModalView', 'customFieldId']);
      return;
    }

    if (!customFieldId) removeParam(['editCustomFieldActiveModalView']);
  }, [activeModalView, removeParam, customFieldId, isValidModalView, isReadOnly]);

  const customField_id = customFieldId ? castStringToInteger(customFieldId) : 0;

  return !!activeModalView ? (
    <CustomFieldEditModalUIContext.Provider
      value={{
        isOpen: !!activeModalView,
        customFieldId: customField_id,
        editCustomFieldActiveModalView: activeModalView as CustomFieldEditModalViewType,
      }}
    >
      {children}
    </CustomFieldEditModalUIContext.Provider>
  ) : null;
};

export const useCustomFieldEditModalUIContext = (): CustomFieldEditModalUIContextType =>
  useContext(CustomFieldEditModalUIContext);
