import { IBKJComboBoxProps } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBox/BKJComboBox.types';

export const shiftUnpaidBreakTimeDropdownOptions: IBKJComboBoxProps['options'] = [
  {
    key: 0,
    value: '00:00',
    label: '0 mins',
  },
  {
    key: 0.25,
    value: '00:15',
    label: '15 mins',
  },
  {
    key: 0.5,
    value: '00:30',
    label: '30 mins',
  },
  {
    key: 0.75,
    value: '00:45',
    label: '45 mins',
  },
  {
    key: 1,
    value: '01:00',
    label: '1 hr',
  },
  {
    key: 1.25,
    value: '01:15',
    label: '1 hr  15 mins',
  },
  {
    key: 1.5,
    value: '01:30',
    label: '1 hr  30 mins',
  },
  {
    key: 1.75,
    value: '01:45',
    label: '1 hr  45 mins',
  },
  {
    key: 2,
    value: '02:00',
    label: '2 hrs',
  },
  {
    key: 2.25,
    value: '02:15',
    label: '2 hrs  15 mins',
  },
  {
    key: 2.5,
    value: '02:30',
    label: '2 hrs  30 mins',
  },
  {
    key: 2.75,
    value: '02:45',
    label: '2 hrs  45 mins',
  },
  {
    key: 3,
    value: '03:00',
    label: '3 hrs',
  },
  {
    key: 3.25,
    value: '03:15',
    label: '3 hrs  15 mins',
  },
  {
    key: 3.5,
    value: '03:30',
    label: '3 hrs  30 mins',
  },
  {
    key: 3.75,
    value: '03:45',
    label: '3 hrs  45 mins',
  },
  {
    key: 4,
    value: '04:00',
    label: '4 hrs',
  },
].map((item) => ({ ...item, key: String(item.key), id: String(item.value) }));
