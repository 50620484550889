import { ApiRequestConfigType } from 'requests/index.types';
import { AGENCY_USERS, CUSTOMER_USERS } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { userProfilePage_me_AgencyUser, userProfilePage_me_B2bCustomer } from 'types/graphql-types';

export const API_PUT_b2bUserProfile =
  (userId: string) =>
  (formValues: UserProfilePageForm): ApiRequestConfigType => {
    return {
      __typename: 'LegacyAPI',
      url: `${CUSTOMER_USERS}/${userId}`,
      method: METHOD_PUT,
      body: {
        user: {
          ...formValues,
        },
      },
    };
  };

export const API_PUT_agencyUserProfile =
  (userId: string) =>
  (formValues: UserProfilePageForm): ApiRequestConfigType => {
    return {
      __typename: 'LegacyAPI',
      url: `${AGENCY_USERS}/${userId}`,
      method: METHOD_PUT,
      body: {
        agency_user: {
          ...formValues,
        },
      },
    };
  };

export interface UserProfilePageForm {
  first_name:
    | userProfilePage_me_B2bCustomer['firstName']
    | userProfilePage_me_AgencyUser['firstName'];
  last_name: userProfilePage_me_B2bCustomer['lastName'] | userProfilePage_me_AgencyUser['lastName'];
  phone: string | undefined;
  email: userProfilePage_me_B2bCustomer['email'] | userProfilePage_me_AgencyUser['email'];
  locale: userProfilePage_me_B2bCustomer['locale'] | userProfilePage_me_AgencyUser['locale'];
}
