import {
  FetchAgencyContextType,
  IAgencyGridSelectProps,
  IAgencyGridSelectPropsContextType,
} from 'components/AgencyGridSelect/AgencyGridSelect.types';
import { Context, createContext, FC, useContext, useMemo } from 'react';

export const AgencyGridSelectPropsContext = createContext<IAgencyGridSelectPropsContextType>(
  {} as IAgencyGridSelectPropsContextType,
);

export const AgencyGridSelectPropsProvider: FC<IAgencyGridSelectProps> = ({
  onChangeForm,
  fetchAgenciesContext,
  errorMessage,
  type,
  selected,
  name,
  positionId,
  children,
}) => {
  const { data, loadMore } = useContext<FetchAgencyContextType>(
    fetchAgenciesContext as unknown as Context<FetchAgencyContextType>,
  );

  const agencyList = useMemo(
    () => data?.fetchAgencyCommunities.nodes || [],
    [data?.fetchAgencyCommunities.nodes],
  );

  const contextValue = useMemo(
    () => ({
      hasNextPage: !!data?.fetchAgencyCommunities?.pageInfo?.hasNextPage,
      agencyList,
      onChangeForm,
      loadMore,
      errorMessage,
      type,
      selected,
      name,
      positionId,
    }),
    [
      agencyList,
      data?.fetchAgencyCommunities?.pageInfo?.hasNextPage,
      errorMessage,
      loadMore,
      name,
      onChangeForm,
      positionId,
      selected,
      type,
    ],
  );

  return (
    <AgencyGridSelectPropsContext.Provider value={contextValue}>
      {children}
    </AgencyGridSelectPropsContext.Provider>
  );
};

export const useAgencyGridSelectPropsContext = (): IAgencyGridSelectPropsContextType =>
  useContext(AgencyGridSelectPropsContext);
