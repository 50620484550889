import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSendToAgenciesPropsContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.props.context';
import {
  SendToAgenciesViewTitle,
  PostRequestRadioOptions,
  NextButton,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { useURLParameters } from 'hooks';
import { useTranslation } from 'hooks/useTranslation';
import { isCurrentLanguageFrench } from 'providers/i18NextProvider.constants';
import { FC, Fragment, useEffect } from 'react';

export const PostRequestOptionsView: FC = () => {
  const { t } = useTranslation();
  const { values, onChange } = useSendToAgenciesFormContext();
  const { goToNextView } = useSendToAgenciesPropsContext();

  const [, { removeParam }] = useURLParameters();
  useEffect(() => {
    if (!shiftModalRoutingService.isBackButtonVisible())
      removeParam(['shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [removeParam]);

  const isDeclineBehaviorInternalOnly = values.post_action === 'internal_only';
  const isDeclineBehaviorSendToAgencies = values.post_action === 'send_to_agencies';
  return (
    <Fragment>
      <Flex flexDirection="column" flex="1" padding="40px 32px">
        <SendToAgenciesViewTitle>{t('shift_modal:POST_REQUEST_OPTIONS')}</SendToAgenciesViewTitle>

        <PostRequestRadioOptions
          name="post_action"
          value="internal_only"
          onChange={onChange}
          checked={isDeclineBehaviorInternalOnly}
          label={t('shift_modal:KEEP_SHIFT_WITHIN_TEAM_NOTE')}
          isFrenchFormat={isCurrentLanguageFrench()}
        />

        <PostRequestRadioOptions
          name="post_action"
          value="send_to_agencies"
          onChange={onChange}
          checked={isDeclineBehaviorSendToAgencies}
          label={t('shift_modal:SEND_SHIFT_TO_ALL_MEMBER_NOTE')}
          isFrenchFormat={isCurrentLanguageFrench()}
        />
      </Flex>
      <BKJSideModalFooter>
        <AutoMargin />
        <NextButton
          variant="PurpleSolid"
          width="180px"
          disabled={!values.post_action}
          onClick={goToNextView}
        >
          {t('shift_modal:NEXT')}
        </NextButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
