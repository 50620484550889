import { GROUPS } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_POST_createDynamicGroupParamsType } from 'requests/POST_createDynamicGroup.types';

export const API_POST_createDynamicGroup = ({
  name,
  community_id,
  employment_statuses,
  position_types,
  minimum_per_day_minutes,
  maximum_per_day_minutes,
  minimum_per_period_minutes,
  maximum_per_period_minutes,
}: API_POST_createDynamicGroupParamsType): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: GROUPS,
    method: METHOD_POST,
    body: {
      group: {
        name,
        type: 'DynamicGroup',
        community_id,
        dynamic_group_criteria_attributes: {
          employment_statuses,
          position_types,
          minimum_per_day_minutes,
          maximum_per_day_minutes,
          minimum_per_period_minutes,
          maximum_per_period_minutes,
        },
      },
    },
  };
};
