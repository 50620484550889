import { CUSTOMER_SHIFT_SWAP } from 'constants/endpoints';
import { METHOD_GET } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_GET_shiftSwap = (id: string): ApiRequestConfigType => {
  return {
    method: METHOD_GET,
    url: CUSTOMER_SHIFT_SWAP(id),
    __typename: 'LegacyAPI',
  };
};
