import { AvailableMemberTabTableHeader } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemberTabTableHeader';
import { AvailableMemberTabTableBody } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemberTabTableBody';
import { AvailableMemberTabTableWrapper } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailabilityMemberTab.styled';

export const AvailableMemberTabTable = () => {
  return (
    <AvailableMemberTabTableWrapper>
      <AvailableMemberTabTableHeader />
      <AvailableMemberTabTableBody />
    </AvailableMemberTabTableWrapper>
  );
};
