import { useShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import { createContext, FC, useContext, useMemo } from 'react';
import { shiftsCalendarPage } from 'types/graphql-types';
import { calendarTotalStatusStyleMap } from 'components/ShiftCalendar/ShiftCalendar.constants';
import { ITotalSummaryFooterEntry } from 'components/TotalSummaryFooter/TotalSummaryFooter.types';
import { aggregateShiftsCalenderPageData } from 'components/ShiftCalendar/CalenderTotalSummaryFooter/CalenderTotalSummaryFooter.constants';
import { CalenderTotalCount } from 'components/ShiftCalendar/CalenderTotalSummaryFooter/CalenderTotalSummaryFooter.types';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import { useSelector } from 'react-redux';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';
export interface ICalenderTotalSummaryFooterProps {
  monthYear: string;
  entries: ITotalSummaryFooterEntry[];
}

export const CalenderTotalSummaryFooterPropsContext =
  createContext<ICalenderTotalSummaryFooterProps>({
    monthYear: '',
    entries: [],
  });

export const CalenderTotalSummaryFooterPropsProvider: FC = ({ children }) => {
  const { values, data } = useShiftsPageContext();
  const { t } = useTranslation();

  const shiftsCalendarPageData = data as shiftsCalendarPage;

  const { filterDate } = values;
  const diff = DateTime.fromISO(filterDate[1]).diff(DateTime.fromISO(filterDate[0]), 'day').days;
  const monthYear = DateTime.fromISO(filterDate[0]).plus({ day: diff / 2 });

  const {
    pendingCount,
    acceptedCount,
    assignedCount,
    unfulfilledCount,
    completedCount,
  }: CalenderTotalCount = useMemo(
    () => aggregateShiftsCalenderPageData(shiftsCalendarPageData, monthYear),
    [monthYear, shiftsCalendarPageData],
  );

  const isUserPremium = useSelector(isUserPremiumSelector);
  const isUserAgency = useSelector(isUserAgencySelector);
  const locationsWithScheduling = useSelector(schedulingReadAccessFacilityIdsSelector);
  const isSchedulingEnabled = locationsWithScheduling.includes(Number(values.location.value));
  const month = monthYear.toFormat('LLLL').toUpperCase();
  const translatedMonth = t(month);
  const contextValue = useMemo(
    () => ({
      monthYear: `${translatedMonth} ${monthYear.toFormat('yyyy')}`,
      entries: [
        {
          label: t('shifts_calendar:PENDING'),
          count: pendingCount,
          color: calendarTotalStatusStyleMap['pending'],
        },
        {
          label: t('status:ACCEPTED'),
          count: acceptedCount,
          color: calendarTotalStatusStyleMap['accepted'],
        },
        isUserPremium &&
          isSchedulingEnabled && {
            label: t('common:ASSIGNED'),
            count: assignedCount,
            color: calendarTotalStatusStyleMap['assigned'],
          },
        !isUserAgency && {
          label: t('common:UNFULFILLED'),
          count: unfulfilledCount,
          color: calendarTotalStatusStyleMap['unfulfilled'],
        },
        isUserAgency && {
          label: t('common:COMPLETED'),
          count: completedCount,
          color: calendarTotalStatusStyleMap['completed'],
        },
      ].filter((entry) => !!entry) as ITotalSummaryFooterEntry[],
    }),
    [
      translatedMonth,
      monthYear,
      t,
      pendingCount,
      acceptedCount,
      isUserPremium,
      isSchedulingEnabled,
      assignedCount,
      isUserAgency,
      unfulfilledCount,
      completedCount,
    ],
  );
  return (
    <CalenderTotalSummaryFooterPropsContext.Provider value={contextValue}>
      {children}
    </CalenderTotalSummaryFooterPropsContext.Provider>
  );
};

export const useCalenderTotalSummaryFooterPropsContext = () =>
  useContext(CalenderTotalSummaryFooterPropsContext);
