import { CustomFieldCreateModalUrlParamsType } from 'components/CustomFieldCreateModal/CustomFieldCreateModal.types';
import {
  CustomFieldCreateModalViewMap,
  CustomFieldCreateModalViewType,
} from 'components/CustomFieldCreateModal/CustomFieldCreateModal.views';
import { useURLParameters } from 'hooks';
import { createContext, FC, ReactNode, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalRoutingService } from 'services';
import isUserPremiumSecondarySelector from 'store/selectors/userSelectors/isUserPremiumSecondarySelector';

type CustomFieldCreateModalUIContextType = {
  isOpen: boolean;
  createCustomFieldActiveModalView: CustomFieldCreateModalViewType;
};

export const CustomFieldCreateModalUIContext = createContext<CustomFieldCreateModalUIContextType>({
  isOpen: true,
  createCustomFieldActiveModalView: 'CreateCustomFieldView',
});

export const customFieldCreateModalRoutingService = new ModalRoutingService(
  'createCustomField',
  'CreateCustomFieldView',
  CustomFieldCreateModalViewMap,
  {},
  [],
);

export const CustomFieldCreateModalUIProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ createCustomFieldActiveModalView: activeModalView }, { removeParam }] =
    useURLParameters<CustomFieldCreateModalUrlParamsType>();

  const isReadOnly = useSelector(isUserPremiumSecondarySelector);

  const isValidModalView = Object.keys(CustomFieldCreateModalViewMap).includes(activeModalView);

  useEffect(() => {
    if (!isValidModalView || isReadOnly) removeParam(['createCustomFieldActiveModalView']);
  }, [activeModalView, removeParam, isValidModalView, isReadOnly]);

  return !!activeModalView ? (
    <CustomFieldCreateModalUIContext.Provider
      value={{
        isOpen: !!activeModalView,
        createCustomFieldActiveModalView: activeModalView as CustomFieldCreateModalViewType,
      }}
    >
      {children}
    </CustomFieldCreateModalUIContext.Provider>
  ) : null;
};

export const useCustomFieldCreateModalUIContext = (): CustomFieldCreateModalUIContextType =>
  useContext(CustomFieldCreateModalUIContext);
