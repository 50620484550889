import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { ADD_SHIFT_REVIEW } from 'constants/endpoints';
import { ShiftModalContextType } from 'components/ShiftModal/ShiftModal.ui.context';

export const API_POST_addShiftReview = (params: {
  id: ShiftModalContextType['shift_id'];
  rating: number;
  comment: string;
}): ApiRequestConfigType => {
  const url = ADD_SHIFT_REVIEW(params.id);
  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url,
    body: {
      review: {
        rating: params.rating,
        comment: params.comment,
      },
    },
  };
};
