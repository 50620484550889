import { deleteViewReasonLabel } from '../DeleteView.utils';
import { IDeleteShiftReasonOption } from './DeleteViewReasonSelect.types';

export const deleteViewReasonSelectOptions: IDeleteShiftReasonOption[] = [
  {
    key: 'EntryError',
    value: 'entry_error',
    label: deleteViewReasonLabel('entry_error'),
  },
  {
    key: 'ShiftFulfillment',
    value: 'shift_was_fulfilled',
    label: 'Shift Fulfillment',
  },
  {
    key: 'NotRequired',
    value: 'not_required',
    label: deleteViewReasonLabel('not_required'),
  },
  {
    key: 'DuplicateEntry',
    value: 'duplicate_entry',
    label: deleteViewReasonLabel('duplicate_entry'),
  },
  {
    key: 'Other',
    value: 'other',
    label: deleteViewReasonLabel('other'),
  },
];
