import { LoginFormSchema, isResponseSuccessful } from 'pages/LoginPage/LoginPage';
import { IUserLoginActionPayload } from 'store/reducers/auth/authReducer.types';
import { DEFAULT_BASE_URL } from 'config';
import { AsyncThunkAction } from '@reduxjs/toolkit';

export function authResponseHandler(
  response: AsyncThunkAction<IUserLoginActionPayload, LoginFormSchema, {}>,
) {
  const authResponse = response as unknown as { type: string; payload?: { baseUrl?: string } };
  if (isResponseSuccessful(authResponse) && DEFAULT_BASE_URL !== authResponse?.payload?.baseUrl) {
    // Re-init BASE_URL for global consts
    window.location.reload();
    return false;
  }
  return response;
}
