import { BKJTableWrapper } from 'components/BKJTable/BKJTable.styled';
import { BKJTableRowWrapper } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const GroupsTableWrapper = styled(BKJTableWrapper)`
  margin-top: 10px;
  height: calc(100% - 74px);
`;

export const GroupsTableFooter = styled(Flex)`
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.3);
  height: 64px;
  width: 100%;
`;

export const GroupsTableFooterPaginationWrapper = styled(Flex)`
  position: fixed;
  bottom: 4px;
  padding-left: 40px;
  padding-bottom: 10px;
  margin-top: 10px;
  height: 50px;
`;

export const GroupsTableRowWrapper = styled(BKJTableRowWrapper)<{ isGrey?: boolean }>`
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
`;
