import { ISetupBannerProps } from 'components/SetupBanner/SetupBanner.type';
import styled, { css } from 'styled-components';

export const BannerWrapper = styled.div<ISetupBannerProps>`
  ${({ background }) =>
    background &&
    css`
      background-image: url('${background}');
      background-size: cover;
    `}
  background-position: right center;
  background-size: cover;
  height: 61px;
  min-height: 61px;
  color: white !important;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
`;

export const Text = styled.div`
  ${({ theme }) => theme.typography.Body};
`;
