import { css } from 'styled-components';
import { IBKJThemeCheckbox } from '@bookjane2/bookjane-design-library/lib/components/BKJCheckbox/BKJCheckbox.types';

export const XtmThemeCheckbox: IBKJThemeCheckbox = {
  GreenSolid: {
    unchecked: css`
      background-color: ${({ theme }) => theme.colors.White};
      border: 1px solid ${({ theme }) => theme.colors.Grey6};
    `,
    checked: css`
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
      border: 1px solid ${({ theme }) => theme.colors.PrimaryDefault};
    `,
  },
  PurpleSolid: {
    unchecked: css`
      background-color: ${({ theme }) => theme.colors.White};
      border: 1px solid ${({ theme }) => theme.colors.Grey6};
    `,
    checked: css`
      background-color: ${({ theme }) => theme.colors.PrimaryDefault};
      border: 1px solid ${({ theme }) => theme.colors.PrimaryDefault};
    `,
  },
  GreenNoBorder: {
    unchecked: css`
      background-color: transparent;
      border: 0;
    `,
    checked: css`
      background-color: transparent;
      border: 0;
    `,
  },
  PurpleOutlined: {
    unchecked: css`
      background-color: ${({ theme }) => theme.colors.White};
      border: 1px solid ${({ theme }) => theme.colors.Grey6};
    `,
    checked: css`
      background-color: ${({ theme }) => theme.colors.White};
    `,
  },
};
