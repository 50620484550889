import { ISO8601Type } from 'types/common.types';
import { DeleteShiftReasonType } from './DeleteView.types';
import { DateTime } from 'luxon';

export const deleteViewReasonLabel = (deleteViewReason: DeleteShiftReasonType): string => {
  return deleteViewReason
    .split('_')
    .reduce((accum, curr) => `${accum} ${curr.charAt(0).toUpperCase() + curr.slice(1)}`, '');
};

export const isTimeWithinTwelveHours = (
  date: ISO8601Type,
  timeZone?: Nullable<string>,
): boolean => {
  const dateTimeZone = timeZone || undefined;
  const startDate = DateTime.now().setZone(dateTimeZone);
  const endDate = startDate.setZone(dateTimeZone).endOf('minute').plus({ hour: 12 });
  const time = DateTime.fromISO(date).setZone(dateTimeZone);
  return time >= startDate && time <= endDate;
};
