import { Flex } from 'components/Flex';
import {
  EmptyBody,
  EmptyHeader,
  EmptyMessage,
} from 'pages/ShiftsPage/ShiftsEmptyView/ShiftsEmptyView.styled';
import { useTranslation } from 'hooks/useTranslation';
import { shiftPageEmptyCopy } from 'pages/ShiftsPage/ShiftsPage.constants';

export function ShiftsPageEmptyView() {
  const { t } = useTranslation();
  return (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
      <EmptyMessage>
        <EmptyHeader>{t(shiftPageEmptyCopy.header)}</EmptyHeader>
        <EmptyBody>{t(shiftPageEmptyCopy.body)}</EmptyBody>
      </EmptyMessage>
    </Flex>
  );
}
