import { useSSFBehaviors } from 'hooks';
import { FC, useContext } from 'react';
import { GQLAPIContextType, pageContextInitialState } from 'types/common.types';
import { shiftSummaryViewTeamMembers } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { withMemo } from 'utils/withMemo';
import { CreateShiftsPageContext } from '../CreateShiftsPage.context';
import { ICreateShift } from '../CreateShiftsPage.types';
import { shiftSummaryViewTeamMembersConfig } from './ShiftSummaryView.constants';
import { SelectedJanesInfo } from './ShiftSummaryView.types';

export const ShiftSummaryViewContext = createGQLDataContext({ ...pageContextInitialState });

export const ShiftSummaryViewProvider: FC = withMemo(({ children }): JSX.Element => {
  const { groupedFormsBehaviors } = useContext(CreateShiftsPageContext);

  const createShifts = groupedFormsBehaviors.groupedForms as unknown as ICreateShift[];

  const byJaneIds = createShifts.reduce((accum: string[], curr: ICreateShift) => {
    const requestJanes = curr?.values?.request_janes?.split(',').filter((id) => id !== '') || [];
    return [...accum, ...requestJanes];
  }, []);

  const [, { data, ...rest }] = useSSFBehaviors(shiftSummaryViewTeamMembersConfig({ byJaneIds }));

  const selectedJanesInfo: SelectedJanesInfo =
    (data as shiftSummaryViewTeamMembers)?.fetchJanes.nodes.reduce(
      (accum, curr) => ({ ...accum, [curr.id]: curr }),
      {},
    ) || [];

  return (
    <ShiftSummaryViewContext.Provider value={{ data: selectedJanesInfo, ...rest }}>
      {children}
    </ShiftSummaryViewContext.Provider>
  );
});

export const useShiftSummaryViewContext = (): GQLAPIContextType<SelectedJanesInfo> =>
  useContext(ShiftSummaryViewContext);
