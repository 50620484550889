import { AgencyGridSelectComponent } from 'components/AgencyGridSelect/AgencyGridSelect.component';
import { AgencyGridSelectPropsProvider } from 'components/AgencyGridSelect/AgencyGridSelect.props.context';
import { IAgencyGridSelectProps } from 'components/AgencyGridSelect/AgencyGridSelect.types';
import { FC, useCallback } from 'react';

export const AgencyGridSelect: FC<
  Omit<IAgencyGridSelectProps, 'children'> & {
    name: string;
    errorMessage?: string;
    type: 'Single' | 'Multiple';
    selected: number[];
    positionId: number;
  }
> = ({
  onChangeForm: _onChangeForm,
  fetchAgenciesContext,
  errorMessage,
  type,
  selected,
  name,
  positionId,
}) => {
  const onChangeForm = useCallback(_onChangeForm, [_onChangeForm]);

  return (
    <AgencyGridSelectPropsProvider
      onChangeForm={onChangeForm}
      fetchAgenciesContext={fetchAgenciesContext}
      positionId={positionId}
      errorMessage={errorMessage}
      type={type}
      selected={selected}
      name={name}
    >
      <AgencyGridSelectComponent />
    </AgencyGridSelectPropsProvider>
  );
};
