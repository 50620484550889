import { useTranslation } from 'hooks/useTranslation';
import {
  BKJTableWrapperMarginBottom,
  ShiftDistributionRulesRow,
  TextContent,
} from 'pages/ShiftDistributionPage/ShiftDistributionPage.styled';

import ShiftDistributionRuleTable from 'pages/ShiftDistributionPage/ShiftDistributionRuleTable';
import { Fragment } from 'react';

const ShiftDistributionRules: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <TextContent data-cy="txt-under-shiftDistribution-rules">
        {t('location_settings:SHIFT_DISTRIBUTION_DESCRIPTION')}
      </TextContent>
      <BKJTableWrapperMarginBottom>
        <ShiftDistributionRulesRow data-cy="lbl-customPosition-read">
          {t('location_settings:SHIFT_DISTRIBUTION_RULE')}
        </ShiftDistributionRulesRow>
        <ShiftDistributionRuleTable />
      </BKJTableWrapperMarginBottom>
      <br />
    </Fragment>
  );
};

export default ShiftDistributionRules;
