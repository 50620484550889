import { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
  MonthViewWrapper,
  DayCellsWrapper,
  DayCell,
  TodayIndicator,
  DaysOfTheWeek,
} from './MonthView.styled';
import { CalendarNavigation } from '../CalendarNavigation';
import { getDayCellsConfigArray } from './MonthView.utils';
import { BKJCalendarContext } from '../../BKJCalendar.context';
import {
  getPrettyMonthAndYear,
  getPreviousMonthCalendarRange,
  getNextMonthCalendarRange,
} from '../../../../../BKJDatePicker.utils';
import { IBKJDatePickerChangeEvent } from '../../../../../BKJDatePicker.types';
import { ISO8601Type } from 'types/common.types';
import { withMemo } from 'utils/withMemo';
import { IDayCellConfig } from 'components/BKJDatePicker/src/components/BKJCalendar/src/components/MonthView/MonthView.types';

const RenderDayCell = withMemo(function _RenderDayCell({
  onClick,
  isDisabled,
  isSelected,
  label,
  isDateToday,
  isBetweenSelectedRange,
  isDateInViewMonth,
  isDateInHoverRange,
  isHoveredOnCalendar,
  id,
  onMouseEnter,
  onMouseLeave,
}: IDayCellConfig & { onMouseEnter: () => void; onMouseLeave: () => void }) {
  return (
    <DayCell
      key={id}
      onClick={onClick}
      isSelected={isSelected}
      isDateInViewMonth={isDateInViewMonth}
      isBetweenSelectedRange={isBetweenSelectedRange}
      isDateInHoverRange={isDateInHoverRange}
      isHoveredOnCalendar={isHoveredOnCalendar}
      isDisabled={isDisabled}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {label}
      {isDateToday && <TodayIndicator />}
    </DayCell>
  );
});

export const MonthView: FC = (): JSX.Element => {
  const {
    dateRangeInMonthView,
    handleClickMonthViewDateDisplay,
    handleMonthViewOnChange,
    name,
    type,
    value,
    disabledTo,
    disabledFrom,
  } = useContext(BKJCalendarContext);
  const prettyMonthAndYear = getPrettyMonthAndYear(dateRangeInMonthView[0]);
  const [hoveredDate, setHoveredDate] = useState('');
  const daysForMonth = useMemo(
    () =>
      getDayCellsConfigArray({
        dateRangeInMonthView,
        hoveredDate,
        name,
        onChange: handleMonthViewOnChange,
        type,
        value,
        disabledTo,
        disabledFrom,
      }),
    [
      dateRangeInMonthView,
      disabledFrom,
      disabledTo,
      handleMonthViewOnChange,
      hoveredDate,
      name,
      type,
      value,
    ],
  );

  const handleClickNextMonth = () => {
    const e: IBKJDatePickerChangeEvent = {
      target: {
        name: 'dateRangeInMonthView',
        value: getNextMonthCalendarRange(dateRangeInMonthView[0]),
      },
    };
    handleMonthViewOnChange(e);
  };

  const handleClickPreviousMonth = () => {
    const e: IBKJDatePickerChangeEvent = {
      target: {
        name: 'dateRangeInMonthView',
        value: getPreviousMonthCalendarRange(dateRangeInMonthView[0]),
      },
    };
    handleMonthViewOnChange(e);
  };

  const handleDayCellMouseEnter = useCallback(
    (id: ISO8601Type) => () => {
      setHoveredDate(id);
    },
    [],
  );

  const handleDayCellMouseLeave = useCallback(() => {
    setHoveredDate('');
  }, []);

  return (
    <MonthViewWrapper>
      <CalendarNavigation
        displayLabel={prettyMonthAndYear}
        onClickDisplay={handleClickMonthViewDateDisplay}
        onClickNext={handleClickNextMonth}
        onClickPrevious={handleClickPreviousMonth}
      />
      <DaysOfTheWeek />
      <DayCellsWrapper>
        {daysForMonth.map((dayConfig) => {
          const {
            id,
            isBetweenSelectedRange,
            isDateInHoverRange,
            isDateInViewMonth,
            isDateToday,
            isDisabled,
            isHoveredOnCalendar,
            isSelected,
            label,
            onClick,
          } = dayConfig;
          return (
            <RenderDayCell
              id={id}
              isBetweenSelectedRange={isBetweenSelectedRange}
              isDateInHoverRange={isDateInHoverRange}
              isDateInViewMonth={isDateInViewMonth}
              isDateToday={isDateToday}
              isDisabled={isDisabled}
              isHoveredOnCalendar={isHoveredOnCalendar}
              isSelected={isSelected}
              key={id}
              label={label}
              onClick={onClick}
              onMouseEnter={handleDayCellMouseEnter(id)}
              onMouseLeave={handleDayCellMouseLeave}
            />
          );
        })}
      </DayCellsWrapper>
    </MonthViewWrapper>
  );
};
