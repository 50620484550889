import createCachedSelector from 're-reselect';

const getSchedule = (state) => state.schedule;

export const selectedScheduleFacilityIdSelector = createCachedSelector(getSchedule, (schedule) => {
  const value = Number.parseInt(schedule.selectedFacilityId);
  if (value == null) return value;
  if (typeof value !== 'number')
    throw new Error('selectedScheduleFacilityIdSelector must return an integer.');
  return value;
})(({ schedule }) => `${schedule.selectedFacilityId || 'null'}`);
