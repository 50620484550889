/* eslint-disable import/no-anonymous-default-export */
import { BKJButton, BKJFileInput } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import {
  InviteSubtitle,
  InviteText,
  InviteTitle,
  InviteViewWrapper,
} from 'components/InviteTeamMembersModal/InviteTeamMembers.styled';
import { InviteTeamMemberModalTemplate } from 'components/InviteTeamMembersModal/InviteTeamMembersModalTemplate';
import React, { useContext } from 'react';
import { SuccessView } from '../SuccessView';
import { CSVViewContext, CSVViewContextProvider } from './CSVView.context';

const CSVView: React.FC = () => {
  const { formValues, flowView, onDownload, onChange, onSubmit, setNumInvites } =
    useContext(CSVViewContext);

  switch (flowView) {
    case 'success':
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target?.result as string;
        const numLines = text.split('\n').length;
        setNumInvites(numLines);
      };
      if (formValues.csv) {
        reader.readAsText(formValues.csv);
      }
      return <SuccessView view="CSVView" />;
    default:
      return (
        <InviteTeamMemberModalTemplate>
          <InviteViewWrapper flexDirection="column" childMarginBottom="32px">
            <InviteTitle>Import via Spreadsheet</InviteTitle>
            <Flex flexDirection="column">
              <InviteSubtitle>1. Download Spreadsheet</InviteSubtitle>
              <InviteText>Fill in team member data in the Spreadsheet Template.</InviteText>
            </Flex>
            <BKJButton variant="PurpleOutlined" width="192px" onClick={onDownload}>
              Download
            </BKJButton>
            <Flex flexDirection="column">
              <InviteSubtitle>2. Upload Spreadsheet</InviteSubtitle>
              <InviteText>
                Select the CSV or XLS file you saved and upload it to add and invite team members.
              </InviteText>
            </Flex>
            <BKJFileInput
              value={formValues.csv}
              name="csv"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              accept=".csv,.xlsx"
            />
          </InviteViewWrapper>
          <BKJSideModalFooter>
            <AutoMargin />
            <BKJButton
              width="180px"
              variant="GreenSolid"
              onClick={onSubmit}
              disabled={!formValues.csv}
            >
              Import
            </BKJButton>
          </BKJSideModalFooter>
        </InviteTeamMemberModalTemplate>
      );
  }
};

export default () => (
  <CSVViewContextProvider>
    <CSVView />
  </CSVViewContextProvider>
);
