import { USER_GUARD_SELECTOR_MAP } from 'guards/UserGuard.constants';
import { UserGuardType } from 'guards/UserGuard.types';
import { globalStore } from 'reduxStore';
import { IGlobalReduxStore } from 'store';
import { isArray, isUserGuardType } from 'types/common.types';

export function assertUserPermissions(userType: UserGuardType | UserGuardType[]) {
  let isUserAuthorized;
  const store = globalStore.getState() as IGlobalReduxStore;
  if (isArray(userType) && userType.length === 0) return false;

  if (isArray(userType)) {
    isUserAuthorized = !!userType.some((userType: UserGuardType) => {
      if (isUserGuardType(userType)) return USER_GUARD_SELECTOR_MAP[userType](store);
      return false;
    });
  } else {
    if (isUserGuardType(userType)) isUserAuthorized = !!USER_GUARD_SELECTOR_MAP[userType](store);
  }
  return !!isUserAuthorized;
}
