import { Flex } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const BKJSSFBarSearchInputWrapper = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  min-width: 150px;
  width: 320px;
  max-width: 320px;
  order: 101;
  padding: 0 12px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    height: 46px;
    min-height: 46px;
  }
`;

export const StyledBKJSSFBarSearchInput = styled.input`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ theme }) => theme.common.Ellipsis};
  background-color: ${({ theme }) => theme.colors.White};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  align-items: center;
  border: none;
  display: flex;
  font-size: 16px;
  height: 100%;
  line-height: 24px;
  outline: none;
  width: 100%;
  padding: 0;
  &::placeholder {
    color: ${({ theme }) => theme.colors.Grey4};
  }
`;

export const IconWrapper = styled(Flex)`
  height: 52px;
  width: 24px;
  min-width: 24px;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    height: 48px;
  }
`;
