import { createSelector } from '@reduxjs/toolkit';
import { TYPE_PERMISSION_READ } from 'constants/types';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

const schedulingReadAccessFacilityIdsSelector = createSelector(
  getUserPermissions,
  (userPermissions) => {
    const ids: number[] = [];
    const schedulingCommunities = userPermissions?.SCHEDULING?.communities;

    if (!schedulingCommunities) return [];

    Object.entries(schedulingCommunities).forEach(([key, arr]) => {
      if (arr.includes(TYPE_PERMISSION_READ)) ids.push(Number(key));
    });
    return ids;
  },
);

export default schedulingReadAccessFacilityIdsSelector;
