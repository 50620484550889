import styled, { css } from 'styled-components';

export const NavigationButton = styled.button<{ isActive: boolean }>`
  position: relative;
  background-color: transparent;
  min-width: fit-content;
  color: #45424e;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  border: none;
  outline: none;
  padding: 0 16px;
  transition: all 100ms linear;
  overflow: hidden;
  cursor: pointer;
  margin-left: 8px;
  background-color: ${(props) => props.theme.colors.Grey1};
  ${({ theme }) => theme.shadows.InnerShadow};
  ${({ theme }) => theme.common.Ellipsis};
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: white;
      cursor: unset;
      box-shadow: none;
    `};

  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 16px;
  line-height: 24px;
`;
