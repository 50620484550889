import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useApplyFacilitiesFormContext } from 'components/CustomFieldEditModal/contexts/ApplyFacilities.form.context';
import { customFieldEditModalRoutingService } from 'components/CustomFieldEditModal/contexts/CustomFieldModalEdit.ui.context';
import { useFetchCustomFieldByIdDataContext } from 'components/CustomFieldModal/Contexts/FetchCustomFieldById.data.context';
import { CustomFieldInfoFormInitialState } from 'components/CustomFieldModal/CustomFieldModal.constants';
import { CustomFieldInfoFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';

import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { API_DELETE_customField } from 'requests/DELETE_customField';
import { API_PUT_customField } from 'requests/PUT_customField';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { VALIDATION_dropdownOptions } from 'validations/VALIDATION_dropdownOptions';
import { VALIDATION_placeholder } from 'validations/VALIDATION_placeholder';

type EditCustomFieldFormContextType = UseFormBehaviors<CustomFieldInfoFormSchema> & {
  onDelete: (e: ChangeEventType) => void;
};

export const EditCustomFieldFormContext = createContext<EditCustomFieldFormContextType>(
  {} as EditCustomFieldFormContextType,
);

export const EditCustomFieldFormProvider: FC = ({ children }) => {
  const { data: customFieldById } = useFetchCustomFieldByIdDataContext();
  const { apiRequest } = useAPIActions();
  const { values, isDisabled } = useApplyFacilitiesFormContext();
  const { t } = useTranslation();

  const handleDeleteCustomField = useCallback(async () => {
    apiRequest(API_DELETE_customField(`${customFieldById.id}`), {
      onSuccess: () => {
        customFieldEditModalRoutingService.close();
        queryRefetchService.refetchDataQueryByKey('CUSTOM_FIELD', 'all');
        toastService.success(t('success:CUSTOM_FIELD_DELETED'));
      },
      onError: handleGenericErrorMessage,
    });
  }, [apiRequest, customFieldById.id, t]);

  const initialState = useMemo<CustomFieldInfoFormSchema>(
    () => ({
      id: customFieldById?.id,
      type: [customFieldById?.type],
      name: customFieldById?.name,
      mobile: customFieldById?.mobile,
      preFillDefault: !!customFieldById?.preFillDefault,
      dropdownOptions: customFieldById?.dropdownOptions || [],
      allFacilities: !!customFieldById?.allFacilities,
      placeholder: customFieldById?.placeholder,
      communityIds: customFieldById?.communityIds || [],
    }),
    [customFieldById],
  );

  const form: UseFormBehaviors<CustomFieldInfoFormSchema> =
    useFormBehaviors<CustomFieldInfoFormSchema>({
      initialState,
      onSubmit: async (customFieldFormParams) => {
        return await apiRequest(API_PUT_customField(customFieldFormParams, values), {
          onSuccess: () => {
            customFieldEditModalRoutingService.close();
            queryRefetchService.refetchDataQueryByKey('CUSTOM_FIELD', 'all');
            toastService.success(t('success:CUSTOM_FIELD_SAVED'));
          },
          onError: handleGenericErrorMessage,
        });
      },
      validations: {
        placeholder: VALIDATION_placeholder,
        dropdownOptions: VALIDATION_dropdownOptions,
      },
      type: 'EDIT',
      isDirtyCheckEnabled: true,
    });
  const value = useMemo(
    () => ({
      ...form,
      isDisabled: form.isDisabled && isDisabled,
      onReset: () => form.onReset(CustomFieldInfoFormInitialState),
      onSubmit: (e: ChangeEventType) => {
        form.onSubmit(e);
        form.onReset(CustomFieldInfoFormInitialState);
      },
      onDelete: handleDeleteCustomField,
    }),
    [form, handleDeleteCustomField, isDisabled],
  );

  return (
    <EditCustomFieldFormContext.Provider value={value}>
      {children}
    </EditCustomFieldFormContext.Provider>
  );
};

export const useEditCustomFieldFormContext = (): EditCustomFieldFormContextType =>
  useContext(EditCustomFieldFormContext);
