import { globalStore } from 'reduxStore';
import { pusherService } from 'services/PusherService';
import { IShiftModalRoutingService } from 'components/ShiftModal/ShiftModal.types';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { toastService } from 'services';
import { captureException } from 'utils/captureException';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';
import { history } from 'utils/history';
import { ZendeskSettings, ZendeskWidget } from 'types/zendesk-types';
import { ReactText } from 'react';
import { INormalizedPlaceHandleChangeProps } from '@bookjane2/bookjane-design-library/lib/components/BKJAddressInput/BKJAddressInput.types';

export interface IPlaceResult {
  description: string;
  place_id: string;
}

interface AutocompleteService {
  new (n: HTMLElement | null): {
    getQueryPredictions: (
      arg1: {
        input: string;
      },
      arg2: (results: IPlaceResult[]) => void,
    ) => [];
  };
}

interface PlacesService {
  new (n: HTMLElement | null): {
    getDetails: (
      arg1: {
        placeId: string;
        fields: Array<'formatted_address' | 'geometry' | 'address_components'>;
      },
      arg2: (result: INormalizedPlaceHandleChangeProps) => void,
    ) => [];
  };
}
declare global {
  interface Window {
    chmln: { identify: Function };
    _mfq: { push: (arr: string[]) => void };
    j360: {
      globalStore: typeof globalStore;
      pusherService: typeof pusherService;
      onError: (error: Error) => void;
      shiftModalRoutingService: IShiftModalRoutingService;
      toastService: {
        success(msg: string): ReactText;
        error(msg: string): ReactText;
        warn(msg: string): ReactText;
      };
      toggleSettingsNavigationOpen: Function;
      history: History;
      createBookJaneRequestHeaders: (headers: Record<string, string>) => Record<string, string>;
    };
    scheduleUtils: { forceDataUpdate: () => void };
    google: {
      maps: {
        places: {
          AutocompleteService: AutocompleteService;
          PlacesService: PlacesService;
        };
      };
    };
    opera?: string;
    zE: ZendeskWidget;
    zESettings: ZendeskSettings;
  }
}

window._mfq = window._mfq || [];

window.j360 = {
  globalStore: globalStore,
  toggleSettingsNavigationOpen: () => null,
  shiftModalRoutingService: shiftModalRoutingService,
  onError: (error: Error) => {
    captureException(error);
    return void 0;
  },
  pusherService,
  history: history as unknown as History,
  toastService,
  createBookJaneRequestHeaders,
};

window.scheduleUtils = {
  forceDataUpdate: () => null,
};
