import { J360Logo } from 'components/BKJMainNavigation/src/J360Logo';
import { Flex } from 'components/Flex';
import { PageWrapper } from 'components/PageWrapper';
import { MessageText, PurpleText } from 'pages/ErrorPages/ErrorPages.styled';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export function ServerNotReachablePage() {
  const { isWhiteLabelled } = WhiteLabelAccessor;
  return (
    <PageWrapper>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="24px"
      >
        {!isWhiteLabelled() && <J360Logo />}
        <PurpleText>We'll be back shortly.</PurpleText>
        <MessageText>
          We are going under scheduled maintenance. Please check back at a later time, we appreciate
          your patience.
        </MessageText>
      </Flex>
    </PageWrapper>
  );
}
