import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { IGlobalReduxStore } from 'store';

const getHeartbeat = (state: IGlobalReduxStore) => state.heartbeat;
export const isJ360ApiDown = createCachedSelector(
  getHeartbeat,
  (heartbeat) => heartbeat.isJ360ApiDown,
)((store) => `${store.heartbeat.isJ360ApiDown}`);

export const useIsJ360ApiDown = () => useSelector(isJ360ApiDown);
