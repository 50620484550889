import {
  IUseMonthRangePickerBehaviorsArgs,
  IUseMonthRangePickerReturn,
} from './MonthRangePicker.types';
import {
  getPrettyRange,
  getOneMonthRangeFromPreviousDay,
  getOneMonthRangeFromNextDay,
} from '../../BKJDatePicker.utils';

export function useMonthRangePickerBehaviors({
  value,
}: IUseMonthRangePickerBehaviorsArgs): IUseMonthRangePickerReturn {
  const startDate = value[0];
  const previousValue = getOneMonthRangeFromPreviousDay({ date: startDate });
  const nextValue = getOneMonthRangeFromNextDay({ date: startDate });
  const displayDate = getPrettyRange(value);
  return {
    nextValue,
    previousValue,
    displayDate,
  };
}
