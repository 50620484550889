import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { AppLink } from 'components/AppLink/AppLink';
import { FeatureGuard } from 'guards/FeatureGuard';
import { UserGuard } from 'guards/UserGuard';
import { FC } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

function getColor() {
  const { whiteLabelProvider, WhiteLabelProvidersEnum } = WhiteLabelAccessor;
  switch (whiteLabelProvider) {
    case WhiteLabelProvidersEnum.XTM:
      return 'AccentPurple';
    default:
      return 'White';
  }
}

export const CreateShiftButton: FC = () => {
  const { t } = useTranslation();
  return (
    <FeatureGuard NOT type="FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER">
      <UserGuard
        userType={['USER_TYPE_B2B', 'USER_TYPE_PREMIUM_SECONDARY', 'USER_TYPE_ESSENTIAL_SECONDARY']}
      >
        <AppLink to="/create-shifts">
          <BKJButton variant="PurpleSolid">
            <BKJIcon iconName="NewBooking" color={getColor()} fontSize={20} />
            &nbsp; {t('schedule:CREATE_SHIFT')}
          </BKJButton>
        </AppLink>
      </UserGuard>
    </FeatureGuard>
  );
};
