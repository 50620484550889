import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { customFieldEditModalRoutingService } from 'components/CustomFieldEditModal/contexts/CustomFieldModalEdit.ui.context';
import { useFetchCommunitiesDataContext } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import { useFetchCustomFieldByIdDataContext } from 'components/CustomFieldModal/Contexts/FetchCustomFieldById.data.context';
import { ApplyFacilitiesFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { useFormBehaviors } from 'hooks';
import { FormBehaviorsType, UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { VALIDATION_communityIds } from 'validations/VALIDATION_communityIds';

type ApplyFacilitiesFormContextType = UseFormBehaviors<ApplyFacilitiesFormSchema> & {
  handleApplyFacilitiesChange: (e: ChangeEventType) => void;
  handleApplyAllFacilitiesChange: (e: ChangeEventType) => void;
};

export const ApplyFacilitiesFormContext = createContext<ApplyFacilitiesFormContextType>(
  {} as ApplyFacilitiesFormContextType,
);

export const ApplyFacilitiesFormProvider: FC = ({ children }) => {
  const { data: communitiesList } = useFetchCommunitiesDataContext();
  const { data } = useFetchCustomFieldByIdDataContext();

  const initialState = useMemo<ApplyFacilitiesFormSchema>(
    () => ({
      allFacilities: data.allFacilities,
      communityIds: data.communityIds,
    }),
    [data.allFacilities, data.communityIds],
  );

  const customFieldFacilitiesFormBehaviorsOptions = {
    initialState,
    type: 'EDIT' as FormBehaviorsType,
    isDirtyCheckEnabled: true,
    onSubmit: () => customFieldEditModalRoutingService.goToView('EditCustomFieldView'),
    validations: {
      communityIds: VALIDATION_communityIds,
    },
  };

  const customFieldFacilitiesForm = useFormBehaviors<ApplyFacilitiesFormSchema>(
    customFieldFacilitiesFormBehaviorsOptions,
  );

  const handleApplyFacilitiesChange = useCallback(
    (event: ChangeEventType) => {
      const {
        target: { value },
      } = event;

      if (customFieldFacilitiesForm.values.communityIds.includes(`${value}`)) {
        customFieldFacilitiesForm.onChange([
          {
            target: {
              name: 'allFacilities',
              value: false,
            },
          },
          {
            target: {
              name: 'communityIds',
              value: customFieldFacilitiesForm.values.communityIds.filter(
                (communityId) => communityId !== `${value}`,
              ),
            },
          },
        ]);
      } else {
        customFieldFacilitiesForm.onChange([
          {
            target: {
              name: 'allFacilities',
              value: false,
            },
          },
          {
            target: {
              name: 'communityIds',
              value: [...customFieldFacilitiesForm.values.communityIds, `${value}`],
            },
          },
        ]);
      }
    },
    [customFieldFacilitiesForm],
  );

  const handleApplyAllFacilitiesChange = useCallback(
    (event: ChangeEventType) => {
      const {
        target: { value },
      } = event;

      const updatedCommunityIds = value
        ? communitiesList?.fetchCommunities.nodes.map((community) => `${community.id}`)
        : [];

      customFieldFacilitiesForm.onChange([
        {
          target: {
            name: 'allFacilities',
            value,
          },
        },
        {
          target: {
            name: 'communityIds',
            value: updatedCommunityIds,
          },
        },
      ]);
    },
    [communitiesList?.fetchCommunities.nodes, customFieldFacilitiesForm],
  );

  const contextValue = useMemo(
    () => ({
      ...customFieldFacilitiesForm,
      handleApplyFacilitiesChange,
      handleApplyAllFacilitiesChange,
    }),
    [customFieldFacilitiesForm, handleApplyFacilitiesChange, handleApplyAllFacilitiesChange],
  );

  return (
    <ApplyFacilitiesFormContext.Provider value={contextValue}>
      {children}
    </ApplyFacilitiesFormContext.Provider>
  );
};

export const useApplyFacilitiesFormContext = () => useContext(ApplyFacilitiesFormContext);
