import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  ${({ isNewTabInput }) =>
    isNewTabInput &&
    css`
      button {
        cursor: text;
        &:hover {
          cursor: text;
        }
      }
    `}
`;

export const Button = styled.button`
  background: none;
  border: none;
  height: 20px;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #8080ba;
  height: 20px;
  caret-color: #8080ba;

  &:focus {
    ::placeholder {
      color: transparent;
    }
  }

  &:not(:focus) {
    ::placeholder {
      ${({ theme }) => theme.fonts.PoppinsText};
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: #45424e;
    }
  }
`;

export const Anchor = styled.div``;
