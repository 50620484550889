import i18next from 'i18next';
import { toastService } from 'services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function firstArrayValue(obj: any): any {
  const MAX_DEPTH = 10;
  let currentObj = obj;

  for (let depth = 0; depth < MAX_DEPTH; depth++) {
    const values = Object.values(currentObj);
    const firstObject = values.filter((o) => typeof o === 'object' && !Array.isArray(o))[0];
    const firstArray = values.filter((o) => Array.isArray(o))[0];

    if (firstObject) {
      currentObj = firstObject;
    } else if (firstArray && Array.isArray(firstArray)) {
      return firstArray[0];
    } else {
      return null;
    }
  }
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deriveErrorMessage(error: any) {
  // { error: { message: 'error message' } }
  if (error?.message) {
    return error.message;
  }
  // { data: { entity: { key: ['error message'] } } }
  // { data: { entity: ['error message'] } }
  if (error?.data) {
    // { error: { data: { message: 'error message' } } }
    if (error.data?.message) {
      return error?.data?.message;
    }
    return firstArrayValue(error.data);
  }
}

export function handleGenericErrorMessage(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  customErrorMessage: string = i18next.t('errors:SOMETHING_WENT_WRONG'),
) {
  let errorMsg = deriveErrorMessage(error);

  if (!errorMsg) {
    errorMsg = customErrorMessage;
  }

  toastService.error(errorMsg);
}
