import { FC } from 'react';

export const PetalMainLogo: FC = () => {
  return (
    <svg width="94" height="44" viewBox="0 0 94 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3_2)">
        <path
          d="M15.59 1.40451C14.75 0.87451 13.81 0.51451 12.83 0.35451C11.34 0.09451 9.82 -0.0254899 8.3 0.00451005H0.15C0.07 0.00451005 0 0.0745101 0 0.15451V20.6345C0 20.7145 0.06 20.7845 0.14 20.7845H6.03C6.11 20.7845 6.18 20.7145 6.18 20.6345V14.4445C6.18 14.3645 6.25 14.2945 6.33 14.2945H10.45C11.61 14.3145 12.77 14.1545 13.89 13.8245C14.8 13.5545 15.65 13.0845 16.37 12.4545C17.04 11.8445 17.56 11.0845 17.88 10.2345C18.24 9.25451 18.42 8.21451 18.4 7.16451C18.43 5.99451 18.19 4.83451 17.71 3.76451C17.23 2.79451 16.49 1.97451 15.59 1.39451V1.40451ZM11.52 9.02451C11 9.36451 10.04 9.53451 8.56 9.53451H6.32C6.24 9.53451 6.17 9.46451 6.17 9.38451V4.95451C6.17 4.87451 6.24 4.80451 6.32 4.80451H8.56C10.07 4.80451 11.05 4.97451 11.55 5.31451C12.06 5.66451 12.33 6.29451 12.33 7.17451C12.33 8.04451 12.06 8.66451 11.52 9.02451Z"
          fill="#000044"
        />
        <path
          d="M27.01 12.3745H35.28C35.36 12.3745 35.43 12.3045 35.43 12.2245V8.05452C35.43 7.97452 35.37 7.90452 35.28 7.90452H27.01C26.93 7.90452 26.86 7.83452 26.86 7.75452V5.35452C26.86 5.27452 26.93 5.20452 27.01 5.20452H36.85C36.93 5.20452 37 5.13452 37 5.05452V0.154517C37 0.0745166 36.93 0.0045166 36.85 0.0045166H20.83C20.75 0.0045166 20.68 0.0745166 20.68 0.154517V20.6345C20.68 20.7145 20.75 20.7845 20.83 20.7845H37.26C37.34 20.7845 37.41 20.7145 37.41 20.6345V16.1945C37.41 16.1145 37.34 16.0445 37.26 16.0445H27.01C26.93 16.0445 26.86 15.9745 26.86 15.8945V12.5145C26.86 12.4345 26.93 12.3645 27.01 12.3645V12.3745Z"
          fill="#000044"
        />
        <path
          d="M45.76 20.7745H51.64C51.72 20.7745 51.79 20.7045 51.79 20.6245V5.85452C51.79 5.77452 51.86 5.70452 51.94 5.70452H57.62C57.7 5.70452 57.77 5.63452 57.77 5.55452V0.154517C57.77 0.0745166 57.7 0.0045166 57.62 0.0045166H39.67C39.59 0.0045166 39.52 0.0745166 39.52 0.154517V5.56452C39.52 5.64452 39.59 5.71452 39.67 5.71452H45.47C45.55 5.71452 45.62 5.78452 45.62 5.86452V20.6345C45.62 20.7145 45.69 20.7845 45.77 20.7845L45.76 20.7745Z"
          fill="#000044"
        />
        <path
          d="M62.64 0.104517L55.43 20.5845C55.4 20.6645 55.44 20.7445 55.52 20.7745C55.54 20.7745 55.55 20.7745 55.57 20.7745H61.51C61.58 20.7745 61.63 20.7345 61.65 20.6645L62.41 18.0745C62.43 18.0145 62.49 17.9645 62.55 17.9645H69.37C69.44 17.9645 69.49 18.0145 69.51 18.0745L70.24 20.6645C70.26 20.7245 70.32 20.7745 70.38 20.7745H76.37C76.45 20.7745 76.52 20.7045 76.52 20.6245C76.52 20.6045 76.52 20.5945 76.52 20.5745L69.31 0.104517C69.29 0.0445166 69.23 0.0045166 69.17 0.0045166H62.77C62.71 0.0045166 62.65 0.0445166 62.63 0.104517H62.64ZM63.61 13.7445L65.84 6.18452C65.86 6.10452 65.94 6.06452 66.02 6.08452C66.07 6.09452 66.11 6.13452 66.12 6.18452L68.3 13.7545C68.32 13.8345 68.28 13.9145 68.2 13.9345C68.19 13.9345 68.17 13.9345 68.16 13.9345H63.75C63.67 13.9345 63.6 13.8645 63.6 13.7845C63.6 13.7745 63.6 13.7545 63.6 13.7445H63.61Z"
          fill="#000044"
        />
        <path
          d="M84.79 0.0045166H78.9C78.82 0.0045166 78.75 0.0745166 78.75 0.154517V20.6345C78.75 20.7145 78.82 20.7845 78.9 20.7845H94.03C94.11 20.7845 94.18 20.7145 94.18 20.6345V15.1345C94.18 15.0545 94.11 14.9845 94.03 14.9845H85.09C85.01 14.9845 84.94 14.9145 84.94 14.8345V0.154517C84.94 0.0745166 84.87 0.0045166 84.79 0.0045166Z"
          fill="#000044"
        />
        <path
          d="M66.49 44.0045H72.39C72.47 44.0045 72.54 43.9345 72.54 43.8545V27.1245C72.54 27.0445 72.47 26.9745 72.39 26.9745H55.44C55.36 26.9745 55.29 27.0445 55.29 27.1245V32.6245C55.29 32.7045 55.35 32.7745 55.43 32.7745H66.19C66.27 32.7745 66.34 32.8445 66.34 32.9245V43.8545C66.34 43.9345 66.41 44.0045 66.49 44.0045Z"
          fill="#735BF5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_2">
          <rect width="94" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
