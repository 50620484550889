import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { BKJIconNameType } from '@bookjane2/bookjane-design-library/lib/components/BKJIcon/BKJIcon.types';
import { IconButtonWrapper } from './IconButton.styled';

export function IconButton({
  iconName,
  onClick,
}: {
  iconName: BKJIconNameType;
  onClick: (ev: unknown) => void;
}) {
  return (
    <IconButtonWrapper onClick={onClick}>
      <BKJIcon iconName={iconName} />
    </IconButtonWrapper>
  );
}
