import { GQLAPIContextType } from 'types/common.types';
import { FC, ReactNode, useContext, useEffect } from 'react';
import { useGQLQuery } from 'hooks';
import { createGQLDataContext } from 'utils/createDataContext';
import { positionNameLabelQueryConfig } from 'components/PositionNameLabel/PositionNameLabel.constants';
import { positionNameLabel } from 'types/graphql-types';

export const PositionNameLabelContext = createGQLDataContext({
  positionName: '',
  isLoading: true,
}) as GQLAPIContextType;

export const PositionNameLabelProvider: FC<{ children: ReactNode; position_id: number }> = ({
  children,
  position_id,
}) => {
  const { data, fetch, isLoading } = useGQLQuery<positionNameLabel>(
    positionNameLabelQueryConfig(position_id),
  );
  const positionName = data?.fetchPosition.name;

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position_id]);

  return (
    <PositionNameLabelContext.Provider value={{ positionName, isLoading }}>
      {children}
    </PositionNameLabelContext.Provider>
  );
};

export const usePositionNameLabelContext = (): { positionName: ''; isLoading: true } =>
  useContext(PositionNameLabelContext);
