import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { FetchCommunitiesDataContext } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import {
  FacilitiesSelectionTableWrapper,
  FacilitiesSelectionTableHeader,
  FacilitiesSelectionTableLabel,
} from 'components/CustomFieldModal/CustomFieldModal.styled';
import { ApplyFacilitiesFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { FacilitiesSelectionTableContent } from 'components/CustomFieldModal/Views/ApplyEditFacilitiesToCustomField/FacilitiesSelectionTableContent';
import { QuerySwitch } from 'components/QuerySwitch';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const FacilitiesSelectionTable: FC<{
  values: ApplyFacilitiesFormSchema;
  handleApplyFacilitiesChange: ChangeFunctionType;
  handleApplyAllFacilitiesChange: ChangeFunctionType;
}> = ({ values, handleApplyFacilitiesChange, handleApplyAllFacilitiesChange }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <FacilitiesSelectionTableHeader justifyContent="space-between">
        <FacilitiesSelectionTableLabel>{t('settings:Locations')}</FacilitiesSelectionTableLabel>
        <FacilitiesSelectionTableLabel>{t('auth:APPLY')}</FacilitiesSelectionTableLabel>
      </FacilitiesSelectionTableHeader>
      <FacilitiesSelectionTableWrapper flexDirection="column">
        <QuerySwitch
          context={FetchCommunitiesDataContext}
          component={() => (
            <FacilitiesSelectionTableContent
              values={values}
              handleApplyFacilitiesChange={handleApplyFacilitiesChange}
              handleApplyAllFacilitiesChange={handleApplyAllFacilitiesChange}
            />
          )}
        />
      </FacilitiesSelectionTableWrapper>
    </Fragment>
  );
};
