import { BKJSideModal } from 'components/BKJSideModal';
import { FetchCommunitiesDataProvider } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import {
  FetchCustomFieldByIdDataContext,
  FetchCustomFieldByIdDataProvider,
} from 'components/CustomFieldModal/Contexts/FetchCustomFieldById.data.context';
import { QuerySwitch } from 'components/QuerySwitch';
import { FetchCommunitiesCountDataProvider } from 'components/CustomFieldModal/Contexts/FetchCommunitiesCount.data.context';
import { FC, useMemo } from 'react';
import { withMemo } from 'utils/withMemo';
import {
  CustomFieldEditModalViewMap,
  getHeaderComponent,
} from 'components/CustomFieldEditModal/CustomFieldEditModal.views';
import {
  customFieldEditModalRoutingService,
  CustomFieldEditModalUIProvider,
  useCustomFieldEditModalUIContext,
} from 'components/CustomFieldEditModal/contexts/CustomFieldModalEdit.ui.context';
import { EditCustomFieldFormProvider } from 'components/CustomFieldEditModal/contexts/EditCustomField.form.context';
import {
  CustomFieldEditModalPropsProvider,
  useCustomFieldEditModalPropsContext,
} from 'components/CustomFieldEditModal/contexts/CustomFieldModalEdit.props.context';
import {
  ApplyFacilitiesFormProvider,
  useApplyFacilitiesFormContext,
} from 'components/CustomFieldEditModal/contexts/ApplyFacilities.form.context';

const SideModal: FC = () => {
  const { onClose } = useCustomFieldEditModalPropsContext();
  const { editCustomFieldActiveModalView, isOpen } = useCustomFieldEditModalUIContext();
  const { onReset } = useApplyFacilitiesFormContext();

  const Component = useMemo(
    () => CustomFieldEditModalViewMap[editCustomFieldActiveModalView],
    [editCustomFieldActiveModalView],
  );

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={onClose!}
      headerComponent={getHeaderComponent(editCustomFieldActiveModalView)}
      showBackButton={customFieldEditModalRoutingService.isBackButtonVisible()}
      onBack={() => {
        onReset();
        customFieldEditModalRoutingService.goToPrevView();
      }}
    >
      {Component ? <Component /> : null}
    </BKJSideModal>
  );
};

const FetchCustomFieldComponent: FC = () => {
  const { customFieldId } = useCustomFieldEditModalUIContext();

  return useMemo(
    () => (
      <FetchCustomFieldByIdDataProvider customFieldId={customFieldId!}>
        <QuerySwitch
          context={FetchCustomFieldByIdDataContext}
          component={() => (
            <CustomFieldEditModalPropsProvider>
              <ApplyFacilitiesFormProvider>
                <EditCustomFieldFormProvider>
                  <SideModal />
                </EditCustomFieldFormProvider>
              </ApplyFacilitiesFormProvider>
            </CustomFieldEditModalPropsProvider>
          )}
        />
      </FetchCustomFieldByIdDataProvider>
    ),
    [customFieldId],
  );
};

const _CustomFieldEditModal: FC = () => {
  return (
    <CustomFieldEditModalUIProvider>
      <FetchCommunitiesDataProvider>
        <FetchCommunitiesCountDataProvider>
          <FetchCustomFieldComponent />
        </FetchCommunitiesCountDataProvider>
      </FetchCommunitiesDataProvider>
    </CustomFieldEditModalUIProvider>
  );
};

export const CustomFieldEditModal = withMemo(_CustomFieldEditModal);
