import { FC, Fragment, useMemo } from 'react';
import {
  BKJCheckboxListItem,
  BKJSelect,
  BKJSelectSearchControls,
} from '@bookjane2/bookjane-design-library';
import { UserEmploymentStatusEnum } from 'types/graphql-types';
import { castEnumToDropdownItems } from 'utils/castEnumsToDropdownItems';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { InputNameType } from 'types/common.types';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { EmploymentStatusFilterInputOptionType } from 'components/EmploymentStatusFilterInput/EmploymentStatusFilterInput.types';
import { useTranslation } from 'hooks/useTranslation';

export const EmploymentStatusFilterInput: FC<{
  value: string[];
  onChange: ChangeFunctionType;
  name: InputNameType;
  options?: EmploymentStatusFilterInputOptionType[];
}> = ({ value, onChange, name, options }) => {
  const { t, i18n } = useTranslation();
  let _options = useMemo(
    () =>
      castEnumToDropdownItems(UserEmploymentStatusEnum, name).map(
        ({ value, label, ...rest }) => ({
          ...rest,
          value,
          label: i18n.exists(`status:${value.toUpperCase()}`)
            ? t(`status:${value.toUpperCase()}`)
            : label,
        }),
        [],
      ),
    [i18n, name, t],
  );
  // let _options = castEnumToDropdownItems(UserEmploymentStatusEnum, name);

  if (options) {
    _options = _options.filter((option) => options.some((opt) => opt === option.value));
  }
  const RenderOptions: FC<IBKJSelectRenderOptionsProps> = (props) => {
    const isAllSelected = props.selected.length === _options.length;
    const { i18n } = useTranslation();

    return (
      <Fragment>
        <BKJSelectSearchControls
          locale={i18n.language}
          numSelected={props.selected.length}
          isAllSelected={isAllSelected}
          onSelectAll={() => {
            isAllSelected
              ? props.onChange({
                  target: {
                    name: name,
                    label: [],
                    value: [],
                  },
                })
              : props.onChange({
                  target: {
                    name: name,
                    label: _options.map(({ label }) => label),
                    value: _options.map(({ value }) => value),
                  },
                });
          }}
        />
        <div>
          {_options.map((opt) => {
            const { type, ...restProps } = props;
            return <BKJCheckboxListItem {...restProps} {...opt} />;
          })}
        </div>
      </Fragment>
    );
  };
  return (
    <BKJSelect
      moreValuesLabel={t('MORE')}
      options={_options}
      type="Multiple"
      variant="GreyOutlinedPillClear"
      placeholder={t('common:EMPLOYMENT_STATUS')}
      dropdownWidth="358px"
      dropdownPlacement="bottom-start"
      name={name}
      onChange={onChange}
      value={value}
      renderOptions={RenderOptions}
    />
  );
};
