import { FC, useContext, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useLocation } from 'react-router-dom';
import { fetchGroups, fetchGroupsVariables } from 'types/graphql-types';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { useTranslation } from 'hooks/useTranslation';
import { useQueryForm } from 'hooks/useQueryForm';
import { authLink, createApolloClient, httpLink } from 'providers/ApolloProvider';
import { QUERY_settingsGroups } from 'queries';
import { IQueryFormResult } from 'hooks/useQueryForm/useQueryForm.types';

export const ShiftDistributionRulesGroupsModalDataContext =
  createGQLDataContext<IQueryFormResult<GroupsOptionType[], fetchGroupsVariables>>();

export type GroupsOptionType = {
  id: string;
  name: string;
  label: string;
  subLabel?: string;
  key: number;
  value: number;
  type: IBKJComboBoxAsyncOption['type'];
};

const client = createApolloClient();

export const ShiftDistributionRulesGroupsModalDataProvider: FC = ({ children }) => {
  client.setLink(authLink.concat(httpLink()));
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const { t } = useTranslation();

  const result = useQueryForm(
    {
      key: 'FETCH_GROUPS_QUERY',
      query: QUERY_settingsGroups,
      pageSize: 10,
      paginationType: 'InfiniteScroll',
      client,
      responseTransformer: (data: fetchGroups | undefined) => {
        if (!data || !data?.fetchCommunity?.groups?.nodes?.length) return [];
        const groupsOptions: GroupsOptionType[] = data.fetchCommunity?.groups?.nodes
          ? data.fetchCommunity.groups.nodes.map(({ id, displayId, name, __typename }) => {
              return {
                name: name,
                label: name,
                subLabel: `${
                  __typename === 'StaticGroup'
                    ? t('groups:GROUPS_STATIC_TYPE_SUBLABEL')
                    : t('groups:GROUPS_DYNAMIC_TYPE_SUBLABEL')
                }`,
                key: displayId,
                value: displayId,
                id: id,
                type: 'Standard',
              };
            })
          : [];
        return groupsOptions;
      },
    },
    {
      locationId: Number.parseInt(locationId),
      filter: { withName: '' },
    },
  );

  const contextValues = useMemo(() => {
    return result;
  }, [result]);

  return (
    <ShiftDistributionRulesGroupsModalDataContext.Provider value={contextValues}>
      {children}
    </ShiftDistributionRulesGroupsModalDataContext.Provider>
  );
};

export const useShiftDistributionRulesGroupsModalDataContext = (): IQueryFormResult<
  GroupsOptionType[],
  fetchGroupsVariables
> => useContext(ShiftDistributionRulesGroupsModalDataContext);
