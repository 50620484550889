import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isMultiLocationSchedulingFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    if (userPermissions?.MULTI_LOCATION_SCHEDULING?.access)
      return (
        userPermissions?.MULTI_LOCATION_SCHEDULING?.access?.includes('read') &&
        !!userPermissions.MULTI_LOCATION_SCHEDULING.communities &&
        !!userPermissions.MULTI_LOCATION_SCHEDULING.communities[communityId] &&
        !!userPermissions.MULTI_LOCATION_SCHEDULING.communities[communityId]?.includes('read')
      );
    else return false;
  });
