import { B2B_DELETE_CUSTOM_FIELDS } from 'constants/endpoints';
import { METHOD_DELETE } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_DELETE_customField = (customFieldId: string): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: B2B_DELETE_CUSTOM_FIELDS(customFieldId),
    method: METHOD_DELETE,
  };
};
