import React, { FC, useContext } from 'react';
import { BKJTableSubHeader } from 'components/BKJTable/BKJTableSubHeader';
import { ShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import {
  BKJTableWrapper,
  BKJTableBody,
  BKJStickyTableHead,
} from 'components/BKJTable/BKJTable.styled';
import { shiftsListInitialSortFields } from 'pages/ShiftsPage/ShiftsPage.constants';
import { onSort } from 'pages/ShiftsListPage/ShiftsTable/ShiftsTable.utils';
import { ShiftsAgencyTableRow } from 'pages/ShiftsListPage/ShiftsTableRow/ShiftsAgencyTableRow';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { ShiftsAgencyTableColumnNameType } from 'pages/ShiftsListPage/ShiftsTable/ShiftsAgencyTable.types';
import { shiftsListPage_fetchOrders_edges } from 'types/graphql-types';
import { IDirectionType } from 'components/BKJTable/BKJTableSubHeader/BKJTableSubHeader.types';
import { SHIFTS_AGENCY_TABLE_COLUMNS_WIDTH } from 'pages/ShiftsListPage/ShiftsTable/ShiftsTable.constants';
import { useTranslation } from 'hooks/useTranslation';

export const ShiftsAgencyTable: FC = () => {
  const { values, data, onChange } = useContext(ShiftsPageContext);

  function TableSort(e: ChangeEventType) {
    onChange(onSort(e));
  }

  const sortFields = values.sortField
    ? [JSON.parse(values.sortField)]
    : shiftsListInitialSortFields;

  const getColumnDirection = (column: ShiftsAgencyTableColumnNameType): IDirectionType => {
    const field = sortFields.find((field) => field.name === column);
    if (field) {
      return field.direction;
    }
    return 'default';
  };
  const { t } = useTranslation();

  return (
    <BKJTableWrapper>
      <BKJTableBody gridTemplateColumns={SHIFTS_AGENCY_TABLE_COLUMNS_WIDTH}>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            title={t('shifts_calendar:SHIFT_DATE')}
            name="startAt"
            onChevronClick={TableSort}
            direction={getColumnDirection('startAt')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader name="customer" title={t('common:CUSTOMER')} direction="default" />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader name="city" title={t('shifts_calendar:CITY')} direction="default" />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="janeName"
            title={t('common:TEAM_MEMBER')}
            onChevronClick={TableSort}
            direction={getColumnDirection('janeName')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="positionName"
            title={t('common:POSITION')}
            onChevronClick={TableSort}
            direction={getColumnDirection('positionName')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="status"
            title={t('shifts_calendar:STATUS')}
            direction="default"
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="startAt"
            title={t('shifts_calendar:START_TIME')}
            direction={getColumnDirection('startAt')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="endAt"
            title={t('shifts_calendar:END_TIME')}
            direction={getColumnDirection('endAt')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="unpaidBreakTime"
            title={t('shifts_calendar:UNPAID_BREAKTIME')}
            direction={getColumnDirection('unpaidBreakTime')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="totalDuration"
            title={t('common:TOTAL_DURATION')}
            onChevronClick={TableSort}
            direction={getColumnDirection('totalDuration')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="hourly_rate"
            title={t('common:HOURLY_PAY_RATE')}
            direction="default"
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="estimated_total_pay"
            title={t('common:ESTIMATED_TOTAL_PAY')}
            direction="default"
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="id"
            title={t('shifts_calendar:SHIFT_NO')}
            onChevronClick={TableSort}
            direction={getColumnDirection('id')}
          />
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader
            name="createdAt"
            title={t('shifts_calendar:CREATED_AT')}
            onChevronClick={TableSort}
            direction={getColumnDirection('createdAt')}
          />
        </BKJStickyTableHead>
        {data?.fetchOrders?.edges.map(({ node: order }: shiftsListPage_fetchOrders_edges) => {
          if (order !== null) return <ShiftsAgencyTableRow key={order.id} {...order} />;
          return null;
        })}
      </BKJTableBody>
    </BKJTableWrapper>
  );
};
