import { useEffect } from 'react';
import { LoginFormSchema } from 'pages/LoginPage/LoginPage';
import { useUserActions } from 'store/reducers/auth/authReducer';
import { SessionService } from 'services';
import { authResponseHandler } from 'pages/LoginPage/LoginPage.constants';
import { AsyncThunk } from '@reduxjs/toolkit';
import { IUserLoginActionPayload } from 'store/reducers/auth/authReducer.types';

enum MESSAGE_EVENTS {
  login,
  logout,
}

const loginUser = async ({
  username,
  password,
  userLoginAction,
}: LoginFormSchema & {
  userLoginAction: AsyncThunk<IUserLoginActionPayload, LoginFormSchema, {}>;
}) => {
  authResponseHandler(await userLoginAction({ username, password }));
  window.location.reload();
  return false;
};

function handleLoginMessageEvent(
  { username, password }: LoginFormSchema,
  userLoginAction: AsyncThunk<IUserLoginActionPayload, LoginFormSchema, {}>,
) {
  const currentUser =
    SessionService.getUser() && SessionService.getToken()
      ? SessionService.getUser()?.email
      : undefined;
  if (username && username !== currentUser) {
    const trimmedUsername = username.trim();
    console.log(`Trigger login for user: ${trimmedUsername}`);
    loginUser({ username: trimmedUsername, password, userLoginAction });
  } else {
    console.log(`User ${username} is already logged in`);
    console.log(SessionService.getUser());
  }
}

export function useMessaging() {
  const { userLogin, userLogout } = useUserActions();

  useEffect(() => {
    const messageEventHandler = ({ data /*, origin*/ }: MessageEvent) => {
      if (data && data.action) {
        switch (data.action) {
          case MESSAGE_EVENTS[MESSAGE_EVENTS.login]:
            if (data.payload) {
              handleLoginMessageEvent(data.payload, userLogin);
            } else {
              console.log(
                `Incorrect payload provided, got ${JSON.stringify(data.payload)} but expected {
              username: string;
              password: string;
            }`,
              );
            }
            break;
          case MESSAGE_EVENTS[MESSAGE_EVENTS.logout]:
            console.log('Logout user');
            userLogout();
            break;
        }
      }
    };
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
  }, [userLogin, userLogout]);
}
