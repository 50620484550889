import {
  EmptyBody,
  EmptyHeader,
  EmptyMessage,
  EmptyViewFlex,
} from './DedicatedTeamEmptyView.styled';

export function DedicatedTeamEmptyView() {
  return (
    <EmptyViewFlex width="100%" height="100%" justifyContent="center" alignItems="center">
      <EmptyMessage>
        <EmptyHeader>You currently have no dedicated teams</EmptyHeader>
        <EmptyBody>
          Create dedicated teams and add the agency members that are trained for your locations.
          Only they will be able to accept your shifts unless you choose to override this in Create
          Shift.
        </EmptyBody>
      </EmptyMessage>
    </EmptyViewFlex>
  );
}
