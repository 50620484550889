import { CalendarDayCell } from 'components/ShiftCalendar/CalendarDayCell/CalendarDayCell';
import { CalendarRowWrapper } from 'components/ShiftCalendar/ShiftCalendar.styled';
import { getDaysArrayBetweenTwoDates } from 'components/BKJDatePicker/src/BKJDatePicker.utils';
import { ISO8601Type } from 'types/common.types';
import { useShiftsPageContext } from 'pages/ShiftsPage/ShiftsPage.context';
import { FC } from 'react';
import { formatDate } from 'format/formatDate';
import { withMemo } from 'utils/withMemo';

export const CalendarDayWrapper: FC = withMemo(() => {
  const { filterDate } = useShiftsPageContext().values;

  const arrayOfDays = getDaysArrayBetweenTwoDates(filterDate);
  return (
    <CalendarRowWrapper>
      {arrayOfDays.map((date: ISO8601Type) => {
        const formattedDate = formatDate(date, 'yyyy-LL-dd');
        return <CalendarDayCell date={formattedDate} key={formattedDate} />;
      })}
    </CalendarRowWrapper>
  );
});
