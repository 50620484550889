import { FormValidationErrorMessageType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { DeleteViewFormSchema } from 'components/ShiftModal/src/views/DeleteView/DeleteView.types';
import { isString } from 'types/common.types';

export const VALIDATION_cancellationReason = (value: string, rest: DeleteViewFormSchema) => {
  const messages: FormValidationErrorMessageType = [];

  if (isString(value)) {
    if (value.length === 0 && rest.cancellationReason[0] === 'other')
      messages.push('This field is required');
  }
  return messages;
};
