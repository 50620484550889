import { METHOD_PATCH } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { TIMESHEETS, CUSTOMER_TIMESHEETS } from 'constants/endpoints';
import { SessionService } from 'services/SessionService';

export const API_PATCH_approveTimeSheet = (params: {
  id: number;
  startTime: string;
  endTime: string;
  unpaidBreak: string;
}): ApiRequestConfigType => {
  const url =
    (SessionService.assertUserType('Agency') ? TIMESHEETS : CUSTOMER_TIMESHEETS) + '/' + params.id;
  return {
    __typename: 'LegacyAPI',
    method: METHOD_PATCH,
    url,
    body: {
      time_sheet: {
        approved_start_time: params.startTime,
        approved_end_time: params.endTime,
        approved_unpaid_break_time: params.unpaidBreak,
        status: 'approved',
      },
    },
  };
};

export const API_PATCH_noShowTimeSheet = (params: { id: number }): ApiRequestConfigType => {
  const url =
    (SessionService.assertUserType('Agency') ? TIMESHEETS : CUSTOMER_TIMESHEETS) + '/' + params.id;
  return {
    __typename: 'LegacyAPI',
    method: METHOD_PATCH,
    url,
    body: {
      time_sheet: {
        status: 'no_show',
      },
    },
  };
};
