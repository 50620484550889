import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  ActivityTabTableStyled,
  ActivityTabTableWrapper,
  TableHeaderCell,
} from 'components/ActivityTab/ActivityTab.styled';
import { DesktopAuditTrailActivityTabRow } from 'components/AuditTrailActivityTab/DesktopAuditTrailActivityTabRow';
import { ActivityTabRowPropsProvider } from 'components/ActivityTab/ActivityTabRow.props.context';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes';
import { UserType } from 'types/activities.types';
import { ActivitiesType } from 'types/users.types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { MobileAuditTrailActivityTabRow } from 'components/AuditTrailActivityTab/MobileAuditTrailActivityTabRow';

export const AuditTrailActivityTab: FC<{ activities: ActivitiesType[]; timeZone?: string }> = ({
  activities,
  timeZone,
  children,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const AuditTrailActivityTabRow = isMobile
    ? MobileAuditTrailActivityTabRow
    : DesktopAuditTrailActivityTabRow;
  return (
    <ErrorBoundary
      componentName="AuditTrailActivityTab"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <ActivityTabTableWrapper>
        <MountTransition>
          <ActivityTabTableStyled>
            {!isMobile && (
              <Fragment>
                <TableHeaderCell>{t('common:TIME')}</TableHeaderCell>
                <TableHeaderCell>{t('common:ACTIVITY')}</TableHeaderCell>
                <TableHeaderCell>{t('dashboard:DETAILS')}</TableHeaderCell>
              </Fragment>
            )}
            {activities?.map(({ createdAt, event, user, metadata }, index) => {
              const userDetails = { ...user } as UserType;

              return (
                <ActivityTabRowPropsProvider
                  timeZone={timeZone}
                  key={createdAt + event.value}
                  createdAt={createdAt}
                  event={event}
                  userDetails={userDetails}
                  index={index}
                  metadata={metadata}
                >
                  <AuditTrailActivityTabRow />
                </ActivityTabRowPropsProvider>
              );
            })}
          </ActivityTabTableStyled>
        </MountTransition>
      </ActivityTabTableWrapper>
      {children}
    </ErrorBoundary>
  );
};
