export function cloneDeep<T>(value: T): T {
  if (value === null || typeof value !== 'object') {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(cloneDeep) as unknown as T;
  }
  const result = {} as T;
  for (const key of Object.keys(value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result[key as keyof T] = cloneDeep((value as any)[key]);
  }

  return result;
}
