import LogRocket from 'logrocket';
import { isArray } from 'types/common.types';
import { deepClean } from 'utils/deepClean';

const cleanKeys = [
  'password',
  'password_confirmation',
  'authorization',
  'Authorization',
  'Cookies',
  'cookies',
  'token',
  'Token',
];
const isObject = (value: unknown): value is Record<string, unknown> =>
  value !== null && typeof value === 'object' && !isArray(value);

export const captureException = (
  error: Error,
  options?: {
    tags?: {
      [tagName: string]: string | number | boolean;
    };
    extra?: {
      [tagName: string]: string | number | boolean | Record<string, unknown>;
    };
  },
): void => {
  const cleanedExtra = deepClean(options?.extra || {}, {
    cleanKeys,
  });

  const stringifiedValuesExtra = cleanedExtra;

  Object.keys(cleanedExtra).forEach((key) => {
    if (isObject(cleanedExtra[key]) || isArray(cleanedExtra[key])) {
      try {
        stringifiedValuesExtra[key] = JSON.stringify(deepClean(cleanedExtra[key], { cleanKeys }));
      } catch (e: unknown) {
        captureException(e as Error, {
          tags: { type: 'JSONStringifyError', file: 'utils/captureException.ts' },
        });
      }
    }
  });

  LogRocket.captureException(error, {
    tags: options?.tags || {},
    extra: stringifiedValuesExtra,
  });

  return void 0;
};
