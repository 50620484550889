/* eslint-disable import/no-anonymous-default-export */
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import {
  InviteLabel,
  InviteText,
  InviteTitle,
  InviteViewWrapper,
} from 'components/InviteTeamMembersModal/InviteTeamMembers.styled';
import { InviteTeamMemberModalTemplate } from 'components/InviteTeamMembersModal/InviteTeamMembersModalTemplate';
import { LocationFilterInput } from 'components/LocationFilterInput';
import React, { useContext, useEffect } from 'react';
import { SuccessView } from '../SuccessView';
import { EmailViewContext, EmailViewContextProvider } from './EmailView.context';

const EmailView: React.FC = () => {
  const {
    formValues,
    flowView,
    // errorIndices,
    setErrorIndices,
    error,
    setError,
    onChange,
    onSubmit,
    setNumInvites,
  } = useContext(EmailViewContext);

  useEffect(() => {
    let errors = new Set<number>();
    let errorMessage = '';
    // Check email formats
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    formValues?.emails?.forEach((email: string, index: number) => {
      if (!regex.test(email)) {
        errors.add(index);
        errorMessage = 'Please make sure emails are in the correct format.';
      }
    });

    // Check for duplicates
    let dupes: { [key: string]: number[] } = {};
    formValues?.emails?.forEach((email: string, index: number) => {
      dupes[email] = dupes[email] || [];
      dupes[email].push(index);
    });
    for (const email in dupes) {
      if (dupes[email].length > 1) {
        dupes[email].forEach((index) => {
          errors.add(index);
        });
        errorMessage = 'Please remove any duplicate emails.';
      }
    }
    setError(errorMessage);
    setErrorIndices(errors);
    setNumInvites(formValues?.emails?.length);
  }, [formValues.emails, formValues?.emails?.length, setErrorIndices, setError, setNumInvites]);

  switch (flowView) {
    case 'success':
      return <SuccessView view="EmailView" />;
    default:
      return (
        <InviteTeamMemberModalTemplate>
          <InviteViewWrapper flexDirection="column" childMarginBottom="32px">
            <InviteTitle>Invite via Email</InviteTitle>
            <InviteText>
              Enter team members' emails and select Invite. <br />
              Users will be invited to download the BookJane app and setup their accounts.
            </InviteText>
            <Flex flexDirection="column" childMarginBottom="8px" width="100%">
              <InviteLabel>Facility</InviteLabel>
              <LocationFilterInput
                value={formValues.facility}
                onChange={(e: ChangeEventType) => onChange('facility', e.target.value)}
                width="100%"
              />
            </Flex>
            <Flex flexDirection="column" childMarginBottom="8px">
              <InviteLabel>Emails</InviteLabel>
              {/* <BKJPillInput
                name="emails"
                value={formValues.emails ? formValues.emails : []}
                setValue={(value: string[]) => onChange('emails', value)}
                errorIndices={Array.from(errorIndices)}
                error={error}
              /> */}
            </Flex>
          </InviteViewWrapper>
          <BKJSideModalFooter>
            <AutoMargin />
            <BKJButton
              width="180px"
              variant="GreenSolid"
              onClick={onSubmit}
              disabled={!!error || !(formValues?.emails?.length > 0 && formValues.facility)}
            >
              Invite
            </BKJButton>
          </BKJSideModalFooter>
        </InviteTeamMemberModalTemplate>
      );
  }
};

export default () => (
  <EmailViewContextProvider>
    <EmailView />
  </EmailViewContextProvider>
);
