import { SSF_GLOBAL_CACHE_KEY } from 'services/ssfPersistenceService';
import { localPersistenceService } from 'services/localPersistenceService';
import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';

export const getCurrentlySelectedLocation = (): LocationOptionType => {
  const cacheKey = SSF_GLOBAL_CACHE_KEY();
  const cacheJson = localPersistenceService.getItem(cacheKey);

  let currentLocation = {} as LocationOptionType;

  if (cacheJson) {
    const globalCache = JSON.parse(cacheJson);
    currentLocation = globalCache?.location;
  }

  return currentLocation;
};
