import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { IGlobalReduxStore } from 'store';
import { isUserObjectAgencyType, isUserObjectB2bType } from 'types/common.types';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

export const isUserB2bSelector = createCachedSelector([getUser], (user) => {
  if (!user) return false;
  if (isUserObjectB2bType(user))
    return user.user_type === 'B2bCustomer' && user.type !== 'SecondaryUser';
  if (isUserObjectAgencyType(user)) return false;
  return false;
})((store) => {
  if (!store.auth.user) return 'NO_SESSION';
  if (isUserObjectB2bType(store.auth.user)) return store.auth.user.user_type;
  return 'AGENCY_USER';
});

export const useIsUserB2bSelector = () => useSelector(isUserB2bSelector);
