/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs';
import {
  ADP_UPLOAD_HISTORY,
  ADP_LOCATION,
  ADP_EMPLOYEES_INFO,
  ADP_USER_INFO,
  ADP_SYNC,
} from 'constants/endpoints';

import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';
import {
  AdpEmployeeAccountDetail,
  AdpEmployer,
  AdpScheduleLog,
  FetchWorkersResponse,
} from 'store/reducers/adpSlice/index.constants';
import { AdpScheduleLogsResponse } from 'pages/ADPProfilePage/ADPProfilePage.types';

interface Item {
  id: string;
  adp_schedule_log_id: string;
  order_schedule_id: string;
  user_id: string;
}

export interface AdpScheduleResult {
  disableRetry?: boolean;
  status?: string | number;
  message: string;
}

const syncADPEmployees = async (userInfo: AdpEmployer): Promise<AdpScheduleResult> => {
  try {
    const get = await fetch(`${ADP_SYNC}?adp_employer_id=${userInfo.id}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await get.json();
    if (get.status === 200) {
      return result;
    }
    return {
      status: get.status,
      message: get.statusText,
    };
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const fetchADPLocations = async (userId: number): Promise<any> => {
  try {
    const get = await fetch(`${ADP_LOCATION}/${userId}`, {
      headers: createBookJaneRequestHeaders({}),
    });

    const result = await get.json();
    return result;
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const fetchUserInfo = async (item: Item, userInfo: AdpEmployer): Promise<AdpScheduleResult> => {
  try {
    const get = await fetch(
      `${ADP_UPLOAD_HISTORY}/${item.id}/retry_adp_schedules?adp_employer_id=${userInfo.id}`,
      {
        headers: createBookJaneRequestHeaders({}),
      },
    );
    const result = await get.json();
    if (get.status === 200) {
      result.disableRetry = true;
      return result;
    }
    return {
      status: 'Error',
      message: result.message,
    };
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const retryAdpSchedule = async (
  item: AdpScheduleLog,
  userInfo: AdpEmployer,
): Promise<AdpScheduleResult> => {
  try {
    const get = await fetch(
      `${ADP_UPLOAD_HISTORY}/${item.id}/retry_adp_schedules?adp_employer_id=${userInfo.id}`,
      {
        method: 'POST',
        headers: createBookJaneRequestHeaders({}),
      },
    );
    const result = await get.json();
    if (get.status === 200) {
      result.disableRetry = true;
      return result;
    }
    return {
      status: 'Error',
      message: result.message,
    };
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const fetchADPEmployeesInfo = async (
  userInfo: AdpEmployer,
  filtered: string[],
  sortBy: string,
  direction: string,
  page: number,
  per_page: number,
): Promise<unknown> => {
  try {
    const queryFilters = {
      filter: {
        with_community: filtered,
      },
    };
    const QUERY = `${qs.stringify(queryFilters, {
      arrayFormat: 'brackets',
      // parseArrays: 'false',
      encodeValuesOnly: true,
    })}`;
    const Sort = `sort=${sortBy}&direction=${direction}`;
    const get = await fetch(
      `${ADP_EMPLOYEES_INFO}?adp_employer_id=${userInfo.id}&per_page=${per_page}&page=${page}&${Sort}&${QUERY}`,
      {
        headers: createBookJaneRequestHeaders({}),
      },
    );
    const result = await get.json();
    return result;
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const fetchUploadHistory = async (
  userInfo: AdpEmployer,
  filtered: string[],
  page: number,
  sortBy: string,
  direction: boolean,
): Promise<unknown> => {
  try {
    const queryFilters = {
      filter: {
        with_community: filtered,
      },
    };
    const QUERY = `${qs.stringify(queryFilters, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    })}`;

    const Sort = `sort=${sortBy}&direction=${direction ? 'asc' : 'desc'}`;
    const URL = ADP_UPLOAD_HISTORY;
    const get = await fetch(
      `${URL}?adp_employer_id=${userInfo.id}&per_page=${10}&page=${page || 1}&${Sort}&${QUERY}`,
      {
        headers: createBookJaneRequestHeaders({}),
      },
    );
    const result = await get.json();
    if (get.status === 200) {
      return result as AdpScheduleLogsResponse;
    }
    return {
      message: result.message,
    };
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const fetchLogDetails = async (
  userInfo: AdpEmployer,
  selectedAccount: AdpScheduleLog,
  page: number,
  sortBy?: string,
  direction?: string,
): Promise<FetchWorkersResponse> => {
  try {
    const Sort = `sort=${sortBy}&direction=${direction}`;
    const URL = `${ADP_UPLOAD_HISTORY}/${selectedAccount.id}?adp_employer_id=${userInfo.id}&page=${
      page || 1
    }&per_page=10&${Sort}`;
    const get = await fetch(`${URL}`, {
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await get.json();
    return result as FetchWorkersResponse;
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const syncTOADP = async (userInfo: AdpEmployer, item: AdpEmployeeAccountDetail): Promise<any> => {
  try {
    const URL = `${ADP_UPLOAD_HISTORY}/${item.adp_schedule_log_id}/retry_push_schedule?adp_employer_id=${userInfo.id}&order_schedule_id=${item.order_schedule_id}&user_id=${item.user_id}&adp_timesheet_log_id=${item.id}`;
    const post = await fetch(`${URL}`, {
      method: 'POST',
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await post.json();
    if (post.status === 200) {
      return result;
    }
    return {
      status: post.status,
      message: post.statusText,
    };
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

const deleteADPAccount = async (userInfo: AdpEmployer): Promise<any> => {
  try {
    const URL = ADP_USER_INFO;
    const put = await fetch(`${URL}/${userInfo.id}`, {
      method: 'DELETE',
      headers: createBookJaneRequestHeaders({}),
    });
    const result = await put.json();
    return result;
  } catch (error) {
    console.error('Error fetching  info:', error);
    throw error;
  }
};

export {
  fetchUserInfo,
  retryAdpSchedule,
  fetchADPLocations,
  fetchADPEmployeesInfo,
  fetchUploadHistory,
  deleteADPAccount,
  fetchLogDetails,
  syncTOADP,
  syncADPEmployees,
};
