import { fetchTimeOffQueryConfig } from 'components/TimeOffModal/contexts/data.constants';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext, useEffect, useMemo } from 'react';
import { timeOffModalFetchTimeOffById } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchTimeOffDataContext = createGQLDataContext({});

export const FetchTimeOffDataProvider: FC<{ timeOffId: string }> = ({ children, timeOffId }) => {
  const queryConfig = useMemo(() => fetchTimeOffQueryConfig(timeOffId), [timeOffId]);
  const res = useGQLQuery(queryConfig);

  useEffect(() => {
    res.fetch();
  }, []); // eslint-disable-line

  return (
    <FetchTimeOffDataContext.Provider value={res}>{children}</FetchTimeOffDataContext.Provider>
  );
};

export const useFetchTimeOffDataContext = (): UseGQLQuery<timeOffModalFetchTimeOffById> =>
  useContext(FetchTimeOffDataContext);
