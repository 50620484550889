import { CalenderTotalCount } from 'components/ShiftCalendar/CalenderTotalSummaryFooter/CalenderTotalSummaryFooter.types';
import { DateTime } from 'luxon';
import { OrderStatusEnum, shiftsCalendarPage } from 'types/graphql-types';

export const initCalenderTotalCount: CalenderTotalCount = {
  pendingCount: 0,
  acceptedCount: 0,
  assignedCount: 0,
  unfulfilledCount: 0,
  completedCount: 0,
  absentCount: 0,
};

export const aggregateShiftsCalenderPageData = (data: shiftsCalendarPage, monthYear: DateTime) => {
  const calenderTotalCountMap = data?.groupCountOrders.map((groupCountOrder) => {
    const res = JSON.parse(JSON.stringify(initCalenderTotalCount));

    if (DateTime.fromISO(groupCountOrder.group.fieldValue).hasSame(monthYear, 'month'))
      groupCountOrder.counted.forEach((count) => {
        count.subGroups.forEach((subGroup) => {
          switch (subGroup.fieldValue.toLowerCase()) {
            case OrderStatusEnum.accepted:
              res.acceptedCount += count.value;
              break;
            case 'open':
            case OrderStatusEnum.requested:
            case OrderStatusEnum.pending:
              res.pendingCount += count.value;
              break;
            case OrderStatusEnum.assigned:
              res.assignedCount += count.value;
              break;
            case OrderStatusEnum.unfulfilled:
              res.unfulfilledCount += count.value;
              break;
            case OrderStatusEnum.absent:
              res.absentCount += count.value;
              break;
            case OrderStatusEnum.completed:
              res.completedCount += count.value;
          }
        });
      });

    return res;
  }) || [JSON.parse(JSON.stringify(initCalenderTotalCount))];

  return calenderTotalCountMap.reduce(
    (accum, curr) => ({
      pendingCount: accum.pendingCount + curr.pendingCount,
      acceptedCount: accum.acceptedCount + curr.acceptedCount,
      assignedCount: accum.assignedCount + curr.assignedCount,
      unfulfilledCount: accum.unfulfilledCount + curr.unfulfilledCount,
      completedCount: accum.completedCount + curr.completedCount,
    }),
    JSON.parse(JSON.stringify(initCalenderTotalCount)),
  );
};
