import { useAssignToAgencyTeamMemberFormContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import { fetchAgencyJaneQueryConfig } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/context.constants';
import { useGQLQuery } from 'hooks';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { FC, useContext, useEffect } from 'react';
import {
  assignToAgencyTeamMemberFetchAgencyJaneById,
  assignToAgencyTeamMemberFetchAgencyJaneByIdVariables,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchAgencyTeamMemberByIdDataContext = createGQLDataContext<
  UseGQLQuery<assignToAgencyTeamMemberFetchAgencyJaneById>
>({} as UseGQLQuery<assignToAgencyTeamMemberFetchAgencyJaneById>);

export const FetchAgencyTeamMemberByIdDataProvider: FC = ({ children }) => {
  const { onChange, values } = useAssignToAgencyTeamMemberFormContext();
  const { fetch, ...rest } = useGQLQuery<
    assignToAgencyTeamMemberFetchAgencyJaneById,
    assignToAgencyTeamMemberFetchAgencyJaneById,
    assignToAgencyTeamMemberFetchAgencyJaneByIdVariables
  >(
    fetchAgencyJaneQueryConfig({
      janeId: values.janeId,
      onSuccess: (data: assignToAgencyTeamMemberFetchAgencyJaneById) =>
        onChange({ target: { name: 'janeRelayId', value: data.fetchJane.relayId } }),
    }),
  );

  useEffect(() => {
    if (!!values.janeId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FetchAgencyTeamMemberByIdDataContext.Provider value={rest}>
      {children}
    </FetchAgencyTeamMemberByIdDataContext.Provider>
  );
};

export const useFetchAgencyTeamMemberByIdDataContext =
  (): UseGQLQuery<assignToAgencyTeamMemberFetchAgencyJaneById> =>
    useContext(FetchAgencyTeamMemberByIdDataContext);
