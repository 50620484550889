import { QuerySwitch } from 'components/QuerySwitch';
import {
  ShiftModalDataContext,
  useShiftModalDataContext,
} from 'components/ShiftModal/ShiftModalProvider.data.context';
import { AuditTrailDetailTabView } from 'components/ShiftModal/src/views/DetailView/AuditTrailDetailView/AuditTrailDetailTabView';
import { DetailTabView } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView';
import { DetailTabViewPropsProvider } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.props.context';
import { IDetailViewComponentProps } from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isAuditTrailFeatureEnabledForCommunitySelector } from 'store/selectors/featureFlagSelectors';

export const DetailViewComponent: FC<IDetailViewComponentProps> = (values) => {
  const { data } = useShiftModalDataContext();

  const isAuditTrailEnabled = useSelector(
    isAuditTrailFeatureEnabledForCommunitySelector({
      communityId: data.fetchOrder.community.id,
      auditTrailVersion: data.fetchOrder.auditTrailVersion,
    }),
  );
  return isAuditTrailEnabled ? (
    <AuditTrailDetailTabView {...values} />
  ) : (
    <DetailTabView {...values} />
  );
};

export const DetailView: FC = () => {
  return (
    <DetailTabViewPropsProvider>
      <QuerySwitch component={DetailViewComponent} context={ShiftModalDataContext} />
    </DetailTabViewPropsProvider>
  );
};
