import { FC, Fragment, MouseEventHandler, useCallback, useContext, useState } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  HeaderWrapper,
  MainTitleContainer,
  ResetButtonLabel,
  ResetButtonWrapper,
  SearchTeamMembersWrapper,
  StyledJanesWrapper,
  BKJSideModalFooterContainer,
  BKJSideModalFooterContent,
  BKJSideModalFooterConfirmationButton,
  TotalBidPlacedText,
} from './AwardTeamMemberView.styled';
import { Flex } from 'components/Flex';
import {
  MainTitle,
  RequestedJanesBoldText,
  RequestedJanesText,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/CreateShiftModal.styled';
import { UserGuard } from 'guards/UserGuard';
import { useTranslation } from 'hooks/useTranslation';
import {
  BKJButtonIconTextInput,
  BKJTextInput,
  ErrorBoundary,
} from '@bookjane2/bookjane-design-library';
import { EmploymentStatusFilterInput } from 'components/EmploymentStatusFilterInput';
import { AwardTeamMemberFilterBar } from 'components/ShiftModal/src/views/AwardTeamMemberView/AwardTeamMemberFilterBar';
import {
  AwardTeamMemberContext,
  AwardTeamMemberProvider,
  useAwardTeamMemberContext,
} from 'components/ShiftModal/src/views/AwardTeamMemberView/AwardTeamMemberView.data.context';
import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { withMemo } from 'utils/withMemo';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { AwardJaneGridSelect } from 'components/AwardJaneGridSelect';
import {
  AwardTeamMemberViewFormProvider,
  useAwardTeamMemberViewFormContext,
} from 'components/ShiftModal/src/views/AwardTeamMemberView/AwardTeamMemberView.form.context';
import { getShiftBiddingStatus } from 'utils/shiftStatus';
import { CSVBorderHeaderStyle } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.styled';
const RenderSSFControls: FC = () => {
  const { t } = useTranslation();
  const { values, onChange, onReset } = useAwardTeamMemberContext();
  const [isOpen, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Flex flexDirection="column">
      <SearchTeamMembersWrapper>
        <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
          <BKJButtonIconTextInput
            placeholder={t('shift_modal:SEARCH_MEMBER')}
            buttonLabel={t('shift_modal:FILTERS')}
            name="byFullName"
            iconName="Search"
            buttonIcon="Filters"
            value={values?.byFullName}
            onChange={onChange}
            onButtonClick={handleClick}
            autoComplete="off"
          />
        </UserGuard>
        <UserGuard userType={['USER_TYPE_ESSENTIAL', 'USER_TYPE_ESSENTIAL_SECONDARY']}>
          <BKJTextInput
            placeholder={t('shift_modal:SEARCH_MEMBER')}
            name="byFullName"
            leftIconProps={{ iconName: 'Search' }}
            value={values?.byFullName}
            onChange={onChange}
            autoComplete="off"
          />
        </UserGuard>
      </SearchTeamMembersWrapper>

      <AwardTeamMemberFilterBar isOpen={isOpen}>
        <Flex justifyContent="space-between" width="100%" alignItems="baseline">
          <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
            <EmploymentStatusFilterInput
              name="byEmploymentStatus"
              onChange={onChange}
              value={values?.byEmploymentStatus}
              options={['casual', 'full_time', 'part_time', 'flex']}
            />
          </UserGuard>
          <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
            <ResetButtonWrapper onClick={onReset as MouseEventHandler<HTMLDivElement>}>
              <ResetButtonLabel>{t('shift_modal:RESET')}</ResetButtonLabel>
            </ResetButtonWrapper>
          </UserGuard>
        </Flex>
      </AwardTeamMemberFilterBar>
    </Flex>
  );
};
function RenderJanes() {
  const { handleChange, selectedJanes, loadMore } = useAwardTeamMemberViewFormContext();
  const { createShift } = useContext(CreateShiftCardContext);
  const {
    values: { quantity },
  } = createShift;
  return (
    <AwardJaneGridSelect
      paginationType="InfiniteScroll"
      type={parseInt(quantity[0]) > 1 ? 'Multiple' : 'Single'}
      max={parseInt(quantity[0])}
      name="request_janes"
      dataPath="fetchOrder.bidders"
      value={selectedJanes}
      context={AwardTeamMemberContext}
      onChange={handleChange}
      loadMore={loadMore}
      pageInfoDataPath="fetchOrders.pageInfo"
    />
  );
}
export const AwardTeamMemberView: FC = () => {
  const { data } = useAwardTeamMemberContext();
  const { selectedJanes, onSubmit } = useAwardTeamMemberViewFormContext();
  const biddersLength = data && data?.fetchOrder?.bidders?.length;
  const biddingStatus = getShiftBiddingStatus({
    status: data?.fetchOrder?.status.value,
    biddable: data?.fetchOrder?.biddable,
    biddingTimeEndsAt: data?.fetchOrder?.biddingTimeEndsAt,
    bidders: data?.fetchOrder?.bidders,
  });
  const isDisable = biddingStatus === 'AWARDING' && selectedJanes?.length !== 0 ? false : true;
  const { t } = useTranslation();
  return (
    <Fragment>
      <HeaderWrapper flexDirection="column" borderTop={CSVBorderHeaderStyle}>
        <MainTitleContainer justifyContent="space-between" alignItems="baseline">
          <MainTitle>{t('shift_modal:AWARD_TO_TEAM_MEMBER')}</MainTitle>
          <TotalBidPlacedText>
            {biddersLength} {t('shift_modal:PLACED_BID')}
          </TotalBidPlacedText>
        </MainTitleContainer>
        <RenderSSFControls />
      </HeaderWrapper>
      <StyledJanesWrapper width="100%" flex="1" padding="0px 24px 0px 32px">
        <RenderJanes />
      </StyledJanesWrapper>
      <BKJSideModalFooter>
        <BKJSideModalFooterContainer
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <BKJSideModalFooterContent flexDirection="column">
            <div>
              <RequestedJanesBoldText>{t('shift_modal:SELECTED')}:&nbsp;</RequestedJanesBoldText>
              <RequestedJanesText>{selectedJanes?.length}/1</RequestedJanesText>
            </div>
          </BKJSideModalFooterContent>
          <BKJSideModalFooterConfirmationButton
            variant={'PurpleSolid'}
            onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            disabled={isDisable}
          >
            {t('shift_modal:CONFIRM')}
          </BKJSideModalFooterConfirmationButton>
        </BKJSideModalFooterContainer>
      </BKJSideModalFooter>
    </Fragment>
  );
};
export const AwardTeamMemberViewRender: FC = withMemo((props) => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const Component = {
    ...ShiftModalComponentMap,
    AwardTeamMemberView,
  }[shiftModalActiveModalView];

  return (
    <ErrorBoundary
      componentName="AwardTeamMemberViewRender"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <AwardTeamMemberProvider>
        <AwardTeamMemberViewFormProvider>
          <Component {...props} />
        </AwardTeamMemberViewFormProvider>
      </AwardTeamMemberProvider>
    </ErrorBoundary>
  );
});
