/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IFieldSetWrapperProps,
  IFormWrapperProps,
  IStyledFieldSetWrapperProps,
  IStyledFormWrapperProps,
} from 'components/FormWrapper/FormWrapper.types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Styled = styled.form<IStyledFormWrapperProps<{}>>`
  display: flex;
  flex-direction: column;

  ${({ $borderTop }) => {
    if ($borderTop)
      return css`
        border-top: ${$borderTop};
      `;
    return css``;
  }};

  ${({ $borderBottom }) => {
    if ($borderBottom)
      return css`
        border-bottom: ${$borderBottom};
      `;
    return css``;
  }};

  ${({ $gap }) => {
    if ($gap)
      return css`
        gap: ${$gap};
      `;
    return css``;
  }};

  ${({ $margin }) => {
    if ($margin)
      return css`
        margin: ${$margin};
      `;
    return css``;
  }};
  ${({ $padding }) => {
    if ($padding)
      return css`
        padding: ${$padding};
      `;
    return css``;
  }};
  ${({ $flexDirection }) => {
    if ($flexDirection)
      return css`
        flex-direction: ${$flexDirection};
      `;
    return css``;
  }};
  ${({ $alignContent }) => {
    if ($alignContent)
      return css`
        align-content: ${$alignContent};
      `;
    return css``;
  }};
  ${({ $alignSelf }) => {
    if ($alignSelf)
      return css`
        align-self: ${$alignSelf};
      `;
    return css``;
  }};
  ${({ $alignItems }) => {
    if ($alignItems)
      return css`
        align-items: ${$alignItems};
      `;
    return css``;
  }};
  ${({ $justifyContent }) => {
    if ($justifyContent)
      return css`
        justify-content: ${$justifyContent};
      `;
    return css``;
  }};
  ${({ $justifySelf }) => {
    if ($justifySelf)
      return css`
        justify-self: ${$justifySelf};
      `;
    return css``;
  }};
  ${({ $justifyItems }) => {
    if ($justifyItems)
      return css`
        justify-items: ${$justifyItems};
      `;
    return css``;
  }};
  ${({ $width }) => {
    if ($width)
      return css`
        width: ${$width};
      `;
    return css``;
  }};
  ${({ $minWidth }) => {
    if ($minWidth)
      return css`
        min-width: ${$minWidth};
      `;
    return css``;
  }};
  ${({ $maxWidth }) => {
    if ($maxWidth)
      return css`
        max-width: ${$maxWidth};
      `;
    return css``;
  }};
  ${({ $height }) => {
    if ($height)
      return css`
        height: ${$height};
      `;
    return css``;
  }};
  ${({ $minHeight }) => {
    if ($minHeight)
      return css`
        min-height: ${$minHeight};
      `;
    return css``;
  }};
  ${({ $maxHeight }) => {
    if ($maxHeight)
      return css`
        max-height: ${$maxHeight};
      `;
    return css``;
  }};
  ${({ $flex }) => {
    if ($flex)
      return css`
        flex: ${$flex};
      `;
    return css``;
  }};
  ${({ $flexWrap }) => {
    if ($flexWrap)
      return css`
        flex-wrap: ${$flexWrap};
      `;
    return css``;
  }};
  ${({ $flexBasis }) => {
    if ($flexBasis)
      return css`
        flex-basis: ${$flexBasis};
      `;
    return css``;
  }};
  ${({ $flexShrink }) => {
    if ($flexShrink)
      return css`
        flex-shrink: ${$flexShrink};
      `;
    return css``;
  }};
  ${({ $flexGrow }) => {
    if ($flexGrow)
      return css`
        flex-grow: ${$flexGrow};
      `;
    return css``;
  }};

  ${({ $order }) => {
    if ($order)
      return css`
        order: ${$order};
      `;
    return css``;
  }};

  ${({ $childMarginRight }) => {
    if ($childMarginRight)
      return css`
        > * {
          margin-right: ${$childMarginRight};
          &:last-child {
            margin-right: 0;
          }
        }
      `;
    return css``;
  }};

  ${({ $childMarginLeft }) => {
    if ($childMarginLeft)
      return css`
        > * {
          margin-left: ${$childMarginLeft};
          &:last-child {
            margin-left: 0;
          }
        }
      `;
    return css``;
  }};

  ${({ $childMarginTop }) => {
    if ($childMarginTop)
      return css`
        > * {
          margin-top: ${$childMarginTop};
          &:last-child {
            margin-top: 0;
          }
        }
      `;
    return css``;
  }};
  ${({ $childMarginBottom }) => {
    if ($childMarginBottom)
      return css`
        > * {
          margin-bottom: ${$childMarginBottom};
          &:last-child {
            margin-bottom: 0;
          }
        }
      `;
    return css``;
  }}; ;
`;

export const BKJForm = forwardRef<HTMLFormElement, IFormWrapperProps<any>>((props, ref) => {
  const {
    children,
    flexDirection,
    alignContent,
    alignSelf,
    alignItems,
    justifyContent,
    justifySelf,
    justifyItems,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    margin,
    padding,
    flex,
    flexWrap,
    flexBasis,
    flexShrink,
    flexGrow,
    order,
    childMarginRight,
    childMarginLeft,
    childMarginTop,
    childMarginBottom,
    gap,
    borderTop,
    borderBottom,
    ...rest
  } = props;

  return (
    <Styled
      $childMarginRight={childMarginRight}
      $childMarginLeft={childMarginLeft}
      $childMarginTop={childMarginTop}
      $childMarginBottom={childMarginBottom}
      $flexDirection={flexDirection}
      $alignContent={alignContent}
      $alignSelf={alignSelf}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $justifySelf={justifySelf}
      $justifyItems={justifyItems}
      $width={width}
      $margin={margin}
      $padding={padding}
      $gap={gap}
      $borderTop={borderTop}
      $borderBottom={borderBottom}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $height={height}
      $minHeight={minHeight}
      $maxHeight={maxHeight}
      $flex={flex}
      $flexWrap={flexWrap}
      $flexBasis={flexBasis}
      $flexShrink={flexShrink}
      $flexGrow={flexGrow}
      $order={order}
      {...rest}
      ref={ref}
    >
      {children}
    </Styled>
  );
});

const StyledFieldSet = styled.fieldset<IStyledFieldSetWrapperProps<{}>>`
  display: flex;
  flex-direction: column;

  ${({ $borderTop }) => {
    if ($borderTop)
      return css`
        border-top: ${$borderTop};
      `;
    return css``;
  }};

  ${({ $borderBottom }) => {
    if ($borderBottom)
      return css`
        border-bottom: ${$borderBottom};
      `;
    return css``;
  }};

  ${({ $gap }) => {
    if ($gap)
      return css`
        gap: ${$gap};
      `;
    return css``;
  }};

  ${({ $margin }) => {
    if ($margin)
      return css`
        margin: ${$margin};
      `;
    return css``;
  }};
  ${({ $padding }) => {
    if ($padding)
      return css`
        padding: ${$padding};
      `;
    return css``;
  }};
  ${({ $flexDirection }) => {
    if ($flexDirection)
      return css`
        flex-direction: ${$flexDirection};
      `;
    return css``;
  }};
  ${({ $alignContent }) => {
    if ($alignContent)
      return css`
        align-content: ${$alignContent};
      `;
    return css``;
  }};
  ${({ $alignSelf }) => {
    if ($alignSelf)
      return css`
        align-self: ${$alignSelf};
      `;
    return css``;
  }};
  ${({ $alignItems }) => {
    if ($alignItems)
      return css`
        align-items: ${$alignItems};
      `;
    return css``;
  }};
  ${({ $justifyContent }) => {
    if ($justifyContent)
      return css`
        justify-content: ${$justifyContent};
      `;
    return css``;
  }};
  ${({ $justifySelf }) => {
    if ($justifySelf)
      return css`
        justify-self: ${$justifySelf};
      `;
    return css``;
  }};
  ${({ $justifyItems }) => {
    if ($justifyItems)
      return css`
        justify-items: ${$justifyItems};
      `;
    return css``;
  }};
  ${({ $width }) => {
    if ($width)
      return css`
        width: ${$width};
      `;
    return css``;
  }};
  ${({ $minWidth }) => {
    if ($minWidth)
      return css`
        min-width: ${$minWidth};
      `;
    return css``;
  }};
  ${({ $maxWidth }) => {
    if ($maxWidth)
      return css`
        max-width: ${$maxWidth};
      `;
    return css``;
  }};
  ${({ $height }) => {
    if ($height)
      return css`
        height: ${$height};
      `;
    return css``;
  }};
  ${({ $minHeight }) => {
    if ($minHeight)
      return css`
        min-height: ${$minHeight};
      `;
    return css``;
  }};
  ${({ $maxHeight }) => {
    if ($maxHeight)
      return css`
        max-height: ${$maxHeight};
      `;
    return css``;
  }};
  ${({ $flex }) => {
    if ($flex)
      return css`
        flex: ${$flex};
      `;
    return css``;
  }};
  ${({ $flexWrap }) => {
    if ($flexWrap)
      return css`
        flex-wrap: ${$flexWrap};
      `;
    return css``;
  }};
  ${({ $flexBasis }) => {
    if ($flexBasis)
      return css`
        flex-basis: ${$flexBasis};
      `;
    return css``;
  }};
  ${({ $flexShrink }) => {
    if ($flexShrink)
      return css`
        flex-shrink: ${$flexShrink};
      `;
    return css``;
  }};
  ${({ $flexGrow }) => {
    if ($flexGrow)
      return css`
        flex-grow: ${$flexGrow};
      `;
    return css``;
  }};

  ${({ $order }) => {
    if ($order)
      return css`
        order: ${$order};
      `;
    return css``;
  }};

  ${({ $childMarginRight }) => {
    if ($childMarginRight)
      return css`
        > * {
          margin-right: ${$childMarginRight};
          &:last-child {
            margin-right: 0;
          }
        }
      `;
    return css``;
  }};

  ${({ $childMarginLeft }) => {
    if ($childMarginLeft)
      return css`
        > * {
          margin-left: ${$childMarginLeft};
          &:last-child {
            margin-left: 0;
          }
        }
      `;
    return css``;
  }};

  ${({ $childMarginTop }) => {
    if ($childMarginTop)
      return css`
        > * {
          margin-top: ${$childMarginTop};
          &:last-child {
            margin-top: 0;
          }
        }
      `;
    return css``;
  }};
  ${({ $childMarginBottom }) => {
    if ($childMarginBottom)
      return css`
        > * {
          margin-bottom: ${$childMarginBottom};
          &:last-child {
            margin-bottom: 0;
          }
        }
      `;
    return css``;
  }}; ;
`;

export const BKJFieldSet = forwardRef<HTMLFieldSetElement, IFieldSetWrapperProps<any>>(
  (props, ref) => {
    const {
      children,
      flexDirection,
      alignContent,
      alignSelf,
      alignItems,
      justifyContent,
      justifySelf,
      justifyItems,
      width,
      minWidth,
      maxWidth,
      height,
      minHeight,
      maxHeight,
      margin,
      padding,
      flex,
      flexWrap,
      flexBasis,
      flexShrink,
      flexGrow,
      order,
      childMarginRight,
      childMarginLeft,
      childMarginTop,
      childMarginBottom,
      gap,
      borderTop,
      borderBottom,
      ...rest
    } = props;

    return (
      <StyledFieldSet
        $childMarginRight={childMarginRight}
        $childMarginLeft={childMarginLeft}
        $childMarginTop={childMarginTop}
        $childMarginBottom={childMarginBottom}
        $flexDirection={flexDirection}
        $alignContent={alignContent}
        $alignSelf={alignSelf}
        $alignItems={alignItems}
        $justifyContent={justifyContent}
        $justifySelf={justifySelf}
        $justifyItems={justifyItems}
        $width={width}
        $margin={margin}
        $padding={padding}
        $gap={gap}
        $borderTop={borderTop}
        $borderBottom={borderBottom}
        $minWidth={minWidth}
        $maxWidth={maxWidth}
        $height={height}
        $minHeight={minHeight}
        $maxHeight={maxHeight}
        $flex={flex}
        $flexWrap={flexWrap}
        $flexBasis={flexBasis}
        $flexShrink={flexShrink}
        $flexGrow={flexGrow}
        $order={order}
        {...rest}
        ref={ref}
      >
        {children}
      </StyledFieldSet>
    );
  },
);
export const BKJFieldErrorMessage = styled.pre`
  ${({ theme }) => theme.fonts.PoppinsText};
  margin-top: 4px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.ErrorDefault};
`;
