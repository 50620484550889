import { GROUPS } from 'constants/endpoints';
import { METHOD_PATCH } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_PATCH_updateDynamicGroupType } from 'requests/PATCH_updateDynamicGroup.types';

export const API_PATCH_updateDynamicGroup = ({
  name,
  groupId,
  employment_statuses,
  position_types,
  minimum_per_day_minutes,
  maximum_per_day_minutes,
  minimum_per_period_minutes,
  maximum_per_period_minutes,
}: API_PATCH_updateDynamicGroupType): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: `${GROUPS}/${groupId}`,
    method: METHOD_PATCH,
    body: {
      group: {
        name,
        type: 'DynamicGroup',
        dynamic_group_criteria_attributes: {
          employment_statuses,
          position_types,
          minimum_per_day_minutes,
          maximum_per_day_minutes,
          minimum_per_period_minutes,
          maximum_per_period_minutes,
        },
      },
    },
  };
};
