import { FC, createContext, useContext, useEffect, useState } from 'react';
import { ResponsiveBreakPoints } from 'providers/ResponsiveProvider/BKJThemeBreakpoints';

interface IDimensions {
  appWidth: number;
  appHeight: number;
  innerHeight: number;
  innerWidth: number;
}

interface IDevices {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

type ResponsiveType = IDimensions & IDevices;
const Context = createContext({} as IDimensions & IDevices);

export const ResponsiveProvider: FC = ({ children }) => {
  const deviceSizes = ResponsiveBreakPoints;
  const [dimensions, setDimensions] = useState<IDimensions>(() => {
    let appWidth;
    let appHeight;
    let innerHeight;
    let innerWidth;

    if (typeof window !== 'undefined') {
      appWidth = document.body.clientWidth;
      appHeight = document.body.clientHeight;
      innerHeight = window.innerHeight;
      innerWidth = window.innerWidth;
    } else {
      appWidth = 0;
      appHeight = 0;
      innerHeight = 0;
      innerWidth = 0;
    }

    return {
      appWidth,
      appHeight,
      innerHeight,
      innerWidth,
    };
  });

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setDimensions({
        appWidth: document.body.clientWidth,
        appHeight: document.body.clientHeight,
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const { appWidth } = dimensions;

  return (
    <Context.Provider
      value={{
        ...dimensions,
        isDesktop: appWidth > deviceSizes.Desktop,
        isTablet: appWidth <= deviceSizes.Tablet,
        isMobile: appWidth <= deviceSizes.Mobile,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const withResponsive = (Comp: FC) => (props: React.PropsWithChildren<unknown>) =>
  (
    <Context.Consumer>
      {(responsive: ResponsiveType) => <Comp {...{ ...responsive, ...props }} />}
    </Context.Consumer>
  );

export const useResponsive = () => useContext(Context);
