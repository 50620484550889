import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getAuth = (state: IGlobalReduxStore) => state.auth;

export const isAllFacilitiesFilterFeatureEnabledSelector = createSelector([getAuth], (auth) => {
  if (
    auth?.permissions &&
    auth?.permissions?.ALL_FACILITIES_FILTER &&
    auth?.permissions?.ALL_FACILITIES_FILTER?.access.includes('read')
  ) {
    return true;
  }

  return false;
});
