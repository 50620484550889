import { IBKJTheme } from '@bookjane2/bookjane-design-library/lib/providers';
import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 145px 275px;
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;
`;

export const TableHeader = styled.div`
  min-height: 50px;
  display: flex;
  grid-column: repeat(4, 1fr);
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  padding: 0 16px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const GreyText = styled.span`
  color: ${({ theme }) => theme.colors.Grey5};
  text-decoration-color: ${({ theme }) => theme.colors.Grey5};
  fill: ${({ theme }) => theme.colors.Grey5};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
`;

export const TableCell = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  grid-column: repeat(4, 1fr);
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  padding: 0 16px;
  ${({ theme }) => theme.fonts.PoppinsText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
  &:nth-child(8n + 1),
  &:nth-child(8n + 2),
  &:nth-child(8n + 3),
  &:nth-child(8n + 4) {
    background-color: ${({ theme }) => theme.colors.Grey1};
  }
`;

export const TableCellCentered = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-column: repeat(4, 1fr);
  font-size: 14px;
  line-height: 24px;
  ${({ theme }) => theme.fonts.PoppinsText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
  &:nth-child(8n + 1),
  &:nth-child(8n + 2),
  &:nth-child(8n + 3),
  &:nth-child(8n + 4) {
    background-color: ${({ theme }) => theme.colors.Grey1};
  }
`;

export const ApplyFacilitiesTable = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 400px 155px;
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;
  height: 100%;
  max-height: calc(100% - 80px);
  overflow-y: overlay;
`;

export const ApplyFacilitiesTableHeader = styled.div`
  position: sticky;
  z-index: 1;
  top: 0px;
  min-height: 50px;
  display: flex;
  align-items: center;
  grid-column: repeat(2, 1fr);
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  padding: 0 32px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
  background-color: white;
`;

interface IApplyFacilitiesTableCellProps {
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  cursor?: 'pointer' | 'default';
}

export const ApplyFacilitiesTableCell = styled.div<IApplyFacilitiesTableCellProps>`
  min-height: 50px;
  display: flex;
  align-items: center;
  grid-column: repeat(2, 1fr);
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  padding: 0 32px;
  ${({ theme }) => theme.fonts.PoppinsText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};
  background-color: white;

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `};
`;

export const ApplyFacilitiesHeader = styled(Flex)`
  position: sticky;
  height: 168px;
  width: 100%;
  min-height: 168px;
  top: 0;
  max-height: 168px;
  grid-column: 1 / span 2;
  z-index: 1;
  background-color: white;
`;

export const ApplyFacilitiesNoResults = styled(Flex)`
  height: 100%;
  width: 100%;
  grid-column: 1 / span 2;
  padding: 32px 0;
  background-color: white;
`;

export const ReadViewTableWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;
`;

export const ReadViewTableCell = styled.div<{
  topBorder?: boolean;
  bgColor?: keyof IBKJTheme['colors'];
}>`
  min-height: 50px;
  display: flex;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  padding: 0 16px;
  ${({ theme }) => theme.fonts.PoppinsText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey5};

  ${({ bgColor, theme }) =>
    bgColor &&
    css`
      background-color: ${theme.colors[bgColor]};
    `};
  ${({ topBorder, theme }) =>
    topBorder &&
    css`
      border-top: 1px solid ${theme.colors.Grey5};
    `};
`;
