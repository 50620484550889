import { FC, useContext, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useSSFBehaviors } from 'hooks';
import { GQLAPIContextType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useLocation } from 'react-router-dom';
import { shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes } from 'types/graphql-types';
import { QUERY_shiftDistributionRulesGroups } from 'queries';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';

const viewDetailsApolloClient = createApolloClient({ cache: createApolloCache() });

export const ViewDetailsDataContext =
  createGQLDataContext<
    GQLAPIContextType<shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes> & {
      isLoading: boolean;
    }
  >();

const getViewDetailsConfig = (
  locationId: string,
  positionSettingsId?: string,
): ISSFBehaviorsOptions =>
  !!positionSettingsId
    ? {
        key: 'SHIFT_DISTRIBUTION_RULES_GROUPS_VIEW_DETAILS_QUERY',
        query: QUERY_shiftDistributionRulesGroups,
        paginationType: 'PagedPagination',
        pageSize: 5,
        client: viewDetailsApolloClient,
        schema: {
          locationId: {
            initialValue: Number.parseInt(locationId),
            isSessionCached: true,
          },
        },
        payloadTransformer: (values) => ({
          ...values,
          filter: {
            forCommunities: {
              withId: [Number.parseInt(locationId)],
            },
            forPositions: { byId: [Number.parseInt(positionSettingsId)] },
          },
        }),
      }
    : {
        key: 'SHIFT_DISTRIBUTION_RULES_GROUPS_VIEW_DETAILS_DEFAULT_QUERY',
        query: QUERY_shiftDistributionRulesGroups,
        paginationType: 'PagedPagination',
        pageSize: 5,
        client: viewDetailsApolloClient,
        schema: {
          locationId: {
            initialValue: Number.parseInt(locationId),
            isSessionCached: true,
          },
        },
        payloadTransformer: (values) => ({
          ...values,
          filter: {
            forCommunities: {
              withId: [Number.parseInt(locationId)],
            },
            defaultRules: true,
          },
        }),
      };

export const ViewDetailsDataProvider: FC<{ positionSettingsId?: string }> = ({
  positionSettingsId,
  children,
}) => {
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const config = useMemo(
    () => getViewDetailsConfig(locationId, positionSettingsId),
    [locationId, positionSettingsId],
  );
  const [values, useQueryOption] = useSSFBehaviors(config);

  const contextValues = useMemo(() => {
    return {
      values,
      ...useQueryOption,
      isLoading: useQueryOption.isLoading,
      data:
        useQueryOption?.data &&
        useQueryOption?.data?.fetchShiftDistributionRules?.nodes &&
        useQueryOption?.data?.fetchShiftDistributionRules?.nodes.length > 0
          ? useQueryOption?.data?.fetchShiftDistributionRules?.nodes[0]
          : {},
    };
  }, [values, useQueryOption]);

  return (
    <ViewDetailsDataContext.Provider value={contextValues}>
      {children}
    </ViewDetailsDataContext.Provider>
  );
};

export const useViewDetailsDataContext = () =>
  useContext(
    ViewDetailsDataContext,
  ) as GQLAPIContextType<shiftDistributionRulesGroups_fetchShiftDistributionRules_nodes> & {
    isLoading: boolean;
  };
