import { BKJListItem } from '@bookjane2/bookjane-design-library';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { FetchTimeOffDataProvider } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import {
  TimeOffModalComponentMap,
  titleOptions,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  TimeOffModalRoutingProvider,
  useTimeOffModalRoutingContext,
} from 'components/TimeOffModal/TimeOffModal.routing.context';
import { TimeOffModalTemplate } from 'components/TimeOffModal/TimeOffModalTemplate';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withMemo } from 'utils/withMemo';

const ViewComponent = () => {
  const { timeOffModalActiveModalView } = useTimeOffModalRoutingContext();
  const Component = TimeOffModalComponentMap[timeOffModalActiveModalView];
  return Component ? <Component /> : <Fragment />;
};

const TimeOffModalComponent = () => {
  const { timeOffId } = useTimeOffModalRoutingContext();
  return !!timeOffId ? (
    <FetchTimeOffDataProvider timeOffId={timeOffId}>
      <TimeOffModalTemplate>
        <ViewComponent />
      </TimeOffModalTemplate>
    </FetchTimeOffDataProvider>
  ) : (
    <TimeOffModalTemplate>
      <ViewComponent />
    </TimeOffModalTemplate>
  );
};

export const TimeOffModal = () => {
  return (
    <TimeOffModalRoutingProvider>
      <TimeOffModalComponent />
    </TimeOffModalRoutingProvider>
  );
};

export const RenderOptions: FC<IBKJSelectRenderOptionsProps> = withMemo(function _RenderOptions(
  props,
) {
  const { t } = useTranslation();
  const translatedOptions = titleOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }));
  return (
    <Fragment>
      {translatedOptions.map((option) => {
        const { type, ...restProps } = props;
        return <BKJListItem {...restProps} {...option} />;
      })}
    </Fragment>
  );
});
