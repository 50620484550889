import {
  ILocationVariables,
  LocationOptionType,
} from 'components/LocationFilterInput/LocationFilterInput.types';
import { useQueryForm } from 'hooks/useQueryForm';
import { IQueryFormResult, IQueryOptions } from 'hooks/useQueryForm/useQueryForm.types';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { authLink, createApolloClient, httpLink } from 'providers/ApolloProvider';
import { QUERY_fetchAgencyCommunities, QUERY_fetchCommunitiesPaginated } from 'queries';
import { FC, useContext, useMemo } from 'react';
import { useUserAgencyIdSelector } from 'store/selectors/userSelectors/userSelector';
import { fetchAgencyCommunities, fetchCommunitiesPaginated } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const LocationFilterDataContext =
  createGQLDataContext<IQueryFormResult<LocationOptionType[], ILocationVariables>>();

const client = createApolloClient();

export const LocationFilterDataProvider: FC = ({ children }) => {
  const agencyId = useUserAgencyIdSelector();

  client.setLink(authLink.concat(httpLink()));

  const result = useQueryForm(
    useUserTypeSwitch<
      IQueryOptions<
        fetchCommunitiesPaginated | fetchAgencyCommunities,
        ILocationVariables,
        LocationOptionType[]
      >
    >({
      Agency: {
        key: 'LOCATION_FILTER_INPUT_AGENCY',
        query: QUERY_fetchAgencyCommunities,
        pageSize: 10,
        client,
        responseTransformer: (data?: fetchAgencyCommunities) => {
          if (!data) return [];

          const mapped: LocationOptionType[] = data?.fetchAgency?.agencyCommunities
            ? data?.fetchAgency?.agencyCommunities.map((community) => {
                const stringified = community!.id.toString();
                return {
                  latitude: community.location?.latitude,
                  longitude: community.location?.longitude,
                  name: 'location',
                  label: community!.name,
                  key: stringified,
                  value: stringified,
                  id: stringified,
                  type: 'Standard',
                  timeZone: community.location?.timeZone || undefined,
                  country: community.location?.country || undefined,
                };
              })
            : [];
          return mapped;
        },
      },
      B2B: {
        key: 'LOCATION_FILTER_INPUT_B2B',
        query: QUERY_fetchCommunitiesPaginated,
        pageSize: 10,
        client,
        responseTransformer: (data?: fetchCommunitiesPaginated) => {
          if (!data) return [];
          const mapped: LocationOptionType[] = data?.fetchCommunities?.nodes
            ? data.fetchCommunities.nodes.map(({ id, name, location }) => {
                const stringified = id.toString();
                return {
                  name: 'location',
                  label: name,
                  key: stringified,
                  value: stringified,
                  id: stringified,
                  type: 'Standard',
                  timeZone: location?.timeZone || undefined,
                  country: location?.country || undefined,
                };
              })
            : [];

          return mapped;
        },
      },
    }),
    useUserTypeSwitch({
      B2B: {
        byName: '',
      },
      Agency: {
        id: agencyId,
        byName: '',
      },
    }),
  );

  const value = useMemo(() => {
    return result;
  }, [result]);
  return (
    <LocationFilterDataContext.Provider value={value}>
      {children}
    </LocationFilterDataContext.Provider>
  );
};

export const useLocationFilterDataContext = (): IQueryFormResult<
  LocationOptionType[],
  ILocationVariables
> => useContext(LocationFilterDataContext);
