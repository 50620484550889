import { FC } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ReasonForEdits } from 'components/ShiftModal/src/views/ReasonForEdits';
import { OrderStatusEnum } from 'types/graphql-types';
import { NotifyTeamMember } from 'components/ShiftModal/src/views/NotifyTeamMember';
import { BKJButton, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useEditViewContext } from 'components/ShiftModal/src/views/EditView/EditView.context';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';
import { useTranslation } from 'hooks/useTranslation';

export const EditingReasonAndNotifyView: FC = () => {
  const { t } = useTranslation();
  const {
    data: {
      fetchOrder: { status, mainJane, biddable },
    },
  } = useShiftModalDataContext();
  const { isSubmitting, values, isNotifyFlowRequired } = useEditViewContext();

  const { goToConfirmEditsView } = useShiftModalPropsContext();

  const isInProgressOrCompletedShift =
    status.value === OrderStatusEnum.in_progress || status.value === OrderStatusEnum.completed;

  const isShowNotifyFlow =
    isNotifyFlowRequired &&
    !(isInProgressOrCompletedShift && mainJane && mainJane.acceptedByAgency && !biddable) &&
    !(isInProgressOrCompletedShift && mainJane && mainJane.acceptedByAgency);
  const isReasonRequired =
    values?.reasonForEdit?.[0] === 'other'
      ? values?.reasonForEdit?.[0] && values?.specifiedReasonForEdit?.[0]
      : values?.reasonForEdit?.[0];

  const isDisabled = isInProgressOrCompletedShift
    ? !(
        isReasonRequired &&
        (mainJane && !mainJane.acceptedByAgency && isNotifyFlowRequired
          ? values.notification_method
          : true)
      )
    : !values.notification_method;

  return (
    <ErrorBoundary
      componentName="EditingReasonAndNotifyView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      {isInProgressOrCompletedShift && <ReasonForEdits />}
      {isShowNotifyFlow && <NotifyTeamMember />}
      <BKJSideModalFooter justifyContent="space-between">
        <AutoMargin />
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          type="button"
          disabled={isDisabled}
          isLoading={isSubmitting}
          onClick={goToConfirmEditsView}
        >
          {t('auth:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </ErrorBoundary>
  );
};
