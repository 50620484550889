import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_fetchOrderCanAssign } from 'queries';
import { fetchOrderCanAssign, fetchOrderCanAssignVariables } from 'types/graphql-types';

export const getConfirmAvailabilityQueryConfig = ({
  orderId,
  onSuccess,
}: {
  orderId: number;
  onSuccess: (data: fetchOrderCanAssign) => void;
}): UseGQLQueryConfig<fetchOrderCanAssign, fetchOrderCanAssign, fetchOrderCanAssignVariables> => ({
  key: 'CONFIRM_AVAILABILITY_CAN_ASSIGN_VALIDATION_QUERY',
  query: QUERY_fetchOrderCanAssign,
  onSuccess,
  variables: {
    orderId,
  },
});
