import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { useFetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import {
  TIME_OFF_MODAL_DETAILS_GENERAL_TAB_1,
  TIME_OFF_MODAL_DETAILS_GENERAL_TAB_2,
} from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffViewGeneralTab/DetailTimeOffViewGeneralTab.constants';
import {
  DetailTimeOffViewAdditionalDetailNameWrapper,
  DetailTimeOffViewAdditionalDetailsWrapper,
  DetailTimeOffViewAdditionalDetailWrapper,
  DetailTimeOffViewGeneralTabWrapper,
  DetailTimeOffViewNoAdditionalDetailsWrapper,
} from 'components/TimeOffModal/views/DetailTimeOffView/DetailTimeOffViewGeneralTab/DetailTimeOffViewGeneralTab.styled';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';

const DetailTimeOffViewNoAdditionalDetails: FC = () => {
  const { t } = useTranslation();
  return (
    <DetailTimeOffViewNoAdditionalDetailsWrapper>
      <div>{t(TIME_OFF_MODAL_DETAILS_GENERAL_TAB_1)}</div>
      <div>{t(TIME_OFF_MODAL_DETAILS_GENERAL_TAB_2)}</div>
    </DetailTimeOffViewNoAdditionalDetailsWrapper>
  );
};

const DetailTimeOffViewAdditionalDetails: FC = () => {
  const {
    data: {
      fetchTimeOff: { notes, customFields },
    },
  } = useFetchTimeOffDataContext();

  const { t } = useTranslation();

  return (
    <DetailTimeOffViewAdditionalDetailsWrapper>
      {!!notes && (
        <Fragment>
          <DetailTimeOffViewAdditionalDetailNameWrapper>
            {t('schedule:NOTES')}
          </DetailTimeOffViewAdditionalDetailNameWrapper>
          <DetailTimeOffViewAdditionalDetailWrapper>
            {notes}
          </DetailTimeOffViewAdditionalDetailWrapper>
        </Fragment>
      )}
      {!!notes && !!customFields.length && (
        <SectionDivider variant="Thin" margin={`32px 0 32px 0`} />
      )}
      {!!customFields.length && (
        <Fragment>
          {customFields.map(({ name, value }) => (
            <Fragment>
              <DetailTimeOffViewAdditionalDetailNameWrapper>
                {name}
              </DetailTimeOffViewAdditionalDetailNameWrapper>
              <DetailTimeOffViewAdditionalDetailWrapper>
                {!!value ? value : '-'}
              </DetailTimeOffViewAdditionalDetailWrapper>
            </Fragment>
          ))}
        </Fragment>
      )}
    </DetailTimeOffViewAdditionalDetailsWrapper>
  );
};

export const DetailTimeOffViewGeneralTab: FC = () => {
  const {
    data: {
      fetchTimeOff: { notes, customFields },
    },
  } = useFetchTimeOffDataContext();

  return (
    <DetailTimeOffViewGeneralTabWrapper>
      {notes === '' && customFields.length === 0 ? (
        <DetailTimeOffViewNoAdditionalDetails />
      ) : (
        <DetailTimeOffViewAdditionalDetails />
      )}
    </DetailTimeOffViewGeneralTabWrapper>
  );
};
