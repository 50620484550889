import { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { customFieldFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/customFieldFeatureEnabledSelector';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchCommunitiesCountDataContext = createGQLDataContext(0);

export const FetchCommunitiesCountDataProvider: FC = ({ children }) => {
  const _communityPermissions = useSelector(customFieldFeatureEnabledSelector);
  const communityPermissions = useMemo(() => _communityPermissions, [_communityPermissions]);

  return (
    <FetchCommunitiesCountDataContext.Provider value={communityPermissions.length}>
      {children}
    </FetchCommunitiesCountDataContext.Provider>
  );
};

export const useFetchCommunitiesCountDataContext = (): Number =>
  useContext(FetchCommunitiesCountDataContext);
