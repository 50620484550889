import { AutoRebidDialogProps } from 'components/AutoRebidDialog/AutoRebidDialog.types';
import {
  AutoRebidDialogFormProvider,
  useAutoRebidDialogFormContext,
} from 'components/AutoRebidDialog/AutoRebidDialog.form.context';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AutoRebidDialogView: FC<AutoRebidDialogProps> = ({
  isAutoRebidConfirmationOpen,
  setIsAutoRebidConfirmationOpen,
}) => {
  const { t } = useTranslation();
  const { handleAutoRebid } = useAutoRebidDialogFormContext();
  return (
    <BKJCenteredDialog
      isOpen={isAutoRebidConfirmationOpen}
      variant="Default"
      title={t('dialog:EXTEND_BID_CONFIRMATION')}
      text={t('dialog:EXTEND_BID_APPROVAL_MESSAGE')}
      cancelLabel={t('auth:CANCEL')}
      actionLabel={t('auth:CONFIRM')}
      onAction={() => {
        setIsAutoRebidConfirmationOpen(false);
        return handleAutoRebid();
      }}
      onCancel={() => setIsAutoRebidConfirmationOpen(false)}
      onClose={() => setIsAutoRebidConfirmationOpen(false)}
    />
  );
};

export const AutoRebidDialog: FC<AutoRebidDialogProps> = (props) => {
  return (
    <AutoRebidDialogFormProvider {...props}>
      <AutoRebidDialogView {...props} />
    </AutoRebidDialogFormProvider>
  );
};
