import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { IGlobalReduxStore } from 'store';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

export const isUserAccessStatusPendingSelector = createCachedSelector(getUser, (user) => {
  switch (user?.access_status) {
    case 'not_approved':
      return true;
    case 'approved':
    default:
      return false;
  }
})((store) => {
  if (!store.auth.user) return 'NO_SESSION';

  return store.auth.user.access_status;
});

export const useIsUserAccessStatusPendingSelector = () =>
  useSelector(isUserAccessStatusPendingSelector);
