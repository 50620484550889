import {
  RateShiftView,
  DetailView,
  DeleteView,
  ReleaseShiftView,
  EditView,
  NotifyTeamMember,
  AuditTrailView,
  AwardTeamMemberView,
  AwardUnavailableView,
} from 'components/ShiftModal/src/views';
import { PreRequestOptionsView } from 'components/ShiftModal/src/views/SendToAgenciesView/PreRequestOptionsView';
import { SendToAgenciesSummaryView } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView';
import { PostRequestOptionsView } from 'components/ShiftModal/src/views/SendToAgenciesView/PostRequestOptionsView';
import { QUERY_shiftModalOrderDetails } from 'queries';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { shiftModalOrderDetails } from 'types/graphql-types';
import { AssignToAgencyTeamMemberSummaryView } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/AssignToAgencyTeamMemberSummaryView';
import { SelectAgencyTeamMemberView } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/SelectAgencyTeamMemberView';
import { TeamMemberUnavailableView } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/AssignToAgencyTeamMemberSummaryView/TeamMemberUnavailableView.component';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';
import { SelectAgenciesView } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/SelectAgenciesView';
import { ShiftUnavailableView } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/SelectAgencyTeamMemberView/ShiftUnavailableView.component';
import { RequestTeamMemberView } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberView';
import { ReasonForEdits } from 'components/ShiftModal/src/views/ReasonForEdits/ReasonForEdits';
import { ConfirmEditsView } from 'components/ShiftModal/src/views/ConfirmEditsView/ConfirmEditsView';
import { SessionService } from 'services/SessionService';
import { EditingReasonAndNotifyView } from 'components/ShiftModal/src/views/DetailView/GeneralTab/ReasonForEditingAndNotify';
import { SendToAgencyConfirmationView } from 'components/ShiftModal/src/views/SendToAgencyConfirmationView';

export const ShiftModalComponentMap = {
  AssignToAgencyTeamMemberSummaryView,
  AssignToAgencyTeamMemberView: SelectAgencyTeamMemberView,
  TeamMemberUnavailableView,
  ShiftUnavailableView,
  DetailView,
  EditView,
  NotifyTeamMember,
  DeleteView,
  RateShiftView,
  ReleaseShiftView,
  PreRequestOptionsView,
  SendToAgenciesSummaryView,
  RequestTeamMemberView,
  PostRequestOptionsView,
  SelectAgenciesView,
  AuditTrailView,
  AwardTeamMemberView,
  AwardUnavailableView,
  ReasonForEdits,
  EditingReasonAndNotifyView,
  ConfirmEditsView,
  SendToAgencyConfirmationView,
};

export type ShiftModalViewType = keyof typeof ShiftModalComponentMap;

const shiftModalApolloClient = createApolloClient({ cache: createApolloCache() });

export const shiftModalDetailsQueryConfig = (
  facility_id: number,
  shift_id?: number,
): UseGQLQueryConfig<
  shiftModalOrderDetails,
  shiftModalOrderDetails,
  { id: number | undefined }
> => ({
  key: 'SHIFT_MODAL_DETAILS_QUERY',
  query: QUERY_shiftModalOrderDetails,
  client: shiftModalApolloClient,
  variables: {
    id: shift_id,
  },
  context: {
    headers: {
      'USER-CURRENT-FACILITY-TYPE': SessionService.assertUserType('B2B')
        ? 'Community'
        : 'AgencyCommunity',
      'USER-CURRENT-FACILITY-ID': facility_id,
    },
  },
});
