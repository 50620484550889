import { QuerySwitch } from 'components/QuerySwitch';
import {
  FetchJaneByIdDataProvider,
  FetchJaneByIdDataContext,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/DirectRequests/FetchJaneByJaneId.data.context';
import { DirectRequestsComponent } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/DirectRequests/DirectRequests.component';
import { FC } from 'react';

export const DirectRequests: FC = () => (
  <FetchJaneByIdDataProvider>
    <QuerySwitch context={FetchJaneByIdDataContext} component={DirectRequestsComponent} />
  </FetchJaneByIdDataProvider>
);
