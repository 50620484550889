import { SSFPaginationType } from './../hooks/useSSFBehaviors/useSSFBehaviors.types';
import { ApolloError } from '@apollo/client';
import { RenderingStateType } from 'types/common.types';

export function getRenderingState(
  paginationType: SSFPaginationType,
  isLoading: boolean,
  error: ApolloError | undefined,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
): RenderingStateType {
  if (error) return 'ERROR';
  if (paginationType === 'InfiniteScroll' && isLoading && data) return 'READY';
  if (isLoading) return 'LOADING';
  if (isLoading || data == null) return 'LOADING';
  if (!isLoading && error) return 'ERROR';
  return 'READY';
}
