import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const DefaultRuleLabel = styled.div`
  color: ${({ theme }) => theme.colors.Grey6};
  font-size: 14px;
`;

export const LocationName = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  padding: 12px 0 40px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const ModalContentWrapper = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-top: solid 1px #8b96a350;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
