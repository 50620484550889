import React, { FC } from 'react';
import { ErrorWrapper } from 'components/ErrorLabel/styled';

interface ErrorLabelProps {
  isVisible: boolean;
  message: string;
  pointed?: boolean;
  [key: string]: unknown;
}

const ErrorLabel: FC<ErrorLabelProps> = ({ isVisible, message, pointed = false, ...rest }) => {
  if (!isVisible) return null;
  return (
    <ErrorWrapper pointed={pointed} {...rest}>
      {message.split('\n').map((str, index) => (
        <div key={index}>{str}</div>
      ))}
    </ErrorWrapper>
  );
};

export default ErrorLabel;
