import { CustomFieldInfoFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import i18next from 'i18next';
import pluralize from 'pluralize';

const VALIDATION_dropdownOptions = (
  value: CustomFieldInfoFormSchema[keyof CustomFieldInfoFormSchema],
  rest: CustomFieldInfoFormSchema,
): string | string[] => {
  const dropdownOptions = value as string[];
  if (rest.type[0] !== 'Single-Select Dropdown') return '';
  const data = dropdownOptions.map((str) => str.toLowerCase());
  let errs: string[] = [];
  const uniqueData = Array.from(new Set(data));
  const hasDuplicates = uniqueData.length !== data.length;
  const duplicates = data.filter((val, i) => data.indexOf(val) !== i);
  if (data.length === 0) errs.push(i18next.t('settings:AT_LEAST_1_OPTION_IS_REQUIRED'));
  if (data.length > 200)
    errs.push(
      `${i18next.t('settings:REMOVE')} ${data.length - 200} ${pluralize(
        'option',
        data.length - 200,
      )}`,
    );
  if (hasDuplicates)
    [
      i18next.t('settings:REMOVE_DUPLICATE_VALUES'),
      ...duplicates.map((str) => `\t• ${str}`),
    ].forEach((item) => errs.push(item));
  if (errs.length) return errs;
  return '';
};

export { VALIDATION_dropdownOptions };
