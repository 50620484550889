import { BKJButton } from '@bookjane2/bookjane-design-library';
import {
  ColorDot,
  TotalSummaryFooterContentWrapper,
  TotalSummaryFooterEntryWrapper,
  TotalSummaryFooterText,
  TotalSummaryFooterTitleWrapper,
  TotalSummaryFooterWrapper,
} from 'components/TotalSummaryFooter/TotalSummaryFooter.styled';
import { ITotalSummaryFooterProps } from 'components/TotalSummaryFooter/TotalSummaryFooter.types';
import { FC } from 'react';

export const TotalSummaryFooter: FC<ITotalSummaryFooterProps> = ({
  title,
  buttonProps,
  entries,
}) => {
  return (
    <TotalSummaryFooterWrapper>
      <TotalSummaryFooterContentWrapper>
        <TotalSummaryFooterTitleWrapper>{title}</TotalSummaryFooterTitleWrapper>
        {entries.map(({ color, label, count }) => (
          <TotalSummaryFooterEntryWrapper key={label}>
            {color && <ColorDot color={color}>•</ColorDot>}
            <TotalSummaryFooterText>
              &nbsp;{label}:&nbsp;{count}
            </TotalSummaryFooterText>
          </TotalSummaryFooterEntryWrapper>
        ))}
      </TotalSummaryFooterContentWrapper>
      {buttonProps && (
        <BKJButton width="192px" variant="PurpleSolid" {...buttonProps}>
          {buttonProps.label}
        </BKJButton>
      )}
    </TotalSummaryFooterWrapper>
  );
};
