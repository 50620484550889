type APINamespaceType = string;

export const API_NAMESPACE_SCHEDULING_SEARCH_MEMBERS: APINamespaceType =
  'API_NAMESPACE_SCHEDULING_SEARCH_MEMBERS';
export const API_NAMESPACE_PUBLISHED_SHIFT_DETAILS_MODAL: APINamespaceType =
  'API_NAMESPACE_PUBLISHED_SHIFT_DETAILS_MODAL';
export const API_NAMESPACE_ALL_SHIFTS_REQUEST_OPTIONS: APINamespaceType =
  'API_NAMESPACE_ALL_SHIFTS_REQUEST_OPTIONS';
export const API_NAMESPACE_SCHEDULE_TABLE_BODY_JANES: APINamespaceType =
  'API_NAMESPACE_SCHEDULE_TABLE_BODY_JANES';
export const API_NAMESPACE_SCHEDULE_CLEAR_DRAFTS_POSITION_WEEK: APINamespaceType =
  'API_NAMESPACE_SCHEDULE_CLEAR_DRAFTS_POSITION_WEEK';
export const API_NAMESPACE_SCHEDULE_CLEAR_DRAFTS_POSITION_ALL_TIME: APINamespaceType =
  'API_NAMESPACE_SCHEDULE_CLEAR_DRAFTS_POSITION_ALL_TIME';
export const API_NAMESPACE_SCHEDULE_CLEAR_DRAFTS_ALL_TIME: APINamespaceType =
  'API_NAMESPACE_SCHEDULE_CLEAR_DRAFTS_ALL_TIME';
