import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const SendToAgencyConfirmationViewContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px;
  }
`;

export const SendToAgencyConfirmationViewTitle = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 18px;
  }
`;

export const SendToAgencyConfirmationViewBody = styled(Flex)`
  font-size: 14px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 21px;
`;
