import { SettingsNavigationPropsProvider } from 'components/SettingsNavigation/SettingsNavigation.props.context';
import { forwardRef } from 'react';
import { SettingsNavigationComponent } from './SettingsNavigation.component';

export const SettingsNavigation = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <SettingsNavigationPropsProvider>
      <SettingsNavigationComponent {...props} ref={ref} />
    </SettingsNavigationPropsProvider>
  );
});
