import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { buildTotalEstimatedPayoutRange } from '../../SendToAgenciesSummaryView.constants';
import { ShiftPay } from '..';
import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

function OpenPayComponent() {
  const { data: fetchOrderData } = useShiftModalDataContext();

  const allOpenEstimatedTotalPayout = buildTotalEstimatedPayoutRange(
    fetchOrderData.fetchOrder!.position!.openPriceRange!,
    fetchOrderData.fetchOrder!.totalDuration!,
  );

  return (
    <ErrorBoundary componentName="OpenPay" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <ShiftPay
        estimatedHourlyPay={fetchOrderData.fetchOrder!.position.openPriceRange?.rangeLabel!}
        estimatedTotalPay={allOpenEstimatedTotalPayout}
      />
    </ErrorBoundary>
  );
}

export function OpenPay() {
  return (
    <ErrorBoundary componentName="OpenPay" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <OpenPayComponent />
    </ErrorBoundary>
  );
}
