import { FormValidationErrorMessageType } from '@bookjane2/bookjane-design-library/lib/common.types';
import {
  DeleteShiftReasonType,
  DeleteViewFormSchema,
} from 'components/ShiftModal/src/views/DeleteView/DeleteView.types';

export const VALIDATION_specifiedOtherReason = (
  value: DeleteShiftReasonType[],
  rest: DeleteViewFormSchema,
) => {
  const messages: FormValidationErrorMessageType = [];
  if (value[0]) {
    if (value[0] === 'other' && rest.specifiedOtherReason.length === 0)
      messages.push('Specified Other Reason is required');
  } else messages.push('Delete Shift Reason is required');

  return messages;
};
