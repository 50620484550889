import { BKJIcon, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { AppLink } from 'components/AppLink/AppLink';
import { ISettingsNavigationProps } from 'components/SettingsNavigation/ExpandableMenuLink/ExpandableMenuLink.types';
import {
  administratorSettingsPagePath,
  administratorSettingsPageRouteMatch,
} from 'components/SettingsNavigation/SettingsNavigation.constants';
import {
  AddNewButton,
  LocationSearchInputWrapper,
  MenuAccordion,
} from 'components/SettingsNavigation/SettingsNavigation.styled';
import { SettingsNavMenuItem } from 'components/SettingsNavigation/SettingsNavMenuItem';
import { useCachedScrollRef } from 'hooks/useCachedScrollRef';
import { useHandleSearch } from 'hooks/useHandleSearch';
import { useNumSettingsMenuItems } from 'hooks/useNumSettingsMenuItems';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { FC, useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { filterStrictMatch } from 'utils/filterStrictMatch';
import { useSelector } from 'react-redux';
import isUserSecondarySelector from 'store/selectors/userSelectors/isUserSecondarySelector';
import { useAdministratorSettingsDataContext } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { AdministratorSettingsDataProvider } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { searchData } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';

import { inviteAdminModalRoutingService } from 'components/InviteAdminModal/InviteAdminModal.ui.context';
import styled from 'styled-components';
import { InviteAdminModal } from 'components/InviteAdminModal';
import { FeatureGuard } from 'guards/FeatureGuard';
import { isAccushieldSyncingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isAccushieldSyncingFeatureEnabledSelector';
import { useTranslation } from 'hooks/useTranslation';
const AddNewAdminButtonWrapper = styled.div<{ isActive: boolean }>`
  top: 0;
  left: 0;
  position: sticky;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.Grey3 : theme.colors.White};
  z-index: 1;
`;

const AdministratorSettingsNavigationViewComponent: FC<ISettingsNavigationProps> = ({
  isOpen,
  isExpanded,
}) => {
  const numMenuItems = useNumSettingsMenuItems();
  const isUserSecondary = useSelector(isUserSecondarySelector);
  const isAccushieldSyncing = useSelector(isAccushieldSyncingFeatureEnabledSelector);
  const { searchData } = useAdministratorSettingsDataContext();

  const { scrollElementRef, handleScroll } = useCachedScrollRef(
    'LocationSettingsNavigationScrollTop',
  );

  const { search, onChange, onReset, handleKeyDown } = useHandleSearch();

  const match = useRouteMatch<{ id: string }>(administratorSettingsPageRouteMatch);
  const adminId = match?.params?.id;

  const items = useMemo(() => {
    return filterStrictMatch<searchData>(search, searchData, (item) => {
      let label = item.first_name.concat(' ' + item.last_name);
      return label;
    });
  }, [searchData, search]);
  const { t } = useTranslation();

  return (
    <MenuAccordion
      $isOpen={isOpen}
      onScroll={handleScroll}
      $isExpanded={isExpanded}
      numMenuItems={numMenuItems}
      ref={scrollElementRef}
    >
      <FeatureGuard NOT type="FEATURE_TYPE_ACCUSHIELD_SYNCING">
        <UserGuard
          userType={[
            UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
            UserPermissionEnum.USER_TYPE_ESSENTIAL_PRIMARY,
            UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
          ]}
        >
          <AddNewAdminButtonWrapper isActive={!!match?.url.includes('inviteAdminActiveModalView')}>
            <AddNewButton
              data-cy="btn-Add Admin"
              onClick={() => inviteAdminModalRoutingService.open({})}
            >
              <BKJIcon iconName="Add" />
              {t('settings:INVITE_ADMIN')}
            </AddNewButton>
          </AddNewAdminButtonWrapper>
        </UserGuard>
      </FeatureGuard>

      <LocationSearchInputWrapper
        width="100%"
        justifyContent="center"
        alignItems="center"
        padding="0 16px"
        isTopStickied={isUserSecondary}
        isAdjustForReadOnly={isAccushieldSyncing}
      >
        <BKJTextInput
          name="search"
          onChange={onChange}
          value={search}
          placeholder={t('common:SEARCH')}
          onKeyDown={handleKeyDown}
          leftIconProps={{ iconName: 'Search' }}
          rightIconProps={
            search
              ? {
                  iconName: 'CloseBold',
                  onClick: () => onReset(),
                }
              : undefined
          }
        />
      </LocationSearchInputWrapper>
      {items.length === 0 && search.length > 0 && (
        <SettingsNavMenuItem
          active={false}
          label={t('common:NO_RESULTS')}
          scrollElement={scrollElementRef.current}
        />
      )}
      {items &&
        items.map((option) => {
          const active = adminId === option.id.toString();
          return (
            <AppLink key={option.id} to={`${administratorSettingsPagePath}/${option.id}/general`}>
              <SettingsNavMenuItem
                active={active}
                label={`${option.first_name} ${option.last_name}`}
                scrollElement={scrollElementRef.current}
              />
            </AppLink>
          );
        })}
    </MenuAccordion>
  );
};

const AdministratorSettingsNavigationViewWrapper: FC<ISettingsNavigationProps> = (props) => {
  const { searchData } = useAdministratorSettingsDataContext();

  const adminIds = useCallback(() => searchData.map((item) => item.id) || [], [searchData]);
  return useMemo(
    () => <AdministratorSettingsNavigationViewComponent key={`${adminIds}`} {...props} />,
    [adminIds, props],
  );
};

export const AdministratorSettingsNavigation: FC<ISettingsNavigationProps> = (props) => {
  return (
    <AdministratorSettingsDataProvider>
      <InviteAdminModal />
      <AdministratorSettingsNavigationViewWrapper {...props} />
    </AdministratorSettingsDataProvider>
  );
};
