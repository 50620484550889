import styled, { css } from 'styled-components';

export const ConfirmEditsContainer = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(3, 1fr);

  max-width: 500px;
`;
export const ConfirmEditsRow = styled.div<{ $isEven?: boolean }>`
  padding: 12px;
  gap: 10px;
  color: ${({ theme }) => theme.colors.Grey7};
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  border-bottom: solid 1px ${({ theme }) => theme.colors.Grey5};
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $isEven }) =>
    $isEven &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey1};
    `};
`;
export const ConfirmEditsHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.AccentPurple};
  color: ${({ theme }) => theme.colors.White};
  padding: 12px;
  line-height: 24px;
  gap: 8px;
`;

export const ReasonForEditingHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.Grey7};
`;

export const ReasonForEditingBody = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.Grey7};
`;

export const ZeroHoursWorked = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.Grey7};
`;
