import {
  FooterLinks,
  InputLabelText,
  StyledBKJToggleInput,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { ExternalNavMenuItem } from 'components/BKJMainNavigation/src/ExternalNavMenuItem';
import { IMobileNavFooterProps } from 'components/BKJMainNavigation/src/Mobile/MobileNavFooter.types';
import { NavMenuItem } from 'components/BKJMainNavigation/src/NavMenuItem';
import { Flex } from 'components/Flex';
import { FC, memo } from 'react';
import { useUserActions } from 'store/reducers/auth/authReducer';
import { deepEquals } from 'utils/deepEquals';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

const getHelpDeskLink = () => {
  const { whiteLabelProvider, WhiteLabelProvidersEnum } = WhiteLabelAccessor;
  switch (whiteLabelProvider) {
    case WhiteLabelProvidersEnum.XTM:
      return 'https://www.paidtoday.io/support';
    case WhiteLabelProvidersEnum.ACCUSHIELD:
      return 'https://flexaccushield.zendesk.com';
    case WhiteLabelProvidersEnum.PETAL:
      return 'https://support.petalmd.com/hc/en-ca';
    default:
      return 'https://bookjane.zendesk.com';
  }
};

const Component: FC<IMobileNavFooterProps> = ({
  disabled,
  isOpen,
  onClose,
  isShowDesktopItems,
  toggleDesktopItems,
}) => {
  const { userLogout } = useUserActions();
  const { isXtmProvider } = WhiteLabelAccessor;
  const { t } = useTranslation();
  const onChange = () => {
    toggleDesktopItems(!isShowDesktopItems);
  };
  return (
    <FooterLinks>
      <Flex alignItems="center" padding="17px 0 17px 28px">
        <StyledBKJToggleInput
          variant="Switch"
          name="show_desktop_items"
          onChange={onChange}
          checked={isShowDesktopItems}
          minWidth="12px"
          width="12px"
        />
        <InputLabelText>Show desktop site</InputLabelText>
      </Flex>
      <ExternalNavMenuItem
        href={getHelpDeskLink()}
        label={t('navigation:HELP')}
        icon="Question"
        disabled={disabled.current}
        isOpen={isOpen}
        onClick={(e) => {
          onClose(e);
          e.preventDefault();
          return window.open(getHelpDeskLink(), '_blank');
        }}
      />
      {!isXtmProvider && (
        <NavMenuItem
          label={t('navigation:LOGOUT')}
          icon="Logout"
          data-cy="logout"
          disabled={disabled.current}
          isOpen={isOpen}
          onClick={(e) => {
            userLogout();
            onClose(e);
          }}
        />
      )}
    </FooterLinks>
  );
};

export const MobileNavFooter = memo(Component, deepEquals);
