import {
  AllowOvertimeDialogFormProvider,
  useAllowOvertimeDialogFormContext,
} from 'components/AllowOvertimeDialog/AllowOvertimeDialog.form.context';
import { AllowOvertimeDialogProps } from 'components/AllowOvertimeDialog/AllowOvertimeDialog.types';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AllowOvertimeDialogView: FC<AllowOvertimeDialogProps> = ({
  isAllowOvertimeConfirmationOpen,
  setIsAllowOvertimeConfirmationOpen,
}) => {
  const { t } = useTranslation();
  const { handleAllowOvertime } = useAllowOvertimeDialogFormContext();
  return (
    <BKJCenteredDialog
      isOpen={isAllowOvertimeConfirmationOpen}
      variant="Default"
      title={t('dialog:ALLOW_OVERTIME_CONFIRMATION')}
      text={t('dialog:ALLOW_OVERTIME_APPROVAL_MESSAGE')}
      cancelLabel={t('auth:CANCEL')}
      actionLabel={t('auth:CONFIRM')}
      onAction={() => {
        setIsAllowOvertimeConfirmationOpen(false);
        return handleAllowOvertime();
      }}
      onCancel={() => setIsAllowOvertimeConfirmationOpen(false)}
      onClose={() => setIsAllowOvertimeConfirmationOpen(false)}
    />
  );
};

export const AllowOvertimeDialog: FC<AllowOvertimeDialogProps> = (props) => {
  return (
    <AllowOvertimeDialogFormProvider {...props}>
      <AllowOvertimeDialogView {...props} />
    </AllowOvertimeDialogFormProvider>
  );
};
