import { NavMenuItemsWrapper } from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { InternalNavMenuItem } from 'components/BKJMainNavigation/src/InternalNavMenuItem';
import { FeatureGuard } from 'guards/FeatureGuard';
import { UserGuard } from 'guards/UserGuard';
import { Fragment, MutableRefObject } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

export const MobileNavMenuItems = ({
  disabled,
  isOpen,
  onClose,
  isShowDesktopItems,
}: {
  disabled: MutableRefObject<boolean>;
  isOpen: boolean;
  onClose: () => void;
  isShowDesktopItems: boolean;
}) => {
  const { isXtmProvider, isPetalProvider } = WhiteLabelAccessor;
  const { t } = useTranslation();
  return (
    <NavMenuItemsWrapper>
      <InternalNavMenuItem
        path="/dashboard"
        label={t('navigation:DASHBOARD')}
        icon="Dashboard"
        data-cy="NavMenuItems-Dashboard"
        disabled={disabled?.current}
        isOpen={isOpen}
        onClick={onClose}
        exact
      />

      <FeatureGuard NOT type="FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER">
        <Fragment>
          {isShowDesktopItems && (
            <FeatureGuard type="FEATURE_TYPE_SCHEDULING">
              <InternalNavMenuItem
                data-testid="nav-menu-schedule"
                path="/schedule"
                label={t('navigation:SCHEDULE')}
                icon="Schedule"
                data-cy="NavMenuItems-Schedule"
                disabled={disabled.current}
                isOpen={isOpen}
                onClick={onClose}
              />
            </FeatureGuard>
          )}
          <UserGuard
            userType={[
              'USER_TYPE_B2B',
              'USER_TYPE_PREMIUM_SECONDARY',
              'USER_TYPE_ESSENTIAL_SECONDARY',
            ]}
          >
            <InternalNavMenuItem
              path="/create-shifts"
              label={t('navigation:CREATE_BATCH_SHIFTS')}
              icon="NewBooking"
              data-cy="NavMenuItems-CreateBatchShifts"
              disabled={disabled.current}
              isOpen={isOpen}
              onClick={onClose}
            />
          </UserGuard>
        </Fragment>
      </FeatureGuard>
      {isShowDesktopItems && (
        <Fragment>
          <InternalNavMenuItem
            path="/shifts"
            label={t('common:SHIFTS')}
            icon="Shifts"
            data-cy="NavMenuItems-Shifts"
            disabled={disabled.current}
            isOpen={isOpen}
            onClick={onClose}
          />
          <InternalNavMenuItem
            path="/timesheets"
            label={t('navigation:TIMESHEETS')}
            icon="Timesheets"
            data-cy="NavMenuItems-Timesheets"
            disabled={disabled.current}
            isOpen={isOpen}
            onClick={onClose}
          />
          {!isXtmProvider && (
            <UserGuard
              userType={[
                'USER_TYPE_AGENCY',
                'USER_TYPE_B2B',
                'USER_TYPE_PREMIUM_SECONDARY',
                'USER_TYPE_ESSENTIAL_SECONDARY',
              ]}
            >
              <InternalNavMenuItem
                path="/team-member"
                label={t('navigation:TEAM_MEMBER')}
                icon="TeamMembers"
                data-cy="NavMenuItems-TeamMembers"
                disabled={disabled.current}
                isOpen={isOpen}
                onClick={onClose}
              />
            </UserGuard>
          )}
          {!isXtmProvider && !isPetalProvider && (
            <UserGuard
              userType={[
                'USER_TYPE_B2B',
                'USER_TYPE_PREMIUM_SECONDARY',
                'USER_TYPE_ESSENTIAL_SECONDARY',
              ]}
            >
              <InternalNavMenuItem
                path="/agency-member"
                label={t('navigation:AGENCY_MEMBER')}
                icon="AgencyMembers"
                data-cy="NavMenuItems-AgencyMembers"
                disabled={disabled.current}
                isOpen={isOpen}
                onClick={onClose}
              />
            </UserGuard>
          )}
          <InternalNavMenuItem
            path="/communications"
            label={t('navigation:COMMUNICATIONS')}
            icon="Communications"
            data-cy="NavMenuItems-Communications"
            disabled={disabled.current}
            isOpen={isOpen}
            onClick={onClose}
          />
          <InternalNavMenuItem
            path="/settings"
            label={t('navigation:SETTINGS')}
            icon="Settings"
            data-cy="NavMenuItems-Settings"
            disabled={disabled.current}
            isOpen={isOpen}
            onClick={onClose}
          />
        </Fragment>
      )}
    </NavMenuItemsWrapper>
  );
};
