import { BKJComboBox } from '@bookjane2/bookjane-design-library';
import { FC } from 'react';
import { timeFrameDropdownOptions } from './TimeFrameSelectInput.constants';
import { ITimeFrameSelectInputProps } from './TimeFrameSelectInput.types';

export const TimeFrameSelectInput: FC<ITimeFrameSelectInputProps> = ({
  name,
  placeholder,
  value,
  onChange,
  label,
  error,
  allowedAutoPlacements = ['bottom'],
}) => {
  return (
    <BKJComboBox
      error={error}
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      options={timeFrameDropdownOptions}
      type="Single"
      action="OpenToggle"
      filter="TimeStartsWith"
      $dropdownHeight="170px"
      allowedAutoPlacements={allowedAutoPlacements}
    />
  );
};
