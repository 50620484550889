import { GROUPS } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_POST_createStaticGroupParamsType } from 'requests/POST_createStaticGroup.types';

export const API_POST_createStaticGroup = ({
  name,
  community_id,
  members,
}: API_POST_createStaticGroupParamsType): ApiRequestConfigType => {
  const group_janes_attributes = members.map((member) => ({
    jane_id: member,
  }));
  return {
    __typename: 'LegacyAPI',
    url: GROUPS,
    method: METHOD_POST,
    body: {
      group: {
        name,
        type: 'StaticGroup',
        community_id,
        group_janes_attributes,
      },
    },
  };
};
