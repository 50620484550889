import { BKJButton, BKJCheckbox, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJSideModal } from 'components/BKJSideModal';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { FacilitiesSelectionCountLabel } from 'components/CustomFieldModal/CustomFieldModal.styled';
import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';
import { H3 } from 'components/Typography';
import { useAvailabilityLocationSettingsPageFormContext } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.form.context';
import {
  ApplyFacilitiesHeader,
  ApplyFacilitiesNoResults,
  ApplyFacilitiesTable,
  ApplyFacilitiesTableCell,
  ApplyFacilitiesTableHeader,
} from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.styled';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC, Fragment, useCallback, useRef } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';
import { css } from 'styled-components';
import { filterStrictMatch } from 'utils/filterStrictMatch';

const FacilityInputRow: FC<{ id: string; label: string }> = ({ id, label }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const { values, onChange } = useAvailabilityLocationSettingsPageFormContext();
  const isDisabled = locationId === `${id}`;

  const testId = `${id}-${label}`;
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (event: ChangeEventType) => {
      onChange({
        target: {
          name: 'communityIds',
          value: event.target.value
            ? values.communityIds.concat(`${id}`)
            : values.communityIds.filter((currentValueId) => `${currentValueId}` !== `${id}`),
        },
      });
    },
    [id, onChange, values.communityIds],
  );

  const handleClick = useCallback((event) => {
    event.preventDefault();
    ref.current?.click();
  }, []);

  return (
    <Fragment key={id}>
      <ApplyFacilitiesTableCell onClick={handleClick} cursor="pointer">
        {label}
      </ApplyFacilitiesTableCell>
      <ApplyFacilitiesTableCell onClick={handleClick} justifyContent="center" cursor="pointer">
        <BKJCheckbox
          ref={ref}
          disabled={isDisabled}
          value={values.communityIds.includes(`${id}`)}
          name="communityIds"
          variant="GreenSolid"
          data-testid={testId}
          onChange={handleChange}
        />
      </ApplyFacilitiesTableCell>
    </Fragment>
  );
};

const SelectAllFacilitiesOption: FC = () => {
  const { t } = useTranslation();
  const {
    locationFilter: { data: communities },
  } = useAuthRouteDataContext();

  const schedulingReadAccessCommunityIds = useSelector(schedulingReadAccessFacilityIdsSelector).map(
    String,
  );

  const { locationId } = useParams<{ locationId: string }>();
  const { values, onChange } = useAvailabilityLocationSettingsPageFormContext();
  const results = filterStrictMatch<LocationOptionType>(
    values.byName,
    communities.filter((community) => {
      return schedulingReadAccessCommunityIds.some((id) => id === community.id);
    }),
    'label',
  );
  const handleChange = useCallback(
    (event: ChangeEventType) => {
      onChange([
        {
          target: {
            name: 'communityIds',
            value: event.target.value ? results.map(({ id }) => `${id}`) : [locationId],
          },
        },
        event,
      ]);
    },
    [locationId, onChange, results],
  );
  const ref = useRef<HTMLInputElement>(null);
  const handleClick = useCallback((event) => {
    event.preventDefault();
    ref.current?.click();
  }, []);
  return (
    <Fragment>
      <ApplyFacilitiesTableCell
        cursor="pointer"
        onClick={handleClick}
        css={css`
          z-index: 1;
          position: sticky;
          top: 50px;
        `}
      >
        {t('All Facilities')}
      </ApplyFacilitiesTableCell>
      <ApplyFacilitiesTableCell
        onClick={handleClick}
        cursor="pointer"
        css={css`
          z-index: 1;
          position: sticky;
          top: 50px;
          justify-content: center;
        `}
      >
        <BKJCheckbox
          ref={ref}
          value={values.allFacilities}
          name="allFacilities"
          variant="GreenSolid"
          data-testid="allFacilities"
          onChange={handleChange}
        />
      </ApplyFacilitiesTableCell>
    </Fragment>
  );
};

const ApplyFacilitiesView: FC = () => {
  const { t } = useTranslation();
  const {
    locationFilter: { data: communities },
  } = useAuthRouteDataContext();
  const schedulingReadAccessCommunityIds = useSelector(schedulingReadAccessFacilityIdsSelector).map(
    String,
  );
  const { values, onReset, isDisabled, onChange } =
    useAvailabilityLocationSettingsPageFormContext();
  const results = filterStrictMatch<LocationOptionType>(
    values.byName,
    communities.filter((community) => {
      return schedulingReadAccessCommunityIds.some((id) => id === community.id);
    }),
    'label',
  );

  return (
    <Fragment>
      <ApplyFacilitiesHeader flexDirection="column" gap="32px" padding="32px" flex="1">
        <H3>{t('settings:SELECT_LOCATIONS_TO_APPLY')}:</H3>
        <BKJTextInput
          onChange={onChange}
          name="byName"
          value={values.byName}
          placeholder={t('settings:SEARCH_LOCATIONS')}
          leftIconProps={{ iconName: 'Search' }}
          rightIconProps={
            values.byName
              ? {
                  iconName: 'CloseBold',
                  onClick: () => {
                    onReset({ ...values, byName: '' });
                  },
                }
              : undefined
          }
          autoComplete="off"
          onBlur={() => {
            if (results.length === 0) onReset({ ...values, byName: '' });
          }}
        />
      </ApplyFacilitiesHeader>
      <ApplyFacilitiesTable>
        <ApplyFacilitiesTableHeader>{t('Facilities')}</ApplyFacilitiesTableHeader>
        <ApplyFacilitiesTableHeader
          css={css`
            justify-content: center;
          `}
        >
          {t('auth:APPLY')}
        </ApplyFacilitiesTableHeader>
        {!results.length ? (
          <ApplyFacilitiesNoResults alignItems="center" justifyContent="center">
            {t('CUSTOM_FIELDS_MODAL_FACILITIES_EMPTY_SEARCH')}
          </ApplyFacilitiesNoResults>
        ) : (
          <Fragment>
            <SelectAllFacilitiesOption />

            {results.map(FacilityInputRow)}
          </Fragment>
        )}
      </ApplyFacilitiesTable>
      <BKJSideModalFooter justifyContent="space-between">
        <FacilitiesSelectionCountLabel>
          {`${t('common:SELECTED')}: ${values.communityIds.length}/${
            communities.filter((community) => {
              return schedulingReadAccessCommunityIds.some((id) => id === community.id);
            }).length
          }`}
        </FacilitiesSelectionCountLabel>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          disabled={isDisabled}
          onClick={() => {
            onReset({ ...values, byName: '', isApplyToFacilitiesModalOpen: false });
          }}
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};

export const ApplyAvailabilityToOtherLocationsModal: FC = () => {
  const { t } = useTranslation();
  const { values, onReset } = useAvailabilityLocationSettingsPageFormContext();

  return (
    <BKJSideModal
      isOpen={values.isApplyToFacilitiesModalOpen}
      onClose={() => {
        onReset({ ...values, byName: '', isApplyToFacilitiesModalOpen: false });
      }}
      headerComponent={t('settings:APPLY_SETTINGS')}
      showBackButton={false}
    >
      <ApplyFacilitiesView />
    </BKJSideModal>
  );
};
