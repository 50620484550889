import { ITimeFrameOption } from './TimeFrameSelectInput.types';

export const timeFrameDropdownOptions: ITimeFrameOption[] = [
  {
    key: '00:00',
    value: '00:00:00',
    label: '12:00 am',
  },
  {
    key: '00:15',
    value: '00:15:00',
    label: '12:15 am',
  },
  {
    key: '00:30',
    value: '00:30:00',
    label: '12:30 am',
  },
  {
    key: '00:45',
    value: '00:45:00',
    label: '12:45 am',
  },
  {
    key: '01:00',
    value: '01:00:00',
    label: '1:00 am',
  },
  {
    key: '01:15',
    value: '01:15:00',
    label: '1:15 am',
  },
  {
    key: '01:30',
    value: '01:30:00',
    label: '1:30 am',
  },
  {
    key: '01:45',
    value: '01:45:00',
    label: '1:45 am',
  },
  {
    key: '02:00',
    value: '02:00:00',
    label: '2:00 am',
  },
  {
    key: '02:15',
    value: '02:15:00',
    label: '2:15 am',
  },
  {
    key: '02:30',
    value: '02:30:00',
    label: '2:30 am',
  },
  {
    key: '02:45',
    value: '02:45:00',
    label: '2:45 am',
  },
  {
    key: '03:00',
    value: '03:00:00',
    label: '3:00 am',
  },
  {
    key: '03:15',
    value: '03:15:00',
    label: '3:15 am',
  },
  {
    key: '03:30',
    value: '03:30:00',
    label: '3:30 am',
  },
  {
    key: '03:45',
    value: '03:45:00',
    label: '3:45 am',
  },
  {
    key: '04:00',
    value: '04:00:00',
    label: '4:00 am',
  },
  {
    key: '04:15',
    value: '04:15:00',
    label: '4:15 am',
  },
  {
    key: '04:30',
    value: '04:30:00',
    label: '4:30 am',
  },
  {
    key: '04:45',
    value: '04:45:00',
    label: '4:45 am',
  },
  {
    key: '05:00',
    value: '05:00:00',
    label: '5:00 am',
  },
  {
    key: '05:15',
    value: '05:15:00',
    label: '5:15 am',
  },
  {
    key: '05:30',
    value: '05:30:00',
    label: '5:30 am',
  },
  {
    key: '05:45',
    value: '05:45:00',
    label: '5:45 am',
  },
  {
    key: '06:00',
    value: '06:00:00',
    label: '6:00 am',
  },
  {
    key: '06:15',
    value: '06:15:00',
    label: '6:15 am',
  },
  {
    key: '06:30',
    value: '06:30:00',
    label: '6:30 am',
  },
  {
    key: '06:45',
    value: '06:45:00',
    label: '6:45 am',
  },
  {
    key: '07:00',
    value: '07:00:00',
    label: '7:00 am',
  },
  {
    key: '07:15',
    value: '07:15:00',
    label: '7:15 am',
  },
  {
    key: '07:30',
    value: '07:30:00',
    label: '7:30 am',
  },
  {
    key: '07:45',
    value: '07:45:00',
    label: '7:45 am',
  },
  {
    key: '08:00',
    value: '08:00:00',
    label: '8:00 am',
  },
  {
    key: '08:15',
    value: '08:15:00',
    label: '8:15 am',
  },
  {
    key: '08:30',
    value: '08:30:00',
    label: '8:30 am',
  },
  {
    key: '08:45',
    value: '08:45:00',
    label: '8:45 am',
  },
  {
    key: '09:00',
    value: '09:00:00',
    label: '9:00 am',
  },
  {
    key: '09:15',
    value: '09:15:00',
    label: '9:15 am',
  },
  {
    key: '09:30',
    value: '09:30:00',
    label: '9:30 am',
  },
  {
    key: '09:45',
    value: '09:45:00',
    label: '9:45 am',
  },
  {
    key: '10:00',
    value: '10:00:00',
    label: '10:00 am',
  },
  {
    key: '10:15',
    value: '10:15:00',
    label: '10:15 am',
  },
  {
    key: '10:30',
    value: '10:30:00',
    label: '10:30 am',
  },
  {
    key: '10:45',
    value: '10:45:00',
    label: '10:45 am',
  },
  {
    key: '11:00',
    value: '11:00:00',
    label: '11:00 am',
  },
  {
    key: '11:15',
    value: '11:15:00',
    label: '11:15 am',
  },
  {
    key: '11:30',
    value: '11:30:00',
    label: '11:30 am',
  },
  {
    key: '11:45',
    value: '11:45:00',
    label: '11:45 am',
  },
  {
    key: '12:00',
    value: '12:00:00',
    label: '12:00 pm',
  },
  {
    key: '12:15',
    value: '12:15:00',
    label: '12:15 pm',
  },
  {
    key: '12:30',
    value: '12:30:00',
    label: '12:30 pm',
  },
  {
    key: '12:45',
    value: '12:45:00',
    label: '12:45 pm',
  },
  {
    key: '13:00',
    value: '13:00:00',
    label: '1:00 pm',
  },
  {
    key: '13:15',
    value: '13:15:00',
    label: '1:15 pm',
  },
  {
    key: '13:30',
    value: '13:30:00',
    label: '1:30 pm',
  },
  {
    key: '13:45',
    value: '13:45:00',
    label: '1:45 pm',
  },
  {
    key: '14:00',
    value: '14:00:00',
    label: '2:00 pm',
  },
  {
    key: '14:15',
    value: '14:15:00',
    label: '2:15 pm',
  },
  {
    key: '14:30',
    value: '14:30:00',
    label: '2:30 pm',
  },
  {
    key: '14:45',
    value: '14:45:00',
    label: '2:45 pm',
  },
  {
    key: '15:00',
    value: '15:00:00',
    label: '3:00 pm',
  },
  {
    key: '15:15',
    value: '15:15:00',
    label: '3:15 pm',
  },
  {
    key: '15:30',
    value: '15:30:00',
    label: '3:30 pm',
  },
  {
    key: '15:45',
    value: '15:45:00',
    label: '3:45 pm',
  },
  {
    key: '16:00',
    value: '16:00:00',
    label: '4:00 pm',
  },
  {
    key: '16:15',
    value: '16:15:00',
    label: '4:15 pm',
  },
  {
    key: '16:30',
    value: '16:30:00',
    label: '4:30 pm',
  },
  {
    key: '16:45',
    value: '16:45:00',
    label: '4:45 pm',
  },
  {
    key: '17:00',
    value: '17:00:00',
    label: '5:00 pm',
  },
  {
    key: '17:15',
    value: '17:15:00',
    label: '5:15 pm',
  },
  {
    key: '17:30',
    value: '17:30:00',
    label: '5:30 pm',
  },
  {
    key: '17:45',
    value: '17:45:00',
    label: '5:45 pm',
  },
  {
    key: '18:00',
    value: '18:00:00',
    label: '6:00 pm',
  },
  {
    key: '18:15',
    value: '18:15:00',
    label: '6:15 pm',
  },
  {
    key: '18:30',
    value: '18:30:00',
    label: '6:30 pm',
  },
  {
    key: '18:45',
    value: '18:45:00',
    label: '6:45 pm',
  },
  {
    key: '19:00',
    value: '19:00:00',
    label: '7:00 pm',
  },
  {
    key: '19:15',
    value: '19:15:00',
    label: '7:15 pm',
  },
  {
    key: '19:30',
    value: '19:30:00',
    label: '7:30 pm',
  },
  {
    key: '19:45',
    value: '19:45:00',
    label: '7:45 pm',
  },
  {
    key: '20:00',
    value: '20:00:00',
    label: '8:00 pm',
  },
  {
    key: '20:15',
    value: '20:15:00',
    label: '8:15 pm',
  },
  {
    key: '20:30',
    value: '20:30:00',
    label: '8:30 pm',
  },
  {
    key: '20:45',
    value: '20:45:00',
    label: '8:45 pm',
  },
  {
    key: '21:00',
    value: '21:00:00',
    label: '9:00 pm',
  },
  {
    key: '21:15',
    value: '21:15:00',
    label: '9:15 pm',
  },
  {
    key: '21:30',
    value: '21:30:00',
    label: '9:30 pm',
  },
  {
    key: '21:45',
    value: '21:45:00',
    label: '9:45 pm',
  },
  {
    key: '22:00',
    value: '22:00:00',
    label: '10:00 pm',
  },
  {
    key: '22:15',
    value: '22:15:00',
    label: '10:15 pm',
  },
  {
    key: '22:30',
    value: '22:30:00',
    label: '10:30 pm',
  },
  {
    key: '22:45',
    value: '22:45:00',
    label: '10:45 pm',
  },
  {
    key: '23:00',
    value: '23:00:00',
    label: '11:00 pm',
  },
  {
    key: '23:15',
    value: '23:15:00',
    label: '11:15 pm',
  },
  {
    key: '23:30',
    value: '23:30:00',
    label: '11:30 pm',
  },
  {
    key: '23:45',
    value: '23:45:00',
    label: '11:45 pm',
  },
].map((item) => ({ ...item, id: item.value }));
