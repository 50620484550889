import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_shiftModalTimeSheetDetails } from 'queries';

export const timesheetsTabQueryConfig = ({
  initialValues,
  key,
}: {
  initialValues: { orderScheduleId: number };
  key: string;
}): UseGQLQueryConfig => {
  return {
    key,
    query: QUERY_shiftModalTimeSheetDetails,
    variables: {
      orderScheduleId: initialValues.orderScheduleId,
    },
  };
};
