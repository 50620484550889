import { useSSFBehaviors } from 'hooks';
import { FC, useContext, useMemo } from 'react';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { QUERY_communityGroups } from 'queries';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';
import { communityGroups } from 'types/graphql-types';
import { useLocation } from 'react-router-dom';
import { createGQLDataContext } from 'utils/createDataContext';
import { GQLAPIContextType } from 'types/common.types';

export const NewGroupDataContext = createGQLDataContext<GQLAPIContextType<communityGroups>>();

const communityGroupsApolloClient = createApolloClient({ cache: createApolloCache() });

const groupsConfigForLocation = (selectedFacilityId: number): ISSFBehaviorsOptions => ({
  key: 'FETCH_COMMUNITY_GROUPS_QUERY',
  query: QUERY_communityGroups,
  pageSize: 10,
  paginationType: 'PagedPagination',
  client: communityGroupsApolloClient,
  debounceTimeout: 500,
  variables: {
    locationId: selectedFacilityId,
  },
  payloadTransformer: (variables) => {
    return {
      ...variables,
      filter: {
        withName: variables.withName,
      },
    };
  },
  schema: {
    locationId: { initialValue: selectedFacilityId },
    withName: { initialValue: '', mapper: String },
  },
});

export const NewGroupDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const location_id = Number.parseInt(pathname.split('/')[3]);

  const [values, opts] = useSSFBehaviors<communityGroups>(groupsConfigForLocation(location_id));

  const contextValues = useMemo(() => {
    return {
      values,
      ...opts,
    };
  }, [opts, values]);

  return (
    <NewGroupDataContext.Provider value={contextValues}>{children}</NewGroupDataContext.Provider>
  );
};

export const useNewGroupDataContext = (): GQLAPIContextType<communityGroups> =>
  useContext(NewGroupDataContext);
