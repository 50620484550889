import { CUSTOMER_ORDER } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_POST_createOrdersParamsType } from 'requests/POST_createOrders.types';

export const API_POST_createOrders = (
  requestBody: API_POST_createOrdersParamsType,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: CUSTOMER_ORDER,
    method: METHOD_POST,
    body: requestBody,
  };
};
