import { IShiftRecurringIntervalOptionsInput } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/CreateShiftModal.types';
import i18next from 'i18next';

export const shiftRecurringIntervalOptions = (
  recurring_frequency: string,
): IShiftRecurringIntervalOptionsInput[] => {
  switch (recurring_frequency) {
    case 'month':
      return [...Array(3)].map((_, i) => ({
        key: i + 1,
        value: String(i + 1) as IShiftRecurringIntervalOptionsInput['value'],
        label: String(i + 1) as IShiftRecurringIntervalOptionsInput['label'],
      }));

    case 'day':
      return [...Array(30)].map((_, i) => ({
        key: i + 1,
        value: String(i + 1) as IShiftRecurringIntervalOptionsInput['value'],
        label: String(i + 1) as IShiftRecurringIntervalOptionsInput['label'],
      }));
    case 'week':
    default:
      return [...Array(4)].map((_, i) => ({
        key: i + 1,
        value: String(i + 1) as IShiftRecurringIntervalOptionsInput['value'],
        label: String(i + 1) as IShiftRecurringIntervalOptionsInput['label'],
      }));
  }
};
export const shiftRecurringFrequencyOptions = () => [
  {
    key: 'day',
    value: 'day',
    label: i18next.t('DAY'),
  },
  {
    key: 'week',
    value: 'week',
    label: i18next.t('WEEK'),
  },
  {
    key: 'month',
    value: 'month',
    label: i18next.t('MONTH'),
  },
];
