export const formatNumberWithOrdinalSuffix = (field: number): string => {
  const tenth = field % 10;
  const hundredth = field % 100;
  if (tenth === 1 && hundredth !== 11) {
    return field + 'st';
  }
  if (tenth === 2 && hundredth !== 12) {
    return field + 'nd';
  }
  if (tenth === 3 && hundredth !== 13) {
    return field + 'rd';
  }
  return field + 'th';
};
