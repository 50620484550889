import { CalendarPopUpWrapper } from 'components/BKJDatePicker/src/components/BKJCalendarPopUp/CalendarPopUp.styled';
import { Caption } from 'components/Typography';
import styled, { css } from 'styled-components';

export const ShiftDateCalendarInputDropdownWrapper = styled(CalendarPopUpWrapper)`
  box-shadow: none;
`;

export const CalendarInputWrapper = styled.div``;

export const Label = styled(Caption)`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const RequiredAsterisk = styled.label<{ disabled?: boolean }>`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.ErrorDefault};
  margin-bottom: 0;
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.TextDisabled};
    `}
`;
