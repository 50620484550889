import { FC, useEffect } from 'react';
import { QUERY_fetchUserLocale } from 'queries';
import { useGQLQuery } from 'hooks';
import { I18nextProvider as Provider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEng from 'locales/en/en_US.json';
import translationFre from 'locales/fr/fr-CA.json';
import authEng from 'locales/en/auth.json';
import authFre from 'locales/fr/auth.json';
import scheduleEng from 'locales/en/schedule.json';
import scheduleFre from 'locales/fr/schedule.json';
import dashboardEng from 'locales/en/dashboard.json';
import dashboardFre from 'locales/fr/dashboard.json';
import timesheetsEng from 'locales/en/timesheets.json';
import timesheetsFre from 'locales/fr/timesheets.json';
import agency_dashboardEng from 'locales/en/agency_Dashboard.json';
import agency_dashboardFre from 'locales/fr/agency_Dashboard.json';
import create_shiftsEng from 'locales/en/create_shifts.json';
import create_shiftsFre from 'locales/fr/create_shifts.json';
import shifts_calendarEng from 'locales/en/shifts_calendar.json';
import shifts_calendarFre from 'locales/fr/shifts_calendar.json';
import shift_modalEng from 'locales/en/shift_modal.json';
import shift_modalFre from 'locales/fr/shift_modal.json';
import settingsEng from 'locales/en/settings.json';
import settingsFre from 'locales/fr/settings.json';
import errorsEng from 'locales/en/errors.json';
import errorsFre from 'locales/fr/errors.json';
import location_settingsEng from 'locales/en/location_settings.json';
import location_settingsFre from 'locales/fr/location_settings.json';
import tab_navigationEng from 'locales/en/tab_navigation.json';
import tab_navigationFre from 'locales/fr/tab_navigation.json';
import successEng from 'locales/en/success.json';
import successFre from 'locales/fr/success.json';
import statusEng from 'locales/en/status.json';
import statusFre from 'locales/fr/status.json';
import navigationEng from 'locales/en/navigation.json';
import navigationFre from 'locales/fr/navigation.json';
import commonEng from 'locales/en/common.json';
import commonFre from 'locales/fr/common.json';
import team_memberEng from 'locales/en/team-member.json';
import team_memberFre from 'locales/fr/team-member.json';
import team_memberModalEng from 'locales/en/team-memberModal.json';
import team_memberModalFre from 'locales/fr/team-memberModal.json';
import dialogEng from 'locales/en/dialog.json';
import dialogFre from 'locales/fr/dialog.json';
import groupsEng from 'locales/en/groups.json';
import groupsFre from 'locales/fr/groups.json';
import sorting_optionsEng from 'locales/en/sorting_options.json';
import sorting_optionsFre from 'locales/fr/sorting_options.json';
import {
  fetchLocale_me_B2bCustomer,
  fetchLocale_me_AgencyUser,
  fetchLocale_me_SecondaryUser,
} from 'types/graphql-types';
import agency_memberEng from 'locales/en/agency-member.json';
import agency_memberFre from 'locales/fr/agency-member.json';
import communicationEng from 'locales/en/communication.json';
import communicationFre from 'locales/fr/communication.json';
import profile_settingsEng from 'locales/en/profile-settings.json';
import profile_settingsFre from 'locales/fr/profile-settings.json';
import empty_state_messagesEng from 'locales/en/empty-state-messages.json';
import empty_state_messagesFre from 'locales/fr/empty-state-messages.json';

const defaultLanguage = process.env.REACT_APP_WHITE_LABEL === 'petal' ? 'fr' : 'en';
i18n.use(initReactI18next).init({
  debug: false,
  lng: defaultLanguage,
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
  resources: {
    en: {
      translations: translationEng,
      auth: authEng,
      dashboard: dashboardEng,
      schedule: scheduleEng,
      create_shifts: create_shiftsEng,
      agency_dashboard: agency_dashboardEng,
      shifts_calendar: shifts_calendarEng,
      shift_modal: shift_modalEng,
      timesheets: timesheetsEng,
      settings: settingsEng,
      tab_navigation: tab_navigationEng,
      location_settings: location_settingsEng,
      errors: errorsEng,
      success: successEng,
      status: statusEng,
      navigation: navigationEng,
      common: commonEng,
      dialog: dialogEng,
      team_member: team_memberEng,
      team_memberModal: team_memberModalEng,
      groups: groupsEng,
      sorting_options: sorting_optionsEng,
      agency_member: agency_memberEng,
      empty_state_messages: empty_state_messagesEng,
      communication: communicationEng,
      profile_settings: profile_settingsEng,
    },
    fr: {
      translations: translationFre,
      auth: authFre,
      dashboard: dashboardFre,
      schedule: scheduleFre,
      create_shifts: create_shiftsFre,
      agency_dashboard: agency_dashboardFre,
      shifts_calendar: shifts_calendarFre,
      shift_modal: shift_modalFre,
      timesheets: timesheetsFre,
      settings: settingsFre,
      tab_navigation: tab_navigationFre,
      location_settings: location_settingsFre,
      errors: errorsFre,
      status: statusFre,
      success: successFre,
      navigation: navigationFre,
      common: commonFre,
      dialog: dialogFre,
      team_member: team_memberFre,
      team_memberModal: team_memberModalFre,
      groups: groupsFre,
      sorting_options: sorting_optionsFre,
      agency_member: agency_memberFre,
      communication: communicationFre,
      empty_state_messages: empty_state_messagesFre,
      profile_settings: profile_settingsFre,
    },
  },
  ns: [
    'translations',
    'auth',
    'dashboard',
    'schedule',
    'create_shifts',
    'agency_dashboard',
    'shifts_calendar',
    'shift_modal',
    'timesheets',
    'settings',
    'tab_navigation',
    'location_settings',
    'errors',
    'status',
    'success',
    'common',
    'team_member',
    'team_memberModal',
    'dialog',
    'navigation',
    'groups',
    'sorting_options',
    'agency_member',
    'communication',
    'empty_state_messages',
    'profile_settings',
  ], // Separate the namespaces with individual strings in an array
  defaultNS: 'translations',
  react: {
    useSuspense: true,
  },
});

interface fetchLocale {
  me: fetchLocale_me_B2bCustomer | fetchLocale_me_AgencyUser | fetchLocale_me_SecondaryUser;
}
export const I18NextProvider: FC = ({ children }): JSX.Element => {
  const { data, fetch } = useGQLQuery<fetchLocale>({
    key: 'FETCH_USER_LOCALE',
    query: QUERY_fetchUserLocale,
  });

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data?.me) {
      const newLanguage = data.me.locale || defaultLanguage;
      i18n.changeLanguage(newLanguage);
    }
  }, [data]);

  return <Provider i18n={i18n}>{children}</Provider>;
};
