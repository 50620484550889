import { DeleteCustomFieldView } from 'components/CustomFieldEditModal/views/DeleteCustomFieldView';
import { EditCustomFieldView } from 'components/CustomFieldEditModal/views/EditCustomFieldView';
import { EditFacilitiesView } from 'components/CustomFieldEditModal/views/EditFacilitiesView';
import i18next from 'i18next';

export const CustomFieldEditModalViewMap = {
  EditFacilitiesView,
  EditCustomFieldView,
  DeleteCustomFieldView,
};

export type CustomFieldEditModalViewType = keyof typeof CustomFieldEditModalViewMap;

export function getHeaderComponent(activeModalView: CustomFieldEditModalViewType) {
  switch (activeModalView) {
    case 'DeleteCustomFieldView':
      return i18next.t('settings:DELETE_CUSTOM_FIELD');
    case 'EditCustomFieldView':
    case 'EditFacilitiesView':
      return i18next.t('settings:EDIT_CUSTOM_FIELD');
  }
}
