import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { INVITE_BY } from 'constants/endpoints';

export const API_POST_inviteTeamMembers = (params: {
  communityId: string;
  inviteType: 'email' | 'phone';
  data: string[];
}): ApiRequestConfigType => {
  const url = INVITE_BY;
  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url,
    body: {
      invitations: {
        community_id: params.communityId,
        invite_type: params.inviteType,
        data: params.data,
      },
    },
  };
};
