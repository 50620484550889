import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';

export const DropdownWidth = '474px';

export const DropdownWrapper = styled.div`
  max-height: 228px;
  min-height: 38px;
  height: 228px;
  box-sizing: content-box;
  overflow-y: overlay;
  z-index: 1000;
  border: 1px solid ${({ theme }) => theme.colors.Grey6};
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${({ theme }) => theme.colors.White};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: -2px auto auto 77px !important;
  min-width: ${DropdownWidth};
  max-width: ${DropdownWidth};
  transform: translate3d(1034px, 248px, 0px);
`;

export const SearchTeamMembersGroupsWrapper = styled.div<{ isOpen: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.Grey6};
  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      `;
    } else {
      return css`
        border-radius: 8px;
      `;
    }
  }}
`;

export const CloseIconWrapper = styled.div`
  position: fixed;
  right: 50px;
`;

export const InfiniteScrollWrapper = styled.div`
  padding-top: 20px;
  background-color: white;
`;

export const JaneSelect = styled(Flex)<{ selected: boolean }>`
  padding-top: 4px;
  padding-bottom: 4px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey2};
      order: -1;
    `};
  &:hover {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
`;

export const NoResultsWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.Grey4};
  font-size: 14px;
  line-height: 24px;
  height: 38px;
  width: ${DropdownWidth};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`;
