import { B2B_VALIDATE_TIME_OFF } from 'constants/endpoints';
import { METHOD_GET } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export interface ITimeOffValidationsQuery {
  time_off: {
    id?: string;
    start_time?: string;
    end_time?: string;
    jane_id?: string;
  };
}

export function validateTimeOffValidationsQuery(
  timeOffValidationsQuery: ITimeOffValidationsQuery,
): boolean {
  const {
    time_off: { start_time, end_time, jane_id },
  } = timeOffValidationsQuery;
  return !!start_time && !!end_time && !!jane_id;
}

export const API_GET_timeOffValidations = (
  query: ITimeOffValidationsQuery,
): ApiRequestConfigType => ({
  __typename: 'LegacyAPI',
  method: METHOD_GET,
  url: B2B_VALIDATE_TIME_OFF,
  query,
});
