import { BKJButton } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const DeleteButton = styled(BKJButton)`
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  text-decoration: none;
`;
