import {
  defaultEndTime,
  defaultStartTime,
  timeOffModalRoutingService,
  titleOptions,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  VALIDATION_durationMin1Hr,
  VALIDATION_endDate,
  VALIDATION_without_msg,
} from 'components/TimeOffModal/TimeOffModalView.validation.constants';
import { createTimeOffPayloadTransformer } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.constants';
import { useCreateTimeOffViewDataContext } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.data.context';
import { ICreateTimeOffViewForm } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.types';
import { useFormBehaviors, useURLParameters } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';
import { DateTime } from 'luxon';

import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { API_POST_createTimeOff } from 'requests/POST_createTimeOff';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { selectedScheduleFacilityIdSelector } from 'store/selectors/scheduleSelectors/selectedScheduleFacilityIdSelector';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { combineMsgs } from 'validations/index.utils';

export type CreateTimeOffViewFormContextType = UseFormBehaviors<ICreateTimeOffViewForm>;

export const CreateTimeOffViewFormContext = createContext({} as CreateTimeOffViewFormContextType);

export const CreateTimeOffViewFormProvider: FC = (props) => {
  const { children } = props;
  const [{ date, jane_id }] =
    useURLParameters<{
      date: string;
      jane_id: string;
    }>();

  const selectedFacilityId: number = useSelector(selectedScheduleFacilityIdSelector);
  const { timeOffCustomFields } = useCreateTimeOffViewDataContext();
  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data) => {
      const {
        allDay,
        startDate,
        endDate,
        startTime,
        endTime,
        title,
        notes,
        urlDate,
        customFields,
        ...rest
      } = data;
      await apiRequest(
        API_POST_createTimeOff(
          createTimeOffPayloadTransformer({
            jane_id,
            allDay,
            startDate,
            endDate,
            startTime,
            endTime,
            title,
            notes,
            selectedFacilityId,
            customFields: { ...rest },
            timeOffCustomFields,
          }),
        ),
        {
          onSuccess: (res) => {
            toastService.success(t('success:TIME_OFF_CREATED'));
            window.scheduleUtils.forceDataUpdate();
            timeOffModalRoutingService.close();
          },
          onError: handleGenericErrorMessage,
        },
      );
    },
    [apiRequest, jane_id, selectedFacilityId, timeOffCustomFields, t],
  );

  interface State {
    notes: string;
    title: string[];
    urlDate: string;
    allDay: boolean;
    startDate: string[];
    startTime: string[];
    endDate: string[];
    endTime: string[];
  }
  interface CreateTimeOffViewFormType extends State {
    [key: string]: string | string[] | boolean | undefined;
  }
  const initialState: State = useMemo(() => {
    return {
      notes: '',
      title: [titleOptions[0].value],
      urlDate: date,
      allDay: true,
      startDate: [DateTime.fromISO(date).toString()],
      startTime: [defaultStartTime],
      endDate: [DateTime.fromISO(date).toString()],
      endTime: [defaultEndTime],
    };
  }, [date]);
  const initialStateWithCustomFields = useMemo(() => {
    if (!timeOffCustomFields) return initialState;

    const customFieldsState = timeOffCustomFields.reduce((acc, customField) => {
      const isDropdownAndPrefillDefault =
        customField.fieldType === 'dropdown' && customField.prefillDefault;
      const dropdownDefaultOptionValue = customField?.customFieldOptions?.length
        ? customField?.customFieldOptions[0].value
        : '';
      return {
        ...acc,
        [customField.name]: isDropdownAndPrefillDefault ? dropdownDefaultOptionValue : '',
      };
    }, {});

    return {
      ...initialState,
      ...customFieldsState,
    };
  }, [timeOffCustomFields, initialState]);

  const useFormBehaviorsOptions: UseFormBehaviors_Options<CreateTimeOffViewFormType> = {
    initialState: initialStateWithCustomFields,
    onSubmit: handleSubmit,
    validations: {
      startDate: VALIDATION_without_msg,
      startTime: VALIDATION_without_msg,
      endDate: VALIDATION_endDate,
      endTime: combineMsgs(VALIDATION_without_msg, VALIDATION_durationMin1Hr),
    },
  } as unknown as UseFormBehaviors_Options<CreateTimeOffViewFormType>;

  const form = useFormBehaviors<ICreateTimeOffViewForm>(useFormBehaviorsOptions);

  const value = useMemo(
    () => ({
      ...form,
    }),
    [form],
  );

  return (
    <CreateTimeOffViewFormContext.Provider value={value}>
      {children}
    </CreateTimeOffViewFormContext.Provider>
  );
};

export const useCreateTimeOffViewFormContext = (): CreateTimeOffViewFormContextType => {
  return useContext(CreateTimeOffViewFormContext);
};
