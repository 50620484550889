import {
  BKJEllipsisMenu,
  BKJLoadingSpinner,
  BKJMenuItem,
} from '@bookjane2/bookjane-design-library';
import { ShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { useTranslation } from 'hooks/useTranslation';
import {
  TableHeaderColumn,
  TableHeaderRow,
} from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/index.styled';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { GET_shiftModalTeamMemberAvailability } from 'requests/GET_shiftModalTeamMemberAvailability';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { saveResponseAsCSV } from 'utils/saveResponseAsCSV';

export const AvailableMemberTabTableHeader = () => {
  const { shift_id } = useContext(ShiftModalUIContext);
  const requestOptions = GET_shiftModalTeamMemberAvailability({
    shift_id: `${shift_id}`,
    data: 'csv',
  });
  const { apiRequest } = useAPIActions();
  const { isPending } = useSelector((state) => apiDataSelector(state, requestOptions));

  const exportAvailableTeamMembersCSV = useCallback(() => {
    apiRequest(requestOptions, {
      onSuccess: (response) => {
        saveResponseAsCSV(
          response,
          `team-member-availability-shift-#${shift_id}-${Date.now()}.csv`,
        );
      },
    });
  }, [apiRequest, requestOptions, shift_id]);
  const { t, i18n } = useTranslation();
  const tableColumnStyle = i18n.language === 'en' ? 'nowrap' : 'normal';

  return (
    <TableHeaderRow>
      <TableHeaderColumn style={{ whiteSpace: tableColumnStyle }}>
        {t('shift_modal:SHIFT_SENT_AT')}
      </TableHeaderColumn>
      <TableHeaderColumn css="margin-left: 42px;" style={{ whiteSpace: tableColumnStyle }}>
        {t('shift_modal:TEAM_MEMBERS')}
      </TableHeaderColumn>
      <TableHeaderColumn></TableHeaderColumn>
      <TableHeaderColumn>
        <BKJEllipsisMenu>
          <BKJMenuItem onClick={exportAvailableTeamMembersCSV}>
            {isPending ? <BKJLoadingSpinner variant="XSmall" /> : t('shift_modal:EXPORT_CSV')}
          </BKJMenuItem>
        </BKJEllipsisMenu>
      </TableHeaderColumn>
    </TableHeaderRow>
  );
};
