import { CustomFieldInfoFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import i18next from 'i18next';
import { ICustomField } from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettings.types';

export const VALIDATION_fieldName =
  (customFieldList: ICustomField[]) =>
  (value: CustomFieldInfoFormSchema[keyof CustomFieldInfoFormSchema]): string => {
    const fieldName = value as string;
    if (fieldName.trim() === '') return i18next.t('auth:PLEASE_ENTER_FIELD_NAME');
    if (fieldName.length > 50) return i18next.t('auth:MAX_CHARACTER_LIMIT_ERROR');
    if (
      customFieldList
        .map((customField) => customField.name.toLowerCase())
        .includes(fieldName.toLowerCase())
    )
      return i18next.t('auth:FIELD_NAME_ALREADY_EXIST');
    return '';
  };
