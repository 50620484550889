import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { QUERY_settingsGroups } from 'queries';

export const fetchGroupsConfig = (locationId: number): ISSFBehaviorsOptions => {
  return {
    key: 'FETCH_GROUPS_QUERY',
    query: QUERY_settingsGroups,
    pageSize: 10,
    schema: {
      locationId: {
        initialValue: locationId,
      },
    },
  };
};
