import { BKJIcon, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  WarningWrapper,
  WarningText,
} from 'components/ShiftModal/src/views/ReleaseShiftView/ReleaseShiftView.styled';
import { useTranslation } from 'react-i18next';
import { OrderStatusEnum } from 'types/graphql-types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { DateTime } from 'luxon';

export const ReleaseShiftViewWarningComponent = () => {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { startAt, mainJane, status } = data.fetchOrder!;
  const isShiftInProgress = status?.value === OrderStatusEnum.in_progress;
  const isAgencyJane = !!mainJane?.agencyCommunity;

  const { timeZone } = getCurrentlySelectedLocation();

  const diff = DateTime.fromISO(startAt)
    .setZone(timeZone)
    .diff(DateTime.now().setZone(timeZone), 'hour').hours;
  if (!isShiftInProgress && (diff > 12 || !isAgencyJane)) return null;

  const warningMessage = isShiftInProgress
    ? t('shift_modal:RELEASE_SHIFT_IN_PROGRESS_WARNING')
    : t('shift_modal:RELEASE_SHIFT_WITHIN_12_HOURS');
  return (
    <WarningWrapper>
      <Flex>
        <BKJIcon iconName="Warning" />
      </Flex>
      <Flex>
        <WarningText>{warningMessage}</WarningText>
      </Flex>
    </WarningWrapper>
  );
};

export const ReleaseShiftViewWarning = () => (
  <ErrorBoundary
    componentName="ReleaseShiftViewWarning"
    showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
  >
    <ReleaseShiftViewWarningComponent />
  </ErrorBoundary>
);
