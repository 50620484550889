import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isMultiLocationCallOutFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    if (userPermissions?.MULTI_LOCATION_CALLOUT?.access)
      return (
        userPermissions?.MULTI_LOCATION_CALLOUT?.access?.includes('read') &&
        !!userPermissions.MULTI_LOCATION_CALLOUT.communities &&
        !!userPermissions.MULTI_LOCATION_CALLOUT.communities[communityId] &&
        !!userPermissions.MULTI_LOCATION_CALLOUT.communities[communityId]?.includes('read')
      );
    else return false;
  });
