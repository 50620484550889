import { useFormBehaviors } from 'hooks';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useCompanyProfilePageDataContext } from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.context';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { METHOD_PUT } from 'requests';
import { AGENCY_PATH, LIABILITY_INSURANCE_PATH } from 'constants/endpoints';
import { CompanyProfilePageForm } from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.constants';
import { useTranslation } from 'hooks/useTranslation';
import { ApiRequestConfigType } from 'requests/index.types';

export const CompanyProfilePageFormContext = createContext(
  {} as UseFormBehaviors<CompanyProfilePageForm>,
);

export const CompanyProfilePageFormProvider: FC = ({ children }) => {
  const { data } = useCompanyProfilePageDataContext();
  const { apiRequestSeries } = useAPIActions();
  const { t } = useTranslation();
  const handleSubmit = useCallback(
    async (formValues) => {
      const liability = data?.me?.agency?.liability;

      const apiRequests: ApiRequestConfigType[] = [];

      if (!!liability?.id) {
        apiRequests.push({
          __typename: 'LegacyAPI',
          method: METHOD_PUT,
          url: `${LIABILITY_INSURANCE_PATH}/${Number(liability.id)}`,
          body: {
            liability: {
              licence_number: formValues.licence_number,
            },
          },
        });
      }

      if (!!data?.me?.agency?.id) {
        apiRequests.push({
          __typename: 'LegacyAPI',
          method: METHOD_PUT,
          url: `${AGENCY_PATH}/${data.me.agency.id}`,
          body: {
            agencies_attributes: {
              name: formValues.company_name,
              liability_attributes: !liability?.id
                ? {
                    licence_number: formValues.licence_number,
                  }
                : undefined,
            },
          },
        });
      }
      if (apiRequests.length) {
        apiRequestSeries(apiRequests, {
          onSuccess: () => toastService.success(t('success:CHANGES_SUCCESSFULLY_SAVED')),
          onError: handleGenericErrorMessage as () => void,
        });
      }
    },
    [apiRequestSeries, data.me.agency.id, data.me.agency?.liability, t],
  );

  const form = useFormBehaviors<CompanyProfilePageForm>({
    initialState: {
      company_name: data.me?.agency?.name,
      licence_number: data.me?.agency?.liability?.licenceNumber || '',
    },
    onSubmit: handleSubmit,
  });

  const contextValue = useMemo(() => form, [form]);

  return (
    <CompanyProfilePageFormContext.Provider value={contextValue}>
      {children}
    </CompanyProfilePageFormContext.Provider>
  );
};

export const useCompanyProfilePageFormContext = () => useContext(CompanyProfilePageFormContext);
