import { useURLParameters } from 'hooks';
import { createContext, useCallback } from 'react';
import {
  IInviteTeamMembersModalContext,
  InviteTeamMembersModalViewType,
} from './InviteTeamMembersModal.types';

const initialState: IInviteTeamMembersModalContext = {
  isOpen: false,
  onClose: () => null,
  activeView: 'SMSView',
};

export const InviteTeamMembersModalContext =
  createContext<IInviteTeamMembersModalContext>(initialState);

export const InviteTeamMembersModalProvider: React.FC = ({ children }) => {
  const [{ inviteTeamMembersModal: activeView }, { removeParam }] = useURLParameters();

  const handleClose = useCallback(() => {
    removeParam('inviteTeamMembersModal');
  }, [removeParam]);

  if (!activeView) return null;
  return (
    <InviteTeamMembersModalContext.Provider
      value={{
        isOpen: !!activeView,
        onClose: handleClose,
        activeView: `${activeView}View` as InviteTeamMembersModalViewType,
      }}
    >
      {children}
    </InviteTeamMembersModalContext.Provider>
  );
};
