import createCachedSelector from 're-reselect';
import { initialFacilityViewState } from 'store/reducers/scheduleSlice/index.initialState';
import { generateViewKey } from 'store/reducers/scheduleSlice/index.utils';

const getSchedule = (state) => state.schedule;
const getSelectedFacilityId = (state) => state.schedule.selectedFacilityId;

const scheduleFacilityViewStateSelector = createCachedSelector(
  getSelectedFacilityId,
  getSchedule,
  (selectedFacilityId, schedule) => {
    const facilityViewStateKey = generateViewKey(selectedFacilityId);
    if (!schedule[facilityViewStateKey]) return initialFacilityViewState;
    return schedule[facilityViewStateKey];
  },
)((state) => {
  const selectedFacilityId = getSelectedFacilityId(state);
  const schedule = getSchedule(state);

  const facilityViewStateKey = generateViewKey(selectedFacilityId);
  if (!schedule[facilityViewStateKey]) return JSON.stringify(initialFacilityViewState);
  return JSON.stringify(schedule[facilityViewStateKey]);
});

export default scheduleFacilityViewStateSelector;
