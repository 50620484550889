import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSSFBehaviors } from 'hooks';
import { SSFBehaviorsContextType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { QUERY_sendToAgenciesSummaryViewTeamMembersCount } from 'queries';
import { FC, useContext, useMemo } from 'react';
import { sendToAgenciesSummaryViewTeamMembersCount } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { getShiftStartTimeAndEndTime } from 'utils/time';

export const FetchAvailableTeamMembersCountDataContext = createGQLDataContext<
  SSFBehaviorsContextType<sendToAgenciesSummaryViewTeamMembersCount>
>({} as SSFBehaviorsContextType<sendToAgenciesSummaryViewTeamMembersCount>);

export const FetchAvailableTeamMembersCountDataProvider: FC = ({ children }) => {
  const { data: fetchOrderData } = useShiftModalDataContext();
  const { values: formValues } = useSendToAgenciesFormContext();
  const {
    fetchOrder: { startAt, endAt, startDate },
  } = fetchOrderData;

  const [byStartTime, byEndTime] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
    startDate,
  });

  const {
    locationFilter: { initialValue: facilityId },
  } = useAuthRouteDataContext();

  const [values, rest] = useSSFBehaviors({
    key: 'SEND_TO_AGENCIES_SUMMARY_VIEW_FETCH_AVAILABLE_TEAM_MEMBERS_COUNT',
    query: QUERY_sendToAgenciesSummaryViewTeamMembersCount,
    payloadTransformer: (variables) => ({
      ...variables,
      filter: {
        belongingToTeam: variables.isDedicated
          ? {
              forPositions: [variables.positionId],
              inCommunities: [variables.communityId],
            }
          : undefined,
      },
    }),
    schema: {
      orderId: {
        initialValue: fetchOrderData.fetchOrder!.id,
      },
      isDedicated: {
        initialValue: formValues.isDedicated,
      },
      positionId: {
        initialValue: fetchOrderData.fetchOrder!.position.id,
      },
      communityId: {
        initialValue: fetchOrderData.fetchOrder!.community.id,
      },
      byFacilityId: {
        initialValue: Number(facilityId.value),
      },
      byStartTime: {
        initialValue: byStartTime,
      },
      byEndTime: {
        initialValue: byEndTime,
      },
    },
  });

  const contextValue = useMemo(() => ({ values, ...rest }), [rest, values]);

  return (
    <FetchAvailableTeamMembersCountDataContext.Provider value={contextValue}>
      {children}
    </FetchAvailableTeamMembersCountDataContext.Provider>
  );
};

export const useFetchAvailableTeamMembersCountDataContext =
  (): SSFBehaviorsContextType<sendToAgenciesSummaryViewTeamMembersCount> =>
    useContext(FetchAvailableTeamMembersCountDataContext);
