import { FC, Fragment, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import {
  searchTeamMembersForGroup,
  searchTeamMembersForGroup_fetchJanes_edges_node,
  searchTeamMembersForGroup_fetchJanes_edges_node_staticGroups,
} from 'types/graphql-types';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import {
  NoResultsWrapper,
  InfiniteScrollWrapper,
} from 'pages/SettingsPage/modals/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.styled';
import { useTranslation } from 'hooks/useTranslation';
import { TeamMemberOption } from 'pages/SettingsPage/modals/GroupsModal/components/SearchTeamMembersGroups/src/TeamMemberOption';
import { SearchTeamMembersGroupsContext } from 'pages/SettingsPage/modals/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.context';
import { useGroupsModalFormContext } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.form.context';
import { isMultiLocationCallOutFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { Flex } from 'components/Flex';

interface ITeamMembersListProps {
  groupMemberIds: string[];
  data: searchTeamMembersForGroup;
  value: string;
  name: string;
  onSelect: (e: unknown) => void;
  onLoadMore: () => void;
}

export const TeamMembersList: FC<ITeamMembersListProps> = ({
  groupMemberIds,
  data,
  value,
  name,
  onSelect,
  onLoadMore,
}) => {
  const { values: queryContextValues } = useContext(SearchTeamMembersGroupsContext);

  const { deletedMemberIds } = useGroupsModalFormContext();
  const { t } = useTranslation();
  const currentlySelectedLocation = getCurrentlySelectedLocation();
  const isMultiLocationCallOutFeatureEnabled = useSelector(
    isMultiLocationCallOutFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );
  const isInStaticGroup = useCallback(
    (staticGroups: searchTeamMembersForGroup_fetchJanes_edges_node_staticGroups[]) =>
      staticGroups
        .map((group) => group.displayId)
        .includes(queryContextValues.groupsFilter.withId[0]),
    [queryContextValues.groupsFilter.withId],
  );
  const isChecked = useCallback(
    (node: searchTeamMembersForGroup_fetchJanes_edges_node) =>
      groupMemberIds.includes(node.id) ||
      (!deletedMemberIds.has(node.id) && isInStaticGroup(node.staticGroups)),
    [deletedMemberIds, groupMemberIds, isInStaticGroup],
  );

  if (!data || (data?.fetchJanes?.edges && data?.fetchJanes?.edges.length === 0))
    return <NoResultsWrapper>{t('common:NO_RESULTS')}</NoResultsWrapper>;

  return (
    <Fragment>
      {data?.fetchJanes?.edges &&
        data.fetchJanes.edges.map(({ node }) => {
          if (!node) return <Fragment />;
          const isSharedJanePrimaryCommunityDifferentFromSelectedLocation =
            !!isMultiLocationCallOutFeatureEnabled &&
            !node?.agencyCommunity &&
            node?.primaryCommunity?.id !== currentlySelectedLocation?.value?.toString();

          return (
            <Flex alignItems="center">
              <TeamMemberOption
                isChecked={isChecked(node)}
                key={node.id}
                node={node as searchTeamMembersForGroup_fetchJanes_edges_node}
                value={value}
                name={name}
                onSelect={onSelect}
              />
              {isSharedJanePrimaryCommunityDifferentFromSelectedLocation && (
                <BKJIcon iconName="Group2" width={16} height={14} />
              )}
            </Flex>
          );
        })}
      {!!data?.fetchJanes?.pageInfo.hasNextPage ? (
        <InfiniteScrollWrapper>
          <InfiniteScrollLoading onViewportEnter={onLoadMore} />
        </InfiniteScrollWrapper>
      ) : null}
    </Fragment>
  );
};
