export enum EnvironmentType {
  sandbox1 = 'sandbox1',
  sandbox2 = 'sandbox2',
  staging = 'staging',
  'demo-petal' = 'demo-petal',
  'staging-petal' = 'staging-petal',
  petal = 'petal',
  'demo-accushield' = 'demo-accushield',
  demo = 'demo',
  accushield = 'accushield',
  development = 'development',
  e2etest = 'e2etest',
  localhost = 'localhost',
  jest = 'jest',
  test = 'test',
  master = 'master',
  default = 'default',
}
export type BKJEnvType = keyof typeof EnvironmentType;

export type EnvironmentConfigType = Record<
  BKJEnvType,
  {
    DEFAULT_BASE_URL: string;
    UK_BASE_URL: string;
    CYPRESS_BASE_URL: string;
    SOCKETURL: string;
    ROCKET_ID: string;
    ADP_REDIRECT_URI: string;
    ADP_PARTNER_CLIENT_ID_USA: string;
    ADP_PARTNER_CLIENT_ID_CA: string;
    BASE_AUTH_URL: string;
  }
>;

export const environments: EnvironmentConfigType = {
  default: {
    BASE_AUTH_URL: 'https://sandbox1-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://sandbox1.bookjane.com',
    UK_BASE_URL: 'https://sandbox1.bookjane.com',
    CYPRESS_BASE_URL: 'https://sandbox1-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://sandbox1-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  test: {
    BASE_AUTH_URL: 'https://sandbox1-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://sandbox.bookjane.com',
    UK_BASE_URL: 'https://sandbox.bookjane.com',
    CYPRESS_BASE_URL: 'https://sandbox-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://sandbox1-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  jest: {
    BASE_AUTH_URL: 'https://sandbox1-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://sandbox.bookjane.com',
    UK_BASE_URL: 'https://sandbox.bookjane.com',
    CYPRESS_BASE_URL: 'https://sandbox-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://sandbox1-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  e2etest: {
    BASE_AUTH_URL: 'https://sandbox1-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://e2etest.bookjane.com/',
    UK_BASE_URL: 'https://e2etest.bookjane.com/',
    CYPRESS_BASE_URL: 'https://e2etest-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://e2etest-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  sandbox1: {
    BASE_AUTH_URL: 'https://sandbox1-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://sandbox1.bookjane.com',
    UK_BASE_URL: 'https://sandbox1.bookjane.com',
    CYPRESS_BASE_URL: 'https://sandbox1-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://sandbox1-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  sandbox2: {
    BASE_AUTH_URL: 'https://sandbox2-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://sandbox2.bookjane.com',
    UK_BASE_URL: 'https://sandbox2.bookjane.com',
    CYPRESS_BASE_URL: 'https://sandbox2-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://sandbox2-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  localhost: {
    BASE_AUTH_URL: '',
    DEFAULT_BASE_URL: 'http://localhost:3000',
    UK_BASE_URL: 'http://localhost:3000',
    CYPRESS_BASE_URL: 'http://localhost:3000',
    SOCKETURL: 'https://tobox-node-dev.bookjane.com:5000',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'http://localhost:3000',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '52abfa73-a6c4-4390-b96a-348981a55914',
  },
  development: {
    BASE_AUTH_URL: '',
    DEFAULT_BASE_URL: 'https://deva.bookjane.com',
    UK_BASE_URL: 'https://deva.bookjane.com',
    CYPRESS_BASE_URL: 'https://dev-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: '',
    ADP_REDIRECT_URI: 'https://dev-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: 'd4c66d26-03e3-40d5-91f1-00f6c2bce833',
  },
  demo: {
    BASE_AUTH_URL: 'https://demo-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://demo.bookjane.com',
    UK_BASE_URL: 'https://demo-uk.bookjane.com',
    CYPRESS_BASE_URL: 'https://demo-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-demo',
    ADP_REDIRECT_URI: 'https://demo-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  'demo-accushield': {
    BASE_AUTH_URL: 'https://demo-authflex.accushield.com',
    DEFAULT_BASE_URL: 'https://demo-apiflex.accushield.com',
    UK_BASE_URL: 'https://demo-apiflex-uk.accushield.com',
    CYPRESS_BASE_URL: 'https://demo-flex.accushield.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-demo',
    ADP_REDIRECT_URI: 'https://demo-flex.accushield.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  accushield: {
    BASE_AUTH_URL: 'https://authflex.accushield.com',
    DEFAULT_BASE_URL: 'https://apiflex.accushield.com',
    UK_BASE_URL: 'https://apiflex-uk.accushield.com',
    CYPRESS_BASE_URL: 'https://flex.accushield.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-prod',
    ADP_REDIRECT_URI: 'https://flex.accushield.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  staging: {
    BASE_AUTH_URL: 'https://staging-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://staging.bookjane.com',
    UK_BASE_URL: 'https://staging-uk.bookjane.com',
    CYPRESS_BASE_URL: 'https://staging-ui.bookjane.com',
    SOCKETURL: 'https://sockets-staging.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-dev',
    ADP_REDIRECT_URI: 'https://staging-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  'demo-petal': {
    BASE_AUTH_URL: 'https://demo-petal-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://demo-petal.bookjane.com',
    UK_BASE_URL: 'https://demo-petal-uk.bookjane.com',
    CYPRESS_BASE_URL: 'https://demo-petal-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-demo',
    ADP_REDIRECT_URI: 'https://demo-petal-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  'staging-petal': {
    BASE_AUTH_URL: 'https://staging-petal-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://staging-petal.bookjane.com',
    UK_BASE_URL: 'https://staging-petal-uk.bookjane.com',
    CYPRESS_BASE_URL: 'https://staging-petal-ui.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-dev',
    ADP_REDIRECT_URI: 'https://staging-petal-ui.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  petal: {
    BASE_AUTH_URL: 'https://petal-auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://petal.bookjane.com',
    UK_BASE_URL: 'https://petal-uk.bookjane.com',
    CYPRESS_BASE_URL: 'https://petal.bookjane.com',
    SOCKETURL: 'https://sockets-sandbox.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-prod',
    ADP_REDIRECT_URI: 'https://petal.bookjane.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: '17bd11a9-3aaa-4b98-9b46-a2a37d7603de',
  },
  master: {
    BASE_AUTH_URL: 'https://auth.bookjane.com',
    DEFAULT_BASE_URL: 'https://apiv2.bookjane.com',
    UK_BASE_URL: 'https://uk.bookjane.com',
    CYPRESS_BASE_URL: 'https://apiv2-ui.bookjane.com',
    SOCKETURL: 'https://sockets.bookjane.com:5000/',
    ROCKET_ID: 'ouyzqc/bookjane-j360-prod',
    ADP_REDIRECT_URI: 'https://bookjanej360.com',
    ADP_PARTNER_CLIENT_ID_USA: '47661d6d-75bd-4adf-b5c0-a360b6fc7744',
    ADP_PARTNER_CLIENT_ID_CA: 'd4c66d26-03e3-40d5-91f1-00f6c2bce833',
  },
};
