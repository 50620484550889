import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IGlobalReduxStore } from 'store';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

const getAuth = (state: IGlobalReduxStore) => state.auth;

export const authSelector = createSelector(getAuth, (auth) => auth);

export const userSelector = createCachedSelector(
  getUser,
  (user) => user,
)((store) => {
  if (!store.auth.user) return 'NO_SESSION';

  return store.auth.user.id;
});

export const useUserSelector = () => useSelector(userSelector);

export const useUserIdSelector = () => useSelector(userSelector)?.id;

export const useUserAgencyIdSelector = () => useSelector(userSelector)?.agency_id;
