import { BKJTableData } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';

import styled from 'styled-components';

export const ShiftsTableData = styled(BKJTableData)``;

export const Text = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText}
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const Recurring = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  display: flex;
`;

export const DateText = styled(Text)`
  white-space: nowrap;
`;

export const Label = styled.label`
  display: none;
`;
export const FacilityName = styled.label`
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  height: 21px;
  max-height: 21px;
  min-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const JaneName = styled.label`
  ${({ theme }) => theme.fonts.PoppinsText};
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  height: 24px;
  max-height: 24px;
  min-height: 24px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const PremiumJaneName = styled(JaneName)`
  margin-bottom: 0;
`;
