import styled from 'styled-components';
import React from 'react';

export const BigModalText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  text-align: left;
  color: #45424e;
  margin-bottom: 33px;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  margin: auto;
`;
export const ShiftCount = styled.span``;

export const PublishModalCheckGroupWrapper = styled.div`
  margin-left: 35px;
`;

export const ModalFormGroup = styled.div`
  margin-bottom: 32px;
  img {
    margin-right: 5px;
  }
`;

export const HeaderText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  text-align: left;
  color: #45424e;
  margin-bottom: 4px;
`;

export const HeaderShiftText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #45424e;
  margin-top: 8px;
  margin-bottom: 20px;
`;

export const HeaderLabel = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: left;
  color: #45424e;
`;
//temporarily updated css for petal demo
export const TableRow = styled.div`
  grid-template-columns: 2fr 100px 140px 100px;
  display: grid;
`;
export const TableRowLabel = styled(TableRow)`
  font-size: 12px;
  ${({ theme }) => theme.fonts.PoppinsText};
  margin-bottom: 13px;
`;
export const TableRowTotal = styled(TableRow)`
  background-color: #1c0f51;
  color: white;
  height: 40px;
  font-size: 16px;
  ${({ theme }) => theme.fonts.PoppinsText};
`;

export const TableDataRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: #f6f7fb;
  }
  font-size: 16px;
  ${({ theme }) => theme.fonts.PoppinsText};
  border-bottom: 1px solid rgba(139, 150, 163, 0.6);
  padding-top: 22px;
  padding-bottom: 22px;
`;
export const TableColumn = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TableColumnHead = styled.div`
  align-items: center;
  display: flex;
  padding-left: 12px;
`;
export const TableDataColumnHead = styled(TableColumnHead)``;
export const TableDataColumn = styled(TableColumn)``;

export const Table = styled.div``;

export const ConflictsAndWarningWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 27px;
`;

export const WarningText = styled.div`
  display: grid;
  color: #45424e;
  font-size: 14px;
  ${({ theme }) => theme.fonts.PoppinsMedium};
  margin-top: -4px;
`;

export const ConflictsText = styled(WarningText)`
  color: #ce1037;
`;

export const ConflictsAndWarningLinkText = styled.div`
  font-size: 16px;
  ${({ theme }) => theme.fonts.PoppinsBold};
  color: #8080ba;
  cursor: pointer;
`;

export function WarningIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 20 20">
      <path fill="#ED9D52" d="M0 0h20v20H0z" />
      <path
        fill="#45424E"
        d="M9.5 12.828l-.142-7.543h1.914l-.143 7.543H9.501zm.815.914c.295 0 .543.1.743.3.2.2.3.448.3.743s-.1.543-.3.743c-.2.2-.448.3-.743.3s-.548-.1-.757-.3c-.2-.2-.3-.448-.3-.743s.1-.543.3-.743c.21-.2.462-.3.757-.3z"
      />
    </svg>
  );
}
export function ConflictsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 20 20">
      <path fill="#CE1037" d="M0 0h20v20H0z" />
      <path
        fill="#fff"
        d="M9.5 12.828l-.142-7.543h1.914l-.143 7.543H9.501zm.815.914c.295 0 .543.1.743.3.2.2.3.448.3.743s-.1.543-.3.743c-.2.2-.448.3-.743.3s-.548-.1-.757-.3c-.2-.2-.3-.448-.3-.743s.1-.543.3-.743c.21-.2.462-.3.757-.3z"
      />
    </svg>
  );
}
