import { AvailableTeamMembersProps } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/AvailableTeamMember/AvailableTeamMembers.types';
import {
  HideOnMobile,
  IconWithMarginRight,
  ShiftCardButton,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.styled';
import { FC, Fragment, useContext } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { formatPlural } from 'utils/formatPlural';
import { CreateShiftCardContext } from '../CreateShiftCard.context';
import { isCurrentLanguageFrench } from 'providers/i18NextProvider.constants';

export const AvailableTeamMembers: FC<AvailableTeamMembersProps> = (props) => {
  const { t } = useTranslation();
  const { createShift } = useContext(CreateShiftCardContext);
  const { count, onClick } = props;
  const {
    values: { request_janes = '', startDate },
  } = createShift;

  const requestedTeamMembersCount = request_janes.split(',').filter((id) => id !== '').length;

  if (count === undefined || startDate === '') return <Fragment />;
  return (
    <ShiftCardButton onClick={onClick} variant="PurpleNoDecorations">
      <IconWithMarginRight iconName="Search" color="PrimaryClick" />
      {requestedTeamMembersCount === 0 ? (
        <Fragment>
          {count}{' '}
          {isCurrentLanguageFrench() ? (
            <Fragment>
              {t('common:TEAM_MEMBERS')}
              <HideOnMobile>&nbsp;{t('common:MEMBER_AVAILABLE')}&nbsp;</HideOnMobile>
            </Fragment>
          ) : (
            <Fragment>
              <HideOnMobile>&nbsp;{t('common:MEMBER_AVAILABLE')}&nbsp;</HideOnMobile>
              {t('common:TEAM_MEMBERS')}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {requestedTeamMembersCount}&nbsp;
          <HideOnMobile>&nbsp;{t('common:REQUESTED')}&nbsp;</HideOnMobile>
          {`${t('create_shifts:TEAM')} ${formatPlural(
            t('create_shifts:MEMBER'),
            requestedTeamMembersCount,
          )}`}
        </Fragment>
      )}
    </ShiftCardButton>
  );
};
