import { Flex } from 'components/Flex';
import { Fragment } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSelector } from 'react-redux';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useDetailTabViewPropsContext } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.props.context';
import { useURLParameters } from 'hooks';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { WebDetailViewFooter } from 'components/ShiftModal/src/views/DetailView/WebDetailViewFooter';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { MobileDetailViewFooter } from 'components/ShiftModal/src/views/DetailView/MobileDetailViewFooter';
import { IDetailViewFooterProps } from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export function DetailViewFooter() {
  const { data } = useShiftModalDataContext();

  const { goToDeleteView, goToEditView, goToReleaseShiftView, goToSendToAgenciesView } =
    useShiftModalPropsContext();

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [{ agencyCommunityId }] = useURLParameters<{ agencyCommunityId?: string }>();

  const { openConfirmAvailabilityModal } = useDetailTabViewPropsContext();
  const { isMobile } = useResponsive();
  const Footer = isMobile ? MobileDetailViewFooter : WebDetailViewFooter;

  const props: IDetailViewFooterProps = {
    data,
    openConfirmAvailabilityModal,
    agencyCommunityId,
    isUserAgencyAndAgencyLocationFilterForcedDisabled,
    goToSendToAgenciesView,
    goToReleaseShiftView,
    goToEditView,
    goToDeleteView,
  };

  if (isReadOnly) return <Fragment />;

  return (
    <Flex justifyContent="space-between" width="100%">
      <Footer {...props} />
    </Flex>
  );
}
