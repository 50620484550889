import { BKJListItem, BKJSelect } from '@bookjane2/bookjane-design-library';

import {
  IShiftRecurringFrequencySelectInput,
  IShiftRecurringIntervalSelectInputRenderGroupProps,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/CreateShiftModal.types';
import { shiftRecurringFrequencyOptions } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/RecurringView/RecurringView.constants';
import { FC, forwardRef, Fragment } from 'react';

const RenderGroup = ({
  name,
  selected,
  options,
  onChange,
}: IShiftRecurringIntervalSelectInputRenderGroupProps) => {
  return (
    <Fragment>
      {options.map(({ key, label, value }) => {
        return (
          <BKJListItem
            key={key}
            value={value}
            label={label}
            name={name}
            onChange={onChange}
            selected={selected}
          />
        );
      })}
    </Fragment>
  );
};

export const ShiftRecurringFrequencySelectInput: FC<IShiftRecurringFrequencySelectInput> =
  forwardRef(({ name, variant = 'GreyOutlined', width, value, onChange, ...props }, ref) => {
    const translatedShiftRecurringFrequencyOptions = shiftRecurringFrequencyOptions();
    return (
      <BKJSelect
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(props as any)}
        ref={ref}
        options={translatedShiftRecurringFrequencyOptions}
        type="Single"
        variant={variant}
        width={width}
        name={name}
        value={value}
        onChange={onChange}
        renderOptions={RenderGroup}
      />
    );
  });
