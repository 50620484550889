import styled from 'styled-components';

import { calculateColor } from 'assets/scheduling/kulers';

export const H1 = styled.h1`
  font-family: 'Poppins SemiBold' !important;
  font-size: 70px;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const H2 = styled.h2`
  font-family: 'Poppins SemiBold' !important;
  font-size: 45px;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;
export const H3 = styled.h3`
  font-family: 'Poppins SemiBold' !important;
  font-size: 28px;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;
export const H4 = styled.h4`
  font-family: 'Poppins SemiBold' !important;
  font-size: 24px;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const H5 = styled.h5`
  font-family: 'Poppins SemiBold' !important;
  font-size: 20px;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const H6 = styled.h6`
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const BodyBold = styled.p`
  font-family: 'Poppins SemiBold' !important;
  font-size: ${(props) => props.size || '20px'};
  font-weight: bold;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
  margin: 0 0 12px 0;
  padding: 0;
`;

export const BodyBoldText = styled.p`
  font-family: 'Poppins SemiBold' !important;
  font-size: ${(props) => props.size || '16px'};
  font-weight: bold;
  color: ${(props) => calculateColor(props.color) || '#45424e'};
  margin: 0 0 12px 0;
  padding: 0;
`;

export const BodyL = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5;
  font-size: 20px;
  font-weight: ${(props) => props.weight || 'lighter'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
  margin: 0 0 12px 0;
  padding: 0;
`;

export const BodyM = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5;
  font-size: 16px;
  font-weight: ${(props) => props.weight || 'lighter'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
  margin: 0 0 12px 0;
  padding: 0;
`;

export const BodyS = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5;
  font-size: 14px;
  font-weight: ${(props) => props.weight || 'lighter'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
  margin: 0 0 12px 0;
  padding: 0;
`;

export const BodyXS = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5;
  font-size: 12px;
  font-weight: ${(props) => props.weight || 'lighter'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
  margin: 0 0 12px 0;
  padding: 0;
`;

export const BodyTextL = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5 !important;
  font-size: 20px;
  margin: 0;
  font-weight: ${(props) => props.weight || 'bold'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const BodyTextM = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5 !important;
  font-size: 16px;
  margin: 0;
  font-weight: ${(props) => props.weight || 'bold'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const BodyTextS = styled.p`
  font-family: 'Poppins Medium' !important;
  line-height: 1.5 !important;
  font-size: 14px;
  margin: 0;
  font-weight: ${(props) => props.weight || 'bold'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const BodyTextXS = styled.p`
  font-family: 'Poppins SemiBold' !important;
  line-height: 1.5 !important;
  font-size: 12px;
  margin: 0;
  font-weight: ${(props) => props.weight || 'bold'};
  color: ${(props) => calculateColor(props.color) || '#45424e'};
`;

export const LinkText = styled.a`
  font-family: 'Poppins SemiBold' !important;
  font-size: ${(props) => props.size || '16px'};
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => calculateColor(props.color) || '#8080BA'};
`;

export const InputLabelText = styled.p`
  font-family: 'Poppins SemiBold' !important;
  margin: 0 0 0.5rem 0 !important;
  padding: 0;
  color: ${(props) => props.color || '#45424e'};
  font-size: 12px;
`;
export const LabelText = styled.p`
  font-family: 'Poppins SemiBold' !important;
  margin: 0 0 8px 0;
  padding: 0;
  color: ${(props) => props.color || '#8b96a3'};
  font-size: 12px;
`;
