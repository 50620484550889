import { AGENCY_COMMUNITY_V1 } from 'constants/endpoints';
import { GeneralLocationSettingsNewFormFields as GLSNFFields } from 'pages/LocationSettingsPage/GeneralLocationSettingsPageNewForm.types';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_POST_createNewAgencyFacility = (formValues: GLSNFFields): ApiRequestConfigType => {
  const data = new FormData();

  data.append('communities_attributes[name]', formValues.name);
  data.append(
    'communities_attributes[contact_name]',
    `${formValues.first_name} ${formValues.last_name}`,
  );
  data.append('communities_attributes[contact_email]', formValues.email);
  data.append('communities_attributes[contact_phone]', formValues.phone);
  if (formValues.street_number)
    data.append(
      'communities_attributes[location_attributes][street_number]',
      formValues.street_number,
    );

  if (formValues.street)
    data.append('communities_attributes[location_attributes][street]', formValues.street);
  if (formValues.longitude)
    data.append(
      'communities_attributes[location_attributes][longitude]',
      String(formValues.longitude),
    );
  if (formValues.latitude)
    data.append(
      'communities_attributes[location_attributes][latitude]',
      String(formValues.latitude),
    );
  if (formValues.city)
    data.append('communities_attributes[location_attributes][city]', formValues.city);
  if (formValues.state)
    data.append('communities_attributes[location_attributes][state]', formValues.state);
  if (formValues.country)
    data.append('communities_attributes[location_attributes][country]', formValues.country);
  if (formValues.zip_code)
    data.append('communities_attributes[location_attributes][zip_code]', formValues.zip_code);
  if (formValues.regulatory_id)
    data.append('communities_attributes[wsib_attributes][regulatory_id]', formValues.regulatory_id);
  if (formValues.document)
    data.append(
      'communities_attributes[wsib_attributes][wsib_documents_attributes][0][document]',
      formValues.document,
      formValues.document.name,
    );
  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url: AGENCY_COMMUNITY_V1,
    body: data,
  };
};
