import { QuerySwitch } from 'components/QuerySwitch';
import {
  FetchDepartmentsDataContext,
  FetchDepartmentsDataProvider,
} from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/contexts/FetchDepartments.data.context';
import { PositionsSettingsRedirectPageComponent } from 'pages/SettingsPage/PositionsSettingsPage/PositionsSettingsRedirectPageComponent';

export const PositionsSettingsRedirectPage = () => (
  <FetchDepartmentsDataProvider>
    <QuerySwitch
      component={PositionsSettingsRedirectPageComponent}
      context={FetchDepartmentsDataContext}
    />
  </FetchDepartmentsDataProvider>
);
