import { FC, useContext, useState } from 'react';
import { YearViewWrapper, MonthCell, ThisMonthIndicator } from './YearView.styled';
import { CalendarNavigation } from '../CalendarNavigation';
import { getMonthCellsConfigArray, getYearInView } from './YearView.utils';
import { BKJCalendarContext } from '../../BKJCalendar.context';

export const YearView: FC = (): JSX.Element => {
  const { value, name, dateRangeInMonthView, handleYearViewOnChange } =
    useContext(BKJCalendarContext);
  const [year, setYear] = useState(getYearInView(dateRangeInMonthView));
  const monthsForYear = getMonthCellsConfigArray({
    name,
    value,
    year,
    onChange: handleYearViewOnChange,
  });

  return (
    <YearViewWrapper>
      <CalendarNavigation
        displayLabel={year}
        onClickPrevious={() => setYear(year - 1)}
        onClickNext={() => setYear(year + 1)}
      />
      {monthsForYear.map((monthConfig) => {
        const { onClick, isSelected, label, isMonthThisMonth } = monthConfig;
        return (
          <MonthCell onClick={onClick} isSelected={isSelected} key={label}>
            {label}
            {isMonthThisMonth && <ThisMonthIndicator />}
          </MonthCell>
        );
      })}
    </YearViewWrapper>
  );
};
