import {
  ShiftModalComponentMap,
  ShiftModalViewType,
} from 'components/ShiftModal/ShiftModal.constants';
import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect } from 'react';
import { ModalRoutingService } from 'services/ModalRoutingService';
import { castStringToInteger } from 'utils';

export type ShiftModalContextType = {
  isOpen: boolean;
  shift_id?: number;
  shiftModalActiveModalView: ShiftModalViewType;
};

export const ShiftModalUIContext = createContext({
  isOpen: false,
  shift_id: undefined as ShiftModalContextType['shift_id'],
  shiftModalActiveModalView: 'DetailView' as ShiftModalViewType,
});

export const shiftModalRoutingService = new ModalRoutingService(
  'shiftModal',
  'DetailView',
  ShiftModalComponentMap,
  { shiftModalShiftId: 'number' },
  ['TeamMemberUnavailableView', 'ShiftUnavailableView'],
);

export const ShiftModalUIProvider: FC = ({ children }) => {
  const [{ shiftModalShiftId, shiftModalActiveModalView = 'DetailView' }, { removeParam }] =
    useURLParameters<{
      shiftModalShiftId?: string;
      shiftModalActiveModalView: ShiftModalViewType;
    }>();

  const shift_id = shiftModalShiftId ? castStringToInteger(shiftModalShiftId) : undefined;

  useEffect(() => {
    if (window.j360) window.j360.shiftModalRoutingService = shiftModalRoutingService;
    if (!Object.keys(ShiftModalComponentMap).includes(shiftModalActiveModalView)) {
      removeParam(['shiftModalActiveModalView', 'shiftModalShiftId']);
    }
    if (!shift_id && shiftModalActiveModalView) {
      removeParam('shiftModalActiveModalView');
    }
  }, [shiftModalActiveModalView, removeParam, shift_id]);

  return (
    <ShiftModalUIContext.Provider
      value={{
        isOpen: !!shift_id,
        shift_id: shift_id,
        shiftModalActiveModalView,
      }}
    >
      {children}
    </ShiftModalUIContext.Provider>
  );
};

export const useShiftModalUIContext = (): ShiftModalContextType => useContext(ShiftModalUIContext);
