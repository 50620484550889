import { DEFAULT_BASE_URL } from 'config';
import qs from 'qs';
import { ApiRequestConfigType } from 'requests/index.types';

export const trimBaseURL = (url: string) => url.replace(`${DEFAULT_BASE_URL}/`, '');

export const generateRequestId = (args: ApiRequestConfigType) => {
  const { url, method, query, namespace } = args;
  if (!method || !url) {
    throw new Error('Missing required parameters');
  }
  return query
    ? `${method.toUpperCase()}_${trimBaseURL(url)}?${qs.stringify(query, {
        arrayFormat: 'brackets',
        encodeValuesOnly: true,
      })}${namespace ? `_${namespace}` : ''}`
    : `${method.toUpperCase()}_${trimBaseURL(url)}${namespace ? `_${namespace}` : ''}`;
};

export const createAPIActionType = (args: ApiRequestConfigType) => {
  const { url, method, query, namespace } = args;

  if (!method || !url) {
    throw new Error('Missing required parameters');
  }

  return query
    ? `${method.toUpperCase()}_${trimBaseURL(url).replace('/', '-')}?${qs.stringify(query)}${
        namespace ? `_${namespace}` : ''
      }`
    : `${method.toUpperCase()}/${trimBaseURL(url).replace('/', '-')}${
        namespace ? `_${namespace}` : ''
      }`;
};
