import {
  customFieldsPayloadConverter,
  endOfDay,
  ICustomFields,
  startOfDay,
} from 'components/TimeOffModal/TimeOffModal.constants';
import { createDateWithHourAndMinute } from 'components/TimeOffModal/TimeOffModalView.validation.constants';
import { DateTime } from 'luxon';
import { QUERY_timeOffFetchJane } from 'queries';
import {
  API_POST_createTimeOffParamsType,
  CustomFieldEntity,
} from 'requests/POST_createTimeOff.types';
import { fetchTimeOffCustomFields_fetchCustomFields_nodes } from 'types/graphql-types';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { getHoursMinsSecFromDate } from 'utils/time';

export const fetchJaneQueryConfig = ({ janeId }: { janeId: string }) => ({
  key: 'CREATE_TIME_OFF',
  query: QUERY_timeOffFetchJane,
  schema: {
    janeId: {
      initialValue: janeId,
    },
  },
});

export const getStartEndDateTime = ({
  startDate,
  endDate,
  startTime,
  endTime,
  allDay,
}: {
  startDate: string[];
  endDate: string[];
  startTime: string[];
  endTime: string[];
  allDay: boolean;
}) => {
  const startDateTime = allDay
    ? createDateWithHourAndMinute(startDate, startOfDay)
    : createDateWithHourAndMinute(startDate, startTime);

  const endDateTime = allDay
    ? createDateWithHourAndMinute(endDate, endOfDay)
    : createDateWithHourAndMinute(endDate, endTime);

  return [startDateTime, endDateTime];
};

export const createTimeOffPayloadTransformer = (payload: {
  jane_id: string;
  selectedFacilityId: number;
  allDay: boolean;
  startDate: string[];
  endDate: string[];
  startTime: string[];
  endTime: string[];
  title: string[];
  notes: string;
  customFields: ICustomFields;
  timeOffCustomFields: fetchTimeOffCustomFields_fetchCustomFields_nodes[];
}): API_POST_createTimeOffParamsType => {
  const {
    jane_id,
    allDay,
    startDate,
    endDate,
    startTime,
    endTime,
    title,
    notes,
    selectedFacilityId,
    customFields,
    timeOffCustomFields,
  } = payload;

  let [from, to] = getStartEndDateTime({
    startTime,
    startDate,
    endDate,
    endTime,
    allDay,
  });

  if (getHoursMinsSecFromDate(to) === startOfDay[0])
    to = DateTime.fromISO(to).minus({ second: 1 }).toString();

  const custom_fields = customFieldsPayloadConverter(
    customFields,
    timeOffCustomFields,
  ) as CustomFieldEntity[];

  const { timeZone } = getCurrentlySelectedLocation();

  const fromDateTime = DateTime.fromISO(from).setZone(timeZone);
  const toDateTime = DateTime.fromISO(to).setZone(timeZone);

  const start_date_time = {
    date: fromDateTime.toFormat('yyyy-LL-dd'),
    hour: fromDateTime.get('hour'),
    minute: fromDateTime.get('minute'),
  };

  const end_date_time = {
    date: toDateTime.toFormat('yyyy-LL-dd'),
    hour: toDateTime.get('hour'),
    minute: toDateTime.get('minute'),
  };

  return {
    time_off: {
      start_date_time,
      end_date_time,
      title: title[0],
      notes,
      jane_id,
      community_id: selectedFacilityId,
      custom_fields,
    },
  };
};
