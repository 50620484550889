import * as React from 'react';

export function GeneralLocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
        fill="#00C18C"
      />
      <path
        d="M24.7778 26.3339H25.5557C26.0224 26.3339 26.3335 26.0228 26.3335 25.5561V18.5557C26.3335 18.089 26.0224 17.7778 25.5557 17.7778H24.7778V25.5561V26.3339Z"
        fill="white"
      />
      <path
        d="M9.22119 25.5561V17.7778H8.44336C7.97666 17.7778 7.66553 18.089 7.66553 18.5557V25.5561C7.66553 26.0228 7.97666 26.3339 8.44336 26.3339H9.22119V25.5561Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.4442 7.66602H11.5546C11.0879 7.66602 10.7767 7.97715 10.7767 8.44384V25.5561C10.7767 26.0228 11.0879 26.3339 11.5546 26.3339H16.2215V23.2226H17.7772V26.3339H22.4442C22.9108 26.3339 23.222 26.0228 23.222 25.5561V8.44384C23.222 7.97715 22.9108 7.66602 22.4442 7.66602ZM15.4437 20.8891H13.1102V19.3334H15.4437V20.8891ZM13.1102 17.7778H15.4437V16.2221H13.1102V17.7778ZM15.4437 14.6665H13.1102V13.1108H15.4437V14.6665ZM13.1102 11.5552H15.4437V9.9995H13.1102V11.5552ZM20.8885 20.8891H18.555V19.3334H20.8885V20.8891ZM18.555 17.7778H20.8885V16.2221H18.555V17.7778ZM20.8885 14.6665H18.555V13.1108H20.8885V14.6665ZM18.555 11.5552H20.8885V9.9995H18.555V11.5552Z"
        fill="white"
      />
      <circle cx="17" cy="17" r="16.5" stroke="white" />
    </svg>
  );
}
