import { FC } from 'react';

import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { MobileBulkCreateShiftView } from 'pages/CreateShiftsPage/BulkCreateShiftView/MobileCreateShiftView';
import { WebBulkCreateShiftView } from 'pages/CreateShiftsPage/BulkCreateShiftView/WebBulkCreateShiftView';

export const BulkCreateShiftView: FC = () => {
  const { isMobile } = useResponsive();
  const ShiftViewComponent = isMobile ? MobileBulkCreateShiftView : WebBulkCreateShiftView;

  return <ShiftViewComponent />;
};
