import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  ActivityTabTableStyled,
  ActivityTabTableWrapper,
  TableHeaderCell,
} from 'components/ActivityTab/ActivityTab.styled';
import { ActivityTabRow } from 'components/ActivityTab/ActivityTabRow';
import { ActivityTabRowPropsProvider } from 'components/ActivityTab/ActivityTabRow.props.context';
import { FC } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes';
import { UserType } from 'types/activities.types';
import { ActivitiesType } from 'types/users.types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const ActivityTab: FC<{ activities: ActivitiesType[]; timeZone?: string }> = ({
  activities,
  timeZone,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary componentName="ActivityTab" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <ActivityTabTableWrapper>
        <MountTransition>
          <ActivityTabTableStyled>
            <TableHeaderCell>{t('common:TIME')}</TableHeaderCell>
            <TableHeaderCell>{t('common:ACTIVITY')}</TableHeaderCell>
            <TableHeaderCell>{t('dashboard:BY_USER')}</TableHeaderCell>

            {activities?.map(({ createdAt, event, user, metadata }, index) => {
              const userDetails = { ...user } as UserType;

              return (
                <ActivityTabRowPropsProvider
                  timeZone={timeZone}
                  key={createdAt + event.value}
                  createdAt={createdAt}
                  event={event}
                  userDetails={userDetails}
                  index={index}
                  metadata={metadata}
                >
                  <ActivityTabRow />
                </ActivityTabRowPropsProvider>
              );
            })}
          </ActivityTabTableStyled>
        </MountTransition>
      </ActivityTabTableWrapper>
      {children}
    </ErrorBoundary>
  );
};
