import { BKJSelect } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';
import { Flex } from 'components/Flex/Flex';

export const StyledBKJSelect = styled(BKJSelect)``;

export const LocationFilterWrapper = styled(Flex)<{ width?: string }>`
  width: ${({ width = '318px' }) => width};
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 10px 18px 10px 18px;
    width: 100%;
  }
`;
