import { GQLAPIContextType } from 'types/common.types';
import { createGQLDataContext } from 'utils/createDataContext';
import { fetchGroups } from 'types/graphql-types';
import { FC, useContext, useMemo } from 'react';
import { useSSFBehaviors } from 'hooks';
import { useLocation } from 'react-router-dom';
import { fetchGroupsConfig } from 'pages/GroupsPage/GroupsPage.constants';

export const GroupsPageDataContext = createGQLDataContext<GQLAPIContextType<fetchGroups>>();

export const GroupsPageDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const location_id = pathname.split('/')[3];

  const [values, opts] = useSSFBehaviors(fetchGroupsConfig(Number.parseInt(location_id)));

  const contextValues = useMemo(() => {
    return {
      values,
      ...opts,
    };
  }, [opts, values]);

  return (
    <GroupsPageDataContext.Provider value={contextValues}>
      {children}
    </GroupsPageDataContext.Provider>
  );
};

export const useGroupsPageDataContext = (): GQLAPIContextType<fetchGroups> =>
  useContext(GroupsPageDataContext);
