import { SSFBehaviorsKeyType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';

export const ssfKeysSharingValuesConfig: Record<SSFBehaviorsKeyType, SSFBehaviorsKeyType[]> = {
  'DASHBOARD_UPCOMING-SHIFTS_PAGE': [
    'DASHBOARD_PAST-SHIFTS_PAGE',
    'DASHBOARD_RECENTLY-CREATED-SHIFTS_PAGE',
  ],
  'DASHBOARD_PAST-SHIFTS_PAGE': [
    'DASHBOARD_UPCOMING-SHIFTS_PAGE',
    'DASHBOARD_RECENTLY-CREATED-SHIFTS_PAGE',
  ],
  'DASHBOARD_RECENTLY-CREATED-SHIFTS_PAGE': [
    'DASHBOARD_UPCOMING-SHIFTS_PAGE',
    'DASHBOARD_PAST-SHIFTS_PAGE',
  ],
  SHIFTS_CALENDAR_PAGE: ['SHIFTS_LIST_PAGE'],
  SHIFTS_LIST_PAGE: ['SHIFTS_CALENDAR_PAGE'],
};
