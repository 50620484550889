import { createShiftModalComponentMap } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/CreateShiftModal.constants';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { FC, useContext } from 'react';
import i18next from 'i18next';
import { BKJSideModal } from 'components/BKJSideModal/BKJSideModal';

export const CreateShiftModal: FC = () => {
  const { modalView, createShift, cancelAndCloseModal } = useContext(CreateShiftCardContext);

  if (!modalView || !createShift) {
    return null;
  }
  const ModalComponent: () => JSX.Element | null = createShiftModalComponentMap[modalView];

  const ShiftModalHeaderComponent = (): JSX.Element | null => {
    const quantity = createShift.values.quantity;
    return <>{`${i18next.t('create_shifts:NEW_SHIFT')} (x${quantity[0]})`}</>;
  };

  return (
    <BKJSideModal
      isOpen={true}
      onClose={cancelAndCloseModal}
      headerComponent={ShiftModalHeaderComponent}
    >
      <ModalComponent />
    </BKJSideModal>
  );
};
