// This is supplied as a default value to responseTransformer which is passed in as requestOptions to apiRequest in apiSlice

export function defaultMapper(data) {
  const entries = Object.entries(data);
  const transformedData = {};
  entries.forEach(([key, val]) => {
    // Check each key's value to see if it's an array of objects AND that it contains a key called id
    if (Array.isArray(val) && val.length && val[0].id) {
      transformedData[key] = {};
      transformedData[`${key}_ids`] = [];
      val.forEach((item) => {
        // if it is an array of objects with a key of id inside, transform it to a map with the following structure:
        // data: { columnName: { [id]: record } }
        transformedData[key][item.id] = item;
        // additionally also create an array of ids
        // data: { columnName_ids: [...ids] }
        transformedData[`${key}_ids`].push(item.id);
      });
    } else {
      transformedData[key] = val;
      if (Array.isArray(val)) transformedData[`${key}_ids`] = [];
    }
  });
  return transformedData;
}
