import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { sessionTokenSelector } from 'store/selectors/userSelectors/sessionTokenSelector';
import { assertUserPermissions } from 'utils/assertUserPermissions';
import { IUserGuardProps } from './UserGuard.types';

export const UserGuard: FC<IUserGuardProps> = (props) => {
  const { children, userType } = props;
  const sessionToken = useSelector(sessionTokenSelector);
  const userIsType = assertUserPermissions(userType);
  if (!sessionToken) return <Fragment />;
  if (!userIsType) return <Fragment />;
  return <Fragment>{children}</Fragment>;
};
