import { useFetchSelectedAgenciesRateDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests/FetchSelectedAgenciesRate.data.context';
import { ShiftPay } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/ShiftPay';
import { FC } from 'react';

export const SelectedAgenciesPayComponent: FC = () => {
  const {
    data: { rate, cost },
  } = useFetchSelectedAgenciesRateDataContext();

  return (
    <ShiftPay
      estimatedHourlyPay={
        rate?.min === rate?.max
          ? `$${rate?.min.toFixed(2)}`
          : `$${rate?.min.toFixed(2)} - $${rate?.max.toFixed(2)}`
      }
      estimatedTotalPay={
        cost?.min === cost?.max
          ? `$${cost?.min.toFixed(2)}`
          : `$${cost?.min.toFixed(2)} - $${cost?.max.toFixed(2)}`
      }
    />
  );
};
