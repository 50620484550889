import { GroupsModalComponent } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.component';
import { GroupsModalDataProvider } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.data.context';
import { GroupsDetailsUIProvider } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.ui.context';

import { FC } from 'react';

export const GroupsModal: FC = () => {
  return (
    <GroupsDetailsUIProvider>
      <GroupsModalDataProvider>
        <GroupsModalComponent />
      </GroupsModalDataProvider>
    </GroupsDetailsUIProvider>
  );
};
