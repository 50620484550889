import styled, { css } from 'styled-components';
import { FC } from 'react';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { withMemo } from 'utils/withMemo';

export type MountTransitionComponentType = 'div' | 'span';

interface IMountTransitionProps extends HTMLMotionProps<'div'> {
  slide?: number;
  slideUp?: number;
  component?: MountTransitionComponentType;
}

const MountTransitionComponent = withMemo<FC<IMountTransitionProps>>(
  ({ children, component = 'div', ...rest }) => {
    const MotionComponent = motion[component];
    return (
      <AnimatePresence initial>
        <MotionComponent
          {...rest}
          transition={{ ease: 'easeInOut' }}
          style={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </MotionComponent>
      </AnimatePresence>
    );
  },
);

export const MountTransition = styled(MountTransitionComponent)<{ height?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`;
