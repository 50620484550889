import { fetchTimeOffCustomFieldsQueryConfig } from 'components/TimeOffModal/TimeOffModal.constants';
import { fetchJaneQueryConfig } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.constants';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useGQLQuery, useSSFBehaviors, useURLParameters } from 'hooks';
import { useEffect } from 'react';
import { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectedScheduleFacilityIdSelector } from 'store/selectors/scheduleSelectors/selectedScheduleFacilityIdSelector';
import {
  timeOffFetchJane,
  fetchTimeOffCustomFields_fetchCustomFields_nodes,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const CreateTimeOffViewDataContext = createGQLDataContext({});

export const CreateTimeOffViewDataProvider: FC = ({ children }) => {
  const [{ jane_id }] =
    useURLParameters<{
      jane_id: string;
    }>();

  const selectedFacilityId = useSelector(selectedScheduleFacilityIdSelector);
  const { data: fetchTimeOffCustomFields, fetch } = useGQLQuery(
    fetchTimeOffCustomFieldsQueryConfig({ byFacilityId: selectedFacilityId }),
  );

  const isCustomFieldsEnabled = useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']);

  useEffect(() => {
    if (isCustomFieldsEnabled && !!selectedFacilityId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId]);

  const [values, opts] = useSSFBehaviors(fetchJaneQueryConfig({ janeId: jane_id }));

  const timeOffCustomFields = Object.values(fetchTimeOffCustomFields);

  const value = useMemo(() => {
    return {
      ...values,
      timeOffCustomFields,
      ...opts,
    };
  }, [timeOffCustomFields, opts, values]);

  return (
    <CreateTimeOffViewDataContext.Provider value={value}>
      {children}
    </CreateTimeOffViewDataContext.Provider>
  );
};

export interface ICreateTimeOffViewDataContext {
  timeOffCustomFields: fetchTimeOffCustomFields_fetchCustomFields_nodes[];
  data: timeOffFetchJane;
}

export const useCreateTimeOffViewDataContext = (): ICreateTimeOffViewDataContext =>
  useContext(CreateTimeOffViewDataContext);
