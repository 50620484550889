import { BKJButton, BKJIcon, Flex } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const FlexWrapper = styled(Flex)`
  background-color: white;
`;

export const CustomFieldsSettingsTableStyled = styled.div<{ isReadOnly: boolean }>`
  display: grid;
  grid-auto-columns: min-content;
  grid-template-columns: ${({ isReadOnly }) =>
    isReadOnly ? 'auto auto auto auto auto' : 'auto auto auto auto auto auto'};
`;

export const TableHeaderCell = styled.div`
  min-height: 72px;
  max-height: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TableRow = styled.div<{ isReadOnly: boolean }>`
  background-color: ${({ theme }) => theme.colors.Grey1};
  grid-column: ${({ isReadOnly }) => (isReadOnly ? '1 / span 5' : '1 / span 6')};
  min-height: 72px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const InlineBKJButton = styled(BKJButton)`
  display: inline;
  padding: 0 0 0 0;
  width: auto;
`;

export const TableRowCell = styled.div<{ isGrey?: boolean }>`
  word-break: break-word;
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const FacilitiesTableCellButtonContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TableRowCellButton = styled(BKJButton)`
  width: fit-content;
  height: fit-content;
  min-height: fit-content;
  max-height: fit-content;
  padding: 0 3px;
`;

export const FacilitiesList = styled.span`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const FacilitiesListItem = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const MaxNumberOfCustomFields = styled.div`
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsTextLight};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  padding: 16px;
`;

export const BKJIconWrapper = styled(BKJIcon)`
  padding-right: 8px;
`;
