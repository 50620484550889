import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { AuditTrailTabView } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailTabView';
import { DetailTabViewPropsProvider } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.props.context';
import { NotifyTeamMember } from 'components/ShiftModal/src/views/NotifyTeamMember';
import { useURLParameters } from 'hooks';
import { FC, useEffect } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';

export const AuditTrailView: FC = () => {
  const [{ calloutListId }, { removeParam }] = useURLParameters<{ calloutListId: string }>();
  useEffect(
    () => () => {
      if (calloutListId) {
        removeParam('calloutListId');
        shiftModalRoutingService.goToPrevView();
      }
    },
    [calloutListId, removeParam],
  );
  return (
    <DetailTabViewPropsProvider>
      <AuditTrailTabView />
    </DetailTabViewPropsProvider>
  );
};

export const AuditTrailViewRenderer: FC = withMemo((props) => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const Component = {
    ...ShiftModalComponentMap,
    AuditTrailView,
    NotifyTeamMember,
  }[shiftModalActiveModalView];
  return (
    <ErrorBoundary componentName="AuditTrailView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <Component {...props} />
    </ErrorBoundary>
  );
});
