// import React, { useContext } from 'react';
import { BKJTableBody, BKJTableHeader, BKJTableWrapper } from 'components/BKJTable/BKJTable.styled';
import { DedicatedTeamTableHeader } from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamTable/DedicatedTeamTableHeader';
import { DedicatedTeamTableRow } from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamTable/DedicatedTeamTableRow';
import { DedicatedTeamEmptyView } from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamEmptyView';
import { hasJanes } from 'pages/AgencyMemberPage/AgencyMemberPage.utils';
// import { AgencyMemberPageContext } from 'pages/AgencyMemberPage/AgencyMemberPage.context';

export function DedicatedTeamTable() {
  // const { data } = useContext(AgencyMemberPageContext);

  const data = {
    fetchJanes: {
      pageInfo: {
        endCursor: '',
        startCursor: '',
        hasNextPage: false,
        hasPreviousPage: false,
      },
      edges: [],
      pageCount: 0,
    },
  };

  if (!hasJanes(data)) return <DedicatedTeamEmptyView />;

  return (
    <BKJTableWrapper>
      <BKJTableHeader>
        <DedicatedTeamTableHeader />
      </BKJTableHeader>
      <BKJTableBody>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {data.fetchJanes.edges.map(({ node: jane }: any) => {
          return <DedicatedTeamTableRow key={jane?.id} {...jane} />;
        })}
      </BKJTableBody>
    </BKJTableWrapper>
  );
}
