import { BKJTableBody, BKJTableHeader, BKJTableWrapper } from 'components/BKJTable/BKJTable.styled';
import { AgencyMemberEmptyView } from 'pages/AgencyMemberTablePage/AgencyMemberEmptyView';
import { AgencyMemberPageContext } from 'pages/AgencyMemberPage/AgencyMemberPage.context';
import { AgencyMemberTableHeader } from 'pages/AgencyMemberTablePage/AgencyMemberTable/AgencyMemberTableHeader';
import { AgencyMemberTableRow } from 'pages/AgencyMemberTablePage/AgencyMemberTable/AgencyMemberTableRow';
import React, { useContext } from 'react';
import { hasJanes } from 'pages/AgencyMemberPage/AgencyMemberPage.utils';

export function AgencyMemberTable() {
  const { data } = useContext(AgencyMemberPageContext);

  if (!hasJanes(data)) return <AgencyMemberEmptyView />;

  return (
    <BKJTableWrapper>
      <BKJTableHeader>
        <AgencyMemberTableHeader />
      </BKJTableHeader>
      <BKJTableBody>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {data.fetchJanes.edges.map(({ node: jane }: any) => {
          return <AgencyMemberTableRow key={jane?.id} {...jane} />;
        })}
      </BKJTableBody>
    </BKJTableWrapper>
  );
}
