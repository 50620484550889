import { Flex } from 'components/Flex';
import styled from 'styled-components';
import { BKJButton } from '@bookjane2/bookjane-design-library';

export const ReleaseShiftViewWrapper = styled(Flex)`
  padding: 32px;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px;
  }
`;

export const ReleaseShiftTitle = styled.h3`
  ${({ theme }) => theme.typography.H3};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 18px;
  }
`;

export const FormLabel = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ theme }) => theme.common.Ellipsis};
  line-height: 21px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 8px;
  display: block;
`;

export const WarningText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  line-height: 21px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};

  margin-left: 14px;
`;

export const FormGroup = styled.div`
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-bottom: 24px;
  }
`;
export const InnerWrapper = styled.div``;
export const ReleaseContent = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 21px;
  font-size: 16px;
  margin-bottom: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 14px;
  }
`;

export const WarningWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.Grey1};
  padding: 16px;
  margin-bottom: 32px;
`;

export const ConfirmationButton = styled(BKJButton)`
  width: 180px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    width: 100%;
    padding: 22px;
  }
`;
