import { FormValidationErrorMessageType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { isString } from 'types/common.types';
import i18next from 'i18next';

function validateEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const VALIDATION_email = (value: unknown): FormValidationErrorMessageType => {
  const messages: FormValidationErrorMessageType = [];

  if (isString(value)) {
    if (value.length === 0)
      return messages.concat(i18next.t('auth:YOU_MUST_ENTER_AN_EMAIL_ADDRESS'));

    if (!validateEmail(value)) messages.push(i18next.t('auth:INVALID_EMAIL_ADDRESS_FORMAT'));
  }
  return messages;
};
