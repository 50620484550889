import { CUSTOMER_SECONDARY_USERS } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { AdministratorSettingsGeneralFormFields } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';

export const API_PUT_updateCustomerSecondaryDetails = (
  id: string,
  formValues: AdministratorSettingsGeneralFormFields,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    method: METHOD_PUT,
    url: `${CUSTOMER_SECONDARY_USERS}/${id}`,
    body: {
      secondary_user: { ...formValues },
    },
  };
};
