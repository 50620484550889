import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModal } from 'components/BKJSideModal/BKJSideModal';
import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { ShiftModalHeaderMap } from 'components/ShiftModal/ShiftModalHeaders';
import { ShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const ShiftModalTemplate: FC = ({ children }) => {
  const { isOpen, shiftModalActiveModalView } = useShiftModalUIContext();

  return (
    <BKJSideModal
      data-testid="J360ShiftModal"
      isOpen={isOpen}
      context={ShiftModalDataContext}
      onClose={shiftModalRoutingService.close}
      onBack={shiftModalRoutingService.goToPrevView}
      headerComponent={ShiftModalHeaderMap[shiftModalActiveModalView]}
      showBackButton={shiftModalRoutingService.isBackButtonVisible()}
    >
      <ErrorBoundary componentName="ShiftModal" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        {children}
      </ErrorBoundary>
    </BKJSideModal>
  );
};
