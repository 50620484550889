import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { useSelector } from 'react-redux';
import { castStringToInteger } from 'utils';
import { useFetchAgencyTeamMembersDataContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMembers.data.context';

interface ISendToAgencyTeamMemberViewProps {
  goToSummaryView: () => void;
}

export const SendToAgencyTeamMemberViewPropsContext =
  createContext<ISendToAgencyTeamMemberViewProps>({} as ISendToAgencyTeamMemberViewProps);

export const SendToAgencyTeamMemberViewPropsProvider: FC = ({ children }) => {
  const { error } = useFetchAgencyTeamMembersDataContext();
  const { data } = useShiftModalDataContext();
  const [, { removeParam }] = useURLParameters();

  useEffect(() => {
    switch (error?.message) {
      case 'order cannot be assigned':
        shiftModalRoutingService.goToView('ShiftUnavailableView');
        break;
    }
  }, [error?.message]);

  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [{ agencyCommunityId: _agencyCommunityId }] =
    useURLParameters<{ agencyCommunityId?: string }>();
  const agencyCommunityId = castStringToInteger(_agencyCommunityId!);

  useEffect(() => {
    if (!data.fetchOrder.canAssign) removeParam('shiftModalActiveModalView');
    if (!isUserAgencyAndAgencyLocationFilterForcedDisabled && !agencyCommunityId)
      removeParam(['shiftModalActiveModalView', 'shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [
    agencyCommunityId,
    data.fetchOrder.canAssign,
    isUserAgencyAndAgencyLocationFilterForcedDisabled,
    removeParam,
  ]);

  const contextValue = useMemo(
    () => ({
      goToSummaryView: () =>
        shiftModalRoutingService.goToView('AssignToAgencyTeamMemberSummaryView'),
    }),
    [],
  );

  return (
    <SendToAgencyTeamMemberViewPropsContext.Provider value={contextValue}>
      {children}
    </SendToAgencyTeamMemberViewPropsContext.Provider>
  );
};

export const useSendToAgencyTeamMemberViewPropsContext = (): ISendToAgencyTeamMemberViewProps =>
  useContext(SendToAgencyTeamMemberViewPropsContext);
