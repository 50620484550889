import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  API_GET_timeOffValidations,
  ITimeOffValidationsQuery,
  validateTimeOffValidationsQuery,
} from 'requests/GET_timeOffValidations';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';

export interface ITimeOffValidationErrors {
  type: string;
  error_messages: {
    base: string[];
  };
}

export interface ITimeOffValidationContext {
  timeOffValidationPending: boolean;
  timeOffValidationErrors: ITimeOffValidationErrors;
}

export const TimeOffValidationContext = createContext<ITimeOffValidationContext>(
  {} as ITimeOffValidationContext,
);

export const TimeOffValidationProvider: FC<{ timeOffValidationsQuery: ITimeOffValidationsQuery }> =
  ({ children, timeOffValidationsQuery }) => {
    const { apiRequest } = useAPIActions();

    const requestOptions = API_GET_timeOffValidations(timeOffValidationsQuery);
    const { isPending, error } = useSelector((state) => apiDataSelector(state, requestOptions));

    useEffect(() => {
      if (validateTimeOffValidationsQuery(timeOffValidationsQuery)) apiRequest(requestOptions);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      timeOffValidationsQuery.time_off.end_time,
      timeOffValidationsQuery.time_off.start_time,
      timeOffValidationsQuery.time_off.jane_id,
    ]);

    const contextValue = useMemo(
      () => ({
        timeOffValidationPending: isPending,
        timeOffValidationErrors: error,
      }),
      [error, isPending],
    );

    return (
      <TimeOffValidationContext.Provider value={contextValue}>
        {children}
      </TimeOffValidationContext.Provider>
    );
  };

export const useTimeOffValidationContext = () => useContext(TimeOffValidationContext);
