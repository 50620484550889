import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { FC, useContext } from 'react';

import {
  AutoExtendBidToggleInputLabel,
  AutoExtendBidToggleInputWrapper,
  StyledBKJToggleInput,
  ShiftCardButtonWithNoHeight,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/AutoExtendBidToggleInput/AutoExtendBidToggleInput.styled';
import { useTranslation } from 'hooks/useTranslation';
import { BKJIconTooltip, Flex } from '@bookjane2/bookjane-design-library';
import { getShiftStartTimeAndEndTime, getShiftStartTimeWithInOneHour } from 'utils/time';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const AutoExtendBidToggleInput: FC = () => {
  const {
    createShift: {
      values: { auto_extend_bid, startAt, endAt, startDate },
    },
    handleChange,
  } = useContext(CreateShiftCardContext);
  const [start_time] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
    startDate,
  });

  const { timeZone } = getCurrentlySelectedLocation();
  const isShiftStartTimeWithinOneHour = getShiftStartTimeWithInOneHour(start_time, timeZone);
  const { t } = useTranslation();

  return (
    <AutoExtendBidToggleInputWrapper>
      <ShiftCardButtonWithNoHeight variant="PurpleNoDecorations">
        <AutoExtendBidToggleInputLabel>
          {t('create_shifts:AUTO_EXTEND_BID')}?
        </AutoExtendBidToggleInputLabel>
      </ShiftCardButtonWithNoHeight>
      <BKJIconTooltip iconName="Info" placement="top">
        <Flex width="300px">{t('create_shifts:AUTO_RE_BID_INFO')}</Flex>
      </BKJIconTooltip>
      <StyledBKJToggleInput
        variant="Switch"
        name="auto_extend_bid"
        checked={!!auto_extend_bid && !isShiftStartTimeWithinOneHour}
        onChange={handleChange}
        disabled={isShiftStartTimeWithinOneHour}
      />
    </AutoExtendBidToggleInputWrapper>
  );
};
