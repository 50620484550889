import { QuerySwitch } from 'components/QuerySwitch';
import { TimeFrameLocationSettingsForm } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm';
import {
  TimeFrameLocationSettingsPageDataContext,
  TimeFrameLocationSettingsPageDataProvider,
} from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageForm.data.context';
import { TimeFrameLocationSettingsPageRead } from 'pages/LocationSettingsPage/TimeFrameLocationSettingsPageRead';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isUserSecondarySelector from 'store/selectors/userSelectors/isUserSecondarySelector';
import { withMemo } from 'utils/withMemo';

export const Component: FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const isReadOnly = useSelector(isUserSecondarySelector);
  const TimeFrameSettingsComponent = useMemo(
    () => (isReadOnly ? TimeFrameLocationSettingsPageRead : TimeFrameLocationSettingsForm),
    [isReadOnly],
  );

  switch (locationId) {
    default:
      return (
        <TimeFrameLocationSettingsPageDataProvider key={locationId}>
          <QuerySwitch
            context={TimeFrameLocationSettingsPageDataContext}
            component={TimeFrameSettingsComponent}
          />
        </TimeFrameLocationSettingsPageDataProvider>
      );
  }
};

export const TimeFrameLocationSettingsPage = withMemo(Component);
