import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const InviteViewWrapper = styled(Flex)`
  height: 100%;
  padding: 32px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const InviteTitle = styled.h3`
  ${({ theme }) => theme.typography.H3};
`;

export const InviteSubtitle = styled.span`
  ${({ theme }) => theme.typography.Bold}
`;

export const InviteText = styled.span`
  ${({ theme }) => theme.typography.Body};
`;

export const InviteLabel = styled.span`
  ${({ theme }) => theme.typography.Caption};
`;

export const InviteStepLine = styled.div`
  width: 1px;
  height: 16px;
  margin-left: 11px;
  background-color: ${({ theme }) => theme.colors.Grey5};
`;
