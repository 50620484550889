import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { SessionService } from 'services';

export function parseJson(value: string | null) {
  try {
    if (typeof value !== 'string') return undefined;
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
}

export function usePersistentState<T>(
  valueIfCacheUndefined: T,
  key: string,
): [T, Dispatch<SetStateAction<T>>] {
  const uniqueKey = `${SessionService.getUserId()}-${key}`;

  const getValue = useCallback((): T => {
    const savedValue = window.localStorage.getItem(uniqueKey);
    return !savedValue ? valueIfCacheUndefined : parseJson(savedValue);
  }, [valueIfCacheUndefined, uniqueKey]);

  const [value, setValue] = useState(getValue());

  const handleSetValue = useCallback(
    (nextValue) => {
      setValue(nextValue);
      window.localStorage.setItem(uniqueKey, JSON.stringify(nextValue));
    },
    [uniqueKey],
  );

  return [value, handleSetValue];
}
