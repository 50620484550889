import { saveAs } from 'file-saver';
import { AxiosResponse } from 'axios';

export function saveResponseAsCSV(
  response: AxiosResponse,
  fileName: string = `export-${Date.now()}.csv`,
) {
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response.data], {
    type: 'text/csv;charset=utf-8;',
  });

  return saveAs(blob, fileName);
}
