import { BKJHorizontalRule } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

export const ShiftModalWrapper = styled.div``;

export const SectionDivider = styled(BKJHorizontalRule)<{ margin?: string }>`
  ${({ margin }) => {
    if (margin)
      return css`
        margin: ${margin};
      `;
  }};
`;
