import i18next from 'i18next';
import { DateTime } from 'luxon';

export function getLocalizedDateFormat(
  date: string,
  timeZone?: string | undefined,
): {
  dayOfWeek: string;
  fullDayNameOfWeek: string;
  month: string;
} {
  const dayOfWeek = DateTime.fromISO(date).setZone(timeZone).toFormat('ccc').toUpperCase();
  const fullDayNameOfWeek = DateTime.fromISO(date).setZone(timeZone).toFormat('cccc').toUpperCase();
  const month = DateTime.fromISO(date).setZone(timeZone).toFormat('LLL').toUpperCase();
  const translatedDayOfWeek = i18next.t(dayOfWeek);
  const translatedFullDayNameOfWeek = i18next.t(fullDayNameOfWeek);
  const translatedMonth = i18next.t(month);

  return {
    dayOfWeek: translatedDayOfWeek,
    fullDayNameOfWeek: translatedFullDayNameOfWeek,
    month: translatedMonth,
  };
}
