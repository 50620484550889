import { IGridProps } from 'components/Grid/Grid.types';
import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const TextContentTitle = styled.h4`
  ${({ theme }) => theme.fonts.PoppinsBold};
  color: ${({ theme }) => theme.colors.AccentPurple};
  font-size: 20px;
  margin-bottom: 32px;
`;

export const TextContent = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 16px;
`;
export const Text = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 21px;
`;

export const RadioButtonWrapper = styled.div`
  ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  line-height: 24px;
`;

export const Description = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 25px;
  margin-top: 4px;
  margin-left: 34px;
`;

export const OptionalDescription = styled(Description)`
  margin-bottom: 27px;
`;

export const RadioButtonFormSection = styled.div`
  margin-bottom: 30px;
`;

export const LastChangedFooter = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 21px;
  padding-top: 10px;
  margin-bottom: 24px;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BKJTableWrapperMarginBottom = styled.div`
  overflow: overlay;
  height: 100%;
  max-width: 100%;
  margin-bottom: 28px;
`;

export const ShiftDistributionRulesTableContentCell = styled.div<{ isGrey?: boolean }>`
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
  min-height: 72px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
  display: flex;
  align-items: center;
`;
export const ShiftDistributionRulesTableContentCellEmptyFooter = styled.div<{ isGrey?: boolean }>`
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
  min-height: 36px;
  padding: 12px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const Grid = styled.div<IGridProps>`
  display: grid;
  grid-auto-rows: min-content;

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      grid-template-columns: 350px 1fr 100px;
    `}
`;

export const ShiftdistributionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: 65%;
  flex-grow: 1;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 24px;
`;

export const ShiftDistributionRulesRow = styled.div`
  min-height: 72px;
  max-height: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const UnderlineButton = styled.a`
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  text-decoration: underline;
  background-color: transparent;
  border: none;
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
`;
