import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { AssignToAgencyTeamMemberFormProvider } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

const AssignToAgencyTeamMemberViewComponent: FC = () => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const Component = ShiftModalComponentMap[shiftModalActiveModalView];

  return <Component />;
};

export const AssignToAgencyTeamMemberViewRenderer: FC = () => {
  return (
    <ErrorBoundary
      componentName="AssignToAgencyTeamMemberView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <AssignToAgencyTeamMemberFormProvider>
        <AssignToAgencyTeamMemberViewComponent />
      </AssignToAgencyTeamMemberFormProvider>
    </ErrorBoundary>
  );
};
