import { BKJButton } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { groupsModalRoutingService } from 'pages/SettingsPage/modals/GroupsModal/GroupsModal.ui.context';
import {
  groupsPageEmptyHeaderCopy,
  groupsPageEmptyBodyCopy,
} from 'pages/GroupsPage/GroupsPageEmptyView/GroupsPageEmptyView.constants';
import {
  EmptyBody,
  EmptyHeader,
  EmptyMessage,
  EmptyViewWrapper,
} from 'pages/GroupsPage/GroupsPageEmptyView/GroupsPageEmptyView.styled';

export function GroupsPageEmptyView() {
  return (
    <EmptyViewWrapper>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <EmptyMessage>
          <EmptyHeader>{groupsPageEmptyHeaderCopy}</EmptyHeader>
          <EmptyBody>{groupsPageEmptyBodyCopy}</EmptyBody>
        </EmptyMessage>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={() => groupsModalRoutingService.open({}, 'NewGroup')}
        >
          Create a group
        </BKJButton>
      </Flex>
    </EmptyViewWrapper>
  );
}
