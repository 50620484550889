import { createSelector } from 'reselect';
import { IGlobalReduxStore } from 'store';

const getUser = (state: IGlobalReduxStore) => state.auth.user;

const isUserAgencyPrimarySelector = createSelector(
  [getUser],
  (user) => user?.type === 'AgencyOwner',
);

export default isUserAgencyPrimarySelector;
