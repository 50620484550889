import styled from 'styled-components';
import { zIndexLayers } from 'constants/zIndexLayers';
import { elementDimensions } from 'constants/elementDimensions';

export const DesktopPageHeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  min-height: ${elementDimensions.DesktopPageHeaderHeight}px;
  height: ${elementDimensions.DesktopPageHeaderHeight}px;
  max-height: ${elementDimensions.DesktopPageHeaderHeight}px;
  background-color: ${(props) => props.theme.colors.White};
  ${({ theme }) => theme.shadows.Default};
  position: relative;
  z-index: ${zIndexLayers.pageHeader};
  white-space: pre;

  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    display: none;
  }
`;

export const DesktopPageHeaderTitle = styled.div`
  ${({ theme }) => theme.typography.H3};
`;
