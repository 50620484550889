import { CUSTOMER_COMMUNITIESV2 } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

interface IPutCommunity {
  name: string;
  firstName: string;
  lastName: string;
  contactPhone: string;
  contactEmail: string;
  locationAttributes: {
    id: number;
    label?: Nullable<string>;
    value?: Nullable<string>;
    longitude?: Nullable<number>;
    latitude?: Nullable<number>;
    flat?: Nullable<string>;
    street?: Nullable<string>;
    street_number?: Nullable<string>;
    city?: Nullable<string>;
    state?: Nullable<string>;
    country?: Nullable<string>;
    zip_code?: Nullable<string>;
    full_address?: Nullable<string>;
  };
}

export const API_PUT_updateCommunityDetails = (
  id: string,
  payload: IPutCommunity,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    method: METHOD_PUT,
    url: `${CUSTOMER_COMMUNITIESV2}/${id}`,
    body: {
      community: {
        name: payload.name,
        contact_name: `${payload.firstName} ${payload.lastName}`,
        contact_phone: payload.contactPhone,
        contact_email: payload.contactEmail,
        location_attributes: {
          ...payload.locationAttributes,
          label: payload.locationAttributes.label,
          value: payload.locationAttributes.value,
          longitude: payload.locationAttributes.longitude,
          latitude: payload.locationAttributes.latitude,
          flat: payload.locationAttributes.flat,
          street: payload.locationAttributes.street,
          street_number: payload.locationAttributes.street_number,
          city: payload.locationAttributes.city,
          state: payload.locationAttributes.state,
          country: payload.locationAttributes.country,
          zip_code: payload.locationAttributes.zip_code,
        },
      },
    },
  };
};
