import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { createApolloClient, createApolloCache } from 'providers/ApolloProvider';
import { QUERY_sendToSelectedAgenciesFetchAgenciesByOrderId } from 'queries';

const fetchAgenciesByOrderIdClient = createApolloClient({ cache: createApolloCache() });

export const fetchAgenciesByOrderIdConfig = ({
  orderId,
}: {
  orderId: string;
}): ISSFBehaviorsOptions => ({
  key: 'SELECT_AGENCIES_FETCH_AGENCIES_BY_ORDER_ID',
  query: QUERY_sendToSelectedAgenciesFetchAgenciesByOrderId,
  paginationType: 'InfiniteScroll',
  client: fetchAgenciesByOrderIdClient,
  pageSize: 8,
  payloadTransformer: (variables) => ({
    ...variables,
    byRateLessThanOrEqualTo: variables.byRateLessThanOrEqualTo
      ? Number.parseFloat(variables.byRateLessThanOrEqualTo[0])
      : undefined,
  }),
  schema: {
    byName: {
      initialValue: '',
    },
    orderId: {
      initialValue: orderId,
    },
    byRateLessThanOrEqualTo: {
      initialValue: undefined,
    },
  },
});
