export function transform<I, O>(a: I): O {
  return a as unknown as O;
}

export function getInitialPaginationState(
  { endCursor = undefined, pageSize = 10 }: { endCursor?: string; pageSize: number } = {
    endCursor: undefined,
    pageSize: 10,
  },
) {
  return {
    after: endCursor,
    first: pageSize,
  };
}
