import { Flex } from 'components/Flex';
import { MountTransition } from 'routes/MountTransition';
import styled from 'styled-components';

export const DashboardPageInProgress = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextBold}
  color: ${({ theme }) => theme.colors.AccentBrand};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  display: inline-flex;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 0 20px;
  }
`;

export const DashboardPageDateGroupWrapper = styled(MountTransition)`
  margin-top: 32px;
  padding: 0 55px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-top: 12px;
    padding: 0;
  }
`;

export const DashboardPageDateGroupText = styled.span`
  ${({ theme }) => theme.typography.Body};
  display: flex;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 12px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin-bottom: 0;
    padding: 0 20px;
  }
`;

export const DashboardPageMapContentWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  overflow-y: overlay;
`;

export const DashboardPageMapContentSidePanel = styled(Flex)`
  overflow-y: overlay;
  height: 100%;
  width: 50%;
`;

export const DashboardPageMapContent = styled(Flex)`
  height: 100%;
  width: 50%;
`;
