import { FC, useContext, useEffect, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import { GQLAPIContextType } from 'types/common.types';
import {
  fetchTieringDetails,
  fetchTieringDetails_fetchCommunity_biddingTimes,
  fetchTieringDetailsVariables,
} from 'types/graphql-types';
import { useLocation } from 'react-router-dom';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_shiftDistributionRulesTieringDetails } from 'queries';

type ShiftDistributionData = {
  locationName: string;
  biddingTimes: Nullable<fetchTieringDetails_fetchCommunity_biddingTimes[]>;
};

export const fetchShiftDistributionLocationConfig = (
  locationId: number,
): UseGQLQueryConfig<
  fetchTieringDetails,
  ShiftDistributionData,
  fetchTieringDetailsVariables,
  fetchTieringDetailsVariables
> => {
  return {
    key: 'SHIFT_DISTRIBUTION_RULES_MODAL_LOCATION_QUERY',
    query: QUERY_shiftDistributionRulesTieringDetails,
    variables: {
      id: locationId,
    },
    responseTransformer: (data: fetchTieringDetails): ShiftDistributionData => {
      return {
        locationName: data && data.fetchCommunity ? data.fetchCommunity.name : '',
        biddingTimes: data?.fetchCommunity?.biddingTimes?.length
          ? [...data.fetchCommunity.biddingTimes].sort((a, b) => a.priorityTime - b.priorityTime)
          : null,
      };
    },
  };
};

export const ShiftDistributionLocationDataContext =
  createGQLDataContext<GQLAPIContextType<ShiftDistributionData>>();

export const ShiftDistributionLocationDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const { data, fetch } = useGQLQuery<fetchTieringDetails, ShiftDistributionData, {}>(
    fetchShiftDistributionLocationConfig(Number.parseInt(locationId)),
  );

  useEffect(() => {
    if (locationId) {
      fetch();
    }
  }, []); // eslint-disable-line

  const contextValues = useMemo(() => {
    return {
      locationName: data?.locationName,
      biddingTimes: data?.biddingTimes,
    };
  }, [data]);

  return (
    <ShiftDistributionLocationDataContext.Provider value={contextValues}>
      {children}
    </ShiftDistributionLocationDataContext.Provider>
  );
};

export const useShiftDistributionLocationDataContext =
  (): GQLAPIContextType<ShiftDistributionData> => useContext(ShiftDistributionLocationDataContext);
