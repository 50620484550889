import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import { authLink, createApolloClient, httpLink } from 'providers/ApolloProvider';
import { QUERY_locationDropdownData } from 'queries';
import {
  locationDropdownData,
  locationDropdownData_me_AgencyUser,
  locationDropdownData_me_B2bCustomer,
} from 'types/graphql-types';

export const getDefaultLocationDropdownValue = (options: LocationOptionType[]) => {
  if (options.length) {
    return options[0];
  }

  return undefined;
};

export const locationOptionsTransformer = (data?: locationDropdownData) => {
  if (!data) return [];
  const me = data.me as locationDropdownData_me_B2bCustomer;
  const mapped: LocationOptionType[] = me?.communities.map(({ id, name, location }) => {
    const stringified = id.toString();
    return {
      name: 'location',
      label: name,
      key: stringified,
      value: stringified,
      id: stringified,
      type: 'Standard',
      timeZone: location?.timeZone || undefined,
      country: location?.country || undefined,
    };
  });

  return mapped;
};

export const agencyLocationOptionsTransformer = (data?: locationDropdownData) => {
  if (!data) return [];

  const me = data.me as locationDropdownData_me_AgencyUser;

  const mapped: LocationOptionType[] = me.agency
    ? me?.agency?.agencyCommunities.map((community) => {
        const stringified = community!.id.toString();
        return {
          latitude: community.location?.latitude,
          longitude: community.location?.longitude,

          name: 'location',
          label: community!.name,
          key: stringified,
          value: stringified,
          id: stringified,
          type: 'Standard',
          timeZone: community?.location?.timeZone || undefined,
          country: community?.location?.country || undefined,
        };
      })
    : [];
  return mapped;
};

const client = createApolloClient();

export const useLocationFilterQueryConfig = (): UseGQLQueryConfig<
  locationDropdownData,
  LocationOptionType[],
  {}
> => {
  client.setLink(authLink.concat(httpLink()));
  return useUserTypeSwitch({
    B2B: {
      key: 'LOCATION_FILTER_B2B',
      query: QUERY_locationDropdownData,
      responseTransformer: locationOptionsTransformer,
      client,
    },
    Agency: {
      key: 'LOCATION_FILTER_AGENCY',
      query: QUERY_locationDropdownData,
      responseTransformer: agencyLocationOptionsTransformer,
      client,
    },
  });
};
