import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { FeaturePermissionLevelType } from 'types/common.types';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isSchedulingFeatureEnabledSelector = createSelector(
  getUserPermissions,
  (permissions) => {
    let isOrganizationLevelSchedulingEnabled = false;

    if (permissions?.SCHEDULING?.access)
      isOrganizationLevelSchedulingEnabled = permissions.SCHEDULING.access.includes('read');
    else return false;

    let readAccessFacilityIds: string[] = [];

    if (!!permissions?.SCHEDULING?.communities)
      Object.entries(
        permissions?.SCHEDULING?.communities as { [key: string]: FeaturePermissionLevelType[] },
      ).forEach(([key, arr]: [string, FeaturePermissionLevelType[]]) => {
        if (arr.includes('read')) readAccessFacilityIds.push(key);
      });

    const isThereAtLeastOneCommunityWithSchedulingEnabled = readAccessFacilityIds.length > 0;

    return isOrganizationLevelSchedulingEnabled && isThereAtLeastOneCommunityWithSchedulingEnabled;
  },
);
