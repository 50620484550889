import React, { useState } from 'react';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { IRecurrenceListProps } from 'components/RecurrenceList/RecurrenceList.types';
import {
  RecurrenceListLabelWrapper,
  RecurrenceListLabel,
  RecurrenceListShift,
} from 'components/RecurrenceList/RecurrenceList.styled';
import { useTranslation } from 'hooks/useTranslation';

export const RecurrenceList: React.FC<IRecurrenceListProps> = ({ shifts }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(true);

  const handleShiftClick = (shiftId: number) => {
    shiftModalRoutingService.open({ shiftModalShiftId: shiftId }, 'DetailView');
  };

  return (
    <Flex flexDirection="column" childMarginBottom="11px">
      <RecurrenceListLabelWrapper
        alignItems="center"
        childMarginRight="9.5px"
        onClick={() => setOpen(!isOpen)}
      >
        <RecurrenceListLabel>{t('schedule:SHIFTS_IN_RECURRENCE')}</RecurrenceListLabel>
        <BKJIcon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} />
      </RecurrenceListLabelWrapper>
      {isOpen && (
        <Flex flexDirection="column" childMarginBottom="11px">
          {shifts.map(({ id, orderSchedule }) => (
            <RecurrenceListShift onClick={() => handleShiftClick(id)} key={id}>
              {t('dashboard:SHIFT_NUMBER')}
              {orderSchedule.id}
            </RecurrenceListShift>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
