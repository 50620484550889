import { WHITE_LABEL } from 'config';

enum WhiteLabelProvidersEnum {
  ACCUSHIELD = 'accushield',
  PETAL = 'petal',
  XTM = 'xtm',
  WHITE_LABEL_DEMO = 'white_label_demo',
}

const WhiteLabelProviders = Object.values(WhiteLabelProvidersEnum) as string[];

function isWhiteLabelled() {
  return WhiteLabelProviders.includes(WHITE_LABEL);
}

const whiteLabelProvider: Nullable<WhiteLabelProvidersEnum> = !isWhiteLabelled()
  ? null
  : (WHITE_LABEL as WhiteLabelProvidersEnum);

const isAccushieldProvider = whiteLabelProvider === WhiteLabelProvidersEnum.ACCUSHIELD;
const isPetalProvider = whiteLabelProvider === WhiteLabelProvidersEnum.PETAL;
const isXtmProvider = whiteLabelProvider === WhiteLabelProvidersEnum.XTM;
const isWhiteLabelDemoProvider = whiteLabelProvider === WhiteLabelProvidersEnum.WHITE_LABEL_DEMO;

export const WhiteLabelAccessor = {
  WhiteLabelProvidersEnum,
  isWhiteLabelled,
  isAccushieldProvider,
  isPetalProvider,
  isWhiteLabelDemoProvider,
  isXtmProvider,
  whiteLabelProvider,
};
