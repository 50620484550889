import { useGroupedFormsBehaviors } from 'hooks';
import {
  GroupedFormsBehaviorsOnSubmitArgsType,
  GroupedFormsOnSubmitResultType,
} from 'hooks/useGroupedFormsBehaviors/useGroupedFormsBehaviors.types';
import { createShiftsPageInitialState } from 'pages/CreateShiftsPage/CreateShiftsPage.constants';
import {
  CreateShiftsPageViewType,
  ICreateShiftsPageContext,
} from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import {
  createOrdersPayloadTransformer,
  getInitialCreateShiftForm,
  getInitialCreateShiftsPageForm,
} from 'pages/CreateShiftsPage/CreateShiftsPage.utils';
import { FC, MouseEventHandler, useCallback, useContext, useState, useEffect } from 'react';
import { API_POST_createOrders } from 'requests/POST_createOrders';
import { toastService } from 'services';
import { withMemo } from 'utils/withMemo';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { history } from 'utils/history';
import isUserEssentialSelector from 'store/selectors/userSelectors/isUserEssentialSelector';
import { useSelector } from 'react-redux';
import { createGQLDataContext } from 'utils/createDataContext';
import { formatPlural } from 'utils/formatPlural';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { SSF_GLOBAL_CACHE_KEY } from 'services/ssfPersistenceService';
import { useTranslation } from 'hooks/useTranslation';
import { useGQLQuery } from 'hooks';
import {
  fetchCommunityBiddingPeriod,
  fetchCommunityBiddingPeriodVariables,
} from 'types/graphql-types';
import { communityBiddingPeriodConfig } from 'pages/CreateShiftsPage/CreateShiftsPage.constants';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';

export const CreateShiftsPageContext = createGQLDataContext<{}>(createShiftsPageInitialState);

export const CreateShiftsPageProvider: FC = withMemo(({ children }): JSX.Element => {
  const [view, setView] = useState<CreateShiftsPageViewType>('CREATE_SHIFTS');
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] = useState<boolean>(false);
  const [pendingConfirmationEvent, setPendingConfirmationEvent] = useState<ChangeEventType>(null);

  const { apiRequest } = useAPIActions();
  const location = getCurrentlySelectedLocation();
  const isUserEssential = useSelector(isUserEssentialSelector);
  const { t } = useTranslation();
  const isShiftBiddingFeatureEnabled = useSelector(isShiftBiddingFeatureEnabledSelector);
  const handleSubmit = useCallback(
    async ({
      groupedFormsValues,
      values,
    }: GroupedFormsBehaviorsOnSubmitArgsType): Promise<GroupedFormsOnSubmitResultType> => {
      await apiRequest(
        API_POST_createOrders(
          createOrdersPayloadTransformer({
            groupedFormsValues,
            values,
            isUserEssential,
            isShiftBiddingFeatureEnabled,
          }),
        ),
        {
          onSuccess: (res) => {
            const count = res?.data?.orders?.length;
            history.push('/dashboard/recent');
            toastService.success(
              `${count} ${formatPlural(t('success:SHIFT'), count)} ${t('success:CREATED')}.`,
            );
          },
          onError: handleGenericErrorMessage,
        },
      );
      return {};
    },
    [apiRequest, isUserEssential, isShiftBiddingFeatureEnabled, t],
  );

  const groupedFormsBehaviors = useGroupedFormsBehaviors({
    initialGroupedFormsState: [
      getInitialCreateShiftForm({
        isUserEssential,
      }),
    ],
    initialState: getInitialCreateShiftsPageForm({
      initialValues: {
        location: location,
        request_janes: [],
        estimatedTotalPayout: 0,
      },
    }),
    onSubmit: handleSubmit,
    isDirtyCheckEnabled: true,
    cacheConfig: {
      location: {
        cacheStorage: 'localStorage',
        cachekey: SSF_GLOBAL_CACHE_KEY(),
      },
    },
  });

  const { values, onChange, isSubmitDisabled, forceValidateForm, resetGroupedForms } =
    groupedFormsBehaviors;
  const { data: biddingPeriodData, fetch: fetchBiddingPeriod } = useGQLQuery<
    fetchCommunityBiddingPeriod,
    fetchCommunityBiddingPeriod,
    fetchCommunityBiddingPeriodVariables,
    fetchCommunityBiddingPeriodVariables
  >(communityBiddingPeriodConfig(parseInt(values.location.value)));

  useEffect(() => {
    if (!biddingPeriodData) {
      fetchBiddingPeriod();
    }
  }, [fetchBiddingPeriod, biddingPeriodData]);

  const handleChange = (e: ChangeEventType) => {
    onChange(e);
    resetGroupedForms();
    setView('CREATE_SHIFTS');
    setPendingConfirmationEvent(null);
    setIsDiscardChangesDialogOpen(false);
  };

  const handleDiscardChangesDialogCancel = () => {
    setPendingConfirmationEvent(null);
    return setIsDiscardChangesDialogOpen(false);
  };

  const handleDiscardChangesDialogAction = () => {
    if (pendingConfirmationEvent) {
      onChange(pendingConfirmationEvent);
      resetGroupedForms();
      setView('CREATE_SHIFTS');
      setPendingConfirmationEvent(null);
      setIsDiscardChangesDialogOpen(false);
    }
  };

  const handleConfirmBooking: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      e.preventDefault();
      if (isSubmitDisabled) {
        forceValidateForm();
      } else {
        setView('SHIFTS_SUMMARY');
      }
    },
    [forceValidateForm, isSubmitDisabled],
  );
  return (
    <CreateShiftsPageContext.Provider
      value={{
        groupedFormsBehaviors,
        view,
        biddingPeriods: biddingPeriodData,
        goToView: setView,
        values,
        onChange: handleChange,
        onConfirmBooking: handleConfirmBooking,
        fetch,
        isDiscardChangesDialogOpen,
        handleDiscardChangesDialogCancel,
        handleDiscardChangesDialogAction,
      }}
    >
      {children}
    </CreateShiftsPageContext.Provider>
  );
});

export const useCreateShiftsPageContext = (): ICreateShiftsPageContext =>
  useContext(CreateShiftsPageContext);
