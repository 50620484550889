import styled from 'styled-components';

export const CriteriaBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

export const CriteriaSubHeader = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  padding-top: 25px;
  padding-bottom: 24px;
`;

export const CriteriaColumn = styled.div<{ colspan?: number }>`
  width: ${({ colspan = 1 }) => `${25 * colspan}%`};

  font-size: 14px;
  margin-right: 5px;

  padding-bottom: 5px;
`;

export const SelectCriteriaWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 15px;

  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  align-items: 'flex-start';
  width: 100%;
`;
