import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { LoginFormSchema } from 'pages/LoginPage/LoginPage';
import { isString } from 'types/common.types';
import i18next from 'i18next';

export const VALIDATION_password: UseFormBehaviors_ValidationFnType<LoginFormSchema> = (value) => {
  const messages: string[] = [];
  if (isString(value)) {
    if (value.length === 0) messages.push(i18next.t('auth:PLEASE_ENTER_YOUR_PASSWORD'));
  }
  return messages;
};
