import { isUserB2bSelector } from 'store/selectors/userSelectors/isUserB2bSelector';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import isUserAgencyPrimarySelector from 'store/selectors/userSelectors/isUserAgencyPrimarySelector';
import isUserAgencySecondarySelector from 'store/selectors/userSelectors/isUserAgencySecondarySelector';
import isUserEssentialSelector from 'store/selectors/userSelectors/isUserEssentialSelector';
import isUserEssentialPrimarySelector from 'store/selectors/userSelectors/isUserEssentialPrimarySelector';
import isUserEssentialSecondarySelector from 'store/selectors/userSelectors/isUserEssentialSecondarySelector';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import isUserPremiumPrimarySelector from 'store/selectors/userSelectors/isUserPremiumPrimarySelector';
import isUserPremiumSecondarySelector from 'store/selectors/userSelectors/isUserPremiumSecondarySelector';
import { isUserApprovedSelector } from 'store/selectors/userSelectors/isUserApprovedSelector';
import { isUserPendingApprovalSelector } from 'store/selectors/userSelectors/isUserPendingApprovalSelector';

export const USER_GUARD_SELECTOR_MAP = {
  USER_TYPE_B2B: isUserB2bSelector,
  USER_TYPE_AGENCY: isUserAgencySelector,
  USER_TYPE_AGENCY_PRIMARY: isUserAgencyPrimarySelector,
  USER_TYPE_AGENCY_SECONDARY: isUserAgencySecondarySelector,
  USER_TYPE_ESSENTIAL: isUserEssentialSelector,
  USER_TYPE_ESSENTIAL_PRIMARY: isUserEssentialPrimarySelector,
  USER_TYPE_ESSENTIAL_SECONDARY: isUserEssentialSecondarySelector,
  USER_TYPE_PREMIUM: isUserPremiumSelector,
  USER_TYPE_PREMIUM_PRIMARY: isUserPremiumPrimarySelector,
  USER_TYPE_PREMIUM_SECONDARY: isUserPremiumSecondarySelector,
  USER_TYPE_APPROVED: isUserApprovedSelector,
  USER_TYPE_PENDING: isUserPendingApprovalSelector,
};
