import { B2B_CREATE_TIME_OFF } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import { API_POST_createTimeOffParamsType } from 'requests/POST_createTimeOff.types';

export const API_POST_createTimeOff = (
  requestBody: API_POST_createTimeOffParamsType,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: B2B_CREATE_TIME_OFF,
    method: METHOD_POST,
    body: requestBody,
  };
};
