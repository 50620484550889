import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';
import { BKJIcon } from '@bookjane2/bookjane-design-library';

export const JaneGridWrapper = styled(Flex)``;

export const BKJImageWrapper = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    height: auto;
    max-height: 27vw;
    min-height: 96px;
  }
`;

export const JaneGridErrorText = styled.span`
  max-width: 345px;
  text-align: center;

  font-size: 16px;
  line-height: 24px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;

export const JaneGridCard = styled.label`
  display: flex;
  width: calc(33% - 10px);
  margin: 8px;
  background: #ffffff;
  overflow: hidden;
  height: 278px;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.2);
  will-change: box-shadow;
  transition: box-shadow 200ms ease-in;
  @media (min-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:nth-child(3n - 8) {
      margin-left: 0;
    }
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.5);
  }

  &:focus {
    box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.5);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    margin: 16px;
    width: calc(45% - 5px);
    &:nth-child(2n + 2) {
      margin-right: 0;
    }
    &:nth-child(2n - 8) {
      margin-left: 0;
    }
  }
`;

export const JaneGridJaneName = styled.span`
  display: block;
  font-size: 16px;
  line-height: 24px;
  max-height: 50px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;
export const JanePendingSignUp = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  ${({ theme }) => theme.fonts.PoppinsLight};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;
export const StyledBKJIcon = styled(BKJIcon)<{
  left: string;
  top: string;
}>`
  position: absolute;
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `};
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `};
`;
export const PendingSignUpName = styled.span<{ isFrenchFormat: boolean }>`
  margin-left: ${({ isFrenchFormat }) => (isFrenchFormat ? '25px' : '10px')};
`;
export const JaneGridAgencyName = styled.span`
  display: block;
  font-size: 14px;
  line-height: 21px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};

  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  ${({ theme }) => theme.common.Ellipsis};
`;

export const JaneGridSelectButton = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.Grey1};
  border-radius: 0px 0px 8px 8px;
`;

export const JaneGridText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const JaneGridGreyText = styled(JaneGridText)`
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const JaneProfilePic = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const JaneConflictTooltipWrapper = styled(Flex)`
  width: 290px;
`;
