import { QUERY_shiftSummaryViewTeamMembers } from 'queries';
import { shiftSummaryViewTeamMembersVariables } from 'types/graphql-types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';

export const shiftSummaryViewTeamMembersConfig = ({
  byJaneIds,
}: shiftSummaryViewTeamMembersVariables): ISSFBehaviorsOptions => {
  return {
    key: 'SHIFT-SUMMARY-VIEW_TEAM-MEMBERS',
    query: QUERY_shiftSummaryViewTeamMembers,
    schema: {
      byJaneIds: {
        initialValue: byJaneIds,
      },
    },
  };
};
