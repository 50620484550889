import { fetchTimeOffCustomFieldsQueryConfig } from 'components/TimeOffModal/TimeOffModal.constants';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useGQLQuery, useURLParameters } from 'hooks';
import { UseGQLQuery, UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_timeOffModalFetchTimeOffById } from 'queries';
import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectedScheduleFacilityIdSelector } from 'store/selectors/scheduleSelectors/selectedScheduleFacilityIdSelector';
import { GQLAPIContextType } from 'types/common.types';
import {
  fetchTimeOffCustomFields_fetchCustomFields_nodes,
  timeOffModalFetchTimeOffById,
  timeOffModalFetchTimeOffByIdVariables,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const EditTimeOffViewDataContext = createGQLDataContext({}) as GQLAPIContextType;

export function fetchTimeOffQueryConfig({
  timeOffId,
}: {
  timeOffId: string;
}): UseGQLQueryConfig<
  timeOffModalFetchTimeOffById,
  timeOffModalFetchTimeOffById,
  timeOffModalFetchTimeOffByIdVariables
> {
  return {
    key: 'TIME_OFF_MODAL_QUERY_CONFIG',
    query: QUERY_timeOffModalFetchTimeOffById,
    variables: {
      timeOffId: timeOffId,
    },
  };
}

export const EditTimeOffViewDataProvider: FC = ({ children }) => {
  const [{ timeOffModalTimeOffId }] =
    useURLParameters<{
      timeOffModalTimeOffId: string;
    }>();

  const fetchTimeOffValues = useGQLQuery<
    timeOffModalFetchTimeOffById,
    timeOffModalFetchTimeOffById,
    timeOffModalFetchTimeOffByIdVariables
  >(fetchTimeOffQueryConfig({ timeOffId: timeOffModalTimeOffId }));

  const selectedFacilityId = useSelector(selectedScheduleFacilityIdSelector);
  const fetchTimeOffCustomFieldsGQL = useGQLQuery(
    fetchTimeOffCustomFieldsQueryConfig({ byFacilityId: selectedFacilityId }),
  );

  const { data: fetchTimeOffCustomFields } = fetchTimeOffCustomFieldsGQL;
  const isCustomFieldsEnabled = useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']);

  useEffect(() => {
    if (!!timeOffModalTimeOffId) fetchTimeOffValues.fetch();
    if (isCustomFieldsEnabled && !!selectedFacilityId) fetchTimeOffCustomFieldsGQL.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOffModalTimeOffId]);

  const timeOffCustomFields = Object.values(fetchTimeOffCustomFields);

  const formValues = {
    ...fetchTimeOffValues,
    timeOffCustomFields,
  };

  return (
    <EditTimeOffViewDataContext.Provider value={formValues}>
      {children}
    </EditTimeOffViewDataContext.Provider>
  );
};

export interface IEditTimeOffViewDataContext {
  timeOffCustomFields: fetchTimeOffCustomFields_fetchCustomFields_nodes[];
}

export const useEditTimeOffViewDataContext = (): IEditTimeOffViewDataContext &
  UseGQLQuery<timeOffModalFetchTimeOffById> => useContext(EditTimeOffViewDataContext);
