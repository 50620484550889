export type QUERY_REFETCH_TOPICS =
  | 'CUSTOM_FIELD'
  | 'SHIFT_CARD'
  | 'TIMESHEETS_CARD'
  | 'DASHBOARD_PAGE'
  | 'TEAM_MEMBERS_SETTINGS_PAGE_TABLE_ROW';
class QueryRefetchService {
  private refetchMap: Record<QUERY_REFETCH_TOPICS, Record<string, () => void>> = {
    SHIFT_CARD: {},
    TIMESHEETS_CARD: {},
    CUSTOM_FIELD: {},
    DASHBOARD_PAGE: {},
    TEAM_MEMBERS_SETTINGS_PAGE_TABLE_ROW: {},
  };

  public addDataQuery(
    topic: QUERY_REFETCH_TOPICS,
    key: string | number,
    refetchFunc: () => void,
  ): void {
    const parsedKey = `${key}`;
    this.refetchMap[topic][parsedKey] = refetchFunc;
  }

  public removeDataQuery(topic: QUERY_REFETCH_TOPICS, key: string | number) {
    const parsedKey = `${key}`;
    delete this.refetchMap[topic][parsedKey];
  }

  public refetchDataQueryByKey(topic: QUERY_REFETCH_TOPICS, key: string | number): void {
    const parsedKey = `${key}`;
    if (this.refetchMap[topic][parsedKey]) this.refetchMap[topic][parsedKey]();
    else console.warn(`[topic: ${topic}][key: ${key}] is not a valid refetch entry`);
  }
}

export const queryRefetchService = new QueryRefetchService();
