import { customFieldEditModalRoutingService } from 'components/CustomFieldEditModal/contexts/CustomFieldModalEdit.ui.context';
import { createContext, FC, useContext } from 'react';

interface ICustomFieldEditModalProps {
  goToEditFacilitiesView: () => void;
  goToDeleteCustomFieldView: () => void;
  onClose: () => void;
}

export const CustomFieldEditModalPropsContext = createContext<Partial<ICustomFieldEditModalProps>>({
  goToEditFacilitiesView: () => null,
  goToDeleteCustomFieldView: () => null,
  onClose: () => null,
});

export const CustomFieldEditModalPropsProvider: FC = ({ children }) => {
  const contextValue = {
    goToEditFacilitiesView: () => customFieldEditModalRoutingService.goToView('EditFacilitiesView'),
    goToDeleteCustomFieldView: () =>
      customFieldEditModalRoutingService.goToView('DeleteCustomFieldView'),
    onClose: () => customFieldEditModalRoutingService.close(),
  };

  return (
    <CustomFieldEditModalPropsContext.Provider value={contextValue}>
      {children}
    </CustomFieldEditModalPropsContext.Provider>
  );
};

export const useCustomFieldEditModalPropsContext = () =>
  useContext(CustomFieldEditModalPropsContext);
