import { DetailViewActiveTabType } from './DetailView.types';

export const PATH_DETAIL_VIEW_TAB_MAP = (pathname: string): DetailViewActiveTabType => {
  switch (pathname) {
    case '/timesheets':
      return 'TimesheetsTab';
    default:
      return 'GeneralTab';
  }
};
