import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import {
  GeneralLocationSettingsFormApiError as GLSFApiError,
  GeneralLocationSettingsFormFields as GLSFFields,
} from 'pages/LocationSettingsPage/GeneralLocationSettingsPageForm.types';
import { useLocationRecordDetailDataContext } from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { createContext, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { API_PUT_updateAgencyCommunityDetails } from 'requests/PUT_updateAgencyCommunityDetails';
import { API_PUT_updateCommunityDetails } from 'requests/PUT_updateCommunityDetails';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { VALIDATION_email, VALIDATION_required } from 'validations';
import { useTranslation } from 'hooks/useTranslation';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';

const GeneralLocationSettingsFormFormContext = createContext<
  UseFormBehaviors<GLSFFields, GLSFApiError>
>({} as UseFormBehaviors<GLSFFields, GLSFApiError>);

export const GeneralLocationSettingsFormFormProvider: FC = ({ children }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data } = useLocationRecordDetailDataContext();
  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();

  const form = useFormBehaviors<GLSFFields, GLSFApiError>({
    initialState: data,
    onSubmit: useUserTypeSwitch({
      Agency: (formValues: GLSFFields) => {
        return apiRequest(API_PUT_updateAgencyCommunityDetails(locationId, formValues), {
          onError: handleGenericErrorMessage,
          onSuccess: () => {
            toastService.success(t('settings:Successfully_updated'));
          },
        });
      },
      B2B: (formValues: GLSFFields) => {
        return apiRequest(
          API_PUT_updateCommunityDetails(locationId, {
            contactEmail: formValues.email,
            contactPhone: formValues.phone,
            firstName: formValues.first_name,
            lastName: formValues.last_name,
            name: formValues.name,
            locationAttributes: {
              id: formValues.id,
              longitude: formValues.longitude,
              latitude: formValues.latitude,
              flat: formValues.flat,
              street: formValues.street,
              street_number: formValues.street_number,
              city: formValues.city,
              state: formValues.state,
              country: formValues.country,
              zip_code: formValues.zip_code,
              full_address: formValues.full_address,
            },
          }),
          {
            onError: handleGenericErrorMessage,
            onSuccess: () => {
              toastService.success(t('settings:Successfully_updated'));
            },
          },
        );
      },
    }),
    validations: useUserTypeSwitch({
      Agency: {
        name: VALIDATION_required,
        full_address: VALIDATION_required,
        first_name: VALIDATION_required,
        last_name: VALIDATION_required,
        email: VALIDATION_email,
        phone: VALIDATION_required,
      },
      B2B: {
        name: VALIDATION_required,
        full_address: VALIDATION_required,
        first_name: VALIDATION_required,
        last_name: VALIDATION_required,
        email: VALIDATION_email,
        phone: VALIDATION_required,
      },
    }),
  });

  return (
    <GeneralLocationSettingsFormFormContext.Provider value={form}>
      {children}
    </GeneralLocationSettingsFormFormContext.Provider>
  );
};

export const useGeneralLocationSettingsFormFormContext = () =>
  useContext<UseFormBehaviors<GLSFFields, GLSFApiError>>(GeneralLocationSettingsFormFormContext);
