import styled, { css } from 'styled-components';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { IMoreOptionsIconProps, IPageWrapperProps } from 'components/PageWrapper/PageWrapper.types';
import { elementDimensions } from 'constants/elementDimensions';

export const PageWrapper = styled.main<IPageWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - ${elementDimensions.NavigationWidth}px);
  width: calc(100vw - ${elementDimensions.NavigationWidth}px);
  min-width: calc(100vw - ${elementDimensions.NavigationWidth}px);
  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    max-width: calc(100vw);
    width: calc(100vw);
    min-width: calc(100vw);
  }
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: overlay;
  background-color: ${({ theme }) => theme.colors.White};

  ${({ background }) =>
    background &&
    css`
      background-image: url('${background}');
      background-size: cover;
    `};
  ${({ $width }) => {
    if ($width)
      return css`
        width: ${$width};
      `;
  }};
  ${({ $minWidth }) => {
    if ($minWidth)
      return css`
        min-width: ${$minWidth};
      `;
  }};
  ${({ $maxWidth }) => {
    if ($maxWidth)
      return css`
        max-width: ${$maxWidth};
      `;
  }};
`;

export const MoreOptionsIcon = styled(BKJIcon)<IMoreOptionsIconProps>`
  cursor: pointer;
  margin-left: 25px;
  display: flex;
  color: ${(props) => props.theme.colors.TextHighlight2};
`;
