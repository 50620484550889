import { BKJButton, BKJIcon, BKJPagination } from '@bookjane2/bookjane-design-library';
import { BKJTableFooter } from 'components/BKJTable/BKJTable.styled';
import { Flex } from 'components/Flex';
import { AgencyMemberPageContext } from 'pages/AgencyMemberPage/AgencyMemberPage.context';
import { DedicatedTeamTable } from 'pages/AgencyMemberDedicatedTeamsPage/DedicatedTeamTable';
import { useContext } from 'react';
import { css } from 'styled-components';

export function AgencyMemberDedicatedTeamsPage() {
  const { prevPage, nextPage, firstPage, lastPage, currentPage, data } =
    useContext(AgencyMemberPageContext);
  return (
    <>
      <DedicatedTeamTable />
      <BKJTableFooter>
        <Flex width="100%" justifyContent="space-between">
          <BKJPagination
            currentPage={currentPage}
            pageCount={data?.fetchJanes?.pageCount}
            onFirstPage={firstPage}
            onPrevPage={prevPage}
            onNextPage={nextPage}
            onLastPage={lastPage}
          />
          <BKJButton
            variant="PurpleSolid"
            width="207px"
            onClick={() =>
              window.alert('Clicking on this button will open the dedicated team panel')
            }
          >
            <BKJIcon
              iconName="Add"
              color="White"
              secondaryColor="PrimaryDefault"
              css={css`
                margin-right: 12px;
              `}
            />
            New Team
          </BKJButton>
        </Flex>
      </BKJTableFooter>
    </>
  );
}
