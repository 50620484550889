import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useFetchAgenciesByOrderIdDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SelectAgenciesView/contexts/FetchAgenciesByOrderId.data.context';
import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ISelectAgenciesViewProps {
  fetchAgenciesNoAgenciesErrorMessage: string;
  isOpen: boolean;
  handleFilterClick: () => void;
}

export const SelectAgenciesViewPropsContext = createContext<ISelectAgenciesViewProps>(
  {} as ISelectAgenciesViewProps,
);

export const SelectAgenciesViewPropsProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [, { removeParam }] = useURLParameters();

  useEffect(() => {
    if (!shiftModalRoutingService.isBackButtonVisible())
      removeParam(['shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [removeParam]);

  const [isOpen, setOpen] = useState(false);

  const { data, values: fetchAgenciesFilterValues } = useFetchAgenciesByOrderIdDataContext();

  const [fetchAgenciesNoAgenciesErrorMessage, setFetchAgenciesNoAgenciesErrorMessage] =
    useState('');

  useEffect(() => {
    if (data?.fetchAgencyCommunities.nodes?.length === 0) {
      if (
        !fetchAgenciesFilterValues?.byRateLessThanOrEqualTo &&
        !fetchAgenciesFilterValues?.byName
      ) {
        setFetchAgenciesNoAgenciesErrorMessage(t('errors:NO_AGENCIES_AVAILABLE'));
      } else {
        setFetchAgenciesNoAgenciesErrorMessage(t('errors:NO_SEARCH_RESULT'));
      }
    } else {
      setFetchAgenciesNoAgenciesErrorMessage('');
    }
  }, [
    data?.fetchAgencyCommunities.nodes?.length,
    fetchAgenciesFilterValues?.byName,
    fetchAgenciesFilterValues?.byRateLessThanOrEqualTo,
    t,
  ]);

  const contextValue = useMemo(
    () => ({
      isOpen,
      fetchAgenciesNoAgenciesErrorMessage,
      handleFilterClick: () => setOpen(!isOpen),
    }),
    [fetchAgenciesNoAgenciesErrorMessage, isOpen],
  );

  return (
    <SelectAgenciesViewPropsContext.Provider value={contextValue}>
      {children}
    </SelectAgenciesViewPropsContext.Provider>
  );
};

export const useSelectAgenciesViewPropsContext = (): ISelectAgenciesViewProps =>
  useContext(SelectAgenciesViewPropsContext);
