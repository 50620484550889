import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_shiftCardFetchOrder } from 'queries';
import { shiftCardFetchOrder } from 'types/graphql-types';

export const fetchOrderQueryConfig = (
  orderId: number,
): UseGQLQueryConfig<shiftCardFetchOrder, shiftCardFetchOrder, { orderId: number }> => ({
  key: 'FETCH_ORDER_QUERY',
  query: QUERY_shiftCardFetchOrder,
  variables: {
    orderId,
  },
});
