import i18next from 'i18next';
import { DateTime } from 'luxon';
import { ISO8601Type, ISO8601TupleType } from 'types/common.types';

export const getCalendarMonthRange = (date: ISO8601Type): ISO8601TupleType => {
  const firstDayOfMonth = DateTime.fromISO(date).startOf('month');
  const isFirstDayOfMonthSunday = firstDayOfMonth.weekday === 7;
  let startDate = firstDayOfMonth;
  if (!isFirstDayOfMonthSunday) {
    startDate = firstDayOfMonth.minus({ day: firstDayOfMonth.weekday % 7 });
  }

  const lastDayOfMonth = DateTime.fromISO(date).endOf('month').plus({ day: 1 });
  const isLastDayOfMonthSaturday = lastDayOfMonth.weekday === 6;
  let endDate = lastDayOfMonth;
  if (!isLastDayOfMonthSaturday) {
    const numDaysToAddToRange = 7 - lastDayOfMonth.weekday;
    endDate = lastDayOfMonth.plus({ day: numDaysToAddToRange });
  }

  return [startDate.toString(), endDate.toString()];
};

export const getOneMonthRangeFromDate = (date: ISO8601Type): ISO8601TupleType => {
  const startDate = DateTime.fromISO(date).startOf('day');
  const endDate = startDate.endOf('day').plus({ month: 1 }).minus({ day: 1 });
  return [startDate.toString(), endDate.toString()];
};

export const getOneWeekRangeFromDate = (date: ISO8601Type): ISO8601TupleType => {
  const startDate = DateTime.fromISO(date);
  const endDate = startDate.endOf('day').plus({ week: 1 }).minus({ day: 1 });
  return [startDate.toString(), endDate.toString()];
};

export const getSingleDateRangeFromDate = (date: ISO8601Type): ISO8601TupleType => {
  const startDate = DateTime.fromISO(date).startOf('day');
  const endDate = DateTime.fromISO(date).endOf('day');
  return [startDate.toString(), endDate.toString()];
};

export const getCurrentMonthCalendarRange = (): ISO8601TupleType =>
  getCalendarMonthRange(DateTime.now().toString());

export const getOneMonthRangeFromToday = (): ISO8601TupleType => {
  const startDate = DateTime.now().toString();
  return getOneMonthRangeFromDate(startDate);
};

export const getOneWeekRangeFromToday = (): ISO8601TupleType => {
  const startDate = DateTime.now().toString();
  return getOneWeekRangeFromDate(startDate);
};

export const getSingleDateRangeFromToday = (): ISO8601TupleType => {
  const startDate = DateTime.now().toString();
  return getSingleDateRangeFromDate(startDate);
};

export const getDaysArrayBetweenTwoDates = ([
  startDate,
  endDate,
]: ISO8601TupleType): Array<ISO8601Type> => {
  const numOfDaysForMonth = Math.round(
    DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'day').days,
  );
  return [...new Array(numOfDaysForMonth)].map((_, daysToAdd) =>
    DateTime.fromISO(startDate).plus({ day: daysToAdd }).toString(),
  );
};

export const getFortyTwoDaysArrayFromDate = (date: ISO8601Type) => {
  return [...new Array(42)].map((_, daysToAdd) =>
    DateTime.fromISO(date).plus({ day: daysToAdd }).toString(),
  );
};

export const getPreviousMonthCalendarRange = (date: ISO8601Type): ISO8601TupleType => {
  const dateDayOfMonth = DateTime.fromISO(date).get('day');
  const isDateFirstDayOfMonth = dateDayOfMonth === 1;
  if (isDateFirstDayOfMonth) {
    return getCalendarMonthRange(DateTime.fromISO(date).minus({ month: 1 }).toString());
  }
  return getCalendarMonthRange(DateTime.fromISO(date).set({ day: 1 }).toString());
};

export const getNextMonthCalendarRange = (date: ISO8601Type): ISO8601TupleType => {
  const dateDayOfMonth = DateTime.fromISO(date).get('day');
  const isDateFirstDayOfMonth = dateDayOfMonth === 1;
  if (isDateFirstDayOfMonth) {
    return getCalendarMonthRange(DateTime.fromISO(date).plus({ month: 1 }).toString());
  }
  return getCalendarMonthRange(DateTime.fromISO(date).plus({ month: 2 }).toString());
};

export const getOneMonthRangeFromPreviousDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const previousDay = DateTime.fromISO(date).minus({ day: 1 }).toString();
  return getOneMonthRangeFromDate(previousDay);
};

export const getOneWeekRangeFromPreviousDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const previousDay = DateTime.fromISO(date).minus({ day: 1 }).toString();
  return getOneWeekRangeFromDate(previousDay);
};

export const getSingleDateRangeFromPreviousDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const previousDay = DateTime.fromISO(date).minus({ day: 1 }).toString();
  return getSingleDateRangeFromDate(previousDay);
};

export const getOneMonthRangeFromNextDay = ({ date }: { date: ISO8601Type }): ISO8601TupleType => {
  const nextDay = DateTime.fromISO(date).plus({ day: 1 }).toString();
  return getOneMonthRangeFromDate(nextDay);
};

export const getOneWeekRangeFromNextDay = ({ date }: { date: ISO8601Type }): ISO8601TupleType => {
  const nextDay = DateTime.fromISO(date).plus({ day: 1 }).toString();
  return getOneWeekRangeFromDate(nextDay);
};

export const getSingleDateRangeFromNextDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const nextDay = DateTime.fromISO(date).plus({ day: 1 }).toString();
  return getSingleDateRangeFromDate(nextDay);
};

export const getPrettyMonthAndYear = (startDate: ISO8601Type): string => {
  const startDateDayOfMonth = DateTime.fromISO(startDate).get('day');
  const isStartDateFirstDay = startDateDayOfMonth === 1;
  const month = DateTime.fromISO(startDate).get('month');
  let nextDay;
  let year;
  const monthKey = DateTime.fromISO(startDate)
    .plus({ month: isStartDateFirstDay ? 0 : 1 })
    .toFormat('LLLL')
    .toUpperCase();

  const translatedMonth = i18next.t(`translations:${monthKey}`);
  if (month === 12) {
    nextDay = DateTime.fromISO(startDate).plus({ day: 15 });
    year = nextDay.toFormat('yyyy');
  } else {
    year = DateTime.fromISO(startDate).toFormat('yyyy');
  }
  return `${translatedMonth}, ${year}`;
};

export const getPrettyRange = ([startDate, endDate]: ISO8601TupleType): string => {
  const currentYear = DateTime.now().toFormat('yyyy');
  const startDateYear = DateTime.fromISO(startDate).toFormat('yyyy');
  const endDateYear = DateTime.fromISO(endDate).toFormat('yyyy');
  const isRangeInCurrentYear = startDateYear === currentYear && endDateYear === currentYear;
  const startDateMonth = DateTime.fromISO(startDate).toFormat('LLL').toUpperCase();
  const translatedStartDateMonth = i18next.t(startDateMonth);
  const endDateMonth = DateTime.fromISO(endDate).toFormat('LLL').toUpperCase();
  const translatedEndDateMonth = i18next.t(endDateMonth);
  return `${translatedStartDateMonth} ${DateTime.fromISO(startDate).toFormat(
    'd',
  )} - ${translatedEndDateMonth} ${DateTime.fromISO(endDate).toFormat('d')}${
    isRangeInCurrentYear ? '' : `, ${endDateYear}`
  }`;
};

export const getCustomPrettyRange = ([startDate, endDate]: ISO8601TupleType): string => {
  const start = DateTime.fromISO(startDate);
  let end = DateTime.fromISO(endDate);

  // Calculate the end date that is 2 months from the start date
  const endOfTwoMonths = start.plus({ month: 2 }).endOf('month');

  // Check if the end date is beyond 2 months from the start date
  if (end > endOfTwoMonths) {
    // If yes, set the end date to the end of the two-month span
    end = endOfTwoMonths;
  }

  const startDateFormatted = start.toFormat('d LLL');
  const endDateFormatted = end.toFormat('d LLL');

  return `${startDateFormatted} - ${endDateFormatted}`;
};

export const getCustomDateRangeFromDate = ([
  startDate,
  endDate,
]: ISO8601TupleType): ISO8601TupleType => {
  // const previousValue = startDate;
  // const nextValue = endDate;

  return [startDate, endDate];
};

export const getPrettySingleDateFromRange = ([startDate, _]: ISO8601TupleType): string => {
  const month = DateTime.fromISO(startDate).toFormat('LLL').toUpperCase();
  const translatedDateMonth = i18next.t(month);
  return `${translatedDateMonth}, ${DateTime.fromISO(startDate).toFormat('d, yyyy')}`;
};

export const getPrettyDateFromDate = (date: ISO8601Type): string => {
  return DateTime.fromISO(date).toFormat('LLL d, yyyy');
};

export const getPrettyDayAndDateFromDate = (date: ISO8601Type, omitYear = true): string => {
  const isWithinCurrentYear = DateTime.fromISO(date).get('year') === DateTime.now().get('year');

  const dayOfWeek = DateTime.fromISO(date).toFormat('ccc').toUpperCase();
  const month = DateTime.fromISO(date).toFormat('LLL').toUpperCase();
  const TranslatedDayOfWeek = i18next.t(`translations:${dayOfWeek}`);
  const TranslatedMonth = i18next.t(`translations:${month}`);
  if (omitYear && isWithinCurrentYear) {
    return `${TranslatedDayOfWeek}, ${TranslatedMonth} ${DateTime.fromISO(date).toFormat('d')}`;
  }

  return `${TranslatedDayOfWeek}, ${TranslatedMonth} ${DateTime.fromISO(date).toFormat('d, yyyy')}`;
};

export const isDateInCurrentMonth = (date: ISO8601Type): boolean => {
  return DateTime.now().get('month') === DateTime.fromISO(date).get('month');
};

export const getFirstDayOfMonthInsideDateRange = (dateRange: ISO8601TupleType): ISO8601Type => {
  const startDate = dateRange[0];
  const startDateDayOfMonth = DateTime.fromISO(startDate).get('day');
  if (startDateDayOfMonth === 1) {
    return startDate;
  } else {
    return DateTime.fromISO(startDate).plus({ month: 1 }).set({ day: 1 }).toString();
  }
};

export const getFirstDayOfMonthFromDate = (date: ISO8601Type): ISO8601Type => {
  const startDateDayOfMonth = DateTime.fromISO(date).get('day');
  if (startDateDayOfMonth === 1) {
    return DateTime.fromISO(date).startOf('day').toString();
  } else {
    return DateTime.fromISO(date).plus({ month: 1 }).set({ day: 1 }).toString();
  }
};

export const getFirstDayOfThisMonth = () =>
  getFirstDayOfMonthFromDate(DateTime.now().startOf('month').toString());
