import { BKJTableHead } from 'components/BKJTable/BKJTable.styled';
import { BKJTableSubHeader } from 'components/BKJTable/BKJTableSubHeader';
import { AgencyMemberTableHeadRow } from 'pages/AgencyMemberTablePage/AgencyMemberTable/AgencyMemberTableHeader.styled';

export function AgencyMemberTableHeader() {
  return (
    <AgencyMemberTableHeadRow>
      <BKJTableHead>
        <BKJTableSubHeader name="jane" title="Team Member" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="agency" title="Agency" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="position" title="Position" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="appID" title="App ID" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="status" title="Status" direction="default" />
      </BKJTableHead>
      <BKJTableHead>
        <BKJTableSubHeader name="action" title="Action" direction="default" />
      </BKJTableHead>
    </AgencyMemberTableHeadRow>
  );
}
