import {
  ApplyFacilitiesFormSchema,
  CustomFieldInfoFormSchema,
  CustomFieldTypeMap,
} from 'components/CustomFieldModal/CustomFieldModal.types';
import { B2B_CREATE_CUSTOM_FIELDS } from 'constants/endpoints';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_POST_customField = (
  customFieldInfoFormPayload: CustomFieldInfoFormSchema,
  applyFacilitiesFormPayload: ApplyFacilitiesFormSchema,
): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    url: B2B_CREATE_CUSTOM_FIELDS,
    method: METHOD_POST,
    body: {
      community_ids: applyFacilitiesFormPayload.communityIds.map((communityId) =>
        Number.parseInt(communityId),
      ),
      custom_field: {
        field_type: CustomFieldTypeMap[customFieldInfoFormPayload.type[0]],
        name: customFieldInfoFormPayload.name,
        mobile: customFieldInfoFormPayload.mobile,
        placeholder: customFieldInfoFormPayload.placeholder,
        prefill_default: customFieldInfoFormPayload.preFillDefault,
        all_communities: applyFacilitiesFormPayload.allFacilities,
        custom_field_options_attributes: customFieldInfoFormPayload.dropdownOptions.map(
          (dropdownOption) => ({
            value: dropdownOption,
          }),
        ),
      },
    },
  };
};
