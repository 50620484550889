import styled from 'styled-components';
import { H3 } from 'components/Typography';
import { Flex } from 'components/Flex';

export const UserProfileHeader = styled(H3)`
  /* color: ${({ theme }) => theme.colors.AccentPurple}; */
  /* color: rgb(128, 128, 186);; */

  padding-bottom: 10px;
`;

export const UserProfileContentWrapper = styled.div`
  border-radius: 5px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: white;

  display: flex;
  margin-right: auto;

  padding: 2rem 150px;

  width: 100%;
`;

export const InputLabelText = styled.p`
  font-family: 'Poppins Medium';
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  font-style: normal;
  color: #45424e;
  margin-bottom: 4px;
`;

export const UserProfilePageWrapper = styled(Flex)`
  background-color: rgb(246, 247, 251);
  padding-bottom: 5px;
`;
