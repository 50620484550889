import { ShiftModalUIProvider } from 'components/ShiftModal/ShiftModal.ui.context';
import { ShiftModalDataProvider } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ShiftModalComponent } from 'components/ShiftModal/ShiftModal';
import { ShiftModalPropsProvider } from 'components/ShiftModal/ShiftModal.props.context';

export const ShiftModal = () => {
  return (
    <ShiftModalUIProvider>
      <ShiftModalDataProvider>
        <ShiftModalPropsProvider>
          <ShiftModalComponent />
        </ShiftModalPropsProvider>
      </ShiftModalDataProvider>
    </ShiftModalUIProvider>
  );
};
