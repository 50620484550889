import { BKJTooltipWrapper, Flex, BKJButton } from '@bookjane2/bookjane-design-library';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  CommunityName,
  LabelText,
  MainTitle,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import { SummaryInfo } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/SummaryInfo';
import { SectionDivider } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/SendToAgenciesSummaryView.styled';
import { FC, Fragment, MouseEventHandler } from 'react';
import { InfoSection } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { useFetchAgencyTeamMemberByIdDataContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMemberById.data.context';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  ConfirmShiftAssigningCancellationFeeToolTip,
  ConfirmShiftAssigningDescription,
  ConfirmShiftAssigningDescriptionBold,
  JaneInfoAgencyName,
  JaneInfoName,
} from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/AssignToAgencyTeamMemberSummaryView/AssignToAgencyTeamMemberSummaryView.styled';
import { useAssignToAgencyTeamMemberFormContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import { useTranslation } from 'hooks/useTranslation';

const ToolTipComponent: FC = () => {
  const { t } = useTranslation();
  return (
    <ConfirmShiftAssigningCancellationFeeToolTip>
      {t('ASSIGN_AGENCY_TEAM_MEMBER_CONFIRM_SHIFT_MESSAGE_6')}
    </ConfirmShiftAssigningCancellationFeeToolTip>
  );
};

export const AssignToAgencyTeamMemberSummaryViewComponent: FC = () => {
  const { data: janeInfo } = useFetchAgencyTeamMemberByIdDataContext();
  const { data: orderInfo } = useShiftModalDataContext();
  const { isSubmitting, isDisabled, onSubmit } = useAssignToAgencyTeamMemberFormContext();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Flex flexDirection="column" padding="40px 32px 0px 32px" gap="22.5px" height="100%">
        <MainTitle>{t('shift_modal:CONFIRM_SHIFT_ASSIGNMENT')}</MainTitle>
        <CommunityName>{orderInfo?.fetchOrder?.community.name}</CommunityName>
        <SummaryInfo />
        <SectionDivider variant="Thin" />
        <Flex flexDirection="column" gap="8px">
          <LabelText>{t('shift_modal:TEAM_MEMBER')}</LabelText>
          <InfoSection gap="20px" alignItems="center">
            <JaneAvatar
              status="pending"
              url={janeInfo?.fetchJane?.avatarUrls.origin}
              pendingSignup={janeInfo?.fetchJane?.pendingSignup}
            />
            <Flex flexDirection="column">
              <JaneInfoName>{`${janeInfo?.fetchJane?.firstName} ${janeInfo?.fetchJane?.lastName}`}</JaneInfoName>
              <JaneInfoAgencyName>
                {janeInfo?.fetchJane?.agencyCommunity?.agency.name}
              </JaneInfoAgencyName>
            </Flex>
          </InfoSection>
        </Flex>
        <ConfirmShiftAssigningDescription>
          {t('ASSIGN_AGENCY_TEAM_MEMBER_CONFIRM_SHIFT_MESSAGE_1')}{' '}
          <ConfirmShiftAssigningDescriptionBold>
            {t('ASSIGN_AGENCY_TEAM_MEMBER_CONFIRM_SHIFT_MESSAGE_2')}{' '}
            <BKJTooltipWrapper Component={ToolTipComponent} placement="bottom">
              <Flex flexDirection="column" gap="20px" width="209px">
                <div>{t('ASSIGN_AGENCY_TEAM_MEMBER_CONFIRM_SHIFT_MESSAGE_3')}</div>
                <div>{t('ASSIGN_AGENCY_TEAM_MEMBER_CONFIRM_SHIFT_MESSAGE_4')}</div>
              </Flex>
            </BKJTooltipWrapper>{' '}
            {t('ASSIGN_AGENCY_TEAM_MEMBER_CONFIRM_SHIFT_MESSAGE_5')}{' '}
          </ConfirmShiftAssigningDescriptionBold>
        </ConfirmShiftAssigningDescription>
      </Flex>
      <BKJSideModalFooter justifyContent="end">
        <BKJButton
          width="180px"
          variant="PurpleSolid"
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          disabled={isDisabled || isSubmitting}
        >
          {t('shift_modal:CONFIRM')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
