import { GQLAPIContextType } from 'types/common.types';
import { FC, ReactNode, useContext, useEffect } from 'react';
import { useGQLQuery } from 'hooks';
import { createGQLDataContext } from 'utils/createDataContext';
import { locationNameLabel, locationNameLabelVariables } from 'types/graphql-types';
import { QUERY_locationNameLabel } from 'queries';

export const LocationNameLabelContext = createGQLDataContext({
  locationName: '',
  isLoading: true,
}) as GQLAPIContextType;

const locationNameLabelQueryConfig = (location_id: number) => ({
  key: 'LOCATION_NAME_QUERY',
  query: QUERY_locationNameLabel,
  variables: {
    id: location_id,
  },
});

export const LocationNameLabelProvider: FC<{ children: ReactNode; locationId: number }> = ({
  children,
  locationId,
}) => {
  const { data, fetch, isLoading } = useGQLQuery<
    locationNameLabel,
    locationNameLabel,
    locationNameLabelVariables
  >(locationNameLabelQueryConfig(locationId));
  const locationName = data?.fetchCommunity.name;

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <LocationNameLabelContext.Provider value={{ locationName, isLoading }}>
      {children}
    </LocationNameLabelContext.Provider>
  );
};

export const useLocationNameLabelContext = (): { locationName: ''; isLoading: true } =>
  useContext(LocationNameLabelContext);
