import { BKJLoadingSpinner, GenericErrorMessage } from '@bookjane2/bookjane-design-library';
import { IBKJLoadingSpinnerProps } from '@bookjane2/bookjane-design-library/lib/components/BKJLoadingSpinner/BKJLoadingSpinner.types';
import { Flex } from 'components/Flex';
import { ComponentType, Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { ApiRequestConfigType } from 'requests/index.types';
import { MountTransition } from 'routes/MountTransition';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { LegacyAPIContextType, isReactComponent, isReactElement } from 'types/common.types';
import i18next from 'i18next';

export const LegacyAPISwitch = ({
  context: requestOptions,
  loadingComponent: LoadingComponent,
  loadingIconSize,
  component: Component,
  overrides = [],
}: {
  component: ComponentType<LegacyAPIContextType>;
  loadingComponent: FunctionComponent<{}>;
  loadingIconSize: IBKJLoadingSpinnerProps['variant'];
  overrides?: Array<'NO_ERROR'>;
  context: ApiRequestConfigType;
}) => {
  const values: LegacyAPIContextType = useSelector((state) =>
    apiDataSelector(state, requestOptions),
  );
  const isErrorStateOverridden = overrides.includes('NO_ERROR');
  const componentMap = {
    ERROR: isErrorStateOverridden ? (
      isReactComponent(Component) ? (
        <Component {...values} />
      ) : isReactElement(Component) ? (
        <Fragment>{Component}</Fragment>
      ) : (
        <Fragment />
      )
    ) : (
      <GenericErrorMessage
        errorMessage={i18next.t('errors:SOMETHING_WENT_WRONG_TRY_REFRESHING_PAGE')}
      />
    ),
    READY: isReactComponent(Component) ? (
      <Component {...values} />
    ) : isReactElement(Component) ? (
      <Fragment>{Component}</Fragment>
    ) : (
      <Fragment />
    ),
    LOADING: LoadingComponent ? (
      <LoadingComponent />
    ) : (
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
        <MountTransition>
          <BKJLoadingSpinner variant={loadingIconSize} />
        </MountTransition>
      </Flex>
    ),
  };
  return componentMap[values.status];
};
