import {
  BKJAddressInput,
  BKJButton,
  BKJPhoneNumberInput,
  BKJTextInput,
} from '@bookjane2/bookjane-design-library';
import { BKJFieldSet, BKJForm } from 'components/FormWrapper';
import { H3, P } from 'components/Typography';
import {
  GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_DESCRIPTION,
  GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_TITLE,
} from 'pages/LocationSettingsPage/GeneralLocationSettingsPage.constants';
import {
  GeneralLocationSettingsFormFormProvider,
  useGeneralLocationSettingsFormFormContext,
} from 'pages/LocationSettingsPage/GeneralLocationSettingsPageForm.form.context';
import { useLocationRecordDetailDataContext } from 'pages/LocationSettingsPage/LocationRecordDetail.data.context';
import { H4 } from 'pages/SettingsPage/SettingsPage.styled';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useParams } from 'react-router-dom';

const Component: FC = () => {
  const { data } = useLocationRecordDetailDataContext();
  const { t } = useTranslation();

  const { onSubmit, apiErrors, isSubmitting, fieldErrors, isDisabled, onChange, values } =
    useGeneralLocationSettingsFormFormContext();
  return (
    <Fragment>
      <BKJForm onSubmit={onSubmit} width="672px" maxWidth="672px" gap="22px">
        <H3 data-cy="hdr-facilityname">{data.name}</H3>
        <BKJFieldSet width="414px" maxWidth="414px" gap="14px">
          <BKJTextInput
            label={t('settings:LOCATION_NAME')}
            data-cy="txt-facilityname"
            placeholder={t('settings:LOCATION_NAME')}
            value={values.name}
            error={fieldErrors.name}
            name="name"
            required
            onChange={onChange}
          />
          <BKJAddressInput
            name="full_address"
            value={values.full_address}
            error={fieldErrors.full_address}
            required
            onChange={onChange}
            label={t('settings:LOCATION_ADDRESS')}
            placeholder={t('settings:LOCATION_ADDRESS')}
          />
          <BKJTextInput
            name="flat"
            value={values.flat}
            onChange={onChange}
            error={fieldErrors.flat}
            label={t('settings:Suite/Unit_No.')}
            placeholder={t('settings:Suite/Unit_No.')}
          />
        </BKJFieldSet>
        <H4 data-cy="hdr-contactinfo">
          {t(GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_TITLE)}
        </H4>
        <P data-cy="txt-contactinfo-desc">
          {t(GENERAL_LOCATION_SETTINGS_PAGE_FORM_CONTACT_INFORMATION_DESCRIPTION)}
        </P>
        <BKJFieldSet width="414px" maxWidth="414px" gap="14px">
          <BKJTextInput
            name="first_name"
            value={values.first_name}
            error={fieldErrors.first_name}
            required
            onChange={onChange}
            label={t('settings:FIRST_NAME')}
            placeholder={t('settings:FIRST_NAME')}
          />
          <BKJTextInput
            name="last_name"
            value={values.last_name}
            error={fieldErrors.last_name}
            required
            onChange={onChange}
            label={t('settings:LAST_NAME')}
            placeholder={t('settings:LAST_NAME')}
          />
          <BKJTextInput
            name="email"
            value={values.email}
            error={fieldErrors.email}
            required
            type="email"
            onChange={onChange}
            label={t('settings:EMAIL')}
            placeholder={t('settings:EMAIL')}
          />
          <BKJPhoneNumberInput
            name="phone"
            value={values.phone}
            error={fieldErrors.phone}
            required
            onChange={onChange}
            label={t('settings:PHONE_NUMBER')}
            placeholder={t('settings:PHONE_NUMBER')}
            maxLength={10}
          />
        </BKJFieldSet>

        {apiErrors && <span>{apiErrors.message}</span>}
        <BKJButton
          type="submit"
          variant="PurpleSolid"
          width="192px"
          disabled={isDisabled}
          isLoading={isSubmitting}
          data-cy="btn-save"
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJForm>
    </Fragment>
  );
};

export const GeneralLocationSettingsForm: FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  return (
    <GeneralLocationSettingsFormFormProvider key={locationId}>
      <Component />
    </GeneralLocationSettingsFormFormProvider>
  );
};
