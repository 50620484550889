import { createContext, FC } from 'react';
import { ISSFBarsContext } from 'components/SSFBars/SSFBars.types';
import { usePersistentState } from 'hooks';

export const SSFBarsContext = createContext<ISSFBarsContext>({
  isOpen: false,
  setIsOpen: () => null,
});

export const SSFBarsProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = usePersistentState<boolean>(false, 'SSFBarsProviderIsOpen');

  return (
    <SSFBarsContext.Provider value={{ isOpen, setIsOpen, __discriminator: 'SSFBarsContext' }}>
      {children}
    </SSFBarsContext.Provider>
  );
};
