import {
  DesktopPageHeaderTitle,
  DesktopPageHeaderWrapper,
} from 'components/DesktopPageHeader/DesktopPageHeader.styled';
import { IDesktopPageHeaderProps } from 'components/DesktopPageHeader/DesktopPageHeader.types';
import { FC } from 'react';

export const DesktopPageHeader: FC<IDesktopPageHeaderProps> = ({
  title,
  children,
  ...rest
}): JSX.Element => {
  return (
    <DesktopPageHeaderWrapper {...rest}>
      <DesktopPageHeaderTitle data-cy="page-title">{title}</DesktopPageHeaderTitle>
      {children}
    </DesktopPageHeaderWrapper>
  );
};
