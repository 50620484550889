import { GOOGLE_ANALYTICS_ID } from 'config';
import { FC, Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

export const GoogleAnalyticsContainer: FC = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.set({ location: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
  return <Fragment />;
};
