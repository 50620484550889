import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import { ShiftStatusBadge } from 'components/ShiftStatusBadge';
import { StarRating } from 'components/StarRating';
import { Tags } from 'components/Tags';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { JaneName } from 'pages/DashboardPage/JaneName';
import { WarningIcon } from 'pages/SchedulePage/Modals/SchedulePublishModal/styled';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes';
import { queryRefetchService } from 'services/QueryRefetchService';
import { parseVerboseDate } from 'utils';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { withMemo } from 'utils/withMemo';
import {
  CallOutStatus,
  CallOutStatusWrapper,
  InProgressHighlight,
  JaneNameWrapper,
  ShiftActionsWrapper,
  ShiftCardIcon,
  ShiftCardSectionA,
  ShiftCardSectionB,
  ShiftCardText,
  BiddingEndsText,
  ShiftCardWrapper,
  ShiftIdWrapper,
  ShiftInfoWrapper,
} from 'components/ShiftCardSkeleton//ShiftCardSkeleton.styled';
import { IShiftCardSkeletonProps } from 'components/ShiftCardSkeleton//ShiftCardSkeleton.types';

import { RenderShiftActions } from 'components/RenderShiftActions';
import { ContinueCalloutDialog } from 'components/ContinueCalloutDialog';
import { toastService } from 'services';
import { AllowOvertimeDialog } from 'components/AllowOvertimeDialog';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { AutoRebidDialog } from 'components/AutoRebidDialog';

export const WebShiftCardSkeleton = withMemo(function (
  props: IShiftCardSkeletonProps,
): JSX.Element | null {
  const { t, i18n } = useTranslation();
  const {
    id,
    bidders,
    biddable,
    biddingTimeEndsAt,
    status,
    mainJane,
    dedicated,
    sentToAgencies,
    acceptedBySharedTeamMember,
    position,
    startAt,
    endAt,
    community,
    isReviewAllowed,
    review,
    recurrence,
    orderSchedule,
    priority,
  } = props.order;

  const {
    shiftActions,
    isShiftInfoWrapperVisible,
    isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
    callOutStatus,
    isAuditTrailEnabled,
    ShiftActionComponent,
    biddingStatus,
    handleCardClick,
    openRatingFlow,
    isContinueCallOutConfirmationOpen,
    isAllowOvertimeConfirmationOpen,
    isAutoRebidConfirmationOpen,
    setIsContinueCallOutConfirmationOpen,
    setIsAllowOvertimeConfirmationOpen,
    setIsAutoRebidConfirmationOpen,
    isAgencyShift,
  } = props;

  const { timeZone } = getCurrentlySelectedLocation();

  return (
    <MountTransition>
      {callOutStatus === 'PAUSED' && (
        <ContinueCalloutDialog
          orderId={id}
          isContinueCallOutConfirmationOpen={isContinueCallOutConfirmationOpen}
          setIsContinueCallOutConfirmationOpen={setIsContinueCallOutConfirmationOpen}
          onSuccess={() => {
            toastService.success(t('dashboard:CALL_OUT_SUCCESSFUL_RESUMED'));
            queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
            window.scheduleUtils.forceDataUpdate();
          }}
        />
      )}
      <AllowOvertimeDialog
        orderId={id}
        isAllowOvertimeConfirmationOpen={isAllowOvertimeConfirmationOpen}
        setIsAllowOvertimeConfirmationOpen={setIsAllowOvertimeConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('dashboard:ALLOW_OVERTIME_SUCCESS'));
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
          window.scheduleUtils.forceDataUpdate();
        }}
      />
      <AutoRebidDialog
        orderId={id}
        isAutoRebidConfirmationOpen={isAutoRebidConfirmationOpen}
        setIsAutoRebidConfirmationOpen={setIsAutoRebidConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('success:SHIFT_BIDDING_SUCCESSFULLY_AUTO_EXTENDED'));
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
          window.scheduleUtils.forceDataUpdate();
        }}
      />
      <ShiftCardWrapper
        onClick={handleCardClick}
        data-cy={`ShiftCard-${status.value}`}
        className="ShiftCard"
      >
        <ShiftCardSectionA>
          {status.name === 'In Progress' && <InProgressHighlight />}
          <Flex
            justifyContent="center"
            childMarginBottom="8px"
            width="130px"
            minWidth="130px"
            flexDirection="column"
          >
            <ShiftIdWrapper>{`${t('dashboard:SHIFT_NUMBER')}${orderSchedule.id}`}</ShiftIdWrapper>
            <ShiftStatusBadge status={status.value}>
              {getTranslatedResponse(status?.name, 'status', i18n, t)}
            </ShiftStatusBadge>
          </Flex>
          <Flex
            margin="0 28px 0 0"
            minWidth="80px"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <JaneAvatar
              size={75}
              status={status.value}
              url={mainJane?.avatarUrls.large}
              pendingSignup={mainJane?.pendingSignup ?? false}
            />
          </Flex>
          <Flex
            flexDirection="column"
            childMarginBottom="8px"
            css="overflow: hidden;"
            width="calc(100% - 238px)"
            maxWidth="calc(100% - 238px)"
          >
            <JaneNameWrapper>
              <JaneName mainJane={mainJane} status={status.name} />
              &nbsp; &nbsp;
              {isSharedJanePrimaryCommunityDifferentFromSelectedLocation && (
                <Fragment>
                  <BKJIcon iconName="Group2" width={16} height={14} />
                  &nbsp; &nbsp;
                </Fragment>
              )}
              <Tags
                id={id}
                status={status.value}
                isRecurring={!!recurrence}
                isDedicated={dedicated}
                isSentToAgencies={sentToAgencies}
                hasJane={!!mainJane}
                janePrimaryCommunity={mainJane?.primaryCommunity}
                isAgencyJane={!!mainJane?.agencyCommunity}
                isAcceptedBySharedTeamMember={!!acceptedBySharedTeamMember}
                biddable={biddable}
                bidders={bidders}
                biddingTimeEndsAt={biddingTimeEndsAt}
              />
            </JaneNameWrapper>
            <ShiftCardText data-cy="ShiftCard-PositionName">{position?.name}</ShiftCardText>
            {biddingStatus === 'BIDDING' && !isAgencyShift && (
              <BiddingEndsText priority={priority}>
                {t('dashboard:BIDDING_ENDS')}:&nbsp;
                {DateTime.fromISO(biddingTimeEndsAt)
                  .setZone(String(timeZone))
                  .toFormat("EEE, MMM d 'at' h:mm a")}
              </BiddingEndsText>
            )}
            {!biddable &&
              isAuditTrailEnabled &&
              (callOutStatus === 'COMPLETE' ? (
                <CallOutStatusWrapper>
                  <CallOutStatus>{t('dashboard:CALL_OUT_COMPLETE')} &nbsp;</CallOutStatus>
                  <WarningIcon />
                </CallOutStatusWrapper>
              ) : (
                callOutStatus === 'PAUSED' && (
                  <CallOutStatusWrapper>
                    <CallOutStatus>{t('dashboard:CALL_OUT_PAUSED')} &nbsp;</CallOutStatus>
                    <BKJIcon iconName="Pause" />
                  </CallOutStatusWrapper>
                )
              ))}
          </Flex>
        </ShiftCardSectionA>
        <ShiftCardSectionB>
          <ShiftInfoWrapper
            isVisible={isShiftInfoWrapperVisible}
            flexDirection="column"
            childMarginBottom="8px"
          >
            <Flex alignItems="center">
              <ShiftCardIcon iconName="ShiftsDate" color="AccentBrand" fontSize={24} />
              <ShiftCardText>{parseVerboseDate([startAt, endAt], timeZone)}</ShiftCardText>
              {recurrence !== null && (
                <Fragment>
                  &nbsp;
                  <ShiftCardIcon iconName="Recurring" fontSize={24} />
                </Fragment>
              )}
            </Flex>
            <Flex alignItems="center">
              <ShiftCardIcon iconName="ShiftsLocation" color="AccentBrand" fontSize={24} />
              <ShiftCardText>{community?.name}</ShiftCardText>
            </Flex>
          </ShiftInfoWrapper>
          {isShiftInfoWrapperVisible && (
            <ShiftActionsWrapper childMarginBottom="8px" onClick={(e) => e.stopPropagation()}>
              {isReviewAllowed ? (
                <UserGuard
                  userType={[
                    UserPermissionEnum.USER_TYPE_B2B,
                    UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
                    UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
                  ]}
                >
                  <BKJButton variant="PurpleSolid" width="192px" onClick={openRatingFlow}>
                    {t('common:ADD_RATING')}
                  </BKJButton>
                </UserGuard>
              ) : review?.rating ? (
                <Flex justifyContent="center" alignItems="center" width="100%">
                  <StarRating rating={review?.rating} />
                </Flex>
              ) : null}
              <UserGuard
                userType={[
                  UserPermissionEnum.USER_TYPE_B2B,
                  UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
                  UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
                ]}
              >
                {!!shiftActions.length && (
                  <RenderShiftActions
                    variant="PurpleSolid"
                    shiftActions={shiftActions}
                    SingleItemComponent={ShiftActionComponent}
                  />
                )}
              </UserGuard>
            </ShiftActionsWrapper>
          )}
        </ShiftCardSectionB>
      </ShiftCardWrapper>
    </MountTransition>
  );
});
