import { FC, memo, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { BKJCalendarPopUp } from 'components/BKJDatePicker';
import { deepEquals } from 'utils/deepEquals';
import { mergeRefs } from 'utils/mergeRefs';
import { createPortal } from 'react-dom';
import { useClickOutside } from 'hooks';
import { usePopper } from 'react-popper';
import { getJ360ElementNode } from 'utils/getJ360ElementNode';
import { BKJDatePickerContext } from 'components/BKJDatePicker';
import { popperModifiers } from './SelectDatesButton.constants';
import { StyledSelectDatesButton } from './SelectDatesButton.styled';
import { CreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { ISO8601Type } from 'types/common.types';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { v4 as uuid } from 'uuid';
import { getInitialCreateShiftForm } from 'pages/CreateShiftsPage/CreateShiftsPage.utils';
import { DROPDOWN_ROOT_ID } from 'constants/elements';
import { useTranslation } from 'hooks/useTranslation';

export const SelectDatesButton: FC = memo((): JSX.Element | null => {
  const { groupedFormsBehaviors } = useContext(CreateShiftsPageContext);
  const { addFormItem, removeFormItem } = groupedFormsBehaviors;
  const [calendarShifts, setCalendarShifts] = useState<Record<ISO8601Type, string>>({});
  const calendarValues = Object.keys(calendarShifts);

  const popupRef = useRef<HTMLElement | null>(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState();
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(
    getJ360ElementNode(DROPDOWN_ROOT_ID),
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: popperModifiers,
    placement: 'bottom-start',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!targetElement) {
      const element = document.createElement('div');
      element.setAttribute('id', DROPDOWN_ROOT_ID);
      document.body.appendChild(element);
      setTargetElement(element);
    }
  }, [targetElement]); // eslint-disable-line

  function handleCalendarPopUpClose() {
    if (isCalendarOpen) {
      setCalendarShifts({});
      setIsCalendarOpen(false);
    }
  }

  useClickOutside(
    [
      popupRef && popupRef.current,
      targetElement && targetElement,
      referenceElement && referenceElement,
    ],
    handleCalendarPopUpClose,
  );

  const handleClickSelectDatesButton: MouseEventHandler<HTMLButtonElement> = () => {
    setCalendarShifts({});
    setIsCalendarOpen(!isCalendarOpen);
  };

  function handleChange(e: ChangeEventType) {
    let _calendarShifts = { ...calendarShifts };
    const _changedCalendarValues = e.target.value;

    if (_changedCalendarValues.length < calendarValues.length) {
      const removeDates = calendarValues.filter(
        (value: ISO8601Type) => !_changedCalendarValues.includes(value),
      );

      _calendarShifts = _changedCalendarValues.reduce(
        (acc: Record<ISO8601Type, string>, date: ISO8601Type) => {
          acc[date] = _calendarShifts[date];
          return acc;
        },
        {},
      );

      removeDates.forEach((date: ISO8601Type) => removeFormItem({ id: calendarShifts[date] }));
    } else {
      const addDates = _changedCalendarValues.filter(
        (value: ISO8601Type) => !calendarValues.includes(value),
      );

      _calendarShifts = addDates.reduce(
        (_calendarShifts: Record<ISO8601Type, string>, date: ISO8601Type) => {
          return {
            ..._calendarShifts,
            [date]: uuid(),
          };
        },
        _calendarShifts,
      );

      addDates.forEach((date: ISO8601Type) => {
        addFormItem(
          getInitialCreateShiftForm({
            id: _calendarShifts[date],
            startDate: date,
          }),
        );
      });
    }

    setCalendarShifts(_calendarShifts);
  }

  return (
    <BKJDatePickerContext.Provider
      value={{
        type: 'MultipleDates',
        name: 'SelectDates',
        value: calendarValues,
        onChange: handleChange,
      }}
    >
      <StyledSelectDatesButton
        variant="PurpleSolid"
        ref={setReferenceElement}
        onClick={handleClickSelectDatesButton}
        data-cy="btn-selectDates"
      >
        {t('create_shifts:SELECT_DATES')}
      </StyledSelectDatesButton>
      {isCalendarOpen &&
        !!targetElement &&
        createPortal(
          <BKJCalendarPopUp
            context={BKJDatePickerContext}
            ref={mergeRefs([popupRef, setPopperElement])}
            style={styles.popper}
            {...attributes.popper}
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
          />,
          targetElement,
        )}
    </BKJDatePickerContext.Provider>
  );
}, deepEquals);
