import 'core-js';
import 'symbol-observable';
import 'setupGlobals';
import { sessionService } from 'services';
import ReactDOM from 'react-dom';
import { Root } from 'Root';
import 'index.css';

sessionService.onAppLaunch();

ReactDOM.render(<Root />, document.getElementById('root'));
