import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isMultiLocationFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    const hasPermission = (
      permissionType: 'MULTI_LOCATION_SCHEDULING' | 'MULTI_LOCATION_CALLOUT',
    ) => {
      const communities = userPermissions?.[permissionType]?.communities;
      return (
        userPermissions?.[permissionType]?.access?.includes('read') &&
        communities?.[communityId]?.includes('read')
      );
    };

    return (
      hasPermission('MULTI_LOCATION_SCHEDULING') || hasPermission('MULTI_LOCATION_CALLOUT') || false
    );
  });
