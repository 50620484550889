import { BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const FacilitiesSearchWrapperStyled = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.Grey6};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 44px;
  max-height: 44px;
  padding-left: 16px;
  padding-right: 48px;
  order: unset;
  width: 100%;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;

export const FacilitiesSelectionTableWrapper = styled(Flex)`
  overflow-y: scroll;
  height: 100%;
`;

export const FacilitiesSelectionTableHeader = styled(Flex)`
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
  width: 100%;
  height: fit-content;
  padding: 26px 32px;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsMedium};
`;

export const FacilitiesSelectionTableBody = styled(Flex)`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.Poppins};
`;

export const FacilitiesSelectionAllTableRow = styled(Flex)`
  width: 100%;
  padding: 13.5px 32px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.Grey4};
`;

export const FacilitiesSelectionTableRow = styled(Flex)`
  width: 100%;
  padding: 24px 32px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.Grey4};
`;

export const FacilitiesSelectionTableLabel = styled.div`
  width: fit-content;
`;

export const FacilitiesSelectionTableDescription = styled.div`
  width: fit-content;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  ${({ theme }) => theme.fonts.PoppinsText};
`;

export const Heading = styled.h3`
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsBold};
`;

export const Description = styled.label`
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;

export const FacilitiesSelectionCountLabel = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium}
  font-size: 16px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  display: flex;
`;

export const EditFacilitiesWrapper = styled(Flex)`
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 22px;
  width: auto;
  :hover {
    cursor: pointer;
  }
`;

export const NoResultsFoundWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
`;

export const TextButtonWrapper = styled(BKJButton)`
  width: fit-content;
  justify-content: left;
  font-size: 14px;
  line-height: 22px;
  /* color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsText}; */

  min-height: auto;
  max-height: auto;
  padding: 0 0;
`;

export const MobileCheckboxTextWrapper = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
`;
