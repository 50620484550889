import styled from 'styled-components';
import { zIndexLayers } from 'constants/zIndexLayers';

export const I18nLanguageSwitcher = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: ${zIndexLayers.translationSwitch};
  cursor: pointer;
`;
