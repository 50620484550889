import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';
import { isUserObjectB2bType } from 'types/common.types';

// Selectors

const getUser = (state: IGlobalReduxStore) => state.auth.user;

export const isAvailabilityFeatureEnabledSelector = createSelector([getUser], (user) => {
  if (!user) return false;
  if (isUserObjectB2bType(user)) return user.availability_status === 'available';
  return false;
});
