import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const RecurrenceListLabelWrapper = styled(Flex)`
  cursor: pointer;
`;

export const RecurrenceListLabel = styled.span`
  ${({ theme }) => theme.typography.CaptionBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
`;

export const RecurrenceListShift = styled.span`
  ${({ theme }) => theme.typography.TextLink};
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-decoration: underline;
  cursor: pointer;
`;
