import { Fragment, FC, ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { FeatureType } from './FeatureGuard.types';

export const FeatureGuard: FC<{ children: ReactNode; type: FeatureType; NOT?: boolean }> = ({
  children,
  type,
  NOT = false,
}): ReactElement | null => {
  let isFeatureEnabled;
  isFeatureEnabled = useSelector(FEATURE_SELECTORS_MAP[type]);
  if (NOT) isFeatureEnabled = !isFeatureEnabled;
  if (!isFeatureEnabled) return null;
  return <Fragment>{children}</Fragment>;
};
