import {
  GroupRule,
  createGroupRule,
  createInitialGroupRule,
  handleApiError,
  toGroupRules,
  toShiftDistributionGroupRules,
  updateGroupId,
  updateWaitTimeMinutes,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';
import { shiftDistributionRulesGroupsModalRoutingService } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import {
  API_POST_shiftDistirbutionRulesGroupRuleBatch,
  GroupRulesFormActions,
  GroupRulesFormSchema,
  createAddGroupRulesRequest,
  createUpdateGroupRulesRequest,
} from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/AddPositionRule.constants';
import { useViewDetailsDataContext } from 'pages/SettingsPage/modals/ShiftDistributionRulesGroupsModal/views/ViewDetails.data.context';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { useTranslation } from 'hooks/useTranslation';

type EditRuleFormContextType = UseFormBehaviors<EditRuleFormSchema> &
  GroupRulesFormSchema &
  GroupRulesFormActions;

type EditRuleFormSchema = {
  skip_wait_time_threshold_minutes: string[];
  shiftDistributionId?: number;
};

export const EditRuleFormContext = createContext<EditRuleFormContextType>(
  {} as EditRuleFormContextType,
);

export const EditRuleFormProvider: FC = ({ children }) => {
  const { data } = useViewDetailsDataContext();
  const [groupRules, setGroupRules] = useState<GroupRule[]>(
    toGroupRules(data?.groupRules) || [createInitialGroupRule()],
  );

  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const { apiRequest } = useAPIActions();

  const { t } = useTranslation();

  const handleAddGroupRule = useCallback(
    () => setGroupRules([...groupRules, createGroupRule()]),
    [groupRules],
  );

  const handleGroupSelect = useCallback(
    (groupOption, indexId) =>
      setGroupRules([...groupRules.map(updateGroupId({ groupOption, indexId }))]),
    [groupRules],
  );
  const handleGroupDelete = useCallback(
    (index) => {
      groupRules.splice(index, 1);
      setGroupRules([...groupRules]);
    },
    [groupRules],
  );

  const handleWaitTimeSelect = useCallback(
    (waitTimeMinutesOptionValue, indexId) =>
      setGroupRules([
        ...groupRules.map(updateWaitTimeMinutes({ waitTimeMinutesOptionValue, indexId })),
      ]),
    [groupRules],
  );

  const handleSubmit = useCallback(
    async (values) => {
      const groupRulesTransformed = groupRules.map(toShiftDistributionGroupRules);
      const req = !!values.shiftDistributionId
        ? createUpdateGroupRulesRequest({
            shiftDistributionId: values.shiftDistributionId,
            skipWaitTimeThresholdMinutes: values.skip_wait_time_threshold_minutes,
            positionId: data?.position?.id,
          })
        : createAddGroupRulesRequest({
            skipWaitTimeThresholdMinutes: Number.parseInt(
              values.skip_wait_time_threshold_minutes[0],
            ),
            communityId: Number.parseInt(locationId),
            positionId: data?.position?.id,
          });
      return await apiRequest(req, {
        onSuccess: async (response) => {
          if (!!response?.data?.shift_distribution_rule?.id) {
            const shiftDistributionRuleId = response.data.shift_distribution_rule.id;
            return await apiRequest(
              API_POST_shiftDistirbutionRulesGroupRuleBatch({
                group_rules: groupRulesTransformed,
                shiftDistributionRuleId,
              }),
              {
                onSuccess: async () => {
                  toastService.success(t('success:RULE_SAVED'));
                  shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
                },
                onError: async (error) => {
                  toastService.error(JSON.stringify(error?.data?.group_rules?.errors));
                  shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
                },
              },
            );
          } else {
            shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
          }
        },
        onError: handleApiError,
      });
    },
    [apiRequest, data?.position?.id, groupRules, locationId, t],
  );

  const editRuleFormBehaviorsOptions: UseFormBehaviors_Options<EditRuleFormSchema> = {
    initialState: {
      skip_wait_time_threshold_minutes:
        !!data?.skipWaitTimeThresholdMinutes || data?.skipWaitTimeThresholdMinutes === 0
          ? [`${data.skipWaitTimeThresholdMinutes}`]
          : ['1440'],
      shiftDistributionId: data?.id,
    },
    isDirtyCheckEnabled: true,
    type: 'CREATE',
    onSubmit: handleSubmit,
    validations: {},
  };

  const editRuleForm = useFormBehaviors<EditRuleFormSchema>(editRuleFormBehaviorsOptions);
  const value = useMemo(
    () => ({
      ...editRuleForm,
      addGroupRule: handleAddGroupRule,
      onGroupSelect: handleGroupSelect,
      onWaitTimeSelect: handleWaitTimeSelect,
      onGroupDelete: handleGroupDelete,
      groupRules,
      isDisabled: !!groupRules.find((rule) => !rule.groupOption.id),
    }),
    [
      editRuleForm,
      groupRules,
      handleAddGroupRule,
      handleGroupDelete,
      handleGroupSelect,
      handleWaitTimeSelect,
    ],
  );

  return <EditRuleFormContext.Provider value={value}>{children}</EditRuleFormContext.Provider>;
};

export const useEditRuleFormContext = (): EditRuleFormContextType =>
  useContext(EditRuleFormContext);
