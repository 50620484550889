export const formatUnpaidBreakTime = (breakTime: string) => {
  const time = breakTime.split(':').map((t) => parseInt(t, 10));
  return time[0] ? time[0] + ' hrs ' + time[1] + ' mins' : time[1] + ' mins';
};

export const formatTime = (totalTime: number) => {
  let hours = Math.round(totalTime - (totalTime % 1));
  let minutes = Math.round((totalTime % 1) * 60);
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  let formattedTime = '';
  if (hours) formattedTime = formattedTime.concat(hours.toString(), ' hrs ');
  if (minutes) formattedTime = formattedTime.concat(minutes.toString(), ' mins');
  if (formattedTime === '') formattedTime = '0 hrs';
  return formattedTime;
};

export const calculateTotalPay = (totalTime: number, payRate: number) => payRate * totalTime;

export const calculateTotalDuration = (
  startTime: string[],
  endTime: string[],
  unpaidBreak?: string,
) => {
  const [startHours, startMins] = startTime[0].split(':');
  const [endHours, endMins] = endTime[0].split(':');
  const start = parseInt(startHours) + parseInt(startMins) / 60;
  const end = parseInt(endHours) + parseInt(endMins) / 60;
  let total = end - start;
  if (unpaidBreak) {
    const [breakHours, breakMins] = unpaidBreak?.split(':');
    const breakTime = parseInt(breakHours) + parseInt(breakMins) / 60;
    total -= breakTime;
  }
  return total;
};
