import { BKJIcon } from '@bookjane2/bookjane-design-library';
import React, { useState } from 'react';
import { BKJTableDirectionIcon } from './BKJTableDirectionIcon';
import {
  BKJTableSubHeaderWrapper,
  HeaderText,
  InfoWrapper,
  TextWrapper,
  TooltipText,
  TooltipWrapper,
} from './BKJTableSubHeader.styled';
import { IBKJTableSubHeaderProps } from './BKJTableSubHeader.types';

export const BKJTableSubHeader: React.FC<IBKJTableSubHeaderProps> = (props): JSX.Element => {
  const { title, tooltip, onChevronClick, className, direction = 'default', name, ...rest } = props;
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <BKJTableSubHeaderWrapper className={className}>
      <TextWrapper title={title} {...rest}>
        <HeaderText>{title} </HeaderText>
        <InfoWrapper onMouseLeave={() => setTooltipOpen(false)}>
          {tooltip && <BKJIcon iconName="Info" onMouseOver={() => setTooltipOpen(true)} />}
          {tooltip && isTooltipOpen && (
            <TooltipWrapper>
              <TooltipText>{tooltip}</TooltipText>
            </TooltipWrapper>
          )}
        </InfoWrapper>
      </TextWrapper>
      {onChevronClick && (
        <BKJTableDirectionIcon onClick={onChevronClick} name={name} direction={direction} />
      )}
    </BKJTableSubHeaderWrapper>
  );
};
