import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { timeOffModalRoutingService } from 'components/TimeOffModal/TimeOffModal.constants';
import {
  TIME_OFF_MODAL_DELETE_TIME_OFF_IN_PROGRESS,
  TIME_OFF_MODAL_DELETE_TIME_OFF,
} from 'components/TimeOffModal/views/DeleteTimeOffView/DeleteTimeOffView.constants';
import { useDeleteTimeOffViewPropsContext } from 'components/TimeOffModal/views/DeleteTimeOffView/DeleteTimeOffView.props.context';
import {
  DeleteTimeOffDescriptionWrapper,
  DeleteTimeOffViewWrapper,
  MainTitle,
} from 'components/TimeOffModal/views/DeleteTimeOffView/DeleteTimeOffView.styled';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const DeleteTimeOffViewComponent: FC = () => {
  const { t } = useTranslation();
  const { handleDeleteTimeOffById, isTimeOffInProgress } = useDeleteTimeOffViewPropsContext();

  return (
    <Fragment>
      <DeleteTimeOffViewWrapper>
        <MainTitle>
          {isTimeOffInProgress
            ? t('schedule:TIME_OFF_STARTED')
            : `${t('schedule:DELETE_TIME_OFF')}?`}
        </MainTitle>
        <DeleteTimeOffDescriptionWrapper>
          {isTimeOffInProgress
            ? t(TIME_OFF_MODAL_DELETE_TIME_OFF_IN_PROGRESS)
            : t(TIME_OFF_MODAL_DELETE_TIME_OFF)}
        </DeleteTimeOffDescriptionWrapper>
      </DeleteTimeOffViewWrapper>
      <BKJSideModalFooter justifyContent="end" gap="8px">
        {isTimeOffInProgress && (
          <BKJButton
            variant="PurpleOutlined"
            width="180px"
            onClick={() => timeOffModalRoutingService.goToView('EditTimeOffView')}
          >
            {t('schedule:EDIT')}
          </BKJButton>
        )}
        <BKJButton variant="RedSolid" onClick={handleDeleteTimeOffById} width="180px">
          {t('auth:DELETE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
