import i18next from 'i18next';
import { DateTime } from 'luxon';

export const formatDetailTimeOffStartTimeEndTime = (
  dateTime: string,
  timeZone: string | undefined,
) => {
  const dayName = DateTime.fromISO(dateTime).setZone(timeZone).toFormat('ccc');
  const translatedDayName = i18next.t(`translations:${dayName.toUpperCase()}`);

  const monthName = DateTime.fromISO(dateTime).setZone(timeZone).toFormat('LLL');
  const translatedMonthName = i18next.t(`translations:${monthName.toUpperCase()}`);

  const dateYear = DateTime.fromISO(dateTime).setZone(timeZone).toFormat(`dd',' yyyy`);
  const timeLabel = DateTime.fromISO(dateTime).setZone(timeZone).toFormat('h:mm a');

  const atLabel = i18next.t('common:AT');

  return `${translatedDayName}, ${translatedMonthName} ${dateYear} ${atLabel} ${timeLabel}`;
};
