import { BKJButton } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const SaveButton = styled(BKJButton)`
  width: '180px';
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    width: '100%';
    margin: 12px 0;
  }
`;
