import { IGridProps } from 'components/Grid/Grid.types';
import styled, { css } from 'styled-components';

export const Grid = styled.div<IGridProps>`
  height: 100%;
  display: grid;
  grid-auto-rows: min-content;

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      grid-template-columns: repeat(${gridTemplateColumns}, 1fr);
    `}

  ${({ gridTemplateRows }) =>
    gridTemplateRows &&
    css`
      grid-template-rows: repeat(${gridTemplateRows}, 1fr);
    `}

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `};

  ${({ gridColumnGap }) =>
    gridColumnGap &&
    css`
      grid-column-gap: ${gridColumnGap};
    `};

  ${({ gridRow }) =>
    gridRow &&
    css`
      grid-row: ${gridRow};
    `};

  ${({ gridRowGap }) =>
    gridRowGap &&
    css`
      grid-row-gap: ${gridRowGap};
    `};

  ${({ gridTemplateAreas }) =>
    gridTemplateAreas &&
    css`
      grid-template-areas: ${gridTemplateAreas};
    `};

  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `};
`;
