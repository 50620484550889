import { FC } from 'react';
import {
  IBKJSideModalProps,
  BKJSideModalHeaderComponentType,
} from 'components/BKJSideModal/BKJSideModal.types';
import { Portal } from 'components/Portal/Portal';
import { BKJSideModalBackdrop } from 'components/BKJSideModal/BKJSideModal.styled';
import { getJ360ElementNode } from 'utils/getJ360ElementNode';
import { AnimatePresence } from 'framer-motion';
import { MODAL_ROOT_ID } from 'constants/elements';
import { WebBKJSideModalDrawerContainer } from 'components/BKJSideModal/WebBKJSideModalDrawerContainer';
import { MobileBKJSideModalDrawerContainer } from 'components/BKJSideModal/MobileBKJSideModalDrawerContainer';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';

let targetElement = getJ360ElementNode(MODAL_ROOT_ID);

export const BKJSideModal: FC<IBKJSideModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    showBackButton,
    onBack,
    children,
    headerComponent,
    bgColor,
    context,
    hasPadding = false,
  } = props;

  if (!targetElement) {
    targetElement = document.createElement('div');
    targetElement.setAttribute('id', MODAL_ROOT_ID);
    document.body.appendChild(targetElement);
  }
  const { isDesktop } = useResponsive();
  const HeaderComponent = headerComponent as BKJSideModalHeaderComponentType;

  return (
    <AnimatePresence initial key="BKJSideModal">
      {isOpen && (
        <Portal targetElement={targetElement}>
          {isDesktop ? (
            <WebBKJSideModalDrawerContainer
              isOpen={isOpen}
              onClose={onClose!}
              headerComponent={HeaderComponent}
              hasPadding={hasPadding}
              showBackButton={showBackButton}
              onBack={onBack}
              children={children}
              bgColor={bgColor}
              context={context}
            />
          ) : (
            <MobileBKJSideModalDrawerContainer
              isOpen={isOpen}
              onClose={onClose!}
              headerComponent={HeaderComponent}
              hasPadding={hasPadding}
              showBackButton={showBackButton}
              onBack={onBack}
              children={children}
              bgColor={bgColor}
              context={context}
            />
          )}
          <BKJSideModalBackdrop
            key="BKJSideModalBackdrop"
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'tween',
              ease: 'linear',
              duration: 0.3,
            }}
          />
        </Portal>
      )}
    </AnimatePresence>
  );
};
