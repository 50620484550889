import { Flex } from 'components/Flex';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { WeekdaysEnum } from 'types/graphql-types';
import { parseVerboseDate } from 'utils';
import { SendToAgenciesInlineIcon, SendToAgenciesText } from '../../SendToAgenciesView.styled';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { DateTime } from 'luxon';
import { capitalize } from 'utils/capitalize';

export function SummaryInfo() {
  const { data } = useShiftModalDataContext();

  const { timeZone } = getCurrentlySelectedLocation();

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex alignItems="center">
        <SendToAgenciesInlineIcon iconName="ShiftsTime" color="PrimaryDefault" />
        <SendToAgenciesText>
          {parseVerboseDate([data.fetchOrder?.startAt, data.fetchOrder?.endAt])}
        </SendToAgenciesText>
      </Flex>

      {data.fetchOrder?.recurrence && (
        <Flex alignItems="center">
          <SendToAgenciesInlineIcon iconName="Recurring" color="PrimaryDefault" />
          <SendToAgenciesText>
            {`${data.fetchOrder?.recurrence?.weekdays
              ?.map((weekday: keyof typeof WeekdaysEnum) => capitalize(weekday).slice(0, 3))
              .join(', ')} - ${data.fetchOrder?.recurrence?.frequency?.split(' - ')[0]}`}
            {data.fetchOrder?.recurrence?.expirationDate &&
              ` • Ends on ${DateTime.fromISO(data.fetchOrder?.recurrence?.expirationDate)
                .setZone(timeZone)
                .toFormat('LLL d, yyyy')}`}
          </SendToAgenciesText>
        </Flex>
      )}

      <Flex alignItems="center">
        <SendToAgenciesText>{data.fetchOrder?.position.name}</SendToAgenciesText>
      </Flex>
    </Flex>
  );
}
