import styled, { css } from 'styled-components';

export interface IPaperContainerProps {
  gridTemplateColumns?: string;
  gridColumn?: string;
}

export const PaperContainer = styled.div<IPaperContainerProps>`
  height: 100%;
  border-radius: 8px !important;
  display: flex;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      display: grid;
      grid-template-columns: repeat(${gridTemplateColumns}, 1fr);
      grid-auto-rows: min-content;
    `}

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `}
`;
