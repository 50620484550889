import styled from 'styled-components';

export const Text = styled.span`
  color: #45424e;
  display: block;
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const BoldText = styled.span`
  ${({ theme }) => theme.typography.Bold}
`;
