import i18next from 'i18next';
import { ISO8601TupleType } from 'types/common.types';
import { DialogType } from 'components/TimesheetsCard/TimesheetsCard.types';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import { DateTime } from 'luxon';

export const getShiftTime = (
  [startTime, endTime]: ISO8601TupleType,
  skipTimeDuration: boolean = false,
  timeZone: string | undefined,
): string => {
  const { dayOfWeek, month } = getLocalizedDateFormat(startTime, timeZone);

  if (skipTimeDuration) {
    return `${dayOfWeek} ${month} ${DateTime.fromISO(startTime).setZone(timeZone).toFormat('dd')}`;
  }

  return `${dayOfWeek} ${month} ${DateTime.fromISO(startTime)
    .setZone(timeZone)
    .toFormat(`dd '${i18next.t('common:AT')}' h:mm a`)} - ${DateTime.fromISO(endTime)
    .setZone(timeZone)
    .toFormat('h:mm a')}`;
};

export const generateDialogData = (
  type: DialogType,
  t: (key: string) => string,
  onAction: () => void,
) => {
  // Ensure correct type inference for variant property
  let variant: 'Danger' | 'Default' = 'Danger';

  if (type === 'approve') {
    variant = 'Default';
  }
  switch (type) {
    case 'no_show':
      return {
        title: t('CONFIRM_NO_SHOW_TITLE'),
        text: t('CONFIRM_NO_SHOW_TEXT'),
        variant: variant,
        onAction: onAction,
        isOpen: true,
      };

    case 'approve':
      return {
        title: t('timesheets:APPROVE_THIS_SHEET_CONFIRMATION'),
        text: t('timesheets:ADJUST_BREAK_TIMES'),
        variant: variant,
        onAction: onAction,
        isOpen: true,
      };

    default:
      return {
        title: '',
        text: '',
        variant: variant,
        onAction: () => {},
        isOpen: false,
      };
  }
};
