import { Flex } from '@bookjane2/bookjane-design-library';
import {
  AgencyGridWrapper,
  AgencyGridNoAgenciesTextWrapper,
} from 'components/AgencyGridSelect/AgencyGridSelect.styled';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { FC } from 'react';

import { AgencyGridSelectCardComponent } from 'components/AgencyGridSelect/AgencyGridSelectCard.component';
import { useAgencyGridSelectPropsContext } from 'components/AgencyGridSelect/AgencyGridSelect.props.context';

export const AgencyGridSelectComponent: FC = () => {
  const { agencyList, hasNextPage, loadMore, errorMessage } = useAgencyGridSelectPropsContext();

  if (!!errorMessage) {
    return (
      <Flex
        width="100%"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AgencyGridNoAgenciesTextWrapper>{errorMessage}</AgencyGridNoAgenciesTextWrapper>
      </Flex>
    );
  }

  return (
    <AgencyGridWrapper>
      {agencyList.map((agency) => (
        <AgencyGridSelectCardComponent agency={agency} />
      ))}
      {hasNextPage && loadMore && (
        <Flex justifyContent="center" alignItems="center" width="100%" padding="24px 0px 0px 0px">
          <InfiniteScrollLoading onViewportEnter={loadMore} />
        </Flex>
      )}
    </AgencyGridWrapper>
  );
};
