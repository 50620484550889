import styled from 'styled-components';
import { Grid } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';

export const PreferencesContainer = styled(Grid)`
  max-width: 840px;
  border: solid 1px #aeb8c540;
  margin: 0;
`;
export const PreferenceRow = styled.div`
  &:nth-child(2n) {
    background: #f6f7fb;
  }
`;
export const SpaceBetweenRow = styled.div`
  width: 5px;
  height: 5px;
`;
