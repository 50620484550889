import { BKJ_ENVIRONMENT } from 'config';
import { useEffect } from 'react';
import { useUserRoleSelector } from 'store/selectors/userSelectors/userRoleSelector';
import { useUserSelector } from 'store/selectors/userSelectors/userSelector';

export function MouseflowContainer() {
  const user = useUserSelector();
  const user_role = useUserRoleSelector();
  useEffect(() => {
    if (user) {
      const { email } = user;
      if (user_role && email) {
        window._mfq.push(['identify', email]);
        window._mfq.push(['setVariable', 'role', user_role]);
        window._mfq.push(['setVariable', 'environment', BKJ_ENVIRONMENT]);
      }
    }
  }, [user, user_role]);

  return null;
}
