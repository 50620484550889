import { TimeOffModalComponentMap } from 'components/TimeOffModal/TimeOffModal.constants';
import {
  ITimeOffModalRoutingContext,
  TimeOffModalViewType,
} from 'components/TimeOffModal/TimeOffModal.types';
import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect } from 'react';

export const TimeOffModalRoutingContext = createContext<ITimeOffModalRoutingContext>({
  isOpen: false,
} as ITimeOffModalRoutingContext);

export const TimeOffModalRoutingProvider: FC = ({ children }) => {
  const [{ timeOffModalTimeOffId, timeOffModalActiveModalView }, { removeParam }] =
    useURLParameters<{
      timeOffModalTimeOffId?: string;
      timeOffModalActiveModalView: TimeOffModalViewType;
    }>();

  useEffect(() => {
    if (!Object.keys(TimeOffModalComponentMap).includes(timeOffModalActiveModalView))
      removeParam(['timeOffModalActiveModalView', 'timeOffModalTimeOffId']);

    if (timeOffModalActiveModalView !== 'CreateTimeOffView' && !timeOffModalTimeOffId)
      removeParam(['timeOffModalActiveModalView', 'timeOffModalTimeOffId']);
  }, [removeParam, timeOffModalActiveModalView, timeOffModalTimeOffId]);

  return (
    <TimeOffModalRoutingContext.Provider
      value={{
        isOpen: !!timeOffModalActiveModalView,
        timeOffModalActiveModalView,
        timeOffId: timeOffModalTimeOffId || '',
      }}
    >
      {children}
    </TimeOffModalRoutingContext.Provider>
  );
};

export const useTimeOffModalRoutingContext = () => useContext(TimeOffModalRoutingContext);
