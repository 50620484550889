import { Fragment } from 'react';

import { FC, useContext } from 'react';
import { CreateShiftCardError, CreateShiftCardErrorsWrapper } from './CreateShiftCard.styled';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { CreateShiftModal } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { useTranslation } from 'hooks/useTranslation';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { WebCreateShiftCard } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/WebCreateShiftCard';
import { MobileCreateShiftCard } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/MobileCreateShiftCard';

export const CreateShiftCard: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const {
    createShift,
    formErrors,
    isEditShiftDetailsDialogOpen,
    handleEditShiftDialogAction,
    handleEditShiftDialogCancel,
  } = useContext(CreateShiftCardContext);
  const { values } = createShift;

  return (
    <Fragment>
      {isDesktop ? <WebCreateShiftCard /> : <MobileCreateShiftCard />}
      <CreateShiftCardErrorsWrapper>
        {formErrors &&
          formErrors[0] &&
          formErrors.map((error, index) => (
            <CreateShiftCardError key={`${values.id}-form-error-${index}`}>
              {error}
            </CreateShiftCardError>
          ))}
      </CreateShiftCardErrorsWrapper>
      <CreateShiftModal />
      <BKJCenteredDialog
        variant="Default"
        title={t('create_shifts:EDIT_SHIFT_DETAILS')}
        text={t('create_shifts:PREVIOUS_TEAM_MEMBER_REMOVE_TEXT')}
        isOpen={isEditShiftDetailsDialogOpen}
        cancelLabel={t('auth:CANCEL')}
        actionLabel={t('auth:CONFIRM')}
        onAction={handleEditShiftDialogAction}
        onClose={handleEditShiftDialogCancel}
        onCancel={handleEditShiftDialogCancel}
      />
    </Fragment>
  );
};
