import { useFetchTimeOffDataContext } from 'components/TimeOffModal/contexts/FetchTimeOff.data.context';
import { useTimeOffModalRoutingContext } from 'components/TimeOffModal/TimeOffModal.routing.context';
import { createContext, FC, MouseEventHandler, useContext, useMemo } from 'react';
import { toastService } from 'services';
import { useGQLMutation } from 'hooks/useGQLMutation.ts';
import { MUTATION_deleteTimeOff } from 'mutation';
import {
  MUTATION_deleteTimeOffById,
  MUTATION_deleteTimeOffByIdVariables,
} from 'types/graphql-types';
import { UseGQLMutation } from 'hooks/useGQLMutation.ts/useGQLMutation.types';
import { timeOffModalRoutingService } from 'components/TimeOffModal/TimeOffModal.constants';
import { useTranslation } from 'hooks/useTranslation';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { DateTime, Interval } from 'luxon';

interface IDeleteTimeOffViewProps
  extends Omit<
    UseGQLMutation<MUTATION_deleteTimeOffById, MUTATION_deleteTimeOffByIdVariables>,
    'mutate'
  > {
  handleDeleteTimeOffById: MouseEventHandler<HTMLButtonElement>;
  isTimeOffInProgress: boolean;
}

export const DeleteTimeOffViewPropsContext = createContext<IDeleteTimeOffViewProps>(
  {} as IDeleteTimeOffViewProps,
);

export const DeleteTimeOffViewPropsProvider: FC = ({ children }) => {
  const { timeOffId } = useTimeOffModalRoutingContext();
  const { t } = useTranslation();
  const { mutate, ...rest } = useGQLMutation<
    MUTATION_deleteTimeOffById,
    MUTATION_deleteTimeOffByIdVariables
  >({
    key: 'DELETE_TIME_OFF_VIEW_DELETE_TIME_OFF_BY_ID',
    mutation: MUTATION_deleteTimeOff,
    variables: {
      timeOffId: timeOffId || '',
    },
    refetchQueries: ['scheduleTimeOffs'],
    onSuccess: () => {
      toastService.success(t('success:DELETED'));
      window.scheduleUtils.forceDataUpdate();
      timeOffModalRoutingService.close();
    },
    onError: handleGenericErrorMessage,
  });

  const {
    data: {
      fetchTimeOff: { startTime, endTime },
    },
  } = useFetchTimeOffDataContext();

  const isTimeOffInProgress = useMemo(() => {
    const now = DateTime.now();
    const start = DateTime.fromISO(startTime);
    const end = DateTime.fromISO(endTime);
    const interval = Interval.fromDateTimes(start, end);
    return interval.contains(now);
  }, [endTime, startTime]);

  const contextValue = useMemo(
    () => ({
      handleDeleteTimeOffById: mutate as unknown as MouseEventHandler<HTMLButtonElement>,
      isTimeOffInProgress,
      ...rest,
    }),
    [isTimeOffInProgress, mutate, rest],
  );

  return (
    <DeleteTimeOffViewPropsContext.Provider value={contextValue}>
      {children}
    </DeleteTimeOffViewPropsContext.Provider>
  );
};

export const useDeleteTimeOffViewPropsContext = (): IDeleteTimeOffViewProps =>
  useContext(DeleteTimeOffViewPropsContext);
