import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { isUserAccessStatusPendingSelector } from 'store/selectors/userSelectors/isUserAccessStatusPendingSelector';
import { isUserB2bSelector } from 'store/selectors/userSelectors/isUserB2bSelector';

export const isUserB2bPendingSelector = createCachedSelector(
  [isUserB2bSelector, isUserAccessStatusPendingSelector],

  (isUserB2b, isUserAccessStatusPending) => {
    return isUserB2b && isUserAccessStatusPending;
  },
)((store) => `${isUserB2bSelector(store)}${isUserAccessStatusPendingSelector(store)}`);

export const useIsUserB2bPendingSelector = () => useSelector(isUserB2bPendingSelector);
