import {
  BKJButton,
  BKJCheckbox,
  BKJIcon,
  BKJPillTextArea,
  BKJTextInput,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useApplyFacilitiesFormContext } from 'components/CustomFieldEditModal/contexts/ApplyFacilities.form.context';
import { useCustomFieldEditModalPropsContext } from 'components/CustomFieldEditModal/contexts/CustomFieldModalEdit.props.context';
import { useEditCustomFieldFormContext } from 'components/CustomFieldEditModal/contexts/EditCustomField.form.context';
import {
  CUSTOM_FIELDS_MODAL_DESCRIPTION,
  DropdownOptionLimit,
  whatsThisUrlRedirect,
} from 'components/CustomFieldModal/CustomFieldModal.constants';
import {
  Description,
  EditFacilitiesWrapper,
  Heading,
  MobileCheckboxTextWrapper,
  TextButtonWrapper,
} from 'components/CustomFieldModal/CustomFieldModal.styled';
import { CustomFieldTypeSelectInput } from 'components/CustomFieldTypeSelectInput';
import { FC, Fragment, MouseEventHandler } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const EditCustomFieldView: FC = () => {
  const { goToEditFacilitiesView, goToDeleteCustomFieldView } =
    useCustomFieldEditModalPropsContext();
  const { onChange, onSubmit, values, fieldErrors, isDisabled } = useEditCustomFieldFormContext();
  const { values: applyFacilitiesValue } = useApplyFacilitiesFormContext();
  const { t } = useTranslation();
  const { isPetalProvider } = WhiteLabelAccessor;

  return (
    <Fragment>
      <Flex flexDirection="column" gap="32px" padding="32px" flex="1">
        <Heading>{t('settings:EDIT_FIELD')}:</Heading>

        <Description>{t(CUSTOM_FIELDS_MODAL_DESCRIPTION)}</Description>

        <Flex gap="4px" flexDirection="column">
          <Description>{t('settings:FIELD_TYPE')}</Description>
          <CustomFieldTypeSelectInput
            disabled={true}
            value={values.type}
            onChange={onChange}
            name={'type'}
            placeholder={''}
          />

          {!isPetalProvider && (
            <form action={whatsThisUrlRedirect} target="_blank">
              <TextButtonWrapper variant="PurpleText">
                {t('settings:WHAT_IS_THIS')}?
              </TextButtonWrapper>
            </form>
          )}
        </Flex>

        <Flex gap="4px" flexDirection="column">
          <BKJTextInput
            disabled={true}
            value={values.name}
            onChange={onChange}
            label={t('settings:FIELD_NAME')}
            required={true}
            name={'name'}
            placeholder={t('settings:ENTER_FIELD_NAME')}
            autoComplete="off"
          />
        </Flex>

        {['Single-Line Text', 'Paragraph Text'].includes(values.type[0]) ? (
          <Flex gap="4px" flexDirection="column">
            <BKJTextInput
              value={values.placeholder}
              onChange={onChange}
              label={t('settings:PLACEHOLDER_TEXT')}
              name={'placeholder'}
              placeholder={t('settings:PLACEHOLDER_TEXT_APPEARS')}
              error={fieldErrors.placeholder}
              autoComplete="off"
            />
          </Flex>
        ) : null}

        {values.type[0] === 'Single-Select Dropdown' && (
          <Fragment>
            <Flex gap="4px" flexDirection="column">
              <BKJPillTextArea
                label={t('settings:DROPDOWN_VALUES')}
                placeholder={t('settings:ENTER_DROPDOWN_VALUES_NOTE')}
                valueDescription="values"
                value={values.dropdownOptions}
                required={true}
                onChange={onChange}
                validations={[]}
                max={DropdownOptionLimit}
                name="dropdownOptions"
                height="216px"
                error={fieldErrors.dropdownOptions}
                autoComplete="off"
              />
            </Flex>
            {/* https://bookjane.atlassian.net/browse/WEB-2504: Remove 'prefill dropdown value with first value in the list' */}
            {/* <Flex>
              <Description>
                <BKJCheckbox
                  value={values.preFillDefault}
                  name="preFillDefault"
                  variant={'GreenSolid'}
                  onChange={onChange}
                />
                {t(CUSTOM_FIELDS_MODAL_DROPDOWN_PLACEHOLDER)}
              </Description>
            </Flex> */}
          </Fragment>
        )}

        <Flex>
          <BKJCheckbox
            variant="GreenSolid"
            value={values.mobile}
            name="mobile"
            onChange={onChange}
          />
          <MobileCheckboxTextWrapper>{t('settings:SHOW_FIELD_NOTE')}</MobileCheckboxTextWrapper>
        </Flex>

        <EditFacilitiesWrapper alignItems="center" gap="8px" onClick={goToEditFacilitiesView}>
          <TextButtonWrapper variant="PurpleText" width="max-content">
            {t('settings:EDIT_LOCATIONS')}
          </TextButtonWrapper>
          &nbsp;
          {`(${
            applyFacilitiesValue.allFacilities
              ? t('common:ALL')
              : applyFacilitiesValue.communityIds.length
          } ${t('settings:APPLIED')})`}
          <BKJIcon iconName="ChevronRight" />
        </EditFacilitiesWrapper>
      </Flex>

      <BKJSideModalFooter>
        <TextButtonWrapper width="180px" variant="RedText" onClick={goToDeleteCustomFieldView}>
          {t('auth:DELETE')}
        </TextButtonWrapper>
        <AutoMargin />
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          disabled={isDisabled}
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
