import React from 'react';
import styled, { css } from 'styled-components';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const ScheduleTabNavigationWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #dae1e9;
`;

export const TabRowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 65px;
  max-height: 65px;
  min-height: 65px;
  overflow-x: auto;
  max-width: calc(100vw - 80px);
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    padding: 0px 15px;
    width: 100%;
  }
`;

export const RelativeAnchor = styled.div`
  position: relative;
  height: 65px;
  max-height: 65px;
  min-height: 65px;

  ${({ isScrollEnabled }) =>
    isScrollEnabled &&
    css`
      max-width: calc(100% - 140px);
    `}
`;

export const StyledTab = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 280px;
  height: 52px;
  padding: 0 16px 0 20px;
  box-shadow: 0 2px 8px 0 rgba(13, 51, 32, 0.1);
  outline: none;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ isActive }) => {
    return isActive ? '#ffffff' : '#f6f7fb';
  }};
  transition: all 100ms linear;
  overflow: hidden;
  ${({ isActive }) => {
    if (!isActive)
      return css`
        cursor: pointer;
        &:hover {
          background-color: #e9eef4;
        }
        &:active {
          background-color: #dfe3e9;
        }
      `;
    return css``;
  }}
`;

export const GradientFade = styled.div`
  pointer-events: none;
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, rgba(218, 225, 233, 1) 4%, rgba(255, 255, 255, 0) 100%);

  ${({ isFlipped }) =>
    isFlipped &&
    css`
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 4%, rgba(218, 225, 233, 1) 100%);
    `}
  width: 28px;
  height: 65px;
  bottom: 0;
  transition: opacity 200ms linear;
  ${({ isFlipped }) => !isFlipped && 'left: 0'};
  ${({ isFlipped }) => isFlipped && 'right: 0'};
`;

export const NewTab = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  width: 52px;
  min-width: 52px;
  max-width: 52px;
  outline: none;
  border: none;
  box-shadow: 0 2px 8px 0 rgba(13, 51, 32, 0.1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ theme }) => {
    switch (WhiteLabelAccessor.whiteLabelProvider) {
      case WhiteLabelAccessor.WhiteLabelProvidersEnum.XTM:
        return theme.colors.AccentPurple;
      default:
        return '#4e4785';
    }
  }};
  cursor: pointer;
  transition: all 100ms linear;
  &:after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: #ffffff;
    position: absolute;
    transition: all 100ms linear;
  }
  &:before {
    content: '';
    width: 14px;
    height: 1px;
    background-color: #ffffff;
    position: absolute;
    transform: rotate(90deg);
    transition: all 100ms linear;
  }
  &:hover {
    background-color: #342a6a;
  }
  &:active {
    background-color: #7975b6;
  }
  &:disabled {
    background-color: #f6f7fb;
    cursor: not-allowed;
    &:after {
      background-color: #dae1e9;
    }
    &:before {
      background-color: #dae1e9;
    }
  }
`;

export const TabCloseButton = styled.button`
  margin-left: 8px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  border: none;
  cursor: pointer;
  ${({ theme }) => theme.fonts.PoppinsText};
  min-height: 24px;
  height: 24px;
  width: 24px;
  min-width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    width: 130%;
    height: 130%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

export const TabLabel = styled.label`
  ${({ isActive, theme }) =>
    isActive ? theme.fonts.PoppinsTextBold : theme.fonts.PoppinsTextMedium};
  font-size: 16px;
  color: #45424e;
  cursor: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function SearchedMembersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 20 20"
      style={{ marginRight: 8 }}
    >
      <path
        fill="#BABABA"
        fillRule="evenodd"
        d="M4.957 6.579c0 2.895 2.375 5.263 5.278 5.263 2.903 0 5.278-2.368 5.278-5.263V5.263C15.513 2.368 13.138 0 10.235 0 7.332 0 4.957 2.368 4.957 5.263V6.58zm2.64 6.842h5.278c3.694 0 6.597 2.895 6.597 6.579H1c0-3.684 2.903-6.579 6.597-6.579z"
        clipRule="evenodd"
      />
      <mask id="vqr7zwmupa" width="19" height="20" x="1" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M4.957 6.579c0 2.895 2.375 5.263 5.278 5.263 2.903 0 5.278-2.368 5.278-5.263V5.263C15.513 2.368 13.138 0 10.235 0 7.332 0 4.957 2.368 4.957 5.263V6.58zm2.64 6.842h5.278c3.694 0 6.597 2.895 6.597 6.579H1c0-3.684 2.903-6.579 6.597-6.579z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#vqr7zwmupa)">
        <path fill="#45424E" d="M0 0h20v20H0z" />
      </g>
    </svg>
  );
}

export function ConflictsAndWarningsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      style={{ marginRight: 8 }}
    >
      <path fill="#CE1037" d="M0 0h20v20H0z" />
      <path
        fill="#fff"
        d="M9.5 12.828l-.142-7.543h1.914l-.143 7.543H9.501zm.815.914c.295 0 .543.1.743.3.2.2.3.448.3.743s-.1.543-.3.743c-.2.2-.448.3-.743.3s-.548-.1-.757-.3c-.2-.2-.3-.448-.3-.743s.1-.543.3-.743c.21-.2.462-.3.757-.3z"
      />
    </svg>
  );
}
