import { FC, useContext } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useSSFBehaviors, useURLParameters } from 'hooks';
import { assignToAgencyTeamMemberFetchAgencyJanes } from 'types/graphql-types';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { SessionService } from 'services';
import { fetchAgencyTeamMembersQueryConfig } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/context.constants';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { useSelector } from 'react-redux';
import { castStringToInteger } from 'utils';
import { SSFBehaviorsContextType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';

export const FetchAgencyTeamMembersDataContext = createGQLDataContext({});

export const FetchAgencyTeamMembersDataProvider: FC = ({ children }) => {
  const { data } = useShiftModalDataContext();
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [{ agencyCommunityId: _agencyCommunityId }] =
    useURLParameters<{
      agencyCommunityId: string;
    }>();

  const agencyCommunityId = _agencyCommunityId
    ? castStringToInteger(_agencyCommunityId)
    : undefined;

  const [values, rest] = useSSFBehaviors<assignToAgencyTeamMemberFetchAgencyJanes>(
    fetchAgencyTeamMembersQueryConfig({
      orderId: data.fetchOrder.relayId,
      positionId: data.fetchOrder.position.id,
      agencyId: SessionService.getUserAgencyId()!,
      agencyCommunityId: !isUserAgencyAndAgencyLocationFilterForcedDisabled
        ? [agencyCommunityId!]
        : [],
    }),
  );

  return (
    <FetchAgencyTeamMembersDataContext.Provider value={{ values, ...rest }}>
      {children}
    </FetchAgencyTeamMembersDataContext.Provider>
  );
};

export const useFetchAgencyTeamMembersDataContext =
  (): SSFBehaviorsContextType<assignToAgencyTeamMemberFetchAgencyJanes> =>
    useContext(FetchAgencyTeamMembersDataContext);
