import { history } from 'utils/history';

type ViewType<Enum> = keyof Enum & string;

type AllTypes<ViewEnum, ModalRoutingServiceSchema> =
  | number
  | string
  | URLSearchParams
  | ViewType<ViewEnum>
  | Function
  | ViewEnum
  | Array<ViewType<ViewEnum>>
  | ModalRoutingServiceSchema
  | boolean
  | Record<string, string | number>
  | null;

export class ModalRoutingService<
  DefaultView extends ViewType<ViewEnum>,
  ViewEnum extends Record<string, {}>,
  ModalRoutingServiceSchema extends Record<string, 'string' | 'number'>,
> {
  private urlQueryParams: URLSearchParams;
  private activeViewKey: string;
  activeModalView: ViewType<ViewEnum>;
  defaultView: ViewType<ViewEnum>;
  private viewHistory: Array<ViewType<ViewEnum>>;
  private schema: ModalRoutingServiceSchema;
  public viewMap: ViewEnum;
  private showPrevModalBackButton: boolean;
  private prevModalParams: Record<string, string | number>;

  private hideBackButtonViewList: string[];

  [key: string]: AllTypes<ViewEnum, ModalRoutingServiceSchema>;
  /**
   * @description A service that encapsulates all of the common functionality used by modals.
   * @param uniqueId A unique key to namespace the url params.
   * @param defaultView The default view the modal lands on.
   * @param viewMap An object containing all the react component view definitions.
   * @param schema An object { [key: URLParamName]: Type } containing the name of the required URLSearchParam value along with its type (if it is a string it is set as is - but if its a number Number.parseInt(value, 10) is applied).
   */
  constructor(
    uniqueId: string,
    defaultView: DefaultView,
    viewMap: ViewEnum,
    schema: ModalRoutingServiceSchema,
    hideBackButtonViewList: string[],
  ) {
    this.urlQueryParams = new URLSearchParams(window.location.search);
    this.defaultView = defaultView;
    this.viewHistory = [];
    this.viewMap = viewMap;
    this.schema = schema;
    this.showPrevModalBackButton = false;
    this.prevModalParams = {};
    this.hideBackButtonViewList = hideBackButtonViewList;

    this.activeViewKey = `${uniqueId}ActiveModalView`;
    const initialState = this.prepareInitialState(schema);

    const initialActiveView: ViewType<ViewEnum> =
      this.urlQueryParams.get(this.activeViewKey) || defaultView;

    const isOpen =
      Object.values(initialState).length > 0 && Object.values(initialState).every(Boolean);

    this.activeModalView = initialActiveView;
    if (!this.urlQueryParams.get(this.activeViewKey) && isOpen)
      this.setParams({ [this.activeViewKey]: initialActiveView });
  }

  /**
   * @description This method sets all the dependent values needed from the URLSearchParameters object by going through the provided schema initializer.
   * @param schema An object { [key: URLParamName]: Type } containing the name of the required URLSearchParam value along with its type (if it is a string it is set as is - but if its a number Number.parseInt(value, 10) is applied).
   */
  private prepareInitialState = (schema: ModalRoutingServiceSchema): Record<string, {}> => {
    const obj: Record<string, {}> = {};
    Object.entries(schema).forEach(([key, value]) => {
      const urlParamValue = this.urlQueryParams.get(key);
      if (urlParamValue !== null)
        switch (value) {
          case 'number':
            this[key] = Number.parseInt(urlParamValue, 10);
            obj[key] = Number.parseInt(urlParamValue, 10);
            break;
          case 'string':
            this[key] = urlParamValue;
            obj[key] = urlParamValue;
            break;
        }
    });
    return obj;
  };
  /**
   * @description Will return boolean depending on whether or not the active modal view matches at least one of the view enums
   * @returns Boolean.
   */
  public isOpen = (): boolean => {
    return Object.keys(this.viewMap).includes(this.activeModalView);
  };

  /**
   * @description Method to get the current activeView value.
   * @returns The current activeView value.
   */
  public getActiveView = (): ViewType<ViewEnum> => {
    return this.activeModalView;
  };
  /**
   * This method gets the value of a parameter.
   * @returns Value.
   */
  public getParam = (key: string): AllTypes<ViewEnum, ModalRoutingServiceSchema> => {
    return this[key];
  };

  /**
   * @description This method sets the active view.
   * @param nextView The next view to display.
   */
  public goToView = (
    nextView: ViewType<ViewEnum>,
    o: Record<string, string | number> = {},
  ): void => {
    if (this.activeModalView)
      this.viewHistory = Array.from([...new Set([...this.viewHistory, this.activeModalView])]);
    this.activeModalView = nextView;
    this.setParams({ ...o, [this.activeViewKey]: this.activeModalView });
  };

  /**
   * @description This method goes back to the previous view.
   */
  public goToPrevView = (): void => {
    this.viewHistory = Array.from([...new Set([...this.viewHistory])]);
    const prevView = this.viewHistory.pop() || null;
    if (!prevView && this.showPrevModalBackButton) {
      this.removeAllParams();
      this.setParams(this.prevModalParams);
    } else if (!prevView) {
      this.setParams({ [this.activeViewKey]: this.defaultView });
    } else {
      this.setParams({ [this.activeViewKey]: prevView });
    }
  };
  /**
   * @description This method returns true if the current view is not equal to the default view.
   * @returns Boolean.
   */
  public isBackButtonVisible = (): boolean => {
    if (this.hideBackButtonViewList.includes(this.activeModalView)) return false;
    if (this.showPrevModalBackButton) return true;
    if (this.viewHistory.length === 0) return false;
    return this.activeModalView !== this.defaultView;
  };
  /**
   * @description This method closes the modal instance.
   */
  public close = (): void => {
    this.removeAllParams();
    this.viewHistory = [];
  };
  /**
   * @description This method opens the modal instance.
   * @param {Record<string, string | number>} o Object containing all the dependency values required.
   * @param {ViewType<ViewEnum>} initialView The initial view that is set when opening the modal.
   * @param {Record<string, string | number>} [prevParams] containing the previous modal params.
   */

  public open = (
    o: Record<string, string | number>,
    initialView: ViewType<ViewEnum> = this.defaultView,
    prevParams?: Record<string, string | number>,
  ): void => {
    if (prevParams) {
      this.showPrevModalBackButton = true;
      this.prevModalParams = prevParams;
    } else {
      this.showPrevModalBackButton = false;
      this.prevModalParams = {};
    }
    this.activeModalView = initialView;
    this.setParams({ ...o, [this.activeViewKey]: initialView });
  };
  /**
   * @description This method sets the url search parameters.
   * @param params The new values you want to set in the URL search parameters.
   */

  private setParams = (params: Record<string, string | number>): void => {
    this.urlQueryParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]: [string, string | number]) => {
      if (this.urlQueryParams.has(key)) {
        if (key === this.activeViewKey) {
          this.activeModalView = value as ViewType<ViewEnum>;
        } else {
          const schemaType = this.schema[key];

          if (schemaType === 'number' && typeof value === 'string') {
            this[key] = Number.parseInt(value, 10);
          }
        }
        this.urlQueryParams.set(key, String(value));
      } else this.urlQueryParams.append(key, String(value));
    });
    history.push(`${window.location.pathname}?${this.urlQueryParams.toString()}`);
  };

  /**
   * @description This method removes all URL search params.
   * */
  private removeAllParams = (): void => {
    history.push(window.location.pathname);
    this.urlQueryParams = new URLSearchParams(window.location.search);
  };
}
