import { BKJDatePickerType } from 'components/BKJDatePicker/src';
import { SortFieldType } from 'pages/ShiftsListPage/ShiftsTable/ShiftsB2BTable.types';
import { ShiftsPageRouteType } from 'pages/ShiftsPage/ShiftsPage.types';

export const shiftsPagePaths: ShiftsPageRouteType[] = ['/shifts/calendar', '/shifts/list'];

export const shiftPageEmptyCopy: Record<string, string> = {
  header: 'dashboard:NO_UPCOMING_SHIFTS',
  body: 'shifts_calendar:NO_SHIFT_MESSAGE',
};

export const shiftsListInitialSortField = {
  name: 'startAt',
  direction: 'asc',
};

export const shiftsListInitialSortFields: SortFieldType[] = [
  {
    name: 'startAt',
    direction: 'asc',
  },
  {
    name: 'positionName',
    direction: 'asc',
  },
];

export const ShiftsPageTabs: { label: string; path: ShiftsPageRouteType }[] = [
  { label: 'CALENDAR_VIEW', path: '/shifts/calendar' },
  { label: 'LIST_VIEW', path: '/shifts/list' },
];

export const calendarTypeMap: Record<string, BKJDatePickerType> = {
  '/shifts/calendar': 'SingleMonth',
  '/shifts/list': 'MonthRange',
};
