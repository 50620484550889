import { USER_PERMISSIONS } from 'constants/endpoints';
import { METHOD_GET } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export const API_GET_userPermissions = ({ baseUrl }: { baseUrl: string }): ApiRequestConfigType => {
  return {
    __typename: 'LegacyAPI',
    method: METHOD_GET,
    url: USER_PERMISSIONS({ baseUrl }),
  };
};
